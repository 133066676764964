/*!
 * tui-chart-all
 * @fileoverview tui-chart
 * @author NHN. FE Development Lab <dl_javascript@nhn.com>
 * @version 3.11.2
 * @license MIT
 * @link https://github.com/nhn/tui.chart
 * bundle created at "Wed Apr 22 2020 16:39:12 GMT+0900 (Korean Standard Time)"
 */ !(function (t, e) {
    "object" == typeof exports && "object" == typeof module
        ? (module.exports = e())
        : "function" == typeof define && define.amd
            ? define([], e)
            : "object" == typeof exports
                ? (exports.chart = e())
                : ((t.tui = t.tui || {}), (t.tui.chart = e()));
})(this, function () {
    return (function (t) {
        function e(n) {
            if (i[n]) return i[n].exports;
            var o = (i[n] = { exports: {}, id: n, loaded: !1 });
            return t[n].call(o.exports, o, o.exports, e), (o.loaded = !0), o.exports;
        }
        var i = {};
        return (e.m = t), (e.c = i), (e.p = "/dist/"), e(0);
    })([
        function (t, e, i) {
            t.exports = i(2);
        },
        ,
        function (t, e, i) {
            "use strict";
            i(3), i(53), i(58), i(62), i(65), i(68), i(83), i(86), i(92), i(96), i(118), i(121), i(126), i(129);
            var n,
                o = (n = i(133)) && n.__esModule ? n : { default: n };
            t.exports = o.default;
        },
        function (t, e, i) {
            var n = i(4);
            t.exports = n;
        },
        function (t, e, i) {
            i(5);
            var n = i(39).Object;
            t.exports = function (t, e) {
                return n.create(t, e);
            };
        },
        function (t, e, i) {
            var n = i(6),
                o = i(9),
                r = i(49);
            n({ target: "Object", stat: !0, sham: !o }, { create: r });
        },
        function (t, e, i) {
            var n = i(7),
                o = i(8).f,
                r = i(22),
                a = i(25),
                s = i(26),
                u = i(36),
                l = i(48);
            t.exports = function (t, e) {
                var i,
                    h,
                    c,
                    p,
                    d,
                    f,
                    g = t.target,
                    m = t.global,
                    y = t.stat;
                if ((h = m ? n : y ? n[g] || s(g, {}) : (n[g] || {}).prototype))
                    for (c in e) {
                        if (((d = e[c]), (p = t.noTargetGet ? (f = o(h, c)) && f.value : h[c]), !(i = l(m ? c : g + (y ? "." : "#") + c, t.forced)) && void 0 !== p)) {
                            if (typeof d == typeof p) continue;
                            u(d, p);
                        }
                        (t.sham || (p && p.sham)) && r(d, "sham", !0), a(h, c, d, t);
                    }
            };
        },
        function (t, e) {
            (function (e) {
                var i = function (t) {
                    return t && t.Math == Math && t;
                };
                t.exports = i("object" == typeof globalThis && globalThis) || i("object" == typeof window && window) || i("object" == typeof self && self) || i("object" == typeof e && e) || Function("return this")();
            }.call(
                e,
                (function () {
                    return this;
                })()
            ));
        },
        function (t, e, i) {
            var n = i(9),
                o = i(11),
                r = i(12),
                a = i(13),
                s = i(17),
                u = i(19),
                l = i(20),
                h = Object.getOwnPropertyDescriptor;
            e.f = n
                ? h
                : function (t, e) {
                    if (((t = a(t)), (e = s(e, !0)), l))
                        try {
                            return h(t, e);
                        } catch (i) {}
                    if (u(t, e)) return r(!o.f.call(t, e), t[e]);
                };
        },
        function (t, e, i) {
            var n = i(10);
            t.exports = !n(function () {
                return (
                    7 !=
                    Object.defineProperty({}, 1, {
                        get: function () {
                            return 7;
                        },
                    })[1]
                );
            });
        },
        function (t, e) {
            t.exports = function (t) {
                try {
                    return !!t();
                } catch (e) {
                    return !0;
                }
            };
        },
        function (t, e) {
            "use strict";
            var i = {}.propertyIsEnumerable,
                n = Object.getOwnPropertyDescriptor,
                o = n && !i.call({ 1: 2 }, 1);
            e.f = o
                ? function (t) {
                    var e = n(this, t);
                    return !!e && e.enumerable;
                }
                : i;
        },
        function (t, e) {
            t.exports = function (t, e) {
                return { enumerable: !(1 & t), configurable: !(2 & t), writable: !(4 & t), value: e };
            };
        },
        function (t, e, i) {
            var n = i(14),
                o = i(16);
            t.exports = function (t) {
                return n(o(t));
            };
        },
        function (t, e, i) {
            var n = i(10),
                o = i(15),
                r = "".split;
            t.exports = n(function () {
                return !Object("z").propertyIsEnumerable(0);
            })
                ? function (t) {
                    return "String" == o(t) ? r.call(t, "") : Object(t);
                }
                : Object;
        },
        function (t, e) {
            var i = {}.toString;
            t.exports = function (t) {
                return i.call(t).slice(8, -1);
            };
        },
        function (t, e) {
            t.exports = function (t) {
                if (void 0 == t) throw TypeError("Can't call method on " + t);
                return t;
            };
        },
        function (t, e, i) {
            var n = i(18);
            t.exports = function (t, e) {
                var i, o;
                if (!n(t)) return t;
                if ((e && "function" == typeof (i = t.toString) && !n((o = i.call(t)))) || ("function" == typeof (i = t.valueOf) && !n((o = i.call(t)))) || (!e && "function" == typeof (i = t.toString) && !n((o = i.call(t))))) return o;
                throw TypeError("Can't convert object to primitive value");
            };
        },
        function (t, e) {
            t.exports = function (t) {
                return "object" == typeof t ? null !== t : "function" == typeof t;
            };
        },
        function (t, e) {
            var i = {}.hasOwnProperty;
            t.exports = function (t, e) {
                return i.call(t, e);
            };
        },
        function (t, e, i) {
            var n = i(9),
                o = i(10),
                r = i(21);
            t.exports =
                !n &&
                !o(function () {
                    return (
                        7 !=
                        Object.defineProperty(r("div"), "a", {
                            get: function () {
                                return 7;
                            },
                        }).a
                    );
                });
        },
        function (t, e, i) {
            var n = i(7),
                o = i(18),
                r = n.document,
                a = o(r) && o(r.createElement);
            t.exports = function (t) {
                return a ? r.createElement(t) : {};
            };
        },
        function (t, e, i) {
            var n = i(9),
                o = i(23),
                r = i(12);
            t.exports = n
                ? function (t, e, i) {
                    return o.f(t, e, r(1, i));
                }
                : function (t, e, i) {
                    return (t[e] = i), t;
                };
        },
        function (t, e, i) {
            var n = i(9),
                o = i(20),
                r = i(24),
                a = i(17),
                s = Object.defineProperty;
            e.f = n
                ? s
                : function (t, e, i) {
                    if ((r(t), (e = a(e, !0)), r(i), o))
                        try {
                            return s(t, e, i);
                        } catch (n) {}
                    if ("get" in i || "set" in i) throw TypeError("Accessors not supported");
                    return "value" in i && (t[e] = i.value), t;
                };
        },
        function (t, e, i) {
            var n = i(18);
            t.exports = function (t) {
                if (!n(t)) throw TypeError(String(t) + " is not an object");
                return t;
            };
        },
        function (t, e, i) {
            var n = i(7),
                o = i(22),
                r = i(19),
                a = i(26),
                s = i(27),
                u = i(29),
                l = u.get,
                h = u.enforce,
                c = String(String).split("String");
            (t.exports = function (t, e, i, s) {
                var u = !!s && !!s.unsafe,
                    l = !!s && !!s.enumerable,
                    p = !!s && !!s.noTargetGet;
                return (
                    "function" == typeof i && ("string" != typeof e || r(i, "name") || o(i, "name", e), (h(i).source = c.join("string" == typeof e ? e : ""))),
                        t === n ? void (l ? (t[e] = i) : a(e, i)) : (u ? !p && t[e] && (l = !0) : delete t[e], void (l ? (t[e] = i) : o(t, e, i)))
                );
            })(Function.prototype, "toString", function () {
                return ("function" == typeof this && l(this).source) || s(this);
            });
        },
        function (t, e, i) {
            var n = i(7),
                o = i(22);
            t.exports = function (t, e) {
                try {
                    o(n, t, e);
                } catch (i) {
                    n[t] = e;
                }
                return e;
            };
        },
        function (t, e, i) {
            var n = i(28),
                o = Function.toString;
            "function" != typeof n.inspectSource &&
            (n.inspectSource = function (t) {
                return o.call(t);
            }),
                (t.exports = n.inspectSource);
        },
        function (t, e, i) {
            var n = i(7),
                o = i(26),
                r = "__core-js_shared__",
                a = n[r] || o(r, {});
            t.exports = a;
        },
        function (t, e, i) {
            var n,
                o,
                r,
                a = i(30),
                s = i(7),
                u = i(18),
                l = i(22),
                h = i(19),
                c = i(31),
                p = i(35),
                d = s.WeakMap,
                f = function (t) {
                    return r(t) ? o(t) : n(t, {});
                },
                g = function (t) {
                    return function (e) {
                        var i;
                        if (!u(e) || (i = o(e)).type !== t) throw TypeError("Incompatible receiver, " + t + " required");
                        return i;
                    };
                };
            if (a) {
                var m = new d(),
                    y = m.get,
                    v = m.has,
                    $ = m.set;
                (n = function (t, e) {
                    return $.call(m, t, e), e;
                }),
                    (o = function (t) {
                        return y.call(m, t) || {};
                    }),
                    (r = function (t) {
                        return v.call(m, t);
                    });
            } else {
                var x = c("state");
                (p[x] = !0),
                    (n = function (t, e) {
                        return l(t, x, e), e;
                    }),
                    (o = function (t) {
                        return h(t, x) ? t[x] : {};
                    }),
                    (r = function (t) {
                        return h(t, x);
                    });
            }
            t.exports = { set: n, get: o, has: r, enforce: f, getterFor: g };
        },
        function (t, e, i) {
            var n = i(7),
                o = i(27),
                r = n.WeakMap;
            t.exports = "function" == typeof r && /native code/.test(o(r));
        },
        function (t, e, i) {
            var n = i(32),
                o = i(34),
                r = n("keys");
            t.exports = function (t) {
                return r[t] || (r[t] = o(t));
            };
        },
        function (t, e, i) {
            var n = i(33),
                o = i(28);
            (t.exports = function (t, e) {
                return o[t] || (o[t] = void 0 !== e ? e : {});
            })("versions", []).push({ version: "3.6.4", mode: n ? "pure" : "global", copyright: "\xa9 2020 Denis Pushkarev (zloirock.ru)" });
        },
        function (t, e) {
            t.exports = !1;
        },
        function (t, e) {
            var i = 0,
                n = Math.random();
            t.exports = function (t) {
                return "Symbol(" + String(void 0 === t ? "" : t) + ")_" + (++i + n).toString(36);
            };
        },
        function (t, e) {
            t.exports = {};
        },
        function (t, e, i) {
            var n = i(19),
                o = i(37),
                r = i(8),
                a = i(23);
            t.exports = function (t, e) {
                for (var i = o(e), s = a.f, u = r.f, l = 0; l < i.length; l++) {
                    var h = i[l];
                    n(t, h) || s(t, h, u(e, h));
                }
            };
        },
        function (t, e, i) {
            var n = i(38),
                o = i(40),
                r = i(47),
                a = i(24);
            t.exports =
                n("Reflect", "ownKeys") ||
                function (t) {
                    var e = o.f(a(t)),
                        i = r.f;
                    return i ? e.concat(i(t)) : e;
                };
        },
        function (t, e, i) {
            var n = i(39),
                o = i(7),
                r = function (t) {
                    return "function" == typeof t ? t : void 0;
                };
            t.exports = function (t, e) {
                return arguments.length < 2 ? r(n[t]) || r(o[t]) : (n[t] && n[t][e]) || (o[t] && o[t][e]);
            };
        },
        function (t, e, i) {
            var n = i(7);
            t.exports = n;
        },
        function (t, e, i) {
            var n = i(41),
                o = i(46).concat("length", "prototype");
            e.f =
                Object.getOwnPropertyNames ||
                function (t) {
                    return n(t, o);
                };
        },
        function (t, e, i) {
            var n = i(19),
                o = i(13),
                r = i(42).indexOf,
                a = i(35);
            t.exports = function (t, e) {
                var i,
                    s = o(t),
                    u = 0,
                    l = [];
                for (i in s) !n(a, i) && n(s, i) && l.push(i);
                for (; e.length > u; ) n(s, (i = e[u++])) && (~r(l, i) || l.push(i));
                return l;
            };
        },
        function (t, e, i) {
            var n = i(13),
                o = i(43),
                r = i(45),
                a = function (t) {
                    return function (e, i, a) {
                        var s,
                            u = n(e),
                            l = o(u.length),
                            h = r(a, l);
                        if (t && i != i) {
                            for (; l > h; ) if ((s = u[h++]) != s) return !0;
                        } else for (; l > h; h++) if ((t || h in u) && u[h] === i) return t || h || 0;
                        return !t && -1;
                    };
                };
            t.exports = { includes: a(!0), indexOf: a(!1) };
        },
        function (t, e, i) {
            var n = i(44),
                o = Math.min;
            t.exports = function (t) {
                return t > 0 ? o(n(t), 9007199254740991) : 0;
            };
        },
        function (t, e) {
            var i = Math.ceil,
                n = Math.floor;
            t.exports = function (t) {
                return isNaN((t = +t)) ? 0 : (t > 0 ? n : i)(t);
            };
        },
        function (t, e, i) {
            var n = i(44),
                o = Math.max,
                r = Math.min;
            t.exports = function (t, e) {
                var i = n(t);
                return i < 0 ? o(i + e, 0) : r(i, e);
            };
        },
        function (t, e) {
            t.exports = ["constructor", "hasOwnProperty", "isPrototypeOf", "propertyIsEnumerable", "toLocaleString", "toString", "valueOf"];
        },
        function (t, e) {
            e.f = Object.getOwnPropertySymbols;
        },
        function (t, e, i) {
            var n = i(10),
                o = /#|\.prototype\./,
                r = function (t, e) {
                    var i = s[a(t)];
                    return i == l || (i != u && ("function" == typeof e ? n(e) : !!e));
                },
                a = (r.normalize = function (t) {
                    return String(t).replace(o, ".").toLowerCase();
                }),
                s = (r.data = {}),
                u = (r.NATIVE = "N"),
                l = (r.POLYFILL = "P");
            t.exports = r;
        },
        function (t, e, i) {
            var n,
                o = i(24),
                r = i(50),
                a = i(46),
                s = i(35),
                u = i(52),
                l = i(21),
                h = i(31),
                c = "prototype",
                p = "script",
                d = h("IE_PROTO"),
                f = function () {},
                g = function (t) {
                    return "<" + p + ">" + t + "</" + p + ">";
                },
                m = function (t) {
                    t.write(g("")), t.close();
                    var e = t.parentWindow.Object;
                    return (t = null), e;
                },
                y = function () {
                    var t,
                        e = l("iframe");
                    return (e.style.display = "none"), u.appendChild(e), (e.src = String("java" + p + ":")), (t = e.contentWindow.document).open(), t.write(g("document.F=Object")), t.close(), t.F;
                },
                v = function () {
                    try {
                        n = document.domain && new ActiveXObject("htmlfile");
                    } catch (t) {}
                    v = n ? m(n) : y();
                    for (var e = a.length; e--; ) delete v[c][a[e]];
                    return v();
                };
            (s[d] = !0),
                (t.exports =
                    Object.create ||
                    function (t, e) {
                        var i;
                        return null !== t ? ((f[c] = o(t)), (i = new f()), (f[c] = null), (i[d] = t)) : (i = v()), void 0 === e ? i : r(i, e);
                    });
        },
        function (t, e, i) {
            var n = i(9),
                o = i(23),
                r = i(24),
                a = i(51);
            t.exports = n
                ? Object.defineProperties
                : function (t, e) {
                    r(t);
                    for (var i, n = a(e), s = n.length, u = 0; s > u; ) o.f(t, (i = n[u++]), e[i]);
                    return t;
                };
        },
        function (t, e, i) {
            var n = i(41),
                o = i(46);
            t.exports =
                Object.keys ||
                function (t) {
                    return n(t, o);
                };
        },
        function (t, e, i) {
            var n = i(38);
            t.exports = n("document", "documentElement");
        },
        function (t, e, i) {
            var n = i(54);
            t.exports = n;
        },
        function (t, e, i) {
            i(55);
            var n = i(39);
            t.exports = n.Object.assign;
        },
        function (t, e, i) {
            var n = i(6),
                o = i(56);
            n({ target: "Object", stat: !0, forced: Object.assign !== o }, { assign: o });
        },
        function (t, e, i) {
            "use strict";
            var n = i(9),
                o = i(10),
                r = i(51),
                a = i(47),
                s = i(11),
                u = i(57),
                l = i(14),
                h = Object.assign,
                c = Object.defineProperty;
            t.exports =
                !h ||
                o(function () {
                    if (
                        n &&
                        1 !==
                        h(
                            { b: 1 },
                            h(
                                c({}, "a", {
                                    enumerable: !0,
                                    get: function () {
                                        c(this, "b", { value: 3, enumerable: !1 });
                                    },
                                }),
                                { b: 2 }
                            )
                        ).b
                    )
                        return !0;
                    var t = {},
                        e = {},
                        i = Symbol(),
                        o = "abcdefghijklmnopqrst";
                    return (
                        (t[i] = 7),
                            o.split("").forEach(function (t) {
                                e[t] = t;
                            }),
                        7 != h({}, t)[i] || r(h({}, e)).join("") != o
                    );
                })
                    ? function (t, e) {
                        for (var i = u(t), o = arguments.length, h = 1, c = a.f, p = s.f; o > h; )
                            for (var d, f = l(arguments[h++]), g = c ? r(f).concat(c(f)) : r(f), m = g.length, y = 0; m > y; ) (d = g[y++]), (n && !p.call(f, d)) || (i[d] = f[d]);
                        return i;
                    }
                    : h;
        },
        function (t, e, i) {
            var n = i(16);
            t.exports = function (t) {
                return Object(n(t));
            };
        },
        function (t, e, i) {
            var n = i(59);
            t.exports = n;
        },
        function (t, e, i) {
            i(60);
            var n = i(39);
            t.exports = n.Object.entries;
        },
        function (t, e, i) {
            var n = i(6),
                o = i(61).entries;
            n(
                { target: "Object", stat: !0 },
                {
                    entries: function (t) {
                        return o(t);
                    },
                }
            );
        },
        function (t, e, i) {
            var n = i(9),
                o = i(51),
                r = i(13),
                a = i(11).f,
                s = function (t) {
                    return function (e) {
                        for (var i, s = r(e), u = o(s), l = u.length, h = 0, c = []; l > h; ) (i = u[h++]), (n && !a.call(s, i)) || c.push(t ? [i, s[i]] : s[i]);
                        return c;
                    };
                };
            t.exports = { entries: s(!0), values: s(!1) };
        },
        function (t, e, i) {
            var n = i(63);
            t.exports = n;
        },
        function (t, e, i) {
            i(64);
            var n = i(39);
            t.exports = n.Object.values;
        },
        function (t, e, i) {
            var n = i(6),
                o = i(61).values;
            n(
                { target: "Object", stat: !0 },
                {
                    values: function (t) {
                        return o(t);
                    },
                }
            );
        },
        function (t, e, i) {
            var n = i(66);
            t.exports = n;
        },
        function (t, e, i) {
            i(67);
            var n = i(39);
            t.exports = n.Object.keys;
        },
        function (t, e, i) {
            var n = i(6),
                o = i(57),
                r = i(51),
                a = i(10)(function () {
                    r(1);
                });
            n(
                { target: "Object", stat: !0, forced: a },
                {
                    keys: function (t) {
                        return r(o(t));
                    },
                }
            );
        },
        function (t, e, i) {
            var n = i(69);
            t.exports = n;
        },
        function (t, e, i) {
            i(70);
            var n = i(82);
            t.exports = n("Array", "forEach");
        },
        function (t, e, i) {
            "use strict";
            var n = i(6),
                o = i(71);
            n({ target: "Array", proto: !0, forced: [].forEach != o }, { forEach: o });
        },
        function (t, e, i) {
            "use strict";
            var n = i(72).forEach,
                o = i(80),
                r = i(81),
                a = o("forEach"),
                s = r("forEach");
            t.exports =
                a && s
                    ? [].forEach
                    : function (t) {
                        return n(this, t, arguments.length > 1 ? arguments[1] : void 0);
                    };
        },
        function (t, e, i) {
            var n = i(73),
                o = i(14),
                r = i(57),
                a = i(43),
                s = i(75),
                u = [].push,
                l = function (t) {
                    var e = 1 == t,
                        i = 2 == t,
                        l = 3 == t,
                        h = 4 == t,
                        c = 6 == t,
                        p = 5 == t || c;
                    return function (d, f, g, m) {
                        for (var y, v, $ = r(d), x = o($), T = n(f, g, 3), b = a(x.length), _ = 0, A = m || s, D = e ? A(d, b) : i ? A(d, 0) : void 0; b > _; _++)
                            if ((p || _ in x) && ((v = T((y = x[_]), _, $)), t)) {
                                if (e) D[_] = v;
                                else if (v)
                                    switch (t) {
                                        case 3:
                                            return !0;
                                        case 5:
                                            return y;
                                        case 6:
                                            return _;
                                        case 2:
                                            u.call(D, y);
                                    }
                                else if (h) return !1;
                            }
                        return c ? -1 : l || h ? h : D;
                    };
                };
            t.exports = { forEach: l(0), map: l(1), filter: l(2), some: l(3), every: l(4), find: l(5), findIndex: l(6) };
        },
        function (t, e, i) {
            var n = i(74);
            t.exports = function (t, e, i) {
                if ((n(t), void 0 === e)) return t;
                switch (i) {
                    case 0:
                        return function () {
                            return t.call(e);
                        };
                    case 1:
                        return function (i) {
                            return t.call(e, i);
                        };
                    case 2:
                        return function (i, n) {
                            return t.call(e, i, n);
                        };
                    case 3:
                        return function (i, n, o) {
                            return t.call(e, i, n, o);
                        };
                }
                return function () {
                    return t.apply(e, arguments);
                };
            };
        },
        function (t, e) {
            t.exports = function (t) {
                if ("function" != typeof t) throw TypeError(String(t) + " is not a function");
                return t;
            };
        },
        function (t, e, i) {
            var n = i(18),
                o = i(76),
                r = i(77)("species");
            t.exports = function (t, e) {
                var i;
                return o(t) && ("function" == typeof (i = t.constructor) && (i === Array || o(i.prototype)) ? (i = void 0) : n(i) && null === (i = i[r]) && (i = void 0)), new (void 0 === i ? Array : i)(0 === e ? 0 : e);
            };
        },
        function (t, e, i) {
            var n = i(15);
            t.exports =
                Array.isArray ||
                function (t) {
                    return "Array" == n(t);
                };
        },
        function (t, e, i) {
            var n = i(7),
                o = i(32),
                r = i(19),
                a = i(34),
                s = i(78),
                u = i(79),
                l = o("wks"),
                h = n.Symbol,
                c = u ? h : (h && h.withoutSetter) || a;
            t.exports = function (t) {
                return r(l, t) || (s && r(h, t) ? (l[t] = h[t]) : (l[t] = c("Symbol." + t))), l[t];
            };
        },
        function (t, e, i) {
            var n = i(10);
            t.exports =
                !!Object.getOwnPropertySymbols &&
                !n(function () {
                    return !String(Symbol());
                });
        },
        function (t, e, i) {
            var n = i(78);
            t.exports = n && !Symbol.sham && "symbol" == typeof Symbol.iterator;
        },
        function (t, e, i) {
            "use strict";
            var n = i(10);
            t.exports = function (t, e) {
                var i = [][t];
                return (
                    !!i &&
                    n(function () {
                        i.call(
                            null,
                            e ||
                            function () {
                                throw 1;
                            },
                            1
                        );
                    })
                );
            };
        },
        function (t, e, i) {
            var n = i(9),
                o = i(10),
                r = i(19),
                a = Object.defineProperty,
                s = {},
                u = function (t) {
                    throw t;
                };
            t.exports = function (t, e) {
                if (r(s, t)) return s[t];
                e || (e = {});
                var i = [][t],
                    l = !!r(e, "ACCESSORS") && e.ACCESSORS,
                    h = r(e, 0) ? e[0] : u,
                    c = r(e, 1) ? e[1] : void 0;
                return (s[t] =
                    !!i &&
                    !o(function () {
                        if (l && !n) return !0;
                        var t = { length: -1 };
                        l ? a(t, 1, { enumerable: !0, get: u }) : (t[1] = 1), i.call(t, h, c);
                    }));
            };
        },
        function (t, e, i) {
            var n = i(7),
                o = i(73),
                r = Function.call;
            t.exports = function (t, e, i) {
                return o(r, n[t].prototype[e], i);
            };
        },
        function (t, e, i) {
            var n = i(84);
            t.exports = n;
        },
        function (t, e, i) {
            i(85);
            var n = i(39);
            t.exports = n.Array.isArray;
        },
        function (t, e, i) {
            var n = i(6),
                o = i(76);
            n({ target: "Array", stat: !0 }, { isArray: o });
        },
        function (t, e, i) {
            var n = i(87);
            t.exports = n;
        },
        function (t, e, i) {
            i(88);
            var n = i(82);
            t.exports = n("Array", "filter");
        },
        function (t, e, i) {
            "use strict";
            var n = i(6),
                o = i(72).filter,
                r = i(89),
                a = i(81),
                s = r("filter"),
                u = a("filter");
            n(
                { target: "Array", proto: !0, forced: !s || !u },
                {
                    filter: function (t) {
                        return o(this, t, arguments.length > 1 ? arguments[1] : void 0);
                    },
                }
            );
        },
        function (t, e, i) {
            var n = i(10),
                o = i(77),
                r = i(90),
                a = o("species");
            t.exports = function (t) {
                return (
                    r >= 51 ||
                    !n(function () {
                        var e = [];
                        return (
                            ((e.constructor = {})[a] = function () {
                                return { foo: 1 };
                            }),
                            1 !== e[t](Boolean).foo
                        );
                    })
                );
            };
        },
        function (t, e, i) {
            var n,
                o,
                r = i(7),
                a = i(91),
                s = r.process,
                u = s && s.versions,
                l = u && u.v8;
            l ? (o = (n = l.split("."))[0] + n[1]) : a && (!(n = a.match(/Edge\/(\d+)/)) || n[1] >= 74) && (n = a.match(/Chrome\/(\d+)/)) && (o = n[1]), (t.exports = o && +o);
        },
        function (t, e, i) {
            var n = i(38);
            t.exports = n("navigator", "userAgent") || "";
        },
        function (t, e, i) {
            var n = i(93);
            t.exports = n;
        },
        function (t, e, i) {
            i(94);
            var n = i(82);
            t.exports = n("Array", "reduce");
        },
        function (t, e, i) {
            "use strict";
            var n = i(6),
                o = i(95).left,
                r = i(80),
                a = i(81),
                s = r("reduce"),
                u = a("reduce", { 1: 0 });
            n(
                { target: "Array", proto: !0, forced: !s || !u },
                {
                    reduce: function (t) {
                        return o(this, t, arguments.length, arguments.length > 1 ? arguments[1] : void 0);
                    },
                }
            );
        },
        function (t, e, i) {
            var n = i(74),
                o = i(57),
                r = i(14),
                a = i(43),
                s = function (t) {
                    return function (e, i, s, u) {
                        n(i);
                        var l = o(e),
                            h = r(l),
                            c = a(l.length),
                            p = t ? c - 1 : 0,
                            d = t ? -1 : 1;
                        if (s < 2)
                            for (;;) {
                                if (p in h) {
                                    (u = h[p]), (p += d);
                                    break;
                                }
                                if (((p += d), t ? p < 0 : c <= p)) throw TypeError("Reduce of empty array with no initial value");
                            }
                        for (; t ? p >= 0 : c > p; p += d) p in h && (u = i(u, h[p], p, l));
                        return u;
                    };
                };
            t.exports = { left: s(!1), right: s(!0) };
        },
        function (t, e, i) {
            var n = i(97);
            t.exports = n;
        },
        function (t, e, i) {
            i(98), i(109);
            var n = i(39);
            t.exports = n.Array.from;
        },
        function (t, e, i) {
            "use strict";
            var n = i(99).charAt,
                o = i(29),
                r = i(100),
                a = "String Iterator",
                s = o.set,
                u = o.getterFor(a);
            r(
                String,
                "String",
                function (t) {
                    s(this, { type: a, string: String(t), index: 0 });
                },
                function () {
                    var t,
                        e = u(this),
                        i = e.string,
                        o = e.index;
                    return o >= i.length ? { value: void 0, done: !0 } : ((t = n(i, o)), (e.index += t.length), { value: t, done: !1 });
                }
            );
        },
        function (t, e, i) {
            var n = i(44),
                o = i(16),
                r = function (t) {
                    return function (e, i) {
                        var r,
                            a,
                            s = String(o(e)),
                            u = n(i),
                            l = s.length;
                        return u < 0 || u >= l
                            ? t
                                ? ""
                                : void 0
                            : (r = s.charCodeAt(u)) < 55296 || r > 56319 || u + 1 === l || (a = s.charCodeAt(u + 1)) < 56320 || a > 57343
                                ? t
                                    ? s.charAt(u)
                                    : r
                                : t
                                    ? s.slice(u, u + 2)
                                    : ((r - 55296) << 10) + (a - 56320) + 65536;
                    };
                };
            t.exports = { codeAt: r(!1), charAt: r(!0) };
        },
        function (t, e, i) {
            "use strict";
            var n = i(6),
                o = i(101),
                r = i(103),
                a = i(107),
                s = i(105),
                u = i(22),
                l = i(25),
                h = i(77),
                c = i(33),
                p = i(106),
                d = i(102),
                f = d.IteratorPrototype,
                g = d.BUGGY_SAFARI_ITERATORS,
                m = h("iterator"),
                y = "keys",
                v = "values",
                $ = "entries",
                x = function () {
                    return this;
                };
            t.exports = function (t, e, i, h, d, T, b) {
                o(i, e, h);
                var _,
                    A,
                    D,
                    C = function (t) {
                        if (t === d && M) return M;
                        if (!g && t in S) return S[t];
                        switch (t) {
                            case y:
                            case v:
                            case $:
                                return function () {
                                    return new i(this, t);
                                };
                        }
                        return function () {
                            return new i(this);
                        };
                    },
                    E = e + " Iterator",
                    L = !1,
                    S = t.prototype,
                    P = S[m] || S["@@iterator"] || (d && S[d]),
                    M = (!g && P) || C(d),
                    w = ("Array" == e && S.entries) || P;
                if (
                    (w && ((_ = r(w.call(new t()))), f !== Object.prototype && _.next && (c || r(_) === f || (a ? a(_, f) : "function" != typeof _[m] && u(_, m, x)), s(_, E, !0, !0), c && (p[E] = x))),
                    d == v &&
                    P &&
                    P.name !== v &&
                    ((L = !0),
                        (M = function () {
                            return P.call(this);
                        })),
                    (c && !b) || S[m] === M || u(S, m, M),
                        (p[e] = M),
                        d)
                ) {
                    if (((A = { values: C(v), keys: T ? M : C(y), entries: C($) }), b)) for (D in A) (!g && !L && D in S) || l(S, D, A[D]);
                    else n({ target: e, proto: !0, forced: g || L }, A);
                }
                return A;
            };
        },
        function (t, e, i) {
            "use strict";
            var n = i(102).IteratorPrototype,
                o = i(49),
                r = i(12),
                a = i(105),
                s = i(106),
                u = function () {
                    return this;
                };
            t.exports = function (t, e, i) {
                var l = e + " Iterator";
                return (t.prototype = o(n, { next: r(1, i) })), a(t, l, !1, !0), (s[l] = u), t;
            };
        },
        function (t, e, i) {
            "use strict";
            var n,
                o,
                r,
                a = i(103),
                s = i(22),
                u = i(19),
                l = i(77),
                h = i(33),
                c = l("iterator"),
                p = !1,
                d = function () {
                    return this;
                };
            [].keys && ("next" in (r = [].keys()) ? (o = a(a(r))) !== Object.prototype && (n = o) : (p = !0)), void 0 == n && (n = {}), h || u(n, c) || s(n, c, d), (t.exports = { IteratorPrototype: n, BUGGY_SAFARI_ITERATORS: p });
        },
        function (t, e, i) {
            var n = i(19),
                o = i(57),
                r = i(31),
                a = i(104),
                s = r("IE_PROTO"),
                u = Object.prototype;
            t.exports = a
                ? Object.getPrototypeOf
                : function (t) {
                    return (t = o(t)), n(t, s) ? t[s] : "function" == typeof t.constructor && t instanceof t.constructor ? t.constructor.prototype : t instanceof Object ? u : null;
                };
        },
        function (t, e, i) {
            var n = i(10);
            t.exports = !n(function () {
                function t() {}
                return (t.prototype.constructor = null), Object.getPrototypeOf(new t()) !== t.prototype;
            });
        },
        function (t, e, i) {
            var n = i(23).f,
                o = i(19),
                r = i(77)("toStringTag");
            t.exports = function (t, e, i) {
                t && !o((t = i ? t : t.prototype), r) && n(t, r, { configurable: !0, value: e });
            };
        },
        function (t, e) {
            t.exports = {};
        },
        function (t, e, i) {
            var n = i(24),
                o = i(108);
            t.exports =
                Object.setPrototypeOf ||
                ("__proto__" in {}
                    ? (function () {
                        var t,
                            e = !1,
                            i = {};
                        try {
                            (t = Object.getOwnPropertyDescriptor(Object.prototype, "__proto__").set).call(i, []), (e = i instanceof Array);
                        } catch (r) {}
                        return function (i, r) {
                            return n(i), o(r), e ? t.call(i, r) : (i.__proto__ = r), i;
                        };
                    })()
                    : void 0);
        },
        function (t, e, i) {
            var n = i(18);
            t.exports = function (t) {
                if (!n(t) && null !== t) throw TypeError("Can't set " + String(t) + " as a prototype");
                return t;
            };
        },
        function (t, e, i) {
            var n = i(6),
                o = i(110),
                r = !i(117)(function (t) {
                    Array.from(t);
                });
            n({ target: "Array", stat: !0, forced: r }, { from: o });
        },
        function (t, e, i) {
            "use strict";
            var n = i(73),
                o = i(57),
                r = i(111),
                a = i(112),
                s = i(43),
                u = i(113),
                l = i(114);
            t.exports = function (t) {
                var e,
                    i,
                    h,
                    c,
                    p,
                    d,
                    f = o(t),
                    g = "function" == typeof this ? this : Array,
                    m = arguments.length,
                    y = m > 1 ? arguments[1] : void 0,
                    v = void 0 !== y,
                    $ = l(f),
                    x = 0;
                if ((v && (y = n(y, m > 2 ? arguments[2] : void 0, 2)), void 0 == $ || (g == Array && a($)))) for (e = s(f.length), i = new g(e); e > x; x++) (d = v ? y(f[x], x) : f[x]), u(i, x, d);
                else for (p = (c = $.call(f)).next, i = new g(); !(h = p.call(c)).done; x++) (d = v ? r(c, y, [h.value, x], !0) : h.value), u(i, x, d);
                return (i.length = x), i;
            };
        },
        function (t, e, i) {
            var n = i(24);
            t.exports = function (t, e, i, o) {
                try {
                    return o ? e(n(i)[0], i[1]) : e(i);
                } catch (r) {
                    var a = t.return;
                    throw (void 0 !== a && n(a.call(t)), r);
                }
            };
        },
        function (t, e, i) {
            var n = i(77),
                o = i(106),
                r = n("iterator"),
                a = Array.prototype;
            t.exports = function (t) {
                return void 0 !== t && (o.Array === t || a[r] === t);
            };
        },
        function (t, e, i) {
            "use strict";
            var n = i(17),
                o = i(23),
                r = i(12);
            t.exports = function (t, e, i) {
                var a = n(e);
                a in t ? o.f(t, a, r(0, i)) : (t[a] = i);
            };
        },
        function (t, e, i) {
            var n = i(115),
                o = i(106),
                r = i(77)("iterator");
            t.exports = function (t) {
                if (void 0 != t) return t[r] || t["@@iterator"] || o[n(t)];
            };
        },
        function (t, e, i) {
            var n = i(116),
                o = i(15),
                r = i(77)("toStringTag"),
                a =
                    "Arguments" ==
                    o(
                        (function () {
                            return arguments;
                        })()
                    ),
                s = function (t, e) {
                    try {
                        return t[e];
                    } catch (i) {}
                };
            t.exports = n
                ? o
                : function (t) {
                    var e, i, n;
                    return void 0 === t ? "Undefined" : null === t ? "Null" : "string" == typeof (i = s((e = Object(t)), r)) ? i : a ? o(e) : "Object" == (n = o(e)) && "function" == typeof e.callee ? "Arguments" : n;
                };
        },
        function (t, e, i) {
            var n = i(77)("toStringTag"),
                o = {};
            (o[n] = "z"), (t.exports = "[object z]" === String(o));
        },
        function (t, e, i) {
            var n = i(77)("iterator"),
                o = !1;
            try {
                var r = 0,
                    a = {
                        next: function () {
                            return { done: !!r++ };
                        },
                        return: function () {
                            o = !0;
                        },
                    };
                (a[n] = function () {
                    return this;
                }),
                    Array.from(a, function () {
                        throw 2;
                    });
            } catch (s) {}
            t.exports = function (t, e) {
                if (!e && !o) return !1;
                var i = !1;
                try {
                    var r = {};
                    (r[n] = function () {
                        return {
                            next: function () {
                                return { done: (i = !0) };
                            },
                        };
                    }),
                        t(r);
                } catch (a) {}
                return i;
            };
        },
        function (t, e, i) {
            var n = i(119);
            t.exports = n;
        },
        function (t, e, i) {
            i(120);
            var n = i(82);
            t.exports = n("Array", "map");
        },
        function (t, e, i) {
            "use strict";
            var n = i(6),
                o = i(72).map,
                r = i(89),
                a = i(81),
                s = r("map"),
                u = a("map");
            n(
                { target: "Array", proto: !0, forced: !s || !u },
                {
                    map: function (t) {
                        return o(this, t, arguments.length > 1 ? arguments[1] : void 0);
                    },
                }
            );
        },
        function (t, e, i) {
            var n = i(122);
            t.exports = n;
        },
        function (t, e, i) {
            i(123);
            var n = i(82);
            t.exports = n("Array", "fill");
        },
        function (t, e, i) {
            var n = i(6),
                o = i(124),
                r = i(125);
            n({ target: "Array", proto: !0 }, { fill: o }), r("fill");
        },
        function (t, e, i) {
            "use strict";
            var n = i(57),
                o = i(45),
                r = i(43);
            t.exports = function (t) {
                for (var e = n(this), i = r(e.length), a = arguments.length, s = o(a > 1 ? arguments[1] : void 0, i), u = a > 2 ? arguments[2] : void 0, l = void 0 === u ? i : o(u, i); l > s; ) e[s++] = t;
                return e;
            };
        },
        function (t, e, i) {
            var n = i(77),
                o = i(49),
                r = i(23),
                a = n("unscopables"),
                s = Array.prototype;
            void 0 == s[a] && r.f(s, a, { configurable: !0, value: o(null) }),
                (t.exports = function (t) {
                    s[a][t] = !0;
                });
        },
        function (t, e, i) {
            var n = i(127);
            t.exports = n;
        },
        function (t, e, i) {
            i(128);
            var n = i(39);
            n.JSON || (n.JSON = { stringify: JSON.stringify }),
                (t.exports = function (t, e, i) {
                    return n.JSON.stringify.apply(null, arguments);
                });
        },
        function (t, e, i) {
            var n = i(6),
                o = i(38),
                r = i(10),
                a = o("JSON", "stringify"),
                s = /[\uD800-\uDFFF]/g,
                u = /^[\uD800-\uDBFF]$/,
                l = /^[\uDC00-\uDFFF]$/,
                h = function (t, e, i) {
                    var n = i.charAt(e - 1),
                        o = i.charAt(e + 1);
                    return (u.test(t) && !l.test(o)) || (l.test(t) && !u.test(n)) ? "\\u" + t.charCodeAt(0).toString(16) : t;
                },
                c = r(function () {
                    return '"\udf06\ud834"' !== a("��") || '"\udead"' !== a("�");
                });
            a &&
            n(
                { target: "JSON", stat: !0, forced: c },
                {
                    stringify: function (t, e, i) {
                        var n = a.apply(null, arguments);
                        return "string" == typeof n ? n.replace(s, h) : n;
                    },
                }
            );
        },
        function (t, e, i) {
            var n = i(130);
            t.exports = n;
        },
        function (t, e, i) {
            i(131);
            var n = i(82);
            t.exports = n("Function", "bind");
        },
        function (t, e, i) {
            var n = i(6),
                o = i(132);
            n({ target: "Function", proto: !0 }, { bind: o });
        },
        function (t, e, i) {
            "use strict";
            var n = i(74),
                o = i(18),
                r = [].slice,
                a = {},
                s = function (t, e, i) {
                    if (!(e in a)) {
                        for (var n = [], o = 0; o < e; o++) n[o] = "a[" + o + "]";
                        a[e] = Function("C,a", "return new C(" + n.join(",") + ")");
                    }
                    return a[e](t, i);
                };
            t.exports =
                Function.bind ||
                function (t) {
                    var e = n(this),
                        i = r.call(arguments, 1),
                        a = function () {
                            var n = i.concat(r.call(arguments));
                            return this instanceof a ? s(e, n.length, n) : e.apply(t, n);
                        };
                    return o(e.prototype) && (a.prototype = e.prototype), a;
                };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            var o = i(134),
                r = i(179),
                a = n(r),
                s = i(148),
                u = n(s),
                l = i(158),
                h = n(l),
                c = i(298),
                p = n(c);
            i(307), a.default.registerPlugin("Raphael", o.pluginRaphael, o.callback), (a.default.renderUtil = u.default), (a.default.arrayUtil = h.default), (a.default.colorutil = p.default), (t.exports = a.default);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            (e.__esModule = !0), (e.callback = e.pluginRaphael = void 0);
            var o = i(135),
                r = n(o),
                a = i(136),
                s = n(a),
                u = i(160),
                l = n(u),
                h = i(161),
                c = n(h),
                p = i(162),
                d = n(p),
                f = i(165),
                g = n(f),
                m = i(167),
                y = n(m),
                v = i(168),
                $ = n(v),
                x = i(169),
                T = n(x),
                b = i(170),
                _ = n(b),
                A = i(171),
                D = n(A),
                C = i(172),
                E = n(C),
                L = i(174),
                S = n(L),
                P = i(175),
                M = n(P),
                w = i(176),
                k = n(w),
                I = i(177),
                R = n(I),
                B = i(178),
                O = n(B);
            (e.pluginRaphael = {
                bar: s.default,
                boxplot: l.default,
                bullet: c.default,
                column: s.default,
                line: d.default,
                area: g.default,
                pie: y.default,
                bubble: T.default,
                scatter: T.default,
                heatmap: _.default,
                treemap: _.default,
                map: D.default,
                radial: $.default,
                legend: E.default,
                mapLegend: S.default,
                circleLegend: M.default,
                radialPlot: O.default,
                title: k.default,
                axis: R.default,
            }),
                (e.callback = function (t, e) {
                    var i,
                        n,
                        o,
                        a,
                        s,
                        u,
                        l,
                        h,
                        c,
                        p,
                        d,
                        f,
                        g,
                        m,
                        y = (0, r.default)(t, e.width, e.height),
                        v = y.rect(0, 0, e.width, e.height);
                    return (
                        y.raphael.svg &&
                        ((i = y),
                            (n = document.createElementNS("http://www.w3.org/2000/svg", "filter")),
                            (o = document.createElementNS("http://www.w3.org/2000/svg", "feGaussianBlur")),
                            (a = document.createElementNS("http://www.w3.org/2000/svg", "feFlood")),
                            (s = document.createElementNS("http://www.w3.org/2000/svg", "feComposite")),
                            (u = document.createElementNS("http://www.w3.org/2000/svg", "feMorphology")),
                            (l = document.createElementNS("http://www.w3.org/2000/svg", "feMerge")),
                            (h = document.createElementNS("http://www.w3.org/2000/svg", "feMergeNode")),
                            (c = document.createElementNS("http://www.w3.org/2000/svg", "feMergeNode")),
                            (n.id = "glow"),
                            a.setAttribute("result", "flood"),
                            a.setAttribute("flood-color", "#ffffff"),
                            a.setAttribute("flood-opacity", "0.5"),
                            s.setAttribute("in", "flood"),
                            s.setAttribute("result", "mask"),
                            s.setAttribute("in2", "SourceGraphic"),
                            s.setAttribute("operator", "in"),
                            u.setAttribute("in", "mask"),
                            u.setAttribute("result", "dilated"),
                            u.setAttribute("operator", "dilate"),
                            u.setAttribute("radius", "2"),
                            o.setAttribute("in", "dilated"),
                            o.setAttribute("result", "blurred"),
                            o.setAttribute("stdDeviation", "1"),
                            h.setAttribute("in", "blurred"),
                            c.setAttribute("in", "SourceGraphic"),
                            n.appendChild(a),
                            n.appendChild(s),
                            n.appendChild(u),
                            n.appendChild(o),
                            n.appendChild(l),
                            l.appendChild(h),
                            l.appendChild(c),
                            i.defs.appendChild(n),
                            (p = y),
                            (d = document.createElementNS("http://www.w3.org/2000/svg", "filter")),
                            (f = document.createElementNS("http://www.w3.org/2000/svg", "feOffset")),
                            (g = document.createElementNS("http://www.w3.org/2000/svg", "feGaussianBlur")),
                            (m = document.createElementNS("http://www.w3.org/2000/svg", "feBlend")),
                            d.setAttributeNS(null, "id", "shadow"),
                            d.setAttributeNS(null, "x", "-15%"),
                            d.setAttributeNS(null, "y", "-15%"),
                            d.setAttributeNS(null, "width", "180%"),
                            d.setAttributeNS(null, "height", "180%"),
                            f.setAttributeNS(null, "result", "offOut"),
                            f.setAttributeNS(null, "in", "SourceAlpha"),
                            f.setAttributeNS(null, "dx", "2"),
                            f.setAttributeNS(null, "dy", "2"),
                            g.setAttributeNS(null, "result", "blurOut"),
                            g.setAttributeNS(null, "in", "offOut"),
                            g.setAttributeNS(null, "stdDeviation", "2"),
                            m.setAttributeNS(null, "in", "SourceGraphic"),
                            m.setAttributeNS(null, "in2", "blurOut"),
                            m.setAttributeNS(null, "mode", "normal"),
                            d.appendChild(f),
                            d.appendChild(g),
                            d.appendChild(m),
                            p.defs.appendChild(d)),
                            (y.pushDownBackgroundToBottom = function () {
                                v.toBack();
                            }),
                            (y.changeChartBackgroundColor = function (t) {
                                v.attr({ fill: t });
                            }),
                            (y.changeChartBackgroundOpacity = function (t) {
                                v.attr({ "fill-opacity": t });
                            }),
                            (y.resizeBackground = function (t, e) {
                                v.attr({ width: t, height: e });
                            }),
                            v.attr({ fill: "#fff", "stroke-width": 0 }),
                            y
                    );
                });
        },
        function (t, e, i) {
            var n;
            (n = function () {
                return (function (t) {
                    function e(n) {
                        if (i[n]) return i[n].exports;
                        var o = (i[n] = { exports: {}, id: n, loaded: !1 });
                        return t[n].call(o.exports, o, o.exports, e), (o.loaded = !0), o.exports;
                    }
                    var i = {};
                    return (e.m = t), (e.c = i), (e.p = ""), e(0);
                })([
                    function (t, e, i) {
                        var n, o;
                        void 0 !==
                        (o = function (t) {
                            return t;
                        }.apply(e, (n = [i(1), i(3), i(4)]))) && (t.exports = o);
                    },
                    function (t, e, i) {
                        var n, o;
                        void 0 !==
                        (o = function (t) {
                            function e(i) {
                                if (e.is(i, "function")) return m ? i() : t.on("raphael.DOMload", i);
                                if (e.is(i, V)) return e._engine.create[D](e, i.splice(0, 3 + e.is(i[0], G))).add(i);
                                var n = Array.prototype.slice.call(arguments, 0);
                                if (e.is(n[n.length - 1], "function")) {
                                    var o = n.pop();
                                    return m
                                        ? o.call(e._engine.create[D](e, n))
                                        : t.on("raphael.DOMload", function () {
                                            o.call(e._engine.create[D](e, n));
                                        });
                                }
                                return e._engine.create[D](e, arguments);
                            }
                            function i(t) {
                                if ("function" == typeof t || Object(t) !== t) return t;
                                var e = new t.constructor();
                                for (var n in t) t[T](n) && (e[n] = i(t[n]));
                                return e;
                            }
                            function n(t, e, i) {
                                function n() {
                                    var o = Array.prototype.slice.call(arguments, 0),
                                        r = o.join("␀"),
                                        a = (n.cache = n.cache || {}),
                                        s = (n.count = n.count || []);
                                    return a[T](r)
                                        ? ((function t(e, i) {
                                            for (var n = 0, o = e.length; n < o; n++) if (e[n] === i) return e.push(e.splice(n, 1)[0]);
                                        })(s, r),
                                            i ? i(a[r]) : a[r])
                                        : (s.length >= 1e3 && delete a[s.shift()], s.push(r), (a[r] = t[D](e, o)), i ? i(a[r]) : a[r]);
                                }
                                return n;
                            }
                            function o() {
                                return this.hex;
                            }
                            function r(t, e) {
                                for (var i = [], n = 0, o = t.length; o - 2 * !e > n; n += 2) {
                                    var r = [
                                        { x: +t[n - 2], y: +t[n - 1] },
                                        { x: +t[n], y: +t[n + 1] },
                                        { x: +t[n + 2], y: +t[n + 3] },
                                        { x: +t[n + 4], y: +t[n + 5] },
                                    ];
                                    e
                                        ? n
                                            ? o - 4 == n
                                                ? (r[3] = { x: +t[0], y: +t[1] })
                                                : o - 2 == n && ((r[2] = { x: +t[0], y: +t[1] }), (r[3] = { x: +t[2], y: +t[3] }))
                                            : (r[0] = { x: +t[o - 2], y: +t[o - 1] })
                                        : o - 4 == n
                                            ? (r[3] = r[2])
                                            : n || (r[0] = { x: +t[n], y: +t[n + 1] }),
                                        i.push(["C", (-r[0].x + 6 * r[1].x + r[2].x) / 6, (-r[0].y + 6 * r[1].y + r[2].y) / 6, (r[1].x + 6 * r[2].x - r[3].x) / 6, (r[1].y + 6 * r[2].y - r[3].y) / 6, r[2].x, r[2].y]);
                                }
                                return i;
                            }
                            function a(t, e, i, n, o) {
                                return t * (t * (-3 * e + 9 * i - 9 * n + 3 * o) + 6 * e - 12 * i + 6 * n) - 3 * e + 3 * i;
                            }
                            function s(t, e, i, n, o, r, s, u, l) {
                                null == l && (l = 1), (l = l > 1 ? 1 : l < 0 ? 0 : l);
                                for (
                                    var h = l / 2,
                                        c = [-0.1252, 0.1252, -0.3678, 0.3678, -0.5873, 0.5873, -0.7699, 0.7699, -0.9041, 0.9041, -0.9816, 0.9816],
                                        p = [0.2491, 0.2491, 0.2335, 0.2335, 0.2032, 0.2032, 0.1601, 0.1601, 0.1069, 0.1069, 0.0472, 0.0472],
                                        d = 0,
                                        f = 0;
                                    f < 12;
                                    f++
                                ) {
                                    var g = h * c[f] + h,
                                        m = a(g, t, i, o, s),
                                        y = a(g, e, n, r, u),
                                        v = m * m + y * y;
                                    d += p[f] * I.sqrt(v);
                                }
                                return h * d;
                            }
                            function u(t, e, i, n, o, r, a, s) {
                                if (!(R(t, i) < B(o, a) || B(t, i) > R(o, a) || R(e, n) < B(r, s) || B(e, n) > R(r, s))) {
                                    var u = (t - i) * (r - s) - (e - n) * (o - a);
                                    if (u) {
                                        var l = ((t * n - e * i) * (o - a) - (t - i) * (o * s - r * a)) / u,
                                            h = ((t * n - e * i) * (r - s) - (e - n) * (o * s - r * a)) / u,
                                            c = +l.toFixed(2),
                                            p = +h.toFixed(2);
                                        if (
                                            !(
                                                c < +B(t, i).toFixed(2) ||
                                                c > +R(t, i).toFixed(2) ||
                                                c < +B(o, a).toFixed(2) ||
                                                c > +R(o, a).toFixed(2) ||
                                                p < +B(e, n).toFixed(2) ||
                                                p > +R(e, n).toFixed(2) ||
                                                p < +B(r, s).toFixed(2) ||
                                                p > +R(r, s).toFixed(2)
                                            )
                                        )
                                            return { x: l, y: h };
                                    }
                                }
                            }
                            function l(t, i, n) {
                                var o = e.bezierBBox(t),
                                    r = e.bezierBBox(i);
                                if (!e.isBBoxIntersect(o, r)) return n ? 0 : [];
                                for (var a = s.apply(0, t), l = s.apply(0, i), h = R(~~(a / 5), 1), c = R(~~(l / 5), 1), p = [], d = [], f = {}, g = n ? 0 : [], m = 0; m < h + 1; m++) {
                                    var y = e.findDotsAtSegment.apply(e, t.concat(m / h));
                                    p.push({ x: y.x, y: y.y, t: m / h });
                                }
                                for (m = 0; m < c + 1; m++) (y = e.findDotsAtSegment.apply(e, i.concat(m / c))), d.push({ x: y.x, y: y.y, t: m / c });
                                for (m = 0; m < h; m++)
                                    for (var v = 0; v < c; v++) {
                                        var $ = p[m],
                                            x = p[m + 1],
                                            T = d[v],
                                            b = d[v + 1],
                                            _ = 0.001 > O(x.x - $.x) ? "y" : "x",
                                            A = 0.001 > O(b.x - T.x) ? "y" : "x",
                                            D = u($.x, $.y, x.x, x.y, T.x, T.y, b.x, b.y);
                                        if (D) {
                                            if (f[D.x.toFixed(4)] == D.y.toFixed(4)) continue;
                                            f[D.x.toFixed(4)] = D.y.toFixed(4);
                                            var C = $.t + O((D[_] - $[_]) / (x[_] - $[_])) * (x.t - $.t),
                                                E = T.t + O((D[A] - T[A]) / (b[A] - T[A])) * (b.t - T.t);
                                            C >= 0 && C <= 1.001 && E >= 0 && E <= 1.001 && (n ? g++ : g.push({ x: D.x, y: D.y, t1: B(C, 1), t2: B(E, 1) }));
                                        }
                                    }
                                return g;
                            }
                            function h(t, i, n) {
                                (t = e._path2curve(t)), (i = e._path2curve(i));
                                for (var o, r, a, s, u, h, c, p, d, f, g = n ? 0 : [], m = 0, y = t.length; m < y; m++) {
                                    var v = t[m];
                                    if ("M" == v[0]) (o = u = v[1]), (r = h = v[2]);
                                    else {
                                        "C" == v[0] ? ((o = (d = [o, r].concat(v.slice(1)))[6]), (r = d[7])) : ((d = [o, r, o, r, u, h, u, h]), (o = u), (r = h));
                                        for (var $ = 0, x = i.length; $ < x; $++) {
                                            var T = i[$];
                                            if ("M" == T[0]) (a = c = T[1]), (s = p = T[2]);
                                            else {
                                                "C" == T[0] ? ((a = (f = [a, s].concat(T.slice(1)))[6]), (s = f[7])) : ((f = [a, s, a, s, c, p, c, p]), (a = c), (s = p));
                                                var b = l(d, f, n);
                                                if (n) g += b;
                                                else {
                                                    for (var _ = 0, A = b.length; _ < A; _++) (b[_].segment1 = m), (b[_].segment2 = $), (b[_].bez1 = d), (b[_].bez2 = f);
                                                    g = g.concat(b);
                                                }
                                            }
                                        }
                                    }
                                }
                                return g;
                            }
                            function c(t, e, i, n, o, r) {
                                null != t ? ((this.a = +t), (this.b = +e), (this.c = +i), (this.d = +n), (this.e = +o), (this.f = +r)) : ((this.a = 1), (this.b = 0), (this.c = 0), (this.d = 1), (this.e = 0), (this.f = 0));
                            }
                            function p() {
                                return this.x + L + this.y + L + this.width + " \xd7 " + this.height;
                            }
                            function d(t, e) {
                                var i = [],
                                    n = {};
                                if (((this.ms = e), (this.times = 1), t)) {
                                    for (var o in t) t[T](o) && ((n[Z(o)] = t[o]), i.push(Z(o)));
                                    i.sort(ta);
                                }
                                (this.anim = n), (this.top = i[i.length - 1]), (this.percents = i);
                            }
                            function f(i, n, o, r, a, s) {
                                o = Z(o);
                                var u,
                                    l,
                                    h,
                                    p,
                                    d,
                                    f,
                                    g = i.ms,
                                    m = {},
                                    y = {},
                                    $ = {};
                                if (r)
                                    for (b = 0, _ = tU.length; b < _; b++) {
                                        var x = tU[b];
                                        if (x.el.id == n.id && x.anim == i) {
                                            x.percent != o ? (tU.splice(b, 1), (h = 1)) : (l = x), n.attr(x.totalOrigin);
                                            break;
                                        }
                                    }
                                else r = +y;
                                for (var b = 0, _ = i.percents.length; b < _; b++) {
                                    if (i.percents[b] == o || i.percents[b] > r * i.top) {
                                        (o = i.percents[b]), (d = i.percents[b - 1] || 0), (g = (g / i.top) * (o - d)), (p = i.percents[b + 1]), (u = i.anim[o]);
                                        break;
                                    }
                                    r && n.attr(i.anim[i.percents[b]]);
                                }
                                if (u) {
                                    if (l) (l.initstatus = r), (l.start = new Date() - l.ms * r);
                                    else {
                                        for (var A in u)
                                            if (u[T](A) && (Q[T](A) || n.paper.customAttributes[T](A)))
                                                switch (((m[A] = n.attr(A)), null == m[A] && (m[A] = K[A]), (y[A] = u[A]), Q[A])) {
                                                    case G:
                                                        $[A] = (y[A] - m[A]) / g;
                                                        break;
                                                    case "colour":
                                                        m[A] = e.getRGB(m[A]);
                                                        var D = e.getRGB(y[A]);
                                                        $[A] = { r: (D.r - m[A].r) / g, g: (D.g - m[A].g) / g, b: (D.b - m[A].b) / g };
                                                        break;
                                                    case "path":
                                                        var E = tS(m[A], y[A]),
                                                            L = E[1];
                                                        for (m[A] = E[0], $[A] = [], b = 0, _ = m[A].length; b < _; b++) {
                                                            $[A][b] = [0];
                                                            for (var M = 1, w = m[A][b].length; M < w; M++) $[A][b][M] = (L[b][M] - m[A][b][M]) / g;
                                                        }
                                                        break;
                                                    case "transform":
                                                        var k = n._,
                                                            I = tI(k[A], y[A]);
                                                        if (I)
                                                            for (m[A] = I.from, y[A] = I.to, $[A] = [], $[A].real = !0, b = 0, _ = m[A].length; b < _; b++)
                                                                for ($[A][b] = [m[A][b][0]], M = 1, w = m[A][b].length; M < w; M++) $[A][b][M] = (y[A][b][M] - m[A][b][M]) / g;
                                                        else {
                                                            var R = n.matrix || new c(),
                                                                B = {
                                                                    _: { transform: k.transform },
                                                                    getBBox: function () {
                                                                        return n.getBBox(1);
                                                                    },
                                                                };
                                                            (m[A] = [R.a, R.b, R.c, R.d, R.e, R.f]),
                                                                tw(B, y[A]),
                                                                (y[A] = B._.transform),
                                                                ($[A] = [(B.matrix.a - R.a) / g, (B.matrix.b - R.b) / g, (B.matrix.c - R.c) / g, (B.matrix.d - R.d) / g, (B.matrix.e - R.e) / g, (B.matrix.f - R.f) / g]);
                                                        }
                                                        break;
                                                    case "csv":
                                                        var N = S(u[A])[P](v),
                                                            F = S(m[A])[P](v);
                                                        if ("clip-rect" == A) for (m[A] = F, $[A] = [], b = F.length; b--; ) $[A][b] = (N[b] - m[A][b]) / g;
                                                        y[A] = N;
                                                        break;
                                                    default:
                                                        for (N = [][C](u[A]), F = [][C](m[A]), $[A] = [], b = n.paper.customAttributes[A].length; b--; ) $[A][b] = ((N[b] || 0) - (F[b] || 0)) / g;
                                                }
                                        var H = u.easing,
                                            V = e.easing_formulas[H];
                                        if (!V) {
                                            if ((V = S(H).match(U)) && 5 == V.length) {
                                                var z = V;
                                                V = function (t) {
                                                    return (function t(e, i, n, o, r, a) {
                                                        function s(t) {
                                                            return ((d * t + p) * t + c) * t;
                                                        }
                                                        var u,
                                                            l,
                                                            h,
                                                            c = 3 * i,
                                                            p = 3 * (o - i) - c,
                                                            d = 1 - c - p,
                                                            f = 3 * n,
                                                            g = 3 * (r - n) - f;
                                                        return (
                                                            (h = (function t(e, i) {
                                                                var n, o, r, a, u, l;
                                                                for (r = e, l = 0; l < 8; l++) {
                                                                    if (O((a = s(r) - e)) < i) return r;
                                                                    if (1e-6 > O((u = (3 * d * r + 2 * p) * r + c))) break;
                                                                    r -= a / u;
                                                                }
                                                                if (((n = 0), (o = 1), (r = e) < n)) return n;
                                                                if (r > o) return o;
                                                                for (; n < o && !(O((a = s(r)) - e) < i); ) e > a ? (n = r) : (o = r), (r = (o - n) / 2 + n);
                                                                return r;
                                                            })((u = e), (l = 1 / (200 * a)))),
                                                            (((1 - f - g) * h + g) * h + f) * h
                                                        );
                                                    })(t, +z[1], +z[2], +z[3], +z[4], g);
                                                };
                                            } else V = ts;
                                        }
                                        if (
                                            ((f = u.start || i.start || +new Date()),
                                                (x = {
                                                    anim: i,
                                                    percent: o,
                                                    timestamp: f,
                                                    start: f + (i.del || 0),
                                                    status: 0,
                                                    initstatus: r || 0,
                                                    stop: !1,
                                                    ms: g,
                                                    easing: V,
                                                    from: m,
                                                    diff: $,
                                                    to: y,
                                                    el: n,
                                                    callback: u.callback,
                                                    prev: d,
                                                    next: p,
                                                    repeat: s || i.times,
                                                    origin: n.attr(),
                                                    totalOrigin: a,
                                                }),
                                                tU.push(x),
                                            r && !l && !h && ((x.stop = !0), (x.start = new Date() - g * r), 1 == tU.length))
                                        )
                                            return tZ();
                                        h && (x.start = new Date() - x.ms * r), 1 == tU.length && tX(tZ);
                                    }
                                    t("raphael.anim.start." + n.id, n, i);
                                }
                            }
                            function g(t) {
                                for (var e = 0; e < tU.length; e++) tU[e].el.paper == t && tU.splice(e--, 1);
                            }
                            (e.version = "@@VERSION"), (e.eve = t);
                            var m,
                                y,
                                v = /[, ]+/,
                                $ = { circle: 1, rect: 1, path: 1, ellipse: 1, text: 1, image: 1 },
                                x = /\{(\d+)\}/g,
                                T = "hasOwnProperty",
                                b = { doc: document, win: window },
                                _ = { was: Object.prototype[T].call(b.win, "Raphael"), is: b.win.Raphael },
                                A = function () {
                                    this.ca = this.customAttributes = {};
                                },
                                D = "apply",
                                C = "concat",
                                E = "ontouchstart" in b.win || (b.win.DocumentTouch && b.doc instanceof DocumentTouch),
                                L = " ",
                                S = String,
                                P = "split",
                                M = "click dblclick mousedown mousemove mouseout mouseover mouseup touchstart touchmove touchend touchcancel"[P](L),
                                w = { mousedown: "touchstart", mousemove: "touchmove", mouseup: "touchend" },
                                k = S.prototype.toLowerCase,
                                I = Math,
                                R = I.max,
                                B = I.min,
                                O = I.abs,
                                N = I.pow,
                                F = I.PI,
                                G = "number",
                                H = "string",
                                V = "array",
                                z = Object.prototype.toString,
                                W =
                                    ((e._ISURL = /^url\(['"]?(.+?)['"]?\)$/i),
                                        /^\s*((#[a-f\d]{6})|(#[a-f\d]{3})|rgba?\(\s*([\d\.]+%?\s*,\s*[\d\.]+%?\s*,\s*[\d\.]+%?(?:\s*,\s*[\d\.]+%?)?)\s*\)|hsba?\(\s*([\d\.]+(?:deg|\xb0|%)?\s*,\s*[\d\.]+%?\s*,\s*[\d\.]+(?:%?\s*,\s*[\d\.]+)?)%?\s*\)|hsla?\(\s*([\d\.]+(?:deg|\xb0|%)?\s*,\s*[\d\.]+%?\s*,\s*[\d\.]+(?:%?\s*,\s*[\d\.]+)?)%?\s*\))\s*$/i),
                                Y = { NaN: 1, Infinity: 1, "-Infinity": 1 },
                                U = /^(?:cubic-)?bezier\(([^,]+),([^,]+),([^,]+),([^\)]+)\)/,
                                X = I.round,
                                Z = parseFloat,
                                j = parseInt,
                                q = S.prototype.toUpperCase,
                                K = (e._availableAttrs = {
                                    "arrow-end": "none",
                                    "arrow-start": "none",
                                    blur: 0,
                                    "clip-rect": "0 0 1e9 1e9",
                                    cursor: "default",
                                    cx: 0,
                                    cy: 0,
                                    fill: "#fff",
                                    "fill-opacity": 1,
                                    font: '10px "Arial"',
                                    "font-family": '"Arial"',
                                    "font-size": "10",
                                    "font-style": "normal",
                                    "font-weight": 400,
                                    gradient: 0,
                                    height: 0,
                                    href: "http://raphaeljs.com/",
                                    "letter-spacing": 0,
                                    opacity: 1,
                                    path: "M0,0",
                                    r: 0,
                                    rx: 0,
                                    ry: 0,
                                    src: "",
                                    stroke: "#000",
                                    "stroke-dasharray": "",
                                    "stroke-linecap": "butt",
                                    "stroke-linejoin": "butt",
                                    "stroke-miterlimit": 0,
                                    "stroke-opacity": 1,
                                    "stroke-width": 1,
                                    target: "_blank",
                                    "text-anchor": "middle",
                                    title: "Raphael",
                                    transform: "",
                                    width: 0,
                                    x: 0,
                                    y: 0,
                                }),
                                Q = (e._availableAnimAttrs = {
                                    blur: G,
                                    "clip-rect": "csv",
                                    cx: G,
                                    cy: G,
                                    fill: "colour",
                                    "fill-opacity": G,
                                    "font-size": G,
                                    height: G,
                                    opacity: G,
                                    path: "path",
                                    r: G,
                                    rx: G,
                                    ry: G,
                                    stroke: "colour",
                                    "stroke-opacity": G,
                                    "stroke-width": G,
                                    transform: "transform",
                                    width: G,
                                    x: G,
                                    y: G,
                                }),
                                J = /[\x09\x0a\x0b\x0c\x0d\x20\xa0\u1680\u180e\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u202f\u205f\u3000\u2028\u2029]*,[\x09\x0a\x0b\x0c\x0d\x20\xa0\u1680\u180e\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u202f\u205f\u3000\u2028\u2029]*/,
                                tt = { hs: 1, rg: 1 },
                                te = /,?([achlmqrstvxz]),?/gi,
                                ti = /([achlmrqstvz])[\x09\x0a\x0b\x0c\x0d\x20\xa0\u1680\u180e\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u202f\u205f\u3000\u2028\u2029,]*((-?\d*\.?\d*(?:e[\-+]?\d+)?[\x09\x0a\x0b\x0c\x0d\x20\xa0\u1680\u180e\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u202f\u205f\u3000\u2028\u2029]*,?[\x09\x0a\x0b\x0c\x0d\x20\xa0\u1680\u180e\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u202f\u205f\u3000\u2028\u2029]*)+)/gi,
                                tn = /([rstm])[\x09\x0a\x0b\x0c\x0d\x20\xa0\u1680\u180e\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u202f\u205f\u3000\u2028\u2029,]*((-?\d*\.?\d*(?:e[\-+]?\d+)?[\x09\x0a\x0b\x0c\x0d\x20\xa0\u1680\u180e\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u202f\u205f\u3000\u2028\u2029]*,?[\x09\x0a\x0b\x0c\x0d\x20\xa0\u1680\u180e\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u202f\u205f\u3000\u2028\u2029]*)+)/gi,
                                to = /(-?\d*\.?\d*(?:e[\-+]?\d+)?)[\x09\x0a\x0b\x0c\x0d\x20\xa0\u1680\u180e\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u202f\u205f\u3000\u2028\u2029]*,?[\x09\x0a\x0b\x0c\x0d\x20\xa0\u1680\u180e\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u202f\u205f\u3000\u2028\u2029]*/gi,
                                tr =
                                    ((e._radial_gradient = /^r(?:\(([^,]+?)[\x09\x0a\x0b\x0c\x0d\x20\xa0\u1680\u180e\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u202f\u205f\u3000\u2028\u2029]*,[\x09\x0a\x0b\x0c\x0d\x20\xa0\u1680\u180e\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200a\u202f\u205f\u3000\u2028\u2029]*([^\)]+?)\))?/),
                                        {}),
                                ta = function (t, e) {
                                    return Z(t) - Z(e);
                                },
                                ts = function (t) {
                                    return t;
                                },
                                tu = (e._rectPath = function (t, e, i, n, o) {
                                    return o
                                        ? [
                                            ["M", t + o, e],
                                            ["l", i - 2 * o, 0],
                                            ["a", o, o, 0, 0, 1, o, o],
                                            ["l", 0, n - 2 * o],
                                            ["a", o, o, 0, 0, 1, -o, o],
                                            ["l", 2 * o - i, 0],
                                            ["a", o, o, 0, 0, 1, -o, -o],
                                            ["l", 0, 2 * o - n],
                                            ["a", o, o, 0, 0, 1, o, -o],
                                            ["z"],
                                        ]
                                        : [["M", t, e], ["l", i, 0], ["l", 0, n], ["l", -i, 0], ["z"]];
                                }),
                                tl = function (t, e, i, n) {
                                    return null == n && (n = i), [["M", t, e], ["m", 0, -n], ["a", i, n, 0, 1, 1, 0, 2 * n], ["a", i, n, 0, 1, 1, 0, -2 * n], ["z"]];
                                },
                                th = (e._getPath = {
                                    path: function (t) {
                                        return t.attr("path");
                                    },
                                    circle: function (t) {
                                        var e = t.attrs;
                                        return tl(e.cx, e.cy, e.r);
                                    },
                                    ellipse: function (t) {
                                        var e = t.attrs;
                                        return tl(e.cx, e.cy, e.rx, e.ry);
                                    },
                                    rect: function (t) {
                                        var e = t.attrs;
                                        return tu(e.x, e.y, e.width, e.height, e.r);
                                    },
                                    image: function (t) {
                                        var e = t.attrs;
                                        return tu(e.x, e.y, e.width, e.height);
                                    },
                                    text: function (t) {
                                        var e = t._getBBox();
                                        return tu(e.x, e.y, e.width, e.height);
                                    },
                                    set: function (t) {
                                        var e = t._getBBox();
                                        return tu(e.x, e.y, e.width, e.height);
                                    },
                                }),
                                tc = (e.mapPath = function (t, e) {
                                    var i, n, o, r, a, s, u;
                                    if (!e) return t;
                                    for (t = tS(t), o = 0, a = t.length; o < a; o++) for (u = t[o], r = 1, s = u.length; r < s; r += 2) (i = e.x(u[r], u[r + 1])), (n = e.y(u[r], u[r + 1])), (u[r] = i), (u[r + 1] = n);
                                    return t;
                                });
                            if (((e._g = b), (e.type = b.win.SVGAngle || b.doc.implementation.hasFeature("http://www.w3.org/TR/SVG11/feature#BasicStructure", "1.1") ? "SVG" : "VML"), "VML" == e.type)) {
                                var tp,
                                    td = b.doc.createElement("div");
                                if (((td.innerHTML = '<v:shape adj="1"/>'), ((tp = td.firstChild).style.behavior = "url(#default#VML)"), !tp || "object" != typeof tp.adj)) return (e.type = "");
                                td = null;
                            }
                            (e.svg = !(e.vml = "VML" == e.type)),
                                (e._Paper = A),
                                (e.fn = y = A.prototype = e.prototype),
                                (e._id = 0),
                                (e._oid = 0),
                                (e.is = function (t, e) {
                                    return "finite" == (e = k.call(e))
                                        ? !Y[T](+t)
                                        : "array" == e
                                            ? t instanceof Array
                                            : ("null" == e && null === t) ||
                                            (e == typeof t && null !== t) ||
                                            ("object" == e && t === Object(t)) ||
                                            ("array" == e && Array.isArray && Array.isArray(t)) ||
                                            z.call(t).slice(8, -1).toLowerCase() == e;
                                }),
                                (e.angle = function (t, i, n, o, r, a) {
                                    if (null == r) {
                                        var s = t - n,
                                            u = i - o;
                                        return s || u ? (180 + (180 * I.atan2(-u, -s)) / F + 360) % 360 : 0;
                                    }
                                    return e.angle(t, i, r, a) - e.angle(n, o, r, a);
                                }),
                                (e.rad = function (t) {
                                    return ((t % 360) * F) / 180;
                                }),
                                (e.deg = function (t) {
                                    return Math.round((((180 * t) / F) % 360) * 1e3) / 1e3;
                                }),
                                (e.snapTo = function (t, i, n) {
                                    if (((n = e.is(n, "finite") ? n : 10), e.is(t, V))) {
                                        for (var o = t.length; o--; ) if (O(t[o] - i) <= n) return t[o];
                                    } else {
                                        var r = i % (t = +t);
                                        if (r < n) return i - r;
                                        if (r > t - n) return i - r + t;
                                    }
                                    return i;
                                }),
                                (e.createUUID =
                                    ((t9 = function (t) {
                                        var e = (16 * I.random()) | 0;
                                        return ("x" == t ? e : (3 & e) | 8).toString(16);
                                    }),
                                        function () {
                                            return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, t9).toUpperCase();
                                        })),
                                (e.setWindow = function (i) {
                                    t("raphael.setWindow", e, b.win, i), (b.win = i), (b.doc = b.win.document), e._engine.initWin && e._engine.initWin(b.win);
                                });
                            var tf = function (t) {
                                    if (e.vml) {
                                        var i,
                                            o = /^\s+|\s+$/g;
                                        try {
                                            var r = new ActiveXObject("htmlfile");
                                            r.write("<body>"), r.close(), (i = r.body);
                                        } catch (a) {
                                            i = createPopup().document.body;
                                        }
                                        var s = i.createTextRange();
                                        tf = n(function (t) {
                                            try {
                                                i.style.color = S(t).replace(o, "");
                                                var e = s.queryCommandValue("ForeColor");
                                                return (e = ((255 & e) << 16) | (65280 & e) | ((16711680 & e) >>> 16)), "#" + ("000000" + e.toString(16)).slice(-6);
                                            } catch (n) {
                                                return "none";
                                            }
                                        });
                                    } else {
                                        var u = b.doc.createElement("i");
                                        (u.title = "Rapha\xebl Colour Picker"),
                                            (u.style.display = "none"),
                                            b.doc.body.appendChild(u),
                                            (tf = n(function (t) {
                                                return (u.style.color = t), b.doc.defaultView.getComputedStyle(u, "").getPropertyValue("color");
                                            }));
                                    }
                                    return tf(t);
                                },
                                tg = function () {
                                    return "hsb(" + [this.h, this.s, this.b] + ")";
                                },
                                tm = function () {
                                    return "hsl(" + [this.h, this.s, this.l] + ")";
                                },
                                ty = function () {
                                    return this.hex;
                                },
                                t8 = function (t, i, n) {
                                    if ((null == i && e.is(t, "object") && "r" in t && "g" in t && "b" in t && ((n = t.b), (i = t.g), (t = t.r)), null == i && e.is(t, H))) {
                                        var o = e.getRGB(t);
                                        (t = o.r), (i = o.g), (n = o.b);
                                    }
                                    return (t > 1 || i > 1 || n > 1) && ((t /= 255), (i /= 255), (n /= 255)), [t, i, n];
                                },
                                tv = function (t, i, n, o) {
                                    var r = { r: (t *= 255), g: (i *= 255), b: (n *= 255), hex: e.rgb(t, i, n), toString: ty };
                                    return e.is(o, "finite") && (r.opacity = o), r;
                                };
                            (e.color = function (t) {
                                var i;
                                return (
                                    e.is(t, "object") && "h" in t && "s" in t && "b" in t
                                        ? ((i = e.hsb2rgb(t)), (t.r = i.r), (t.g = i.g), (t.b = i.b), (t.hex = i.hex))
                                        : e.is(t, "object") && "h" in t && "s" in t && "l" in t
                                            ? ((i = e.hsl2rgb(t)), (t.r = i.r), (t.g = i.g), (t.b = i.b), (t.hex = i.hex))
                                            : (e.is(t, "string") && (t = e.getRGB(t)),
                                                e.is(t, "object") && "r" in t && "g" in t && "b" in t
                                                    ? ((i = e.rgb2hsl(t)), (t.h = i.h), (t.s = i.s), (t.l = i.l), (i = e.rgb2hsb(t)), (t.v = i.b))
                                                    : ((t = { hex: "none" }).r = t.g = t.b = t.h = t.s = t.v = t.l = -1)),
                                        (t.toString = ty),
                                        t
                                );
                            }),
                                (e.hsb2rgb = function (t, e, i, n) {
                                    var o, r, a, s, u;
                                    return (
                                        this.is(t, "object") && "h" in t && "s" in t && "b" in t && ((i = t.b), (e = t.s), (n = t.o), (t = t.h)),
                                            (t *= 360),
                                            (t = (t % 360) / 60),
                                            (s = (u = i * e) * (1 - O((t % 2) - 1))),
                                            (o = r = a = i - u),
                                            tv((o += [u, s, 0, 0, s, u][(t = ~~t)]), (r += [s, u, u, s, 0, 0][t]), (a += [0, 0, s, u, u, s][t]), n)
                                    );
                                }),
                                (e.hsl2rgb = function (t, e, i, n) {
                                    var o, r, a, s, u;
                                    return (
                                        this.is(t, "object") && "h" in t && "s" in t && "l" in t && ((i = t.l), (e = t.s), (t = t.h)),
                                        (t > 1 || e > 1 || i > 1) && ((t /= 360), (e /= 100), (i /= 100)),
                                            (t *= 360),
                                            (t = (t % 360) / 60),
                                            (s = (u = 2 * e * (i < 0.5 ? i : 1 - i)) * (1 - O((t % 2) - 1))),
                                            (o = r = a = i - u / 2),
                                            tv((o += [u, s, 0, 0, s, u][(t = ~~t)]), (r += [s, u, u, s, 0, 0][t]), (a += [0, 0, s, u, u, s][t]), n)
                                    );
                                }),
                                (e.rgb2hsb = function (t, e, i) {
                                    var n, o, r, a;
                                    return {
                                        h: (n =
                                            ((((n = 0 == (a = (r = R((t = (i = t8(t, e, i))[0]), (e = i[1]), (i = i[2]))) - B(t, e, i)) ? null : r == t ? (e - i) / a : r == e ? (i - t) / a + 2 : (t - e) / a + 4) + 360) % 6) * 60) /
                                            360),
                                        s: (o = 0 == a ? 0 : a / r),
                                        b: r,
                                        toString: tg,
                                    };
                                }),
                                (e.rgb2hsl = function (t, e, i) {
                                    var n, o, r, a, s, u;
                                    return (
                                        (n =
                                            ((((n = 0 == (u = (a = R((t = (i = t8(t, e, i))[0]), (e = i[1]), (i = i[2]))) - (s = B(t, e, i))) ? null : a == t ? (e - i) / u : a == e ? (i - t) / u + 2 : (t - e) / u + 4) + 360) % 6) *
                                                60) /
                                            360),
                                            (r = (a + s) / 2),
                                            { h: n, s: (o = 0 == u ? 0 : r < 0.5 ? u / (2 * r) : u / (2 - 2 * r)), l: r, toString: tm }
                                    );
                                }),
                                (e._path2string = function () {
                                    return this.join(",").replace(te, "$1");
                                }),
                                (e._preload = function (t, e) {
                                    var i = b.doc.createElement("img");
                                    (i.style.cssText = "position:absolute;left:-9999em;top:-9999em"),
                                        (i.onload = function () {
                                            e.call(this), (this.onload = null), b.doc.body.removeChild(this);
                                        }),
                                        (i.onerror = function () {
                                            b.doc.body.removeChild(this);
                                        }),
                                        b.doc.body.appendChild(i),
                                        (i.src = t);
                                }),
                                (e.getRGB = n(function (t) {
                                    if (!t || (t = S(t)).indexOf("-") + 1) return { r: -1, g: -1, b: -1, hex: "none", error: 1, toString: o };
                                    if ("none" == t) return { r: -1, g: -1, b: -1, hex: "none", toString: o };
                                    tt[T](t.toLowerCase().substring(0, 2)) || "#" == t.charAt() || (t = tf(t));
                                    var i,
                                        n,
                                        r,
                                        a,
                                        s,
                                        u,
                                        l = t.match(W);
                                    return l
                                        ? (l[2] && ((r = j(l[2].substring(5), 16)), (n = j(l[2].substring(3, 5), 16)), (i = j(l[2].substring(1, 3), 16))),
                                        l[3] && ((r = j((s = l[3].charAt(3)) + s, 16)), (n = j((s = l[3].charAt(2)) + s, 16)), (i = j((s = l[3].charAt(1)) + s, 16))),
                                        l[4] &&
                                        ((i = Z((u = l[4][P](J))[0])),
                                        "%" == u[0].slice(-1) && (i *= 2.55),
                                            (n = Z(u[1])),
                                        "%" == u[1].slice(-1) && (n *= 2.55),
                                            (r = Z(u[2])),
                                        "%" == u[2].slice(-1) && (r *= 2.55),
                                        "rgba" == l[1].toLowerCase().slice(0, 4) && (a = Z(u[3])),
                                        u[3] && "%" == u[3].slice(-1) && (a /= 100)),
                                            l[5]
                                                ? ((i = Z((u = l[5][P](J))[0])),
                                                "%" == u[0].slice(-1) && (i *= 2.55),
                                                    (n = Z(u[1])),
                                                "%" == u[1].slice(-1) && (n *= 2.55),
                                                    (r = Z(u[2])),
                                                "%" == u[2].slice(-1) && (r *= 2.55),
                                                ("deg" == u[0].slice(-3) || "\xb0" == u[0].slice(-1)) && (i /= 360),
                                                "hsba" == l[1].toLowerCase().slice(0, 4) && (a = Z(u[3])),
                                                u[3] && "%" == u[3].slice(-1) && (a /= 100),
                                                    e.hsb2rgb(i, n, r, a))
                                                : l[6]
                                                    ? ((i = Z((u = l[6][P](J))[0])),
                                                    "%" == u[0].slice(-1) && (i *= 2.55),
                                                        (n = Z(u[1])),
                                                    "%" == u[1].slice(-1) && (n *= 2.55),
                                                        (r = Z(u[2])),
                                                    "%" == u[2].slice(-1) && (r *= 2.55),
                                                    ("deg" == u[0].slice(-3) || "\xb0" == u[0].slice(-1)) && (i /= 360),
                                                    "hsla" == l[1].toLowerCase().slice(0, 4) && (a = Z(u[3])),
                                                    u[3] && "%" == u[3].slice(-1) && (a /= 100),
                                                        e.hsl2rgb(i, n, r, a))
                                                    : (((l = { r: i, g: n, b: r, toString: o }).hex = "#" + (16777216 | r | (n << 8) | (i << 16)).toString(16).slice(1)), e.is(a, "finite") && (l.opacity = a), l))
                                        : { r: -1, g: -1, b: -1, hex: "none", error: 1, toString: o };
                                }, e)),
                                (e.hsb = n(function (t, i, n) {
                                    return e.hsb2rgb(t, i, n).hex;
                                })),
                                (e.hsl = n(function (t, i, n) {
                                    return e.hsl2rgb(t, i, n).hex;
                                })),
                                (e.rgb = n(function (t, e, i) {
                                    function n(t) {
                                        return (t + 0.5) | 0;
                                    }
                                    return "#" + (16777216 | n(i) | (n(e) << 8) | (n(t) << 16)).toString(16).slice(1);
                                })),
                                (e.getColor = function (t) {
                                    var e = (this.getColor.start = this.getColor.start || { h: 0, s: 1, b: t || 0.75 }),
                                        i = this.hsb2rgb(e.h, e.s, e.b);
                                    return (e.h += 0.075), e.h > 1 && ((e.h = 0), (e.s -= 0.2), e.s <= 0 && (this.getColor.start = { h: 0, s: 1, b: e.b })), i.hex;
                                }),
                                (e.getColor.reset = function () {
                                    delete this.start;
                                }),
                                (e.parsePathString = function (t) {
                                    if (!t) return null;
                                    var i = t$(t);
                                    if (i.arr) return tT(i.arr);
                                    var n = { a: 7, c: 6, h: 1, l: 2, m: 2, r: 4, q: 4, s: 4, t: 2, v: 1, z: 0 },
                                        o = [];
                                    return (
                                        e.is(t, V) && e.is(t[0], V) && (o = tT(t)),
                                        o.length ||
                                        S(t).replace(ti, function (t, e, i) {
                                            var r = [],
                                                a = e.toLowerCase();
                                            if (
                                                (i.replace(to, function (t, e) {
                                                    e && r.push(+e);
                                                }),
                                                "m" == a && r.length > 2 && (o.push([e][C](r.splice(0, 2))), (a = "l"), (e = "m" == e ? "l" : "L")),
                                                "r" == a)
                                            )
                                                o.push([e][C](r));
                                            else for (; r.length >= n[a] && (o.push([e][C](r.splice(0, n[a]))), n[a]); );
                                        }),
                                            (o.toString = e._path2string),
                                            (i.arr = tT(o)),
                                            o
                                    );
                                }),
                                (e.parseTransformString = n(function (t) {
                                    if (!t) return null;
                                    var i = [];
                                    return (
                                        e.is(t, V) && e.is(t[0], V) && (i = tT(t)),
                                        i.length ||
                                        S(t).replace(tn, function (t, e, n) {
                                            var o = [];
                                            k.call(e),
                                                n.replace(to, function (t, e) {
                                                    e && o.push(+e);
                                                }),
                                                i.push([e][C](o));
                                        }),
                                            (i.toString = e._path2string),
                                            i
                                    );
                                }));
                            var t$ = function (t) {
                                var e = (t$.ps = t$.ps || {});
                                return (
                                    e[t] ? (e[t].sleep = 100) : (e[t] = { sleep: 100 }),
                                        setTimeout(function () {
                                            for (var i in e) e[T](i) && i != t && (e[i].sleep--, e[i].sleep || delete e[i]);
                                        }),
                                        e[t]
                                );
                            };
                            (e.findDotsAtSegment = function (t, e, i, n, o, r, a, s, u) {
                                var l = 1 - u,
                                    h = N(l, 3),
                                    c = N(l, 2),
                                    p = u * u,
                                    d = p * u,
                                    f = t + 2 * u * (i - t) + p * (o - 2 * i + t),
                                    g = e + 2 * u * (n - e) + p * (r - 2 * n + e),
                                    m = i + 2 * u * (o - i) + p * (a - 2 * o + i),
                                    y = n + 2 * u * (r - n) + p * (s - 2 * r + n),
                                    v = 90 - (180 * I.atan2(f - m, g - y)) / F;
                                return (
                                    (f > m || g < y) && (v += 180),
                                        {
                                            x: h * t + 3 * c * u * i + 3 * l * u * u * o + d * a,
                                            y: h * e + 3 * c * u * n + 3 * l * u * u * r + d * s,
                                            m: { x: f, y: g },
                                            n: { x: m, y: y },
                                            start: { x: l * t + u * i, y: l * e + u * n },
                                            end: { x: l * o + u * a, y: l * r + u * s },
                                            alpha: v,
                                        }
                                );
                            }),
                                (e.bezierBBox = function (t, i, n, o, r, a, s, u) {
                                    e.is(t, "array") || (t = [t, i, n, o, r, a, s, u]);
                                    var l = tL.apply(null, t);
                                    return { x: l.min.x, y: l.min.y, x2: l.max.x, y2: l.max.y, width: l.max.x - l.min.x, height: l.max.y - l.min.y };
                                }),
                                (e.isPointInsideBBox = function (t, e, i) {
                                    return e >= t.x && e <= t.x2 && i >= t.y && i <= t.y2;
                                }),
                                (e.isBBoxIntersect = function (t, i) {
                                    var n = e.isPointInsideBBox;
                                    return (
                                        n(i, t.x, t.y) ||
                                        n(i, t.x2, t.y) ||
                                        n(i, t.x, t.y2) ||
                                        n(i, t.x2, t.y2) ||
                                        n(t, i.x, i.y) ||
                                        n(t, i.x2, i.y) ||
                                        n(t, i.x, i.y2) ||
                                        n(t, i.x2, i.y2) ||
                                        (((t.x < i.x2 && t.x > i.x) || (i.x < t.x2 && i.x > t.x)) && ((t.y < i.y2 && t.y > i.y) || (i.y < t.y2 && i.y > t.y)))
                                    );
                                }),
                                (e.pathIntersection = function (t, e) {
                                    return h(t, e);
                                }),
                                (e.pathIntersectionNumber = function (t, e) {
                                    return h(t, e, 1);
                                }),
                                (e.isPointInsidePath = function (t, i, n) {
                                    var o = e.pathBBox(t);
                                    return (
                                        e.isPointInsideBBox(o, i, n) &&
                                        h(
                                            t,
                                            [
                                                ["M", i, n],
                                                ["H", o.x2 + 10],
                                            ],
                                            1
                                        ) %
                                        2 ==
                                        1
                                    );
                                }),
                                (e._removedFactory = function (e) {
                                    return function () {
                                        t("raphael.log", null, "Rapha\xebl: you are calling to method “" + e + "” of removed object", e);
                                    };
                                });
                            var tx = (e.pathBBox = function (t) {
                                    var e = t$(t);
                                    if (e.bbox) return i(e.bbox);
                                    if (!t) return { x: 0, y: 0, width: 0, height: 0, x2: 0, y2: 0 };
                                    t = tS(t);
                                    for (var n, o = 0, r = 0, a = [], s = [], u = 0, l = t.length; u < l; u++)
                                        if ("M" == (n = t[u])[0]) (o = n[1]), (r = n[2]), a.push(o), s.push(r);
                                        else {
                                            var h = tL(o, r, n[1], n[2], n[3], n[4], n[5], n[6]);
                                            (a = a[C](h.min.x, h.max.x)), (s = s[C](h.min.y, h.max.y)), (o = n[5]), (r = n[6]);
                                        }
                                    var c = B[D](0, a),
                                        p = B[D](0, s),
                                        d = R[D](0, a),
                                        f = R[D](0, s),
                                        g = d - c,
                                        m = f - p,
                                        y = { x: c, y: p, x2: d, y2: f, width: g, height: m, cx: c + g / 2, cy: p + m / 2 };
                                    return (e.bbox = i(y)), y;
                                }),
                                tT = function (t) {
                                    var n = i(t);
                                    return (n.toString = e._path2string), n;
                                },
                                tb = (e._pathToRelative = function (t) {
                                    var i = t$(t);
                                    if (i.rel) return tT(i.rel);
                                    (e.is(t, V) && e.is(t && t[0], V)) || (t = e.parsePathString(t));
                                    var n = [],
                                        o = 0,
                                        r = 0,
                                        a = 0,
                                        s = 0,
                                        u = 0;
                                    "M" == t[0][0] && ((o = t[0][1]), (r = t[0][2]), (a = o), (s = r), u++, n.push(["M", o, r]));
                                    for (var l = u, h = t.length; l < h; l++) {
                                        var c = (n[l] = []),
                                            p = t[l];
                                        if (p[0] != k.call(p[0]))
                                            switch (((c[0] = k.call(p[0])), c[0])) {
                                                case "a":
                                                    (c[1] = p[1]), (c[2] = p[2]), (c[3] = p[3]), (c[4] = p[4]), (c[5] = p[5]), (c[6] = +(p[6] - o).toFixed(3)), (c[7] = +(p[7] - r).toFixed(3));
                                                    break;
                                                case "v":
                                                    c[1] = +(p[1] - r).toFixed(3);
                                                    break;
                                                case "m":
                                                    (a = p[1]), (s = p[2]);
                                                default:
                                                    for (var d = 1, f = p.length; d < f; d++) c[d] = +(p[d] - (d % 2 ? o : r)).toFixed(3);
                                            }
                                        else {
                                            (c = n[l] = []), "m" == p[0] && ((a = p[1] + o), (s = p[2] + r));
                                            for (var g = 0, m = p.length; g < m; g++) n[l][g] = p[g];
                                        }
                                        var y = n[l].length;
                                        switch (n[l][0]) {
                                            case "z":
                                                (o = a), (r = s);
                                                break;
                                            case "h":
                                                o += +n[l][y - 1];
                                                break;
                                            case "v":
                                                r += +n[l][y - 1];
                                                break;
                                            default:
                                                (o += +n[l][y - 2]), (r += +n[l][y - 1]);
                                        }
                                    }
                                    return (n.toString = e._path2string), (i.rel = tT(n)), n;
                                }),
                                t_ = (e._pathToAbsolute = function (t) {
                                    var i = t$(t);
                                    if (i.abs) return tT(i.abs);
                                    if (((e.is(t, V) && e.is(t && t[0], V)) || (t = e.parsePathString(t)), !t || !t.length)) return [["M", 0, 0]];
                                    var n = [],
                                        o = 0,
                                        a = 0,
                                        s = 0,
                                        u = 0,
                                        l = 0;
                                    "M" == t[0][0] && ((o = +t[0][1]), (a = +t[0][2]), (s = o), (u = a), l++, (n[0] = ["M", o, a]));
                                    for (var h, c, p = 3 == t.length && "M" == t[0][0] && "R" == t[1][0].toUpperCase() && "Z" == t[2][0].toUpperCase(), d = l, f = t.length; d < f; d++) {
                                        if ((n.push((h = [])), (c = t[d])[0] != q.call(c[0])))
                                            switch (((h[0] = q.call(c[0])), h[0])) {
                                                case "A":
                                                    (h[1] = c[1]), (h[2] = c[2]), (h[3] = c[3]), (h[4] = c[4]), (h[5] = c[5]), (h[6] = +(c[6] + o)), (h[7] = +(c[7] + a));
                                                    break;
                                                case "V":
                                                    h[1] = +c[1] + a;
                                                    break;
                                                case "H":
                                                    h[1] = +c[1] + o;
                                                    break;
                                                case "R":
                                                    for (var g = [o, a][C](c.slice(1)), m = 2, y = g.length; m < y; m++) (g[m] = +g[m] + o), (g[++m] = +g[m] + a);
                                                    n.pop(), (n = n[C](r(g, p)));
                                                    break;
                                                case "M":
                                                    (s = +c[1] + o), (u = +c[2] + a);
                                                default:
                                                    for (m = 1, y = c.length; m < y; m++) h[m] = +c[m] + (m % 2 ? o : a);
                                            }
                                        else if ("R" == c[0]) (g = [o, a][C](c.slice(1))), n.pop(), (n = n[C](r(g, p))), (h = ["R"][C](c.slice(-2)));
                                        else for (var v = 0, $ = c.length; v < $; v++) h[v] = c[v];
                                        switch (h[0]) {
                                            case "Z":
                                                (o = s), (a = u);
                                                break;
                                            case "H":
                                                o = h[1];
                                                break;
                                            case "V":
                                                a = h[1];
                                                break;
                                            case "M":
                                                (s = h[h.length - 2]), (u = h[h.length - 1]);
                                            default:
                                                (o = h[h.length - 2]), (a = h[h.length - 1]);
                                        }
                                    }
                                    return (n.toString = e._path2string), (i.abs = tT(n)), n;
                                }),
                                tA = function (t, e, i, n) {
                                    return [t, e, i, n, i, n];
                                },
                                tD = function (t, e, i, n, o, r) {
                                    var a = 1 / 3,
                                        s = 2 / 3;
                                    return [a * t + s * i, a * e + s * n, a * o + s * i, a * r + s * n, o, r];
                                },
                                tC = function (t, e, i, o, r, a, s, u, l, h) {
                                    var c,
                                        p = (120 * F) / 180,
                                        d = (F / 180) * (+r || 0),
                                        f = [],
                                        g = n(function (t, e, i) {
                                            var n = t * I.cos(i) - e * I.sin(i),
                                                o = t * I.sin(i) + e * I.cos(i);
                                            return { x: n, y: o };
                                        });
                                    if (h) (A = h[0]), (D = h[1]), (b = h[2]), (_ = h[3]);
                                    else {
                                        (t = (c = g(t, e, -d)).x), (e = c.y), (u = (c = g(u, l, -d)).x), (l = c.y);
                                        var m = (I.cos((F / 180) * r), I.sin((F / 180) * r), (t - u) / 2),
                                            y = (e - l) / 2,
                                            v = (m * m) / (i * i) + (y * y) / (o * o);
                                        v > 1 && ((i *= v = I.sqrt(v)), (o *= v));
                                        var $ = i * i,
                                            x = o * o,
                                            T = (a == s ? -1 : 1) * I.sqrt(O(($ * x - $ * y * y - x * m * m) / ($ * y * y + x * m * m))),
                                            b = (T * i * y) / o + (t + u) / 2,
                                            _ = (-(T * o) * m) / i + (e + l) / 2,
                                            A = I.asin(((e - _) / o).toFixed(9)),
                                            D = I.asin(((l - _) / o).toFixed(9));
                                        (A = t < b ? F - A : A), (D = u < b ? F - D : D), A < 0 && (A = 2 * F + A), D < 0 && (D = 2 * F + D), s && A > D && (A -= 2 * F), !s && D > A && (D -= 2 * F);
                                    }
                                    var E = D - A;
                                    if (O(E) > p) {
                                        var L = D,
                                            S = u,
                                            M = l;
                                        (D = A + p * (s && D > A ? 1 : -1)), (f = tC((u = b + i * I.cos(D)), (l = _ + o * I.sin(D)), i, o, r, 0, s, S, M, [D, L, b, _]));
                                    }
                                    E = D - A;
                                    var w = I.cos(A),
                                        k = I.sin(A),
                                        R = I.cos(D),
                                        B = I.sin(D),
                                        N = I.tan(E / 4),
                                        G = (4 / 3) * i * N,
                                        H = (4 / 3) * o * N,
                                        V = [t, e],
                                        z = [t + G * k, e - H * w],
                                        W = [u + G * B, l - H * R],
                                        Y = [u, l];
                                    if (((z[0] = 2 * V[0] - z[0]), (z[1] = 2 * V[1] - z[1]), h)) return [z, W, Y][C](f);
                                    f = [z, W, Y][C](f).join()[P](",");
                                    for (var U = [], X = 0, Z = f.length; X < Z; X++) U[X] = X % 2 ? g(f[X - 1], f[X], d).y : g(f[X], f[X + 1], d).x;
                                    return U;
                                },
                                tE = function (t, e, i, n, o, r, a, s, u) {
                                    var l = 1 - u;
                                    return { x: N(l, 3) * t + 3 * N(l, 2) * u * i + 3 * l * u * u * o + N(u, 3) * a, y: N(l, 3) * e + 3 * N(l, 2) * u * n + 3 * l * u * u * r + N(u, 3) * s };
                                },
                                tL = n(function (t, e, i, n, o, r, a, s) {
                                    var u,
                                        l = o - 2 * i + t - (a - 2 * o + i),
                                        h = 2 * (i - t) - 2 * (o - i),
                                        c = t - i,
                                        p = (-h + I.sqrt(h * h - 4 * l * c)) / 2 / l,
                                        d = (-h - I.sqrt(h * h - 4 * l * c)) / 2 / l,
                                        f = [e, s],
                                        g = [t, a];
                                    return (
                                        O(p) > "1e12" && (p = 0.5),
                                        O(d) > "1e12" && (d = 0.5),
                                        p > 0 && p < 1 && ((u = tE(t, e, i, n, o, r, a, s, p)), g.push(u.x), f.push(u.y)),
                                        d > 0 && d < 1 && ((u = tE(t, e, i, n, o, r, a, s, d)), g.push(u.x), f.push(u.y)),
                                            (l = r - 2 * n + e - (s - 2 * r + n)),
                                            (h = 2 * (n - e) - 2 * (r - n)),
                                            (c = e - n),
                                            (p = (-h + I.sqrt(h * h - 4 * l * c)) / 2 / l),
                                            (d = (-h - I.sqrt(h * h - 4 * l * c)) / 2 / l),
                                        O(p) > "1e12" && (p = 0.5),
                                        O(d) > "1e12" && (d = 0.5),
                                        p > 0 && p < 1 && ((u = tE(t, e, i, n, o, r, a, s, p)), g.push(u.x), f.push(u.y)),
                                        d > 0 && d < 1 && ((u = tE(t, e, i, n, o, r, a, s, d)), g.push(u.x), f.push(u.y)),
                                            { min: { x: B[D](0, g), y: B[D](0, f) }, max: { x: R[D](0, g), y: R[D](0, f) } }
                                    );
                                }),
                                tS = (e._path2curve = n(
                                    function (t, e) {
                                        var i = !e && t$(t);
                                        if (!e && i.curve) return tT(i.curve);
                                        for (
                                            var n = t_(t),
                                                o = e && t_(e),
                                                r = { x: 0, y: 0, bx: 0, by: 0, X: 0, Y: 0, qx: null, qy: null },
                                                a = { x: 0, y: 0, bx: 0, by: 0, X: 0, Y: 0, qx: null, qy: null },
                                                s = function (t, e, i) {
                                                    var n, o;
                                                    if (!t) return ["C", e.x, e.y, e.x, e.y, e.x, e.y];
                                                    switch (((t[0] in { T: 1, Q: 1 }) || (e.qx = e.qy = null), t[0])) {
                                                        case "M":
                                                            (e.X = t[1]), (e.Y = t[2]);
                                                            break;
                                                        case "A":
                                                            t = ["C"][C](tC[D](0, [e.x, e.y][C](t.slice(1))));
                                                            break;
                                                        case "S":
                                                            "C" == i || "S" == i ? ((n = 2 * e.x - e.bx), (o = 2 * e.y - e.by)) : ((n = e.x), (o = e.y)), (t = ["C", n, o][C](t.slice(1)));
                                                            break;
                                                        case "T":
                                                            "Q" == i || "T" == i ? ((e.qx = 2 * e.x - e.qx), (e.qy = 2 * e.y - e.qy)) : ((e.qx = e.x), (e.qy = e.y)), (t = ["C"][C](tD(e.x, e.y, e.qx, e.qy, t[1], t[2])));
                                                            break;
                                                        case "Q":
                                                            (e.qx = t[1]), (e.qy = t[2]), (t = ["C"][C](tD(e.x, e.y, t[1], t[2], t[3], t[4])));
                                                            break;
                                                        case "L":
                                                            t = ["C"][C](tA(e.x, e.y, t[1], t[2]));
                                                            break;
                                                        case "H":
                                                            t = ["C"][C](tA(e.x, e.y, t[1], e.y));
                                                            break;
                                                        case "V":
                                                            t = ["C"][C](tA(e.x, e.y, e.x, t[1]));
                                                            break;
                                                        case "Z":
                                                            t = ["C"][C](tA(e.x, e.y, e.X, e.Y));
                                                    }
                                                    return t;
                                                },
                                                u = function (t, e) {
                                                    if (t[e].length > 7) {
                                                        t[e].shift();
                                                        for (var i = t[e]; i.length; ) (h[e] = "A"), o && (c[e] = "A"), t.splice(e++, 0, ["C"][C](i.splice(0, 6)));
                                                        t.splice(e, 1), (g = R(n.length, (o && o.length) || 0));
                                                    }
                                                },
                                                l = function (t, e, i, r, a) {
                                                    t && e && "M" == t[a][0] && "M" != e[a][0] && (e.splice(a, 0, ["M", r.x, r.y]), (i.bx = 0), (i.by = 0), (i.x = t[a][1]), (i.y = t[a][2]), (g = R(n.length, (o && o.length) || 0)));
                                                },
                                                h = [],
                                                c = [],
                                                p = "",
                                                d = "",
                                                f = 0,
                                                g = R(n.length, (o && o.length) || 0);
                                            f < g;
                                            f++
                                        ) {
                                            n[f] && (p = n[f][0]),
                                            "C" != p && ((h[f] = p), f && (d = h[f - 1])),
                                                (n[f] = s(n[f], r, d)),
                                            "A" != h[f] && "C" == p && (h[f] = "C"),
                                                u(n, f),
                                            o && (o[f] && (p = o[f][0]), "C" != p && ((c[f] = p), f && (d = c[f - 1])), (o[f] = s(o[f], a, d)), "A" != c[f] && "C" == p && (c[f] = "C"), u(o, f)),
                                                l(n, o, r, a, f),
                                                l(o, n, a, r, f);
                                            var m = n[f],
                                                y = o && o[f],
                                                v = m.length,
                                                $ = o && y.length;
                                            (r.x = m[v - 2]),
                                                (r.y = m[v - 1]),
                                                (r.bx = Z(m[v - 4]) || r.x),
                                                (r.by = Z(m[v - 3]) || r.y),
                                                (a.bx = o && (Z(y[$ - 4]) || a.x)),
                                                (a.by = o && (Z(y[$ - 3]) || a.y)),
                                                (a.x = o && y[$ - 2]),
                                                (a.y = o && y[$ - 1]);
                                        }
                                        return o || (i.curve = tT(n)), o ? [n, o] : n;
                                    },
                                    null,
                                    tT
                                )),
                                tP =
                                    ((e._parseDots = n(function (t) {
                                        for (var i = [], n = 0, o = t.length; n < o; n++) {
                                            var r = {},
                                                a = t[n].match(/^([^:]*):?([\d\.]*)/);
                                            if (((r.color = e.getRGB(a[1])), r.color.error)) return null;
                                            (r.opacity = r.color.opacity), (r.color = r.color.hex), a[2] && (r.offset = a[2] + "%"), i.push(r);
                                        }
                                        for (n = 1, o = i.length - 1; n < o; n++)
                                            if (!i[n].offset) {
                                                for (var s = Z(i[n - 1].offset || 0), u = 0, l = n + 1; l < o; l++)
                                                    if (i[l].offset) {
                                                        u = i[l].offset;
                                                        break;
                                                    }
                                                u || ((u = 100), (l = o)), (u = Z(u));
                                                for (var h = (u - s) / (l - n + 1); n < l; n++) (s += h), (i[n].offset = s + "%");
                                            }
                                        return i;
                                    })),
                                        (e._tear = function (t, e) {
                                            t == e.top && (e.top = t.prev), t == e.bottom && (e.bottom = t.next), t.next && (t.next.prev = t.prev), t.prev && (t.prev.next = t.next);
                                        })),
                                tM =
                                    ((e._tofront = function (t, e) {
                                        e.top !== t && (tP(t, e), (t.next = null), (t.prev = e.top), (e.top.next = t), (e.top = t));
                                    }),
                                        (e._toback = function (t, e) {
                                            e.bottom !== t && (tP(t, e), (t.next = e.bottom), (t.prev = null), (e.bottom.prev = t), (e.bottom = t));
                                        }),
                                        (e._insertafter = function (t, e, i) {
                                            tP(t, i), e == i.top && (i.top = t), e.next && (e.next.prev = t), (t.next = e.next), (t.prev = e), (e.next = t);
                                        }),
                                        (e._insertbefore = function (t, e, i) {
                                            tP(t, i), e == i.bottom && (i.bottom = t), e.prev && (e.prev.next = t), (t.prev = e.prev), (e.prev = t), (t.next = e);
                                        }),
                                        (e.toMatrix = function (t, e) {
                                            var i = tx(t),
                                                n = {
                                                    _: { transform: "" },
                                                    getBBox: function () {
                                                        return i;
                                                    },
                                                };
                                            return tw(n, e), n.matrix;
                                        })),
                                tw =
                                    ((e.transformPath = function (t, e) {
                                        return tc(t, tM(t, e));
                                    }),
                                        (e._extractTransform = function (t, i) {
                                            if (null == i) return t._.transform;
                                            i = S(i).replace(/\.{3}|\u2026/g, t._.transform || "");
                                            var n = e.parseTransformString(i),
                                                o = 0,
                                                r = 0,
                                                a = 0,
                                                s = 1,
                                                u = 1,
                                                l = t._,
                                                h = new c();
                                            if (((l.transform = n || []), n))
                                                for (var p = 0, d = n.length; p < d; p++) {
                                                    var f,
                                                        g,
                                                        m,
                                                        y,
                                                        v,
                                                        $ = n[p],
                                                        x = $.length,
                                                        T = S($[0]).toLowerCase(),
                                                        b = $[0] != T,
                                                        _ = b ? h.invert() : 0;
                                                    "t" == T && 3 == x
                                                        ? b
                                                            ? ((f = _.x(0, 0)), (g = _.y(0, 0)), (m = _.x($[1], $[2])), (y = _.y($[1], $[2])), h.translate(m - f, y - g))
                                                            : h.translate($[1], $[2])
                                                        : "r" == T
                                                            ? 2 == x
                                                                ? ((v = v || t.getBBox(1)), h.rotate($[1], v.x + v.width / 2, v.y + v.height / 2), (o += $[1]))
                                                                : 4 == x && (b ? ((m = _.x($[2], $[3])), (y = _.y($[2], $[3])), h.rotate($[1], m, y)) : h.rotate($[1], $[2], $[3]), (o += $[1]))
                                                            : "s" == T
                                                                ? 2 == x || 3 == x
                                                                    ? ((v = v || t.getBBox(1)), h.scale($[1], $[x - 1], v.x + v.width / 2, v.y + v.height / 2), (s *= $[1]), (u *= $[x - 1]))
                                                                    : 5 == x && (b ? ((m = _.x($[3], $[4])), (y = _.y($[3], $[4])), h.scale($[1], $[2], m, y)) : h.scale($[1], $[2], $[3], $[4]), (s *= $[1]), (u *= $[2]))
                                                                : "m" == T && 7 == x && h.add($[1], $[2], $[3], $[4], $[5], $[6]),
                                                        (l.dirtyT = 1),
                                                        (t.matrix = h);
                                                }
                                            (t.matrix = h), (l.sx = s), (l.sy = u), (l.deg = o), (l.dx = r = h.e), (l.dy = a = h.f), 1 == s && 1 == u && !o && l.bbox ? ((l.bbox.x += +r), (l.bbox.y += +a)) : (l.dirtyT = 1);
                                        })),
                                tk = function (t) {
                                    var e = t[0];
                                    switch (e.toLowerCase()) {
                                        case "t":
                                            return [e, 0, 0];
                                        case "m":
                                            return [e, 1, 0, 0, 1, 0, 0];
                                        case "r":
                                            return 4 == t.length ? [e, 0, t[2], t[3]] : [e, 0];
                                        case "s":
                                            return 5 == t.length ? [e, 1, 1, t[3], t[4]] : 3 == t.length ? [e, 1, 1] : [e, 1];
                                    }
                                },
                                tI = (e._equaliseTransform = function (t, i) {
                                    (i = S(i).replace(/\.{3}|\u2026/g, t)), (t = e.parseTransformString(t) || []), (i = e.parseTransformString(i) || []);
                                    for (var n, o, r, a, s = R(t.length, i.length), u = [], l = [], h = 0; h < s; h++) {
                                        if (((r = t[h] || tk(i[h])), (a = i[h] || tk(r)), r[0] != a[0] || ("r" == r[0].toLowerCase() && (r[2] != a[2] || r[3] != a[3])) || ("s" == r[0].toLowerCase() && (r[3] != a[3] || r[4] != a[4]))))
                                            return;
                                        for (u[h] = [], l[h] = [], n = 0, o = R(r.length, a.length); n < o; n++) n in r && (u[h][n] = r[n]), n in a && (l[h][n] = a[n]);
                                    }
                                    return { from: u, to: l };
                                });
                            (e._getContainer = function (t, i, n, o) {
                                var r;
                                if (null != (r = null != o || e.is(t, "object") ? t : b.doc.getElementById(t)))
                                    return r.tagName
                                        ? null == i
                                            ? { container: r, width: r.style.pixelWidth || r.offsetWidth, height: r.style.pixelHeight || r.offsetHeight }
                                            : { container: r, width: i, height: n }
                                        : { container: 1, x: t, y: i, width: n, height: o };
                            }),
                                (e.pathToRelative = tb),
                                (e._engine = {}),
                                (e.path2curve = tS),
                                (e.matrix = function (t, e, i, n, o, r) {
                                    return new c(t, e, i, n, o, r);
                                }),
                                (function (t) {
                                    function i(t) {
                                        return t[0] * t[0] + t[1] * t[1];
                                    }
                                    function n(t) {
                                        var e = I.sqrt(i(t));
                                        t[0] && (t[0] /= e), t[1] && (t[1] /= e);
                                    }
                                    (t.add = function (t, e, i, n, o, r) {
                                        var a,
                                            s,
                                            u,
                                            l,
                                            h = [[], [], []],
                                            p = [
                                                [this.a, this.c, this.e],
                                                [this.b, this.d, this.f],
                                                [0, 0, 1],
                                            ],
                                            d = [
                                                [t, i, o],
                                                [e, n, r],
                                                [0, 0, 1],
                                            ];
                                        for (
                                            t &&
                                            t instanceof c &&
                                            (d = [
                                                [t.a, t.c, t.e],
                                                [t.b, t.d, t.f],
                                                [0, 0, 1],
                                            ]),
                                                a = 0;
                                            a < 3;
                                            a++
                                        )
                                            for (s = 0; s < 3; s++) {
                                                for (l = 0, u = 0; u < 3; u++) l += p[a][u] * d[u][s];
                                                h[a][s] = l;
                                            }
                                        (this.a = h[0][0]), (this.b = h[1][0]), (this.c = h[0][1]), (this.d = h[1][1]), (this.e = h[0][2]), (this.f = h[1][2]);
                                    }),
                                        (t.invert = function () {
                                            var t = this.a * this.d - this.b * this.c;
                                            return new c(this.d / t, -this.b / t, -this.c / t, this.a / t, (this.c * this.f - this.d * this.e) / t, (this.b * this.e - this.a * this.f) / t);
                                        }),
                                        (t.clone = function () {
                                            return new c(this.a, this.b, this.c, this.d, this.e, this.f);
                                        }),
                                        (t.translate = function (t, e) {
                                            this.add(1, 0, 0, 1, t, e);
                                        }),
                                        (t.scale = function (t, e, i, n) {
                                            null == e && (e = t), (i || n) && this.add(1, 0, 0, 1, i, n), this.add(t, 0, 0, e, 0, 0), (i || n) && this.add(1, 0, 0, 1, -i, -n);
                                        }),
                                        (t.rotate = function (t, i, n) {
                                            (t = e.rad(t)), (i = i || 0), (n = n || 0);
                                            var o = +I.cos(t).toFixed(9),
                                                r = +I.sin(t).toFixed(9);
                                            this.add(o, r, -r, o, i, n), this.add(1, 0, 0, 1, -i, -n);
                                        }),
                                        (t.x = function (t, e) {
                                            return t * this.a + e * this.c + this.e;
                                        }),
                                        (t.y = function (t, e) {
                                            return t * this.b + e * this.d + this.f;
                                        }),
                                        (t.get = function (t) {
                                            return +this[S.fromCharCode(97 + t)].toFixed(4);
                                        }),
                                        (t.toString = function () {
                                            return e.svg ? "matrix(" + [this.get(0), this.get(1), this.get(2), this.get(3), this.get(4), this.get(5)].join() + ")" : [this.get(0), this.get(2), this.get(1), this.get(3), 0, 0].join();
                                        }),
                                        (t.toFilter = function () {
                                            return (
                                                "progid:DXImageTransform.Microsoft.Matrix(M11=" +
                                                this.get(0) +
                                                ", M12=" +
                                                this.get(2) +
                                                ", M21=" +
                                                this.get(1) +
                                                ", M22=" +
                                                this.get(3) +
                                                ", Dx=" +
                                                this.get(4) +
                                                ", Dy=" +
                                                this.get(5) +
                                                ", sizingmethod='auto expand')"
                                            );
                                        }),
                                        (t.offset = function () {
                                            return [this.e.toFixed(4), this.f.toFixed(4)];
                                        }),
                                        (t.split = function () {
                                            var t = {};
                                            (t.dx = this.e), (t.dy = this.f);
                                            var o = [
                                                [this.a, this.c],
                                                [this.b, this.d],
                                            ];
                                            (t.scalex = I.sqrt(i(o[0]))),
                                                n(o[0]),
                                                (t.shear = o[0][0] * o[1][0] + o[0][1] * o[1][1]),
                                                (o[1] = [o[1][0] - o[0][0] * t.shear, o[1][1] - o[0][1] * t.shear]),
                                                (t.scaley = I.sqrt(i(o[1]))),
                                                n(o[1]),
                                                (t.shear /= t.scaley);
                                            var r = -o[0][1],
                                                a = o[1][1];
                                            return (
                                                a < 0 ? ((t.rotate = e.deg(I.acos(a))), r < 0 && (t.rotate = 360 - t.rotate)) : (t.rotate = e.deg(I.asin(r))),
                                                    (t.isSimple = !(+t.shear.toFixed(9) || (t.scalex.toFixed(9) != t.scaley.toFixed(9) && t.rotate))),
                                                    (t.isSuperSimple = !+t.shear.toFixed(9) && t.scalex.toFixed(9) == t.scaley.toFixed(9) && !t.rotate),
                                                    (t.noRotation = !+t.shear.toFixed(9) && !t.rotate),
                                                    t
                                            );
                                        }),
                                        (t.toTransformString = function (t) {
                                            var e = t || this[P]();
                                            return e.isSimple
                                                ? ((e.scalex = +e.scalex.toFixed(4)),
                                                    (e.scaley = +e.scaley.toFixed(4)),
                                                    (e.rotate = +e.rotate.toFixed(4)),
                                                (e.dx || e.dy ? "t" + [e.dx, e.dy] : "") + (1 != e.scalex || 1 != e.scaley ? "s" + [e.scalex, e.scaley, 0, 0] : "") + (e.rotate ? "r" + [e.rotate, 0, 0] : ""))
                                                : "m" + [this.get(0), this.get(1), this.get(2), this.get(3), this.get(4), this.get(5)];
                                        });
                                })(c.prototype);
                            for (
                                var tR = function () {
                                        this.returnValue = !1;
                                    },
                                    t0 = function () {
                                        return this.originalEvent.preventDefault();
                                    },
                                    tB = function () {
                                        this.cancelBubble = !0;
                                    },
                                    tO = function () {
                                        return this.originalEvent.stopPropagation();
                                    },
                                    tN = function (t) {
                                        var e = b.doc.documentElement.scrollTop || b.doc.body.scrollTop,
                                            i = b.doc.documentElement.scrollLeft || b.doc.body.scrollLeft;
                                        return { x: t.clientX + i, y: t.clientY + e };
                                    },
                                    tF = b.doc.addEventListener
                                        ? function (t, e, i, n) {
                                            var o = function (t) {
                                                var e = tN(t);
                                                return i.call(n, t, e.x, e.y);
                                            };
                                            if ((t.addEventListener(e, o, !1), E && w[e])) {
                                                var r = function (e) {
                                                    for (var o = tN(e), r = e, a = 0, s = e.targetTouches && e.targetTouches.length; a < s; a++)
                                                        if (e.targetTouches[a].target == t) {
                                                            ((e = e.targetTouches[a]).originalEvent = r), (e.preventDefault = t0), (e.stopPropagation = tO);
                                                            break;
                                                        }
                                                    return i.call(n, e, o.x, o.y);
                                                };
                                                t.addEventListener(w[e], r, !1);
                                            }
                                            return function () {
                                                return t.removeEventListener(e, o, !1), E && w[e] && t.removeEventListener(w[e], r, !1), !0;
                                            };
                                        }
                                        : b.doc.attachEvent
                                            ? function (t, e, i, n) {
                                                var o = function (t) {
                                                    t = t || b.win.event;
                                                    var e = b.doc.documentElement.scrollTop || b.doc.body.scrollTop,
                                                        o = b.doc.documentElement.scrollLeft || b.doc.body.scrollLeft,
                                                        r = t.clientX + o,
                                                        a = t.clientY + e;
                                                    return (t.preventDefault = t.preventDefault || tR), (t.stopPropagation = t.stopPropagation || tB), i.call(n, t, r, a);
                                                };
                                                return (
                                                    t.attachEvent("on" + e, o),
                                                        function () {
                                                            return t.detachEvent("on" + e, o), !0;
                                                        }
                                                );
                                            }
                                            : void 0,
                                    t3 = [],
                                    tG = function (e) {
                                        for (var i, n = e.clientX, o = e.clientY, r = b.doc.documentElement.scrollTop || b.doc.body.scrollTop, a = b.doc.documentElement.scrollLeft || b.doc.body.scrollLeft, s = t3.length; s--; ) {
                                            if (((i = t3[s]), E && e.touches)) {
                                                for (var u, l = e.touches.length; l--; )
                                                    if ((u = e.touches[l]).identifier == i.el._drag.id) {
                                                        (n = u.clientX), (o = u.clientY), (e.originalEvent ? e.originalEvent : e).preventDefault();
                                                        break;
                                                    }
                                            } else e.preventDefault();
                                            var h,
                                                c = i.el.node,
                                                p = c.nextSibling,
                                                d = c.parentNode,
                                                f = c.style.display;
                                            b.win.opera && d.removeChild(c),
                                                (c.style.display = "none"),
                                                (h = i.el.paper.getElementByPoint(n, o)),
                                                (c.style.display = f),
                                            b.win.opera && (p ? d.insertBefore(c, p) : d.appendChild(c)),
                                            h && t("raphael.drag.over." + i.el.id, i.el, h),
                                                (n += a),
                                                (o += r),
                                                t("raphael.drag.move." + i.el.id, i.move_scope || i.el, n - i.el._drag.x, o - i.el._drag.y, n, o, e);
                                        }
                                    },
                                    tH = function (i) {
                                        e.unmousemove(tG).unmouseup(tH);
                                        for (var n, o = t3.length; o--; ) ((n = t3[o]).el._drag = {}), t("raphael.drag.end." + n.el.id, n.end_scope || n.start_scope || n.move_scope || n.el, i);
                                        t3 = [];
                                    },
                                    t2 = (e.el = {}),
                                    t1 = M.length;
                                t1--;

                            )
                                !(function (t) {
                                    (e[t] = t2[t] = function (i, n) {
                                        return e.is(i, "function") && ((this.events = this.events || []), this.events.push({ name: t, f: i, unbind: tF(this.shape || this.node || b.doc, t, i, n || this) })), this;
                                    }),
                                        (e["un" + t] = t2["un" + t] = function (i) {
                                            for (var n = this.events || [], o = n.length; o--; ) n[o].name == t && (e.is(i, "undefined") || n[o].f == i) && (n[o].unbind(), n.splice(o, 1), n.length || delete this.events);
                                            return this;
                                        });
                                })(M[t1]);
                            (t2.data = function (i, n) {
                                var o = (tr[this.id] = tr[this.id] || {});
                                if (0 == arguments.length) return o;
                                if (1 == arguments.length) {
                                    if (e.is(i, "object")) {
                                        for (var r in i) i[T](r) && this.data(r, i[r]);
                                        return this;
                                    }
                                    return t("raphael.data.get." + this.id, this, o[i], i), o[i];
                                }
                                return (o[i] = n), t("raphael.data.set." + this.id, this, n, i), this;
                            }),
                                (t2.removeData = function (t) {
                                    return null == t ? (tr[this.id] = {}) : tr[this.id] && delete tr[this.id][t], this;
                                }),
                                (t2.getData = function () {
                                    return i(tr[this.id] || {});
                                }),
                                (t2.hover = function (t, e, i, n) {
                                    return this.mouseover(t, i).mouseout(e, n || i);
                                }),
                                (t2.unhover = function (t, e) {
                                    return this.unmouseover(t).unmouseout(e);
                                });
                            var tV = [];
                            (t2.drag = function (i, n, o, r, a, s) {
                                function u(u) {
                                    (u.originalEvent || u).preventDefault();
                                    var l = u.clientX,
                                        h = u.clientY,
                                        c = b.doc.documentElement.scrollTop || b.doc.body.scrollTop,
                                        p = b.doc.documentElement.scrollLeft || b.doc.body.scrollLeft;
                                    if (((this._drag.id = u.identifier), E && u.touches)) {
                                        for (var d, f = u.touches.length; f--; )
                                            if (((d = u.touches[f]), (this._drag.id = d.identifier), d.identifier == this._drag.id)) {
                                                (l = d.clientX), (h = d.clientY);
                                                break;
                                            }
                                    }
                                    (this._drag.x = l + p),
                                        (this._drag.y = h + c),
                                    t3.length || e.mousemove(tG).mouseup(tH),
                                        t3.push({ el: this, move_scope: r, start_scope: a, end_scope: s }),
                                    n && t.on("raphael.drag.start." + this.id, n),
                                    i && t.on("raphael.drag.move." + this.id, i),
                                    o && t.on("raphael.drag.end." + this.id, o),
                                        t("raphael.drag.start." + this.id, a || r || this, u.clientX + p, u.clientY + c, u);
                                }
                                return (this._drag = {}), tV.push({ el: this, start: u }), this.mousedown(u), this;
                            }),
                                (t2.onDragOver = function (e) {
                                    e ? t.on("raphael.drag.over." + this.id, e) : t.unbind("raphael.drag.over." + this.id);
                                }),
                                (t2.undrag = function () {
                                    for (var i = tV.length; i--; ) tV[i].el == this && (this.unmousedown(tV[i].start), tV.splice(i, 1), t.unbind("raphael.drag.*." + this.id));
                                    tV.length || e.unmousemove(tG).unmouseup(tH), (t3 = []);
                                }),
                                (y.circle = function (t, i, n) {
                                    var o = e._engine.circle(this, t || 0, i || 0, n || 0);
                                    return this.__set__ && this.__set__.push(o), o;
                                }),
                                (y.rect = function (t, i, n, o, r) {
                                    var a = e._engine.rect(this, t || 0, i || 0, n || 0, o || 0, r || 0);
                                    return this.__set__ && this.__set__.push(a), a;
                                }),
                                (y.ellipse = function (t, i, n, o) {
                                    var r = e._engine.ellipse(this, t || 0, i || 0, n || 0, o || 0);
                                    return this.__set__ && this.__set__.push(r), r;
                                }),
                                (y.path = function (t) {
                                    !t || e.is(t, H) || e.is(t[0], V) || (t += "");
                                    var i = e._engine.path(e.format[D](e, arguments), this);
                                    return this.__set__ && this.__set__.push(i), i;
                                }),
                                (y.image = function (t, i, n, o, r) {
                                    var a = e._engine.image(this, t || "about:blank", i || 0, n || 0, o || 0, r || 0);
                                    return this.__set__ && this.__set__.push(a), a;
                                }),
                                (y.text = function (t, i, n) {
                                    var o = e._engine.text(this, t || 0, i || 0, S(n));
                                    return this.__set__ && this.__set__.push(o), o;
                                }),
                                (y.set = function (t) {
                                    e.is(t, "array") || (t = Array.prototype.splice.call(arguments, 0, arguments.length));
                                    var i = new tq(t);
                                    return this.__set__ && this.__set__.push(i), (i.paper = this), (i.type = "set"), i;
                                }),
                                (y.setStart = function (t) {
                                    this.__set__ = t || this.set();
                                }),
                                (y.setFinish = function (t) {
                                    var e = this.__set__;
                                    return delete this.__set__, e;
                                }),
                                (y.getSize = function () {
                                    var t = this.canvas.parentNode;
                                    return { width: t.offsetWidth, height: t.offsetHeight };
                                }),
                                (y.setSize = function (t, i) {
                                    return e._engine.setSize.call(this, t, i);
                                }),
                                (y.setViewBox = function (t, i, n, o, r) {
                                    return e._engine.setViewBox.call(this, t, i, n, o, r);
                                }),
                                (y.top = y.bottom = null),
                                (y.raphael = e);
                            var tz = function (t) {
                                var e = t.getBoundingClientRect(),
                                    i = t.ownerDocument,
                                    n = i.body,
                                    o = i.documentElement,
                                    r = o.clientTop || n.clientTop || 0,
                                    a = o.clientLeft || n.clientLeft || 0;
                                return { y: e.top + (b.win.pageYOffset || o.scrollTop || n.scrollTop) - r, x: e.left + (b.win.pageXOffset || o.scrollLeft || n.scrollLeft) - a };
                            };
                            (y.getElementByPoint = function (t, e) {
                                var i = this.canvas,
                                    n = b.doc.elementFromPoint(t, e);
                                if (b.win.opera && "svg" == n.tagName) {
                                    var o = tz(i),
                                        r = i.createSVGRect();
                                    (r.x = t - o.x), (r.y = e - o.y), (r.width = r.height = 1);
                                    var a = i.getIntersectionList(r, null);
                                    a.length && (n = a[a.length - 1]);
                                }
                                if (!n) return null;
                                for (; n.parentNode && n != i.parentNode && !n.raphael; ) n = n.parentNode;
                                return n == this.canvas.parentNode && (n = i), (n = n && n.raphael ? this.getById(n.raphaelid) : null);
                            }),
                                (y.getElementsByBBox = function (t) {
                                    var i = this.set();
                                    return (
                                        this.forEach(function (n) {
                                            e.isBBoxIntersect(n.getBBox(), t) && i.push(n);
                                        }),
                                            i
                                    );
                                }),
                                (y.getById = function (t) {
                                    for (var e = this.bottom; e; ) {
                                        if (e.id == t) return e;
                                        e = e.next;
                                    }
                                    return null;
                                }),
                                (y.forEach = function (t, e) {
                                    for (var i = this.bottom; i && !1 !== t.call(e, i); ) i = i.next;
                                    return this;
                                }),
                                (y.getElementsByPoint = function (t, e) {
                                    var i = this.set();
                                    return (
                                        this.forEach(function (n) {
                                            n.isPointInside(t, e) && i.push(n);
                                        }),
                                            i
                                    );
                                }),
                                (t2.isPointInside = function (t, i) {
                                    var n = (this.realPath = th[this.type](this));
                                    return this.attr("transform") && this.attr("transform").length && (n = e.transformPath(n, this.attr("transform"))), e.isPointInsidePath(n, t, i);
                                }),
                                (t2.getBBox = function (t) {
                                    if (this.removed) return {};
                                    var e = this._;
                                    return t
                                        ? ((!e.dirty && e.bboxwt) || ((this.realPath = th[this.type](this)), (e.bboxwt = tx(this.realPath)), (e.bboxwt.toString = p), (e.dirty = 0)), e.bboxwt)
                                        : ((e.dirty || e.dirtyT || !e.bbox) &&
                                        ((!e.dirty && this.realPath) || ((e.bboxwt = 0), (this.realPath = th[this.type](this))), (e.bbox = tx(tc(this.realPath, this.matrix))), (e.bbox.toString = p), (e.dirty = e.dirtyT = 0)),
                                            e.bbox);
                                }),
                                (t2.clone = function () {
                                    if (this.removed) return null;
                                    var t = this.paper[this.type]().attr(this.attr());
                                    return this.__set__ && this.__set__.push(t), t;
                                }),
                                (t2.glow = function (t) {
                                    if ("text" == this.type) return null;
                                    var e = {
                                            width: ((t = t || {}).width || 10) + (+this.attr("stroke-width") || 1),
                                            fill: t.fill || !1,
                                            opacity: null == t.opacity ? 0.5 : t.opacity,
                                            offsetx: t.offsetx || 0,
                                            offsety: t.offsety || 0,
                                            color: t.color || "#000",
                                        },
                                        i = e.width / 2,
                                        n = this.paper,
                                        o = n.set(),
                                        r = this.realPath || th[this.type](this);
                                    r = this.matrix ? tc(r, this.matrix) : r;
                                    for (var a = 1; a < i + 1; a++)
                                        o.push(
                                            n
                                                .path(r)
                                                .attr({
                                                    stroke: e.color,
                                                    fill: e.fill ? e.color : "none",
                                                    "stroke-linejoin": "round",
                                                    "stroke-linecap": "round",
                                                    "stroke-width": +((e.width / i) * a).toFixed(3),
                                                    opacity: +(e.opacity / i).toFixed(3),
                                                })
                                        );
                                    return o.insertBefore(this).translate(e.offsetx, e.offsety);
                                });
                            var t4 = function (t, i, n, o, r, a, u, l, h) {
                                    return null == h
                                        ? s(t, i, n, o, r, a, u, l)
                                        : e.findDotsAtSegment(
                                            t,
                                            i,
                                            n,
                                            o,
                                            r,
                                            a,
                                            u,
                                            l,
                                            (function t(e, i, n, o, r, a, u, l, h) {
                                                if (!(h < 0 || s(e, i, n, o, r, a, u, l) < h)) {
                                                    var c,
                                                        p = 0.5,
                                                        d = 1 - p;
                                                    for (c = s(e, i, n, o, r, a, u, l, d); O(c - h) > 0.01; ) (p /= 2), (d += (c < h ? 1 : -1) * p), (c = s(e, i, n, o, r, a, u, l, d));
                                                    return d;
                                                }
                                            })(t, i, n, o, r, a, u, l, h)
                                        );
                                },
                                t6 = function (t, i) {
                                    return function (n, o, r) {
                                        n = tS(n);
                                        for (var a, s, u, l, h, c = "", p = {}, d = 0, f = 0, g = n.length; f < g; f++) {
                                            if ("M" == (u = n[f])[0]) (a = +u[1]), (s = +u[2]);
                                            else {
                                                if (((l = t4(a, s, u[1], u[2], u[3], u[4], u[5], u[6])), d + l > o)) {
                                                    if (i && !p.start) {
                                                        if (((h = t4(a, s, u[1], u[2], u[3], u[4], u[5], u[6], o - d)), (c += ["C" + h.start.x, h.start.y, h.m.x, h.m.y, h.x, h.y]), r)) return c;
                                                        (p.start = c), (c = ["M" + h.x, h.y + "C" + h.n.x, h.n.y, h.end.x, h.end.y, u[5], u[6]].join()), (d += l), (a = +u[5]), (s = +u[6]);
                                                        continue;
                                                    }
                                                    if (!t && !i) return { x: (h = t4(a, s, u[1], u[2], u[3], u[4], u[5], u[6], o - d)).x, y: h.y, alpha: h.alpha };
                                                }
                                                (d += l), (a = +u[5]), (s = +u[6]);
                                            }
                                            c += u.shift() + u;
                                        }
                                        return (p.end = c), (h = t ? d : i ? p : e.findDotsAtSegment(a, s, u[0], u[1], u[2], u[3], u[4], u[5], 1)).alpha && (h = { x: h.x, y: h.y, alpha: h.alpha }), h;
                                    };
                                },
                                tW = t6(1),
                                tY = t6(),
                                t7 = t6(0, 1);
                            (e.getTotalLength = tW),
                                (e.getPointAtLength = tY),
                                (e.getSubpath = function (t, e, i) {
                                    if (this.getTotalLength(t) - i < 1e-6) return t7(t, e).end;
                                    var n = t7(t, i, 1);
                                    return e ? t7(n, e).end : n;
                                }),
                                (t2.getTotalLength = function () {
                                    var t = this.getPath();
                                    if (t) return this.node.getTotalLength ? this.node.getTotalLength() : tW(t);
                                }),
                                (t2.getPointAtLength = function (t) {
                                    var e = this.getPath();
                                    if (e) return tY(e, t);
                                }),
                                (t2.getPath = function () {
                                    var t,
                                        i = e._getPath[this.type];
                                    if ("text" != this.type && "set" != this.type) return i && (t = i(this)), t;
                                }),
                                (t2.getSubpath = function (t, i) {
                                    var n = this.getPath();
                                    if (n) return e.getSubpath(n, t, i);
                                });
                            var t5 = (e.easing_formulas = {
                                linear: function (t) {
                                    return t;
                                },
                                "<": function (t) {
                                    return N(t, 1.7);
                                },
                                ">": function (t) {
                                    return N(t, 0.48);
                                },
                                "<>": function (t) {
                                    var e = 0.48 - t / 1.04,
                                        i = I.sqrt(0.1734 + e * e),
                                        n = i - e,
                                        o = N(O(n), 1 / 3) * (n < 0 ? -1 : 1),
                                        r = -i - e,
                                        a = N(O(r), 1 / 3) * (r < 0 ? -1 : 1),
                                        s = o + a + 0.5;
                                    return 3 * (1 - s) * s * s + s * s * s;
                                },
                                backIn: function (t) {
                                    return t * t * (2.70158 * t - 1.70158);
                                },
                                backOut: function (t) {
                                    return (t -= 1) * t * (2.70158 * t + 1.70158) + 1;
                                },
                                elastic: function (t) {
                                    return !!t == t ? t : N(2, -10 * t) * I.sin(((t - 0.075) * (2 * F)) / 0.3) + 1;
                                },
                                bounce: function (t) {
                                    var e;
                                    return (
                                        t < 0.36363636363636365
                                            ? (e = 7.5625 * t * t)
                                            : t < 0.7272727272727273
                                                ? ((t -= 0.5454545454545454), (e = 7.5625 * t * t + 0.75))
                                                : t < 0.9090909090909091
                                                    ? ((t -= 0.8181818181818182), (e = 7.5625 * t * t + 0.9375))
                                                    : ((t -= 0.9545454545454546), (e = 7.5625 * t * t + 0.984375)),
                                            e
                                    );
                                },
                            });
                            (t5.easeIn = t5["ease-in"] = t5["<"]), (t5.easeOut = t5["ease-out"] = t5[">"]), (t5.easeInOut = t5["ease-in-out"] = t5["<>"]), (t5["back-in"] = t5.backIn), (t5["back-out"] = t5.backOut);
                            var tU = [],
                                tX =
                                    window.requestAnimationFrame ||
                                    window.webkitRequestAnimationFrame ||
                                    window.mozRequestAnimationFrame ||
                                    window.oRequestAnimationFrame ||
                                    window.msRequestAnimationFrame ||
                                    function (t) {
                                        setTimeout(t, 16);
                                    },
                                tZ = function () {
                                    for (var i = +new Date(), n = 0; n < tU.length; n++) {
                                        var o = tU[n];
                                        if (!o.el.removed && !o.paused) {
                                            var r,
                                                a,
                                                s = i - o.start,
                                                u = o.ms,
                                                l = o.easing,
                                                h = o.from,
                                                c = o.diff,
                                                p = o.to,
                                                d = (o.t, o.el),
                                                g = {},
                                                m = {};
                                            if (
                                                (o.initstatus
                                                    ? ((s = ((o.initstatus * o.anim.top - o.prev) / (o.percent - o.prev)) * u), (o.status = o.initstatus), delete o.initstatus, o.stop && tU.splice(n--, 1))
                                                    : (o.status = (o.prev + (o.percent - o.prev) * (s / u)) / o.anim.top),
                                                    !(s < 0))
                                            ) {
                                                if (s < u) {
                                                    var y = l(s / u);
                                                    for (var v in h)
                                                        if (h[T](v)) {
                                                            switch (Q[v]) {
                                                                case G:
                                                                    r = +h[v] + y * u * c[v];
                                                                    break;
                                                                case "colour":
                                                                    r = "rgb(" + [tj(X(h[v].r + y * u * c[v].r)), tj(X(h[v].g + y * u * c[v].g)), tj(X(h[v].b + y * u * c[v].b))].join(",") + ")";
                                                                    break;
                                                                case "path":
                                                                    r = [];
                                                                    for (var $ = 0, x = h[v].length; $ < x; $++) {
                                                                        r[$] = [h[v][$][0]];
                                                                        for (var b = 1, _ = h[v][$].length; b < _; b++) r[$][b] = +h[v][$][b] + y * u * c[v][$][b];
                                                                        r[$] = r[$].join(L);
                                                                    }
                                                                    r = r.join(L);
                                                                    break;
                                                                case "transform":
                                                                    if (c[v].real)
                                                                        for (r = [], $ = 0, x = h[v].length; $ < x; $++) for (r[$] = [h[v][$][0]], b = 1, _ = h[v][$].length; b < _; b++) r[$][b] = h[v][$][b] + y * u * c[v][$][b];
                                                                    else {
                                                                        var A = function (t) {
                                                                            return +h[v][t] + y * u * c[v][t];
                                                                        };
                                                                        r = [["m", A(0), A(1), A(2), A(3), A(4), A(5)]];
                                                                    }
                                                                    break;
                                                                case "csv":
                                                                    if ("clip-rect" == v) for (r = [], $ = 4; $--; ) r[$] = +h[v][$] + y * u * c[v][$];
                                                                    break;
                                                                default:
                                                                    var D = [][C](h[v]);
                                                                    for (r = [], $ = d.paper.customAttributes[v].length; $--; ) r[$] = +D[$] + y * u * c[v][$];
                                                            }
                                                            g[v] = r;
                                                        }
                                                    d.attr(g),
                                                        (function (e, i, n) {
                                                            setTimeout(function () {
                                                                t("raphael.anim.frame." + e, i, n);
                                                            });
                                                        })(d.id, d, o.anim);
                                                } else {
                                                    if (
                                                        ((function (i, n, o) {
                                                            setTimeout(function () {
                                                                t("raphael.anim.frame." + n.id, n, o), t("raphael.anim.finish." + n.id, n, o), e.is(i, "function") && i.call(n);
                                                            });
                                                        })(o.callback, d, o.anim),
                                                            d.attr(p),
                                                            tU.splice(n--, 1),
                                                        o.repeat > 1 && !o.next)
                                                    ) {
                                                        for (a in p) p[T](a) && (m[a] = o.totalOrigin[a]);
                                                        o.el.attr(m), f(o.anim, o.el, o.anim.percents[0], null, o.totalOrigin, o.repeat - 1);
                                                    }
                                                    o.next && !o.stop && f(o.anim, o.el, o.next, null, o.totalOrigin, o.repeat);
                                                }
                                            }
                                        }
                                    }
                                    tU.length && tX(tZ);
                                },
                                tj = function (t) {
                                    return t > 255 ? 255 : t < 0 ? 0 : t;
                                };
                            (t2.animateWith = function (t, i, n, o, r, a) {
                                if (this.removed) return a && a.call(this), this;
                                var s = n instanceof d ? n : e.animation(n, o, r, a);
                                f(s, this, s.percents[0], null, this.attr());
                                for (var u = 0, l = tU.length; u < l; u++)
                                    if (tU[u].anim == i && tU[u].el == t) {
                                        tU[l - 1].start = tU[u].start;
                                        break;
                                    }
                                return this;
                            }),
                                (t2.onAnimation = function (e) {
                                    return e ? t.on("raphael.anim.frame." + this.id, e) : t.unbind("raphael.anim.frame." + this.id), this;
                                }),
                                (d.prototype.delay = function (t) {
                                    var e = new d(this.anim, this.ms);
                                    return (e.times = this.times), (e.del = +t || 0), e;
                                }),
                                (d.prototype.repeat = function (t) {
                                    var e = new d(this.anim, this.ms);
                                    return (e.del = this.del), (e.times = I.floor(R(t, 0)) || 1), e;
                                }),
                                (e.animation = function (t, i, n, o) {
                                    if (t instanceof d) return t;
                                    (!e.is(n, "function") && n) || ((o = o || n || null), (n = null)), (t = Object(t)), (i = +i || 0);
                                    var r,
                                        a,
                                        s = {};
                                    for (a in t) t[T](a) && Z(a) != a && Z(a) + "%" != a && ((r = !0), (s[a] = t[a]));
                                    if (r) return n && (s.easing = n), o && (s.callback = o), new d({ 100: s }, i);
                                    if (o) {
                                        var u = 0;
                                        for (var l in t) {
                                            var h = j(l);
                                            t[T](l) && h > u && (u = h);
                                        }
                                        t[(u += "%")].callback || (t[u].callback = o);
                                    }
                                    return new d(t, i);
                                }),
                                (t2.animate = function (t, i, n, o) {
                                    if (this.removed) return o && o.call(this), this;
                                    var r = t instanceof d ? t : e.animation(t, i, n, o);
                                    return f(r, this, r.percents[0], null, this.attr()), this;
                                }),
                                (t2.setTime = function (t, e) {
                                    return t && null != e && this.status(t, B(e, t.ms) / t.ms), this;
                                }),
                                (t2.status = function (t, e) {
                                    var i,
                                        n,
                                        o = [],
                                        r = 0;
                                    if (null != e) return f(t, this, -1, B(e, 1)), this;
                                    for (i = tU.length; r < i; r++)
                                        if ((n = tU[r]).el.id == this.id && (!t || n.anim == t)) {
                                            if (t) return n.status;
                                            o.push({ anim: n.anim, status: n.status });
                                        }
                                    return t ? 0 : o;
                                }),
                                (t2.pause = function (e) {
                                    for (var i = 0; i < tU.length; i++) tU[i].el.id != this.id || (e && tU[i].anim != e) || (!1 !== t("raphael.anim.pause." + this.id, this, tU[i].anim) && (tU[i].paused = !0));
                                    return this;
                                }),
                                (t2.resume = function (e) {
                                    for (var i = 0; i < tU.length; i++)
                                        if (tU[i].el.id == this.id && (!e || tU[i].anim == e)) {
                                            var n = tU[i];
                                            !1 !== t("raphael.anim.resume." + this.id, this, n.anim) && (delete n.paused, this.status(n.anim, n.status));
                                        }
                                    return this;
                                }),
                                (t2.stop = function (e) {
                                    for (var i = 0; i < tU.length; i++) tU[i].el.id != this.id || (e && tU[i].anim != e) || (!1 !== t("raphael.anim.stop." + this.id, this, tU[i].anim) && tU.splice(i--, 1));
                                    return this;
                                }),
                                t.on("raphael.remove", g),
                                t.on("raphael.clear", g),
                                (t2.toString = function () {
                                    return "Rapha\xebl’s object";
                                });
                            var tq = function (t) {
                                    if (((this.items = []), (this.length = 0), (this.type = "set"), t))
                                        for (var e = 0, i = t.length; e < i; e++)
                                            !t[e] || (t[e].constructor != t2.constructor && t[e].constructor != tq) || ((this[this.items.length] = this.items[this.items.length] = t[e]), this.length++);
                                },
                                tK = tq.prototype;
                            for (var tQ in ((tK.push = function () {
                                for (var t, e, i = 0, n = arguments.length; i < n; i++)
                                    (t = arguments[i]), !t || (t.constructor != t2.constructor && t.constructor != tq) || ((this[(e = this.items.length)] = this.items[e] = t), this.length++);
                                return this;
                            }),
                                (tK.pop = function () {
                                    return this.length && delete this[this.length--], this.items.pop();
                                }),
                                (tK.forEach = function (t, e) {
                                    for (var i = 0, n = this.items.length; i < n && !1 !== t.call(e, this.items[i], i); i++);
                                    return this;
                                }),
                                t2))
                                t2[T](tQ) &&
                                (tK[tQ] = (function (t) {
                                    return function () {
                                        var e = arguments;
                                        return this.forEach(function (i) {
                                            i[t][D](i, e);
                                        });
                                    };
                                })(tQ));
                            var t9,
                                tJ,
                                et,
                                ee,
                                ei,
                                en,
                                eo = "DOMContentLoaded";
                            return (
                                (tK.attr = function (t, i) {
                                    if (t && e.is(t, V) && e.is(t[0], "object")) for (var n = 0, o = t.length; n < o; n++) this.items[n].attr(t[n]);
                                    else for (var r = 0, a = this.items.length; r < a; r++) this.items[r].attr(t, i);
                                    return this;
                                }),
                                    (tK.clear = function () {
                                        for (; this.length; ) this.pop();
                                    }),
                                    (tK.splice = function (t, e, i) {
                                        (t = t < 0 ? R(this.length + t, 0) : t), (e = R(0, B(this.length - t, e)));
                                        var n,
                                            o = [],
                                            r = [],
                                            a = [];
                                        for (n = 2; n < arguments.length; n++) a.push(arguments[n]);
                                        for (n = 0; n < e; n++) r.push(this[t + n]);
                                        for (; n < this.length - t; n++) o.push(this[t + n]);
                                        var s = a.length;
                                        for (n = 0; n < s + o.length; n++) this.items[t + n] = this[t + n] = n < s ? a[n] : o[n - s];
                                        for (n = this.items.length = this.length -= e - s; this[n]; ) delete this[n++];
                                        return new tq(r);
                                    }),
                                    (tK.exclude = function (t) {
                                        for (var e = 0, i = this.length; e < i; e++) if (this[e] == t) return this.splice(e, 1), !0;
                                    }),
                                    (tK.animate = function (t, i, n, o) {
                                        (e.is(n, "function") || !n) && (o = n || null);
                                        var r,
                                            a,
                                            s = this.items.length,
                                            u = s,
                                            l = this;
                                        if (!s) return this;
                                        o &&
                                        (a = function () {
                                            --s || o.call(l);
                                        }),
                                            (n = e.is(n, H) ? n : a);
                                        var h = e.animation(t, i, n, a);
                                        for (r = this.items[--u].animate(h); u--; ) this.items[u] && !this.items[u].removed && this.items[u].animateWith(r, h, h), (this.items[u] && !this.items[u].removed) || s--;
                                        return this;
                                    }),
                                    (tK.insertAfter = function (t) {
                                        for (var e = this.items.length; e--; ) this.items[e].insertAfter(t);
                                        return this;
                                    }),
                                    (tK.getBBox = function () {
                                        for (var t = [], e = [], i = [], n = [], o = this.items.length; o--; )
                                            if (!this.items[o].removed) {
                                                var r = this.items[o].getBBox();
                                                t.push(r.x), e.push(r.y), i.push(r.x + r.width), n.push(r.y + r.height);
                                            }
                                        return { x: (t = B[D](0, t)), y: (e = B[D](0, e)), x2: (i = R[D](0, i)), y2: (n = R[D](0, n)), width: i - t, height: n - e };
                                    }),
                                    (tK.clone = function (t) {
                                        t = this.paper.set();
                                        for (var e = 0, i = this.items.length; e < i; e++) t.push(this.items[e].clone());
                                        return t;
                                    }),
                                    (tK.toString = function () {
                                        return "Rapha\xebl‘s set";
                                    }),
                                    (tK.glow = function (t) {
                                        var e = this.paper.set();
                                        return (
                                            this.forEach(function (i, n) {
                                                var o = i.glow(t);
                                                null != o &&
                                                o.forEach(function (t, i) {
                                                    e.push(t);
                                                });
                                            }),
                                                e
                                        );
                                    }),
                                    (tK.isPointInside = function (t, e) {
                                        var i = !1;
                                        return (
                                            this.forEach(function (n) {
                                                if (n.isPointInside(t, e)) return (i = !0), !1;
                                            }),
                                                i
                                        );
                                    }),
                                    (e.registerFont = function (t) {
                                        if (!t.face) return t;
                                        this.fonts = this.fonts || {};
                                        var e = { w: t.w, face: {}, glyphs: {} },
                                            i = t.face["font-family"];
                                        for (var n in t.face) t.face[T](n) && (e.face[n] = t.face[n]);
                                        if ((this.fonts[i] ? this.fonts[i].push(e) : (this.fonts[i] = [e]), !t.svg)) {
                                            for (var o in ((e.face["units-per-em"] = j(t.face["units-per-em"], 10)), t.glyphs))
                                                if (t.glyphs[T](o)) {
                                                    var r = t.glyphs[o];
                                                    if (
                                                        ((e.glyphs[o] = {
                                                            w: r.w,
                                                            k: {},
                                                            d:
                                                                r.d &&
                                                                "M" +
                                                                r.d.replace(/[mlcxtrv]/g, function (t) {
                                                                    return { l: "L", c: "C", x: "z", t: "m", r: "l", v: "c" }[t] || "M";
                                                                }) +
                                                                "z",
                                                        }),
                                                            r.k)
                                                    )
                                                        for (var a in r.k) r[T](a) && (e.glyphs[o].k[a] = r.k[a]);
                                                }
                                        }
                                        return t;
                                    }),
                                    (y.getFont = function (t, i, n, o) {
                                        if (((o = o || "normal"), (n = n || "normal"), (i = +i || { normal: 400, bold: 700, lighter: 300, bolder: 800 }[i] || 400), e.fonts)) {
                                            var r,
                                                a = e.fonts[t];
                                            if (!a) {
                                                var s = RegExp("(^|\\s)" + t.replace(/[^\w\d\s+!~.:_-]/g, "") + "(\\s|$)", "i");
                                                for (var u in e.fonts)
                                                    if (e.fonts[T](u) && s.test(u)) {
                                                        a = e.fonts[u];
                                                        break;
                                                    }
                                            }
                                            if (a) for (var l = 0, h = a.length; l < h && ((r = a[l]).face["font-weight"] != i || (r.face["font-style"] != n && r.face["font-style"]) || r.face["font-stretch"] != o); l++);
                                            return r;
                                        }
                                    }),
                                    (y.print = function (t, i, n, o, r, a, s, u) {
                                        (a = a || "middle"), (s = R(B(s || 0, 1), -1)), (u = R(B(u || 1, 3), 1));
                                        var l,
                                            h = S(n)[P](""),
                                            c = 0,
                                            p = 0,
                                            d = "";
                                        if ((e.is(o, "string") && (o = this.getFont(o)), o)) {
                                            l = (r || 16) / o.face["units-per-em"];
                                            for (var f = o.face.bbox[P](v), g = +f[0], m = f[3] - f[1], y = 0, $ = +f[1] + ("baseline" == a ? m + +o.face.descent : m / 2), x = 0, T = h.length; x < T; x++) {
                                                if ("\n" == h[x]) (c = 0), (_ = 0), (p = 0), (y += m * u);
                                                else {
                                                    var b = (p && o.glyphs[h[x - 1]]) || {},
                                                        _ = o.glyphs[h[x]];
                                                    (c += p ? (b.w || o.w) + ((b.k && b.k[h[x]]) || 0) + o.w * s : 0), (p = 1);
                                                }
                                                _ && _.d && (d += e.transformPath(_.d, ["t", c * l, y * l, "s", l, l, g, $, "t", (t - g) / l, (i - $) / l]));
                                            }
                                        }
                                        return this.path(d).attr({ fill: "#000", stroke: "none" });
                                    }),
                                    (y.add = function (t) {
                                        if (e.is(t, "array")) for (var i, n = this.set(), o = 0, r = t.length; o < r; o++) (i = t[o] || {}), $[T](i.type) && n.push(this[i.type]().attr(i));
                                        return n;
                                    }),
                                    (e.format = function (t, i) {
                                        var n = e.is(i, V) ? [0][C](i) : arguments;
                                        return (
                                            t &&
                                            e.is(t, H) &&
                                            n.length - 1 &&
                                            (t = t.replace(x, function (t, e) {
                                                return null == n[++e] ? "" : n[e];
                                            })),
                                            t || ""
                                        );
                                    }),
                                    (e.fullfill =
                                        ((tJ = /\{([^\}]+)\}/g),
                                            (et = /(?:(?:^|\.)(.+?)(?=\[|\.|$|\()|\[('|")(.+?)\2\])(\(\))?/g),
                                            (ee = function (t, e, i) {
                                                var n = i;
                                                return (
                                                    e.replace(et, function (t, e, i, o, r) {
                                                        (e = e || o), n && (e in n && (n = n[e]), "function" == typeof n && r && (n = n()));
                                                    }),
                                                        (n = (null == n || n == i ? t : n) + "")
                                                );
                                            }),
                                            function (t, e) {
                                                return String(t).replace(tJ, function (t, i) {
                                                    return ee(t, i, e);
                                                });
                                            })),
                                    (e.ninja = function () {
                                        if (_.was) b.win.Raphael = _.is;
                                        else {
                                            window.Raphael = void 0;
                                            try {
                                                delete window.Raphael;
                                            } catch (t) {}
                                        }
                                        return e;
                                    }),
                                    (e.st = tK),
                                    t.on("raphael.DOMload", function () {
                                        m = !0;
                                    }),
                                null == (ei = document).readyState &&
                                ei.addEventListener &&
                                (ei.addEventListener(
                                    eo,
                                    (en = function () {
                                        ei.removeEventListener(eo, en, !1), (ei.readyState = "complete");
                                    }),
                                    !1
                                ),
                                    (ei.readyState = "loading")),
                                    (function t() {
                                        /in/.test(ei.readyState) ? setTimeout(t, 9) : e.eve("raphael.DOMload");
                                    })(),
                                    e
                            );
                        }.apply(e, (n = [i(2)]))) && (t.exports = o);
                    },
                    function (t, e, i) {
                        var n, o, r, a, s, u, l, h, c, p, d;
                        (s = "0.4.2"),
                            (u = "hasOwnProperty"),
                            (l = /[\.\/]/),
                            (h = function () {}),
                            (c = function (t, e) {
                                return t - e;
                            }),
                            (p = { n: {} }),
                            ((d = function (t, e) {
                                t = String(t);
                                var i,
                                    n = a,
                                    o = Array.prototype.slice.call(arguments, 2),
                                    s = d.listeners(t),
                                    u = 0,
                                    l = [],
                                    h = {},
                                    p = [],
                                    f = r;
                                (r = t), (a = 0);
                                for (var g = 0, m = s.length; g < m; g++) "zIndex" in s[g] && (l.push(s[g].zIndex), s[g].zIndex < 0 && (h[s[g].zIndex] = s[g]));
                                for (l.sort(c); l[u] < 0; ) if (((i = h[l[u++]]), p.push(i.apply(e, o)), a)) return (a = n), p;
                                for (g = 0; g < m; g++)
                                    if ("zIndex" in (i = s[g])) {
                                        if (i.zIndex == l[u]) {
                                            if ((p.push(i.apply(e, o)), a)) break;
                                            do if (((i = h[l[++u]]) && p.push(i.apply(e, o)), a)) break;
                                            while (i);
                                        } else h[i.zIndex] = i;
                                    } else if ((p.push(i.apply(e, o)), a)) break;
                                return (a = n), (r = f), p.length ? p : null;
                            })._events = p),
                            (d.listeners = function (t) {
                                var e,
                                    i,
                                    n,
                                    o,
                                    r,
                                    a,
                                    s,
                                    u,
                                    h = t.split(l),
                                    c = p,
                                    d = [c],
                                    f = [];
                                for (o = 0, r = h.length; o < r; o++) {
                                    for (u = [], a = 0, s = d.length; a < s; a++) for (i = [(c = d[a].n)[h[o]], c["*"]], n = 2; n--; ) (e = i[n]) && (u.push(e), (f = f.concat(e.f || [])));
                                    d = u;
                                }
                                return f;
                            }),
                            (d.on = function (t, e) {
                                if (((t = String(t)), "function" != typeof e)) return function () {};
                                for (var i = t.split(l), n = p, o = 0, r = i.length; o < r; o++) n = ((n = n.n).hasOwnProperty(i[o]) && n[i[o]]) || (n[i[o]] = { n: {} });
                                for (n.f = n.f || [], o = 0, r = n.f.length; o < r; o++) if (n.f[o] == e) return h;
                                return (
                                    n.f.push(e),
                                        function (t) {
                                            +t == +t && (e.zIndex = +t);
                                        }
                                );
                            }),
                            (d.f = function (t) {
                                var e = [].slice.call(arguments, 1);
                                return function () {
                                    d.apply(null, [t, null].concat(e).concat([].slice.call(arguments, 0)));
                                };
                            }),
                            (d.stop = function () {
                                a = 1;
                            }),
                            (d.nt = function (t) {
                                return t ? RegExp("(?:\\.|\\/|^)" + t + "(?:\\.|\\/|$)").test(r) : r;
                            }),
                            (d.nts = function () {
                                return r.split(l);
                            }),
                            (d.off = d.unbind = function (t, e) {
                                if (!t) return void (d._events = p = { n: {} });
                                var i,
                                    n,
                                    o,
                                    r,
                                    a,
                                    s,
                                    h,
                                    c = t.split(l),
                                    f = [p];
                                for (r = 0, a = c.length; r < a; r++)
                                    for (s = 0; s < f.length; s += o.length - 2) {
                                        if (((o = [s, 1]), (i = f[s].n), "*" != c[r])) i[c[r]] && o.push(i[c[r]]);
                                        else for (n in i) i[u](n) && o.push(i[n]);
                                        f.splice.apply(f, o);
                                    }
                                for (r = 0, a = f.length; r < a; r++)
                                    for (i = f[r]; i.n; ) {
                                        if (e) {
                                            if (i.f) {
                                                for (s = 0, h = i.f.length; s < h; s++)
                                                    if (i.f[s] == e) {
                                                        i.f.splice(s, 1);
                                                        break;
                                                    }
                                                i.f.length || delete i.f;
                                            }
                                            for (n in i.n)
                                                if (i.n[u](n) && i.n[n].f) {
                                                    var g = i.n[n].f;
                                                    for (s = 0, h = g.length; s < h; s++)
                                                        if (g[s] == e) {
                                                            g.splice(s, 1);
                                                            break;
                                                        }
                                                    g.length || delete i.n[n].f;
                                                }
                                        } else for (n in (delete i.f, i.n)) i.n[u](n) && i.n[n].f && delete i.n[n].f;
                                        i = i.n;
                                    }
                            }),
                            (d.once = function (t, e) {
                                var i = function () {
                                    return d.unbind(t, i), e.apply(this, arguments);
                                };
                                return d.on(t, i);
                            }),
                            (d.version = s),
                            (d.toString = function () {
                                return "You are running Eve " + s;
                            }),
                            void 0 !== t && t.exports
                                ? (t.exports = d)
                                : void 0 !==
                                (o = function () {
                                    return d;
                                }.apply(e, (n = []))) && (t.exports = o);
                    },
                    function (t, e, i) {
                        var n, o;
                        void 0 !==
                        (o = function (t) {
                            if (!t || t.svg) {
                                var e = "hasOwnProperty",
                                    i = String,
                                    n = parseFloat,
                                    o = parseInt,
                                    r = Math,
                                    a = r.max,
                                    s = r.abs,
                                    u = r.pow,
                                    l = /[, ]+/,
                                    h = t.eve,
                                    c = "http://www.w3.org/1999/xlink",
                                    p = { block: "M5,0 0,2.5 5,5z", classic: "M5,0 0,2.5 5,5 3.5,3 3.5,2z", diamond: "M2.5,0 5,2.5 2.5,5 0,2.5z", open: "M6,1 1,3.5 6,6", oval: "M2.5,0A2.5,2.5,0,0,1,2.5,5 2.5,2.5,0,0,1,2.5,0z" },
                                    d = {};
                                t.toString = function () {
                                    return "Your browser supports SVG.\nYou are running Rapha\xebl " + this.version;
                                };
                                var f = function (n, o) {
                                        if (o) for (var r in ("string" == typeof n && (n = f(n)), o)) o[e](r) && ("xlink:" == r.substring(0, 6) ? n.setAttributeNS(c, r.substring(6), i(o[r])) : n.setAttribute(r, i(o[r])));
                                        else (n = t._g.doc.createElementNS("http://www.w3.org/2000/svg", n)).style && (n.style.webkitTapHighlightColor = "rgba(0,0,0,0)");
                                        return n;
                                    },
                                    g = function (e, o) {
                                        var l = "linear",
                                            h = e.id + o,
                                            c = 0.5,
                                            p = 0.5,
                                            d = e.node,
                                            g = e.paper,
                                            m = d.style,
                                            v = t._g.doc.getElementById(h);
                                        if (!v) {
                                            if (
                                                ((o = (o = i(o).replace(t._radial_gradient, function (t, e, i) {
                                                    if (((l = "radial"), e && i)) {
                                                        c = n(e);
                                                        var o = 2 * ((p = n(i)) > 0.5) - 1;
                                                        u(c - 0.5, 2) + u(p - 0.5, 2) > 0.25 && (p = r.sqrt(0.25 - u(c - 0.5, 2)) * o + 0.5) && 0.5 != p && (p = p.toFixed(5) - 1e-5 * o);
                                                    }
                                                    return "";
                                                })).split(/\s*\-\s*/)),
                                                "linear" == l)
                                            ) {
                                                var $ = o.shift();
                                                if ((($ = -n($)), isNaN($))) return null;
                                                var x = [0, 0, r.cos(t.rad($)), r.sin(t.rad($))],
                                                    T = 1 / (a(s(x[2]), s(x[3])) || 1);
                                                (x[2] *= T), (x[3] *= T), x[2] < 0 && ((x[0] = -x[2]), (x[2] = 0)), x[3] < 0 && ((x[1] = -x[3]), (x[3] = 0));
                                            }
                                            var b = t._parseDots(o);
                                            if (!b) return null;
                                            if (((h = h.replace(/[\(\)\s,\xb0#]/g, "_")), e.gradient && h != e.gradient.id && (g.defs.removeChild(e.gradient), delete e.gradient), !e.gradient)) {
                                                (v = f(l + "Gradient", { id: h })),
                                                    (e.gradient = v),
                                                    f(v, "radial" == l ? { fx: c, fy: p } : { x1: x[0], y1: x[1], x2: x[2], y2: x[3], gradientTransform: e.matrix.invert() }),
                                                    g.defs.appendChild(v);
                                                for (var _ = 0, A = b.length; _ < A; _++)
                                                    v.appendChild(f("stop", { offset: b[_].offset ? b[_].offset : _ ? "100%" : "0%", "stop-color": b[_].color || "#fff", "stop-opacity": isFinite(b[_].opacity) ? b[_].opacity : 1 }));
                                            }
                                        }
                                        return f(d, { fill: y(h), opacity: 1, "fill-opacity": 1 }), (m.fill = ""), (m.opacity = 1), (m.fillOpacity = 1), 1;
                                    },
                                    m = function () {
                                        var t = document.documentMode;
                                        return t && (9 === t || 10 === t);
                                    },
                                    y = function (t) {
                                        if (m()) return "url('#" + t + "')";
                                        var e = document.location;
                                        return "url('" + (e.protocol + "//") + e.host + e.pathname + e.search + "#" + t + "')";
                                    },
                                    v = function (t) {
                                        var e = t.getBBox(1);
                                        f(t.pattern, { patternTransform: t.matrix.invert() + " translate(" + e.x + "," + e.y + ")" });
                                    },
                                    $ = function (n, o, r) {
                                        if ("path" == n.type) {
                                            for (
                                                var a,
                                                    s,
                                                    u,
                                                    l,
                                                    h,
                                                    c = i(o).toLowerCase().split("-"),
                                                    g = n.paper,
                                                    m = r ? "end" : "start",
                                                    y = n.node,
                                                    v = n.attrs,
                                                    $ = v["stroke-width"],
                                                    x = c.length,
                                                    T = "classic",
                                                    b = 3,
                                                    _ = 3,
                                                    A = 5;
                                                x--;

                                            )
                                                switch (c[x]) {
                                                    case "block":
                                                    case "classic":
                                                    case "oval":
                                                    case "diamond":
                                                    case "open":
                                                    case "none":
                                                        T = c[x];
                                                        break;
                                                    case "wide":
                                                        _ = 5;
                                                        break;
                                                    case "narrow":
                                                        _ = 2;
                                                        break;
                                                    case "long":
                                                        b = 5;
                                                        break;
                                                    case "short":
                                                        b = 2;
                                                }
                                            if (
                                                ("open" == T ? ((b += 2), (_ += 2), (A += 2), (u = 1), (l = r ? 4 : 1), (h = { fill: "none", stroke: v.stroke })) : ((l = u = b / 2), (h = { fill: v.stroke, stroke: "none" })),
                                                    n._.arrows
                                                        ? r
                                                            ? (n._.arrows.endPath && d[n._.arrows.endPath]--, n._.arrows.endMarker && d[n._.arrows.endMarker]--)
                                                            : (n._.arrows.startPath && d[n._.arrows.startPath]--, n._.arrows.startMarker && d[n._.arrows.startMarker]--)
                                                        : (n._.arrows = {}),
                                                "none" != T)
                                            ) {
                                                var D = "raphael-marker-" + T,
                                                    C = "raphael-marker-" + m + T + b + _ + "-obj" + n.id;
                                                t._g.doc.getElementById(D) ? d[D]++ : (g.defs.appendChild(f(f("path"), { "stroke-linecap": "round", d: p[T], id: D })), (d[D] = 1));
                                                var E,
                                                    L = t._g.doc.getElementById(C);
                                                L
                                                    ? (d[C]++, (E = L.getElementsByTagName("use")[0]))
                                                    : ((L = f(f("marker"), { id: C, markerHeight: _, markerWidth: b, orient: "auto", refX: l, refY: _ / 2 })),
                                                        (E = f(f("use"), {
                                                            "xlink:href": "#" + D,
                                                            transform: (r ? "rotate(180 " + b / 2 + " " + _ / 2 + ") " : "") + "scale(" + b / A + "," + _ / A + ")",
                                                            "stroke-width": (1 / ((b / A + _ / A) / 2)).toFixed(4),
                                                        })),
                                                        L.appendChild(E),
                                                        g.defs.appendChild(L),
                                                        (d[C] = 1)),
                                                    f(E, h);
                                                var S = u * ("diamond" != T && "oval" != T);
                                                r ? ((a = n._.arrows.startdx * $ || 0), (s = t.getTotalLength(v.path) - S * $)) : ((a = S * $), (s = t.getTotalLength(v.path) - (n._.arrows.enddx * $ || 0))),
                                                    ((h = {})["marker-" + m] = "url(#" + C + ")"),
                                                (s || a) && (h.d = t.getSubpath(v.path, a, s)),
                                                    f(y, h),
                                                    (n._.arrows[m + "Path"] = D),
                                                    (n._.arrows[m + "Marker"] = C),
                                                    (n._.arrows[m + "dx"] = S),
                                                    (n._.arrows[m + "Type"] = T),
                                                    (n._.arrows[m + "String"] = o);
                                            } else
                                                r ? ((a = n._.arrows.startdx * $ || 0), (s = t.getTotalLength(v.path) - a)) : ((a = 0), (s = t.getTotalLength(v.path) - (n._.arrows.enddx * $ || 0))),
                                                n._.arrows[m + "Path"] && f(y, { d: t.getSubpath(v.path, a, s) }),
                                                    delete n._.arrows[m + "Path"],
                                                    delete n._.arrows[m + "Marker"],
                                                    delete n._.arrows[m + "dx"],
                                                    delete n._.arrows[m + "Type"],
                                                    delete n._.arrows[m + "String"];
                                            for (h in d)
                                                if (d[e](h) && !d[h]) {
                                                    var P = t._g.doc.getElementById(h);
                                                    P && P.parentNode.removeChild(P);
                                                }
                                        }
                                    },
                                    x = { "-": [3, 1], ".": [1, 1], "-.": [3, 1, 1, 1], "-..": [3, 1, 1, 1, 1, 1], ". ": [1, 3], "- ": [4, 3], "--": [8, 3], "- .": [4, 3, 1, 3], "--.": [8, 3, 1, 3], "--..": [8, 3, 1, 3, 1, 3] },
                                    T = function (t, e, n) {
                                        if ((e = x[i(e).toLowerCase()])) {
                                            for (var o = t.attrs["stroke-width"] || "1", r = { round: o, square: o, butt: 0 }[t.attrs["stroke-linecap"] || n["stroke-linecap"]] || 0, a = [], s = e.length; s--; )
                                                a[s] = e[s] * o + (s % 2 ? 1 : -1) * r;
                                            f(t.node, { "stroke-dasharray": a.join(",") });
                                        } else f(t.node, { "stroke-dasharray": "none" });
                                    },
                                    b = function (n, r) {
                                        var u = n.node,
                                            h = n.attrs,
                                            p = u.style.visibility;
                                        for (var d in ((u.style.visibility = "hidden"), r))
                                            if (r[e](d)) {
                                                if (!t._availableAttrs[e](d)) continue;
                                                var m = r[d];
                                                switch (((h[d] = m), d)) {
                                                    case "blur":
                                                        n.blur(m);
                                                        break;
                                                    case "title":
                                                        var y = u.getElementsByTagName("title");
                                                        if (y.length && (y = y[0])) y.firstChild.nodeValue = m;
                                                        else {
                                                            y = f("title");
                                                            var x = t._g.doc.createTextNode(m);
                                                            y.appendChild(x), u.appendChild(y);
                                                        }
                                                        break;
                                                    case "href":
                                                    case "target":
                                                        var b = u.parentNode;
                                                        if ("a" != b.tagName.toLowerCase()) {
                                                            var A = f("a");
                                                            b.insertBefore(A, u), A.appendChild(u), (b = A);
                                                        }
                                                        "target" == d ? b.setAttributeNS(c, "show", "blank" == m ? "new" : m) : b.setAttributeNS(c, d, m);
                                                        break;
                                                    case "cursor":
                                                        u.style.cursor = m;
                                                        break;
                                                    case "transform":
                                                        n.transform(m);
                                                        break;
                                                    case "arrow-start":
                                                        $(n, m);
                                                        break;
                                                    case "arrow-end":
                                                        $(n, m, 1);
                                                        break;
                                                    case "clip-rect":
                                                        var D = i(m).split(l);
                                                        if (4 == D.length) {
                                                            n.clip && n.clip.parentNode.parentNode.removeChild(n.clip.parentNode);
                                                            var C = f("clipPath"),
                                                                E = f("rect");
                                                            (C.id = t.createUUID()),
                                                                f(E, { x: D[0], y: D[1], width: D[2], height: D[3] }),
                                                                C.appendChild(E),
                                                                n.paper.defs.appendChild(C),
                                                                f(u, { "clip-path": "url(#" + C.id + ")" }),
                                                                (n.clip = E);
                                                        }
                                                        if (!m) {
                                                            var L = u.getAttribute("clip-path");
                                                            if (L) {
                                                                var S = t._g.doc.getElementById(L.replace(/(^url\(#|\)$)/g, ""));
                                                                S && S.parentNode.removeChild(S), f(u, { "clip-path": "" }), delete n.clip;
                                                            }
                                                        }
                                                        break;
                                                    case "path":
                                                        "path" == n.type &&
                                                        (f(u, { d: m ? (h.path = t._pathToAbsolute(m)) : "M0,0" }),
                                                            (n._.dirty = 1),
                                                        n._.arrows && ("startString" in n._.arrows && $(n, n._.arrows.startString), "endString" in n._.arrows && $(n, n._.arrows.endString, 1)));
                                                        break;
                                                    case "width":
                                                        if ((u.setAttribute(d, m), (n._.dirty = 1), !h.fx)) break;
                                                        (d = "x"), (m = h.x);
                                                    case "x":
                                                        h.fx && (m = -h.x - (h.width || 0));
                                                    case "rx":
                                                        if ("rx" == d && "rect" == n.type) break;
                                                    case "cx":
                                                        u.setAttribute(d, m), n.pattern && v(n), (n._.dirty = 1);
                                                        break;
                                                    case "height":
                                                        if ((u.setAttribute(d, m), (n._.dirty = 1), !h.fy)) break;
                                                        (d = "y"), (m = h.y);
                                                    case "y":
                                                        h.fy && (m = -h.y - (h.height || 0));
                                                    case "ry":
                                                        if ("ry" == d && "rect" == n.type) break;
                                                    case "cy":
                                                        u.setAttribute(d, m), n.pattern && v(n), (n._.dirty = 1);
                                                        break;
                                                    case "r":
                                                        "rect" == n.type ? f(u, { rx: m, ry: m }) : u.setAttribute(d, m), (n._.dirty = 1);
                                                        break;
                                                    case "src":
                                                        "image" == n.type && u.setAttributeNS(c, "href", m);
                                                        break;
                                                    case "stroke-width":
                                                        (1 == n._.sx && 1 == n._.sy) || (m /= a(s(n._.sx), s(n._.sy)) || 1),
                                                            u.setAttribute(d, m),
                                                        h["stroke-dasharray"] && T(n, h["stroke-dasharray"], r),
                                                        n._.arrows && ("startString" in n._.arrows && $(n, n._.arrows.startString), "endString" in n._.arrows && $(n, n._.arrows.endString, 1));
                                                        break;
                                                    case "stroke-dasharray":
                                                        T(n, m, r);
                                                        break;
                                                    case "fill":
                                                        var P = i(m).match(t._ISURL);
                                                        if (P) {
                                                            C = f("pattern");
                                                            var M = f("image");
                                                            (C.id = t.createUUID()),
                                                                f(C, { x: 0, y: 0, patternUnits: "userSpaceOnUse", height: 1, width: 1 }),
                                                                f(M, { x: 0, y: 0, "xlink:href": P[1] }),
                                                                C.appendChild(M),
                                                                (function (e) {
                                                                    t._preload(P[1], function () {
                                                                        var t = this.offsetWidth,
                                                                            i = this.offsetHeight;
                                                                        f(e, { width: t, height: i }), f(M, { width: t, height: i });
                                                                    });
                                                                })(C),
                                                                n.paper.defs.appendChild(C),
                                                                f(u, { fill: "url(#" + C.id + ")" }),
                                                                (n.pattern = C),
                                                            n.pattern && v(n);
                                                            break;
                                                        }
                                                        var w = t.getRGB(m);
                                                        if (w.error) {
                                                            if (("circle" == n.type || "ellipse" == n.type || "r" != i(m).charAt()) && g(n, m)) {
                                                                if ("opacity" in h || "fill-opacity" in h) {
                                                                    var k = t._g.doc.getElementById(u.getAttribute("fill").replace(/^url\(#|\)$/g, ""));
                                                                    if (k) {
                                                                        var I = k.getElementsByTagName("stop");
                                                                        f(I[I.length - 1], { "stop-opacity": ("opacity" in h ? h.opacity : 1) * ("fill-opacity" in h ? h["fill-opacity"] : 1) });
                                                                    }
                                                                }
                                                                (h.gradient = m), (h.fill = "none");
                                                                break;
                                                            }
                                                        } else
                                                            delete r.gradient,
                                                                delete h.gradient,
                                                            !t.is(h.opacity, "undefined") && t.is(r.opacity, "undefined") && f(u, { opacity: h.opacity }),
                                                            !t.is(h["fill-opacity"], "undefined") && t.is(r["fill-opacity"], "undefined") && f(u, { "fill-opacity": h["fill-opacity"] });
                                                        w[e]("opacity") && f(u, { "fill-opacity": w.opacity > 1 ? w.opacity / 100 : w.opacity });
                                                    case "stroke":
                                                        (w = t.getRGB(m)),
                                                            u.setAttribute(d, w.hex),
                                                        "stroke" == d && w[e]("opacity") && f(u, { "stroke-opacity": w.opacity > 1 ? w.opacity / 100 : w.opacity }),
                                                        "stroke" == d && n._.arrows && ("startString" in n._.arrows && $(n, n._.arrows.startString), "endString" in n._.arrows && $(n, n._.arrows.endString, 1));
                                                        break;
                                                    case "gradient":
                                                        ("circle" == n.type || "ellipse" == n.type || "r" != i(m).charAt()) && g(n, m);
                                                        break;
                                                    case "opacity":
                                                        h.gradient && !h[e]("stroke-opacity") && f(u, { "stroke-opacity": m > 1 ? m / 100 : m });
                                                    case "fill-opacity":
                                                        if (h.gradient) {
                                                            (k = t._g.doc.getElementById(u.getAttribute("fill").replace(/^url\(#|\)$/g, ""))) && f((I = k.getElementsByTagName("stop"))[I.length - 1], { "stop-opacity": m });
                                                            break;
                                                        }
                                                    default:
                                                        "font-size" == d && (m = o(m, 10) + "px");
                                                        var R = d.replace(/(\-.)/g, function (t) {
                                                            return t.substring(1).toUpperCase();
                                                        });
                                                        (u.style[R] = m), (n._.dirty = 1), u.setAttribute(d, m);
                                                }
                                            }
                                        _(n, r), (u.style.visibility = p);
                                    },
                                    _ = function (n, r) {
                                        if ("text" == n.type && (r[e]("text") || r[e]("font") || r[e]("font-size") || r[e]("x") || r[e]("y"))) {
                                            var a = n.attrs,
                                                s = n.node,
                                                u = s.firstChild ? o(t._g.doc.defaultView.getComputedStyle(s.firstChild, "").getPropertyValue("font-size"), 10) : 10;
                                            if (r[e]("text")) {
                                                for (a.text = r.text; s.firstChild; ) s.removeChild(s.firstChild);
                                                for (var l, h = i(r.text).split("\n"), c = [], p = 0, d = h.length; p < d; p++)
                                                    (l = f("tspan")), p && f(l, { dy: 1.2 * u, x: a.x }), l.appendChild(t._g.doc.createTextNode(h[p])), s.appendChild(l), (c[p] = l);
                                            } else for (c = s.getElementsByTagName("tspan"), p = 0, d = c.length; p < d; p++) p ? f(c[p], { dy: 1.2 * u, x: a.x }) : f(c[0], { dy: 0 });
                                            f(s, { x: a.x, y: a.y }), (n._.dirty = 1);
                                            var g = n._getBBox(),
                                                m = a.y - (g.y + g.height / 2);
                                            g.height && m && t.is(m, "finite") && f(c[0], { dy: m });
                                        }
                                    },
                                    A = function (t) {
                                        return t.parentNode && "a" === t.parentNode.tagName.toLowerCase() ? t.parentNode : t;
                                    },
                                    D = function (e, i) {
                                        (this[0] = this.node = e),
                                            (e.raphael = !0),
                                            (this.id = t._oid++),
                                            (e.raphaelid = this.id),
                                            (this.matrix = t.matrix()),
                                            (this.realPath = null),
                                            (this.paper = i),
                                            (this.attrs = this.attrs || {}),
                                            (this._ = { transform: [], sx: 1, sy: 1, deg: 0, dx: 0, dy: 0, dirty: 1 }),
                                        i.bottom || (i.bottom = this),
                                            (this.prev = i.top),
                                        i.top && (i.top.next = this),
                                            (i.top = this),
                                            (this.next = null);
                                    },
                                    C = t.el;
                                (D.prototype = C),
                                    (C.constructor = D),
                                    (t._engine.path = function (t, e) {
                                        var i = f("path");
                                        e.canvas && e.canvas.appendChild(i);
                                        var n = new D(i, e);
                                        return (n.type = "path"), b(n, { fill: "none", stroke: "#000", path: t }), n;
                                    }),
                                    (C.rotate = function (t, e, o) {
                                        if (this.removed) return this;
                                        if (((t = i(t).split(l)).length - 1 && ((e = n(t[1])), (o = n(t[2]))), (t = n(t[0])), null == o && (e = o), null == e || null == o)) {
                                            var r = this.getBBox(1);
                                            (e = r.x + r.width / 2), (o = r.y + r.height / 2);
                                        }
                                        return this.transform(this._.transform.concat([["r", t, e, o]])), this;
                                    }),
                                    (C.scale = function (t, e, o, r) {
                                        if (this.removed) return this;
                                        if (((t = i(t).split(l)).length - 1 && ((e = n(t[1])), (o = n(t[2])), (r = n(t[3]))), (t = n(t[0])), null == e && (e = t), null == r && (o = r), null == o || null == r)) var a = this.getBBox(1);
                                        return (o = null == o ? a.x + a.width / 2 : o), (r = null == r ? a.y + a.height / 2 : r), this.transform(this._.transform.concat([["s", t, e, o, r]])), this;
                                    }),
                                    (C.translate = function (t, e) {
                                        return this.removed || ((t = i(t).split(l)).length - 1 && (e = n(t[1])), (t = n(t[0]) || 0), (e = +e || 0), this.transform(this._.transform.concat([["t", t, e]]))), this;
                                    }),
                                    (C.transform = function (i) {
                                        var n = this._;
                                        if (null == i) return n.transform;
                                        if (
                                            (t._extractTransform(this, i),
                                            this.clip && f(this.clip, { transform: this.matrix.invert() }),
                                            this.pattern && v(this),
                                            this.node && f(this.node, { transform: this.matrix }),
                                            1 != n.sx || 1 != n.sy)
                                        ) {
                                            var o = this.attrs[e]("stroke-width") ? this.attrs["stroke-width"] : 1;
                                            this.attr({ "stroke-width": o });
                                        }
                                        return (n.transform = this.matrix.toTransformString()), this;
                                    }),
                                    (C.hide = function () {
                                        return this.removed || (this.node.style.display = "none"), this;
                                    }),
                                    (C.show = function () {
                                        return this.removed || (this.node.style.display = ""), this;
                                    }),
                                    (C.remove = function () {
                                        var e = A(this.node);
                                        if (!this.removed && e.parentNode) {
                                            var i = this.paper;
                                            for (var n in (i.__set__ && i.__set__.exclude(this),
                                                h.unbind("raphael.*.*." + this.id),
                                            this.gradient && i.defs.removeChild(this.gradient),
                                                t._tear(this, i),
                                                e.parentNode.removeChild(e),
                                                this.removeData(),
                                                this))
                                                this[n] = "function" == typeof this[n] ? t._removedFactory(n) : null;
                                            this.removed = !0;
                                        }
                                    }),
                                    (C._getBBox = function () {
                                        if ("none" == this.node.style.display) {
                                            this.show();
                                            var t = !0;
                                        }
                                        var e,
                                            i = !1;
                                        this.paper.canvas.parentElement ? (e = this.paper.canvas.parentElement.style) : this.paper.canvas.parentNode && (e = this.paper.canvas.parentNode.style),
                                        e && "none" == e.display && ((i = !0), (e.display = ""));
                                        var n = {};
                                        try {
                                            n = this.node.getBBox();
                                        } catch (o) {
                                            n = { x: this.node.clientLeft, y: this.node.clientTop, width: this.node.clientWidth, height: this.node.clientHeight };
                                        } finally {
                                            (n = n || {}), i && (e.display = "none");
                                        }
                                        return t && this.hide(), n;
                                    }),
                                    (C.attr = function (i, n) {
                                        if (this.removed) return this;
                                        if (null == i) {
                                            var o = {};
                                            for (var r in this.attrs) this.attrs[e](r) && (o[r] = this.attrs[r]);
                                            return o.gradient && "none" == o.fill && (o.fill = o.gradient) && delete o.gradient, (o.transform = this._.transform), o;
                                        }
                                        if (null == n && t.is(i, "string")) {
                                            if ("fill" == i && "none" == this.attrs.fill && this.attrs.gradient) return this.attrs.gradient;
                                            if ("transform" == i) return this._.transform;
                                            for (var a = i.split(l), s = {}, u = 0, c = a.length; u < c; u++)
                                                (i = a[u]) in this.attrs ? (s[i] = this.attrs[i]) : t.is(this.paper.customAttributes[i], "function") ? (s[i] = this.paper.customAttributes[i].def) : (s[i] = t._availableAttrs[i]);
                                            return c - 1 ? s : s[a[0]];
                                        }
                                        if (null == n && t.is(i, "array")) {
                                            for (s = {}, u = 0, c = i.length; u < c; u++) s[i[u]] = this.attr(i[u]);
                                            return s;
                                        }
                                        if (null != n) {
                                            var p = {};
                                            p[i] = n;
                                        } else null != i && t.is(i, "object") && (p = i);
                                        for (var d in p) h("raphael.attr." + d + "." + this.id, this, p[d]);
                                        for (d in this.paper.customAttributes)
                                            if (this.paper.customAttributes[e](d) && p[e](d) && t.is(this.paper.customAttributes[d], "function")) {
                                                var f = this.paper.customAttributes[d].apply(this, [].concat(p[d]));
                                                for (var g in ((this.attrs[d] = p[d]), f)) f[e](g) && (p[g] = f[g]);
                                            }
                                        return b(this, p), this;
                                    }),
                                    (C.toFront = function () {
                                        if (this.removed) return this;
                                        var e = A(this.node);
                                        e.parentNode.appendChild(e);
                                        var i = this.paper;
                                        return i.top != this && t._tofront(this, i), this;
                                    }),
                                    (C.toBack = function () {
                                        if (this.removed) return this;
                                        var e = A(this.node),
                                            i = e.parentNode;
                                        return i.insertBefore(e, i.firstChild), t._toback(this, this.paper), this.paper, this;
                                    }),
                                    (C.insertAfter = function (e) {
                                        if (this.removed || !e) return this;
                                        var i = A(this.node),
                                            n = A(e.node || e[e.length - 1].node);
                                        return n.nextSibling ? n.parentNode.insertBefore(i, n.nextSibling) : n.parentNode.appendChild(i), t._insertafter(this, e, this.paper), this;
                                    }),
                                    (C.insertBefore = function (e) {
                                        if (this.removed || !e) return this;
                                        var i = A(this.node),
                                            n = A(e.node || e[0].node);
                                        return n.parentNode.insertBefore(i, n), t._insertbefore(this, e, this.paper), this;
                                    }),
                                    (C.blur = function (e) {
                                        var i = this;
                                        if (0 != +e) {
                                            var n = f("filter"),
                                                o = f("feGaussianBlur");
                                            (i.attrs.blur = e), (n.id = t.createUUID()), f(o, { stdDeviation: +e || 1.5 }), n.appendChild(o), i.paper.defs.appendChild(n), (i._blur = n), f(i.node, { filter: "url(#" + n.id + ")" });
                                        } else i._blur && (i._blur.parentNode.removeChild(i._blur), delete i._blur, delete i.attrs.blur), i.node.removeAttribute("filter");
                                        return i;
                                    }),
                                    (t._engine.circle = function (t, e, i, n) {
                                        var o = f("circle");
                                        t.canvas && t.canvas.appendChild(o);
                                        var r = new D(o, t);
                                        return (r.attrs = { cx: e, cy: i, r: n, fill: "none", stroke: "#000" }), (r.type = "circle"), f(o, r.attrs), r;
                                    }),
                                    (t._engine.rect = function (t, e, i, n, o, r) {
                                        var a = f("rect");
                                        t.canvas && t.canvas.appendChild(a);
                                        var s = new D(a, t);
                                        return (s.attrs = { x: e, y: i, width: n, height: o, rx: r || 0, ry: r || 0, fill: "none", stroke: "#000" }), (s.type = "rect"), f(a, s.attrs), s;
                                    }),
                                    (t._engine.ellipse = function (t, e, i, n, o) {
                                        var r = f("ellipse");
                                        t.canvas && t.canvas.appendChild(r);
                                        var a = new D(r, t);
                                        return (a.attrs = { cx: e, cy: i, rx: n, ry: o, fill: "none", stroke: "#000" }), (a.type = "ellipse"), f(r, a.attrs), a;
                                    }),
                                    (t._engine.image = function (t, e, i, n, o, r) {
                                        var a = f("image");
                                        f(a, { x: i, y: n, width: o, height: r, preserveAspectRatio: "none" }), a.setAttributeNS(c, "href", e), t.canvas && t.canvas.appendChild(a);
                                        var s = new D(a, t);
                                        return (s.attrs = { x: i, y: n, width: o, height: r, src: e }), (s.type = "image"), s;
                                    }),
                                    (t._engine.text = function (e, i, n, o) {
                                        var r = f("text");
                                        e.canvas && e.canvas.appendChild(r);
                                        var a = new D(r, e);
                                        return (
                                            (a.attrs = { x: i, y: n, "text-anchor": "middle", text: o, "font-family": t._availableAttrs["font-family"], "font-size": t._availableAttrs["font-size"], stroke: "none", fill: "#000" }),
                                                (a.type = "text"),
                                                b(a, a.attrs),
                                                a
                                        );
                                    }),
                                    (t._engine.setSize = function (t, e) {
                                        return (
                                            (this.width = t || this.width),
                                                (this.height = e || this.height),
                                                this.canvas.setAttribute("width", this.width),
                                                this.canvas.setAttribute("height", this.height),
                                            this._viewBox && this.setViewBox.apply(this, this._viewBox),
                                                this
                                        );
                                    }),
                                    (t._engine.create = function () {
                                        var e = t._getContainer.apply(0, arguments),
                                            i = e && e.container,
                                            n = e.x,
                                            o = e.y,
                                            r = e.width,
                                            a = e.height;
                                        if (!i) throw Error("SVG container not found.");
                                        var s,
                                            u = f("svg"),
                                            l = "overflow:hidden;";
                                        return (
                                            (n = n || 0),
                                                (o = o || 0),
                                                f(u, { height: (a = a || 342), version: 1.1, width: (r = r || 512), xmlns: "http://www.w3.org/2000/svg", "xmlns:xlink": "http://www.w3.org/1999/xlink" }),
                                                1 == i
                                                    ? ((u.style.cssText = l + "position:absolute;left:" + n + "px;top:" + o + "px"), t._g.doc.body.appendChild(u), (s = 1))
                                                    : ((u.style.cssText = l + "position:relative"), i.firstChild ? i.insertBefore(u, i.firstChild) : i.appendChild(u)),
                                                ((i = new t._Paper()).width = r),
                                                (i.height = a),
                                                (i.canvas = u),
                                                i.clear(),
                                                (i._left = i._top = 0),
                                            s && (i.renderfix = function () {}),
                                                i.renderfix(),
                                                i
                                        );
                                    }),
                                    (t._engine.setViewBox = function (t, e, i, n, o) {
                                        h("raphael.setViewBox", this, this._viewBox, [t, e, i, n, o]);
                                        var r,
                                            s,
                                            u = this.getSize(),
                                            l = a(i / u.width, n / u.height),
                                            c = this.top;
                                        for (
                                            null == t ? (this._vbSize && (l = 1), delete this._vbSize, (r = "0 0 " + this.width + " " + this.height)) : ((this._vbSize = l), (r = t + " " + e + " " + i + " " + n)),
                                                f(this.canvas, { viewBox: r, preserveAspectRatio: o ? "xMidYMid meet" : "xMinYMin" });
                                            l && c;

                                        )
                                            (s = "stroke-width" in c.attrs ? c.attrs["stroke-width"] : 1), c.attr({ "stroke-width": s }), (c._.dirty = 1), (c._.dirtyT = 1), (c = c.prev);
                                        return (this._viewBox = [t, e, i, n, !!o]), this;
                                    }),
                                    (t.prototype.renderfix = function () {
                                        var t,
                                            e = this.canvas,
                                            i = e.style;
                                        try {
                                            t = e.getScreenCTM() || e.createSVGMatrix();
                                        } catch (n) {
                                            t = e.createSVGMatrix();
                                        }
                                        var o = -t.e % 1,
                                            r = -t.f % 1;
                                        (o || r) && (o && ((this._left = (this._left + o) % 1), (i.left = this._left + "px")), r && ((this._top = (this._top + r) % 1), (i.top = this._top + "px")));
                                    }),
                                    (t.prototype.clear = function () {
                                        t.eve("raphael.clear", this);
                                        for (var e = this.canvas; e.firstChild; ) e.removeChild(e.firstChild);
                                        (this.bottom = this.top = null),
                                            (this.desc = f("desc")).appendChild(t._g.doc.createTextNode("Created with Rapha\xebl " + t.version)),
                                            e.appendChild(this.desc),
                                            e.appendChild((this.defs = f("defs")));
                                    }),
                                    (t.prototype.remove = function () {
                                        for (var e in (h("raphael.remove", this), this.canvas.parentNode && this.canvas.parentNode.removeChild(this.canvas), this)) this[e] = "function" == typeof this[e] ? t._removedFactory(e) : null;
                                    });
                                var E = t.st;
                                for (var L in C)
                                    C[e](L) &&
                                    !E[e](L) &&
                                    (E[L] = (function (t) {
                                        return function () {
                                            var e = arguments;
                                            return this.forEach(function (i) {
                                                i[t].apply(i, e);
                                            });
                                        };
                                    })(L));
                            }
                        }.apply(e, (n = [i(1)]))) && (t.exports = o);
                    },
                    function (t, e, i) {
                        var n, o;
                        void 0 !==
                        (o = function (t) {
                            if (!t || t.vml) {
                                var e,
                                    i = "hasOwnProperty",
                                    n = String,
                                    o = parseFloat,
                                    r = Math,
                                    a = r.round,
                                    s = r.max,
                                    u = r.min,
                                    l = r.abs,
                                    h = "fill",
                                    c = /[, ]+/,
                                    p = t.eve,
                                    d = { M: "m", L: "l", C: "c", Z: "x", m: "t", l: "r", c: "v", z: "x" },
                                    f = /([clmz]),?([^clmz]*)/gi,
                                    g = / progid:\S+Blur\([^\)]+\)/g,
                                    m = /-?[^,\s-]+/g,
                                    y = "position:absolute;left:0;top:0;width:1px;height:1px;behavior:url(#default#VML)",
                                    v = { path: 1, rect: 1, image: 1 },
                                    $ = { circle: 1, ellipse: 1 },
                                    x = function (e) {
                                        var i = /[ahqstv]/gi,
                                            o = t._pathToAbsolute;
                                        if ((n(e).match(i) && (o = t._path2curve), (i = /[clmz]/g), o == t._pathToAbsolute && !n(e).match(i))) {
                                            var r = n(e).replace(f, function (t, e, i) {
                                                var n = [],
                                                    o = "m" == e.toLowerCase(),
                                                    r = d[e];
                                                return (
                                                    i.replace(m, function (t) {
                                                        o && 2 == n.length && ((r += n + d["m" == e ? "l" : "L"]), (n = [])), n.push(a(21600 * t));
                                                    }),
                                                    r + n
                                                );
                                            });
                                            return r;
                                        }
                                        var s,
                                            u,
                                            l = o(e);
                                        r = [];
                                        for (var h = 0, c = l.length; h < c; h++) {
                                            (s = l[h]), "z" == (u = l[h][0].toLowerCase()) && (u = "x");
                                            for (var p = 1, g = s.length; p < g; p++) u += a(21600 * s[p]) + (p != g - 1 ? "," : "");
                                            r.push(u);
                                        }
                                        return r.join(" ");
                                    },
                                    T = function (e, i, n) {
                                        var o = t.matrix();
                                        return o.rotate(-e, 0.5, 0.5), { dx: o.x(i, n), dy: o.y(i, n) };
                                    },
                                    b = function (t, e, i, n, o, r) {
                                        var a = t._,
                                            s = t.matrix,
                                            u = a.fillpos,
                                            c = t.node,
                                            p = c.style,
                                            d = 1,
                                            f = "",
                                            g = 21600 / e,
                                            m = 21600 / i;
                                        if (((p.visibility = "hidden"), e && i)) {
                                            if (((c.coordsize = l(g) + " " + l(m)), (p.rotation = r * (e * i < 0 ? -1 : 1)), r)) {
                                                var y = T(r, n, o);
                                                (n = y.dx), (o = y.dy);
                                            }
                                            if ((e < 0 && (f += "x"), i < 0 && (f += " y") && (d = -1), (p.flip = f), (c.coordorigin = -(n * g) + " " + -(o * m)), u || a.fillsize)) {
                                                var v = c.getElementsByTagName(h);
                                                (v = v && v[0]),
                                                    c.removeChild(v),
                                                u && ((y = T(r, s.x(u[0], u[1]), s.y(u[0], u[1]))), (v.position = y.dx * d + " " + y.dy * d)),
                                                a.fillsize && (v.size = a.fillsize[0] * l(e) + " " + a.fillsize[1] * l(i)),
                                                    c.appendChild(v);
                                            }
                                            p.visibility = "visible";
                                        }
                                    };
                                t.toString = function () {
                                    return "Your browser doesn’t support SVG. Falling down to VML.\nYou are running Rapha\xebl " + this.version;
                                };
                                var _ = function (t, e, i) {
                                        for (var o = n(e).toLowerCase().split("-"), r = i ? "end" : "start", a = o.length, s = "classic", u = "medium", l = "medium"; a--; )
                                            switch (o[a]) {
                                                case "block":
                                                case "classic":
                                                case "oval":
                                                case "diamond":
                                                case "open":
                                                case "none":
                                                    s = o[a];
                                                    break;
                                                case "wide":
                                                case "narrow":
                                                    l = o[a];
                                                    break;
                                                case "long":
                                                case "short":
                                                    u = o[a];
                                            }
                                        var h = t.node.getElementsByTagName("stroke")[0];
                                        (h[r + "arrow"] = s), (h[r + "arrowlength"] = u), (h[r + "arrowwidth"] = l);
                                    },
                                    A = function (r, l) {
                                        r.attrs = r.attrs || {};
                                        var p = r.node,
                                            d = r.attrs,
                                            f = p.style,
                                            g = v[r.type] && (l.x != d.x || l.y != d.y || l.width != d.width || l.height != d.height || l.cx != d.cx || l.cy != d.cy || l.rx != d.rx || l.ry != d.ry || l.r != d.r),
                                            m = $[r.type] && (d.cx != l.cx || d.cy != l.cy || d.r != l.r || d.rx != l.rx || d.ry != l.ry),
                                            y = r;
                                        for (var T in l) l[i](T) && (d[T] = l[T]);
                                        if (
                                            (g && ((d.path = t._getPath[r.type](r)), (r._.dirty = 1)),
                                            l.href && (p.href = l.href),
                                            l.title && (p.title = l.title),
                                            l.target && (p.target = l.target),
                                            l.cursor && (f.cursor = l.cursor),
                                            "blur" in l && r.blur(l.blur),
                                            ((l.path && "path" == r.type) || g) &&
                                            ((p.path = x(~n(d.path).toLowerCase().indexOf("r") ? t._pathToAbsolute(d.path) : d.path)),
                                                (r._.dirty = 1),
                                            "image" == r.type && ((r._.fillpos = [d.x, d.y]), (r._.fillsize = [d.width, d.height]), b(r, 1, 1, 0, 0, 0))),
                                            "transform" in l && r.transform(l.transform),
                                                m)
                                        ) {
                                            var A = +d.cx,
                                                C = +d.cy,
                                                E = +d.rx || +d.r || 0,
                                                L = +d.ry || +d.r || 0;
                                            (p.path = t.format("ar{0},{1},{2},{3},{4},{1},{4},{1}x", a((A - E) * 21600), a((C - L) * 21600), a((A + E) * 21600), a((C + L) * 21600), a(21600 * A))), (r._.dirty = 1);
                                        }
                                        if ("clip-rect" in l) {
                                            var S = n(l["clip-rect"]).split(c);
                                            if (4 == S.length) {
                                                (S[2] = +S[2] + +S[0]), (S[3] = +S[3] + +S[1]);
                                                var P = p.clipRect || t._g.doc.createElement("div"),
                                                    M = P.style;
                                                (M.clip = t.format("rect({1}px {2}px {3}px {0}px)", S)),
                                                p.clipRect ||
                                                ((M.position = "absolute"),
                                                    (M.top = 0),
                                                    (M.left = 0),
                                                    (M.width = r.paper.width + "px"),
                                                    (M.height = r.paper.height + "px"),
                                                    p.parentNode.insertBefore(P, p),
                                                    P.appendChild(p),
                                                    (p.clipRect = P));
                                            }
                                            l["clip-rect"] || (p.clipRect && (p.clipRect.style.clip = "auto"));
                                        }
                                        if (r.textpath) {
                                            var w = r.textpath.style;
                                            l.font && (w.font = l.font),
                                            l["font-family"] && (w.fontFamily = '"' + l["font-family"].split(",")[0].replace(/^['"]+|['"]+$/g, "") + '"'),
                                            l["font-size"] && (w.fontSize = l["font-size"]),
                                            l["font-weight"] && (w.fontWeight = l["font-weight"]),
                                            l["font-style"] && (w.fontStyle = l["font-style"]);
                                        }
                                        if (
                                            ("arrow-start" in l && _(y, l["arrow-start"]),
                                            "arrow-end" in l && _(y, l["arrow-end"], 1),
                                            null != l.opacity ||
                                            null != l["stroke-width"] ||
                                            null != l.fill ||
                                            null != l.src ||
                                            null != l.stroke ||
                                            null != l["stroke-width"] ||
                                            null != l["stroke-opacity"] ||
                                            null != l["fill-opacity"] ||
                                            null != l["stroke-dasharray"] ||
                                            null != l["stroke-miterlimit"] ||
                                            null != l["stroke-linejoin"] ||
                                            null != l["stroke-linecap"])
                                        ) {
                                            var k = p.getElementsByTagName(h),
                                                I = !1;
                                            if (
                                                ((k = k && k[0]) || (I = k = e(h)),
                                                "image" == r.type && l.src && (k.src = l.src),
                                                l.fill && (k.on = !0),
                                                (null != k.on && "none" != l.fill && null !== l.fill) || (k.on = !1),
                                                k.on && l.fill)
                                            ) {
                                                var R = n(l.fill).match(t._ISURL);
                                                if (R) {
                                                    k.parentNode == p && p.removeChild(k), (k.rotate = !0), (k.src = R[1]), (k.type = "tile");
                                                    var B = r.getBBox(1);
                                                    (k.position = B.x + " " + B.y),
                                                        (r._.fillpos = [B.x, B.y]),
                                                        t._preload(R[1], function () {
                                                            r._.fillsize = [this.offsetWidth, this.offsetHeight];
                                                        });
                                                } else
                                                    (k.color = t.getRGB(l.fill).hex),
                                                        (k.src = ""),
                                                        (k.type = "solid"),
                                                    t.getRGB(l.fill).error && (y.type in { circle: 1, ellipse: 1 } || "r" != n(l.fill).charAt()) && D(y, l.fill, k) && ((d.fill = "none"), (d.gradient = l.fill), (k.rotate = !1));
                                            }
                                            if ("fill-opacity" in l || "opacity" in l) {
                                                var O = ((+d["fill-opacity"] + 1 || 2) - 1) * ((+d.opacity + 1 || 2) - 1) * ((+t.getRGB(l.fill).o + 1 || 2) - 1);
                                                (O = u(s(O, 0), 1)), (k.opacity = O), k.src && (k.color = "none");
                                            }
                                            p.appendChild(k);
                                            var N = p.getElementsByTagName("stroke") && p.getElementsByTagName("stroke")[0],
                                                F = !1;
                                            N || (F = N = e("stroke")),
                                            ((l.stroke && "none" != l.stroke) || l["stroke-width"] || null != l["stroke-opacity"] || l["stroke-dasharray"] || l["stroke-miterlimit"] || l["stroke-linejoin"] || l["stroke-linecap"]) &&
                                            (N.on = !0),
                                            ("none" == l.stroke || null === l.stroke || null == N.on || 0 == l.stroke || 0 == l["stroke-width"]) && (N.on = !1);
                                            var G = t.getRGB(l.stroke);
                                            N.on && l.stroke && (N.color = G.hex), (O = ((+d["stroke-opacity"] + 1 || 2) - 1) * ((+d.opacity + 1 || 2) - 1) * ((+G.o + 1 || 2) - 1));
                                            var H = 0.75 * (o(l["stroke-width"]) || 1);
                                            if (
                                                ((O = u(s(O, 0), 1)),
                                                null == l["stroke-width"] && (H = d["stroke-width"]),
                                                l["stroke-width"] && (N.weight = H),
                                                H && H < 1 && (O *= H) && (N.weight = 1),
                                                    (N.opacity = O),
                                                l["stroke-linejoin"] && (N.joinstyle = l["stroke-linejoin"] || "miter"),
                                                    (N.miterlimit = l["stroke-miterlimit"] || 8),
                                                l["stroke-linecap"] && (N.endcap = "butt" == l["stroke-linecap"] ? "flat" : "square" == l["stroke-linecap"] ? "square" : "round"),
                                                "stroke-dasharray" in l)
                                            ) {
                                                var V = {
                                                    "-": "shortdash",
                                                    ".": "shortdot",
                                                    "-.": "shortdashdot",
                                                    "-..": "shortdashdotdot",
                                                    ". ": "dot",
                                                    "- ": "dash",
                                                    "--": "longdash",
                                                    "- .": "dashdot",
                                                    "--.": "longdashdot",
                                                    "--..": "longdashdotdot",
                                                };
                                                N.dashstyle = V[i](l["stroke-dasharray"]) ? V[l["stroke-dasharray"]] : "";
                                            }
                                            F && p.appendChild(N);
                                        }
                                        if ("text" == y.type) {
                                            y.paper.canvas.style.display = "";
                                            var z = y.paper.span,
                                                W = d.font && d.font.match(/\d+(?:\.\d*)?(?=px)/);
                                            (f = z.style),
                                            d.font && (f.font = d.font),
                                            d["font-family"] && (f.fontFamily = d["font-family"]),
                                            d["font-weight"] && (f.fontWeight = d["font-weight"]),
                                            d["font-style"] && (f.fontStyle = d["font-style"]),
                                                (W = o(d["font-size"] || (W && W[0])) || 10),
                                                (f.fontSize = 100 * W + "px"),
                                            y.textpath.string && (z.innerHTML = n(y.textpath.string).replace(/</g, "&#60;").replace(/&/g, "&#38;").replace(/\n/g, "<br>"));
                                            var Y = z.getBoundingClientRect();
                                            (y.W = d.w = (Y.right - Y.left) / 100),
                                                (y.H = d.h = (Y.bottom - Y.top) / 100),
                                                (y.X = d.x),
                                                (y.Y = d.y + y.H / 2),
                                            ("x" in l || "y" in l) && (y.path.v = t.format("m{0},{1}l{2},{1}", a(21600 * d.x), a(21600 * d.y), a(21600 * d.x) + 1));
                                            for (var U = ["x", "y", "text", "font", "font-family", "font-weight", "font-style", "font-size"], X = 0, Z = U.length; X < Z; X++)
                                                if (U[X] in l) {
                                                    y._.dirty = 1;
                                                    break;
                                                }
                                            switch (d["text-anchor"]) {
                                                case "start":
                                                    (y.textpath.style["v-text-align"] = "left"), (y.bbx = y.W / 2);
                                                    break;
                                                case "end":
                                                    (y.textpath.style["v-text-align"] = "right"), (y.bbx = -y.W / 2);
                                                    break;
                                                default:
                                                    (y.textpath.style["v-text-align"] = "center"), (y.bbx = 0);
                                            }
                                            y.textpath.style["v-text-kern"] = !0;
                                        }
                                    },
                                    D = function (e, i, a) {
                                        e.attrs = e.attrs || {};
                                        var s = (e.attrs, Math.pow),
                                            u = "linear",
                                            l = ".5 .5";
                                        if (
                                            ((e.attrs.gradient = i),
                                                (i = (i = n(i).replace(t._radial_gradient, function (t, e, i) {
                                                    return (u = "radial"), e && i && ((e = o(e)), (i = o(i)), s(e - 0.5, 2) + s(i - 0.5, 2) > 0.25 && (i = r.sqrt(0.25 - s(e - 0.5, 2)) * (2 * (i > 0.5) - 1) + 0.5), (l = e + " " + i)), "";
                                                })).split(/\s*\-\s*/)),
                                            "linear" == u)
                                        ) {
                                            var h = i.shift();
                                            if (((h = -o(h)), isNaN(h))) return null;
                                        }
                                        var c = t._parseDots(i);
                                        if (!c) return null;
                                        if (((e = e.shape || e.node), c.length)) {
                                            e.removeChild(a), (a.on = !0), (a.method = "none"), (a.color = c[0].color), (a.color2 = c[c.length - 1].color);
                                            for (var p = [], d = 0, f = c.length; d < f; d++) c[d].offset && p.push(c[d].offset + " " + c[d].color);
                                            (a.colors = p.length ? p.join() : "0% " + a.color),
                                                "radial" == u ? ((a.type = "gradientTitle"), (a.focus = "100%"), (a.focussize = "0 0"), (a.focusposition = l), (a.angle = 0)) : ((a.type = "gradient"), (a.angle = (270 - h) % 360)),
                                                e.appendChild(a);
                                        }
                                        return 1;
                                    },
                                    C = function (e, i) {
                                        (this[0] = this.node = e),
                                            (e.raphael = !0),
                                            (this.id = t._oid++),
                                            (e.raphaelid = this.id),
                                            (this.X = 0),
                                            (this.Y = 0),
                                            (this.attrs = {}),
                                            (this.paper = i),
                                            (this.matrix = t.matrix()),
                                            (this._ = { transform: [], sx: 1, sy: 1, dx: 0, dy: 0, deg: 0, dirty: 1, dirtyT: 1 }),
                                        i.bottom || (i.bottom = this),
                                            (this.prev = i.top),
                                        i.top && (i.top.next = this),
                                            (i.top = this),
                                            (this.next = null);
                                    },
                                    E = t.el;
                                (C.prototype = E),
                                    (E.constructor = C),
                                    (E.transform = function (e) {
                                        if (null == e) return this._.transform;
                                        var i,
                                            o = this.paper._viewBoxShift,
                                            r = o ? "s" + [o.scale, o.scale] + "-1-1t" + [o.dx, o.dy] : "";
                                        o && (i = e = n(e).replace(/\.{3}|\u2026/g, this._.transform || "")), t._extractTransform(this, r + e);
                                        var a,
                                            s = this.matrix.clone(),
                                            u = this.skew,
                                            l = this.node,
                                            h = ~n(this.attrs.fill).indexOf("-"),
                                            c = !n(this.attrs.fill).indexOf("url(");
                                        if ((s.translate(1, 1), c || h || "image" == this.type)) {
                                            if (((u.matrix = "1 0 0 1"), (u.offset = "0 0"), (a = s.split()), (h && a.noRotation) || !a.isSimple)) {
                                                l.style.filter = s.toFilter();
                                                var p = this.getBBox(),
                                                    d = this.getBBox(1),
                                                    f = p.x - d.x,
                                                    g = p.y - d.y;
                                                (l.coordorigin = -(21600 * f) + " " + -(21600 * g)), b(this, 1, 1, f, g, 0);
                                            } else (l.style.filter = ""), b(this, a.scalex, a.scaley, a.dx, a.dy, a.rotate);
                                        } else (l.style.filter = ""), (u.matrix = n(s)), (u.offset = s.offset());
                                        return null !== i && ((this._.transform = i), t._extractTransform(this, i)), this;
                                    }),
                                    (E.rotate = function (t, e, i) {
                                        if (this.removed) return this;
                                        if (null != t) {
                                            if (((t = n(t).split(c)).length - 1 && ((e = o(t[1])), (i = o(t[2]))), (t = o(t[0])), null == i && (e = i), null == e || null == i)) {
                                                var r = this.getBBox(1);
                                                (e = r.x + r.width / 2), (i = r.y + r.height / 2);
                                            }
                                            return (this._.dirtyT = 1), this.transform(this._.transform.concat([["r", t, e, i]])), this;
                                        }
                                    }),
                                    (E.translate = function (t, e) {
                                        return (
                                            this.removed ||
                                            ((t = n(t).split(c)).length - 1 && (e = o(t[1])),
                                                (t = o(t[0]) || 0),
                                                (e = +e || 0),
                                            this._.bbox && ((this._.bbox.x += t), (this._.bbox.y += e)),
                                                this.transform(this._.transform.concat([["t", t, e]]))),
                                                this
                                        );
                                    }),
                                    (E.scale = function (t, e, i, r) {
                                        if (this.removed) return this;
                                        if (
                                            ((t = n(t).split(c)).length - 1 && ((e = o(t[1])), (i = o(t[2])), (r = o(t[3])), isNaN(i) && (i = null), isNaN(r) && (r = null)),
                                                (t = o(t[0])),
                                            null == e && (e = t),
                                            null == r && (i = r),
                                            null == i || null == r)
                                        )
                                            var a = this.getBBox(1);
                                        return (i = null == i ? a.x + a.width / 2 : i), (r = null == r ? a.y + a.height / 2 : r), this.transform(this._.transform.concat([["s", t, e, i, r]])), (this._.dirtyT = 1), this;
                                    }),
                                    (E.hide = function () {
                                        return this.removed || (this.node.style.display = "none"), this;
                                    }),
                                    (E.show = function () {
                                        return this.removed || (this.node.style.display = ""), this;
                                    }),
                                    (E.auxGetBBox = t.el.getBBox),
                                    (E.getBBox = function () {
                                        var t = this.auxGetBBox();
                                        if (this.paper && this.paper._viewBoxShift) {
                                            var e = {},
                                                i = 1 / this.paper._viewBoxShift.scale;
                                            return (
                                                (e.x = t.x - this.paper._viewBoxShift.dx),
                                                    (e.x *= i),
                                                    (e.y = t.y - this.paper._viewBoxShift.dy),
                                                    (e.y *= i),
                                                    (e.width = t.width * i),
                                                    (e.height = t.height * i),
                                                    (e.x2 = e.x + e.width),
                                                    (e.y2 = e.y + e.height),
                                                    e
                                            );
                                        }
                                        return t;
                                    }),
                                    (E._getBBox = function () {
                                        return this.removed ? {} : { x: this.X + (this.bbx || 0) - this.W / 2, y: this.Y - this.H, width: this.W, height: this.H };
                                    }),
                                    (E.remove = function () {
                                        if (!this.removed && this.node.parentNode) {
                                            for (var e in (this.paper.__set__ && this.paper.__set__.exclude(this),
                                                t.eve.unbind("raphael.*.*." + this.id),
                                                t._tear(this, this.paper),
                                                this.node.parentNode.removeChild(this.node),
                                            this.shape && this.shape.parentNode.removeChild(this.shape),
                                                this))
                                                this[e] = "function" == typeof this[e] ? t._removedFactory(e) : null;
                                            this.removed = !0;
                                        }
                                    }),
                                    (E.attr = function (e, n) {
                                        if (this.removed) return this;
                                        if (null == e) {
                                            var o,
                                                r = {};
                                            for (var a in this.attrs) this.attrs[i](a) && (r[a] = this.attrs[a]);
                                            return r.gradient && "none" == r.fill && (r.fill = r.gradient) && delete r.gradient, (r.transform = this._.transform), r;
                                        }
                                        if (null == n && t.is(e, "string")) {
                                            if (e == h && "none" == this.attrs.fill && this.attrs.gradient) return this.attrs.gradient;
                                            for (var s = e.split(c), u = {}, l = 0, d = s.length; l < d; l++)
                                                (e = s[l]) in this.attrs ? (u[e] = this.attrs[e]) : t.is(this.paper.customAttributes[e], "function") ? (u[e] = this.paper.customAttributes[e].def) : (u[e] = t._availableAttrs[e]);
                                            return d - 1 ? u : u[s[0]];
                                        }
                                        if (this.attrs && null == n && t.is(e, "array")) {
                                            for (u = {}, l = 0, d = e.length; l < d; l++) u[e[l]] = this.attr(e[l]);
                                            return u;
                                        }
                                        for (var f in (null != n && ((o = {})[e] = n), null == n && t.is(e, "object") && (o = e), o)) p("raphael.attr." + f + "." + this.id, this, o[f]);
                                        if (o) {
                                            for (f in this.paper.customAttributes)
                                                if (this.paper.customAttributes[i](f) && o[i](f) && t.is(this.paper.customAttributes[f], "function")) {
                                                    var g = this.paper.customAttributes[f].apply(this, [].concat(o[f]));
                                                    for (var m in ((this.attrs[f] = o[f]), g)) g[i](m) && (o[m] = g[m]);
                                                }
                                            o.text && "text" == this.type && (this.textpath.string = o.text), A(this, o);
                                        }
                                        return this;
                                    }),
                                    (E.toFront = function () {
                                        return this.removed || this.node.parentNode.appendChild(this.node), this.paper && this.paper.top != this && t._tofront(this, this.paper), this;
                                    }),
                                    (E.toBack = function () {
                                        return this.removed || (this.node.parentNode.firstChild != this.node && (this.node.parentNode.insertBefore(this.node, this.node.parentNode.firstChild), t._toback(this, this.paper))), this;
                                    }),
                                    (E.insertAfter = function (e) {
                                        return (
                                            this.removed ||
                                            (e.constructor == t.st.constructor && (e = e[e.length - 1]),
                                                e.node.nextSibling ? e.node.parentNode.insertBefore(this.node, e.node.nextSibling) : e.node.parentNode.appendChild(this.node),
                                                t._insertafter(this, e, this.paper)),
                                                this
                                        );
                                    }),
                                    (E.insertBefore = function (e) {
                                        return this.removed || (e.constructor == t.st.constructor && (e = e[0]), e.node.parentNode.insertBefore(this.node, e.node), t._insertbefore(this, e, this.paper)), this;
                                    }),
                                    (E.blur = function (e) {
                                        var i = this.node.runtimeStyle,
                                            n = i.filter;
                                        return (
                                            (n = n.replace(g, "")),
                                                0 != +e
                                                    ? ((this.attrs.blur = e), (i.filter = n + "  progid:DXImageTransform.Microsoft.Blur(pixelradius=" + (+e || 1.5) + ")"), (i.margin = t.format("-{0}px 0 0 -{0}px", a(+e || 1.5))))
                                                    : ((i.filter = n), (i.margin = 0), delete this.attrs.blur),
                                                this
                                        );
                                    }),
                                    (t._engine.path = function (t, i) {
                                        var n = e("shape");
                                        (n.style.cssText = y), (n.coordsize = "21600 21600"), (n.coordorigin = i.coordorigin);
                                        var o = new C(n, i),
                                            r = { fill: "none", stroke: "#000" };
                                        t && (r.path = t), (o.type = "path"), (o.path = []), (o.Path = ""), A(o, r), i.canvas && i.canvas.appendChild(n);
                                        var a = e("skew");
                                        return (a.on = !0), n.appendChild(a), (o.skew = a), o.transform(""), o;
                                    }),
                                    (t._engine.rect = function (e, i, n, o, r, a) {
                                        var s = t._rectPath(i, n, o, r, a),
                                            u = e.path(s),
                                            l = u.attrs;
                                        return (u.X = l.x = i), (u.Y = l.y = n), (u.W = l.width = o), (u.H = l.height = r), (l.r = a), (l.path = s), (u.type = "rect"), u;
                                    }),
                                    (t._engine.ellipse = function (t, e, i, n, o) {
                                        var r = t.path();
                                        return r.attrs, (r.X = e - n), (r.Y = i - o), (r.W = 2 * n), (r.H = 2 * o), (r.type = "ellipse"), A(r, { cx: e, cy: i, rx: n, ry: o }), r;
                                    }),
                                    (t._engine.circle = function (t, e, i, n) {
                                        var o = t.path();
                                        return o.attrs, (o.X = e - n), (o.Y = i - n), (o.W = o.H = 2 * n), (o.type = "circle"), A(o, { cx: e, cy: i, r: n }), o;
                                    }),
                                    (t._engine.image = function (e, i, n, o, r, a) {
                                        var s = t._rectPath(n, o, r, a),
                                            u = e.path(s).attr({ stroke: "none" }),
                                            l = u.attrs,
                                            c = u.node,
                                            p = c.getElementsByTagName(h)[0];
                                        return (
                                            (l.src = i),
                                                (u.X = l.x = n),
                                                (u.Y = l.y = o),
                                                (u.W = l.width = r),
                                                (u.H = l.height = a),
                                                (l.path = s),
                                                (u.type = "image"),
                                            p.parentNode == c && c.removeChild(p),
                                                (p.rotate = !0),
                                                (p.src = i),
                                                (p.type = "tile"),
                                                (u._.fillpos = [n, o]),
                                                (u._.fillsize = [r, a]),
                                                c.appendChild(p),
                                                b(u, 1, 1, 0, 0, 0),
                                                u
                                        );
                                    }),
                                    (t._engine.text = function (i, o, r, s) {
                                        var u = e("shape"),
                                            l = e("path"),
                                            h = e("textpath");
                                        (o = o || 0),
                                            (r = r || 0),
                                            (s = s || ""),
                                            (l.v = t.format("m{0},{1}l{2},{1}", a(21600 * o), a(21600 * r), a(21600 * o) + 1)),
                                            (l.textpathok = !0),
                                            (h.string = n(s)),
                                            (h.on = !0),
                                            (u.style.cssText = y),
                                            (u.coordsize = "21600 21600"),
                                            (u.coordorigin = "0 0");
                                        var c = new C(u, i),
                                            p = { fill: "#000", stroke: "none", font: t._availableAttrs.font, text: s };
                                        (c.shape = u),
                                            (c.path = l),
                                            (c.textpath = h),
                                            (c.type = "text"),
                                            (c.attrs.text = n(s)),
                                            (c.attrs.x = o),
                                            (c.attrs.y = r),
                                            (c.attrs.w = 1),
                                            (c.attrs.h = 1),
                                            A(c, p),
                                            u.appendChild(h),
                                            u.appendChild(l),
                                            i.canvas.appendChild(u);
                                        var d = e("skew");
                                        return (d.on = !0), u.appendChild(d), (c.skew = d), c.transform(""), c;
                                    }),
                                    (t._engine.setSize = function (e, i) {
                                        var n = this.canvas.style;
                                        return (
                                            (this.width = e),
                                                (this.height = i),
                                            e == +e && (e += "px"),
                                            i == +i && (i += "px"),
                                                (n.width = e),
                                                (n.height = i),
                                                (n.clip = "rect(0 " + e + " " + i + " 0)"),
                                            this._viewBox && t._engine.setViewBox.apply(this, this._viewBox),
                                                this
                                        );
                                    }),
                                    (t._engine.setViewBox = function (e, i, n, o, r) {
                                        t.eve("raphael.setViewBox", this, this._viewBox, [e, i, n, o, r]);
                                        var a,
                                            s,
                                            u = this.getSize(),
                                            l = u.width,
                                            h = u.height;
                                        return (
                                            r && ((a = h / o), (s = l / n), n * a < l && (e -= (l - n * a) / 2 / a), o * s < h && (i -= (h - o * s) / 2 / s)),
                                                (this._viewBox = [e, i, n, o, !!r]),
                                                (this._viewBoxShift = { dx: -e, dy: -i, scale: u }),
                                                this.forEach(function (t) {
                                                    t.transform("...");
                                                }),
                                                this
                                        );
                                    }),
                                    (t._engine.initWin = function (t) {
                                        var i = t.document;
                                        i.styleSheets.length < 31 ? i.createStyleSheet().addRule(".rvml", "behavior:url(#default#VML)") : i.styleSheets[0].addRule(".rvml", "behavior:url(#default#VML)");
                                        try {
                                            i.namespaces.rvml || i.namespaces.add("rvml", "urn:schemas-microsoft-com:vml"),
                                                (e = function (t) {
                                                    return i.createElement("<rvml:" + t + ' class="rvml">');
                                                });
                                        } catch (n) {
                                            e = function (t) {
                                                return i.createElement("<" + t + ' xmlns="urn:schemas-microsoft.com:vml" class="rvml">');
                                            };
                                        }
                                    }),
                                    t._engine.initWin(t._g.win),
                                    (t._engine.create = function () {
                                        var e = t._getContainer.apply(0, arguments),
                                            i = e.container,
                                            n = e.height,
                                            o = e.width,
                                            r = e.x,
                                            a = e.y;
                                        if (!i) throw Error("VML container not found.");
                                        var s = new t._Paper(),
                                            u = (s.canvas = t._g.doc.createElement("div")),
                                            l = u.style;
                                        return (
                                            (r = r || 0),
                                                (a = a || 0),
                                                (o = o || 512),
                                                (n = n || 342),
                                                (s.width = o),
                                                (s.height = n),
                                            o == +o && (o += "px"),
                                            n == +n && (n += "px"),
                                                (s.coordsize = "21600000 21600000"),
                                                (s.coordorigin = "0 0"),
                                                (s.span = t._g.doc.createElement("span")),
                                                (s.span.style.cssText = "position:absolute;left:-9999em;top:-9999em;padding:0;margin:0;line-height:1;"),
                                                u.appendChild(s.span),
                                                (l.cssText = t.format("top:0;left:0;width:{0};height:{1};display:inline-block;position:relative;clip:rect(0 {0} {1} 0);overflow:hidden", o, n)),
                                                1 == i ? (t._g.doc.body.appendChild(u), (l.left = r + "px"), (l.top = a + "px"), (l.position = "absolute")) : i.firstChild ? i.insertBefore(u, i.firstChild) : i.appendChild(u),
                                                (s.renderfix = function () {}),
                                                s
                                        );
                                    }),
                                    (t.prototype.clear = function () {
                                        t.eve("raphael.clear", this),
                                            (this.canvas.innerHTML = ""),
                                            (this.span = t._g.doc.createElement("span")),
                                            (this.span.style.cssText = "position:absolute;left:-9999em;top:-9999em;padding:0;margin:0;line-height:1;display:inline;"),
                                            this.canvas.appendChild(this.span),
                                            (this.bottom = this.top = null);
                                    }),
                                    (t.prototype.remove = function () {
                                        for (var e in (t.eve("raphael.remove", this), this.canvas.parentNode.removeChild(this.canvas), this)) this[e] = "function" == typeof this[e] ? t._removedFactory(e) : null;
                                        return !0;
                                    });
                                var L = t.st;
                                for (var S in E)
                                    E[i](S) &&
                                    !L[i](S) &&
                                    (L[S] = (function (t) {
                                        return function () {
                                            var e = arguments;
                                            return this.forEach(function (i) {
                                                i[t].apply(i, e);
                                            });
                                        };
                                    })(S));
                            }
                        }.apply(e, (n = [i(1)]))) && (t.exports = o);
                    },
                ]);
            }),
                (t.exports = n());
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                if (Array.isArray(t)) {
                    for (var e = 0, i = Array(t.length); e < t.length; e++) i[e] = t[e];
                    return i;
                }
                return Array.from(t);
            }
            e.__esModule = !0;
            var r = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                a = i(135),
                s = n(a),
                u = i(137),
                l = n(u),
                h = i(138),
                c = n(h),
                p = i(139),
                d = n(p),
                f = i(143),
                g = n(f),
                m = (function () {
                    function t() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t);
                    }
                    return (
                        (t.prototype.render = function (t, e) {
                            var i = e.groupBounds,
                                n = e.theme,
                                o = e.seriesDataModel,
                                r = e.chartType,
                                a = e.options;
                            return i
                                ? ((this.paper = t),
                                    (this.theme = n),
                                    (this.seriesDataModel = o),
                                    (this.chartType = r),
                                    this.paper.setStart(),
                                    (this.options = a),
                                    (this.theme = n),
                                    (this.groupBars = this._renderBars(i)),
                                    (this.groupBorders = this._renderBarBorders(i)),
                                    (this.overlay = this._renderOverlay()),
                                    (this.groupBounds = i),
                                    this.paper.setFinish())
                                : null;
                        }),
                            (t.prototype._renderOverlay = function () {
                                var t = this._renderBar({ width: 1, height: 1, left: 0, top: 0 }, "#fff", { "fill-opacity": 0 });
                                return t.node.setAttribute("class", "auto-shape-rendering"), t;
                            }),
                            (t.prototype._renderBar = function (t, e, i) {
                                if (t.width < 0 || t.height < 0) return null;
                                var n = g.default.renderRect(this.paper, t, Object.assign({ fill: e, stroke: "none" }, i)).toFront();
                                return n.node.setAttribute("class", "auto-shape-rendering"), n;
                            }),
                            (t.prototype._renderBars = function (t) {
                                var e = this,
                                    i = this.theme.colors,
                                    n = this.options,
                                    o = n.colorByPoint,
                                    r = n.animationDuration;
                                return t.map(function (t, n) {
                                    return t.map(function (t, a) {
                                        if (!t) return null;
                                        var s = e.seriesDataModel.getSeriesItem(n, a),
                                            u = o ? i[n] : i[a];
                                        return { rect: e._renderBar(r ? t.start : t.end, u), color: u, bound: t.end, item: s, groupIndex: n, index: a, isRange: s.isRange };
                                    });
                                });
                            }),
                            (t.prototype._makeRectPoints = function (t) {
                                return {
                                    leftTop: { left: Math.ceil(t.left), top: Math.ceil(t.top) },
                                    rightTop: { left: Math.ceil(t.left + t.width), top: Math.ceil(t.top) },
                                    rightBottom: { left: Math.ceil(t.left + t.width), top: Math.ceil(t.top + t.height) },
                                    leftBottom: { left: Math.ceil(t.left), top: Math.ceil(t.top + t.height) },
                                };
                            }),
                            (t.prototype._makeTopLinePath = function (t, e, i) {
                                var n = i.value,
                                    o = null;
                                if ("bar" === e || n >= 0 || i.isRange) {
                                    var r = Object.assign({}, t.leftTop);
                                    (r.left -= "column" === e || n < 0 ? 1 : 0), (o = g.default.makeLinePath(r, t.rightTop).join(" "));
                                }
                                return o;
                            }),
                            (t.prototype._makeRightLinePath = function (t, e, i) {
                                var n = null;
                                return ("column" === e || i.value >= 0 || i.isRange) && (n = g.default.makeLinePath(t.rightTop, t.rightBottom).join(" ")), n;
                            }),
                            (t.prototype._makeBottomLinePath = function (t, e, i) {
                                var n = null;
                                return ("bar" === e || i.value < 0 || i.isRange) && (n = g.default.makeLinePath(t.leftBottom, t.rightBottom).join(" ")), n;
                            }),
                            (t.prototype._makeLeftLinePath = function (t, e, i) {
                                var n = null;
                                return ("column" === e || i.value < 0 || i.isRange) && (n = g.default.makeLinePath(t.leftTop, t.leftBottom).join(" ")), n;
                            }),
                            (t.prototype._makeBorderLinesPaths = function (t, e, i) {
                                var n = this._makeRectPoints(t),
                                    o = { top: this._makeTopLinePath(n, e, i), right: this._makeRightLinePath(n, e, i), bottom: this._makeBottomLinePath(n, e, i), left: this._makeLeftLinePath(n, e, i) };
                                return (
                                    Object.keys(o).forEach(function (t) {
                                        o[t] || delete o[t];
                                    }),
                                        o
                                );
                            }),
                            (t.prototype._renderBorderLines = function (t, e, i, n) {
                                var o = this._makeBorderLinesPaths(t, i, n),
                                    a = {};
                                return (
                                    Object.entries(o).forEach(function (t) {
                                        var i = r(t, 2),
                                            n = i[0],
                                            o = i[1];
                                        a[n] = g.default.renderLine(self.paper, o, { color: e, strokeWidth: 1 });
                                    }),
                                        a
                                );
                            }),
                            (t.prototype._renderBarBorders = function (t) {
                                var e = this,
                                    i = this.theme.borderColor;
                                return i
                                    ? t.map(function (t, n) {
                                        return t.map(function (t, o) {
                                            if (!t) return null;
                                            var r = e.seriesDataModel.getSeriesItem(n, o);
                                            return e._renderBorderLines(t.start, i, self.chartType, r);
                                        });
                                    })
                                    : null;
                            }),
                            (t.prototype._animateRect = function (t, e, i) {
                                t.animate({ x: e.width ? e.left : e.left - 1, y: e.height ? e.top : e.top - 1, width: e.width ? e.width : 2, height: e.height ? e.height : 2, opacity: e.height && e.width ? 1 : 0.4 }, i, ">");
                            }),
                            (t.prototype._animateBorders = function (t, e, i, n) {
                                var o = this._makeBorderLinesPaths(e, i, n);
                                (0, d.default)(t, function (t, e) {
                                    t.animate({ path: o[e] }, 700, ">");
                                });
                            }),
                            (t.prototype.animate = function (t) {
                                var e = this,
                                    i = this.groupBorders || [],
                                    n = this.options.animationDuration;
                                g.default.forEach2dArray(this.groupBars, function (t, o, r) {
                                    var a = i[o] && i[o][r];
                                    t && (n && e._animateRect(t.rect, t.bound, n), a && e._animateBorders(a, t.bound, e.chartType, t.item));
                                }),
                                t &&
                                (this.callbackTimeout = setTimeout(function () {
                                    t(), delete e.callbackTimeout;
                                }, n));
                            }),
                            (t.prototype.showAnimation = function (t) {
                                var e = this.groupBars[t.groupIndex][t.index],
                                    i = e.bound;
                                this.overlay.attr({ width: i.width + 8, height: i.height + 8, stroke: "#fff", "stroke-width": "1", x: i.left - 4, y: i.top - 4, "fill-opacity": 1 }),
                                    this.resortBarIndex(t.groupIndex),
                                    this.overlay.toFront(),
                                    e.rect.toFront(),
                                this.labelSet && this.labelSet.toFront(),
                                    this.overlay.node.setAttribute("filter", "url(#shadow)");
                            }),
                            (t.prototype.hideAnimation = function (t) {
                                this.resortBarIndex(t.groupIndex), this.overlay.attr({ width: 1, height: 1, x: 0, y: 0, "fill-opacity": 0 }), this.labelSet && this.labelSet.toFront();
                            }),
                            (t.prototype.resortBarIndex = function (t) {
                                this.groupBars[t].forEach(function (t) {
                                    t.rect.toFront();
                                });
                            }),
                            (t.prototype._updateRectBound = function (t, e) {
                                t.attr({ x: e.left, y: e.top, width: e.width, height: e.height });
                            }),
                            (t.prototype.resize = function (t) {
                                var e = this,
                                    i = this.groupBorders || [],
                                    n = t.dimension,
                                    o = t.groupBounds;
                                (this.groupBounds = o),
                                    this.paper.setSize(n.width, n.height),
                                    g.default.forEach2dArray(this.groupBars, function (t, n, r) {
                                        if (t) {
                                            var a = i[n] && i[n][r],
                                                s = o[n][r].end;
                                            (t.bound = s), g.default.updateRectBound(t.rect, s), a && e._updateBordersPath(a, s, e.chartType, t.item);
                                        }
                                    });
                            }),
                            (t.prototype._changeBordersColor = function (t, e) {
                                (0, d.default)(t, function (t) {
                                    t.attr({ stroke: e });
                                });
                            }),
                            (t.prototype._changeBarColor = function (t, e, i) {
                                if ((this.groupBars[t.groupIndex][t.index].rect.attr({ fill: e }), i)) {
                                    var n = this.groupBorders[t.groupIndex][t.index];
                                    this._changeBordersColor(n, i);
                                }
                            }),
                            (t.prototype.selectSeries = function (t) {
                                var e = this.groupBars[t.groupIndex][t.index],
                                    i = s.default.color(e.color),
                                    n = this.theme.selectionColor || g.default.makeChangedLuminanceColor(i.hex, 0.2),
                                    o = this.theme.borderColor;
                                if (o) {
                                    var r = s.default.color(o);
                                    o = g.default.makeChangedLuminanceColor(r.hex, 0.2);
                                }
                                this._changeBarColor(t, n, o);
                            }),
                            (t.prototype.unselectSeries = function (t) {
                                var e = this.groupBars[t.groupIndex][t.index],
                                    i = this.theme.borderColor;
                                this._changeBarColor(t, e.color, i);
                            }),
                            (t.prototype.selectLegend = function (t) {
                                var e = this.groupBorders || [],
                                    i = (0, l.default)(t);
                                g.default.forEach2dArray(this.groupBars, function (n, o, r) {
                                    if (n) {
                                        var a = e[o] && e[o][r],
                                            s = i || t === r ? 1 : 0.3;
                                        n.rect.attr({ "fill-opacity": s }),
                                        a &&
                                        (0, d.default)(a, function (t) {
                                            t.attr({ "stroke-opacity": s });
                                        });
                                    }
                                });
                            }),
                            (t.prototype.renderSeriesLabel = function (t, e, i, n, o) {
                                var r = o || "column" === this.chartType ? "middle" : "start",
                                    a = { "font-size": n.fontSize, "font-family": n.fontFamily, "font-weight": n.fontWeight, fill: n.color, opacity: 0, "text-anchor": r },
                                    s = (this.labelSet = t.set());
                                return (
                                    i.forEach(function (i, n) {
                                        i.forEach(function (i, o) {
                                            var r = e[n][o],
                                                u = g.default.renderText(t, r.end, i.end, a),
                                                l = u.node.style;
                                            if (((l.userSelect = "none"), (l.cursor = "default"), s.push(u), r.start)) {
                                                var h = g.default.renderText(t, r.start, i.start, a);
                                                (h.node.style.userSelect = "none"), (h.node.style.cursor = "default"), s.push(h);
                                            }
                                        });
                                    }),
                                        s
                                );
                            }),
                            (t.prototype._calculateConnectorPosition = function (t, e) {
                                for (var i = [], n = "bar" === this.chartType, o = r(t, 2), a = o[0], s = o[1], u = r(e, 2), l = u[0], h = u[1], c = 0; c < a.length; c += 1) {
                                    var p = l.items[c].value < 0,
                                        d = h.items[c].value < 0,
                                        f = a[c].end,
                                        g = f.top,
                                        m = f.left,
                                        y = f.width,
                                        v = f.height,
                                        $ = s[c].end,
                                        x = $.top,
                                        T = $.left,
                                        b = $.height,
                                        _ = $.width;
                                    i.push([
                                        { top: g + (!n && p ? v : 0) + (n ? v : 0), left: m + (n && p ? 0 : y) },
                                        { top: x + (!n && d ? b : 0), left: T + (n && !d ? _ : 0) },
                                    ]);
                                }
                                return i;
                            }),
                            (t.prototype._makeConnectorModel = function (t) {
                                for (var e = t.groupBounds, i = t.seriesDataModel, n = i.groups, r = [], a = 0; a < e.length - 1; a += 1) {
                                    var s = [e[a], e[a + 1]],
                                        u = [n[a], n[a + 1]];
                                    r.push.apply(r, o(this._calculateConnectorPosition(s, u)));
                                }
                                return r;
                            }),
                            (t.prototype.renderConnector = function (t, e, i) {
                                var n = this._makeConnectorModel(e),
                                    o = (this.connectorSet = t.set()),
                                    a = i.connector,
                                    s = (0, c.default)(a),
                                    u = (s && a.width) || 1,
                                    l = (s && a.color) || "#ddd",
                                    h = (s && "dotted" === a.type) || !1;
                                return (
                                    n.forEach(function (e) {
                                        var i = r(e, 2),
                                            n = i[0],
                                            a = i[1];
                                        o.push(g.default.renderLine(t, g.default.makeLinePath(n, a).join(" "), { color: l, strokeWidth: u, dotted: h, connector: !0 }));
                                    }),
                                        o
                                );
                            }),
                            t
                    );
                })();
            e.default = m;
        },
        function (t, e) {
            "use strict";
            t.exports = function t(e) {
                return null === e;
            };
        },
        function (t, e) {
            "use strict";
            t.exports = function t(e) {
                return e === Object(e);
            };
        },
        function (t, e, i) {
            "use strict";
            var n = i(140),
                o = i(141),
                r = i(142);
            t.exports = function t(e, i, a) {
                n(e) ? o(e, i, a) : r(e, i, a);
            };
        },
        function (t, e) {
            "use strict";
            t.exports = function t(e) {
                return e instanceof Array;
            };
        },
        function (t, e) {
            "use strict";
            t.exports = function t(e, i, n) {
                var o = 0,
                    r = e.length;
                for (n = n || null; o < r && !1 !== i.call(n, e[o], o, e); o += 1);
            };
        },
        function (t, e) {
            "use strict";
            t.exports = function t(e, i, n) {
                var o;
                for (o in ((n = n || null), e)) if (e.hasOwnProperty(o) && !1 === i.call(n, e[o], o, e)) break;
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                return (0, u.default)(t) && "number" == typeof t;
            }
            e.__esModule = !0;
            var r = i(144),
                a = n(r),
                s = i(146),
                u = n(s),
                l = i(145),
                h = n(l),
                c = i(147),
                p = n(c),
                d = i(135),
                f = n(d),
                g = i(148),
                m = n(g),
                y = {
                    cacheFontInfo: "",
                    elementForTextSize: null,
                    canvasElement: (function t() {
                        if (!document.createElement("canvas").getContext) return null;
                        var e = document.createElement("canvas");
                        return "function" == typeof e.getContext("2d").fillText ? e : null;
                    })(),
                };
            e.default = {
                makeLinePath: function (t, e) {
                    var i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 1,
                        n = [t.left, t.top],
                        o = [e.left, e.top],
                        r = (i % 2) / 2;
                    return (
                        n.forEach(function (t, e) {
                            t === o[e] && (n[e] = o[e] = Math.round(t) - r);
                        }),
                            ["M"].concat(n, ["L"], o)
                    );
                },
                renderLine: function (t, e, i) {
                    var n = i.color,
                        o = i.strokeWidth,
                        r = i.dotted,
                        a = i.connector,
                        s = t.path([e]),
                        u = { stroke: n, "stroke-width": (0, h.default)(o) ? 2 : o, "stroke-linecap": "butt", opacity: a ? 0 : 1 };
                    return "transparent" === n && ((u.stroke = "#fff"), (u["stroke-opacity"] = 0)), s.attr(u).node.setAttribute("class", "auto-shape-rendering"), r && s.attr(u).node.setAttribute("class", "stroke-dasharray"), s;
                },
                getEllipsisText: function (t, e, i) {
                    for (var n = String(t).split(""), o = n.length, r = this.getRenderedTextSize(".", i.fontSize, i.fontFamily).width, a = "", s = 2 * r, u = 0; u < o; u += 1) {
                        if ((s += this.getRenderedTextSize(n[u], i.fontSize, i.fontFamily).width) >= e) {
                            a += "..";
                            break;
                        }
                        a += n[u];
                    }
                    return a;
                },
                renderText: function (t, e, i, n) {
                    var o = t.text(e.left, e.top, (0, p.default)(String(i)));
                    return n && (n["dominant-baseline"] ? o.node.setAttribute("dominant-baseline", n["dominant-baseline"]) : o.node.setAttribute("dominant-baseline", "central"), o.attr(n)), o;
                },
                renderArea: function (t, e, i) {
                    var n = t.path(e);
                    return (i = Object.assign({ "stroke-opacity": 0 }, i)), n.attr(i), n;
                },
                renderCircle: function (t, e, i, n) {
                    var o = t.circle(e.left, e.top, i);
                    return n && o.attr(n), o;
                },
                renderRect: function (t, e, i) {
                    var n = t.rect(e.left, e.top, e.width, e.height);
                    return i && n.attr(i), n;
                },
                updateRectBound: function (t, e) {
                    t.attr({ x: e.left, y: e.top, width: e.width, height: e.height });
                },
                forEach2dArray: function (t, e) {
                    t &&
                    t.forEach(function (t, i) {
                        t.forEach(function (t, n) {
                            e(t, i, n);
                        });
                    });
                },
                makeChangedLuminanceColor: function (t, e) {
                    return (
                        (t = t.replace("#", "")),
                            (e = e || 0),
                        "#" +
                        (0, a.default)(3)
                            .map(function (i) {
                                var n = parseInt(t.substr(2 * i, 2), 16),
                                    o = n + n * e;
                                return (o = Math.round(Math.min(Math.max(0, o), 255)).toString(16)), m.default.formatToZeroFill(o, 2);
                            })
                            .join("")
                    );
                },
                getRenderedTextSize: function (t) {
                    var e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 11,
                        i = arguments[2];
                    return y.canvasElement ? this._getTextDimensionWithCanvas(t, e, i) : this._getTextDimensionUseHtmlElement(t, e, i);
                },
                _getTextDimensionWithCanvas: function (t, e, i) {
                    var n = y.canvasElement,
                        o = y.cacheFontInfo,
                        r = n.getContext("2d"),
                        a = e + "px " + i;
                    return o !== a && ((y.cacheFontInfo = a), (r.font = a)), { width: r.measureText(t).width, height: 1.11 * e };
                },
                _getTextDimensionUseHtmlElement: function (t, e, i) {
                    var n = y.cacheFontInfo,
                        o = y.elementForTextSize;
                    if (!o) {
                        var r = (o = document.createElement("div")).style;
                        this._setBasicHtmlElementStyleForGetTextSize(r), document.body.appendChild(o), (y.elementForTextSize = o);
                    }
                    var a = e + "px " + i;
                    if (n !== a) {
                        var s = o.style;
                        (s.fontFamily = i), (s.fontSize = e + "px"), (y.cacheFontInfo = a);
                    }
                    return (o.innerHTML = t), { width: o.clientWidth, height: o.clientHeight };
                },
                _setBasicHtmlElementStyleForGetTextSize: function (t) {
                    (t.visibility = "hidden"), (t.position = "absolute"), (t.margin = 0), (t.padding = 0), (t.lineHeight = 1.11), (t.whiteSpace = "nowrap");
                },
                animateOpacity: function (t, e, i, n) {
                    var r = o(n) ? n : 600,
                        a = o(e) ? e : 0,
                        s = o(i) ? i : 1,
                        u = f.default.animation({ opacity: s }, r);
                    t.attr({ opacity: a }), t.animate(u);
                },
                getDefaultAnimationDuration: function (t) {
                    switch (t) {
                        case "boxplot":
                        case "combo":
                        case "pie":
                        case "scatter":
                        case "bubble":
                        case "area":
                        case "line":
                        case "column":
                        case "bar":
                            return 700;
                        case "heatmap":
                        case "treemap":
                            return 600;
                        default:
                            return 0;
                    }
                },
            };
        },
        function (t, e, i) {
            "use strict";
            var n = i(145);
            t.exports = function t(e, i, o) {
                var r,
                    a = [];
                for (n(i) && ((i = e || 0), (e = 0)), i *= r = (o = o || 1) < 0 ? -1 : 1; e * r < i; e += o) a.push(e);
                return a;
            };
        },
        function (t, e) {
            "use strict";
            t.exports = function t(e) {
                return void 0 === e;
            };
        },
        function (t, e, i) {
            "use strict";
            var n = i(145),
                o = i(137);
            t.exports = function t(e) {
                return !n(e) && !o(e);
            };
        },
        function (t, e) {
            "use strict";
            t.exports = function t(e) {
                var i = { "&quot;": '"', "&amp;": "&", "&lt;": "<", "&gt;": ">", "&#39;": "'", "&nbsp;": " " };
                return e.replace(/&amp;|&lt;|&gt;|&quot;|&#39;|&nbsp;/g, function (t) {
                    return i[t] ? i[t] : t;
                });
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                if (Array.isArray(t)) {
                    for (var e = 0, i = Array(t.length); e < t.length; e++) i[e] = t[e];
                    return i;
                }
                return Array.from(t);
            }
            function r(t, e) {
                (t = (0, u.default)(t) ? t : [t]), (0, b.default)(t, e);
            }
            function a(t) {
                return "alpha(opacity=" + t * C.default.OLD_BROWSER_OPACITY_100 + ")";
            }
            e.__esModule = !0;
            var s = i(140),
                u = n(s),
                l = i(149),
                h = n(l),
                c = i(146),
                p = n(c),
                d = i(150),
                f = n(d),
                g = i(145),
                m = n(g),
                y = i(151),
                v = n(y),
                $ = i(139),
                x = n($),
                T = i(141),
                b = n(T),
                _ = i(153),
                A = n(_),
                D = i(154),
                C = n(D),
                E = i(155),
                L = n(E),
                S = i(158),
                P = n(S),
                M = A.default.msie && A.default.version <= 8,
                w = window.getComputedStyle || !1,
                k = 0,
                I = {
                    concatStr: function () {
                        for (var t = arguments.length, e = Array(t), i = 0; i < t; i++) e[i] = arguments[i];
                        return String.prototype.concat.apply("", e);
                    },
                    oneLineTrim: function () {
                        return function (t) {
                            for (var e = arguments.length, i = Array(e > 1 ? e - 1 : 0), n = 1; n < e; n++) i[n - 1] = arguments[n];
                            return t.reduce(function (t, e, n) {
                                return t + i[n - 1] + e;
                            });
                        }
                            .apply(void 0, arguments)
                            .replace(/\n\s*/g, "");
                    },
                    makeFontCssText: function () {
                        var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
                            e = [];
                        return (
                            t.fontSize && e.push(this.concatStr("font-size:", t.fontSize, "px")),
                            t.fontFamily && e.push(this.concatStr("font-family:", t.fontFamily)),
                            t.color && e.push(this.concatStr("color:", t.color)),
                            t.fontWeight && e.push(this.concatStr("font-weight:", t.fontWeight)),
                                e.length ? e.join(";") : ""
                        );
                    },
                    _makeCachingKey: function (t, e, i) {
                        var n = [t, i];
                        return (
                            (0, x.default)(e, function (t, e) {
                                n.push(t + e);
                            }),
                                n.join("-")
                        );
                    },
                    _addCssStyle: function (t) {
                        var e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
                            i = e.fontSize,
                            n = e.fontFamily,
                            o = e.fontWeight,
                            r = e.cssText;
                        (t.style.fontSize = (i || C.default.DEFAULT_LABEL_FONT_SIZE) + "px"), n && (t.style.fontFamily = n), o && (t.style.fontWeight = o), r && (t.style.cssText += r);
                    },
                    sizeCache: {},
                    _getRenderedLabelSize: function () {
                        var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "",
                            e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
                            i = arguments[2];
                        if (!(t = String(t))) return 0;
                        var n = this._makeCachingKey(t, e, i),
                            o = this.sizeCache[n];
                        if (!o) {
                            var r = this._createSizeCheckEl(),
                                a = r.firstChild;
                            (a.innerText = t), this._addCssStyle(r, e), document.body.appendChild(r), (o = a[i]), document.body.removeChild(r), (this.sizeCache[n] = o);
                        }
                        return o;
                    },
                    checkEl: null,
                    _createSizeCheckEl: function () {
                        if (this.checkEl) this.checkEl.style.cssText = "";
                        else {
                            var t = L.default.create("DIV", "tui-chart-size-check-element"),
                                e = L.default.create("SPAN");
                            t.appendChild(e), (this.checkEl = t);
                        }
                        return this.checkEl;
                    },
                    getRenderedLabelWidth: function (t, e) {
                        return this._getRenderedLabelSize(t, e, "offsetWidth");
                    },
                    getRenderedLabelHeight: function (t, e) {
                        return this._getRenderedLabelSize(t, e, "offsetHeight");
                    },
                    _getRenderedLabelsMaxSize: function (t, e, i) {
                        var n = 0;
                        if (t && t.length) {
                            var o = t.map(function (t) {
                                return i(t, e);
                            });
                            n = P.default.max(o);
                        }
                        return n;
                    },
                    getRenderedLabelsMaxWidth: function (t, e, i) {
                        var n = this.getRenderedLabelWidth.bind(this),
                            o = this._getRenderedLabelsMaxSize(t, e, n);
                        return i ? Math.min(i, o) : o;
                    },
                    getRenderedLabelsMaxHeight: function (t, e) {
                        var i = this.getRenderedLabelHeight.bind(this);
                        return this._getRenderedLabelsMaxSize(t, e, i);
                    },
                    renderDimension: function (t, e) {
                        var i = e.width,
                            n = e.height;
                        t.style.cssText = [this.concatStr("width:", void 0 === i ? 0 : i, "px"), this.concatStr("height:", void 0 === n ? 0 : n, "px")].join(";");
                    },
                    renderPosition: function (t, e) {
                        (0, m.default)(e) ||
                        (0, b.default)(["top", "bottom", "left", "right"], function (i) {
                            var n = e[i];
                            (0, f.default)(n) && (t.style[i] = n + "px");
                        });
                    },
                    renderBackground: function (t, e) {
                        e && (t.style.background = e);
                    },
                    renderFontFamily: function (t, e) {
                        e && (t.style.fontFamily = e);
                    },
                    renderTitle: function (t, e, i) {
                        if (!t) return null;
                        var n = I.makeFontCssText(e),
                            o = L.default.create("DIV", i);
                        return (o.innerHTML = t), e.background && (n += ";" + this.concatStr("background:", e.background)), (o.style.cssText = n), o;
                    },
                    expandBound: function (t) {
                        var e = t.dimension,
                            i = t.position;
                        return {
                            dimension: { width: e.width + 2 * C.default.SERIES_EXPAND_SIZE, height: e.height + 2 * C.default.SERIES_EXPAND_SIZE },
                            position: { left: i.left - C.default.SERIES_EXPAND_SIZE, top: i.top - C.default.SERIES_EXPAND_SIZE },
                        };
                    },
                    _properCase: function (t) {
                        return t.substring(0, 1).toUpperCase() + t.substring(1);
                    },
                    makeMouseEventDetectorName: function (t, e, i) {
                        return t + this._properCase(e) + this._properCase(i);
                    },
                    formatValue: function (t) {
                        var e = t.value,
                            i = t.formatFunctions,
                            n = t.valueType,
                            r = void 0 === n ? "value" : n,
                            a = t.areaType,
                            s = t.legendName,
                            u = t.chartType;
                        return [String(e)].concat(o(i || [])).reduce(function (t, e) {
                            return e(t, u, a, r, s);
                        });
                    },
                    formatValues: function (t, e) {
                        var i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {},
                            n = i.chartType,
                            o = i.areaType,
                            r = i.valueType;
                        return e && e.length
                            ? t.map(function (t) {
                                return I.formatValue({ value: t, formatFunctions: e, chartType: n, areaType: o, valueType: r });
                            })
                            : t;
                    },
                    formatDate: function (t) {
                        var e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : C.default.DEFAULT_DATE_FORMAT,
                            i = (0, h.default)(t) ? t : new Date(t);
                        return (0, v.default)(e, i) || t;
                    },
                    formatDates: function (t) {
                        var e = this,
                            i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : C.default.DEFAULT_DATE_FORMAT;
                        return t.map(function (t) {
                            return e.formatDate(t, i);
                        });
                    },
                    cancelAnimation: function (t) {
                        t && t.id && (cancelAnimationFrame(t.id), delete t.id);
                    },
                    startAnimation: function (t, e, i) {
                        function n() {
                            var a = Math.min((new Date().getTime() - r) / t, 1);
                            e(a), 1 === a ? (delete o.id, i && i()) : (o.id = requestAnimationFrame(n));
                        }
                        var o = {},
                            r = new Date().getTime();
                        return (o.id = requestAnimationFrame(n)), o;
                    },
                    isOldBrowser: function () {
                        return M;
                    },
                    formatToZeroFill: function (t, e) {
                        if ((t = String(t)).length >= e) return t;
                        for (; t.length < e; ) t = "0" + t;
                        return t;
                    },
                    formatToDecimal: function (t, e) {
                        var i = Math.pow(10, e);
                        return 0 === e ? Math.round(t) : (t = parseFloat((t = Math.round(t * i) / i)).toFixed(e));
                    },
                    formatToComma: function (t) {
                        var e,
                            i = (t = String(t)),
                            n = t.indexOf("-") > -1 ? "-" : "",
                            r = "",
                            a = void 0,
                            s = void 0,
                            u = void 0;
                        return (
                            (t.indexOf(".") > -1 ? ((t = String(Math.abs((a = t.split("."))[0]))), (r = "." + a[1])) : (t = String(Math.abs(t))), t.length <= 3)
                                ? (u = i)
                                : ((s = (a = t.split("").reverse()).length - 1),
                                    (a = a.map(function (t, e) {
                                        var i = [t];
                                        return e < s && (e + 1) % 3 == 0 && i.push(","), i;
                                    })),
                                    (u = n + (e = []).concat.apply(e, o(a)).reverse().join("") + r)),
                                u
                        );
                    },
                    makeCssTextFromMap: function (t) {
                        return t
                            .map(function (t, e) {
                                return I.concatStr(e, ":", t);
                            })
                            .join(";");
                    },
                    _perseString: function (t) {
                        return "string" == typeof t || "number" == typeof t ? String(t) : "";
                    },
                    addPrefixSuffix: function (t) {
                        var e = this,
                            i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "",
                            n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : "";
                        return (
                            (i = this._perseString(i)),
                                (n = this._perseString(n)),
                                "" !== i || "" !== n
                                    ? t.map(function (t) {
                                        return e.addPrefixSuffixItem(t, i, n);
                                    })
                                    : t
                        );
                    },
                    addPrefixSuffixItem: function (t) {
                        var e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "",
                            i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : "";
                        return (e = this._perseString(e)) + t + (i = this._perseString(i));
                    },
                    getStyle: function (t) {
                        return w ? window.getComputedStyle(t, "") : t.currentStyle;
                    },
                    generateClipRectId: function () {
                        var t = "clipRectForAnimation" + k;
                        return (k += 1), t;
                    },
                };
            M
                ? ((I.makeOpacityCssText = function (t) {
                    var e = "";
                    return (0, p.default)(t) && (e = ";filter:" + a(t)), e;
                }),
                    (I.setOpacity = function (t, e) {
                        var i = a(e);
                        r(t, function (t) {
                            t.style.filter = i;
                        });
                    }))
                : ((I.makeOpacityCssText = function (t) {
                    var e = "";
                    return (0, p.default)(t) && (e = ";opacity:" + t), e;
                }),
                    (I.setOpacity = function (t, e) {
                        r(t, function (t) {
                            t.style.opacity = e;
                        });
                    })),
                (e.default = I);
        },
        function (t, e) {
            "use strict";
            t.exports = function t(e) {
                return e instanceof Date;
            };
        },
        function (t, e) {
            "use strict";
            t.exports = function t(e) {
                return "number" == typeof e || e instanceof Number;
            };
        },
        function (t, e, i) {
            "use strict";
            var n = i(152),
                o = i(149),
                r = /[\\]*YYYY|[\\]*YY|[\\]*MMMM|[\\]*MMM|[\\]*MM|[\\]*M|[\\]*DD|[\\]*D|[\\]*HH|[\\]*H|[\\]*A/gi,
                a = ["Invalid month", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                s = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
                u = {
                    M: function (t) {
                        return Number(t.month);
                    },
                    MM: function (t) {
                        var e = t.month;
                        return 10 > Number(e) ? "0" + e : e;
                    },
                    MMM: function (t) {
                        return a[Number(t.month)].substr(0, 3);
                    },
                    MMMM: function (t) {
                        return a[Number(t.month)];
                    },
                    D: function (t) {
                        return Number(t.date);
                    },
                    d: function (t) {
                        return u.D(t);
                    },
                    DD: function (t) {
                        var e = t.date;
                        return 10 > Number(e) ? "0" + e : e;
                    },
                    dd: function (t) {
                        return u.DD(t);
                    },
                    YY: function (t) {
                        return Number(t.year) % 100;
                    },
                    yy: function (t) {
                        return u.YY(t);
                    },
                    YYYY: function (t) {
                        var e = "20",
                            i = t.year;
                        return i > 69 && i < 100 && (e = "19"), 100 > Number(i) ? e + String(i) : i;
                    },
                    yyyy: function (t) {
                        return u.YYYY(t);
                    },
                    A: function (t) {
                        return t.meridiem;
                    },
                    a: function (t) {
                        return t.meridiem;
                    },
                    hh: function (t) {
                        var e = t.hour;
                        return 10 > Number(e) ? "0" + e : e;
                    },
                    HH: function (t) {
                        return u.hh(t);
                    },
                    h: function (t) {
                        return String(Number(t.hour));
                    },
                    H: function (t) {
                        return u.h(t);
                    },
                    m: function (t) {
                        return String(Number(t.minute));
                    },
                    mm: function (t) {
                        var e = t.minute;
                        return 10 > Number(e) ? "0" + e : e;
                    },
                };
            t.exports = function t(e, i, a) {
                var l,
                    h,
                    c,
                    p,
                    d,
                    f,
                    g,
                    m,
                    y,
                    v,
                    $ = n(a, "meridiemSet", "AM") || "AM",
                    x = n(a, "meridiemSet", "PM") || "PM";
                return (
                    (p = (h = o(i) ? { year: i.getFullYear(), month: i.getMonth() + 1, date: i.getDate(), hour: i.getHours(), minute: i.getMinutes() } : { year: i.year, month: i.month, date: i.date, hour: i.hour, minute: i.minute }).year),
                        (d = h.month),
                        (f = h.date),
                        (p = Number(p)),
                        (d = Number(d)),
                        (f = Number(f)),
                        (g = (p > -1 && p < 100) || (p > 1969 && p < 2070)),
                        (m = d > 0 && d < 13),
                    !!g &&
                    !!m &&
                    ((v = s[d]), 2 === d && p % 4 == 0 && ((p % 100 == 0 && p % 400 != 0) || (v = 29)), !!(y = f > 0 && f <= v)) &&
                    ((h.meridiem = ""),
                    /([^\\]|^)[aA]\b/.test(e) && ((l = h.hour > 11 ? x : $), h.hour > 12 && (h.hour %= 12), 0 === h.hour && (h.hour = 12), (h.meridiem = l)),
                        (c = e.replace(r, function (t) {
                            return t.indexOf("\\") > -1 ? t.replace(/\\/, "") : u[t](h) || "";
                        })))
                );
            };
        },
        function (t, e, i) {
            "use strict";
            var n = i(145),
                o = i(137);
            t.exports = function t(e, i) {
                for (var r = arguments, a = r[0], s = 1, u = r.length; s < u; s += 1) {
                    if (n(a) || o(a)) return;
                    a = a[r[s]];
                }
                return a;
            };
        },
        function (t, e) {
            "use strict";
            var i,
                n,
                o,
                r,
                a,
                s,
                u,
                l,
                h,
                c,
                p = { chrome: !1, firefox: !1, safari: !1, msie: !1, edge: !1, others: !1, version: 0 };
            window &&
            window.navigator &&
            ((r = (o = window.navigator).appName.replace(/\s/g, "_")),
                (a = o.userAgent),
                (s = /MSIE\s([0-9]+[.0-9]*)/),
                (u = /Trident.*rv:11\./),
                (l = /Edge\/(\d+)\./),
                (h = { firefox: /Firefox\/(\d+)\./, chrome: /Chrome\/(\d+)\./, safari: /Version\/([\d.]+).*Safari\/(\d+)/ }),
            (c = {
                Microsoft_Internet_Explorer: function () {
                    var t = a.match(s);
                    t ? ((p.msie = !0), (p.version = parseFloat(t[1]))) : (p.others = !0);
                },
                Netscape: function () {
                    var t = !1;
                    if (u.exec(a)) (p.msie = !0), (p.version = 11), (t = !0);
                    else if (l.exec(a)) (p.edge = !0), (p.version = a.match(l)[1]), (t = !0);
                    else
                        for (i in h)
                            if (h.hasOwnProperty(i) && (n = a.match(h[i])) && n.length > 1) {
                                (p[i] = t = !0), (p.version = parseFloat(n[1] || 0));
                                break;
                            }
                    t || (p.others = !0);
                },
            })[r] && c[r]()),
                (t.exports = p);
        },
        function (t, e) {
            "use strict";
            (e.__esModule = !0),
                (e.default = {
                    CLASS_NAME_LEGEND_LABEL: "tui-chart-legend-label",
                    CLASS_NAME_LEGEND_CHECKBOX: "tui-chart-legend-checkbox",
                    CLASS_NAME_SERIES_LABEL: "tui-chart-series-label",
                    CLASS_NAME_SERIES_LEGEND: "tui-chart-series-legend",
                    CLASS_NAME_RESET_ZOOM_BTN: "tui-chart-reset-zoom-btn",
                    CLASS_NAME_ZOOM_OUT_BTN: "tui-chart-zoom-out-btn",
                    CLASS_NAME_CHART_EXPORT_MENU_AREA: "tui-chart-chartExportMenu-area",
                    CLASS_NAME_CHART_EXPORT_MENU_ITEM: "tui-chart-chartExportMenu-item",
                    CLASS_NAME_CHART_EXPORT_MENU_BUTTON: "tui-chart-chartExportMenu-button",
                    CLASS_NAME_CHART_EXPORT_MENU_HEAD: "tui-chart-chartExportMenu-head",
                    CLASS_NAME_CHART_EXPORT_MENU_BODY: "tui-chart-chartExportMenu-body",
                    CLASS_NAME_TOOLTIP_VALUE: "tui-chart-tooltip-value",
                    CLASS_NAME_TOOLTIP_HEAD: "tui-chart-tooltip-head",
                    CLASS_NAME_TOOLTIP_BODY: "tui-chart-tooltip-body",
                    CLASS_NAME_SVG_AUTOSHAPE: "auto-shape-rendering",
                    CHART_TYPE_BAR: "bar",
                    CHART_TYPE_COLUMN: "column",
                    CHART_TYPE_LINE: "line",
                    CHART_TYPE_AREA: "area",
                    CHART_TYPE_COMBO: "combo",
                    CHART_TYPE_COLUMN_LINE_COMBO: "columnLineCombo",
                    CHART_TYPE_LINE_SCATTER_COMBO: "lineScatterCombo",
                    CHART_TYPE_LINE_AREA_COMBO: "lineAreaCombo",
                    CHART_TYPE_PIE_DONUT_COMBO: "pieDonutCombo",
                    CHART_TYPE_PIE: "pie",
                    CHART_TYPE_BUBBLE: "bubble",
                    CHART_TYPE_SCATTER: "scatter",
                    CHART_TYPE_HEATMAP: "heatmap",
                    CHART_TYPE_TREEMAP: "treemap",
                    CHART_TYPE_MAP: "map",
                    CHART_TYPE_RADIAL: "radial",
                    CHART_TYPE_BOXPLOT: "boxplot",
                    CHART_TYPE_BULLET: "bullet",
                    CHART_PADDING: 10,
                    CHART_DEFAULT_WIDTH: 500,
                    CHART_DEFAULT_HEIGHT: 400,
                    OVERLAPPING_WIDTH: 1,
                    TEXT_PADDING: 2,
                    SERIES_EXPAND_SIZE: 10,
                    SERIES_AREA_V_PADDING: 10,
                    SERIES_LABEL_PADDING: 5,
                    SERIES_EXTRA_EVENT_AREA_FOR_ZERO: 2,
                    DEFAULT_TITLE_FONT_SIZE: 14,
                    DEFAULT_AXIS_TITLE_FONT_SIZE: 10,
                    DEFAULT_LABEL_FONT_SIZE: 12,
                    DEFAULT_SERIES_LABEL_FONT_SIZE: 11,
                    DEFAULT_PLUGIN: "Raphael",
                    DEFAULT_TICK_COLOR: "black",
                    DEFAULT_THEME_NAME: "default",
                    MAX_HEIGHT_WORD: "A",
                    NORMAL_STACK_TYPE: "normal",
                    PERCENT_STACK_TYPE: "percent",
                    DEFAULT_STACK: "___DEFAULT___STACK___",
                    DUMMY_KEY: "___DUMMY___KEY___",
                    TREEMAP_ROOT_ID: "___TUI_TREEMAP_ROOT___",
                    TREEMAP_ID_PREFIX: "___TUI_TREEMAP_ID___",
                    TREEMAP_DEPTH_KEY_PREFIX: "___TUI_TREEMAP_DEPTH___",
                    TREEMAP_PARENT_KEY_PREFIX: "___TUI_TREEMAP_PARENT___",
                    TREEMAP_LEAF_KEY_PREFIX: "___TUI_TREEMAP_LEAF___",
                    TREEMAP_LIMIT_DEPTH_KEY_PREFIX: "___TUI_TREEMAP_LIMIT_DEPTH___",
                    TREEMAP_DEFAULT_BORDER: "#ffffff",
                    TREEMAP_DEFAULT_BORDER_WIDTH: 4,
                    EMPTY_AXIS_LABEL: "",
                    ANGLE_85: 85,
                    ANGLE_90: 90,
                    ANGLE_360: 360,
                    RAD: Math.PI / 180,
                    RERENDER_TIME: 700,
                    ADDING_DATA_ANIMATION_DURATION: 300,
                    LABEL_ALIGN_OUTER: "outer",
                    LEGEND_ALIGN_TOP: "top",
                    LEGEND_ALIGN_BOTTOM: "bottom",
                    LEGEND_ALIGN_LEFT: "left",
                    LEGEND_PAGINATION_BUTTON_WIDTH: 20,
                    LEGEND_PAGINATION_BUTTON_PADDING_RIGHT: 6,
                    SERIES_OUTER_LABEL_PADDING: 20,
                    PIE_GRAPH_DEFAULT_RATIO: 0.9,
                    PIE_GRAPH_SMALL_RATIO: 0.75,
                    PIE_GRAPH_LEGEND_LABEL_SIZE: 16,
                    PIE_GRAPH_LEGEND_LABEL_INTERVAL: 20,
                    SPECTRUM_LEGEND_TICK_COUNT: 4,
                    LABEL_SEPARATOR: "\n",
                    MAP_CHART_LABEL_DEFAULT_POSITION_RATIO: { x: 0.5, y: 0.5 },
                    MAP_CHART_ZOOM_AREA_WIDTH: 24,
                    MAP_CHART_ZOOM_AREA_HEIGHT: 58,
                    DOT_RADIUS: 4,
                    SCATTER_RADIUS: 7,
                    THEME_PROPS_MAP: { yAxis: ["tickColor", "title", "label"], series: ["label", "colors", "borderColor", "borderWidth", "selectionColor", "startColor", "endColor", "overColor", "dot", "ranges"] },
                    TITLE_AREA_WIDTH_PADDING: 20,
                    CHART_EXPORT_MENU_SIZE: 24,
                    XAXIS_LABEL_TOP_MARGIN: 10,
                    V_LABEL_RIGHT_PADDING: 10,
                    TOOLTIP_PREFIX: "tui-chart-tooltip",
                    TOOLTIP_ZINDEX: 500,
                    TOOLTIP_ANIMATION_TIME: 10,
                    TOOLTIP_PIE_ANIMATION_TIME: 50,
                    MIN_PIXEL_TYPE_STEP_SIZE: 45,
                    MAX_PIXEL_TYPE_STEP_SIZE: 65,
                    PERCENT_STACKED_AXIS_SCALE: { limit: { min: 0, max: 100 }, step: 25, labels: [0, 25, 50, 75, 100] },
                    MINUS_PERCENT_STACKED_AXIS_SCALE: { limit: { min: -100, max: 0 }, step: 25, labels: [0, -25, -50, -75, -100] },
                    DUAL_PERCENT_STACKED_AXIS_SCALE: { limit: { min: -100, max: 100 }, step: 25, labels: [-100, -75, -50, -25, 0, 25, 50, 75, 100] },
                    DIVERGING_PERCENT_STACKED_AXIS_SCALE: { limit: { min: -100, max: 100 }, step: 25, labels: [100, 75, 50, 25, 0, 25, 50, 75, 100] },
                    AXIS_TYPE_DATETIME: "datetime",
                    DEFAULT_DATE_FORMAT: "YYYY.MM.DD hh:mm:dd",
                    DATE_TYPE_YEAR: "year",
                    DATE_TYPE_MONTH: "month",
                    DATE_TYPE_WEEK: "week",
                    DATE_TYPE_DATE: "date",
                    DATE_TYPE_HOUR: "hour",
                    DATE_TYPE_MINUTE: "minute",
                    DATE_TYPE_SECOND: "second",
                    TITLE_PADDING: 20,
                    TITLE_ALIGN_CENTER: "center",
                    TITLE_ALIGN_RIGHT: "right",
                    TITLE_ALIGN_LEFT: "left",
                    DEFAULT_HEADER_HEIGHT: 10,
                    LEGEND_AREA_H_PADDING: 15,
                    LEGEND_AREA_V_PADDING: 7,
                    LEGEND_CHECKBOX_SIZE: 14,
                    LEGEND_ICON_WIDTH: 20,
                    LEGEND_ICON_HEIGHT: 20,
                    LEGEND_LABEL_LEFT_PADDING: 8,
                    LEGEND_V_LABEL_RIGHT_PADDING: 20,
                    LEGEND_H_LABEL_RIGHT_PADDING: 25,
                    LEGEND_LINE_ICON_PATH: "M1,9 L1,3 C1,1.8954305 1.8954305,1 3,1 L3,1 C4.1045695,1 5,1.8954305 5,3 L5,7 C5,8.1045695 5.8954305,9 7,9 L7,9 C8.1045695,9 9,8.1045695 9,7 L9,1",
                    MIN_LEGEND_WIDTH: 100,
                    MAP_LEGEND_AREA_PADDING_WIDE: 22,
                    VERTICAL_MAP_LEGEND_AREA_TOP_PADDING: 26,
                    MAP_LEGEND_AREA_PADDING_NARROW: 10,
                    MAP_LEGEND_TOOLTIP_VERTICAL_PADDING: 4,
                    MAP_LEGEND_TOOLTIP_HORIZONTAL_PADDING: 6,
                    MAP_LEGEND_WEDGE_SIZE: 4,
                    MAP_LEGEND_PADDING_BTW_GRAPH_AND_WEDGE: 4,
                    VERTICAL_MAP_LEGEND_HEIGHT: 320,
                    HORIZONTAL_MAP_LEGEND_WIDTH: 400,
                    MAP_LEGEND_GRAPH_SIZE: 6,
                    MAP_LEGEND_LABEL_PADDING: 5,
                    CIRCLE_LEGEND_LABEL_FONT_SIZE: 9,
                    CIRCLE_LEGEND_PADDING: 10,
                    HALF_RATIO: 0.5,
                    AXIS_BACKGROUND_RIGHT_PADDING: 4,
                    X_AXIS_LABEL_PADDING: 7,
                    X_AXIS_TITLE_PADDING: 5,
                    Y_AXIS_LABEL_PADDING: 17,
                    Y_AXIS_TITLE_PADDING: 7,
                    DEGREE_CANDIDATES: [25, 45, 65, 85],
                    TICK_INTERVAL_AUTO: "auto",
                    YAXIS_ALIGN_CENTER: "center",
                    XAXIS_LABEL_GUTTER: 2,
                    AXIS_STANDARD_MULTIPLE_NUMS: [1, 2, 5, 10, 20, 50, 100],
                    AXIS_LAST_STANDARD_MULTIPLE_NUM: 100,
                    AXIS_EDGE_RATIO: 8,
                    LABEL_PADDING_TOP: 7,
                    LINE_MARGIN_TOP: 14,
                    TOOLTIP_GAP: 5,
                    TOOLTIP_DIRECTION_FORWARD: "forword",
                    TOOLTIP_DIRECTION_CENTER: "center",
                    TOOLTIP_DIRECTION_BACKWARD: "backword",
                    TOOLTIP_DEFAULT_ALIGN_OPTION: "center top",
                    TOOLTIP_DEFAULT_HORIZONTAL_ALIGN_OPTION: "right middle",
                    TOOLTIP_DEFAULT_GROUP_ALIGN_OPTION: "right middle",
                    TOOLTIP_DEFAULT_GROUP_HORIZONTAL_ALIGN_OPTION: "center bottom",
                    HIDE_DELAY: 200,
                    OLD_BROWSER_OPACITY_100: 100,
                    SERIES_LABEL_OPACITY: 0.3,
                    WHEEL_TICK: 120,
                    MAX_ZOOM_MAGN: 5,
                    ZOOM_POSITION_TOP_EXIST_TITLE: 5,
                    ZOOM_POSITION_TOP_NONE_TITLE: 1,
                    FF_WHEELDELTA_ADJUSTING_VALUE: -40,
                    PUBLIC_EVENT_PREFIX: "public_",
                    PUBLIC_EVENT_MAP: { load: !0, selectLegend: !0, selectSeries: !0, unselectSeries: !0, beforeShowTooltip: !0, afterShowTooltip: !0, beforeHideTooltip: !0, changeCheckedLegends: !0, zoom: !0 },
                    RADIAL_PLOT_PADDING: 15,
                    RADIAL_MARGIN_FOR_CATEGORY: 60,
                    RADIAL_CATEGORY_PADDING: 20,
                    COMPONENT_TYPE_DOM: "DOM",
                    COMPONENT_TYPE_RAPHAEL: "Raphael",
                    IMAGE_EXTENSIONS: ["png", "jpeg"],
                    DATA_EXTENSIONS: ["xls", "csv"],
                    GUIDE_AREACHART_AREAOPACITY_TYPE: "areaOpacity should be a number between 0 and 1",
                    BULLET_TYPE_ACTUAL: "Actual",
                    BULLET_TYPE_RANGE: "Ranges",
                    BULLET_TYPE_MARKER: "Markers",
                    BULLET_MARKER_STROKE_TICK: 3,
                    BULLET_MARKER_BUFFER_POSITION: 5,
                    BULLET_RANGES_HEIGHT_RATIO: 0.7,
                    BULLET_ACTUAL_HEIGHT_RATIO: 0.28,
                    BULLET_MARKERS_HEIGHT_RATIO: 0.55,
                    BULLET_MARKER_DETECT_PADDING: 3,
                });
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(140),
                r = n(o),
                a = i(156),
                s = n(a),
                u = i(157),
                l = n(u),
                h = i(141),
                c = n(h);
            e.default = {
                create: function (t, e) {
                    var i = document.createElement(t);
                    return e && this.addClass(i, e), i;
                },
                _getClassNames: function (t) {
                    var e = void 0;
                    return t.classList
                        ? [].concat(
                            (function t(e) {
                                if (Array.isArray(e)) {
                                    for (var i = 0, n = Array(e.length); i < e.length; i++) n[i] = e[i];
                                    return n;
                                }
                                return Array.from(e);
                            })(t.classList)
                        )
                        : (e = t.className || "") && (0, s.default)(e)
                            ? e.split(" ")
                            : [];
                },
                addClass: function (t, e) {
                    if (t && e) {
                        var i = this._getClassNames(t);
                        (0, l.default)(e, i) > -1 || (i.push(e), (t.className = i.join(" ")));
                    }
                },
                removeClass: function (t, e) {
                    var i = this._getClassNames(t),
                        n = (0, l.default)(e, i);
                    -1 !== n && (i.splice(n, 1), (t.className = i.join(" ")));
                },
                hasClass: function (t, e) {
                    var i = this._getClassNames(t);
                    return (0, l.default)(e, i) > -1;
                },
                findParentByClass: function (t, e, i) {
                    var n = t.parentNode;
                    return n ? (this.hasClass(n, e) ? n : "BODY" === n.nodeName || this.hasClass(n, i) ? null : this.findParentByClass(n, e, i)) : null;
                },
                append: function (t, e) {
                    t &&
                    e &&
                    ((e = (0, r.default)(e) ? e : [e]),
                        (0, c.default)(e, function (e) {
                            e && t.appendChild(e);
                        }));
                },
            };
        },
        function (t, e) {
            "use strict";
            t.exports = function t(e) {
                return "string" == typeof e || e instanceof String;
            };
        },
        function (t, e, i) {
            "use strict";
            var n = i(140);
            t.exports = function t(e, i, o) {
                var r, a;
                if (((o = o || 0), !n(i))) return -1;
                if (Array.prototype.indexOf) return Array.prototype.indexOf.call(i, e, o);
                for (a = i.length, r = o; o >= 0 && r < a; r += 1) if (i[r] === e) return r;
                return -1;
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                if (Array.isArray(t)) {
                    for (var e = 0, i = Array(t.length); e < t.length; e++) i[e] = t[e];
                    return i;
                }
                return Array.from(t);
            }
            function r(t, e, i) {
                var n = void 0;
                if (e) {
                    n = a(t, 1)[0];
                    var r = t.slice(1),
                        s = e.call(i, n, 0);
                    (0, f.default)(r, function (t, o) {
                        var r = e.call(i, t, o + 1);
                        r > s && ((s = r), (n = t));
                    });
                } else n = Math.max.apply(Math, o(t));
                return n;
            }
            e.__esModule = !0;
            var a = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                s = i(159),
                u = n(s),
                l = i(157),
                h = n(l),
                c = i(139),
                p = n(c),
                d = i(141),
                f = n(d);
            e.default = {
                min: function t(e, i, n) {
                    var r = void 0;
                    if (i) {
                        r = a(e, 1)[0];
                        var s = e.slice(1),
                            u = i.call(n, r, 0);
                        s.forEach(function (t, e) {
                            var o = i.call(n, t, e + 1);
                            o < u && ((u = o), (r = t));
                        });
                    } else r = Math.min.apply(Math, o(e));
                    return r;
                },
                max: r,
                any: function t(e, i, n) {
                    var o = !1;
                    return (
                        (0, p.default)(e, function (t, r) {
                            return i.call(n, t, r, e) && (o = !0), !o;
                        }),
                            o
                    );
                },
                all: function t(e, i, n) {
                    var o = !!(e || []).length;
                    return (
                        (0, p.default)(e, function (t, r) {
                            return i.call(n, t, r, e) || (o = !1), !1 !== o;
                        }),
                            o
                    );
                },
                unique: function t(e, i, n, o) {
                    var r = [];
                    if (
                        ((0, u.default)(i) || ((o = n), (n = i), (i = !1)),
                            (n =
                                n ||
                                function (t) {
                                    return t;
                                }),
                            i)
                    ) {
                        var a = void 0;
                        (0, f.default)(e, function (t, i) {
                            (t = n.call(o, t, i, e)), (i && a === t) || r.push(t), (a = t);
                        });
                    } else
                        (0, f.default)(e, function (t, i) {
                            (t = n.call(o, t, i, e)), -1 === (0, h.default)(t, r) && r.push(t);
                        });
                    return r;
                },
                pivot: function t(e) {
                    var i = [],
                        n = r(
                            e.map(function (t) {
                                return t.length;
                            })
                        );
                    return (
                        e.forEach(function (t) {
                            for (var e = 0; e < n; e += 1) i[e] || (i[e] = []), i[e].push(t[e]);
                        }),
                            i
                    );
                },
                findIndexFromDateTypeArray: function t(e, i) {
                    var n = Number(i),
                        o = -1,
                        r = !0,
                        s = !1,
                        u = void 0;
                    try {
                        for (var l, h = e.entries()[Symbol.iterator](); !(r = (l = h.next()).done); r = !0) {
                            var c = a(l.value, 2),
                                p = c[0],
                                d = c[1];
                            if (Number(d) === n) {
                                o = p;
                                break;
                            }
                        }
                    } catch (f) {
                        (s = !0), (u = f);
                    } finally {
                        try {
                            !r && h.return && h.return();
                        } finally {
                            if (s) throw u;
                        }
                    }
                    return o;
                },
            };
        },
        function (t, e) {
            "use strict";
            t.exports = function t(e) {
                return "boolean" == typeof e || e instanceof Boolean;
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o,
                r,
                a = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                s = Object.freeze(Object.defineProperties((o = ["\n            M", ",", "\n            V", "\n        "]), { raw: { value: Object.freeze((r = ["\n            M", ",", "\n            V", "\n        "])) } })),
                u = i(135),
                l = n(u),
                h = i(137),
                c = n(h),
                p = i(150),
                d = n(p),
                f = i(143),
                g = n(f),
                m = i(148),
                y = n(m),
                v = (function () {
                    function t() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t);
                    }
                    return (
                        (t.prototype.render = function (t, e) {
                            var i = e.groupBounds;
                            return i
                                ? ((this.paper = t),
                                    (this.theme = e.theme),
                                    (this.options = e.options),
                                    (this.seriesDataModel = e.seriesDataModel),
                                    (this.chartType = e.chartType),
                                    (this.animationDuration = e.options.animationDuration),
                                    this.paper.setStart(),
                                    (this.groupWhiskers = []),
                                    (this.groupMedians = []),
                                    (this.groupBoxes = this._renderBoxplots(i)),
                                    (this.groupBorders = this._renderBoxBorders(i)),
                                    (this.circleOverlay = this._renderCircleOverlay()),
                                    (this.groupBounds = i),
                                    this.paper.setFinish())
                                : null;
                        }),
                            (t.prototype._renderCircleOverlay = function () {
                                return g.default.renderCircle(this.paper, { left: 0, top: 0 }, 0, Object.assign({ "stroke-width": 0 }, { "fill-opacity": 0 }));
                            }),
                            (t.prototype._renderBox = function (t, e, i) {
                                return t.width < 0 || t.height < 0 ? null : g.default.renderRect(this.paper, t, Object.assign({ fill: e, stroke: "none" }, i));
                            }),
                            (t.prototype._renderBoxes = function (t) {
                                var e = this,
                                    i = this.theme.colors,
                                    n = this.options.colorByPoint;
                                return t.map(function (t, o) {
                                    return t.map(function (t, r) {
                                        if (!t) return null;
                                        var a = e.seriesDataModel.getSeriesItem(o, r),
                                            s = n ? i[o] : i[r],
                                            u = e.animationDuration ? t.start : t.end,
                                            l = void 0;
                                        return u && (l = e._renderBox(u, s)), { rect: l, color: s, bound: t.end, item: a, groupIndex: o, index: r };
                                    });
                                });
                            }),
                            (t.prototype._renderBoxplots = function (t) {
                                var e = this._renderBoxes(t);
                                return (this.groupWhiskers = this._renderWhiskers(t)), (this.groupMedians = this._renderMedianLines(t)), (this.groupOutliers = this._renderOutliers(t)), e;
                            }),
                            (t.prototype._renderWhisker = function (t, e, i) {
                                var n = this.paper,
                                    o = this.animationDuration,
                                    r = e.top - t.top,
                                    a = t.left,
                                    u = t.width / 4,
                                    l = "M" + (a + u) + "," + t.top + "H" + (a + 3 * u),
                                    h = y.default.oneLineTrim(s, a + 2 * u, t.top, t.top + Math.abs(r) * (r > 0 ? 1 : -1)),
                                    c = g.default.renderLine(n, l, { color: i, strokeWidth: 1 }),
                                    p = g.default.renderLine(n, h, { color: i, strokeWidth: 1 }),
                                    d = [];
                                return c.attr({ opacity: o ? 0 : 1 }), p.attr({ opacity: o ? 0 : 1 }), d.push(c), d.push(p), d;
                            }),
                            (t.prototype._renderWhiskers = function (t) {
                                var e = this,
                                    i = this.theme.colors,
                                    n = this.options.colorByPoint,
                                    o = [];
                                return (
                                    t.forEach(function (t, r) {
                                        var a = [];
                                        t.forEach(function (t, o) {
                                            var s = n ? i[r] : i[o];
                                            t && (a = (a = a.concat(e._renderWhisker(t.min, t.start, s))).concat(e._renderWhisker(t.max, t.end, s)));
                                        }),
                                            o.push(a);
                                    }),
                                        o
                                );
                            }),
                            (t.prototype._renderMedianLine = function (t) {
                                var e = t.width,
                                    i = "M" + t.left + "," + t.top + ",H" + (t.left + e),
                                    n = g.default.renderLine(this.paper, i, { color: "#fff", strokeWidth: 1 });
                                return n.attr({ opacity: this.animationDuration ? 0 : 1 }), n;
                            }),
                            (t.prototype._renderMedianLines = function (t) {
                                var e = this,
                                    i = [];
                                return (
                                    t.forEach(function (t) {
                                        var n = [];
                                        t.forEach(function (t) {
                                            t && n.push(e._renderMedianLine(t.median));
                                        }),
                                            i.push(n);
                                    }),
                                        i
                                );
                            }),
                            (t.prototype._renderOutlier = function (t, e) {
                                var i = g.default.renderCircle(this.paper, { left: t.left, top: t.top }, 3.5, { stroke: e, "stroke-width": 2 });
                                return i.attr({ opacity: this.animationDuration ? 0 : 1 }), i;
                            }),
                            (t.prototype._renderOutliers = function (t) {
                                var e = this,
                                    i = this.theme.colors,
                                    n = this.options.colorByPoint,
                                    o = [];
                                return (
                                    t.forEach(function (t, r) {
                                        var a = [];
                                        t.forEach(function (t, o) {
                                            var s = n ? i[r] : i[o],
                                                u = [];
                                            t &&
                                            (t.outliers.length &&
                                            t.outliers.forEach(function (t) {
                                                u.push(e._renderOutlier(t, s));
                                            }),
                                                a.push(u));
                                        }),
                                            o.push(a);
                                    }),
                                        o
                                );
                            }),
                            (t.prototype._makeRectPoints = function (t) {
                                return {
                                    leftTop: { left: Math.ceil(t.left), top: Math.ceil(t.top) },
                                    rightTop: { left: Math.ceil(t.left + t.width), top: Math.ceil(t.top) },
                                    rightBottom: { left: Math.ceil(t.left + t.width), top: Math.ceil(t.top + t.height) },
                                    leftBottom: { left: Math.ceil(t.left), top: Math.ceil(t.top + t.height) },
                                };
                            }),
                            (t.prototype._renderBorderLines = function (t, e, i, n) {
                                var o = this,
                                    r = this._makeBorderLinesPaths(t, i, n),
                                    s = {};
                                return (
                                    Object.entries(r).forEach(function (t) {
                                        var i = a(t, 2),
                                            n = i[0],
                                            r = i[1];
                                        s[n] = g.default.renderLine(o.paper, r, { color: e, strokeWidth: 1 });
                                    }),
                                        s
                                );
                            }),
                            (t.prototype._renderBoxBorders = function (t) {
                                var e = this,
                                    i = this.theme.borderColor;
                                return i
                                    ? t.map(function (t, n) {
                                        return t.map(function (t, o) {
                                            if (!t) return null;
                                            var r = e.seriesDataModel.getSeriesItem(n, o);
                                            return e._renderBorderLines(t.start, i, e.chartType, r);
                                        });
                                    })
                                    : null;
                            }),
                            (t.prototype._animateRect = function (t, e, i) {
                                t.animate({ x: e.left, y: e.top, width: e.width, height: e.height }, i, ">");
                            }),
                            (t.prototype.animate = function (t) {
                                var e = this;
                                if (this.animationDuration) {
                                    var i = l.default.animation({ opacity: 1 }, this.animationDuration);
                                    g.default.forEach2dArray(this.groupBoxes, function (t) {
                                        t && e._animateRect(t.rect, t.bound, e.animationDuration);
                                    }),
                                        g.default.forEach2dArray(this.groupWhiskers, function (t) {
                                            t.animate(i.delay(e.animationDuration));
                                        }),
                                        g.default.forEach2dArray(this.groupMedians, function (t) {
                                            t.animate(i.delay(e.animationDuration));
                                        }),
                                        g.default.forEach2dArray(this.groupOutliers, function (t) {
                                            t.forEach(function (t) {
                                                t.animate(i.delay(e.animationDuration));
                                            });
                                        }),
                                    t &&
                                    (this.callbackTimeout = setTimeout(function () {
                                        t(), delete e.callbackTimeout;
                                    }, this.animationDuration));
                                }
                            }),
                            (t.prototype.showAnimation = function (t) {
                                (0, d.default)(t.outlierIndex) ? this.showOutlierAnimation(t) : this.showRectAnimation(t);
                            }),
                            (t.prototype.showRectAnimation = function (t) {
                                var e = this.groupBoxes[t.groupIndex][t.index];
                                (this.hoveredBar = e.rect), this.hoveredBar.attr({ stroke: "#ffffff", "stroke-width": 4 }), this.hoveredBar.node.setAttribute("filter", "url(#shadow)");
                            }),
                            (t.prototype.showOutlierAnimation = function (t) {
                                var e = this.groupOutliers[t.groupIndex][t.index][t.outlierIndex].attr();
                                this.circleOverlay.attr({ r: e.r, cx: e.cx, cy: e.cy, fill: e.stroke, "fill-opacity": 1, stroke: e.stroke, "stroke-width": 4 });
                            }),
                            (t.prototype.hideAnimation = function () {
                                this.circleOverlay.attr({ width: 1, height: 1, x: 0, y: 0, "fill-opacity": 0, "stroke-width": 2 }), this.hoveredBar.attr({ stroke: "none" }), this.hoveredBar.node.setAttribute("filter", "none");
                            }),
                            (t.prototype._updateRectBound = function (t, e) {
                                t.attr({ x: e.left, y: e.top, width: e.width, height: e.height });
                            }),
                            (t.prototype.resize = function (t) {
                                var e = t.dimension,
                                    i = t.groupBounds;
                                (this.groupBounds = i),
                                    this.paper.setSize(e.width, e.height),
                                    g.default.forEach2dArray(this.groupBoxes, function (t, e, n) {
                                        if (t) {
                                            var o = i[e][n].end;
                                            (t.bound = o), g.default.updateRectBound(t.rect, o);
                                        }
                                    });
                            }),
                            (t.prototype._changeBordersColor = function (t, e) {
                                t.forEach(function (t) {
                                    t.attr({ stroke: e });
                                });
                            }),
                            (t.prototype._changeBoxColor = function (t, e, i) {
                                if ((this.groupBoxes[t.groupIndex][t.index].rect.attr({ stroke: e }), i)) {
                                    var n = this.groupBorders[t.groupIndex][t.index];
                                    this._changeBordersColor(n, i);
                                }
                            }),
                            (t.prototype.selectSeries = function (t) {
                                var e = this.groupBoxes[t.groupIndex][t.index],
                                    i = l.default.color(e.color),
                                    n = this.theme.selectionColor,
                                    o = g.default.makeChangedLuminanceColor,
                                    r = n || o(i.hex, 0.2),
                                    a = this.theme.borderColor;
                                if (a) {
                                    var s = l.default.color(a);
                                    a = g.default.makeChangedLuminanceColor(s.hex, 0.2);
                                }
                                this._changeBoxColor(t, r, a);
                            }),
                            (t.prototype.unselectSeries = function (t) {
                                var e = this.groupBoxes[t.groupIndex][t.index],
                                    i = this.theme.borderColor;
                                this._changeBoxColor(t, e.color, i);
                            }),
                            (t.prototype.selectLegend = function (t) {
                                var e = (0, c.default)(t);
                                g.default.forEach2dArray(this.groupBoxes, function (i, n, o) {
                                    i && i.rect.attr({ "stroke-opacity": e || t === o ? 1 : 0.3 });
                                }),
                                    g.default.forEach2dArray(this.groupWhiskers, function (i, n, o) {
                                        i.attr({ "stroke-opacity": e || t === o ? 1 : 0.3 });
                                    }),
                                    g.default.forEach2dArray(this.groupMedians, function (i, n, o) {
                                        i.attr({ "stroke-opacity": e || t === o ? 1 : 0.3 });
                                    });
                            }),
                            (t.prototype.renderSeriesLabel = function (t, e, i, n, o) {
                                var r = { "font-size": n.fontSize, "font-family": n.fontFamily, "font-weight": n.fontWeight, fill: n.color, opacity: 0, "text-anchor": o ? "middle" : "start" },
                                    a = t.set();
                                return (
                                    i.forEach(function (i, n) {
                                        i.forEach(function (i, o) {
                                            var s = e[n][o],
                                                u = g.default.renderText(t, s.end, i.end, r);
                                            if (((u.node.style.userSelect = "none"), (u.node.style.cursor = "default"), u.node.setAttribute("filter", "url(#glow)"), a.push(u), s.start)) {
                                                var l = g.default.renderText(t, s.start, i.start, r);
                                                (l.node.style.userSelect = "none"), (l.node.style.cursor = "default"), l.node.setAttribute("filter", "url(#glow)"), a.push(l);
                                            }
                                        });
                                    }),
                                        a
                                );
                            }),
                            t
                    );
                })();
            e.default = v;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(137),
                r = n(o),
                a = i(153),
                s = n(a),
                u = i(143),
                l = n(u),
                h = i(154),
                c = n(h),
                p = i(148),
                d = n(p),
                f = s.default.msie && s.default.version <= 8,
                g = (function () {
                    function t() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t);
                    }
                    return (
                        (t.prototype.render = function (t, e) {
                            var i = e.groupBounds,
                                n = e.seriesDataModel;
                            return i && i.length
                                ? ((this.paper = t),
                                    (this.theme = e.theme),
                                    (this.dimension = e.dimension),
                                    (this.position = e.position),
                                    (this.options = e.options),
                                    (this.chartType = e.chartType),
                                    (this.isVertical = e.isVertical),
                                    (this.animationDuration = e.options.animationDuration),
                                    (this.seriesDataModel = n),
                                    (this.maxRangeCount = n.maxRangeCount),
                                    (this.maxMarkerCount = n.maxMarkerCount),
                                    (this._graphColors = []),
                                    (this.rangeOpacities = {}),
                                    this.paper.setStart(),
                                    this._renderBounds(i),
                                    this.paper.setFinish())
                                : null;
                        }),
                            (t.prototype._getRangeOpacity = function (t) {
                                var e = this.maxRangeCount;
                                return this.prevMaxRangeCount !== e && this._updateOpacityStep(e), t < e && !this.rangeOpacities[t] && (this.rangeOpacities[t] = 1 - this.opacityStep * (t + 1)), this.rangeOpacities[t];
                            }),
                            (t.prototype._updateOpacityStep = function (t) {
                                (this.rangeOpacities = {}), (this.opacityStep = Number(1 / (t + 1)).toFixed(2)), (this.prevMaxRangeCount = t);
                            }),
                            (t.prototype._renderBounds = function (t) {
                                var e = this,
                                    i = this.theme.ranges,
                                    n = this.paper;
                                (this.groupBars = []),
                                    (this.groupLines = []),
                                    t.forEach(function (t, o) {
                                        var r = e.theme.colors[o],
                                            a = 0,
                                            s = n.set(),
                                            u = n.set();
                                        t.forEach(function (t) {
                                            var n = t.type;
                                            n === c.default.BULLET_TYPE_ACTUAL
                                                ? s.push(e._renderActual(t, r))
                                                : n === c.default.BULLET_TYPE_RANGE
                                                    ? (s.push(e._renderRange(t, r, a, i[a])), (a += 1))
                                                    : n === c.default.BULLET_TYPE_MARKER && u.push(e._renderMarker(t, r));
                                        }),
                                            e.groupBars.push(s),
                                            e.groupLines.push(u);
                                    }, this);
                            }),
                            (t.prototype._renderActual = function (t, e) {
                                return t ? this._renderBar(t, e) : null;
                            }),
                            (t.prototype._renderRange = function (t, e, i, n) {
                                var o = this._getRangeOpacity(i),
                                    r = { opacity: o },
                                    a = e;
                                return t ? (n && ((a = n.color || a), (r.opacity = n.opacity || o)), this._renderBar(t, a, r)) : null;
                            }),
                            (t.prototype._renderBar = function (t, e, i) {
                                return t.width < 0 || t.height < 0 ? null : l.default.renderRect(this.paper, t, Object.assign({ fill: e, stroke: "none" }, i));
                            }),
                            (t.prototype._renderMarker = function (t, e) {
                                return t ? this._renderLine(t, e) : null;
                            }),
                            (t.prototype._renderLine = function (t, e) {
                                var i = t.top,
                                    n = t.left,
                                    o = t.length,
                                    r = this.isVertical ? "L" + (n + o) + "," + i : "L" + n + "," + (i + o);
                                return l.default.renderLine(this.paper, "M" + n + "," + (i + r), { color: e, strokeWidth: c.default.BULLET_MARKER_STROKE_TICK });
                            }),
                            (t.prototype.animate = function (t, e) {
                                var i,
                                    n,
                                    o,
                                    r,
                                    a,
                                    s,
                                    u = this,
                                    l = this.paper,
                                    h = this.dimension,
                                    c = this.position,
                                    p = this.animationDuration,
                                    d = this._getClipRectId(),
                                    g = h.width - 20,
                                    m = h.height - 20,
                                    y = {},
                                    v = {},
                                    $ = this.clipRect;
                                this.isVertical ? ((y.width = g), (y.height = p ? 0 : m), (v.height = m)) : ((y.width = p ? 0 : g), (y.height = m), (v.width = g)),
                                !f &&
                                h &&
                                ($
                                    ? ($.attr({ x: c.left, y: c.top }), $.attr(y))
                                    : (($ =
                                        ((i = l),
                                            (n = c),
                                            (o = y),
                                            (r = d),
                                            (a = document.createElementNS("http://www.w3.org/2000/svg", "clipPath")),
                                            ((s = i.rect(n.left, n.top, o.width, o.height)).id = r + "_rect"),
                                            (a.id = r),
                                            a.appendChild(s.node),
                                            i.defs.appendChild(a),
                                            s)),
                                        (this.clipRect = $)),
                                    e.forEach(function (t) {
                                        "set" === t.type
                                            ? t.forEach(function (t) {
                                                t.node.setAttribute("clip-path", "url(#" + d + ")");
                                            })
                                            : t.node.setAttribute("clip-path", "url(#" + d + ")");
                                    }),
                                p && $.animate(v, p, ">", t)),
                                t &&
                                (this.callbackTimeout = setTimeout(function () {
                                    t(), delete u.callbackTimeout;
                                }, p));
                            }),
                            (t.prototype.resize = function (t) {
                                var e = t.dimension,
                                    i = t.groupBounds,
                                    n = e.width,
                                    o = e.height;
                                (this.dimension = t.dimension), (this.groupBounds = i), this.resizeClipRect(n, o), this.paper.setSize(n, o);
                            }),
                            (t.prototype.resizeClipRect = function (t, e) {
                                var i = this.paper.getById(this._getClipRectId() + "_rect");
                                i && i.attr({ width: t, height: e });
                            }),
                            (t.prototype._getClipRectId = function () {
                                return this.clipRectId || (this.clipRectId = d.default.generateClipRectId()), this.clipRectId;
                            }),
                            (t.prototype._changeBordersColor = function (t, e) {
                                t.forEach(function (t) {
                                    t.attr({ stroke: e });
                                });
                            }),
                            (t.prototype.selectLegend = function (t) {
                                var e = this,
                                    i = (0, r.default)(t);
                                this.groupBars.forEach(function (n, o) {
                                    var r = i || t === o ? 1 : 0.3;
                                    e.groupBars[o].attr({ "fill-opacity": r }),
                                        e.groupLabels[o].attr({ opacity: r }),
                                        e.groupLabels[o].forEach(function (t) {
                                            t.attr({ opacity: r });
                                        });
                                });
                            }),
                            (t.prototype.renderSeriesLabel = function (t, e, i, n) {
                                var o = this,
                                    r = { "font-size": n.fontSize, "font-family": n.fontFamily, "font-weight": n.fontWeight, fill: n.color, opacity: 0, "text-anchor": this.isVertical ? "middle" : "start" },
                                    a = t.set();
                                return (
                                    (this.groupLabels = i.map(function (i, n) {
                                        var s = t.set();
                                        return (
                                            i.forEach(function (i, u) {
                                                var l = o._renderLabel(t, e[n][u], r, i);
                                                s.push(l), a.push(l);
                                            }),
                                                s
                                        );
                                    }, this)),
                                        a
                                );
                            }),
                            (t.prototype._renderLabel = function (t, e, i, n) {
                                var o = l.default.renderText(t, e, n, i),
                                    r = o.node,
                                    a = r.style;
                                return (a.userSelect = "none"), (a.cursor = "default"), r.setAttribute("filter", "url(#glow)"), o;
                            }),
                            (t.prototype.getGraphColors = function () {
                                var t = this;
                                return (
                                    this._graphColors.length ||
                                    (this._graphColors = this.groupBars.map(function (e, i) {
                                        var n = [],
                                            o = t.groupLines[i].length;
                                        e.forEach(function (t) {
                                            n.push(t.attrs.fill);
                                        });
                                        for (var r = n[n.length - 1], a = 0; a <= o; a += 1) n.push(r);
                                        return n;
                                    })),
                                        this._graphColors
                                );
                            }),
                            t
                    );
                })();
            e.default = g;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(137),
                r = n(o),
                a = i(150),
                s = n(a),
                u = i(163),
                l = n(u),
                h = i(143),
                c = n(h),
                p = (function (t) {
                    function e() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        var i = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this));
                        return (i.selectedLegendIndex = null), (i.chartType = "line"), (i.lineWidth = 6), i;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype.render = function (t, e) {
                                var i = e.dimension,
                                    n = e.groupPositions,
                                    o = e.theme,
                                    r = e.options,
                                    a = e.position,
                                    u = o.colors,
                                    l = r.showDot ? 1 : 0,
                                    h = r.spline,
                                    c = (this.lineWidth = (0, s.default)(r.pointWidth) ? r.pointWidth : this.lineWidth),
                                    p = this.makeBorderStyle(o.dot.strokeColor, l, o.dot.strokeWidth),
                                    d = this.makeOutDotStyle(l, p),
                                    f = void 0;
                                return (
                                    (f = h ? this._getSplineLinesPath(n, r.connectNulls) : this._getLinesPath(n, r.connectNulls)),
                                        (this.paper = t),
                                        (this.theme = o),
                                        (this.isSpline = h),
                                        (this.dimension = i),
                                        (this.position = a),
                                        t.setStart(),
                                        (this.groupLines = this._renderLines(t, f, u, c)),
                                        (this.tooltipLine = this._renderTooltipLine(t, i.height)),
                                        (this.groupDots = this._renderDots(t, n, u, l)),
                                    r.allowSelect && ((this.selectionDot = this._makeSelectionDot(t)), (this.selectionColor = o.selectionColor)),
                                        (this.colors = u),
                                        (this.borderStyle = p),
                                        (this.outDotStyle = d),
                                        (this.groupPositions = n),
                                        (this.groupPaths = f),
                                        (this.dotOpacity = l),
                                        (this.animationDuration = r.animationDuration),
                                        delete this.pivotGroupDots,
                                    t.raphael.svg && this.appendShadowFilterToDefs(),
                                        t.setFinish()
                                );
                            }),
                            (e.prototype.appendShadowFilterToDefs = function () {
                                var t = document.createElementNS("http://www.w3.org/2000/svg", "filter"),
                                    e = document.createElementNS("http://www.w3.org/2000/svg", "feOffset"),
                                    i = document.createElementNS("http://www.w3.org/2000/svg", "feGaussianBlur"),
                                    n = document.createElementNS("http://www.w3.org/2000/svg", "feBlend");
                                t.setAttributeNS(null, "id", "shadow"),
                                    t.setAttributeNS(null, "x", "-50%"),
                                    t.setAttributeNS(null, "y", "-50%"),
                                    t.setAttributeNS(null, "width", "180%"),
                                    t.setAttributeNS(null, "height", "180%"),
                                    e.setAttributeNS(null, "result", "offOut"),
                                    e.setAttributeNS(null, "in", "SourceAlpha"),
                                    e.setAttributeNS(null, "dx", "0"),
                                    e.setAttributeNS(null, "dy", "0"),
                                    i.setAttributeNS(null, "result", "blurOut"),
                                    i.setAttributeNS(null, "in", "offOut"),
                                    i.setAttributeNS(null, "stdDeviation", "2"),
                                    n.setAttributeNS(null, "in", "SourceGraphic"),
                                    n.setAttributeNS(null, "in2", "blurOut"),
                                    n.setAttributeNS(null, "mode", "normal"),
                                    t.appendChild(e),
                                    t.appendChild(i),
                                    t.appendChild(n),
                                    this.paper.defs.appendChild(t);
                            }),
                            (e.prototype._getLinesPath = function (t, e) {
                                var i = this;
                                return t.map(function (t) {
                                    return i._makeLinesPath(t, null, e);
                                });
                            }),
                            (e.prototype._getSplineLinesPath = function (t, e) {
                                var i = this;
                                return t.map(function (t) {
                                    return i._makeSplineLinesPath(t, { connectNulls: e });
                                });
                            }),
                            (e.prototype._renderLines = function (t, e, i, n) {
                                return e.map(function (e, o) {
                                    var r = i[o] || "transparent",
                                        a = c.default.renderLine(t, e.join(" "), { color: r, strokeWidth: n });
                                    return a.node.setAttribute("class", "auto-shape-rendering"), a;
                                });
                            }),
                            (e.prototype.resize = function (t) {
                                var e = this,
                                    i = t.dimension,
                                    n = t.groupPositions;
                                this.resizeClipRect(i.width, i.height),
                                    (this.groupPositions = n),
                                    (this.groupPaths = this.isSpline ? this._getSplineLinesPath(n) : this._getLinesPath(n)),
                                    this.paper.setSize(i.width, i.height),
                                    this.tooltipLine.attr({ top: i.height }),
                                    this.groupPaths.forEach(function (t, i) {
                                        e.groupLines[i].attr({ path: t.join(" ") }),
                                            e.groupDots[i].forEach(function (t, o) {
                                                t.endDot && e._moveDot(t.endDot.dot, n[i][o]);
                                            });
                                    });
                            }),
                            (e.prototype.selectLegend = function (t) {
                                var e = this,
                                    i = (0, r.default)(t);
                                this.selectedLegendIndex && -1 !== this.selectedLegendIndex && this.resetSeriesOrder(this.selectedLegendIndex),
                                    (this.selectedLegendIndex = t),
                                    this.groupLines.forEach(function (n, o) {
                                        var r = t === o,
                                            a = e.groupDots[o];
                                        n.attr({ "stroke-opacity": i || r ? 1 : 0.3 }), r && e.moveSeriesToFront(n, a);
                                    }),
                                i &&
                                this.groupLines.forEach(function (t, i) {
                                    e.moveSeriesToFront(t, e.groupDots[i]);
                                });
                            }),
                            (e.prototype.resetSeriesOrder = function (t) {
                                var e = t + 1 < this.groupLines.length ? this.groupLines[t + 1] : null;
                                e &&
                                (this.groupLines[t].insertBefore(e),
                                    this.groupDots[t].forEach(function (t) {
                                        t && t.endDot && t.endDot.dot.insertBefore(e);
                                    }));
                            }),
                            (e.prototype.moveSeriesToFront = function (t, e) {
                                t.toFront(),
                                    e.forEach(function (t) {
                                        t.endDot.dot.toFront();
                                    });
                            }),
                            (e.prototype.animateForAddingData = function (t, e, i, n) {
                                var o = this,
                                    r = t.options.spline ? this._getSplineLinesPath(i) : this._getLinesPath(i),
                                    a = 0;
                                i.length &&
                                (n && (a = 1),
                                    this.groupLines.forEach(function (t, s) {
                                        var u = o.groupDots[s],
                                            l = i[s];
                                        n && o._removeFirstDot(u),
                                            u.forEach(function (t, i) {
                                                var n = l[i + a];
                                                o._animateByPosition(t.endDot.dot, n, e);
                                            }),
                                            o._animateByPath(t, r[s], e);
                                    }));
                            }),
                            (e.prototype.renderSeriesLabel = function (t, e, i, n) {
                                var o = { "font-size": n.fontSize, "font-family": n.fontFamily, "font-weight": n.fontWeight, fill: n.color, "text-anchor": "middle", opacity: 0 },
                                    r = t.set();
                                return (
                                    i.forEach(function (i, n) {
                                        i.forEach(function (i, a) {
                                            var s = e[n][a],
                                                u = c.default.renderText(t, s.end, i.end, o);
                                            if ((r.push(u), (u.node.style.userSelect = "none"), (u.node.style.cursor = "default"), u.node.setAttribute("filter", "url(#glow)"), s.start)) {
                                                var l = c.default.renderText(t, s.start, i.start, o);
                                                (l.node.style.userSelect = "none"), (l.node.style.cursor = "default"), l.node.setAttribute("filter", "url(#glow)"), r.push(l);
                                            }
                                        });
                                    }),
                                        r
                                );
                            }),
                            e
                    );
                })(l.default);
            e.default = p;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                r = i(153),
                a = n(r),
                s = i(146),
                u = n(s),
                l = i(137),
                h = n(l),
                c = i(150),
                p = n(c),
                d = i(145),
                f = n(d),
                g = i(143),
                m = n(g),
                y = i(148),
                v = n(y),
                $ = i(164),
                x = n($),
                T = i(158),
                b = n(T),
                _ = i(154),
                A = n(_),
                D = a.default.msie && a.default.version <= 8,
                C = "over",
                E = (function () {
                    function t() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t);
                    }
                    return (
                        (t.prototype._makeLinesPath = function (t, e, i) {
                            var n,
                                o = [],
                                r = !1;
                            return (
                                (e = e || "top"),
                                    [].concat(t).forEach(function (t) {
                                        var n = r && !i ? "M" : "L";
                                        t ? (o.push([n, t.left, t[e]]), r && (r = !1)) : (r = !0);
                                    }),
                                (o = (n = []).concat.apply(
                                    n,
                                    (function t(e) {
                                        if (Array.isArray(e)) {
                                            for (var i = 0, n = Array(e.length); i < e.length; i++) n[i] = e[i];
                                            return n;
                                        }
                                        return Array.from(e);
                                    })(o)
                                )).length > 0 && (o[0] = "M"),
                                    o
                            );
                        }),
                            (t.prototype._getAnchor = function (t, e, i, n) {
                                var o = (e.left - t.left) / 2,
                                    r = (i.left - e.left) / 2,
                                    a = void 0,
                                    s = void 0;
                                n
                                    ? ((a = Math.atan((t.left - e.left) / Math.abs(t.top - e.top))), (s = Math.atan((e.left - i.left) / Math.abs(i.top - e.top))))
                                    : ((a = Math.atan((e.left - t.left) / Math.abs(e.top - t.top))), (s = Math.atan((i.left - e.left) / Math.abs(e.top - i.top))));
                                var u = Math.PI / 2 - (((a = t.top < e.top ? Math.PI - a : a) + (s = i.top < e.top ? Math.PI - s : s)) % (2 * Math.PI)) / 2,
                                    l = o * Math.sin(u + a),
                                    h = o * Math.cos(u + a),
                                    c = r * Math.sin(u + s),
                                    p = r * Math.cos(u + s),
                                    d = { x1: e.left - l, y1: e.top + h, x2: e.left + c, y2: e.top + p };
                                return n && ((d.y1 = e.top - h), (d.y2 = e.top - p)), d;
                            }),
                            (t.prototype._getSplinePositionsGroups = function (t, e) {
                                var i = [],
                                    n = [];
                                return (
                                    t.forEach(function (o, r) {
                                        var a = r === t.length - 1;
                                        o && n.push(o), ((!o && n.length > 0 && !e) || a) && (i.push(n), (n = []));
                                    }),
                                        i
                                );
                            }),
                            (t.prototype._getSplinePartialPaths = function (t, e) {
                                var i = this,
                                    n = [],
                                    r = void 0,
                                    a = void 0,
                                    s = void 0,
                                    u = void 0,
                                    l = void 0;
                                return (
                                    t.forEach(function (t) {
                                        var h = o(t, 1)[0],
                                            c = h;
                                        (a = t.length),
                                            (s = c),
                                            (r = t[a - 1]),
                                            (l = (u = t.slice(1).slice(0, a - 2)).map(function (n, o) {
                                                var r = t[o + 2],
                                                    a = i._getAnchor(s, n, r, e);
                                                return (
                                                    (s = n),
                                                    Math.abs(a.y1 - h.top) > Math.abs(h.top - n.top) && (a.y1 = n.top),
                                                    Math.abs(a.y2 - r.top) > Math.abs(r.top - n.top) && (a.y2 = n.top),
                                                        (h = n),
                                                        [a.x1, a.y1, n.left, n.top, a.x2, a.y2]
                                                );
                                            })).push([r.left, r.top, r.left, r.top]),
                                            l.unshift(["M", c.left, c.top, "C", c.left, c.top]),
                                            n.push(l);
                                    }),
                                        n
                                );
                            }),
                            (t.prototype._makeSplineLinesPath = function (t) {
                                var e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
                                    i = this._getSplinePositionsGroups(t, e.connectNulls),
                                    n = this._getSplinePartialPaths(i, e.isReverseDirection),
                                    o = [];
                                return (
                                    n.forEach(function (t) {
                                        o = o.concat(t);
                                    }),
                                    e.isBeConnected && (o[0] = o[0].slice(3)),
                                        o
                                );
                            }),
                            (t.prototype._renderTooltipLine = function (t, e) {
                                var i = m.default.makeLinePath({ left: 10, top: e }, { left: 10, top: 0 });
                                return m.default.renderLine(t, i, { color: "transparent", strokeWidth: 1 });
                            }),
                            (t.prototype.appendShadowFilterToDefs = function () {
                                var t = document.createElementNS("http://www.w3.org/2000/svg", "filter"),
                                    e = document.createElementNS("http://www.w3.org/2000/svg", "feOffset"),
                                    i = document.createElementNS("http://www.w3.org/2000/svg", "feGaussianBlur"),
                                    n = document.createElementNS("http://www.w3.org/2000/svg", "feBlend");
                                t.setAttributeNS(null, "id", "shadow"),
                                    t.setAttributeNS(null, "x", "-50%"),
                                    t.setAttributeNS(null, "y", "-50%"),
                                    t.setAttributeNS(null, "width", "180%"),
                                    t.setAttributeNS(null, "height", "180%"),
                                    e.setAttributeNS(null, "result", "offOut"),
                                    e.setAttributeNS(null, "in", "SourceAlpha"),
                                    e.setAttributeNS(null, "dx", "0"),
                                    e.setAttributeNS(null, "dy", "0"),
                                    i.setAttributeNS(null, "result", "blurOut"),
                                    i.setAttributeNS(null, "in", "offOut"),
                                    i.setAttributeNS(null, "stdDeviation", "2"),
                                    n.setAttributeNS(null, "in", "SourceGraphic"),
                                    n.setAttributeNS(null, "in2", "blurOut"),
                                    n.setAttributeNS(null, "mode", "normal"),
                                    t.appendChild(e),
                                    t.appendChild(i),
                                    t.appendChild(n),
                                    this.paper.defs.appendChild(t);
                            }),
                            (t.prototype.makeBorderStyle = function (t, e, i) {
                                var n = { "stroke-width": i, "stroke-opacity": e };
                                return t && (n.stroke = t), n;
                            }),
                            (t.prototype.makeOutDotStyle = function (t, e) {
                                var i = { "fill-opacity": t, "stroke-opacity": t, r: 6 };
                                return e && Object.assign(i, e), i;
                            }),
                            (t.prototype.renderDot = function (t, e, i, n) {
                                var o = (this.theme && this.theme.dot) || { dot: {} },
                                    r = void 0;
                                if (e) {
                                    var a = t.circle(e.left, e.top, (0, f.default)(o.radius) ? 6 : o.radius),
                                        s = { fill: o.fillColor || i, "fill-opacity": (0, p.default)(n) ? n : o.fillOpacity, stroke: o.strokeColor || i, "stroke-opacity": (0, p.default)(n) ? n : o.strokeOpacity, "stroke-width": o.strokeWidth };
                                    a.attr(s), (r = { dot: a, color: i });
                                }
                                return r;
                            }),
                            (t.prototype._moveDotsToFront = function (t) {
                                m.default.forEach2dArray(t, function (t) {
                                    t.endDot.dot.toFront(), t.startDot && t.startDot.dot.toFront();
                                });
                            }),
                            (t.prototype._renderDots = function (t, e, i, n, o) {
                                var r = this;
                                return e.map(function (e, a) {
                                    var s = i[a];
                                    return Object.values(e).map(function (e) {
                                        var i = { endDot: r.renderDot(t, e, s, n) };
                                        if (r.hasRangeData) {
                                            var a = Object.assign({}, e);
                                            (a.top = a.startTop), (i.startDot = r.renderDot(t, a, s, n));
                                        }
                                        return o && (o.push(i.endDot.dot), i.startDot && o.push(i.startDot.dot)), i;
                                    });
                                });
                            }),
                            (t.prototype._getCenter = function (t, e) {
                                return { left: (t.left + e.left) / 2, top: (t.top + e.top) / 2 };
                            }),
                            (t.prototype._showDot = function (t, e) {
                                var i = this.theme.dot.hover,
                                    n = { "fill-opacity": i.fillOpacity, stroke: i.strokeColor || t.color, "stroke-opacity": i.strokeOpacity, "stroke-width": i.strokeWidth, r: i.radius, filter: "url(#shadow)" };
                                this._setPrevDotAttributes(e, t.dot), i.fillColor && (n.fill = i.fillColor), t.dot.attr(n), t.dot.node && t.dot.node.setAttribute("filter", "url(#shadow)"), t.dot.toFront();
                            }),
                            (t.prototype._setPrevDotAttributes = function (t, e) {
                                this._prevDotAttributes || (this._prevDotAttributes = {}), (this._prevDotAttributes[t] = e.attr());
                            }),
                            (t.prototype._updateLineStrokeOpacity = function (t, e) {
                                var i = 1,
                                    n = !(0, h.default)(this.selectedLegendIndex);
                                this.groupLines &&
                                ((t === C || n) && (i = "radial" === this.chartType && this.showArea ? 0 : 0.3),
                                "out" === t && n && (e = this.getLine(this.selectedLegendIndex)),
                                    this.groupLines.forEach(function (t) {
                                        t.attr({ "stroke-opacity": i });
                                    }),
                                    e.attr({ "stroke-opacity": 1 }));
                            }),
                            (t.prototype.getLine = function (t) {
                                return this.groupLines ? this.groupLines[t] : this.groupAreas[t];
                            }),
                            (t.prototype._updateAreaOpacity = function (t) {
                                this.groupAreas &&
                                this.groupAreas.forEach(function (e) {
                                    e.area.attr({ "fill-opacity": t === C ? 0.3 : 1 });
                                });
                            }),
                            (t.prototype._updateLineStrokeWidth = function (t, e) {
                                var i = { "stroke-width": e };
                                t.attrs && (i.stroke = t.attrs.stroke), t.attr(i);
                            }),
                            (t.prototype.showAnimation = function (t) {
                                var e = t.index,
                                    i = this.groupDots[e],
                                    n = this._findDotItem(i, t.groupIndex),
                                    o = this.groupLines ? this.groupLines[e] : this.groupAreas[e],
                                    r = void 0,
                                    a = void 0;
                                if (n) {
                                    if ("area" === this.chartType) {
                                        var s = o;
                                        (a = s.startLine), (o = s.line), (r = 5), this._updateAreaOpacity(C);
                                    } else r = this.lineWidth;
                                    this._updateLineStrokeOpacity(C, o), this._updateLineStrokeWidth(o, r), a && this._updateLineStrokeWidth(a, r), this._showDot(n.endDot, e), n.startDot && this._showDot(n.startDot, e);
                                }
                            }),
                            (t.prototype._findDotItem = function () {
                                var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : [],
                                    e = arguments[1];
                                return x.default.isRadialChart(this.chartType) && t.length === e && (e = 0), t[e];
                            }),
                            (t.prototype._getPivotGroupDots = function () {
                                return !this.pivotGroupDots && this.groupDots && (this.pivotGroupDots = b.default.pivot(this.groupDots)), this.pivotGroupDots;
                            }),
                            (t.prototype._showGroupDots = function (t) {
                                var e = this,
                                    i = this._getPivotGroupDots();
                                i &&
                                i[t] &&
                                i[t].forEach(function (t, i) {
                                    t.endDot && e._showDot(t.endDot, i), t.startDot && e._showDot(t.startDot, i);
                                });
                            }),
                            (t.prototype.showGroupTooltipLine = function (t, e) {
                                var i = Math.max(t.position.left, 11),
                                    n = m.default.makeLinePath({ left: i, top: e.position.top + t.dimension.height }, { left: i, top: e.position.top });
                                this.tooltipLine && this.tooltipLine.attr({ path: n, stroke: "#999", "stroke-opacity": 1 });
                            }),
                            (t.prototype.showGroupAnimation = function (t) {
                                this._showGroupDots(t);
                            }),
                            (t.prototype._hideDot = function (t, e, i) {
                                var n = this._prevDotAttributes[e],
                                    o = this.outDotStyle;
                                n && !(0, f.default)(i) && (o = { r: n.r, stroke: n.stroke, fill: n.fill, "stroke-opacity": n["stroke-opacity"], "stroke-width": n["stroke-width"], "fill-opacity": n["fill-opacity"] }),
                                    t.attr(o),
                                t.node && t.node.setAttribute("filter", ""),
                                    this.resetSeriesOrder(e);
                            }),
                            (t.prototype.hideAnimation = function (t) {
                                var e = t.groupIndex,
                                    i = t.index,
                                    n = this.groupDots[i],
                                    o = this._findDotItem(n, e),
                                    r = void 0,
                                    a = void 0,
                                    s = void 0,
                                    u = this.dotOpacity;
                                if (o) {
                                    if (((r = this.groupLines ? this.groupLines[i] : this.groupAreas[i]), "area" === this.chartType)) {
                                        a = this.lineWidth;
                                        var l = r;
                                        (s = l.startLine), (r = l.line), this._updateAreaOpacity("out");
                                    } else a = this.lineWidth;
                                    u && !(0, h.default)(this.selectedLegendIndex) && this.selectedLegendIndex !== i && (u = 0.3),
                                        this._updateLineStrokeOpacity("out", r),
                                        this._updateLineStrokeWidth(r, a),
                                    s && this._updateLineStrokeWidth(s, a),
                                    o && (this._hideDot(o.endDot.dot, i, u), o.startDot && this._hideDot(o.startDot.dot, i, u));
                                }
                            }),
                            (t.prototype._hideGroupDots = function (t) {
                                var e = this,
                                    i = !(0, h.default)(this.selectedLegendIndex),
                                    n = this.dotOpacity,
                                    o = this._getPivotGroupDots();
                                o &&
                                o[t] &&
                                o[t].forEach(function (t, o) {
                                    var r = n;
                                    r && i && e.selectedLegendIndex !== o && (r = 0.3), t.endDot && e._hideDot(t.endDot.dot, o, r), t.startDot && e._hideDot(t.startDot.dot, o, r);
                                });
                            }),
                            (t.prototype.hideGroupTooltipLine = function () {
                                this.tooltipLine.attr({ "stroke-opacity": 0 });
                            }),
                            (t.prototype.hideGroupAnimation = function (t) {
                                this._hideGroupDots(t);
                            }),
                            (t.prototype._moveDot = function (t, e) {
                                var i = { cx: e.left, cy: e.top };
                                this.dotOpacity && (i = Object.assign({ "fill-opacity": this.dotOpacity }, i, this.borderStyle)), t.attr(i);
                            }),
                            (t.prototype.animate = function (t, e) {
                                var i,
                                    n,
                                    o,
                                    r,
                                    a,
                                    s,
                                    u,
                                    l = this.paper,
                                    h = this.dimension,
                                    c = this.position,
                                    p = this.animationDuration,
                                    d = this._getClipRectId(),
                                    f = this._makeClipRectPosition(c),
                                    g = this.clipRect;
                                !D &&
                                h &&
                                (g
                                    ? (this._makeClipRectPosition(c), g.attr({ width: p ? 0 : h.width, height: h.height, x: f.left, y: f.top }))
                                    : ((g =
                                        ((i = l),
                                            (n = f),
                                            (o = h),
                                            (r = d),
                                            (a = !!p),
                                            (s = document.createElementNS("http://www.w3.org/2000/svg", "clipPath")),
                                            ((u = i.rect(n.left, n.top, a ? 0 : o.width, o.height)).id = r + "_rect"),
                                            (s.id = r),
                                            s.appendChild(u.node),
                                            i.defs.appendChild(s),
                                            u)),
                                        (this.clipRect = g)),
                                    e.forEach(function (t) {
                                        t.node.setAttribute("clip-path", "url(#" + d + ")");
                                    }),
                                p && g.animate({ width: h.width }, p, ">", t));
                            }),
                            (t.prototype._makeClipRectPosition = function (t) {
                                return { left: t.left - A.default.SERIES_EXPAND_SIZE, top: t.top - A.default.SERIES_EXPAND_SIZE };
                            }),
                            (t.prototype._makeSelectionDot = function (t) {
                                var e = t.circle(0, 0, 7);
                                return e.attr({ fill: "#ffffff", "fill-opacity": 0, "stroke-opacity": 0, "stroke-width": 2 }), e;
                            }),
                            (t.prototype.selectSeries = function (t) {
                                var e = this.groupDots[t.index][t.groupIndex],
                                    i = this.groupPositions[t.index][t.groupIndex];
                                (this.selectedItem = e),
                                    this.selectionDot.attr({ cx: i.left, cy: i.top, "fill-opacity": 0.5, "stroke-opacity": 1, stroke: this.selectionColor || e.endDot.color }),
                                this.selectionStartDot && this.selectionStartDot.attr({ cx: i.left, cy: i.startTop, "fill-opacity": 0.5, "stroke-opacity": 1, stroke: this.selectionColor || e.startDot.color });
                            }),
                            (t.prototype.unselectSeries = function (t) {
                                var e = this.groupDots[t.index][t.groupIndex];
                                this.selectedItem === e && this.selectionDot.attr({ "fill-opacity": 0, "stroke-opacity": 0 }), this.selectionStartDot && this.selectionStartDot.attr({ "fill-opacity": 0, "stroke-opacity": 0 });
                            }),
                            (t.prototype.setSize = function (t, e) {
                                (t = t || this.dimension.width), (e = e || this.dimension.height), this.paper.setSize(t, e);
                            }),
                            (t.prototype._animateByPosition = function (t, e, i) {
                                var n = { cx: e.left, cy: e.top };
                                (0, u.default)(i) && (n.transform = "t-" + i + ",0"), t.animate(n, 300);
                            }),
                            (t.prototype._animateByPath = function (t, e, i) {
                                var n = { path: e.join(" ") };
                                (0, u.default)(i) && (n.transform = "t-" + i + ",0"), t.animate(n, 300);
                            }),
                            (t.prototype._removeFirstDot = function (t) {
                                var e = t.shift();
                                e.endDot.dot.remove(), e.startDot && e.startDot.dot.remove();
                            }),
                            (t.prototype.clear = function () {
                                delete this.paper.dots, this.paper.clear();
                            }),
                            (t.prototype.resizeClipRect = function (t, e) {
                                this.paper.getById(this._getClipRectId() + "_rect").attr({ width: t, height: e });
                            }),
                            (t.prototype._getClipRectId = function () {
                                return this.clipRectId || (this.clipRectId = v.default.generateClipRectId()), this.clipRectId;
                            }),
                            (t.prototype.resetSeriesOrder = function () {}),
                            (t.prototype.moveSeriesToFront = function () {}),
                            t
                    );
                })();
            e.default = E;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(159),
                r = n(o),
                a = i(138),
                s = n(a),
                u = i(154),
                l = n(u),
                h = i(158),
                c = n(h),
                p = {
                    isBarChart: function (t) {
                        return t === l.default.CHART_TYPE_BAR;
                    },
                    isColumnChart: function (t) {
                        return t === l.default.CHART_TYPE_COLUMN;
                    },
                    isBarTypeChart: function (t) {
                        return p.isBarChart(t) || p.isColumnChart(t);
                    },
                    isColumnTypeChart: function (t, e) {
                        return p.isHeatmapChart(t) || p.isColumnChart(t) || p.isBoxplotChart(t) || p.isLineColumnComboChart(t, e);
                    },
                    isBoxplotChart: function (t) {
                        return t === l.default.CHART_TYPE_BOXPLOT;
                    },
                    isBulletChart: function (t) {
                        return t === l.default.CHART_TYPE_BULLET;
                    },
                    isRadialChart: function (t) {
                        return t === l.default.CHART_TYPE_RADIAL;
                    },
                    isDivergingChart: function (t, e) {
                        return this.isBarTypeChart(t) && e;
                    },
                    isNormalStackChart: function (t, e) {
                        var i = p.isAllowedStackOption(t),
                            n = p.isNormalStack(e);
                        return i && n;
                    },
                    isPercentStackChart: function (t, e) {
                        var i = p.isAllowedStackOption(t),
                            n = p.isPercentStack(e);
                        return i && n;
                    },
                    isComboChart: function (t) {
                        return t === l.default.CHART_TYPE_COMBO;
                    },
                    isLineColumnComboChart: function (t, e) {
                        var i = c.default.all(e || [], function (t) {
                            return p.isLineChart(t) || p.isColumnChart(t);
                        });
                        return p.isComboChart(t) && i;
                    },
                    isPieDonutComboChart: function (t, e) {
                        var i = c.default.all(e, function (t) {
                            return p.isPieChart(t);
                        });
                        return p.isComboChart(t) && i;
                    },
                    isLineChart: function (t) {
                        return t === l.default.CHART_TYPE_LINE;
                    },
                    isAreaChart: function (t) {
                        return t === l.default.CHART_TYPE_AREA;
                    },
                    isLineAreaComboChart: function (t, e) {
                        var i = c.default.all(e || [], function (t) {
                            return p.isLineChart(t) || p.isAreaChart(t);
                        });
                        return p.isComboChart(t) && i;
                    },
                    hasLineChart: function (t, e) {
                        var i = c.default.any(e || [], function (t) {
                            return p.isLineChart(t);
                        });
                        return p.isComboChart(t) && i;
                    },
                    isLineScatterComboChart: function (t, e) {
                        var i = c.default.all(e || [], function (t) {
                            return p.isLineChart(t) || p.isScatterChart(t);
                        });
                        return p.isComboChart(t) && i;
                    },
                    isLineTypeChart: function (t, e) {
                        return p.isLineChart(t) || p.isAreaChart(t) || p.isLineAreaComboChart(t, e);
                    },
                    isBubbleChart: function (t) {
                        return t === l.default.CHART_TYPE_BUBBLE;
                    },
                    isScatterChart: function (t) {
                        return t === l.default.CHART_TYPE_SCATTER;
                    },
                    isHeatmapChart: function (t) {
                        return t === l.default.CHART_TYPE_HEATMAP;
                    },
                    isTreemapChart: function (t) {
                        return t === l.default.CHART_TYPE_TREEMAP;
                    },
                    isBoxTypeChart: function (t) {
                        return p.isHeatmapChart(t) || p.isTreemapChart(t);
                    },
                    isMapTypeChart: function (t) {
                        return this.isMapChart(t) || this.isHeatmapChart(t) || this.isTreemapChart(t);
                    },
                    isPieChart: function (t) {
                        return t && -1 !== t.indexOf(l.default.CHART_TYPE_PIE);
                    },
                    isMapChart: function (t) {
                        return t === l.default.CHART_TYPE_MAP;
                    },
                    isCoordinateTypeChart: function (t) {
                        return p.isBubbleChart(t) || p.isScatterChart(t);
                    },
                    allowMinusPointRender: function (t) {
                        return p.isLineTypeChart(t) || p.isCoordinateTypeChart(t) || p.isBoxTypeChart(t) || p.isBulletChart(t);
                    },
                    isChartToDetectMouseEventOnSeries: function (t) {
                        return p.isPieChart(t) || p.isMapChart(t) || p.isCoordinateTypeChart(t);
                    },
                    isLabelAlignOuter: function (t) {
                        return t === l.default.LABEL_ALIGN_OUTER;
                    },
                    isShowLabel: function (t) {
                        return t.showLabel || t.showLegend;
                    },
                    isShowOuterLabel: function (t) {
                        return p.isShowLabel(t) && p.isLabelAlignOuter(t.labelAlign);
                    },
                    isLegendAlignLeft: function (t) {
                        return t === l.default.LEGEND_ALIGN_LEFT;
                    },
                    isLegendAlignTop: function (t) {
                        return t === l.default.LEGEND_ALIGN_TOP;
                    },
                    isLegendAlignBottom: function (t) {
                        return t === l.default.LEGEND_ALIGN_BOTTOM;
                    },
                    isHorizontalLegend: function (t) {
                        return p.isLegendAlignTop(t) || p.isLegendAlignBottom(t);
                    },
                    isVerticalLegend: function (t) {
                        return !p.isHorizontalLegend(t);
                    },
                    isAllowedStackOption: function (t) {
                        return p.isBarChart(t) || p.isColumnChart(t) || p.isAreaChart(t);
                    },
                    isNormalStack: function (t) {
                        return !!t && t.type === l.default.NORMAL_STACK_TYPE;
                    },
                    isPercentStack: function (t) {
                        return !!t && t.type === l.default.PERCENT_STACK_TYPE;
                    },
                    isValidStackOption: function (t) {
                        return p.isNormalStack(t) || p.isPercentStack(t);
                    },
                    isAllowRangeData: function (t) {
                        return p.isBarTypeChart(t) || p.isAreaChart(t);
                    },
                    isYAxisAlignCenter: function (t, e) {
                        return !t && e === l.default.YAXIS_ALIGN_CENTER;
                    },
                    isMinusLimit: function (t) {
                        return t.min <= 0 && t.max <= 0;
                    },
                    isAutoTickInterval: function (t) {
                        return t === l.default.TICK_INTERVAL_AUTO;
                    },
                    isValidLabelInterval: function (t, e) {
                        return t && t > 1 && !e;
                    },
                    isDatetimeType: function (t) {
                        return t === l.default.AXIS_TYPE_DATETIME;
                    },
                    isSupportPublicShowTooptipAPI: function (t) {
                        return this.isBarChart(t) || this.isColumnChart(t) || this.isLineChart(t) || this.isAreaChart(t) || this.isBoxplotChart(t);
                    },
                    isSupportPublicHideTooptipAPI: function (t) {
                        return this.isBarChart(t) || this.isColumnChart(t) || this.isLineChart(t) || this.isAreaChart(t) || this.isBoxplotChart(t);
                    },
                    isRenderConnector: function (t) {
                        return ((0, r.default)(t) && t) || ((0, s.default)(t) && ("solid" === t.type || "dotted" === t.type));
                    },
                };
            e.default = p;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                r = i(137),
                a = n(r),
                s = i(150),
                u = n(s),
                l = i(145),
                h = n(l),
                c = i(163),
                p = n(c),
                d = i(143),
                f = n(d),
                g = i(154),
                m = n(g),
                y = i(166),
                v = n(y),
                $ = m.default.GUIDE_AREACHART_AREAOPACITY_TYPE,
                x = m.default.CLASS_NAME_SVG_AUTOSHAPE,
                T = (function (t) {
                    function e() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        var i = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this));
                        return (i.selectedLegendIndex = null), (i.chartType = "area"), (i.lineWidth = 0), i;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype.render = function (t, e) {
                                var i = e.dimension,
                                    n = e.groupPositions,
                                    o = e.theme,
                                    r = void 0 === o ? {} : o,
                                    a = e.position,
                                    s = e.zeroTop,
                                    l = e.hasRangeData,
                                    h = e.options,
                                    c = r.dot,
                                    p = void 0 === c ? {} : c,
                                    d = r.colors,
                                    f = h.spline,
                                    g = h.allowSelect,
                                    m = h.connectNulls,
                                    y = h.pointWidth,
                                    v = h.showDot,
                                    $ = h.areaOpacity,
                                    x = this._isAreaOpacityNumber($) ? $ : 1,
                                    T = v ? 1 : 0,
                                    b = this.makeBorderStyle(p.strokeColor, T, p.strokeWidth),
                                    _ = this.makeOutDotStyle(T, b),
                                    A = (this.lineWidth = (0, u.default)(y) ? y : this.lineWidth);
                                (this.paper = t),
                                    (this.theme = r),
                                    (this.isSpline = f),
                                    (this.dimension = i),
                                    (this.position = a),
                                    (this.zeroTop = s),
                                    (this.hasRangeData = l),
                                    (this.animationDuration = e.options.animationDuration),
                                    t.setStart(),
                                    (this.groupPaths = this._getAreaChartPath(n, null, m)),
                                    (this.groupAreas = this._renderAreas(t, this.groupPaths, d, A, x)),
                                    (this.tooltipLine = this._renderTooltipLine(t, i.height)),
                                    (this.groupDots = this._renderDots(t, n, d, T)),
                                g && ((this.selectionDot = this._makeSelectionDot(t)), (this.selectionColor = r.selectionColor), this.hasRangeData && (this.selectionStartDot = this._makeSelectionDot(t))),
                                    (this.outDotStyle = _),
                                    (this.groupPositions = n),
                                    (this.dotOpacity = T),
                                    (this.pivotGroupDots = null);
                                var D = t.setFinish();
                                return this._moveSeriesToFrontAll(), this.tooltipLine.toFront(), D;
                            }),
                            (e.prototype._moveSeriesToFrontAll = function () {
                                for (var t = this.groupPaths ? this.groupPaths.length : 0, e = 0; e < t; e += 1) this.moveSeriesToFront(this.groupAreas[e], this.groupDots[e]);
                            }),
                            (e.prototype._getAreaChartPath = function (t, e, i) {
                                return this.isSpline ? this._makeSplineAreaChartPath(t, e) : this._makeAreaChartPath(t, e, i);
                            }),
                            (e.prototype._renderAreas = function (t, e, i, n, o) {
                                return (
                                    (i = i.slice(0, e.length)).reverse(),
                                        e.reverse(),
                                        e
                                            .map(function (e, r) {
                                                var a = {},
                                                    s = i[r] || "transparent",
                                                    u = s,
                                                    l = f.default.renderArea(t, e.area.join(" "), { fill: s, opacity: o, stroke: s }),
                                                    h = f.default.renderLine(t, e.line.join(" "), { color: u, strokeWidth: n });
                                                return (
                                                    l.node.setAttribute("class", x),
                                                        h.node.setAttribute("class", x),
                                                        (a.area = l),
                                                        (a.line = h),
                                                    e.startLine && (a.startLine = f.default.renderLine(t, e.startLine.join(" "), { color: u, strokeWidth: 0 })),
                                                        a
                                                );
                                            })
                                            .reverse()
                                );
                            }),
                            (e.prototype._makeHeight = function (t, e) {
                                return Math.abs(t - e);
                            }),
                            (e.prototype._makeAreasPath = function (t, e) {
                                var i,
                                    n = [],
                                    r = t.length,
                                    a = [],
                                    s = [],
                                    u = [],
                                    l = !1;
                                if (
                                    (Object.entries(t).forEach(function (t) {
                                        var e = o(t, 2),
                                            i = e[0],
                                            a = e[1],
                                            h = void 0;
                                        a ? (l ? ((h = "M"), (l = !1)) : (h = "L"), u.push([h, a.left, a.top]), s.unshift(["L", a.left, a.startTop])) : ((l = !0), s.push(["z"])),
                                        (a && parseInt(i, 10) !== r - 1) || (n.push(u.concat(s)), (u = []), (s = []));
                                    }),
                                        n.forEach(function (t) {
                                            a = a.concat(t);
                                        }),
                                    !1 !== e)
                                ) {
                                    var h = t.length - 1;
                                    a.splice(h + 1, 0, a[h], a[h + 1]);
                                }
                                return (
                                    ((a = (i = []).concat.apply(
                                        i,
                                        (function t(e) {
                                            if (Array.isArray(e)) {
                                                for (var i = 0, n = Array(e.length); i < e.length; i++) n[i] = e[i];
                                                return n;
                                            }
                                            return Array.from(e);
                                        })(a)
                                    ))[0] = "M"),
                                        a
                                );
                            }),
                            (e.prototype._makeAreaChartPath = function (t, e, i) {
                                var n = this;
                                return t.map(function (t) {
                                    var o = { area: n._makeAreasPath(t, e), line: n._makeLinesPath(t, null, i) };
                                    return n.hasRangeData && (o.startLine = n._makeLinesPath(t, "startTop")), o;
                                });
                            }),
                            (e.prototype._makeSplineAreaChartPath = function (t, e) {
                                var i = this;
                                return t.map(function (t) {
                                    var n = t
                                            .concat()
                                            .reverse()
                                            .map(function (t) {
                                                return { left: t.left, top: t.startTop };
                                            }),
                                        o = i._makeSplineLinesPath(t),
                                        r = i._makeSplineLinesPath(n, { isReverseDirection: !0, isBeConnected: !0 }),
                                        a = JSON.parse(JSON.stringify(o)),
                                        s = JSON.parse(JSON.stringify(r));
                                    if (!1 !== e) {
                                        var u = t[t.length - 1],
                                            l = n[n.length - 1];
                                        a.push(["K", u.left, u.top]), a.push(["L", u.left, u.startTop]), s.push(["K", l.left, l.top]), s.push(["L", l.left, l.top]);
                                    }
                                    return { area: a.concat(s), line: o };
                                });
                            }),
                            (e.prototype.resize = function (t) {
                                var e = this,
                                    i = t.dimension,
                                    n = t.groupPositions,
                                    o = t.zeroTop;
                                this.resizeClipRect(i.width, i.height),
                                    (this.zeroTop = o),
                                    (this.groupPositions = n),
                                    (this.groupPaths = this._getAreaChartPath(n)),
                                    this.paper.setSize(i.width, i.height),
                                    this.tooltipLine.attr({ top: i.height }),
                                    this.groupPaths.forEach(function (t, i) {
                                        var o = e.groupAreas[i];
                                        o.area.attr({ path: t.area.join(" ") }),
                                            o.line.attr({ path: t.line.join(" ") }),
                                        o.startLine && o.startLine.attr({ path: t.startLine.join(" ") }),
                                            e.groupDots[i].forEach(function (t, o) {
                                                var r = n[i][o];
                                                if ((t.endDot && e._moveDot(t.endDot.dot, r), t.startDot)) {
                                                    var a = Object.assign({}, r);
                                                    (a.top = a.startTop), e._moveDot(t.startDot.dot, a);
                                                }
                                            });
                                    });
                            }),
                            (e.prototype.selectLegend = function (t) {
                                var e = this,
                                    i = (0, a.default)(t);
                                this.selectedLegendIndex && -1 !== this.selectedLegendIndex && this.resetSeriesOrder(this.selectedLegendIndex),
                                    (this.selectedLegendIndex = t),
                                    this.groupAreas.forEach(function (n, o) {
                                        var r = t === o,
                                            a = i || r ? 1 : 0.3,
                                            s = e.groupDots[o];
                                        n.area.attr({ "fill-opacity": a }), n.line.attr({ "stroke-opacity": a }), n.startLine && n.startLine.attr({ "stroke-opacity": a }), r && e.moveSeriesToFront(n, s);
                                    });
                            }),
                            (e.prototype.resetSeriesOrder = function (t) {
                                var e = t + 1 < this.groupAreas.length ? this.groupAreas[t + 1] : null;
                                if (e) {
                                    var i = e.area;
                                    this.groupAreas[t].area.insertBefore(i),
                                        this.groupAreas[t].line.insertBefore(i),
                                        this.groupDots[t].forEach(function (t) {
                                            t && t.endDot && t.endDot.dot.insertBefore(i);
                                        });
                                }
                            }),
                            (e.prototype.moveSeriesToFront = function (t, e) {
                                t.line.toFront(),
                                    t.area.toFront(),
                                t.startLine && t.startLine.toFront(),
                                    e.forEach(function (t) {
                                        t.endDot.dot.toFront(), t.startDot && t.startDot.dot.toFront();
                                    });
                            }),
                            (e.prototype.animateForAddingData = function (t, e, i, n, o) {
                                var r = this,
                                    a = this._getAreaChartPath(i, !1),
                                    s = 0;
                                i.length &&
                                (n && (s = 1),
                                    (this.zeroTop = o),
                                    this.groupAreas.forEach(function (t, o) {
                                        var u = r.groupDots[o],
                                            l = i[o],
                                            h = a[o];
                                        n && r._removeFirstDot(u),
                                            u.forEach(function (t, i) {
                                                var n = l[i + s];
                                                r._animateByPosition(t.endDot.dot, n, e), t.startDot && r._animateByPosition(t.startDot.dot, { left: n.left, top: n.startTop }, e);
                                            }),
                                            r._animateByPath(t.area, h.area, e),
                                            r._animateByPath(t.line, h.line, e),
                                        t.startLine && r._animateByPath(t.startLine, h.startLine, e);
                                    }));
                            }),
                            (e.prototype.renderSeriesLabel = function (t, e, i, n) {
                                var o = { "font-size": n.fontSize, "font-family": n.fontFamily, "font-weight": n.fontWeight, fill: n.color, "text-anchor": "middle", opacity: 0 },
                                    r = t.set();
                                return (
                                    i.forEach(function (i, n) {
                                        i.forEach(function (i, a) {
                                            var s = e[n][a],
                                                u = f.default.renderText(t, s.end, i.end, o);
                                            if ((r.push(u), (u.node.style.userSelect = "none"), (u.node.style.cursor = "default"), u.node.setAttribute("filter", "url(#glow)"), s.start)) {
                                                var l = f.default.renderText(t, s.start, i.start, o);
                                                (l.node.style.userSelect = "none"), (l.node.style.cursor = "default"), l.node.setAttribute("filter", "url(#glow)"), r.push(l);
                                            }
                                        });
                                    }),
                                        r
                                );
                            }),
                            (e.prototype._isAreaOpacityNumber = function (t) {
                                var e = (0, u.default)(t);
                                return e ? (t < 0 || t > 1) && v.default.print($, "warn") : (0, h.default)(t) || v.default.print($, "error"), e;
                            }),
                            e
                    );
                })(p.default);
            e.default = T;
        },
        function (t, e) {
            "use strict";
            (e.__esModule = !0),
                (e.default = {
                    print: function (t) {
                        var e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "log";
                        window.console && window.console[e](t);
                    },
                });
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                r = i(146),
                a = n(r),
                s = i(137),
                u = n(s),
                l = i(135),
                h = n(l),
                c = i(143),
                p = n(c),
                d = Math.PI / 180,
                f = (function () {
                    function t() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t);
                    }
                    return (
                        (t.prototype.render = function (t, e, i) {
                            var n = t.set();
                            (this.animationDuration = e.options.animationDuration), (this.paper = t);
                            var r = o(e.options.radiusRange, 1);
                            return (
                                (this.holeRatio = r[0]),
                                    (this.chartBackground = e.chartBackground),
                                    (this.chartType = e.chartType),
                                    (this.callbacks = i),
                                    (this.selectionColor = e.theme.selectionColor),
                                    (this.circleBound = e.circleBound),
                                    (this.sectorName = "sector_" + this.chartType),
                                    this._setSectorAttr(),
                                    (this.sectorInfos = this._renderPie(e.sectorData, e.theme.colors, e.additionalIndex, n)),
                                    (this.overlay = this._renderOverlay()),
                                    (this.labelInfos = { value: [], legend: [] }),
                                    (this.prevPosition = null),
                                    (this.prevHoverSector = null),
                                    n
                            );
                        }),
                            (t.prototype.clear = function () {
                                this.paper.clear();
                            }),
                            (t.prototype._makeSectorPath = function (t, e, i, n, o) {
                                var r = n * d,
                                    a = o * d;
                                return { path: ["M", t, e, "L", t + i * Math.sin(r), e - i * Math.cos(r), "A", i, i, 0, o - n > 180 ? 1 : 0, 1, t + i * Math.sin(a), e - i * Math.cos(a), "Z"] };
                            }),
                            (t.prototype._makeDonutSectorPath = function (t, e, i, n, o, r) {
                                var a = n * d,
                                    s = o * d,
                                    u = r || i * this.holeRatio,
                                    l = o - n > 180 ? 1 : 0;
                                return {
                                    path: [
                                        "M",
                                        t + i * Math.sin(a),
                                        e - i * Math.cos(a),
                                        "A",
                                        i,
                                        i,
                                        0,
                                        l,
                                        1,
                                        t + i * Math.sin(s),
                                        e - i * Math.cos(s),
                                        "L",
                                        t + u * Math.sin(s),
                                        e - u * Math.cos(s),
                                        "A",
                                        u,
                                        u,
                                        0,
                                        l,
                                        0,
                                        t + u * Math.sin(a),
                                        e - u * Math.cos(a),
                                        "Z",
                                    ],
                                };
                            }),
                            (t.prototype._setSectorAttr = function () {
                                var t = void 0;
                                this.paper.customAttributes[this.sectorName] || ((t = this.holeRatio ? this._makeDonutSectorPath : this._makeSectorPath), (this.paper.customAttributes[this.sectorName] = t.bind(this)));
                            }),
                            (t.prototype._renderOverlay = function () {
                                var t = { paper: this.paper, circleBound: { cx: 0, cy: 0, r: 0 }, angles: { startAngle: 0, endAngle: 0 }, attrs: { fill: "none", opacity: 0, stroke: this.chartBackground.color, "stroke-width": 1 } },
                                    e = this._renderSector(t);
                                return e.node.setAttribute("class", "auto-shape-rendering"), e.data("id", "overlay"), e.data("chartType", this.chartType), e;
                            }),
                            (t.prototype._renderSector = function (t) {
                                var e = t.circleBound,
                                    i = t.angles,
                                    n = t.attrs;
                                return (n[this.sectorName] = [e.cx, e.cy, e.r, i.startAngle, i.endAngle]), t.paper.path().attr(n);
                            }),
                            (t.prototype._renderPie = function (t, e, i, n) {
                                var o = this,
                                    r = this.circleBound,
                                    a = this.chartBackground,
                                    s = this.animationDuration,
                                    u = [];
                                return (
                                    t.forEach(function (t, l) {
                                        var h = t.ratio,
                                            c = t.angles,
                                            p = e[l],
                                            d = o._renderSector({ paper: o.paper, circleBound: r, angles: s ? c.start : c.end, attrs: { fill: s ? a.color : p, stroke: a.color, "stroke-width": 0 } });
                                        d.node.setAttribute("class", "auto-shape-rendering"),
                                            d.data("index", l),
                                            d.data("legendIndex", l + i),
                                            d.data("chartType", o.chartType),
                                            u.push({ sector: d, color: p, angles: c.end, ratio: h }),
                                            n.push(d);
                                    }),
                                        u
                                );
                            }),
                            (t.prototype._showOverlay = function (t, e) {
                                var i = this.overlay,
                                    n = this.sectorInfos[t],
                                    o = n.angles.startAngle,
                                    r = n.angles.endAngle,
                                    a = this.circleBound,
                                    s = { fill: "#fff", opacity: 1, "stroke-width": 7, "stroke-color": "#fff", "stroke-miterlimit": 15 };
                                (s[this.sectorName] = [a.cx, a.cy, a.r, o, r, a.r * this.holeRatio]),
                                    i.attr(s),
                                    i.data("index", t),
                                    i.data("legendIndex", e),
                                    i.node.setAttribute("filter", "url(#shadow)"),
                                    this._indexingOverlapElement([i, n.sector, this.labelInfos.legend[t], this.labelInfos.value[t]]);
                            }),
                            (t.prototype._indexingOverlapElement = function (t) {
                                t.forEach(function (t) {
                                    t && t.toFront();
                                });
                            }),
                            (t.prototype._hideOverlay = function () {
                                this.overlay.attr({ fill: "none", opacity: 0 }), this._indexingOverlapElement(this.labelInfos.legend), this._indexingOverlapElement(this.labelInfos.value);
                            }),
                            (t.prototype.animate = function (t) {
                                var e = this.sectorName,
                                    i = this.circleBound,
                                    n = this.animationDuration,
                                    o = [i.cx, i.cy, i.r],
                                    r = 0;
                                this.sectorInfos.forEach(function (t) {
                                    var i = t.angles,
                                        a = { fill: t.color };
                                    if (n) {
                                        var s = n * t.ratio;
                                        0 === i.startAngle && 360 === i.endAngle && (i.endAngle = 359.99), (a[e] = o.concat([i.startAngle, i.endAngle]));
                                        var u = h.default.animation(a, s, ">");
                                        t.sector.animate(u.delay(r)), (r += s);
                                    }
                                }),
                                t && setTimeout(t, r);
                            }),
                            (t.prototype.resize = function (t) {
                                var e = t.dimension,
                                    i = t.circleBound;
                                (this.circleBound = i), this.paper.setSize(e.width, e.height);
                            }),
                            (t.prototype.findSectorInfo = function (t) {
                                var e = this.paper && this.paper.getElementByPoint(t.left, t.top),
                                    i = null;
                                return e && (i = { legendIndex: (0, a.default)(e.data("legendIndex")) ? e.data("legendIndex") : -1, index: (0, a.default)(e.data("index")) ? e.data("index") : -1, chartType: e.data("chartType") }), i;
                            }),
                            (t.prototype._isChangedPosition = function (t, e) {
                                return !t || t.left !== e.left || t.top !== e.top;
                            }),
                            (t.prototype._showTooltip = function (t, e) {
                                var i = [{}, 0, t.data("index"), { left: e.left - 20, top: e.top - 20 }];
                                this.callbacks.showTooltip.apply(null, i);
                            }),
                            (t.prototype._isValidSector = function (t) {
                                return t && t.data("chartType") === this.chartType;
                            }),
                            (t.prototype.moveMouseOnSeries = function (t) {
                                var e = this.paper && this.paper.getElementByPoint(t.left, t.top);
                                this._isValidSector(e)
                                    ? (this.prevHoverSector !== e && (this._showOverlay(e.data("index"), e.data("legendIndex")), (this.prevHoverSector = e)), this._isChangedPosition(this.prevPosition, t) && this._showTooltip(e, t))
                                    : this.prevHoverSector && (this._hideOverlay(), this.callbacks.hideTooltip(), (this.prevHoverSector = null)),
                                    (this.prevPosition = t);
                            }),
                            (t.prototype.selectSeries = function (t) {
                                var e = this.sectorInfos[t.index];
                                if (e) {
                                    var i = h.default.color(e.color),
                                        n = p.default.makeChangedLuminanceColor(i.hex, 0.2),
                                        o = this.selectionColor || n;
                                    e.sector.attr({ fill: o });
                                }
                            }),
                            (t.prototype.unselectSeries = function (t) {
                                var e = this.sectorInfos[t.index];
                                e && e.sector.attr({ fill: e.color });
                            }),
                            (t.prototype.selectLegend = function (t) {
                                var e = (0, u.default)(t);
                                this.sectorInfos.forEach(function (i, n) {
                                    i.sector.attr({ "fill-opacity": e || t === n ? 1 : 0.3 });
                                });
                            }),
                            (t.prototype.getRenderedLabelWidth = function (t, e) {
                                return p.default.getRenderedTextSize(t, e.fontSize, e.fontFamily).width;
                            }),
                            (t.prototype.getRenderedLabelHeight = function (t, e) {
                                return p.default.getRenderedTextSize(t, e.fontSize, e.fontFamily).height;
                            }),
                            (t.prototype.renderLabels = function (t) {
                                var e = this,
                                    i = t.theme,
                                    n = t.labelFilter,
                                    o = t.dataType,
                                    r = t.ratioValues,
                                    a = t.seriesNames,
                                    s = { "font-size": i.fontSize, "font-family": t.fontFamily ? t.fontFamily : t.theme.fontFamily, "font-weight": i.fontWeight, "text-anchor": "middle", fill: i.color || "#fff", opacity: 0 };
                                t.positions.forEach(function (i, u) {
                                    var l = r[u],
                                        h = n && !n({ value: t.labels[u], labelType: o, seriesName: a[u], ratio: l }),
                                        c = void 0;
                                    h ||
                                    (t.colors && (s.fill = t.colors[u]),
                                    i && (((c = p.default.renderText(t.paper, i, t.labels[u], s)).node.style.userSelect = "none"), (c.node.style.cursor = "default"), c.node.setAttribute("class", "auto-shape-rendering")),
                                        e.labelInfos[o].push(c),
                                        t.labelSet.push(c));
                                }, this),
                                this.labelSet || (this.labelSet = t.labelSet);
                            }),
                            t
                    );
                })();
            e.default = f;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(137),
                r = n(o),
                a = i(163),
                s = n(a),
                u = i(143),
                l = n(u),
                h = (function (t) {
                    function e() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        var i = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this));
                        return (i.selectedLegendIndex = null), (i.chartType = "radial"), (i.lineWidth = 6), i;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype.render = function (t, e) {
                                var i = e.dimension,
                                    n = e.groupPositions,
                                    o = e.theme,
                                    r = o.colors,
                                    a = e.options,
                                    s = a.pointWidth,
                                    u = a.showDot,
                                    l = a.showArea,
                                    h = u ? 1 : 0,
                                    c = this._getLinesPath(n),
                                    p = this.makeBorderStyle(o.strokeColor, h, o.strokeWidth),
                                    d = this.makeOutDotStyle(h, p),
                                    f = t.set(),
                                    g = (this.lineWidth = s || this.lineWidth),
                                    m = n.map(function (t) {
                                        return t.pop(), t;
                                    });
                                return (
                                    (this.paper = t),
                                        (this.theme = o),
                                        (this.dimension = i),
                                        (this.position = e.position),
                                    l && (this.groupAreas = this._renderArea(t, c, r, f)),
                                        (this.groupLines = this._renderLines(t, c, r, g, f)),
                                        (this.groupDots = this._renderDots(t, m, r, h, f)),
                                    e.options.allowSelect && ((this.selectionDot = this._makeSelectionDot(t)), (this.selectionColor = o.selectionColor)),
                                        (this.colors = r),
                                        (this.borderStyle = p),
                                        (this.outDotStyle = d),
                                        (this.groupPositions = n),
                                        (this.groupPaths = c),
                                        (this.dotOpacity = h),
                                        (this.showArea = l),
                                        f
                                );
                            }),
                            (e.prototype._getLinesPath = function (t) {
                                var e = this;
                                return t.map(function (t) {
                                    return e._makeLinesPath(t);
                                });
                            }),
                            (e.prototype._renderLines = function (t, e, i, n, o) {
                                return e.map(function (e, r) {
                                    var a = i[r] || "transparent",
                                        s = l.default.renderLine(t, e.join(" "), { color: a, strokeWidth: n });
                                    return o.push(s), s;
                                });
                            }),
                            (e.prototype._renderArea = function (t, e, i, n) {
                                var o = this;
                                return e.map(function (e, r) {
                                    var a = i[r] || "transparent",
                                        s = l.default.renderArea(t, e, { fill: a, opacity: 0.4, "stroke-width": o.lineWidth, stroke: a });
                                    return n.push(s), s;
                                }, this);
                            }),
                            (e.prototype.resize = function (t) {
                                var e = this,
                                    i = t.dimension,
                                    n = t.groupPositions;
                                (this.groupPositions = n),
                                    (this.groupPaths = this._getLinesPath(n)),
                                    this.paper.setSize(i.width, i.height),
                                    this.groupPaths.forEach(function (t, i) {
                                        e.groupLines[i].attr({ path: t.join(" ") }),
                                        e.showArea && e.groupAreas[i].attr({ path: t.join(" ") }),
                                            e.groupDots[i].forEach(function (t, o) {
                                                e._moveDot(t.endDot.dot, n[i][o]);
                                            });
                                    });
                            }),
                            (e.prototype.selectLegend = function (t) {
                                var e = (0, r.default)(t);
                                (this.selectedLegendIndex = t),
                                    this.groupLines.forEach(function (i, n) {
                                        i.attr({ "stroke-opacity": e || t === n ? 1 : 0.3 });
                                    });
                            }),
                            e
                    );
                })(s.default);
            e.default = h;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(135),
                r = n(o),
                a = i(146),
                s = n(a),
                u = i(137),
                l = n(u),
                h = i(145),
                c = n(h),
                p = i(143),
                d = n(p),
                f = 0.3,
                g = (function () {
                    function t() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t);
                    }
                    return (
                        (t.prototype.render = function (t, e, i) {
                            var n = t.set();
                            return (
                                (this.paper = t),
                                    (this.animationDuration = e.options.animationDuration),
                                    (this.theme = e.theme),
                                    (this.seriesDataModel = e.seriesDataModel),
                                    (this.groupBounds = e.groupBounds),
                                    (this.callbacks = i),
                                    (this.overlay = this._renderOverlay()),
                                    (this.groupCircleInfos = this._renderCircles(n)),
                                    (this.prevCircle = null),
                                    (this.prevOverCircle = null),
                                    (this.animationTimeoutId = null),
                                    (this.selectedLegend = null),
                                this.paper.raphael.svg && this.appendShadowFilterToDefs(),
                                    n
                            );
                        }),
                            (t.prototype._renderOverlay = function () {
                                return d.default.renderCircle(this.paper, { left: 0, top: 0 }, 0, { fill: "none", stroke: "#fff", "stroke-opacity": 1, "stroke-width": 2 });
                            }),
                            (t.prototype._renderCircles = function (t) {
                                var e = this,
                                    i = this.theme.colors;
                                return this.groupBounds.map(function (n, o) {
                                    return n.map(function (n, r) {
                                        var a = null;
                                        if (n) {
                                            var s = i[r],
                                                u = d.default.renderCircle(e.paper, n, e.animationDuration ? 0 : n.radius, { fill: s, opacity: e.animationDuration ? 0 : 0.8, stroke: "none" });
                                            t.push(u), u.data("groupIndex", o), u.data("index", r), (a = { circle: u, color: s, bound: n });
                                        }
                                        return a;
                                    });
                                });
                            }),
                            (t.prototype._animateCircle = function (t, e, i) {
                                t.animate({ r: e, opacity: 0.8 }, i, ">");
                            }),
                            (t.prototype.animate = function () {
                                var t = this;
                                d.default.forEach2dArray(this.groupCircleInfos, function (e) {
                                    e && t.animationDuration && t._animateCircle(e.circle, e.bound.radius, t.animationDuration);
                                });
                            }),
                            (t.prototype._updatePosition = function (t, e) {
                                t.attr({ cx: e.left, cy: e.top, r: e.radius });
                            }),
                            (t.prototype.resize = function (t) {
                                var e = this,
                                    i = t.dimension,
                                    n = t.groupBounds;
                                (this.groupBounds = n),
                                    this.paper.setSize(i.width, i.height),
                                    d.default.forEach2dArray(this.groupCircleInfos, function (t, i, o) {
                                        var r = n[i][o];
                                        t && ((t.bound = r), e._updatePosition(t.circle, r));
                                    });
                            }),
                            (t.prototype.findIndexes = function (t) {
                                var e = this.paper.getElementByPoint(t.left, t.top),
                                    i = null;
                                return e && (i = { index: e.data("index"), groupIndex: e.data("groupIndex") }), i;
                            }),
                            (t.prototype.appendShadowFilterToDefs = function () {
                                var t = document.createElementNS("http://www.w3.org/2000/svg", "filter"),
                                    e = document.createElementNS("http://www.w3.org/2000/svg", "feOffset"),
                                    i = document.createElementNS("http://www.w3.org/2000/svg", "feGaussianBlur"),
                                    n = document.createElementNS("http://www.w3.org/2000/svg", "feBlend");
                                t.setAttributeNS(null, "id", "shadow"),
                                    t.setAttributeNS(null, "x", "-50%"),
                                    t.setAttributeNS(null, "y", "-50%"),
                                    t.setAttributeNS(null, "width", "180%"),
                                    t.setAttributeNS(null, "height", "180%"),
                                    e.setAttributeNS(null, "result", "offOut"),
                                    e.setAttributeNS(null, "in", "SourceAlpha"),
                                    e.setAttributeNS(null, "dx", "0"),
                                    e.setAttributeNS(null, "dy", "0"),
                                    i.setAttributeNS(null, "result", "blurOut"),
                                    i.setAttributeNS(null, "in", "offOut"),
                                    i.setAttributeNS(null, "stdDeviation", "2"),
                                    n.setAttributeNS(null, "in", "SourceGraphic"),
                                    n.setAttributeNS(null, "in2", "blurOut"),
                                    n.setAttributeNS(null, "mode", "normal"),
                                    t.appendChild(e),
                                    t.appendChild(i),
                                    t.appendChild(n),
                                    this.paper.defs.appendChild(t);
                            }),
                            (t.prototype._isChangedPosition = function (t, e) {
                                return !t || t.left !== e.left || t.top !== e.top;
                            }),
                            (t.prototype.showAnimation = function (t) {
                                var e = this.groupCircleInfos[t.groupIndex][t.index],
                                    i = e.bound;
                                (this.circle = e.circle),
                                    this.overlay.attr({ fill: e.color, cx: i.left, cy: i.top, r: i.radius + 2, stroke: "#fff", opacity: 1 }),
                                    this.circle.attr({ opacity: 1 }),
                                    this.overlay.node.setAttribute("filter", "url(#shadow)"),
                                    this.overlay.toFront(),
                                    this.circle.toFront();
                            }),
                            (t.prototype.hideAnimation = function (t) {
                                var e = f;
                                this.overlay.attr({ cx: 0, cy: 0, r: 0, opacity: 0 }), ((0, l.default)(this.selectedLegend) || t.index === this.selectedLegend) && (e = 0.8), this.circle.attr({ opacity: e });
                            }),
                            (t.prototype._findCircle = function (t) {
                                for (var e = [], i = this.paper, n = void 0; (0, c.default)(n); ) {
                                    var o = i.getElementByPoint(t.left, t.top);
                                    o ? (o.attrs.opacity > f ? (n = o) : (e.push(o), o.hide())) : (n = null);
                                }
                                return (
                                    n || (n = e[0]),
                                        e.forEach(function (t) {
                                            t.show();
                                        }),
                                        n
                                );
                            }),
                            (t.prototype.moveMouseOnSeries = function (t) {
                                var e = this._findCircle(t);
                                if (e && (0, s.default)(e.data("groupIndex"))) {
                                    var i = [{}, e.data("groupIndex"), e.data("index"), { left: t.left - 20, top: t.top - 20 }];
                                    this._isChangedPosition(this.prevPosition, t) && (this.callbacks.showTooltip.apply(null, i), (this.prevOverCircle = e));
                                } else this.prevOverCircle && (this.callbacks.hideTooltip(), (this.prevOverCircle = null));
                                this.prevPosition = t;
                            }),
                            (t.prototype.selectSeries = function (t) {
                                var e = t.groupIndex,
                                    i = t.index,
                                    n = this.groupCircleInfos[e][i],
                                    o = r.default.color(n.color),
                                    a = this.theme.selectionColor || d.default.makeChangedLuminanceColor(o.hex, 0.2);
                                n.circle.attr({ fill: a });
                            }),
                            (t.prototype.unselectSeries = function (t) {
                                var e = t.groupIndex,
                                    i = t.index,
                                    n = this.groupCircleInfos[e][i];
                                n.circle.attr({ fill: n.color });
                            }),
                            (t.prototype.selectLegend = function (t) {
                                var e = (0, l.default)(t);
                                (this.selectedLegend = t),
                                    d.default.forEach2dArray(this.groupCircleInfos, function (i, n, o) {
                                        i && i.circle.attr({ opacity: e || t === o ? 0.8 : f });
                                    });
                            }),
                            t
                    );
                })();
            e.default = g;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(146),
                r = n(o),
                a = i(143),
                s = n(a),
                u = i(164),
                l = n(u),
                h = (function () {
                    function t() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t);
                    }
                    return (
                        (t.prototype.render = function (t, e) {
                            var i = t.set();
                            return (
                                (this.paper = t),
                                    (this.chartType = e.chartType),
                                    (this.theme = e.theme || {}),
                                    (this.colorSpectrum = e.colorSpectrum),
                                    (this.chartBackground = e.chartBackground),
                                    (this.zoomable = e.zoomable),
                                    (this.useColorValue = e.options.useColorValue),
                                    (this.borderColor = this.theme.borderColor || "none"),
                                    (this.borderWidth = this.theme.borderWidth),
                                    (this.groupBounds = e.groupBounds),
                                    (this.boundMap = e.boundMap),
                                    this._bindGetBoundFunction(),
                                    this._bindGetColorFunction(),
                                    (this.seriesDataModel = e.seriesDataModel),
                                    (this.boxesSet = this._renderBoxes(e.seriesDataModel, e.startDepth, !!e.isPivot, i)),
                                    (this.rectOverlay = this._renderRectOverlay()),
                                    i
                            );
                        }),
                            (t.prototype._renderRectOverlay = function () {
                                var t = s.default.renderRect(this.paper, { width: 1, height: 1, left: 0, top: 0 }, Object.assign({ "stroke-width": 0 }, { "fill-opacity": 0 }));
                                return t.node.setAttribute("filter", "url(#shadow)"), t;
                            }),
                            (t.prototype._bindGetBoundFunction = function () {
                                this.boundMap ? (this._getBound = this._getBoundFromBoundMap) : (this._getBound = this._getBoundFromGroupBounds);
                            }),
                            (t.prototype._bindGetColorFunction = function () {
                                this.colorSpectrum ? (this._getColor = this._getColorFromSpectrum) : (this._getColor = this._getColorFromColors);
                            }),
                            (t.prototype._getBoundFromGroupBounds = function (t) {
                                return this.groupBounds[t.groupIndex][t.index].end;
                            }),
                            (t.prototype._getBoundFromBoundMap = function (t) {
                                return this.boundMap[t.id];
                            }),
                            (t.prototype._getColorFromSpectrum = function (t, e) {
                                return t.hasChild && t.depth === e ? "none" : this.colorSpectrum.getColor(t.colorRatio || t.ratio) || this.chartBackground;
                            }),
                            (t.prototype._getColorFromColors = function (t, e) {
                                return t.depth === e ? this.theme.colors[t.group] : "#000";
                            }),
                            (t.prototype._renderRect = function (t, e, i, n) {
                                return s.default.renderRect(this.paper, t, { fill: e, stroke: this.borderColor, "stroke-width": i, "fill-opacity": n });
                            }),
                            (t.prototype._getStrokeWidth = function (t) {
                                return this.borderWidth ? this.borderWidth : t ? 4 : 0;
                            }),
                            (t.prototype._renderBoxes = function (t, e, i, n) {
                                var o = this,
                                    r = l.default.isTreemapChart(this.chartType);
                                return t.map(function (t, i) {
                                    if (r && !o.colorSpectrum && t.getSeriesItemCount()) {
                                        var a = t.getSeriesItem(0);
                                        o._setTreeFillOpacity({ id: a.parent }, e);
                                    }
                                    return t.map(function (t, r) {
                                        var a = null,
                                            s = t.depth,
                                            u = o.colorSpectrum ? o.borderWidth : o._getStrokeWidth(s === e),
                                            l = o.colorSpectrum ? 1 : t.fillOpacity;
                                        (t.groupIndex = i), (t.index = r);
                                        var h = o._getBound(t);
                                        if (h) {
                                            var c = o._getColor(t, e);
                                            (a = { rect: o._renderRect(h, c, u, l), seriesItem: t, color: c }), n && n.push(a.rect);
                                        }
                                        return a;
                                    });
                                }, i);
                            }),
                            (t.prototype._setTreeFillOpacity = function (t, e) {
                                var i = this;
                                this.seriesDataModel.findSeriesItemsByParent(t.id).forEach(function (n, o) {
                                    var r = n.depth;
                                    r === e ? (n.fillOpacity = 1) : r === e + 1 ? (n.fillOpacity = 0.05 * o) : r < e ? (n.fillOpacity = 0) : (n.fillOpacity = t.fillOpacity + 0.05 * o),
                                    n.hasChild && i._setTreeFillOpacity({ id: n.id, fillOpacity: n.fillOpacity }, e);
                                });
                            }),
                            (t.prototype._animateChangingColor = function (t, e, i, n, o) {
                                var a = { "fill-opacity": (0, r.default)(i) ? i : 1, stroke: n, "stroke-width": o };
                                e && (a.fill = e), t.animate(a, 100, ">");
                            }),
                            (t.prototype.showAnimation = function (t) {
                                var e = this.boxesSet[t.groupIndex][t.index];
                                if (e) {
                                    var i = e.rect.node,
                                        n = e.color;
                                    "treemap" !== this.chartType || this.zoomable || this.useColorValue || (n = this.theme.colors[t.index]),
                                        this.rectOverlay.attr({
                                            x: i.getAttribute("x"),
                                            y: i.getAttribute("y"),
                                            width: i.getAttribute("width"),
                                            height: i.getAttribute("height"),
                                            fill: n,
                                            "fill-opacity": 1,
                                            stroke: "#ffffff",
                                            "stroke-width": 4,
                                            "stroke-opacity": 1,
                                        }),
                                        this.rectOverlay.toFront(),
                                    this.labelSet && this.labelSet.toFront();
                                }
                            }),
                            (t.prototype.hideAnimation = function (t) {
                                this.boxesSet[t.groupIndex][t.index] && this.rectOverlay.attr({ width: 1, height: 1, x: 0, y: 0, "fill-opacity": 0, "stroke-opacity": 0 });
                            }),
                            (t.prototype.resize = function (t) {
                                var e = this,
                                    i = t.dimension;
                                (this.boundMap = t.boundMap),
                                    (this.groupBounds = t.groupBounds),
                                    this.paper.setSize(i.width, i.height),
                                    s.default.forEach2dArray(this.boxesSet, function (t, i, n) {
                                        if (t) {
                                            var o = e._getBound(t.seriesItem, i, n);
                                            o && s.default.updateRectBound(t.rect, o);
                                        }
                                    });
                            }),
                            (t.prototype.renderSeriesLabel = function (t, e, i, n) {
                                var o = t.set(),
                                    r = { "font-size": n.fontSize, "font-family": n.fontFamily, "font-weight": n.fontWeight, fill: "#ffffff", opacity: 0 };
                                return (
                                    i.forEach(function (i, n) {
                                        i.forEach(function (i, a) {
                                            var u = s.default.renderText(t, e[n][a].end, i, r);
                                            (u.node.style.userSelect = "none"), (u.node.style.cursor = "default"), o.push(u);
                                        });
                                    }),
                                        (this.labelSet = o),
                                        o
                                );
                            }),
                            (t.prototype.renderSeriesLabelForTreemap = function (t, e, i, n) {
                                var o = t.set(),
                                    r = { "font-size": n.fontSize, "font-family": n.fontFamily, "font-weight": n.fontWeight, fill: n.color, opacity: 0 };
                                return (
                                    i.forEach(function (i, n) {
                                        if (e[n]) {
                                            var a = s.default.renderText(t, e[n], i, r);
                                            (a.node.style.userSelect = "none"), (a.node.style.cursor = "default"), o.push(a);
                                        }
                                    }),
                                        (this.labelSet = o),
                                        o
                                );
                            }),
                            t
                    );
                })();
            e.default = h;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t, e) {
                return Object.freeze(Object.defineProperties(t, { raw: { value: Object.freeze(e) } }));
            }
            e.__esModule = !0;
            var r = o(["\n                  s", ",", ",0,0\n                  t", ",", "\n                "], ["\n                  s", ",", ",0,0\n                  t", ",", "\n                "]),
                a = o(["\n                s", ",", ",0,0\n                t", ",", "\n            "], ["\n                s", ",", ",0,0\n                t", ",", "\n            "]),
                s = i(145),
                u = n(s),
                l = i(153),
                h = n(l),
                c = i(143),
                p = n(c),
                d = i(155),
                f = n(d),
                g = i(148),
                m = n(g),
                y = h.default.msie && h.default.version <= 8,
                v = "gray",
                $ = (function () {
                    function t() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t);
                    }
                    return (
                        (t.prototype.render = function (t, e) {
                            var i,
                                n,
                                o,
                                r = e.mapModel.getMapDimension();
                            (this.ratio = this._getDimensionRatio(e.layout.dimension, r)),
                                (this.dimension = e.layout.dimension),
                                (this.position = e.layout.position),
                                (this.paper = t),
                                (this.sectorSet = t.set()),
                                (this.sectors = this._renderMap(e, this.ratio)),
                            y ||
                            (this.g =
                                ((i = t),
                                    (n = this.sectorSet),
                                    ((o = document.createElementNS("http://www.w3.org/2000/svg", "g")).id = "tui-chart-series-group"),
                                    n.forEach(function (t) {
                                        f.default.append(o, t.node);
                                    }),
                                    i.canvas.appendChild(o),
                                    o)),
                                (this.overColor = e.theme.overColor);
                        }),
                            (t.prototype._getDimensionRatio = function (t, e) {
                                return Math.min(t.height / e.height, t.width / e.width);
                            }),
                            (t.prototype._renderMap = function (t, e) {
                                var i = this.sectorSet,
                                    n = this.paper,
                                    o = t.layout.position,
                                    a = t.colorSpectrum;
                                return t.mapModel.getMapData().map(function (t, s) {
                                    var u = t.ratio,
                                        l = t.path,
                                        h = u ? a.getColor(u) : "#eee",
                                        c = p.default.renderArea(n, l, { fill: h, opacity: 1, stroke: v, "stroke-width": 0.2, "stroke-opacity": 1, transform: m.default.oneLineTrim(r, e, e, o.left / e, o.top / e) });
                                    return c.data("index", s), i.push(c), { sector: c, color: h, ratio: u };
                                });
                            }),
                            (t.prototype.findSectorIndex = function (t) {
                                var e = this.paper.getElementByPoint(t.left, t.top),
                                    i = e && e.data("index"),
                                    n = !(0, u.default)(i) && this.sectors[i];
                                return n && !(0, u.default)(n.ratio) ? i : null;
                            }),
                            (t.prototype.changeColor = function (t) {
                                var e = this.sectors[t],
                                    i = { stroke: "#ffffff", "stroke-width": 4 };
                                this.overColor && (i.fill = this.overColor), e.sector.animate(i, 100, ">"), e.sector.node.setAttribute("filter", "url(#shadow)"), e.sector.toFront();
                            }),
                            (t.prototype.restoreColor = function (t) {
                                var e = this.sectors[t];
                                e.sector.animate({ fill: e.color, stroke: v, "stroke-width": 0.2 }, 100, ">"), e.sector.node.setAttribute("filter", "none");
                            }),
                            (t.prototype.scaleMapPaths = function (t, e, i, n, o) {
                                var r = this.g.transform.baseVal,
                                    a = this.paper.canvas.createSVGTransform(),
                                    s = this.paper.canvas.createSVGMatrix(),
                                    u = this.paper.raphael.matrix(),
                                    l = r.numberOfItems ? r.getItem(0).matrix : { a: 1, b: 0, c: 0, d: 1, e: 0, f: 0 },
                                    h = o.width - this.dimension.width,
                                    c = o.height - this.dimension.height,
                                    p = l.e / l.a,
                                    d = l.f / l.d,
                                    f = -h / l.a,
                                    g = -c / l.d;
                                u.scale(t, t, e.left * i - p * t, e.top * i - d * t);
                                var m = u.e / u.a + p,
                                    y = u.f / u.d + d;
                                m >= 0 ? (u.e = -p * u.a) : m < f && (u.e = f - p),
                                    y >= 0 ? (u.f = -d * u.a) : y < g && (u.f = g - d),
                                    (s.a = u.a),
                                    (s.b = u.b),
                                    (s.c = u.c),
                                    (s.d = u.d),
                                    (s.e = u.e),
                                    (s.f = u.f),
                                    a.setMatrix(s),
                                    r.appendItem(a),
                                    r.initialize(r.consolidate());
                            }),
                            (t.prototype.moveMapPaths = function (t, e) {
                                var i = this.paper.canvas.createSVGMatrix(),
                                    n = this.paper.raphael.matrix(),
                                    o = this.g.transform.baseVal,
                                    r = this.paper.canvas.createSVGTransform(),
                                    a = e.width - this.dimension.width,
                                    s = e.height - this.dimension.height,
                                    u = o.numberOfItems ? o.getItem(0).matrix : { a: 1, b: 0, c: 0, d: 1, e: 0, f: 0 };
                                n.translate(t.x, t.y),
                                    this._translateXForRaphaelMatrix({ raphaelMatrix: n, transformMatrix: u, maxRight: a }),
                                    this._translateYForRaphaelMatrix({ raphaelMatrix: n, transformMatrix: u, maxTop: s }),
                                    (i.a = n.a),
                                    (i.b = n.b),
                                    (i.c = n.c),
                                    (i.d = n.d),
                                    (i.e = n.e),
                                    (i.f = n.f),
                                    r.setMatrix(i),
                                    o.appendItem(r),
                                    o.initialize(o.consolidate());
                            }),
                            (t.prototype._translateXForRaphaelMatrix = function (t) {
                                var e = t.raphaelMatrix,
                                    i = t.transformMatrix,
                                    n = t.maxRight,
                                    o = e.e / e.a,
                                    r = o + i.e / i.a;
                                r >= 0 && o > 0 ? (e.e = 0) : r < 0 && r < -n / i.a && o < 0 && (e.e = 0);
                            }),
                            (t.prototype._translateYForRaphaelMatrix = function (t) {
                                var e = t.raphaelMatrix,
                                    i = t.transformMatrix,
                                    n = t.maxTop,
                                    o = e.f / e.d,
                                    r = o + i.f / i.d;
                                r >= 0 && o > 0 ? (e.f = 0) : r < 0 && r < -n / i.d && o < 0 && (e.f = 0);
                            }),
                            (t.prototype.renderSeriesLabels = function (t, e, i) {
                                var n = {
                                        "font-size": i.fontSize,
                                        "font-family": i.fontFamily,
                                        "font-weight": i.fontWeight,
                                        fill: i.color,
                                        "text-anchor": "middle",
                                        opacity: 0,
                                        transform: m.default.oneLineTrim(a, this.ratio, this.ratio, this.position.left / this.ratio, this.position.top / this.ratio),
                                    },
                                    o = t.set();
                                return (
                                    e.forEach(function (e) {
                                        var i = e.position,
                                            r = p.default.renderText(t, i, e.name || e.code, n);
                                        o.push(r), (r.node.style.userSelect = "none"), (r.node.style.cursor = "default"), r.node.setAttribute("filter", "url(#glow)"), y || self.g.appendChild(r.node);
                                    }),
                                        o
                                );
                            }),
                            t
                    );
                })();
            e.default = $;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o,
                r,
                a = Object.freeze(
                    Object.defineProperties((o = ["\n            M", "\n            ,", "\n            l", "\n            ,", "\n            l", "\n            ,-", "\n        "]), {
                        raw: { value: Object.freeze((r = ["\n            M", "\n            ,", "\n            l", "\n            ,", "\n            l", "\n            ,-", "\n        "])) },
                    })
                ),
                s = i(146),
                u = n(s),
                l = i(137),
                h = n(l),
                c = i(150),
                p = n(c),
                d = i(145),
                f = n(d),
                g = i(154),
                m = n(g),
                y = i(143),
                v = n(y),
                $ = i(158),
                x = n($),
                T = i(148),
                b = n(T),
                _ = i(173),
                A = n(_),
                D = (function () {
                    function t() {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this._checkBoxWidth = 0),
                            (this._checkBoxHeight = 0),
                            (this._legendItemHeight = 0),
                            (this._currentPageCount = 1),
                            (this._showCheckbox = !0);
                    }
                    return (
                        (t.prototype._renderLegendItems = function (t) {
                            var e = this,
                                i = m.default.LEGEND_LABEL_LEFT_PADDING,
                                n = Object.assign({}, this.basePosition);
                            t.forEach(function (t, o) {
                                var r = t.iconType,
                                    a = t.index,
                                    s = t.isUnselected,
                                    u = t.labelHeight,
                                    l = t.checkbox,
                                    h = t.colorByPoint ? "#aaa" : t.theme.color,
                                    c = n.left + e._calculateSingleLegendWidth(a, r) >= e.paper.width;
                                e.isHorizontal && c && ((n.top += e._legendItemHeight + m.default.LABEL_PADDING_TOP), (n.left = e.basePosition.left)),
                                e._showCheckbox && (e._renderCheckbox(n, { isChecked: l.checked, legendIndex: a, legendSet: e.legendSet }), (n.left += e._checkBoxWidth + i)),
                                    e._renderIcon(n, { legendColor: h, iconType: r, labelHeight: u, isUnselected: s, legendIndex: a, legendSet: e.legendSet }),
                                    (n.left += m.default.LEGEND_ICON_WIDTH + i),
                                    e._renderLabel(n, { labelText: t.label, labelHeight: u, isUnselected: s, legendIndex: a, legendSet: e.legendSet }),
                                    e.isHorizontal ? (n.left += e.labelWidths[o] + m.default.LEGEND_H_LABEL_RIGHT_PADDING) : ((n.left = e.basePosition.left), (n.top += e._legendItemHeight + m.default.LINE_MARGIN_TOP));
                            });
                        }),
                            (t.prototype._getLegendData = function (t, e) {
                                var i = this.basePosition.top,
                                    n = this.dimension.height,
                                    o = this.paper.height,
                                    r = t;
                                if (!this.isHorizontal && n + 2 * i > o) {
                                    this._legendItemHeight = Math.max(t[0].labelHeight, m.default.LEGEND_CHECKBOX_SIZE);
                                    var a = Math.floor((o - 2 * i) / (this._legendItemHeight + m.default.LINE_MARGIN_TOP));
                                    r = t.slice((e - 1) * a, e * a);
                                }
                                return r;
                            }),
                            (t.prototype.render = function (t) {
                                (this.eventBus = t.eventBus),
                                    (this.paper = t.paper),
                                    (this.dimension = t.dimension),
                                    (this.legendSet = this.paper.set()),
                                    (this.labelWidths = t.labelWidths),
                                    (this.labelTheme = t.labelTheme),
                                    (this.basePosition = t.position),
                                    (this.isHorizontal = t.isHorizontal),
                                    (this.originalLegendData = t.legendData);
                                var e = [];
                                if (this.originalLegendData.length) {
                                    (this._showCheckbox = (0, u.default)(t.legendData[0].checkbox)), this._setComponentDimensionsBaseOnLabelHeight(t.legendData[0].labelHeight);
                                    var i = this._getLegendData(t.legendData, this._currentPageCount);
                                    if ((this._renderLegendItems(i), !this.isHorizontal && i && i.length < t.legendData.length)) {
                                        var n = this.paper.height - 2 * this.basePosition.top;
                                        (this.availablePageCount = Math.ceil(t.dimension.height / n)), (e = this._renderPaginationArea(this.basePosition, { width: t.dimension.width, height: n }));
                                    }
                                }
                                return { legendSet: this.legendSet, paginationElem: e };
                            }),
                            (t.prototype._paginateLegendAreaTo = function (t) {
                                var e = this._currentPageCount;
                                this._removeLegendItems(), "next" === t ? (e += 1) : (e -= 1), this._renderLegendItems(this._getLegendData(this.originalLegendData, e));
                            }),
                            (t.prototype._removeLegendItems = function () {
                                this.legendSet.forEach(function (t) {
                                    (t.events || []).forEach(function (t) {
                                        t.unbind();
                                    }),
                                        t.remove();
                                });
                            }),
                            (t.prototype._renderPaginationArea = function (t, e) {
                                var i = this,
                                    n = m.default.LEGEND_PAGINATION_BUTTON_WIDTH,
                                    o = m.default.LEGEND_PAGINATION_BUTTON_PADDING_RIGHT,
                                    r = m.default.LEGEND_AREA_V_PADDING,
                                    a = m.default.LEGEND_AREA_H_PADDING,
                                    s = t.top + e.height - r,
                                    u = t.left - a + a,
                                    l = A.default.sum([u, o, n]),
                                    h = ["M", l + 5, ",", s + 3 + 4, "L", l + 5 + 5, ",", s + 8 + 4, "L", l + 10 + 5, ",", s + 3 + 4].join(""),
                                    c = ["M", u + 5, ",", s + 8 + 4, "L", u + 5 + 5, ",", s + 3 + 4, "L", u + 10 + 5, ",", s + 8 + 4].join(""),
                                    p = this._renderPaginationRect({ top: s, left: u }),
                                    d = { color: "#555", strokeWidth: 2 },
                                    f = v.default.renderLine(this.paper, c, d),
                                    g = this._renderPaginationRect({ top: s, left: l }),
                                    y = v.default.renderLine(this.paper, h, d),
                                    $ = this.paper.set(),
                                    x = this.paper.set();
                                return (
                                    (p.className = "tui-chart-icon"),
                                        $.push(p),
                                        $.push(f),
                                        (g.className = "tui-chart-icon"),
                                        x.push(g),
                                        x.push(y),
                                        $.click(function () {
                                            i._currentPageCount > 1 && (i._paginateLegendAreaTo("previous"), (i._currentPageCount -= 1));
                                        }),
                                        x.click(function () {
                                            i._currentPageCount < i.availablePageCount && (i._paginateLegendAreaTo("next"), (i._currentPageCount += 1));
                                        }),
                                        [$, x]
                                );
                            }),
                            (t.prototype._renderPaginationRect = function (t) {
                                var e = m.default.LEGEND_PAGINATION_BUTTON_WIDTH,
                                    i = { left: t.left, top: t.top, width: e, height: e };
                                return v.default.renderRect(this.paper, i, { fill: "#f4f4f4", rx: "1px", ry: "1px", stroke: "none" });
                            }),
                            (t.prototype.makeLabelWidths = function (t, e, i) {
                                return t.map(function (t) {
                                    var n = v.default.getRenderedTextSize(t.label, e.fontSize, e.fontFamily).width;
                                    return i && n > i && (n = i), n + m.default.LEGEND_LABEL_LEFT_PADDING;
                                });
                            }),
                            (t.prototype.getRenderedLabelHeight = function (t, e) {
                                return v.default.getRenderedTextSize(t, e.fontSize, e.fontFamily).height;
                            }),
                            (t.prototype._renderLabel = function (t, e) {
                                var i = this.eventBus,
                                    n = this.labelTheme,
                                    o = { left: t.left, top: t.top + this._legendItemHeight / 2 },
                                    r = { fill: n.color, "font-size": n.fontSize, "font-family": n.fontFamily, "font-weight": n.fontWeight, opacity: e.isUnselected ? 0.5 : 1, "text-anchor": "start" },
                                    a = v.default.renderText(this.paper, o, e.labelText, r);
                                a.data("index", e.legendIndex),
                                    (a.node.style.userSelect = "none"),
                                    (a.node.style.cursor = "pointer"),
                                    e.legendSet.push(a),
                                    a.click(function () {
                                        i.fire("labelClicked", e.legendIndex);
                                    });
                            }),
                            (t.prototype._renderCheckbox = function (t, e) {
                                var i = this,
                                    n = t.left,
                                    o = t.top + (this._legendItemHeight - this._checkBoxHeight) / 2,
                                    r = this._checkBoxWidth / 3,
                                    s = this._checkBoxWidth / 5.7,
                                    u = b.default.oneLineTrim(a, 0.25 * this._checkBoxWidth + n, 0.5 * this._checkBoxHeight + o, s, s, r, r),
                                    l = this.paper.set(),
                                    h = this.paper.rect(n, o, this._checkBoxWidth, this._checkBoxHeight, 0).attr({ fill: "#fff", stroke: "#aaa", "stroke-width": 1 });
                                if ((h.node.setAttribute("class", "auto-shape-rendering"), l.push(h), e.isChecked)) {
                                    var c = this.paper.path(u).attr({ stroke: "#555", "stroke-width": 2 });
                                    c.node.setAttribute("class", "auto-shape-rendering"), l.push(c);
                                }
                                l.data("index", e.legendIndex),
                                    l.click(function () {
                                        i.eventBus.fire("checkboxClicked", e.legendIndex);
                                    }),
                                    l.forEach(function (t) {
                                        e.legendSet.push(t);
                                    });
                            }),
                            (t.prototype._renderIcon = function (t, e) {
                                var i = this,
                                    n = void 0;
                                this.paper.setStart(),
                                    ("line" === e.iconType || "radial" === e.iconType) && this.paper.canvas.transform
                                        ? ((n = this.paper.path(m.default.LEGEND_LINE_ICON_PATH)).attr({ stroke: e.legendColor, "stroke-width": 2, "stroke-opacity": e.isUnselected ? 0.5 : 1 }), n.translate(t.left, t.top))
                                        : (n = v.default.renderRect(
                                            this.paper,
                                            { left: t.left, top: t.top + (m.default.LEGEND_CHECKBOX_SIZE - m.default.LEGEND_ICON_HEIGHT) / 2, width: m.default.LEGEND_ICON_WIDTH, height: m.default.LEGEND_ICON_HEIGHT },
                                            { "stroke-width": 0, fill: e.legendColor, opacity: e.isUnselected ? 0.5 : 1 }
                                        )),
                                    n.data("icon", e.iconType),
                                    n.data("index", e.legendIndex),
                                    n.click(function () {
                                        i.eventBus.fire("labelClicked", e.legendIndex);
                                    }),
                                    e.legendSet.push(n);
                            }),
                            (t.prototype.selectLegend = function (t, e) {
                                e.forEach(function (e) {
                                    var i = e.data("index"),
                                        n = "line" === e.data("icon") ? "stroke-opacity" : "opacity";
                                    (0, h.default)(i) || (0, f.default)(i) ? e.attr(n, 1) : (0, f.default)(i) || ((0, p.default)(t) && i !== t ? e.attr(n, 0.5) : e.attr(n, 1));
                                });
                            }),
                            (t.prototype._getCheckboxWidth = function () {
                                return this._showCheckbox ? this._checkBoxWidth + m.default.LEGEND_LABEL_LEFT_PADDING : 0;
                            }),
                            (t.prototype._getLabelWidth = function (t) {
                                return t ? this.labelWidths[t] || 0 : x.default.max(this.labelWidths);
                            }),
                            (t.prototype._calculateLegendWidth = function () {
                                return this._calculateSingleLegendWidth();
                            }),
                            (t.prototype._calculateSingleLegendWidth = function (t) {
                                return m.default.LEGEND_AREA_H_PADDING + this._getCheckboxWidth() + (m.default.LEGEND_ICON_WIDTH + m.default.LEGEND_LABEL_LEFT_PADDING) + this._getLabelWidth(t) + m.default.LEGEND_AREA_H_PADDING;
                            }),
                            (t.prototype._setComponentDimensionsBaseOnLabelHeight = function (t) {
                                (this._legendItemHeight = Math.max(t, m.default.LEGEND_CHECKBOX_SIZE)), (this._checkBoxWidth = this._checkBoxHeight = m.default.LEGEND_CHECKBOX_SIZE);
                            }),
                            t
                    );
                })();
            e.default = D;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(144),
                r = n(o),
                a = i(158),
                s = n(a),
                u = {
                    calculateLimit: function (t, e) {
                        var i = {},
                            n = 0;
                        t < 0 && ((n = t), (e -= t), (t = 0));
                        var o = (e - t) / 20;
                        return (i.max = e + o + n), e / 6 > t ? (i.min = n) : (i.min = t - o + n), i;
                    },
                    makeTickPixelPositions: function (t, e, i, n) {
                        var o = [];
                        return (
                            (i = i || 0),
                            e > 0 &&
                            ((o = (0, r.default)(0, e).map(function (n) {
                                return (0 === n ? 0 : n / (e - 1)) * t + i;
                            })),
                                (o[o.length - 1] -= 1)),
                            n && o.push(n),
                                o
                        );
                    },
                    makeLabelsFromLimit: function (t, e) {
                        var i = u.findMultipleNum(e),
                            n = Math.round(t.min * i),
                            o = Math.round(t.max * i);
                        return (0, r.default)(n, o + 1, e * i).map(function (t) {
                            return t / i;
                        });
                    },
                    calculateStepFromLimit: function (t, e) {
                        return u.divide(u.subtract(t.max, t.min), e - 1);
                    },
                    sumPlusValues: function (t) {
                        var e = t.filter(function (t) {
                            return t > 0;
                        });
                        return u.sum(e);
                    },
                    sumMinusValues: function (t) {
                        var e = t.filter(function (t) {
                            return t < 0;
                        });
                        return u.sum(e);
                    },
                    makePercentageValue: function (t, e) {
                        return (t / e) * 100;
                    },
                    calculateRatio: function (t, e, i, n) {
                        return e ? ((t - i) / e) * n : 0;
                    },
                },
                l = function (t) {
                    var e = String(t).split(".");
                    return 2 === e.length ? e[1].length : 0;
                },
                h = function () {
                    for (var t = arguments.length, e = Array(t), i = 0; i < t; i++) e[i] = arguments[i];
                    var n = e.map(function (t) {
                        return u.getDecimalLength(t);
                    });
                    return Math.pow(10, s.default.max(n));
                },
                c = function (t, e) {
                    var i = u.findMultipleNum(e);
                    return 1 === i ? t % e : ((t * i) % (e * i)) / i;
                },
                p = function (t, e) {
                    var i = u.findMultipleNum(t, e);
                    return (t * i + e * i) / i;
                },
                d = function (t, e) {
                    var i = u.findMultipleNum(t, e);
                    return (t * i - e * i) / i;
                },
                f = function (t, e) {
                    var i = u.findMultipleNum(t, e);
                    return (t * i * (e * i)) / (i * i);
                },
                g = function (t, e) {
                    var i = u.findMultipleNum(t, e);
                    return (t * i) / (e * i);
                },
                m = function (t) {
                    var e = t.slice();
                    return (
                        e.unshift(0),
                            e.reduce(function (t, e) {
                                return u.add(parseFloat(t), parseFloat(e));
                            })
                    );
                },
                y = function (t) {
                    for (var e, i = [], n = 2; n * n <= t; n += 1) t % n == 0 && ((e = t / n), i.push(n), e !== n && i.push(e));
                    return (
                        i.sort(function (t, e) {
                            return t - e;
                        }),
                            i
                    );
                };
            (u.getDecimalLength = l), (u.findMultipleNum = h), (u.mod = c), (u.add = p), (u.subtract = d), (u.multiply = f), (u.divide = g), (u.divisors = y), (u.sum = m), (e.default = u);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                r = i(143),
                a = n(r),
                s = i(154),
                u = n(s),
                l = i(148),
                h = n(l),
                c = i(164),
                p = n(c),
                d = i(173),
                f = n(d),
                g = (function () {
                    function t() {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.verticalRightBasePath = ["M", -4, 0, "L", -8, 2.5, "L", -8, 12.5, "L", -28, 12.5, "L", -28, -12.5, "L", -8, -12.5, "L", -8, -2.5]),
                            (this.verticalLeftBasePath = ["M", 10, 0, "L", 14, 2.5, "L", 14, 12.5, "L", 34, 12.5, "L", 34, -12.5, "L", 14, -12.5, "L", 14, -2.5]),
                            (this.horizontalTopBasePath = ["M", 0, 10, "L", 2.5, 14, "L", 12.5, 14, "L", 12.5, 34, "L", -12.5, 34, "L", -12.5, 14, "L", -2.5, 14]),
                            (this.horizontalBottomBasePath = ["M", 0, -4, "L", 2.5, -8, "L", 12.5, -8, "L", 12.5, -28, "L", -12.5, -28, "L", -12.5, -8, "L", -2.5, -8]);
                    }
                    return (
                        (t.prototype.render = function (t) {
                            var e = t.layout,
                                i = t.align,
                                n = t.legendSet,
                                r = t.theme,
                                a = t.labels,
                                s = p.default.isHorizontalLegend(i),
                                l = o(a, 1)[0],
                                c = a[a.length - 1],
                                d = h.default.getRenderedLabelsMaxWidth([l, c]),
                                f = h.default.getRenderedLabelHeight(l, r),
                                g = p.default.isBoxTypeChart(this.chartType),
                                m = e.position;
                            (this.layout = e),
                                (this.isHorizontal = s),
                                (this.isLeftLegend = p.default.isLegendAlignLeft(i)),
                                (this.isTopLegend = p.default.isLegendAlignTop(i)),
                                (this.theme = r),
                                (this.paper = t.paper),
                                (this.legendSet = t.legendSet),
                                (this.colorSpectrum = t.colorSpectrum),
                                p.default.isLegendAlignTop(i)
                                    ? (m.top += u.default.MAP_LEGEND_AREA_PADDING_WIDE + f + 2 * u.default.MAP_LEGEND_LABEL_PADDING)
                                    : p.default.isLegendAlignBottom(i)
                                        ? ((m.top += this._calculateHorizontalLegendTooltipHeight(a, r) + u.default.MAP_LEGEND_PADDING_BTW_GRAPH_AND_WEDGE),
                                            g ? (m.top -= u.default.MAP_LEGEND_TOOLTIP_VERTICAL_PADDING) : (m.top += u.default.MAP_LEGEND_AREA_PADDING_WIDE))
                                        : p.default.isLegendAlignLeft(i)
                                            ? (m.left += d + u.default.MAP_LEGEND_LABEL_PADDING)
                                            : (m.left = e.position.right - d - u.default.MAP_LEGEND_LABEL_PADDING - u.default.MAP_LEGEND_GRAPH_SIZE);
                            var y = this._renderGradientBar(this.paper, e, this.colorSpectrum, s);
                            n.push(y), (this.wedge = this._renderWedge(this.paper, m)), (this.wedgeText = this._renderWedgeText(this.paper, m, r)), n.push(this.wedge), (this.gradientBar = y);
                        }),
                            (t.prototype.renderTickLabels = function (t, e, i, n, r) {
                                var s = this,
                                    l = this.theme,
                                    c = { "font-size": l.fontSize, "font-family": l.fontFamily, "font-weight": l.fontWeight, fill: "#000" },
                                    d = o(i, 1)[0],
                                    f = i[i.length - 1],
                                    g = h.default.getRenderedLabelsMaxWidth([d, f]),
                                    m = h.default.getRenderedLabelHeight(d, l);
                                i.forEach(function (i, o) {
                                    var l = e.step * o,
                                        h = Object.assign({}, e.position);
                                    p.default.isHorizontalLegend(n)
                                        ? ((h.left += l), s.isTopLegend ? (h.top -= m / 2) : (h.top += m / 2))
                                        : ((h.top += l), s.isLeftLegend ? ((c["text-anchor"] = "end"), (h.left = u.default.CHART_PADDING + g)) : (c["text-anchor"] = "start")),
                                        r.push(a.default.renderText(t, h, i, c));
                                }, this);
                            }),
                            (t.prototype._renderGradientBar = function (t, e, i, n) {
                                var o = void 0,
                                    r = void 0,
                                    s = void 0;
                                n
                                    ? ((o = e.dimension.width), (r = u.default.MAP_LEGEND_GRAPH_SIZE), (s = 360), (this._makeWedghPath = this._makeHorizontalWedgePath))
                                    : ((o = u.default.MAP_LEGEND_GRAPH_SIZE), (r = e.dimension.height), (s = 270), (this._makeWedghPath = this._makeVerticalWedgePath));
                                var l = { left: e.position.left, top: e.position.top, width: o, height: r };
                                return a.default.renderRect(t, l, { fill: s + "-" + i.start + "-" + i.end, stroke: "none" });
                            }),
                            (t.prototype._renderWedgeText = function (t, e, i) {
                                return (
                                    this.isLeftLegend && (e.left += u.default.MAP_LEGEND_WEDGE_SIZE + u.default.MAP_LEGEND_TOOLTIP_HORIZONTAL_PADDING),
                                        a.default.renderText(t, e, "", { "font-size": i.fontSize, "font-family": i.fontFamily, "font-weight": i.fontWeight, fill: i.color })
                                );
                            }),
                            (t.prototype._renderWedge = function (t, e) {
                                return t.path(this.verticalBasePath).attr({ fill: "gray", stroke: "white", "stroke-opacity": 0.2, opacity: 0, transform: "t" + e.left + "," + e.top });
                            }),
                            (t.prototype._makeVerticalWedgePath = function (t, e) {
                                var i = this.isLeftLegend,
                                    n = (this.verticalBasePath = i ? this.verticalLeftBasePath : this.verticalRightBasePath),
                                    o = u.default.MAP_LEGEND_TOOLTIP_HORIZONTAL_PADDING,
                                    r = u.default.MAP_LEGEND_TOOLTIP_VERTICAL_PADDING,
                                    a = e.width,
                                    s = e.height;
                                return (n[2] = t), (n[5] = t + 2.5), (n[8] = n[11] = t + s / 2 + r), (n[14] = n[17] = t - s / 2 - r), (n[20] = t - 2.5), i ? (n[10] = n[13] = n[4] + a + 2 * o) : (n[10] = n[13] = n[4] - a - 2 * o), n;
                            }),
                            (t.prototype._makeHorizontalWedgePath = function (t, e) {
                                var i = (this.horiziontalBaseBath = this.isTopLegend ? this.horizontalTopBasePath : this.horizontalBottomBasePath),
                                    n = u.default.MAP_LEGEND_TOOLTIP_HORIZONTAL_PADDING,
                                    o = u.default.MAP_LEGEND_TOOLTIP_VERTICAL_PADDING,
                                    r = e.width,
                                    a = e.height;
                                return (
                                    (i[1] = t), (i[4] = t + 2.5), (i[7] = i[10] = t + r / 2 + n), (i[13] = i[16] = t - r / 2 - n), (i[19] = t - 2.5), this.isTopLegend ? (i[11] = i[14] = i[5] + a + 2 * o) : (i[11] = i[14] = i[5] - a - 2 * o), i
                                );
                            }),
                            (t.prototype.showWedge = function (t, e) {
                                var i = this.theme,
                                    n = a.default.getRenderedTextSize(e, i.fontSize, i.fontFamily),
                                    o = this.isHorizontal ? this.layout.dimension.width : this.layout.dimension.height,
                                    r = this._makeWedghPath(o * t, n);
                                this.wedge.attr({ path: r, opacity: 1, fill: this.colorSpectrum.getColor(t) });
                                var s = this.wedge.getBBox(),
                                    l = f.default.sum([s.y, u.default.MAP_LEGEND_TOOLTIP_VERTICAL_PADDING, n.height / 2]),
                                    h = f.default.sum([s.x, u.default.MAP_LEGEND_TOOLTIP_HORIZONTAL_PADDING, n.width / 2]);
                                this.isLeftLegend && (h += u.default.MAP_LEGEND_WEDGE_SIZE),
                                this.isTopLegend && (l += u.default.MAP_LEGEND_WEDGE_SIZE),
                                    this.wedgeText.attr({ x: h, y: l, text: e, opacity: 1 }),
                                    this.wedge.toFront(),
                                    this.wedgeText.toFront();
                            }),
                            (t.prototype.hideWedge = function () {
                                this.wedge.attr({ opacity: 0 }), this.wedgeText.attr({ opacity: 0 });
                            }),
                            (t.prototype.removeLocationURLFromFillAttribute = function () {
                                var t = this.gradientBar,
                                    e = t.node.getAttribute("fill"),
                                    i = /url\('?([^#]+)#[^#]+'?\)/.exec(e),
                                    n = o(i, 2);
                                (this.locationURL = n[1]), t.node.setAttribute("fill", e.replace(this.locationURL, ""));
                            }),
                            (t.prototype.restoreLocationURLToFillAttribute = function () {
                                var t = this.gradientBar,
                                    e = t.node.getAttribute("fill");
                                t.node.setAttribute("fill", e.replace("#", this.locationURL + "#"));
                            }),
                            (t.prototype._calculateHorizontalLegendTooltipHeight = function (t, e) {
                                var i = t.length ? t[t.length - 1] : "",
                                    n = h.default.getRenderedLabelHeight(i, e);
                                return f.default.sum([2 * u.default.MAP_LEGEND_TOOLTIP_VERTICAL_PADDING, n, u.default.MAP_LEGEND_WEDGE_SIZE]);
                            }),
                            (t.prototype._calculateVerticalLegendTooltipWidth = function (t, e) {
                                var i = t.length ? t[t.length - 1] : "",
                                    n = h.default.getRenderedLabelWidth(i, e);
                                return f.default.sum([2 * u.default.MAP_LEGEND_TOOLTIP_HORIZONTAL_PADDING, n, u.default.MAP_LEGEND_WEDGE_SIZE]);
                            }),
                            t
                    );
                })();
            e.default = g;
        },
        function (t, e, i) {
            "use strict";
            e.__esModule = !0;
            var n,
                o = (n = i(143)) && n.__esModule ? n : { default: n },
                r = (function () {
                    function t() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t);
                    }
                    return (
                        (t.prototype.render = function (t, e, i, n, r) {
                            var a = e.position.left + e.dimension.width / 2,
                                s = t.set();
                            return (
                                n.forEach(function (n, u) {
                                    var l = i * n,
                                        h = e.position.top + e.dimension.height - l,
                                        c = o.default.renderCircle(t, { left: a, top: h }, l, { fill: "none", opacity: 1, stroke: "#888", "stroke-width": 1 });
                                    s.push(c), s.push(o.default.renderText(t, { left: a, top: h - l - 5 }, r[u]));
                                }),
                                    s
                            );
                        }),
                            t
                    );
                })();
            e.default = r;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(143),
                r = n(o),
                a = i(154),
                s = n(a),
                u = (function () {
                    function t() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t);
                    }
                    return (
                        (t.prototype.render = function (t) {
                            var e = t.paper,
                                i = t.titleText,
                                n = t.offset,
                                o = t.theme,
                                a = t.align,
                                u = void 0 === a ? s.default.TITLE_ALIGN_LEFT : a,
                                l = t.chartTitleAreaWidth,
                                h = o.fontSize,
                                c = o.fontFamily,
                                p = r.default.getRenderedTextSize(i, h, c),
                                d = e.set(),
                                f = this.getTitlePosition(p, u, l, n);
                            return (
                                d.push(
                                    r.default.renderText(e, f, i, { "font-family": o.fontFamily, "font-size": o.fontSize, "font-weight": o.fontWeight, fill: o.color, "text-anchor": { left: "start", right: "end", center: "middle" }[u] })
                                ),
                                    d
                            );
                        }),
                            (t.prototype.getTitlePosition = function (t, e, i, n) {
                                var o = void 0,
                                    r = { left: (o = e === s.default.TITLE_ALIGN_CENTER ? i / 2 : e === s.default.TITLE_ALIGN_RIGHT ? i : s.default.CHART_PADDING), top: s.default.CHART_PADDING + t.height / 2 };
                                return n && (n.x ? (r.left += n.x) : n.y && (r.top += n.y)), r;
                            }),
                            (t.prototype.resize = function (t, e) {
                                e.attr({ left: s.default.CHART_PADDING });
                            }),
                            t
                    );
                })();
            e.default = u;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(143),
                r = n(o),
                a = i(154),
                s = n(a),
                u = s.default.Y_AXIS_TITLE_PADDING,
                l = s.default.AXIS_BACKGROUND_RIGHT_PADDING,
                h = (function () {
                    function t() {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.ticks = []);
                    }
                    return (
                        (t.prototype.renderBackground = function (t, e, i, n) {
                            var o = (n && n.background) || {},
                                a = o.color,
                                s = o.opacity;
                            return r.default.renderRect(t, { left: 0, top: e.top, width: i.width + e.left - l, height: i.height }, { fill: void 0 === a ? "#fff" : a, opacity: void 0 === s ? 1 : s, "stroke-width": 0 });
                        }),
                            (t.prototype.renderTitle = function (t, e) {
                                var i = e.theme,
                                    n = e.rotationInfo,
                                    o = i.fontFamily,
                                    a = i.fontSize,
                                    s = i.fontWeight,
                                    u = i.color,
                                    l = this.getRenderTitleAnchor(n),
                                    h = this.calculatePosition(t, e),
                                    c = r.default.renderText(t, h, e.text, { "dominant-baseline": "auto", "font-family": o, "font-size": a, "font-weight": s, fill: u, transform: "none", "text-anchor": l });
                                (c.node.style.userSelect = "none"), (c.node.style.cursor = "default"), e.set.push(c);
                            }),
                            (t.prototype.getRenderTitleAnchor = function (t) {
                                var e = "middle";
                                return !t.isPositionRight && (t.isVertical || t.isXAxisTitleLeft) ? ((t.isVertical && !t.isCenter) || (!t.isVertical && t.isXAxisTitleLeft)) && (e = "start") : (e = "end"), e;
                            }),
                            (t.prototype.renderLabel = function (t) {
                                var e = t.positionTopAndLeft,
                                    i = t.labelText,
                                    n = t.paper,
                                    o = t.theme,
                                    a = t.isVertical,
                                    s = t.isCenter,
                                    u = { "dominant-baseline": "central", "font-family": o.fontFamily, "font-size": o.fontSize, "font-weight": o.fontWeight, fill: o.color };
                                t.isPositionRight ? (u["text-anchor"] = "end") : a && !s ? (u["text-anchor"] = "start") : (u["text-anchor"] = "middle");
                                var l = r.default.renderText(n, e, i, u);
                                (l.node.style.userSelect = "none"), (l.node.style.cursor = "default"), t.set.push(l), this.ticks.push(l);
                            }),
                            (t.prototype.renderRotatedLabel = function (t) {
                                var e = t.positionTopAndLeft,
                                    i = t.labelText,
                                    n = t.paper,
                                    o = t.theme,
                                    a = r.default.renderText(n, e, i, {
                                        "dominant-baseline": "central",
                                        "font-family": o.fontFamily,
                                        "font-size": o.fontSize,
                                        "font-weight": o.fontWeight,
                                        fill: o.color,
                                        "text-anchor": "end",
                                        transform: "r" + -t.degree + "," + e.left + "," + e.top,
                                    });
                                (a.node.style.userSelect = "none"), (a.node.style.cursor = "arrow"), t.set.push(a), this.ticks.push(a);
                            }),
                            (t.prototype.renderTicks = function (t) {
                                var e = this,
                                    i = t.paper,
                                    n = t.positions,
                                    o = t.additionalSize,
                                    r = t.isVertical,
                                    a = t.isCenter,
                                    s = t.isDivided,
                                    u = t.isPositionRight,
                                    l = t.tickColor,
                                    h = t.layout,
                                    c = h.position.left + h.dimension.width,
                                    p = h.position.top,
                                    d = h.position.left,
                                    f = s ? t.otherSideDimension.width : 0,
                                    g = void 0;
                                n.forEach(function (n) {
                                    var s,
                                        m = "M";
                                    (n += o),
                                    (s = n) > h.dimension[r ? "height" : "width"] + f ||
                                    (r
                                        ? a
                                            ? ((m += d + "," + (p + n)), (m += "H" + (d + 5)), (m += "M" + c + "," + (p + n)), (m += "H" + (c - 5)))
                                            : u
                                                ? ((m += d + "," + (p + n)), (m += "H" + (d + 5)))
                                                : ((m += c + "," + (p + n)), (m += "H" + (c - 5)))
                                        : ((m += d + n + "," + p), (m += "V" + (p + 5))),
                                    isNaN(n) || ((g = i.path(m).attr({ stroke: l, opacity: 0.5 })), t.set.push(g), e.ticks.push(g)));
                                });
                            }),
                            (t.prototype.renderStandardLine = function (t) {
                                var e = t.areaSize,
                                    i = t.layout,
                                    n = i.position,
                                    o = i.dimension,
                                    r = t.paper,
                                    a = t.isVertical,
                                    s = n.left,
                                    u = Math.abs(t.axisLimit.min),
                                    l = Math.abs(t.axisLimit.max),
                                    h = 1 - l / (u + l),
                                    c = "M",
                                    p = n.top,
                                    d = s + o.width;
                                if (a) {
                                    var f = p;
                                    (d += t.seriesDimension.width * h), (c += d + "," + f), (c += "V" + (p + e));
                                } else (c += s), (p -= t.seriesDimension.height * h), (c += "," + p + "H"), (c += s + e);
                                t.set.push(r.path(c).attr({ "stroke-width": 1, opacity: 0.5 }));
                            }),
                            (t.prototype.renderTickLine = function (t) {
                                var e = t.areaSize,
                                    i = t.paper,
                                    n = t.layout,
                                    o = n.position,
                                    r = o.top,
                                    a = o.left,
                                    s = n.dimension,
                                    u = t.isNegativeStandard,
                                    l = t.isNotDividedXAxis,
                                    h = t.additionalSize,
                                    c = t.isPositionRight,
                                    p = t.isCenter,
                                    d = t.isVertical,
                                    f = t.tickColor,
                                    g = t.seriesDimension,
                                    m = e,
                                    y = s.height + r,
                                    v = a + s.width,
                                    $ = "M",
                                    x = void 0,
                                    T = void 0;
                                c
                                    ? (($ += a + "," + r), ($ += "V" + y))
                                    : d
                                        ? ((x = r), u && (v += g.width / 2), ($ += v + "," + x), p ? (($ += "V" + y), ($ += "M" + a + "," + x), ($ += "V" + y)) : ($ += "V" + (T = r + m)))
                                        : ($ = this._makeNormalTickPath($, { isNotDividedXAxis: l, baseTop: r, baseLeft: a, additionalSize: h, isNegativeStandard: u, seriesDimension: g, lineSize: m })),
                                    t.set.push(i.path($).attr({ "stroke-width": 1, stroke: f, opacity: 0.5 }));
                            }),
                            (t.prototype._makeNormalTickPath = function (t, e) {
                                (t += e.isNotDividedXAxis ? e.baseLeft : e.baseLeft + e.additionalSize), e.isNegativeStandard && (e.baseTop -= e.seriesDimension.height / 2), (t += "," + e.baseTop + "H");
                                var i = e.baseLeft + e.lineSize;
                                return e.isNotDividedXAxis || (i += e.additionalSize), (t += i);
                            }),
                            (t.prototype.animateForAddingData = function (t) {
                                this.ticks.forEach(function (e) {
                                    e.animate({ transform: "t-" + t + ",0" }, 300);
                                });
                            }),
                            (t.prototype.calculatePosition = function (t, e) {
                                var i,
                                    n,
                                    o,
                                    a,
                                    s,
                                    l,
                                    h = e.rotationInfo,
                                    c = e.text,
                                    p = e.theme,
                                    d = e.additionalWidth,
                                    f = e.otherSideDimension,
                                    g = e.areaSize,
                                    m = e.layout,
                                    y = ((i = c), (n = p), r.default.getRenderedTextSize(i, n.fontSize, n.fontFamily).height),
                                    v = ((o = c), (a = p), r.default.getRenderedTextSize(o, a.fontSize, a.fontFamily).width),
                                    $ = m.dimension.height,
                                    x = m.dimension.width,
                                    T = m.position,
                                    b = T.top,
                                    _ = T.left,
                                    A = v - f.width,
                                    D = { top: b + $ - y / 2, left: _ + d + (A < 0 ? 0 : A) };
                                return (
                                    h.isVertical
                                        ? h.isCenter
                                            ? ((D.top += y / 2), (D.left = _ + x / 2))
                                            : h.isDiverging || (D.top = b - y / 2 - u)
                                        : h.isVertical ||
                                        (h.isDiverging && h.isYAxisCenter ? (D.left = _ + g / 2) : h.isDiverging && !h.isYAxisCenter ? (D.left = _ + x / 2) : h.isXAxisTitleLeft ? (D.left = m.position.left) : (D.left = m.position.left + x)),
                                    h.isPositionRight && (D.left += x),
                                    h.isCenter || ((s = D), (l = e.offset) && (l.x && (s.left += l.x), l.y && (s.top += l.y))),
                                        D
                                );
                            }),
                            t
                    );
                })();
            e.default = h;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                r = i(143),
                a = n(r),
                s = i(158),
                u = n(s),
                l = (function () {
                    function t() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t);
                    }
                    return (
                        (t.prototype.render = function (t) {
                            var e = t.paper.set();
                            return (
                                (this.paper = t.paper),
                                    (this.layout = t.layout),
                                    (this.plotPositions = t.plotPositions),
                                    (this.theme = t.theme),
                                    (this.options = t.options),
                                    (this.labelData = t.labelData),
                                    this._renderPlot(e),
                                    this._renderLabels(e),
                                    e.toBack(),
                                    this.paper.pushDownBackgroundToBottom(),
                                    e
                            );
                        }),
                            (t.prototype._renderPlot = function (t) {
                                "circle" === this.options.type ? this._renderCirclePlot(t) : this._renderSpiderwebPlot(t), this._renderCategoryDots(t);
                            }),
                            (t.prototype._renderSpiderwebPlot = function (t) {
                                var e = this._getLinesPath(this.plotPositions);
                                this._renderLines(e, this.theme.lineColor, t);
                            }),
                            (t.prototype._renderCirclePlot = function (t) {
                                for (var e = this.plotPositions, i = o(e, 1), n = o(i[0], 1), r = n[0], s = this.theme.lineColor, u = 1; u < e.length; u += 1) {
                                    var l = o(e[u], 1)[0],
                                        h = r.top - l.top;
                                    t.push(a.default.renderCircle(this.paper, r, h, { stroke: s, "stroke-opacity": 0.05 }));
                                }
                            }),
                            (t.prototype._renderCategoryDots = function (t) {
                                var e = this;
                                this._makePlotDotBounds(u.default.pivot(this.plotPositions)).forEach(function (i) {
                                    var n = a.default.renderRect(e.paper, i, { fill: "#000000", "fill-opacity": 0.5, "stroke-width": 0 });
                                    t.push(n);
                                }, this);
                            }),
                            (t.prototype._makePlotDotBounds = function (t) {
                                var e = t.map(function (t) {
                                    var e = t[t.length - 1];
                                    return { top: e.top - 2, left: e.left - 2, width: 4, height: 4 };
                                });
                                return e.pop(), e;
                            }),
                            (t.prototype._renderLabels = function (t) {
                                var e = this.paper,
                                    i = this.theme,
                                    n = this.labelData,
                                    o = { fill: i.lineColor, "font-size": i.label.fontSize, "font-family": i.label.fontFamily, "text-anchor": "end", "font-weight": "100", "dominant-baseline": "middle" };
                                n.category.forEach(function (i) {
                                    var n = Object.assign({}, o, { "text-anchor": i.position.anchor, fill: "#333333" }),
                                        r = a.default.renderText(e, i.position, i.text, n);
                                    (r.node.style.userSelect = "none"), (r.node.style.cursor = "default"), t.push(r);
                                }),
                                    n.step.forEach(function (i) {
                                        var n = a.default.renderText(e, i.position, i.text, o);
                                        (i.position.top -= 8), (i.position.left -= 3), (n.node.style.userSelect = "none"), (n.node.style.cursor = "default"), t.push(n);
                                    });
                            }),
                            (t.prototype._renderLines = function (t, e, i) {
                                var n = this.paper;
                                return t.map(function (t) {
                                    var o = a.default.renderLine(n, t.join(" "), { color: e, strokeWidth: 1 });
                                    return o.node.setAttribute("stroke-opacity", 0.05), i.push(o), o;
                                });
                            }),
                            (t.prototype._getLinesPath = function (t) {
                                var e = this;
                                return t.map(function (t) {
                                    return e._makeLinesPath(t);
                                });
                            }),
                            (t.prototype._makeLinesPath = function (t, e, i) {
                                var n = [],
                                    o = !1;
                                return (
                                    (e = e || "top"),
                                        t.forEach(function (t) {
                                            var r = o && !i ? "M" : "L";
                                            t ? (n.push([r, t.left, t[e]]), o && (o = !1)) : (o = !0);
                                        }),
                                        ((n = Array.prototype.concat.apply([], n))[0] = "M"),
                                        n
                                );
                            }),
                            t
                    );
                })();
            e.default = l;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                var e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
                    i = arguments[2],
                    n = arguments[3];
                if ((e.table && (e = x.default.makeDataWithTable(e.table)), e.series || (e.series = []), (e = v.default.deepCopy(e)), "combo" !== n)) {
                    var o = e.series;
                    (e.series = {}), (e.series[n] = o);
                }
                ((i = i ? v.default.deepCopy(i) : {}).chartType = n),
                    (i.theme = i.theme || u.default.DEFAULT_THEME_NAME),
                i.series && i.series.stackType && ((i.series.stack = { type: i.series.stackType }), delete i.series.stackType),
                i.series && (0, a.default)(i.series.stack) && (i.series.stack = { type: i.series.stack });
                var r = i.series && i.series.colorByPoint,
                    s = f.default.get(i.theme, n, e.series, r),
                    l = h.default.get(i.chartType, e, s, i);
                return l.render(t), l.animateChart(), l;
            }
            var r = i(156),
                a = n(r),
                s = i(154),
                u = n(s),
                l = i(180),
                h = n(l),
                c = i(182),
                p = n(c),
                d = i(183),
                f = n(d),
                g = i(185),
                m = n(g),
                y = i(186),
                v = n(y),
                $ = i(188),
                x = n($),
                T = i(191),
                b = n(T);
            i(192),
                i(193),
                i(306),
                (t.exports = {
                    barChart: function t(e, i, n) {
                        return o(e, i, n, u.default.CHART_TYPE_BAR);
                    },
                    columnChart: function t(e, i, n) {
                        return o(e, i, n, u.default.CHART_TYPE_COLUMN);
                    },
                    lineChart: function t(e, i, n) {
                        return o(e, i, n, u.default.CHART_TYPE_LINE);
                    },
                    areaChart: function t(e, i, n) {
                        return o(e, i, n, u.default.CHART_TYPE_AREA);
                    },
                    bubbleChart: function t(e, i, n) {
                        return o(e, i, n, u.default.CHART_TYPE_BUBBLE);
                    },
                    scatterChart: function t(e, i, n) {
                        return o(e, i, n, u.default.CHART_TYPE_SCATTER);
                    },
                    heatmapChart: function t(e, i, n) {
                        return o(e, i, n, u.default.CHART_TYPE_HEATMAP);
                    },
                    treemapChart: function t(e, i, n) {
                        return o(e, i, n, u.default.CHART_TYPE_TREEMAP);
                    },
                    comboChart: function t(e, i, n) {
                        return o(e, i, n, u.default.CHART_TYPE_COMBO);
                    },
                    pieChart: function t(e, i, n) {
                        return o(e, i, n, u.default.CHART_TYPE_PIE);
                    },
                    mapChart: function t(e, i, n) {
                        return o(e, i, n, u.default.CHART_TYPE_MAP);
                    },
                    radialChart: function t(e, i, n) {
                        return o(e, i, n, u.default.CHART_TYPE_RADIAL);
                    },
                    boxplotChart: function t(e, i, n) {
                        return o(e, i, n, u.default.CHART_TYPE_BOXPLOT);
                    },
                    bulletChart: function t(e, i, n) {
                        return o(e, i, n, u.default.CHART_TYPE_BULLET);
                    },
                    registerTheme: function t(e, i) {
                        f.default.register(e, i);
                    },
                    registerMap: function t(e, i) {
                        m.default.register(e, i);
                    },
                    registerPlugin: function t(e, i, n) {
                        p.default.register(e, i), b.default.addRendererType(e, n);
                    },
                });
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(154),
                r = n(o),
                a = i(181),
                s = n(a),
                u = i(164),
                l = n(u),
                h = {};
            e.default = {
                _findKey: function (t, e) {
                    var i = null;
                    if (l.default.isComboChart(t)) {
                        var n = s.default.getChartTypeMap(e);
                        n[r.default.CHART_TYPE_COLUMN] && n[r.default.CHART_TYPE_LINE]
                            ? (i = r.default.CHART_TYPE_COLUMN_LINE_COMBO)
                            : n[r.default.CHART_TYPE_LINE] && n[r.default.CHART_TYPE_SCATTER]
                                ? (i = r.default.CHART_TYPE_LINE_SCATTER_COMBO)
                                : n[r.default.CHART_TYPE_AREA] && n[r.default.CHART_TYPE_LINE]
                                    ? (i = r.default.CHART_TYPE_LINE_AREA_COMBO)
                                    : n[r.default.CHART_TYPE_PIE] && (i = r.default.CHART_TYPE_PIE_DONUT_COMBO);
                    } else i = t;
                    return i;
                },
                get: function (t, e, i, n) {
                    var o = h[this._findKey(t, e)];
                    if (!o) throw Error("Not exist " + t + " chart.");
                    return new o(e, i, n);
                },
                register: function (t, e) {
                    h[t] = e;
                },
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                r = i(138),
                a = n(r),
                s = i(139),
                u = n(s),
                l = i(154),
                h = n(l),
                c = i(164),
                p = n(c),
                d = i(158),
                f = n(d);
            e.default = {
                pickStacks: function (t, e) {
                    var i = t.map(function (t) {
                            return t.stack;
                        }),
                        n = f.default.unique(i);
                    e && (n = n.slice(0, 2));
                    var o = n.filter(function (t) {
                        return !!t;
                    });
                    return o.length < n.length && o.push(h.default.DEFAULT_STACK), o;
                },
                _sortSeriesData: function (t, e) {
                    var i = [];
                    return (
                        e || (e = this.pickStacks(t)),
                            e.forEach(function (e) {
                                var n = t.filter(function (t) {
                                    return (t.stack || h.default.DEFAULT_STACK) === e;
                                });
                                i = i.concat(n);
                            }),
                            i
                    );
                },
                removeSeriesStack: function (t) {
                    Object.values(t).forEach(function (t) {
                        delete t.stack;
                    });
                },
                findChartType: function (t, e) {
                    var i = void 0;
                    return t && (i = t[e]), i || e;
                },
                getChartTypeMap: function (t) {
                    var e = this,
                        i = {};
                    return (
                        (0, a.default)(t.series) &&
                        (0, u.default)(t.series, function (n, o) {
                            i[e.findChartType(t.seriesAlias, o)] = !0;
                        }),
                            i
                    );
                },
                _createMinusValues: function (t) {
                    return t.map(function (t) {
                        return t < 0 ? 0 : -t;
                    });
                },
                _createPlusValues: function (t) {
                    return t.map(function (t) {
                        return t < 0 ? 0 : t;
                    });
                },
                _makeNormalDivergingRawSeriesData: function (t) {
                    return (t.length = Math.min(t.length, 2)), (t[0].data = this._createMinusValues(t[0].data)), t[1] && (t[1].data = this._createPlusValues(t[1].data)), t;
                },
                _makeRawSeriesDataForStackedDiverging: function (t) {
                    var e = this,
                        i = this.pickStacks(t, !0),
                        n = [],
                        r = o(i, 2),
                        a = r[0],
                        s = r[1];
                    return (
                        (t = this._sortSeriesData(t, i)).forEach(function (t) {
                            var i = t.stack || h.default.DEFAULT_STACK;
                            i === a ? ((t.data = e._createMinusValues(t.data)), n.push(t)) : i === s && ((t.data = e._createPlusValues(t.data)), n.push(t));
                        }),
                            n
                    );
                },
                _makeRawSeriesDataForDiverging: function (t, e) {
                    return (t = p.default.isValidStackOption(e) ? this._makeRawSeriesDataForStackedDiverging(t) : this._makeNormalDivergingRawSeriesData(t));
                },
                updateRawSeriesDataByOptions: function (t) {
                    var e = this,
                        i = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
                    p.default.isValidStackOption(i.stack) &&
                    Object.keys(t.series).forEach(function (i) {
                        t.series[i] = e._sortSeriesData(t.series[i]);
                    }),
                    i.diverging &&
                    Object.entries(t.series).forEach(function (n) {
                        var r = o(n, 2),
                            a = r[0],
                            s = r[1];
                        t.series[a] = e._makeRawSeriesDataForDiverging(s, i.stack);
                    });
                },
                appendOutliersToSeriesData: function (t) {
                    t.series.boxplot.forEach(function (t) {
                        var e = t.outliers;
                        e &&
                        e.length &&
                        e.forEach(function (e) {
                            t.data[e[0]].push(e[1]);
                        });
                    });
                },
                filterCheckedRawData: function (t, e) {
                    var i = JSON.parse(JSON.stringify(t));
                    if (
                        (e &&
                        Object.entries(i.series).forEach(function (t) {
                            var n = o(t, 2),
                                r = n[0],
                                a = n[1];
                            e[r]
                                ? e[r].length &&
                                (i.series[r] = a.filter(function (t, i) {
                                    return e[r][i];
                                }))
                                : (i.series[r] = []);
                        }),
                            i.series.bullet)
                    ) {
                        var n = [];
                        e.bullet.forEach(function (e, i) {
                            e && n.push(t.categories[i]);
                        }),
                            (i.categories = n);
                    }
                    return i;
                },
                _makeRawSeriesDataForBulletChart: function (t) {
                    var e = t.series.bullet;
                    (t.categories = t.categories || []),
                        (t.categories = (void 0 === e ? [] : e).map(function (t) {
                            return t.name || "";
                        }));
                },
            };
        },
        function (t, e, i) {
            "use strict";
            e.__esModule = !0;
            var n,
                o = (n = i(154)) && n.__esModule ? n : { default: n },
                r = {};
            e.default = {
                get: function (t, e) {
                    var i = r[t || o.default.DEFAULT_PLUGIN];
                    if (!i) throw Error("Not exist " + t + " plugin.");
                    var n = i[e];
                    if (!n) throw Error("Not exist " + e + " chart renderer.");
                    return new n();
                },
                register: function (t, e) {
                    r[t] = e;
                },
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                r = i(140),
                a = n(r),
                s = i(146),
                u = n(s),
                l = i(138),
                h = n(l),
                c = i(139),
                p = n(c),
                d = i(154),
                f = n(d),
                g = i(164),
                m = n(g),
                y = i(184),
                v = n(y),
                $ = {};
            e.default = {
                register: function (t, e) {
                    (e = JSON.parse(JSON.stringify(e))), ($[t] = e);
                },
                _pickSeriesNames: function (t, e) {
                    var i = [];
                    return (
                        m.default.isComboChart(t)
                            ? Object.keys(e).forEach(function (t) {
                                i.push(t);
                            })
                            : i.push(t),
                            i
                    );
                },
                _overwriteTheme: function (t, e) {
                    var i = this;
                    Object.entries(e).forEach(function (n) {
                        var r = o(n, 2),
                            s = r[0],
                            u = r[1],
                            l = t[s];
                        (l || 0 === l) && ((0, a.default)(l) ? (e[s] = l.slice()) : (0, h.default)(l) ? i._overwriteTheme(l, u) : (e[s] = l));
                    });
                },
                _pickValidTheme: function (t, e) {
                    var i = {};
                    return (
                        f.default.THEME_PROPS_MAP[e].forEach(function (e) {
                            (0, u.default)(t[e]) && (i[e] = t[e]);
                        }),
                            i
                    );
                },
                _createComponentThemeWithSeriesName: function (t, e, i, n) {
                    var o = this,
                        r = {};
                    return (
                        (e = e || {}),
                            t.forEach(function (t) {
                                var a = e[t] || o._pickValidTheme(e, n);
                                Object.keys(a).length ? ((r[t] = JSON.parse(JSON.stringify(v.default[n]))), o._overwriteTheme(a, r[t])) : (r[t] = JSON.parse(JSON.stringify(i)));
                            }),
                            r
                    );
                },
                _makeEachSeriesColors: function (t, e, i) {
                    for (var n = [], o = t.length, r = i || 0, a = 0; a < e; a += 1) n.push(t[r]), (r += 1) >= o && (r = 0);
                    return n;
                },
                _setSeriesColors: function (t, e, i, n, o) {
                    var r = this,
                        a = void 0,
                        s = void 0,
                        u = void 0,
                        l = 0;
                    (i = i || {}),
                        t.forEach(function (t) {
                            i[t] ? ((a = i[t].colors), (u = !0)) : ((a = i.colors || v.default.series.colors), (u = !1)),
                                (s = r._getSeriesThemeColorCount(n[t], o)),
                                (e[t].colors = r._makeEachSeriesColors(a, s, !u && l)),
                            u || (l = (s + l) % a.length);
                        });
                },
                _getSeriesThemeColorCount: function (t, e) {
                    var i = 0;
                    if (t && t.length) {
                        var n = t[0] && t[0].data && t[0].data.length;
                        i = e && n ? Math.max(t.length, t[0].data.length) : t.length;
                    }
                    return i;
                },
                _initTheme: function (t, e, i, n, o) {
                    var r = void 0;
                    return (
                        t !== f.default.DEFAULT_THEME_NAME ? ((r = JSON.parse(JSON.stringify(v.default))), this._overwriteTheme(e, r)) : (r = JSON.parse(JSON.stringify(e))),
                            (r.yAxis = this._createComponentThemeWithSeriesName(i, e.yAxis, r.yAxis, "yAxis")),
                            (r.series = this._createComponentThemeWithSeriesName(i, e.series, r.series, "series")),
                            this._setSeriesColors(i, r.series, e.series, n, o),
                            r
                    );
                },
                _createTargetThemesForFontInherit: function (t) {
                    var e = [t.title, t.xAxis.title, t.xAxis.label, t.legend.label, t.plot.label];
                    return (
                        (0, p.default)(t.yAxis, function (t) {
                            e.push(t.title, t.label);
                        }),
                            (0, p.default)(t.series, function (t) {
                                e.push(t.label);
                            }),
                            e
                    );
                },
                _inheritThemeFont: function (t) {
                    var e = this._createTargetThemesForFontInherit(t),
                        i = t.chart.fontFamily;
                    e.forEach(function (t) {
                        t.fontFamily || (t.fontFamily = i);
                    });
                },
                _copySeriesColorTheme: function (t, e, i) {
                    e[i] = { colors: t.colors, borderColor: t.borderColor, selectionColor: t.selectionColor };
                },
                _copySeriesColorThemeToOther: function (t) {
                    var e = this;
                    (0, p.default)(t.series, function (i, n) {
                        e._copySeriesColorTheme(i, t.legend, n), e._copySeriesColorTheme(i, t.tooltip, n);
                    });
                },
                get: function (t, e, i, n) {
                    var o = $[t];
                    if (!o) throw Error("Not exist " + t + " theme.");
                    var r = this._pickSeriesNames(e, i),
                        a = this._initTheme(t, o, r, i, n);
                    return this._inheritThemeFont(a, r), this._copySeriesColorThemeToOther(a), a;
                },
            };
        },
        function (t, e) {
            "use strict";
            e.__esModule = !0;
            var i = "#000000",
                n = "lighter",
                o = "Arial",
                r = (e.DEFAULT_LEGEND_LABEL_FONTCOLOR = "#333"),
                a = { tickColor: i, title: { fontSize: 11, fontFamily: o, color: "#bbbbbb", fontWeight: "bold" }, label: { fontSize: 11, fontFamily: o, color: "#333", fontWeight: "normal" } };
            e.default = {
                chart: { background: { color: "#ffffff", opacity: 1 }, fontFamily: o },
                title: { fontSize: 18, fontFamily: o, color: i, fontWeight: n },
                yAxis: a,
                xAxis: a,
                plot: { lineColor: "#000000", background: "#ffffff", label: { fontSize: 11, fontFamily: o, color: "#888" } },
                series: {
                    label: { fontSize: 11, fontFamily: o, color: i, fontWeight: n },
                    colors: ["#009DD1", "#EC9F04", "#CE2325", "#02A17A", "#463DBA", "#D66093", "#858585", "#942A86", "#29dbe3", "#dddddd"],
                    borderColor: "",
                    borderWidth: "",
                    selectionColor: "",
                    startColor: "#FFE98A",
                    endColor: "#D74177",
                    overColor: "",
                    dot: { fillColor: "", fillOpacity: 1, strokeColor: "", strokeOpacity: "", strokeWidth: 0, radius: 6, hover: { fillColor: "", fillOpacity: 1, strokeColor: "#fff", strokeOpacity: 1, strokeWidth: 4, radius: 6 } },
                    ranges: [],
                },
                legend: { label: { fontSize: 11, fontFamily: o, color: r, fontWeight: n } },
                tooltip: {},
                chartExportMenu: { backgroundColor: "#fff", borderRadius: 0, borderWidth: 1, color: "#000" },
            };
        },
        function (t, e) {
            "use strict";
            e.__esModule = !0;
            var i = {};
            e.default = {
                get: function (t) {
                    var e = i[t];
                    if (!e) throw Error("Not exist " + t + " map.");
                    return e;
                },
                register: function (t, e) {
                    i[t] = e;
                },
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                r = i(140),
                a = n(r),
                s = i(149),
                u = n(s),
                l = i(187),
                h = n(l),
                c = i(138),
                p = n(c),
                d = function t(e) {
                    var i = void 0;
                    return (
                        (0, a.default)(e)
                            ? ((i = []),
                                e.forEach(function (e, n) {
                                    i[n] = t(e);
                                }))
                            : (0, h.default)(e) || (0, u.default)(e)
                                ? (i = e)
                                : (0, p.default)(e)
                                    ? ((i = {}),
                                        Object.entries(e).forEach(function (e) {
                                            var n = o(e, 2),
                                                r = n[0],
                                                a = n[1];
                                            i[r] = t(a);
                                        }))
                                    : (i = e),
                            i
                    );
                };
            e.default = { deepCopy: d };
        },
        function (t, e) {
            "use strict";
            t.exports = function t(e) {
                return e instanceof Function;
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(139),
                r = n(o),
                a = i(189),
                s = n(a),
                u = i(190),
                l = n(u),
                h = i(158),
                c = n(h);
            e.default = {
                makeDataWithTable: function t(e) {
                    var i,
                        n,
                        o = void 0;
                    return (
                        e.element && "TABLE" === e.element.tagName ? (o = e.element) : e.elementId && (o = document.getElementById(e.elementId)),
                            (i = (function t(e) {
                                var i = [];
                                if (e) {
                                    var n = [],
                                        o = (0, l.default)(e.getElementsByTagName("TR"));
                                    (0, r.default)(o, function (t, e) {
                                        var i = (0, l.default)(t.getElementsByTagName(0 === e ? "TH" : "TD")),
                                            o = (0, s.default)(i, "innerText");
                                        n.push(o);
                                    }),
                                    n[0].length < n[1].length && n[0].unshift(""),
                                        (i = c.default.pivot(n));
                                }
                                return i;
                            })(o)),
                            (n = void 0),
                        i.length > 0 &&
                        (((n = {}).categories = []),
                            (n.series = []),
                            (n.categories = i.shift().slice(1)),
                            i.forEach(function (t) {
                                var e = { name: t[0], data: t.slice(1) };
                                n.series.push(e);
                            })),
                            n
                    );
                },
            };
        },
        function (t, e, i) {
            "use strict";
            var n = i(139);
            t.exports = function t(e, i) {
                var o = [];
                return (
                    n(e, function (t) {
                        o.push(t[i]);
                    }),
                        o
                );
            };
        },
        function (t, e, i) {
            "use strict";
            var n = i(141);
            t.exports = function t(e) {
                var i;
                try {
                    i = Array.prototype.slice.call(e);
                } catch (o) {
                    (i = []),
                        n(e, function (t) {
                            i.push(t);
                        });
                }
                return i;
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(155),
                r = n(o),
                a = i(146),
                s = n(a),
                u = {
                    DOM: function (t) {
                        var e = r.default.create("DIV");
                        return r.default.append(t, e), e;
                    },
                },
                l = (function () {
                    function t() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t);
                    }
                    return (
                        (t.prototype.initDimension = function (t) {
                            this.dimension = t;
                        }),
                            (t.prototype.getPaper = function (t, e) {
                                var i = this[e + "Paper"],
                                    n = (0, s.default)(t) && i && r.default.findParentByClass(i.canvas, "tui-chart") !== t;
                                return (i && !n) || ((i = u[e].call(this, t, this.dimension)), "DOM" !== e && (this[e + "Paper"] = i)), i;
                            }),
                            (t.addRendererType = function (t, e) {
                                u[t] = e;
                            }),
                            t
                    );
                })();
            e.default = l;
        },
        function (t, e) {
            "use strict";
            !(function () {
                for (var t = 0, e = ["ms", "moz", "webkit", "o"], i = 0; i < e.length && !window.requestAnimationFrame; ++i)
                    (window.requestAnimationFrame = window[e[i] + "RequestAnimationFrame"]), (window.cancelAnimationFrame = window[e[i] + "CancelAnimationFrame"] || window[e[i] + "CancelRequestAnimationFrame"]);
                window.requestAnimationFrame ||
                (window.requestAnimationFrame = function (e) {
                    var i = new Date().getTime(),
                        n = Math.max(0, 16 - (i - t)),
                        o = window.setTimeout(function () {
                            e(i + n);
                        }, n);
                    return (t = i + n), o;
                }),
                window.cancelAnimationFrame ||
                (window.cancelAnimationFrame = function (t) {
                    clearTimeout(t);
                });
            })();
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            var o = i(154),
                r = n(o),
                a = i(180),
                s = n(a),
                u = i(194),
                l = n(u),
                h = i(284),
                c = n(h),
                p = i(285),
                d = n(p),
                f = i(287),
                g = n(f),
                m = i(288),
                y = n(m),
                v = i(290),
                $ = n(v),
                x = i(291),
                T = n(x),
                b = i(292),
                _ = n(b),
                A = i(293),
                D = n(A),
                C = i(294),
                E = n(C),
                L = i(295),
                S = n(L),
                P = i(296),
                M = n(P),
                w = i(299),
                k = n(w),
                I = i(300),
                R = n(I),
                B = i(303),
                O = n(B),
                N = i(304),
                F = n(N),
                G = i(305),
                H = n(G);
            s.default.register(r.default.CHART_TYPE_BAR, l.default),
                s.default.register(r.default.CHART_TYPE_COLUMN, c.default),
                s.default.register(r.default.CHART_TYPE_LINE, d.default),
                s.default.register(r.default.CHART_TYPE_AREA, g.default),
                s.default.register(r.default.CHART_TYPE_COLUMN_LINE_COMBO, y.default),
                s.default.register(r.default.CHART_TYPE_LINE_SCATTER_COMBO, $.default),
                s.default.register(r.default.CHART_TYPE_LINE_AREA_COMBO, T.default),
                s.default.register(r.default.CHART_TYPE_PIE_DONUT_COMBO, _.default),
                s.default.register(r.default.CHART_TYPE_PIE, D.default),
                s.default.register(r.default.CHART_TYPE_BUBBLE, E.default),
                s.default.register(r.default.CHART_TYPE_SCATTER, S.default),
                s.default.register(r.default.CHART_TYPE_HEATMAP, M.default),
                s.default.register(r.default.CHART_TYPE_TREEMAP, k.default),
                s.default.register(r.default.CHART_TYPE_MAP, R.default),
                s.default.register(r.default.CHART_TYPE_RADIAL, O.default),
                s.default.register(r.default.CHART_TYPE_BOXPLOT, F.default),
                s.default.register(r.default.CHART_TYPE_BULLET, H.default);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(195),
                r = n(o),
                a = i(154),
                s = n(a),
                u = i(181),
                l = n(u),
                h = i(164),
                c = n(h),
                p = (function (t) {
                    function e(i, n, o) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e),
                            l.default.updateRawSeriesDataByOptions(i, o.series);
                        var r = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, { rawData: i, theme: n, options: o, hasAxes: !0 }));
                        return (r.className = "tui-bar-chart"), r._updateOptionsRelatedDiverging(), r;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._updateOptionsRelatedDiverging = function () {
                                var t = this.options;
                                if (((t.series = t.series || {}), t.series.diverging)) {
                                    (t.yAxis = t.yAxis || {}), (t.xAxis = t.xAxis || {}), (t.plot = t.plot || {}), (t.series.stack = t.series.stack || { type: s.default.NORMAL_STACK_TYPE });
                                    var e = c.default.isYAxisAlignCenter(this.hasRightYAxis, t.yAxis.align);
                                    (t.yAxis.isCenter = e), (t.xAxis.divided = e), (t.series.divided = e), (t.plot.divided = e);
                                }
                            }),
                            (e.prototype.addComponents = function () {
                                this.componentManager.register("title", "title"),
                                    this.componentManager.register("plot", "plot"),
                                    this.componentManager.register("legend", "legend"),
                                    this.componentManager.register("barSeries", "barSeries"),
                                    this.componentManager.register("yAxis", "axis"),
                                    this.componentManager.register("xAxis", "axis"),
                                this.hasRightYAxis && this.componentManager.register("rightYAxis", "axis"),
                                    this.componentManager.register("chartExportMenu", "chartExportMenu"),
                                    this.componentManager.register("tooltip", "tooltip"),
                                    this.componentManager.register("mouseEventDetector", "mouseEventDetector");
                            }),
                            (e.prototype.getScaleOption = function () {
                                return { xAxis: !0 };
                            }),
                            (e.prototype.onChangeCheckedLegends = function (t) {
                                var e = void 0;
                                this.hasRightYAxis && (e = { optionChartTypes: ["bar", "bar"] }), r.default.prototype.onChangeCheckedLegends.call(this, t, null, e);
                            }),
                            (e.prototype.addDataRatios = function (t) {
                                var e = this.options.series,
                                    i = void 0 === e ? {} : e,
                                    n = this.chartType,
                                    o = (i[n] || i).stack;
                                this.dataProcessor.addDataRatios(t[n], o, n);
                            }),
                            e
                    );
                })(r.default);
            e.default = p;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(140),
                r = n(o),
                a = i(146),
                s = n(a),
                u = i(156),
                l = n(u),
                h = i(139),
                c = n(h),
                p = i(196),
                d = n(p),
                f = i(198),
                g = n(f),
                m = i(154),
                y = n(m),
                v = i(200),
                $ = n(v),
                x = i(260),
                T = n(x),
                b = i(181),
                _ = n(b),
                A = i(155),
                D = n(A),
                C = i(148),
                E = n(C),
                L = i(186),
                S = n(L),
                P = i(272),
                M = n(P),
                w = i(183),
                k = n(w),
                I = i(164),
                R = n(I),
                B = (function () {
                    function t(e) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.theme = e.theme),
                        e.seriesTypes && (this.seriesTypes = e.seriesTypes),
                        e.chartTypes && (this.chartTypes = e.chartTypes),
                            (this.originalOptions = S.default.deepCopy(e.options)),
                            this._initializeOptions(e.options),
                            (this.chartType = this.options.chartType),
                            (this.hasAxes = e.hasAxes),
                            (this.isVertical = !!e.isVertical),
                            (this.dataProcessor = this._createDataProcessor(e)),
                            (this.eventBus = new g.default()),
                            (this.prevXAxisData = null),
                            (this.componentManager = this._createComponentManager()),
                            (this.hasRightYAxis = (0, r.default)(this.options.yAxis) && this.options.yAxis.length > 1),
                            this.addComponents(),
                            this._attachToEventBus(),
                            this.componentManager.presetAnimationConfig(this.options.series.animation),
                        this.options.usageStatistics && (0, d.default)("chart", "UA-129983528-1");
                    }
                    return (
                        (t.prototype.destroy = function () {
                            var t = this;
                            this.eventBus.off(),
                                (this.chartContainer.outerHTML = ""),
                                (0, c.default)(this, function (e, i) {
                                    t[i] = null;
                                });
                        }),
                            (t.prototype.selectSeries = function (t) {
                                var e = t.legendIndex,
                                    i = t.index,
                                    n = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
                                this.componentManager.get("mouseEventDetector").selectSeries({ chartType: this.chartType, indexes: { groupIndex: i, index: e } }, n);
                            }),
                            (t.prototype.unselectSeries = function () {
                                this.componentManager.get("mouseEventDetector").unselectSeries();
                            }),
                            (t.prototype._attachToEventBus = function () {
                                this.eventBus.on("changeCheckedLegends", this.onChangeCheckedLegends, this), this.onZoom && this.eventBus.on({ zoom: this.onZoom, resetZoom: this.onResetZoom }, this);
                            }),
                            (t.prototype._setOffsetProperty = function (t, e, i) {
                                (0, s.default)(t[e]) && ((t.offset = t.offset || {}), (t.offset[i] = t[e]), delete t[e]);
                            }),
                            (t.prototype._initializeOffset = function (t) {
                                t && (this._setOffsetProperty(t, "offsetX", "x"), this._setOffsetProperty(t, "offsetY", "y"));
                            }),
                            (t.prototype._initializeTitleOptions = function (t) {
                                var e = this;
                                t &&
                                ((0, r.default)(t) ? t : [t]).forEach(function (t) {
                                    var i = t.title;
                                    (0, l.default)(i) && (t.title = { text: i }), e._initializeOffset(t.title);
                                });
                            }),
                            (t.prototype._initializeTooltipOptions = function (t) {
                                (t.grouped = !!t.grouped), this._initializeOffset(t), delete t.position;
                            }),
                            (t.prototype._initializeOptions = function (t) {
                                var e = S.default.deepCopy(t),
                                    i = {
                                        chartTypes: this.chartTypes,
                                        xAxis: {},
                                        series: {},
                                        tooltip: {},
                                        usageStatistics: !0,
                                        chartExportMenu: Object.assign({ visible: !0 }, e.chartExportMenu),
                                        legend: Object.assign({ visible: !0 }, e.legend),
                                    };
                                delete e.chartExportMenu,
                                    delete e.legend,
                                    Object.assign(t, i, e),
                                    this._initializeTitleOptions(t.chart),
                                    this._initializeTitleOptions(t.xAxis),
                                    this._initializeTitleOptions(t.yAxis),
                                    this._initializeTooltipOptions(t.tooltip),
                                    (this.options = t);
                            }),
                            (t.prototype._createDataProcessor = function (t) {
                                return new (t.DataProcessor || T.default)(t.rawData, this.chartType, t.options, this.seriesTypes);
                            }),
                            (t.prototype._createComponentManager = function () {
                                return new $.default({
                                    options: this.options,
                                    theme: this.theme,
                                    dataProcessor: this.dataProcessor,
                                    hasAxes: this.hasAxes,
                                    eventBus: this.eventBus,
                                    isVertical: this.isVertical,
                                    seriesTypes: this.seriesTypes || [this.chartType],
                                });
                            }),
                            (t.prototype.addComponents = function () {}),
                            (t.prototype.getScaleOption = function () {}),
                            (t.prototype._buildBoundsAndScaleData = function (t, e) {
                                return M.default.build(this.dataProcessor, this.componentManager, {
                                    chartType: this.chartType,
                                    seriesTypes: this.seriesTypes,
                                    options: this.options,
                                    theme: this.theme,
                                    hasAxes: this.hasAxes,
                                    scaleOption: this.getScaleOption(),
                                    isVertical: this.isVertical,
                                    hasRightYAxis: this.hasRightYAxis,
                                    addedDataCount: this._dynamicDataHelper ? this._dynamicDataHelper.addedDataCount : null,
                                    prevXAxisData: t,
                                    addingDataMode: e,
                                });
                            }),
                            (t.prototype.addDataRatios = function () {}),
                            (t.prototype.readyForRender = function (t) {
                                var e = this._buildBoundsAndScaleData(this.prevXAxisData, t);
                                return e.axisDataMap.xAxis && (this.prevXAxisData = e.axisDataMap.xAxis), this.addDataRatios(e.limitMap), e;
                            }),
                            (t.prototype.render = function (t) {
                                var e = D.default.create("DIV", "tui-chart " + this.className),
                                    i = this.componentManager,
                                    n = this.dataProcessor,
                                    o = n.getLegendVisibility(),
                                    r = _.default.filterCheckedRawData(n.rawData, o),
                                    a = i.drawingToolPicker.getPaper(e, y.default.COMPONENT_TYPE_RAPHAEL);
                                this.dataProcessor.initData(r),
                                    a.changeChartBackgroundColor(this.theme.chart.background.color),
                                    a.changeChartBackgroundOpacity(this.theme.chart.background.opacity),
                                    E.default.renderFontFamily(e, this.theme.chart.fontFamily),
                                    D.default.append(t, e);
                                var s = this.readyForRender();
                                E.default.renderDimension(e, s.dimensionMap.chart), i.render("render", s, { checkedLegends: o }, e), (this.chartContainer = e), (this.paper = a);
                            }),
                            (t.prototype.protectedRerender = function (t, e) {
                                var i = this.dataProcessor;
                                e || (e = _.default.filterCheckedRawData(i.getCurrentData(), t)), this.dataProcessor.initData(e);
                                var n = this.readyForRender();
                                this.componentManager.render("rerender", n, { checkedLegends: t }, this.chartContainer);
                            }),
                            (t.prototype.rerender = function (t, e) {
                                t = t || this.getCheckedLegend();
                                var i = (e = e || this.dataProcessor.getOriginalRawData()).series;
                                (e.series = Object.keys(i).reduce(function (e, n) {
                                    var o = i[n],
                                        r = t[n];
                                    return (
                                        (e[n] = o.map(function (t, e) {
                                            return (t.visible = r[e]), t;
                                        })),
                                            e
                                    );
                                }, {})),
                                    this.setData(e);
                            }),
                            (t.prototype.setData = function () {
                                var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : null,
                                    e = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1],
                                    i = this._initializeRawData(t),
                                    n = this.dataProcessor,
                                    o = this.options,
                                    r = o.chartType,
                                    a = o.theme;
                                n.initData(i, !0);
                                var s = k.default.get(a, r, i.series);
                                (this.theme = s), this.componentManager.presetBeforeRerender(), this.componentManager.presetForChangeData(s), this.componentManager.presetAnimationConfig(e), this.protectedRerender(n.getLegendVisibility());
                            }),
                            (t.prototype.getCheckedLegend = function () {
                                var t = this.componentManager,
                                    e = this.dataProcessor;
                                return t.has("legend") ? t.get("legend").getCheckedIndexes() : e.getLegendVisibility();
                            }),
                            (t.prototype._initializeRawData = function (t) {
                                this.prevXAxisData = null;
                                var e = S.default.deepCopy(t),
                                    i = this.originalOptions,
                                    n = i.chartType,
                                    o = i.series;
                                if ("combo" !== n && (0, r.default)(e.series)) {
                                    var a = e.series;
                                    (e.series = {}), (e.series[n] = a);
                                }
                                return _.default.updateRawSeriesDataByOptions(e, o), "boxplot" === n && _.default.appendOutliersToSeriesData(e), e;
                            }),
                            (t.prototype.onChangeCheckedLegends = function (t, e, i) {
                                this.protectedRerender(t, e, i);
                            }),
                            (t.prototype.animateChart = function () {
                                this.componentManager.execute("animateComponent");
                            }),
                            (t.prototype.on = function (t, e) {
                                y.default.PUBLIC_EVENT_MAP[t] && this.eventBus.on(y.default.PUBLIC_EVENT_PREFIX + t, e);
                            }),
                            (t.prototype.off = function (t, e) {
                                y.default.PUBLIC_EVENT_MAP[t] && this.eventBus.off(y.default.PUBLIC_EVENT_PREFIX + t, e);
                            }),
                            (t.prototype._updateChartDimension = function (t) {
                                var e = !1,
                                    i = this.options;
                                return (
                                    (i.chart = i.chart || {}),
                                    t.width && t.width > 0 && i.chart.width !== t.width && ((i.chart.width = t.width), (e = !0)),
                                    t.height && t.height > 0 && i.chart.height !== t.height && ((i.chart.height = t.height), (e = !0)),
                                        e
                                );
                            }),
                            (t.prototype.resize = function (t) {
                                var e = this.dataProcessor.getLegendVisibility();
                                if (t && this._updateChartDimension(t)) {
                                    var i = this.readyForRender(),
                                        n = i.dimensionMap.chart;
                                    E.default.renderDimension(this.chartContainer, n), this.paper.resizeBackground(n.width, n.height), this.paper.setSize(n.width, n.height), this.componentManager.render("resize", i, { checkedLegends: e });
                                }
                            }),
                            (t.prototype.setTooltipAlign = function (t) {
                                this.componentManager.get("tooltip").setAlign(t);
                            }),
                            (t.prototype.setTooltipOffset = function (t) {
                                this.componentManager.get("tooltip").setOffset(t);
                            }),
                            (t.prototype.setTooltipPosition = function (t) {
                                this.componentManager.get("tooltip").setPosition(t);
                            }),
                            (t.prototype.resetTooltipAlign = function () {
                                this.componentManager.get("tooltip").resetAlign();
                            }),
                            (t.prototype.resetTooltipOffset = function () {
                                this.componentManager.get("tooltip").resetOffset();
                            }),
                            (t.prototype.resetTooltipPosition = function () {
                                this.resetTooltipOffset();
                            }),
                            (t.prototype.showSeriesLabel = function () {
                                this.componentManager.where({ componentType: "series" }).forEach(function (t) {
                                    t.showLabel();
                                });
                            }),
                            (t.prototype.hideSeriesLabel = function () {
                                this.componentManager.where({ componentType: "series" }).forEach(function (t) {
                                    t.hideLabel();
                                });
                            }),
                            (t.prototype.addData = function () {}),
                            (t.prototype.addPlotLine = function () {}),
                            (t.prototype.addPlotBand = function () {}),
                            (t.prototype.removePlotLine = function () {}),
                            (t.prototype.removePlotBand = function () {}),
                            (t.prototype._getSeriesData = function (t, e, i) {
                                return e < 0 ? null : this.componentManager.get("mouseEventDetector").findDataByIndexes({ index: t, seriesIndex: e, outlierIndex: i });
                            }),
                            (t.prototype._findSeriesIndexByLabel = function (t, e) {
                                for (var i = this.dataProcessor.getLegendLabels(t), n = i ? i.length : 0, o = -1, r = 0; r < n; r += 1)
                                    if (i[r] === e) {
                                        o = r;
                                        break;
                                    }
                                return o;
                            }),
                            (t.prototype._findDataByIndexes = function (t, e) {
                                return this.componentManager.get("mouseEventDetector").findDataByIndexes(t, e);
                            }),
                            (t.prototype.showTooltip = function (t) {
                                var e = void 0,
                                    i = void 0;
                                if (R.default.isSupportPublicShowTooptipAPI(this.chartType)) {
                                    var n = this.options.tooltip && this.options.tooltip.grouped,
                                        o = this.componentManager.get("mouseEventDetector");
                                    n ? (i = { indexes: { groupIndex: t.index } }) : ((e = this._findSeriesIndexByLabel(t.chartType, t.legend)), (i = this._getSeriesData(t.index, e, t.outlierIndex))),
                                        i ? ((i.silent = !0), o._showTooltip(i)) : this.hideTooltip();
                                }
                            }),
                            (t.prototype.hideTooltip = function () {
                                if (R.default.isSupportPublicShowTooptipAPI(this.chartType)) {
                                    var t = this.options.tooltip && this.options.tooltip.grouped,
                                        e = this.componentManager.get("mouseEventDetector");
                                    ((t && e.prevIndex >= 0) || (!t && e.prevFoundData)) && e._hideTooltip({ silent: !0 });
                                }
                            }),
                            t
                    );
                })();
            e.default = B;
        },
        function (t, e, i) {
            "use strict";
            var n = i(145),
                o = i(197);
            t.exports = function t(e, i) {
                var r,
                    a = location.hostname,
                    s = "TOAST UI " + e + " for " + a + ": Statistics",
                    u = window.localStorage.getItem(s);
                (n(window.tui) || !1 !== window.tui.usageStatistics) &&
                ((u && ((r = u), !(new Date().getTime() - r > 6048e5))) ||
                    (window.localStorage.setItem(s, new Date().getTime()),
                        setTimeout(function () {
                            ("interactive" !== document.readyState && "complete" !== document.readyState) || o("https://www.google-analytics.com/collect", { v: 1, t: "event", tid: i, cid: a, dp: a, dh: e, el: e, ec: "use" });
                        }, 1e3)));
            };
        },
        function (t, e, i) {
            "use strict";
            var n = i(142);
            t.exports = function t(e, i) {
                var o = document.createElement("img"),
                    r = "";
                return (
                    n(i, function (t, e) {
                        r += "&" + e + "=" + t;
                    }),
                        (r = r.substring(1)),
                        (o.src = e + "?" + r),
                        (o.style.display = "none"),
                        document.body.appendChild(o),
                        document.body.removeChild(o),
                        o
                );
            };
        },
        function (t, e, i) {
            "use strict";
            function n() {
                (this.events = null), (this.contexts = null);
            }
            var o = i(199),
                r = i(146),
                a = i(156),
                s = i(138),
                u = i(140),
                l = i(187),
                h = i(139),
                c = /\s+/g;
            (n.mixin = function (t) {
                o(t.prototype, n.prototype);
            }),
                (n.prototype._getHandlerItem = function (t, e) {
                    var i = { handler: t };
                    return e && (i.context = e), i;
                }),
                (n.prototype._safeEvent = function (t) {
                    var e,
                        i = this.events;
                    return i || (i = this.events = {}), t && ((e = i[t]) || ((e = []), (i[t] = e)), (i = e)), i;
                }),
                (n.prototype._safeContext = function () {
                    var t = this.contexts;
                    return t || (t = this.contexts = []), t;
                }),
                (n.prototype._indexOfContext = function (t) {
                    for (var e = this._safeContext(), i = 0; e[i]; ) {
                        if (t === e[i][0]) return i;
                        i += 1;
                    }
                    return -1;
                }),
                (n.prototype._memorizeContext = function (t) {
                    var e, i;
                    r(t) && ((e = this._safeContext()), (i = this._indexOfContext(t)) > -1 ? (e[i][1] += 1) : e.push([t, 1]));
                }),
                (n.prototype._forgetContext = function (t) {
                    var e, i;
                    r(t) && ((e = this._safeContext()), (i = this._indexOfContext(t)) > -1 && ((e[i][1] -= 1), e[i][1] <= 0 && e.splice(i, 1)));
                }),
                (n.prototype._bindEvent = function (t, e, i) {
                    var n = this._safeEvent(t);
                    this._memorizeContext(i), n.push(this._getHandlerItem(e, i));
                }),
                (n.prototype.on = function (t, e, i) {
                    var n = this;
                    a(t)
                        ? h((t = t.split(c)), function (t) {
                            n._bindEvent(t, e, i);
                        })
                        : s(t) &&
                        ((i = e),
                            h(t, function (t, e) {
                                n.on(e, t, i);
                            }));
                }),
                (n.prototype.once = function (t, e, i) {
                    function n() {
                        e.apply(i, arguments), o.off(t, n, i);
                    }
                    var o = this;
                    return s(t)
                        ? ((i = e),
                            void h(t, function (t, e) {
                                o.once(e, t, i);
                            }))
                        : void this.on(t, n, i);
                }),
                (n.prototype._spliceMatches = function (t, e) {
                    var i,
                        n = 0;
                    if (u(t)) for (i = t.length; n < i; n += 1) !0 === e(t[n]) && (t.splice(n, 1), (i -= 1), (n -= 1));
                }),
                (n.prototype._matchHandler = function (t) {
                    var e = this;
                    return function (i) {
                        var n = t === i.handler;
                        return n && e._forgetContext(i.context), n;
                    };
                }),
                (n.prototype._matchContext = function (t) {
                    var e = this;
                    return function (i) {
                        var n = t === i.context;
                        return n && e._forgetContext(i.context), n;
                    };
                }),
                (n.prototype._matchHandlerAndContext = function (t, e) {
                    var i = this;
                    return function (n) {
                        var o = t === n.handler,
                            r = e === n.context,
                            a = o && r;
                        return a && i._forgetContext(n.context), a;
                    };
                }),
                (n.prototype._offByEventName = function (t, e) {
                    var i = this,
                        n = l(e),
                        o = i._matchHandler(e);
                    h((t = t.split(c)), function (t) {
                        var e = i._safeEvent(t);
                        n
                            ? i._spliceMatches(e, o)
                            : (h(e, function (t) {
                                i._forgetContext(t.context);
                            }),
                                (i.events[t] = []));
                    });
                }),
                (n.prototype._offByHandler = function (t) {
                    var e = this,
                        i = this._matchHandler(t);
                    h(this._safeEvent(), function (t) {
                        e._spliceMatches(t, i);
                    });
                }),
                (n.prototype._offByObject = function (t, e) {
                    var i,
                        n = this;
                    0 > this._indexOfContext(t)
                        ? h(t, function (t, e) {
                            n.off(e, t);
                        })
                        : a(e)
                            ? ((i = this._matchContext(t)), n._spliceMatches(this._safeEvent(e), i))
                            : l(e)
                                ? ((i = this._matchHandlerAndContext(e, t)),
                                    h(this._safeEvent(), function (t) {
                                        n._spliceMatches(t, i);
                                    }))
                                : ((i = this._matchContext(t)),
                                    h(this._safeEvent(), function (t) {
                                        n._spliceMatches(t, i);
                                    }));
                }),
                (n.prototype.off = function (t, e) {
                    a(t) ? this._offByEventName(t, e) : arguments.length ? (l(t) ? this._offByHandler(t) : s(t) && this._offByObject(t, e)) : ((this.events = {}), (this.contexts = []));
                }),
                (n.prototype.fire = function (t) {
                    this.invoke.apply(this, arguments);
                }),
                (n.prototype.invoke = function (t) {
                    var e, i, n, o;
                    if (!this.hasListener(t)) return !0;
                    for (e = this._safeEvent(t), i = Array.prototype.slice.call(arguments, 1), n = 0; e[n]; ) {
                        if (!1 === (o = e[n]).handler.apply(o.context, i)) return !1;
                        n += 1;
                    }
                    return !0;
                }),
                (n.prototype.hasListener = function (t) {
                    return this.getListenerLength(t) > 0;
                }),
                (n.prototype.getListenerLength = function (t) {
                    return this._safeEvent(t).length;
                }),
                (t.exports = n);
        },
        function (t, e) {
            "use strict";
            t.exports = function t(e, i) {
                var n,
                    o,
                    r,
                    a,
                    s = Object.prototype.hasOwnProperty;
                for (r = 1, a = arguments.length; r < a; r += 1) for (o in (n = arguments[r])) s.call(n, o) && (e[o] = n[o]);
                return e;
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                r = i(140),
                a = n(r),
                s = i(159),
                u = n(s),
                l = i(150),
                h = n(l),
                c = i(138),
                p = n(c),
                d = i(152),
                f = n(d),
                g = i(154),
                m = n(g),
                y = i(155),
                v = n(y),
                $ = i(201),
                x = n($),
                T = i(202),
                b = n(T),
                _ = i(203),
                A = n(_),
                D = i(204),
                C = n(D),
                E = i(206),
                L = n(E),
                S = i(191),
                P = n(S),
                M = i(212),
                w = n(M),
                k = i(217),
                I = n(k),
                R = i(218),
                B = n(R),
                O = i(219),
                N = n(O),
                F = i(225),
                G = n(F),
                H = i(227),
                V = n(H),
                z = i(228),
                W = n(z),
                Y = i(232),
                U = n(Y),
                X = i(239),
                Z = n(X),
                j = i(243),
                q = n(j),
                K = i(244),
                Q = n(K),
                J = i(246),
                tt = n(J),
                te = i(247),
                ti = n(te),
                tn = i(248),
                to = n(tn),
                tr = i(250),
                ta = n(tr),
                ts = i(251),
                tu = n(ts),
                tl = i(252),
                th = n(tl),
                tc = i(253),
                tp = n(tc),
                td = i(254),
                tf = n(td),
                tg = i(256),
                tm = n(tg),
                ty = i(257),
                t8 = n(ty),
                tv = i(258),
                t$ = n(tv),
                tx = i(143),
                tT = n(tx),
                tb = {
                    axis: x.default,
                    plot: b.default,
                    radialPlot: C.default,
                    legend: w.default,
                    spectrumLegend: I.default,
                    circleLegend: B.default,
                    tooltip: N.default,
                    groupTooltip: G.default,
                    mapChartTooltip: V.default,
                    mapChartEventDetector: W.default,
                    mouseEventDetector: U.default,
                    barSeries: Z.default,
                    columnSeries: q.default,
                    lineSeries: Q.default,
                    radialSeries: tt.default,
                    areaSeries: ti.default,
                    bubbleSeries: to.default,
                    scatterSeries: ta.default,
                    mapSeries: tu.default,
                    pieSeries: th.default,
                    heatmapSeries: tp.default,
                    treemapSeries: tf.default,
                    boxplotSeries: tm.default,
                    bulletSeries: t8.default,
                    zoom: t$.default,
                    chartExportMenu: L.default,
                    title: A.default,
                },
                t_ = (function () {
                    function t(e) {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t);
                        var i = e.options.chart,
                            n = (0, f.default)(i, "width") || m.default.CHART_DEFAULT_WIDTH,
                            o = (0, f.default)(i, "height") || m.default.CHART_DEFAULT_HEIGHT;
                        (this.components = []),
                            (this.componentMap = {}),
                            (this.theme = e.theme || {}),
                            (this.options = e.options || {}),
                            (this.dataProcessor = e.dataProcessor),
                            (this.hasAxes = e.hasAxes),
                            (this.isVertical = e.isVertical),
                            (this.eventBus = e.eventBus),
                            (this.drawingToolPicker = new P.default()),
                            this.drawingToolPicker.initDimension({ width: n, height: o }),
                            (this.seriesTypes = e.seriesTypes);
                    }
                    return (
                        (t.prototype._makeComponentOptions = function (t, e, i, n) {
                            return (t = t || this.options[e]), (t = (0, a.default)(t) ? t[n] : t || {});
                        }),
                            (t.prototype.register = function (t, e) {
                                var i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {},
                                    n = i.index || 0,
                                    o = tb[e],
                                    r = o.componentType;
                                (i.name = t), (i.chartTheme = this.theme), (i.chartOptions = this.options), (i.seriesTypes = this.seriesTypes);
                                var a = this._getOptionKey(r, t);
                                (i.theme = this._makeTheme(a, t)),
                                    (i.options = this._makeOptions(a, t, n)),
                                    (i.dataProcessor = this.dataProcessor),
                                    (i.hasAxes = this.hasAxes),
                                    (i.isVertical = this.isVertical),
                                    (i.eventBus = this.eventBus),
                                    (i.alternativeModel = this.alternativeModel);
                                var s = o(i);
                                s && ((s.componentName = t), (s.componentType = r), this.components.push(s), (this.componentMap[t] = s));
                            }),
                            (t.prototype.presetBeforeRerender = function () {
                                this.componentMap.mouseEventDetector.zoomable && this.componentMap.mouseEventDetector.presetBeforeRerender();
                            }),
                            (t.prototype.presetForChangeData = function (t) {
                                var e = this;
                                (this.theme = t),
                                    this.components.forEach(function (t) {
                                        if (t.presetForChangeData) {
                                            var i = t.componentType,
                                                n = t.componentName,
                                                o = e._getOptionKey(i, n);
                                            t.presetForChangeData(e._makeTheme(o, n));
                                        }
                                    });
                            }),
                            (t.prototype.presetAnimationConfig = function (t) {
                                var e = this;
                                this.seriesTypes.forEach(function (i) {
                                    (0, p.default)(e.options.series[i]) ? (e.options.series[i].animationDuration = e._getAnimationDuration(t)) : (e.options.series.animationDuration = e._getAnimationDuration(t));
                                });
                            }),
                            (t.prototype._getAnimationDuration = function (t) {
                                return (0, u.default)(t) && !t ? 0 : (0, p.default)(t) && (0, h.default)(t.duration) ? t.duration : tT.default.getDefaultAnimationDuration(this.options.chartType);
                            }),
                            (t.prototype._makeOptions = function (t, e, i) {
                                var n = this.options[t];
                                return (
                                    n || "rightYAxis" !== t || (n = this.options.yAxis),
                                    "series" === t &&
                                    this.seriesTypes.forEach(function (t) {
                                        return 0 !== e.indexOf(t) || ((n = n[t] || n), (0, a.default)(n) && (n = n[i] || {}), !1);
                                    }),
                                        n
                                );
                            }),
                            (t.prototype._getOptionKey = function (t, e) {
                                return "axis" === t ? e : t;
                            }),
                            (t.prototype._makeTheme = function (t, e) {
                                var i = this.theme[t];
                                return (
                                    i || "rightYAxis" !== t || (i = this.theme.yAxis),
                                    "series" === t &&
                                    this.seriesTypes.forEach(function (t) {
                                        return 0 !== e.indexOf(t) || ((i = i[t]), !1);
                                    }),
                                        i
                                );
                            }),
                            (t.prototype._makeDataForRendering = function (t, e, i, n, o) {
                                var r = Object.assign({ paper: i }, o);
                                return n && (Object.assign(r, n), (r.layout = { dimension: r.dimensionMap[t] || r.dimensionMap[e], position: r.positionMap[t] || r.positionMap[e] })), r;
                            }),
                            (t.prototype.render = function (t, e, i, n) {
                                var o = this,
                                    r = this.components.map(function (r) {
                                        var a = null;
                                        if (r[t]) {
                                            var s = r.componentName,
                                                u = r.componentType,
                                                l = o.drawingToolPicker.getPaper(n, r.drawingType),
                                                h = o._makeDataForRendering(s, u, l, e, i),
                                                c = r[t](h);
                                            c && !c.paper && (a = c);
                                        }
                                        return a;
                                    });
                                n && v.default.append(n, r);
                            }),
                            (t.prototype.where = function (t) {
                                return this.components.filter(function (e) {
                                    var i = !0;
                                    return (
                                        Object.entries(t).forEach(function (t) {
                                            var n = o(t, 2),
                                                r = n[0],
                                                a = n[1];
                                            return e[r] !== a && (i = !1), i;
                                        }),
                                            i
                                    );
                                });
                            }),
                            (t.prototype.execute = function (t) {
                                for (var e = arguments.length, i = Array(e > 1 ? e - 1 : 0), n = 1; n < e; n++) i[n - 1] = arguments[n];
                                this.components.forEach(function (e) {
                                    e[t] && e[t].apply(e, i);
                                });
                            }),
                            (t.prototype.get = function (t) {
                                return this.componentMap[t];
                            }),
                            (t.prototype.has = function (t) {
                                return !!this.get(t);
                            }),
                            t
                    );
                })();
            e.default = t_;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                var e = t.chartOptions,
                    i = t.name,
                    n = t.theme,
                    o = t.seriesTypes,
                    r = e.chartType,
                    a = e.series;
                return (
                    (t.isYAxis = "yAxis" === i || "rightYAxis" === i),
                        (t.shifting = a.shifting),
                        "combo" === r ? (t.isYAxis ? (t.theme = n[o[0]]) : "rightYAxis" === i && ((t.componentType = "yAxis"), (t.theme = n[o[1]]), (t.index = 1))) : t.isYAxis ? (t.theme = n[r]) : (t.theme = n),
                        new b(t)
                );
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(145),
                a = n(r),
                s = i(154),
                u = n(s),
                l = i(164),
                h = n(l),
                c = i(173),
                p = n(c),
                d = i(182),
                f = n(d),
                g = i(148),
                m = n(g),
                y = i(143),
                v = n(y),
                $ = u.default.AXIS_EDGE_RATIO,
                x = u.default.X_AXIS_LABEL_PADDING,
                T = u.default.COMPONENT_TYPE_RAPHAEL,
                b = (function () {
                    function t(e) {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t);
                        var i = e.dataProcessor,
                            n = e.options,
                            o = e.chartTheme,
                            r = e.theme,
                            a = e.name,
                            s = e.isYAxis,
                            u = e.shifting;
                        (this.className = "tui-chart-axis-area"),
                            (this.dataProcessor = i),
                            (this.options = n || {}),
                            (this.theme = Object.assign({}, r, { background: o.chart.background })),
                            (this.isLabelAxis = !1),
                            (this.isYAxis = s),
                            (this.shifting = u),
                            (this.data = {}),
                            (this.layout = null),
                            (this.dimensionMap = null),
                            (this.axisDataMap = null),
                            (this.graphRenderer = f.default.get(T, "axis")),
                            (this.drawingType = T),
                            (this.paperAdditionalWidth = 0),
                            (this.paperAdditionalHeight = 0),
                            (this._elBg = null),
                            (this.isRightYAxis = "rightYAxis" === a);
                    }
                    return (
                        (t.prototype._renderBackground = function () {
                            var t = Object.assign({}, this.layout.dimension),
                                e = Object.assign({}, this.layout.position);
                            this._elBg && this._elBg.remove(), (this._elBg = this.graphRenderer.renderBackground(this.paper, e, t, this.theme.background));
                        }),
                            (t.prototype._renderChildContainers = function (t, e, i) {
                                var n = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : 0,
                                    o = this.isYAxis && this.data.aligned,
                                    r = this.limitMap[this.dataProcessor.chartType],
                                    a = !this.data.limit && r && r.min < 0,
                                    s = h.default.isBarTypeChart(this.dataProcessor.chartType),
                                    u = (this.dataProcessor.getOption("series") || {}).diverging;
                                this.isYAxis && !this.data.isPositionRight && !this.options.isCenter && this.shifting && this._renderBackground(),
                                    this._renderTitleArea(t, n),
                                !1 !== this.options.showLabel && this._renderLabelArea(t, e, i, n),
                                o || this._renderTickArea(t, e, n),
                                a && s && !u && this._renderNegativeStandardsLine(t, n, this.dimensionMap.series, r);
                            }),
                            (t.prototype._renderDividedAxis = function (t) {
                                var e = t.width,
                                    i = this.data,
                                    n = i.tickCount,
                                    o = i.labels,
                                    r = Math.round(e / 2),
                                    a = parseInt(n / 2, 10) + 1,
                                    s = o.slice(0, a),
                                    u = o.slice(a - 1, n),
                                    l = r + this.dimensionMap.yAxis.width - 1;
                                (this.paperAdditionalWidth = r / a), this._renderChildContainers(r, a, s, 0), this._renderChildContainers(e - r - 1 + 1, a, u, l);
                            }),
                            (t.prototype._renderNotDividedAxis = function (t) {
                                var e = t.width,
                                    i = t.height,
                                    n = this.data,
                                    o = n.positionRatio,
                                    r = n.tickCount,
                                    a = n.labels,
                                    s = this.isYAxis ? i : e,
                                    u = 0;
                                o && (u = s * o), this._renderChildContainers(s, r, a, u);
                            }),
                            (t.prototype._renderAxisArea = function () {
                                var t = this.layout.dimension,
                                    e = this.data.isLabelAxis,
                                    i = this.options,
                                    n = i.divided,
                                    o = i.isCenter,
                                    r = t.width;
                                (this.isLabelAxis = e), n ? ((this.containerWidth = r + this.dimensionMap.yAxis.width), this._renderDividedAxis(t), (r = this.containerWidth)) : ((r += o ? 1 : 0), this._renderNotDividedAxis(t));
                            }),
                            (t.prototype._setDataForRendering = function (t) {
                                var e = t.layout,
                                    i = t.dimensionMap,
                                    n = t.limitMap,
                                    o = t.axisDataMap;
                                (this.layout = e), (this.dimensionMap = i), (this.limitMap = n), (this.data = o[this.componentName]), (this.options = this.data.options);
                            }),
                            (t.prototype.render = function (t) {
                                var e = t.paper;
                                (this.paper = e), (this.axisSet = e.set()), this._setDataForRendering(t), this._renderAxisArea();
                            }),
                            (t.prototype.rerender = function (t) {
                                this.axisSet.remove(), this.render(t);
                            }),
                            (t.prototype.resize = function (t) {
                                this.rerender(t);
                            }),
                            (t.prototype.zoom = function (t) {
                                this.rerender(t);
                            }),
                            (t.prototype._getOtherSideDimension = function () {
                                return this.dimensionMap[this.isYAxis ? "xAxis" : "yAxis"];
                            }),
                            (t.prototype._renderTitleArea = function (t, e) {
                                var i = this.options.title,
                                    n = void 0 === i ? {} : i,
                                    o = this.dataProcessor.getOption("xAxis"),
                                    r = this.dataProcessor.getOption("yAxis"),
                                    a = this.dataProcessor.getOption("series") || {};
                                n.text &&
                                this.graphRenderer.renderTitle(this.paper, {
                                    text: n.text,
                                    offset: n.offset,
                                    theme: this.theme.title,
                                    rotationInfo: {
                                        isVertical: this.isYAxis,
                                        isPositionRight: this.data.isPositionRight,
                                        isCenter: this.options.isCenter,
                                        isColumnType: h.default.isColumnTypeChart(this.dataProcessor.chartType, this.dataProcessor.seriesTypes),
                                        isDiverging: a.diverging,
                                        isYAxisCenter: r && "center" === r.align,
                                        isXAxisTitleLeft: o && o.title && "left" === o.title.align,
                                    },
                                    layout: this.layout,
                                    areaSize: t,
                                    additionalWidth: e,
                                    otherSideDimension: this._getOtherSideDimension(),
                                    tickCount: this.data.tickCount,
                                    set: this.axisSet,
                                });
                            }),
                            (t.prototype._renderTickLine = function (t, e, i) {
                                this.graphRenderer.renderTickLine({
                                    areaSize: t,
                                    additionalSize: i,
                                    additionalWidth: this.paperAdditionalWidth,
                                    additionalHeight: this.paperAdditionalHeight,
                                    isPositionRight: this.data.isPositionRight,
                                    isCenter: this.data.options.isCenter,
                                    isNotDividedXAxis: e,
                                    isVertical: this.isYAxis,
                                    tickColor: this.theme.tickColor,
                                    layout: this.layout,
                                    paper: this.paper,
                                    set: this.axisSet,
                                });
                            }),
                            (t.prototype._renderTicks = function (t, e, i, n) {
                                var o = this.theme.tickColor,
                                    r = this.data,
                                    a = r.remainLastBlockInterval,
                                    s = r.sizeRatio,
                                    u = r.tickCount,
                                    l = r.isPositionRight,
                                    h = a ? t : 0,
                                    c = this.isYAxis,
                                    d = this.data.options,
                                    f = d.isCenter,
                                    g = d.divided,
                                    m = p.default.makeTickPixelPositions(t * (void 0 === s ? 1 : s), e, 0, h),
                                    y = this.paperAdditionalHeight + 1,
                                    v = this.paperAdditionalWidth;
                                (m.length = h ? u + 1 : u),
                                    this.graphRenderer.renderTicks({
                                        paper: this.paper,
                                        layout: this.layout,
                                        positions: m,
                                        isVertical: c,
                                        isCenter: f,
                                        isDivided: g,
                                        additionalSize: n,
                                        additionalWidth: v,
                                        additionalHeight: y,
                                        otherSideDimension: this._getOtherSideDimension(),
                                        isPositionRight: l,
                                        tickColor: o,
                                        set: this.axisSet,
                                    });
                            }),
                            (t.prototype._renderNegativeStandardsLine = function (t, e, i, n) {
                                this.graphRenderer.renderStandardLine({ areaSize: t, isVertical: this.isYAxis, layout: this.layout, paper: this.paper, set: this.axisSet, seriesDimension: i, axisLimit: n });
                            }),
                            (t.prototype._renderTickArea = function (t, e, i) {
                                var n = !this.isYAxis && !this.options.divided;
                                this._renderTickLine(t, n, i || 0), this._renderTicks(t, e, n, i || 0);
                            }),
                            (t.prototype._renderLabelArea = function (t, e, i, n) {
                                var o = this.data,
                                    r = o.sizeRatio,
                                    a = o.remainLastBlockInterval,
                                    s = p.default.makeTickPixelPositions(t * (void 0 === r ? 1 : r), e, 0, a ? t : 0),
                                    u = s[1] - s[0];
                                this._renderLabels(s, i, u, n || 0);
                            }),
                            (t.prototype._renderRotationLabels = function (t, e, i, n) {
                                var o = this,
                                    r = this.graphRenderer,
                                    a = this.isYAxis,
                                    s = this.theme.label,
                                    u = this.data.degree,
                                    l = i / 2,
                                    h = i / $,
                                    c = this.layout.position,
                                    p = c.top,
                                    d = c.left,
                                    f = p + x,
                                    g = d,
                                    m = this.options.labelMargin || 0;
                                t.forEach(function (t, c) {
                                    var p = t + (n || 0),
                                        d = {};
                                    a ? ((d.top = p + l), (d.left = i + m)) : ((d.top = f + m), (d.left = g + p + h)), r.renderRotatedLabel({ degree: u, labelText: e[c], paper: o.paper, positionTopAndLeft: d, set: o.axisSet, theme: s });
                                }, this);
                            }),
                            (t.prototype._renderNormalLabels = function (t, e, i, n) {
                                var o = this,
                                    r = this.graphRenderer,
                                    a = this.isYAxis,
                                    s = this.isLabelAxis,
                                    u = this.dataProcessor,
                                    l = this.layout,
                                    c = this.data.isPositionRight,
                                    p = this.theme.label,
                                    d = this.options,
                                    f = d.labelMargin,
                                    g = void 0 === f ? 0 : f,
                                    y = d.pointOnColumn,
                                    $ = d.isCenter,
                                    x = d.maxWidth,
                                    T = h.default.isLineTypeChart(u.chartType, u.seriesTypes),
                                    b = T && y,
                                    _ = h.default.isAutoTickInterval(this.options.tickInterval);
                                t.forEach(function (u, h) {
                                    var d = u + n,
                                        f = i / 2,
                                        y = o._isOverLapXAxisLabel(e[h], u, t[h + 1]),
                                        A = {};
                                    if (!(d < 0 || (!a && _ && y))) {
                                        ((A = a
                                            ? o._getYAxisLabelPosition(l, { labelPosition: d, isCategoryLabel: s, halfLabelDistance: f, isPositionRight: c })
                                            : o._getXAxisLabelPosition(l, {
                                                labelMargin: g,
                                                labelHeight: m.default.getRenderedLabelsMaxHeight(e, p),
                                                labelPosition: d,
                                                isCategoryLabel: s,
                                                isLineTypeChart: T,
                                                isPointOnColumn: b,
                                                halfLabelDistance: f,
                                            })).top = Math.round(A.top)),
                                            (A.left = Math.round(A.left));
                                        var D = e[h];
                                        x && (D = v.default.getEllipsisText(e[h], x, p)),
                                            r.renderLabel({ isPositionRight: c, isVertical: a, isCenter: $, labelSize: i, labelText: D, paper: o.paper, positionTopAndLeft: A, set: o.axisSet, theme: p });
                                    }
                                }, this);
                            }),
                            (t.prototype._isOverLapXAxisLabel = function (t, e, i) {
                                var n = m.default.getRenderedLabelWidth(t);
                                return !(0, a.default)(i) && i - e < n;
                            }),
                            (t.prototype._getYAxisLabelPosition = function (t, e) {
                                var i = void 0,
                                    n = e.labelPosition;
                                return (
                                    e.isCategoryLabel ? (n += e.halfLabelDistance + t.position.top) : (n = t.dimension.height + t.position.top - n),
                                        { top: n, left: (i = e.isPositionRight ? t.position.left + t.dimension.width : this.options.isCenter ? t.position.left + t.dimension.width / 2 : t.position.left) }
                                );
                            }),
                            (t.prototype._getXAxisLabelPosition = function (t, e) {
                                var i = p.default.sum([t.position.top, x, e.labelMargin, e.labelHeight / 2]),
                                    n = e.labelPosition + t.position.left;
                                return e.isCategoryLabel && ((e.isLineTypeChart && !e.isPointOnColumn) || (n += e.halfLabelDistance)), { top: i, left: n };
                            }),
                            (t.prototype._renderLabels = function (t, e, i, n) {
                                var o = this.options,
                                    r = o.rotateLabel,
                                    a = o.prefix,
                                    s = o.suffix,
                                    u = this.data,
                                    l = u.degree,
                                    h = u.multilineLabels,
                                    c = !this.isYAxis && this.isLabelAxis && !1 === r,
                                    p = "xAxis" === this.componentName && l,
                                    d = void 0;
                                (d = c ? h : e).length && (t.length = d.length), (d = m.default.addPrefixSuffix(d, a, s)), p ? this._renderRotationLabels(t, d, i, n) : this._renderNormalLabels(t, d, i, n);
                            }),
                            (t.prototype.animateForAddingData = function (t) {
                                this.isYAxis || this.graphRenderer.animateForAddingData(t.tickSize);
                            }),
                            t
                    );
                })();
            (o.componentType = "axis"), (o.Axis = b);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t, e) {
                return t.start - e.start;
            }
            function r(t) {
                var e = t.seriesTypes,
                    i = t.chartOptions,
                    n = i.chartType,
                    o = i.xAxis.type;
                return (t.chartType = n), (t.chartTypes = e), (t.xAxisTypeOption = o), new T(t);
            }
            e.__esModule = !0;
            var a = function (t, e) {
                if (Array.isArray(t)) return t;
                if (Symbol.iterator in Object(t))
                    return (function t(e, i) {
                        var n = [],
                            o = !0,
                            r = !1,
                            a = void 0;
                        try {
                            for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                        } catch (l) {
                            (r = !0), (a = l);
                        } finally {
                            try {
                                !o && u.return && u.return();
                            } finally {
                                if (r) throw a;
                            }
                        }
                        return n;
                    })(t, e);
                throw TypeError("Invalid attempt to destructure non-iterable instance");
            };
            e.default = r;
            var s = i(140),
                u = n(s),
                l = i(146),
                h = n(l),
                c = i(137),
                p = n(c),
                d = i(145),
                f = n(d),
                g = i(154),
                m = n(g),
                y = i(164),
                v = n(y),
                $ = i(173),
                x = n($),
                T = (function () {
                    function t(e) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.className = "tui-chart-plot-area"),
                            (this.dataProcessor = e.dataProcessor),
                            (this.options = e.options || {}),
                            (this.options.showLine = !!(0, f.default)(this.options.showLine) || this.options.showLine),
                            (this.options.lines = this.options.lines || []),
                            (this.options.bands = this.options.bands || []),
                            (this.xAxisTypeOption = e.xAxisTypeOption),
                            (this.theme = e.theme || {}),
                            (this.chartType = e.chartType),
                            (this.chartTypes = e.chartTypes),
                            (this.layout = null),
                            (this.axisDataMap = null),
                            (this.drawingType = m.default.COMPONENT_TYPE_RAPHAEL);
                    }
                    return (
                        (t.prototype.presetForChangeData = function () {
                            var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : this.theme;
                            this.theme = t;
                        }),
                            (t.prototype._renderPlotArea = function (t) {
                                var e = this.layout.dimension;
                                v.default.isLineTypeChart(this.chartType, this.chartTypes) && this._renderOptionalLines(t, e), this.options.showLine && this._renderPlotLines(t, e);
                            }),
                            (t.prototype._setDataForRendering = function (t) {
                                t && ((this.layout = t.layout), (this.dimensionMap = t.dimensionMap), (this.axisDataMap = t.axisDataMap), (this.paper = t.paper));
                            }),
                            (t.prototype.render = function (t) {
                                var e = (t && t.paper) || this.paper;
                                (this.plotSet = e.set()),
                                    (this.additionalPlotSet = e.set()),
                                    this._setDataForRendering(t),
                                    this._renderPlotArea(this.paper),
                                    this.additionalPlotSet.toBack(),
                                    this.plotSet.toBack(),
                                    e.pushDownBackgroundToBottom();
                            }),
                            (t.prototype.rerender = function (t) {
                                this.additionalPlotSet.remove(), this.plotSet.remove(), this.render(t);
                            }),
                            (t.prototype.resize = function (t) {
                                this.rerender(t);
                            }),
                            (t.prototype.zoom = function (t) {
                                this.rerender(t);
                            }),
                            (t.prototype._makeVerticalLineTemplateParams = function (t) {
                                return Object.assign({ className: "vertical", positionType: "left", width: "1px" }, t);
                            }),
                            (t.prototype._makeHorizontalLineTemplateParams = function (t) {
                                return Object.assign({ className: "horizontal", positionType: "bottom", height: "1px" }, t);
                            }),
                            (t.prototype._renderLine = function (t, e) {
                                var i = this.layout,
                                    n = i.position.top,
                                    o = i.dimension.height,
                                    r = this.paper.path("M" + t + "," + n + "V" + (n + o));
                                return r.attr({ opacity: e.opacity || 1, stroke: e.color }), this.additionalPlotSet.push(r), r;
                            }),
                            (t.prototype._renderBand = function (t, e, i) {
                                var n = this.layout,
                                    o = n.position,
                                    r = n.dimension,
                                    a = r.width - t + o.left,
                                    s = this.paper.rect(t, o.top, e < 0 ? a : e, r.height);
                                return s.attr({ fill: i.color, opacity: i.opacity || 1, stroke: i.color }), this.additionalPlotSet.push(s), s;
                            }),
                            (t.prototype._createOptionalLineValueRange = function (t) {
                                var e = t.range || [t.value];
                                return (
                                    v.default.isDatetimeType(this.xAxisTypeOption) &&
                                    (e = e.map(function (t) {
                                        return new Date(t).getTime() || t;
                                    })),
                                        e
                                );
                            }),
                            (t.prototype._createOptionalLinePosition = function (t, e, i) {
                                var n = (i - t.dataMin) / t.distance,
                                    o = n * e;
                                return 1 === n && (o -= 1), o < 0 && (o = null), o;
                            }),
                            (t.prototype._calculateXAxisLabelDistance = function (t, e) {
                                var i = e.sizeRatio,
                                    n = e.tickCount,
                                    o = e.remainLastBlockInterval,
                                    r = x.default.makeTickPixelPositions(t * (void 0 === i ? 1 : i), n, 0, o ? t : 0);
                                return r[1] - r[0];
                            }),
                            (t.prototype._createOptionalLinePositionWhenLabelAxis = function (t, e, i) {
                                var n = this.dataProcessor,
                                    o = v.default.isLineTypeChart(n.chartType, n.seriesTypes) && e.options.pointOnColumn,
                                    r = n.findCategoryIndex(i),
                                    a = this._calculateXAxisLabelDistance(t, e) / 2,
                                    s = null,
                                    u = void 0;
                                if (!(0, p.default)(r)) {
                                    var l = n.getCategoryCount();
                                    (s = (u = 0 === r ? 0 : r / (o ? l : l - 1)) * t), o && (s += a);
                                }
                                return 1 === u && (s -= 1), s;
                            }),
                            (t.prototype._createOptionalLinePositionMap = function (t, e, i) {
                                var n = this.dataProcessor.getCategories(),
                                    o = n.length,
                                    r = this.dataProcessor.valuesMap.linex,
                                    a = this._createOptionalLineValueRange(t),
                                    s = void 0,
                                    u = void 0;
                                if (
                                    (e.isLabelAxis
                                        ? ((s = this._createOptionalLinePositionWhenLabelAxis(i, e, a[0])), (u = this._createOptionalLinePositionWhenLabelAxis(i, e, a[1])))
                                        : ((s = this._createOptionalLinePosition(e, i, a[0])), (u = a[1] && this._createOptionalLinePosition(e, i, a[1]))),
                                        (0, p.default)(s))
                                ) {
                                    var l = o ? n[0] : r[0];
                                    s = this._isBeforeVisibleValue(a[0], l) ? 0 : -1;
                                }
                                if ((0, p.default)(u) || u > i) {
                                    var h = o ? n[o - 1] : r[r.length - 1];
                                    u = this._isAfterVisibleValue(a[1], h) ? i : -1;
                                }
                                return { start: s, end: u };
                            }),
                            (t.prototype._isBeforeVisibleValue = function (t, e) {
                                var i = this.dataProcessor;
                                if (!(0, h.default)(t)) return !1;
                                if (v.default.isDatetimeType(this.xAxisTypeOption)) return t < e;
                                var n = i.findAbsoluteCategoryIndex(t),
                                    o = i.findAbsoluteCategoryIndex(e);
                                return n >= 0 && n < o;
                            }),
                            (t.prototype._isAfterVisibleValue = function (t, e) {
                                var i = this.dataProcessor;
                                if (!(0, h.default)(t)) return !1;
                                if (v.default.isDatetimeType(this.xAxisTypeOption)) return t > e;
                                var n = i.findAbsoluteCategoryIndex(t),
                                    o = i.findAbsoluteCategoryIndex(e);
                                return n >= 0 && n > o;
                            }),
                            (t.prototype._renderOptionalLine = function (t, e, i, n) {
                                var o = this._createOptionalLinePositionMap(n, t, e),
                                    r = void 0;
                                return o.start >= 0 && o.start <= e && ((i.width = 1), (i.color = n.color || "transparent"), (i.opacity = n.opacity), (r = this._renderLine(o.start + this.layout.position.left, i))), r;
                            }),
                            (t.prototype._makeOptionalBand = function (t, e, i, n) {
                                var r = this,
                                    a = n.range;
                                a && a.length && this._makeRangeTo2DArray(n);
                                var s = n.range.map(function (i) {
                                    return r._createOptionalLinePositionMap({ range: i }, t, e);
                                });
                                return (
                                    n.mergeOverlappingRanges && (s.sort(o), (s = this._mergeOverlappingPositionMaps(s))),
                                        s.map(function (t) {
                                            var o = t.start >= 0 && t.start <= e,
                                                a = void 0;
                                            if (o && t.end >= 0) {
                                                (i.color = n.color || "transparent"), (i.opacity = n.opacity);
                                                var s = t.end - t.start;
                                                a = r._renderBand(t.start + r.layout.position.left, s, i);
                                            }
                                            return a;
                                        }, this)
                                );
                            }),
                            (t.prototype._makeOptionalLines = function (t, e) {
                                var i = e.width,
                                    n = e.height,
                                    o = this.axisDataMap.xAxis,
                                    r = this._makeVerticalLineTemplateParams({ height: n + "px" }),
                                    a = this._renderOptionalLine.bind(this, o, i, r);
                                return t.map(a);
                            }),
                            (t.prototype._makeOptionalBands = function (t, e) {
                                var i = e.width,
                                    n = e.height,
                                    o = this.axisDataMap.xAxis,
                                    r = this._makeVerticalLineTemplateParams({ height: n + "px" }),
                                    a = this._makeOptionalBand.bind(this, o, i, r);
                                return t.map(a);
                            }),
                            (t.prototype._renderOptionalLines = function (t, e) {
                                (this.optionalBands = this._makeOptionalBands(this.options.bands, e)), (this.optionalLines = this._makeOptionalLines(this.options.lines, e));
                            }),
                            (t.prototype._renderVerticalLines = function (t) {
                                var e = this,
                                    i = t.width,
                                    n = this._makeHorizontalPositions(i),
                                    o = this.layout,
                                    r = this.theme.lineColor,
                                    a = o.position,
                                    s = a.top,
                                    u = a.left;
                                n.forEach(function (t) {
                                    var i = "M" + (t + u) + "," + s + "V" + (s + o.dimension.height),
                                        n = e.paper.path(i);
                                    n.attr({ stroke: r, "stroke-width": 1, "stroke-opacity": 0.05 }), e.plotSet.push(n);
                                });
                            }),
                            (t.prototype._renderHorizontalLines = function (t) {
                                var e = this,
                                    i = t.height,
                                    n = this._makeVerticalPositions(i),
                                    o = this.layout,
                                    r = this.theme.lineColor,
                                    a = o.position,
                                    s = a.left,
                                    u = a.top,
                                    l = n.length > 1 ? n[1] - n[0] : 0;
                                n.forEach(function (t, i) {
                                    var n = "M" + s + "," + (l * i + u) + "H" + (s + o.dimension.width),
                                        a = e.paper.path(n);
                                    a.attr({ stroke: r, "stroke-width": 1, "stroke-opacity": 0.05 }), e.plotSet.push(a);
                                });
                            }),
                            (t.prototype._renderPlotLines = function (t, e) {
                                this.options.hideLine || (this._renderVerticalLines(e), this._renderHorizontalLines(e));
                            }),
                            (t.prototype._makeVerticalPositions = function (t) {
                                var e = this.axisDataMap,
                                    i = e.yAxis || e.rightYAxis,
                                    n = x.default.makeTickPixelPositions(t, i.validTickCount);
                                return n.shift(), n;
                            }),
                            (t.prototype._makeDividedPlotPositions = function (t, e) {
                                var i = this.dimensionMap.yAxis.width;
                                e = parseInt(e / 2, 10) + 1;
                                var n = Math.round((t -= i) / 2),
                                    o = t - n,
                                    r = x.default.makeTickPixelPositions(n, e),
                                    a = x.default.makeTickPixelPositions(o, e, n + i);
                                return r.pop(), a.shift(), r.concat(a);
                            }),
                            (t.prototype._makeHorizontalPositions = function (t) {
                                var e = this.axisDataMap.xAxis.validTickCount,
                                    i = void 0;
                                return this.options.divided ? (i = this._makeDividedPlotPositions(t, e)) : (i = x.default.makeTickPixelPositions(t, e)).shift(), i;
                            }),
                            (t.prototype.addPlotLine = function (t) {
                                this.options.lines.push(t), this.rerender();
                            }),
                            (t.prototype.addPlotBand = function (t) {
                                this.options.bands.push(t), this.rerender();
                            }),
                            (t.prototype.removePlotLine = function (t) {
                                (this.options.lines = this.options.lines.filter(function (e) {
                                    return e.id !== t;
                                })),
                                    this.rerender();
                            }),
                            (t.prototype.removePlotBand = function (t) {
                                (this.options.bands = this.options.bands.filter(function (e) {
                                    return e.id !== t;
                                })),
                                    this.rerender();
                            }),
                            (t.prototype.animateForAddingData = function (t) {
                                var e = this,
                                    i = this.options.lines,
                                    n = this.options.bands;
                                this.dataProcessor.isCoordinateType() ||
                                (t.shifting &&
                                    (this._animateItemForAddingData(this.optionalLines, t, function (t) {
                                        i.splice(t, 1);
                                    }),
                                        this.optionalBands.forEach(function (i, o) {
                                            e._animateItemForAddingData(i, t, function (t) {
                                                n[o].range.splice(t, 1);
                                            });
                                        })));
                            }),
                            (t.prototype._animateItemForAddingData = function (t, e, i) {
                                var n = this;
                                t.forEach(function (t, o) {
                                    t.getBBox().x - e.tickSize < n.layout.position.left
                                        ? t.animate({ transform: "T-" + e.tickSize + ",0", opacity: 0 }, 300, "linear", function () {
                                            i(o), t.remove();
                                        })
                                        : t.animate({ transform: "T-" + e.tickSize + ",0" }, 300);
                                });
                            }),
                            (t.prototype._makeRangeTo2DArray = function (t) {
                                var e = t.range;
                                e && (0, u.default)(e) && (0 === e.length || !(0, u.default)(e[0])) && (t.range = [e]);
                            }),
                            (t.prototype._mergeOverlappingPositionMaps = function (t) {
                                var e = t.length,
                                    i = void 0,
                                    n = void 0;
                                e && (n = a((i = [t[0]]), 1)[0]);
                                for (var o = 1; o < e; o += 1) {
                                    var r = t[o];
                                    r.start <= n.end ? (n.end = Math.max(r.end, n.end)) : (i.push(r), (n = r));
                                }
                                return i;
                            }),
                            t
                    );
                })();
            (r.componentType = "plot"), (r.Plot = T);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                var e = t.chartOptions.chart || { title: {} },
                    i = null;
                return e.title && e.title.text && ((t.text = e.title.text), (t.offset = e.title.offset), (t.align = e.title.align), (i = new l(t))), i;
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(154),
                a = n(r),
                s = i(182),
                u = n(s),
                l = (function () {
                    function t(e) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.theme = e.theme || {}),
                            (this.titleText = e.text),
                            (this.offset = e.offset),
                            (this.align = e.align),
                            (this.graphRenderer = u.default.get(a.default.COMPONENT_TYPE_RAPHAEL, "title")),
                            (this.drawingType = a.default.COMPONENT_TYPE_RAPHAEL);
                    }
                    return (
                        (t.prototype.render = function (t) {
                            this.titleSet = this._renderTitleArea(t);
                        }),
                            (t.prototype.resize = function (t) {
                                var e = t.dimensionMap,
                                    i = e.legend ? e.legend.width : 0,
                                    n = e.series.width + i;
                                this.graphRenderer.resize(n, this.titleSet);
                            }),
                            (t.prototype.rerender = function (t) {
                                this.titleSet.remove(), this.render(t);
                            }),
                            (t.prototype._renderTitleArea = function (t) {
                                var e = t.paper,
                                    i = t.dimensionMap,
                                    n = this._calculateForTitleAreaWidth(i);
                                return this.graphRenderer.render({ paper: e, titleText: this.titleText, offset: this.offset, theme: this.theme, align: this.align, chartTitleAreaWidth: n });
                            }),
                            (t.prototype._calculateForTitleAreaWidth = function (t) {
                                var e = t.chartExportMenu,
                                    i = t.chart,
                                    n = e ? 2 * e.width : 0;
                                return i.width - n;
                            }),
                            t
                    );
                })();
            (o.componentType = "title"), (o.Title = l);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                return new y(t);
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(205),
                a = n(r),
                s = i(173),
                u = n(s),
                l = i(154),
                h = n(l),
                c = i(182),
                p = n(c),
                d = h.default.COMPONENT_TYPE_RAPHAEL,
                f = h.default.RADIAL_PLOT_PADDING,
                g = h.default.RADIAL_MARGIN_FOR_CATEGORY,
                m = h.default.RADIAL_CATEGORY_PADDING,
                y = (function () {
                    function t(e) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.className = "tui-chart-plot-area"),
                            (this.options = Object.assign({ type: "spiderweb" }, e.options)),
                            (this.theme = e.theme || {}),
                            (this.graphRenderer = p.default.get(d, "radialPlot")),
                            (this.drawingType = d);
                    }
                    return (
                        (t.prototype._renderPlotArea = function (t, e, i, n) {
                            var o = { paper: t, layout: e, plotPositions: i, labelData: n, theme: this.theme, options: this.options };
                            return this.graphRenderer.render(o);
                        }),
                            (t.prototype._makePositions = function (t, e) {
                                var i = e.dimension,
                                    n = e.position,
                                    o = n.left,
                                    r = n.top,
                                    s = i.width,
                                    l = i.height;
                                (s = s - f - g), (l = l - f - g);
                                var h = u.default.sum([s / 2, f / 2, g / 2, o]),
                                    c = l / 2 - f / 2 - g / 2 - r,
                                    p = t.yAxis.tickCount;
                                return (function t(e) {
                                    for (var i = e.width, n = e.height, o = e.centerX, r = e.centerY, s = e.angleStepCount, u = e.stepCount, l = 360 / s, h = [], c = Math.min(i, n) / 2 / (u - 1), p = 0; p < u; p += 1) {
                                        for (var d = [], f = r + c * p, g = 0; g < s; g += 1) {
                                            var m = a.default.rotatePointAroundOrigin(o, r, o, f, l * g);
                                            d.push({ left: m.x, top: n - m.y });
                                        }
                                        d.push(d[0]), (h[p] = d);
                                    }
                                    return h;
                                })({ width: s, height: l, centerX: h, centerY: c, angleStepCount: t.xAxis.labels.length, stepCount: p });
                            }),
                            (t.prototype._makeCategoryPositions = function (t, e) {
                                var i = e.dimension,
                                    n = e.position,
                                    o = n.left,
                                    r = n.top,
                                    s = i.width,
                                    l = i.height;
                                (s = s - f - m), (l = l - f - m);
                                var h = u.default.sum([s / 2, f / 2, m / 2, o]),
                                    c = l / 2 - f / 2 - m / 2 - r;
                                return (function t(e) {
                                    for (var i = e.width, n = e.height, o = e.centerX, r = e.centerY, s = e.angleStepCount, u = 360 / s, l = [], h = r + Math.min(n, i) / 2, c = 0; c < s; c += 1) {
                                        var p = 360 - u * c,
                                            d = a.default.rotatePointAroundOrigin(o, r, o, h, p),
                                            f = void 0;
                                        (f = p > 0 && p < 180 ? "end" : p > 180 && p < 360 ? "start" : "middle"), l.push({ left: d.x, top: n - d.y, anchor: f });
                                    }
                                    return l;
                                })({ width: s, height: l, centerX: h, centerY: c, angleStepCount: t.xAxis.labels.length });
                            }),
                            (t.prototype._makeLabelData = function (t, e, i) {
                                for (var n = t.xAxis.labels, o = t.yAxis.labels, r = this._makeCategoryPositions(t, e), a = [], s = [], u = 0; u < n.length; u += 1) a.push({ text: n[u], position: r[u] });
                                for (var l = 0; l < o.length - 1; l += 1) s.push({ text: o[l], position: i[l][0] });
                                return { category: a, step: s };
                            }),
                            (t.prototype.render = function (t) {
                                var e = t.axisDataMap,
                                    i = t.layout,
                                    n = t.paper,
                                    o = this._makePositions(e, i),
                                    r = this._makeLabelData(e, i, o);
                                this.plotSet = this._renderPlotArea(n, i, o, r);
                            }),
                            (t.prototype.rerender = function (t) {
                                this.plotSet.remove(), this.render(t);
                            }),
                            (t.prototype.resize = function (t) {
                                this.rerender(t);
                            }),
                            t
                    );
                })();
            (o.componentType = "plot"), (o.RadialPlot = y);
        },
        function (t, e, i) {
            "use strict";
            function n(t, e) {
                return Math.cos(t * a.default.RAD) * e;
            }
            function o(t, e) {
                return Math.sin(t * a.default.RAD) * e;
            }
            e.__esModule = !0;
            var r,
                a = (r = i(154)) && r.__esModule ? r : { default: r };
            e.default = {
                rotatePointAroundOrigin: function t(e, i, n, o, r) {
                    var a = r * (Math.PI / 180),
                        s = (n - e) * Math.cos(a) - (o - i) * Math.sin(a),
                        u = (n - e) * Math.sin(a) + (o - i) * Math.cos(a);
                    return { x: (s += e), y: (u += i) };
                },
                calculateAdjacent: n,
                calculateRotatedHeight: function t(e, i, n) {
                    var r = o(e, i / 2),
                        s = o(a.default.ANGLE_90 - e, n / 2);
                    return 2 * (r + s);
                },
                calculateRotatedWidth: function t(e, i, o) {
                    var r = n(e, i / 2),
                        s = n(a.default.ANGLE_90 - e, o / 2);
                    return 2 * (r + s);
                },
                calculateOpposite: o,
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                var e = t.options.visible,
                    i = t.chartOptions.chart,
                    n = void 0 === i ? {} : i,
                    o = t.chartOptions.chartExportMenu,
                    r = null;
                return n.title && (t.chartTitle = n.title.text), o && o.filename && (t.exportFilename = o.filename), e && (r = new D(t)), r;
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(154),
                a = n(r),
                s = i(207),
                u = n(s),
                l = i(155),
                h = n(l),
                c = i(211),
                p = n(c),
                d = i(164),
                f = n(d),
                g = i(148),
                m = n(g),
                y = ["xls", "csv"],
                v = "menu-opened",
                $ = a.default.CLASS_NAME_CHART_EXPORT_MENU_BUTTON,
                x = a.default.CLASS_NAME_CHART_EXPORT_MENU,
                T = a.default.CLASS_NAME_CHART_EXPORT_MENU_HEAD,
                b = a.default.CLASS_NAME_CHART_EXPORT_MENU_BODY,
                _ = a.default.CLASS_NAME_CHART_EXPORT_MENU_ITEM,
                A = a.default.COMPONENT_TYPE_DOM,
                D = (function () {
                    function t(e) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.className = "tui-chart-chartExportMenu-area"),
                            (this.dataProcessor = e.dataProcessor),
                            (this.chartTitle = e.chartTitle || "tui-chart"),
                            (this.exportFilename = e.exportFilename || this.chartTitle),
                            (this.chartType = e.chartType),
                            (this.layout = null),
                            (this.chartExportMenuContainer = null),
                            (this.chartExportMenu = null),
                            (this.options = e.options),
                            (this.eventBus = e.eventBus),
                            (this.drawingType = A),
                            (this.theme = e.theme || null);
                    }
                    return (
                        (t.prototype._createChartExportMenuButton = function () {
                            var t = h.default.create("div", $);
                            return this.options.buttonClass && h.default.addClass(t, this.options.buttonClass), t;
                        }),
                            (t.prototype._renderChartExportMenuArea = function (t) {
                                var e = this._createChartExportMenuButton(),
                                    i = this.layout.dimension;
                                t.appendChild(e), m.default.renderDimension(t, i), m.default.renderPosition(t, this.layout.position);
                            }),
                            (t.prototype._renderChartExportMenu = function (t) {
                                var e = this.dataProcessor.seriesDataModelMap,
                                    i = this.isDataDownloadAvailable(e),
                                    n = u.default.isDownloadSupported,
                                    o = u.default.isImageExtension,
                                    r = u.default.isImageDownloadAvailable,
                                    a = h.default.create("ul", x),
                                    s = h.default.create("li", T),
                                    l = h.default.create("li", b),
                                    c = a.style,
                                    p = this.theme,
                                    d = p.borderWidth,
                                    f = p.borderRadius,
                                    g = p.backgroundColor,
                                    m = p.color,
                                    v = [];
                                n && (i || r)
                                    ? (v = y.map(function (t) {
                                        var e = void 0;
                                        return ((!o(t) && i) || (o(t) && r)) && (((e = h.default.create("li", _)).id = t), (e.innerHTML = t)), e;
                                    }))
                                    : ((c.width = "200px"), (v[0] = h.default.create("li", _)), (v[0].innerHTML = "Browser does not support client-side download.")),
                                this.theme && (d && (c.borderWidth = d), f && (c.borderRadius = f), g && (c.backgroundColor = g), m && (c.color = m)),
                                this.options.menuClass && h.default.addClass(a, this.options.menuClass),
                                    (s.innerHTML = "Export to"),
                                    h.default.append(l, v),
                                    h.default.append(a, s),
                                    h.default.append(a, l),
                                    (this.chartExportMenu = a),
                                    h.default.append(t, a);
                            }),
                            (t.prototype._setDataForRendering = function (t) {
                                t && ((this.layout = t.layout), (this.dimensionMap = t.dimensionMap), (this.axisDataMap = t.axisDataMap));
                            }),
                            (t.prototype.render = function (t) {
                                var e = null;
                                return (
                                    u.default.isDownloadSupported &&
                                    ((e = this.container = t.paper),
                                        h.default.addClass(e, this.className),
                                        this._setDataForRendering(t),
                                        this._renderChartExportMenuArea(e),
                                        this._renderChartExportMenu(e),
                                        (this.chartExportMenuContainer = e),
                                        this._attachEvent()),
                                        e
                                );
                            }),
                            (t.prototype.rerender = function () {
                                this._hideChartExportMenu();
                            }),
                            (t.prototype.resize = function () {}),
                            (t.prototype._showChartExportMenu = function () {
                                h.default.addClass(this.chartExportMenuContainer, v), (this.chartExportMenu.style.display = "block");
                            }),
                            (t.prototype._hideChartExportMenu = function () {
                                this.chartExportMenuContainer && (h.default.removeClass(this.chartExportMenuContainer, v), (this.chartExportMenu.style.display = "none"));
                            }),
                            (t.prototype._onClick = function (t) {
                                var e = t.target || t.srcElement;
                                if (h.default.hasClass(e, _)) {
                                    if (e.id) {
                                        var i = this._getMainSvgElemenmt(this.container.parentNode);
                                        this.eventBus.fire("beforeImageDownload"), u.default.exportChart(this.exportFilename, e.id, this.dataProcessor.rawData, i, this.options), this.eventBus.fire("afterImageDownload");
                                    }
                                    this._hideChartExportMenu();
                                } else h.default.hasClass(e, $) && this.chartExportMenuContainer === e.parentNode && !h.default.hasClass(this.chartExportMenuContainer, v) ? this._showChartExportMenu() : this._hideChartExportMenu();
                            }),
                            (t.prototype._getMainSvgElemenmt = function (t) {
                                var e = Array.from(t.getElementsByTagName("svg")),
                                    i = void 0;
                                return (
                                    e.forEach(function (e) {
                                        t === e.parentNode && (i = e);
                                    }),
                                        i
                                );
                            }),
                            (t.prototype.isDataDownloadAvailable = function (t) {
                                var e = !0;
                                return (
                                    f.default.isTreemapChart(this.chartType)
                                        ? (e = !1)
                                        : Object.values(t).forEach(function (t) {
                                            return t.isCoordinateType && (e = !1), !1;
                                        }),
                                        e
                                );
                            }),
                            (t.prototype._attachEvent = function () {
                                p.default.on(this.chartExportMenuContainer.parentNode, "click", this._onClick, this);
                            }),
                            (t.prototype._detachEvent = function () {
                                p.default.off(this.chartExportMenuContainer.parentNode, "click", this._onClick);
                            }),
                            t
                    );
                })();
            o.componentType = "chartExportMenu";
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                return p.default.any(m.default.getExtensions(), function (e) {
                    return t === e;
                });
            }
            e.__esModule = !0;
            var r = i(153),
                a = n(r),
                s = i(146),
                u = n(s),
                l = i(156),
                h = n(l),
                c = i(158),
                p = n(c),
                d = i(208),
                f = n(d),
                g = i(210),
                m = n(g),
                y = a.default.msie && (10 === a.default.version || 11 === a.default.version),
                v = !y || (y && document.createElement("canvas").getContext("2d").drawSvg),
                $ = (0, u.default)(document.createElement("a").download),
                x = window.Blob && window.navigator.msSaveOrOpenBlob;
            e.default = {
                exportChart: function t(e, i, n, r, a) {
                    var s,
                        u = a && a[i] ? a[i] : {};
                    o(i)
                        ? m.default.downloadImage(e, i, r)
                        : ((s = i),
                        p.default.any(f.default.getExtensions(), function (t) {
                            return s === t;
                        }) && f.default.downloadData(e, i, n, u));
                },
                isDownloadSupported: $ || x,
                isImageDownloadAvailable: v,
                isImageExtension: o,
                addExtension: function (t, e) {
                    var i = e && (0, h.default)(e),
                        n = void 0,
                        o = void 0;
                    "data" === t ? (n = f.default) : "image" === t && (n = m.default), n && i && (o = n.getExtensions()).push(e);
                },
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                if (Array.isArray(t)) {
                    for (var e = 0, i = Array(t.length); e < t.length; e++) i[e] = t[e];
                    return i;
                }
                return Array.from(t);
            }
            function r(t) {
                var e = [],
                    i = t.categories && (0, $.default)(t.categories.x),
                    n = t.series && (0, $.default)(t.series.bullet),
                    r = !1;
                if (t) {
                    var a = void 0;
                    if ((i ? (r = h(t)) : n ? (r = l(t)) : t.categories && (a = t.categories), r)) return r;
                    e.push([""].concat(a)),
                        Object.values(t.series || {}).forEach(function (t) {
                            t.forEach(function (t) {
                                var i = (0, y.default)(t.data) ? t.data : [t.data];
                                e.push([t.name].concat(o(i)));
                            });
                        });
                }
                return e;
            }
            function a(t, e) {
                for (var i = ["", S.default.BULLET_TYPE_ACTUAL], n = 0; n < t; n += 1) i.push(S.default.BULLET_TYPE_RANGE + n);
                for (var o = 0; o < e; o += 1) i.push(S.default.BULLET_TYPE_MARKER + o);
                return i;
            }
            function s(t, e) {
                for (var i = [], n = 0; n < e; n += 1) {
                    var o = "";
                    t && t[n] && (o = (t[n].length > 0 ? t[n][0] : "") + "~" + (t[n].length > 1 ? t[n][1] : "")), i.push(o);
                }
                return i;
            }
            function u(t, e) {
                for (var i = [], n = 0; n < e; n += 1) {
                    var o = t && t[n] ? t[n] : "";
                    i.push(o);
                }
                return i;
            }
            function l(t) {
                var e,
                    i,
                    n,
                    r = [],
                    l =
                        ((e = t.series.bullet),
                            (i = 0),
                            (n = 0),
                            (0, _.default)(e, function (t) {
                                (i = Math.max(i, t.ranges.length)), (n = Math.max(n, t.markers.length));
                            }),
                            { maxRangeCount: i, maxMarkerCount: n }),
                    h = l.maxRangeCount,
                    c = l.maxMarkerCount;
                return (
                    r.push(a(h, c)),
                        (0, _.default)(t.series.bullet, function (t) {
                            var e = s(t.ranges, h),
                                i = u(t.markers, c),
                                n = [t.name, t.data].concat(o(e), o(i));
                            r.push(n);
                        }),
                        r
                );
            }
            function h(t) {
                var e = [];
                return (
                    e.push([""].concat(o(t.categories.x))),
                        (0, _.default)(t.series, function (i) {
                            (0, _.default)(i, function (i, n) {
                                var r = [t.categories.y[n]].concat(o(i));
                                e.push(r);
                            });
                        }),
                        e
                );
            }
            function c(t) {
                var e, i;
                return M.default.oneLineTrim(
                    g,
                    ((e = t),
                        (i = "<table>"),
                        (0, _.default)(e, function (t, e) {
                            var n = 0 === e ? "th" : "td";
                            (i += "<tr>"),
                                (0, _.default)(t, function (t, o) {
                                    if(t == null){
                                        t = " ";
                                    }
                                    i += "<" + n + (0 !== e || 0 === o ? ' class="number"' : "") + ">" + t + "</" + n + ">";
                                }),
                                (i += "</tr>");
                        }),
                        (i += "</table>"))
                );
            }
            function p(t) {
                var e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
                    i = e.lineDelimiter,
                    n = void 0 === i ? "\n" : i,
                    o = e.itemDelimiter,
                    r = void 0 === o ? "," : o,
                    a = t.length - 1,
                    s = "";
                return (
                    (0, D.default)(t, function (t, e) {
                        var i = t.length - 1;
                        (0, D.default)(t, function (t, e) {
                            if(t == null){
                                t = " ";
                            }
                            (s += (0, T.default)(t) ? t : '"' + t + '"'), e < i && (s += r);
                        }),
                        e < a && (s += n);
                    }),
                        s
                );
            }
            e.__esModule = !0;
            var d,
                f,
                g = Object.freeze(
                    Object.defineProperties(
                        (d = [
                            '<html xmlns:o="urn:schemas-microsoft-com:office:office" \n        xmlns:x="urn:schemas-microsoft-com:office:excel" \n        xmlns="http://www.w3.org/TR/REC-html40">\n        <head>\n            <!--[if gte mso 9]>\n                <xml>\n                    <x:ExcelWorkbook>\n                        <x:ExcelWorksheets>\n                            <x:ExcelWorksheet>\n                                <x:Name>Ark1</x:Name>\n                                <x:WorksheetOptions>\n                                    <x:DisplayGridlines/>\n                                </x:WorksheetOptions>\n                            </x:ExcelWorksheet>\n                        </x:ExcelWorksheets>\n                        </x:ExcelWorkbook>\n                </xml>\n            <![endif]-->\n            <meta name=ProgId content=Excel.Sheet>\n            <meta charset=UTF-8>\n        </head>\n        <body>\n            ',
                            "\n        </body>\n        </html>",
                        ]),
                        {
                            raw: {
                                value: Object.freeze(
                                    (f = [
                                        '<html xmlns:o="urn:schemas-microsoft-com:office:office" \n        xmlns:x="urn:schemas-microsoft-com:office:excel" \n        xmlns="http://www.w3.org/TR/REC-html40">\n        <head>\n            <!--[if gte mso 9]>\n                <xml>\n                    <x:ExcelWorkbook>\n                        <x:ExcelWorksheets>\n                            <x:ExcelWorksheet>\n                                <x:Name>Ark1</x:Name>\n                                <x:WorksheetOptions>\n                                    <x:DisplayGridlines/>\n                                </x:WorksheetOptions>\n                            </x:ExcelWorksheet>\n                        </x:ExcelWorksheets>\n                        </x:ExcelWorkbook>\n                </xml>\n            <![endif]-->\n            <meta name=ProgId content=Excel.Sheet>\n            <meta charset=UTF-8>\n        </head>\n        <body>\n            ',
                                        "\n        </body>\n        </html>",
                                    ])
                                ),
                            },
                        }
                    )
                ),
                m = i(140),
                y = n(m),
                v = i(146),
                $ = n(v),
                x = i(150),
                T = n(x),
                b = i(139),
                _ = n(b),
                A = i(141),
                D = n(A),
                C = i(209),
                E = n(C),
                L = i(154),
                S = n(L),
                P = i(148),
                M = n(P),
                w = { xls: "data:application/vnd.ms-excel;base64,", csv: "data:text/csv;charset=utf-8,%EF%BB%BF" },
                k = { xls: c, csv: p },
                I = [].concat(o(S.default.DATA_EXTENSIONS)),
                R = {
                    downloadData: function (t, e, i, n) {
                        var o = r(i),
                            a = w[e].replace(/(data:|;base64,|,%EF%BB%BF)/g, ""),
                            s = k[e](o, n);
                        this._isNeedDataEncodeing() && ("csv" !== e && (s = window.btoa(unescape(encodeURIComponent(s)))), (s = w[e] + s)), E.default.execDownload(t, e, s, a);
                    },
                    _isNeedDataEncodeing: function () {
                        var t = (0, $.default)(document.createElement("a").download);
                        return !((window.Blob && window.navigator.msSaveOrOpenBlob) || !t);
                    },
                    getExtensions: function () {
                        return I;
                    },
                };
            Object.assign(R, {
                _makeCsvBodyWithRawData: p,
                _makeXlsBodyWithRawData: c,
                _get2DArrayFromRawData: r,
                _get2DArrayFromBulletRawData: l,
                _get2DArrayFromHeatmapRawData: h,
                _makeTCellsFromBulletRanges: s,
                _makeTCellsFromBulletMarkers: u,
                _makeTHeadForBullet: a,
            }),
                (e.default = R);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(146),
                r = n(o),
                a = i(156),
                s = n(a),
                u = i(158),
                l = n(u),
                h = i(154),
                c = n(h),
                p = {
                    downloadAttribute: function t(e, i, n) {
                        if (n) {
                            var o = document.createElement("a");
                            (o.href = n), (o.target = "_blank"), (o.download = e + "." + i), document.body.appendChild(o), o.click(), o.remove();
                        }
                    },
                    msSaveOrOpenBlob: function t(e, i, n, o) {
                        var r,
                            a = ((r = i),
                                l.default.any(c.default.IMAGE_EXTENSIONS, function (t) {
                                    return r === t;
                                }))
                                ? (function t(e) {
                                    for (var i = e.substr(0, e.indexOf(";base64,")).substr(e.indexOf(":") + 1), n = atob(e.substr(e.indexOf(",") + 1)), o = [], r = 0; r < n.length; r += 1024) {
                                        for (var a = n.slice(r, r + 1024), s = Array(a.length), u = 0; u < a.length; u += 1) s[u] = a.charCodeAt(u);
                                        var l = new window.Uint8Array(s);
                                        o.push(l);
                                    }
                                    return new Blob(o, { type: i });
                                })(n)
                                : new Blob([n], { type: o });
                        window.navigator.msSaveOrOpenBlob(a, e + "." + i);
                    },
                };
            e.default = {
                execDownload: function t(e, i, n, o) {
                    var a,
                        u,
                        l,
                        h = ((a = (0, r.default)(document.createElement("a").download)), (u = window.Blob && window.navigator.msSaveOrOpenBlob), (l = void 0), u ? (l = "msSaveOrOpenBlob") : a && (l = "downloadAttribute"), l);
                    h && (0, s.default)(n) && p[h](e, i, n, o);
                },
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(209),
                r = n(o),
                a = i(154),
                s = n(a),
                u = i(153),
                l = n(u),
                h = l.default.msie && (10 === l.default.version || 11 === l.default.version),
                c = window.URL || window.webkitURL || window,
                p = [].concat(
                    (function t(e) {
                        if (Array.isArray(e)) {
                            for (var i = 0, n = Array(e.length); i < e.length; i++) n[i] = e[i];
                            return n;
                        }
                        return Array.from(e);
                    })(s.default.IMAGE_EXTENSIONS)
                );
            e.default = {
                downloadImage: function (t, e, i) {
                    var n = void 0;
                    if ("svg" === i.tagName) {
                        var o,
                            a,
                            s,
                            u,
                            l,
                            p,
                            d,
                            f,
                            g,
                            m,
                            y,
                            v,
                            $,
                            x,
                            T,
                            b,
                            _,
                            A = i.parentNode,
                            D = ((a = (o = i).parentNode), (s = document.createElement("DIV")).appendChild(o), (u = s.innerHTML), a.appendChild(o), (s = null), (a = null), u);
                        ((n = document.createElement("canvas")).width = A.offsetWidth),
                            (n.height = A.offsetHeight),
                            h
                                ? ((l = n),
                                    (p = D),
                                    (d = t),
                                    (f = e),
                                    (g = l.getContext("2d")),
                                h &&
                                (p = (p = (p = (p = p.replace(/xmlns:NS1=""/, "")).replace(/NS1:xmlns:xlink="http:\/\/www\.w3\.org\/1999\/xlink"/, "")).replace(/xmlns="http:\/\/www\.w3\.org\/2000\/svg"/, "")).replace(
                                    /xmlns:xlink="http:\/\/www\.w3\.org\/1999\/xlink"/,
                                    ""
                                )),
                                    g.drawSvg(p, 0, 0),
                                    r.default.execDownload(d, f, l.toDataURL("image/" + f, 1)))
                                : ((m = n),
                                    (y = D),
                                    (v = t),
                                    ($ = e),
                                    (x = m.getContext("2d")),
                                    (T = new Blob([y], { type: "image/svg+xml" })),
                                    (b = c.createObjectURL(T)),
                                    ((_ = new Image()).onload = function () {
                                        x.drawImage(_, 0, 0, m.width, m.height), r.default.execDownload(v, $, m.toDataURL("image/" + $, 1)), c.revokeObjectURL(b);
                                    }),
                                    (_.src = b));
                    } else "canvas" === i.tagName && ((n = i), r.default.execDownload(t, e, n.toDataURL("image/" + e, 1)));
                },
                getExtensions: function () {
                    return p;
                },
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(156),
                r = n(o),
                a = i(139),
                s = n(a),
                u = {},
                l = {
                    _attachEvent: function (t, e, i, n) {
                        var o = void 0;
                        (o = n ? i.bind(n) : i), (u[e + i] = o), t.attachEvent("on" + e, o);
                    },
                    _addEventListener: function (t, e, i, n) {
                        var o = void 0;
                        (o = n ? i.bind(n) : i), (u[e + i] = o), t.addEventListener(e, o);
                    },
                    _bindEvent: function (t, e, i, n) {
                        var o = void 0;
                        "addEventListener" in t ? (o = this._addEventListener) : "attachEvent" in t && (o = this._attachEvent), (l._bindEvent = o), o(t, e, i, n);
                    },
                    on: function (t, e, i, n) {
                        var o = {};
                        (0, r.default)(e) ? (o[e] = i) : ((o = e), (n = i)),
                            (0, s.default)(o, function (e, i) {
                                l._bindEvent(t, i, e, n);
                            });
                    },
                    _detachEvent: function (t, e, i) {
                        u[e + i] && (t.detachEvent("on" + e, u[e + i]), delete u[e + i]);
                    },
                    _removeEventListener: function (t, e, i) {
                        t.removeEventListener(e, u[e + i]), delete u[e + i];
                    },
                    _unbindEvent: function (t, e, i) {
                        var n = void 0;
                        "removeEventListener" in t ? (n = l._removeEventListener) : "detachEvent" in t && (n = l._detachEvent), (l._unbindEvent = n), n(t, e, i);
                    },
                    off: function (t, e, i) {
                        var n = {};
                        (0, r.default)(e) ? (n[e] = i) : (n = e),
                            (0, s.default)(n, function (e, i) {
                                l._unbindEvent(t, i, e);
                            });
                    },
                };
            e.default = l;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                var e = t.options,
                    i = t.dataProcessor.seriesTypes,
                    n = t.chartOptions.chartType,
                    o = !!(0, u.default)(e.visible) || e.visible,
                    r = null;
                return o && ((t.seriesTypes = i), (t.chartType = n), (r = new E(t))), r;
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(137),
                a = n(r),
                s = i(145),
                u = n(s),
                l = i(213),
                h = n(l),
                c = i(198),
                p = n(c),
                d = i(154),
                f = n(d),
                g = i(216),
                m = n(g),
                y = i(182),
                v = n(y),
                $ = i(164),
                x = n($),
                T = i(143),
                b = n(T),
                _ = f.default.LEGEND_ICON_HEIGHT,
                A = f.default.LINE_MARGIN_TOP,
                D = f.default.LEGEND_AREA_H_PADDING,
                C = f.default.PUBLIC_EVENT_PREFIX,
                E = (function () {
                    function t(e) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.theme = e.theme),
                            (this.options = e.options || {}),
                            (this.chartType = e.chartType),
                            (this.seriesTypes = e.seriesTypes || [this.chartType]),
                            (this.eventBus = e.eventBus),
                            (this.className = "tui-chart-legend-area"),
                            (this.dataProcessor = e.dataProcessor),
                            (this.legendModel = new m.default({ theme: this.theme, labels: e.dataProcessor.getLegendLabels(), legendData: e.dataProcessor.getLegendData(), seriesTypes: this.seriesTypes, chartType: this.chartType })),
                            (this.layout = null),
                            (this.graphRenderer = v.default.get(f.default.COMPONENT_TYPE_RAPHAEL, "legend")),
                            (this.paper = null),
                            (this.drawingType = f.default.COMPONENT_TYPE_RAPHAEL);
                    }
                    return (
                        (t.prototype.presetForChangeData = function () {
                            var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : this.theme;
                            (this.theme = t),
                                (this.legendModel = new m.default({
                                    theme: this.theme,
                                    labels: this.dataProcessor.getLegendLabels(),
                                    legendData: this.dataProcessor.getLegendData(),
                                    seriesTypes: this.seriesTypes,
                                    chartType: this.chartType,
                                }));
                        }),
                            (t.prototype._setDataForRendering = function (t) {
                                t && ((this.layout = t.layout), (this.paper = t.paper));
                            }),
                            (t.prototype._render = function (t) {
                                this._setDataForRendering(t);
                                var e = this._renderLegendArea(t.paper),
                                    i = e.legendSet,
                                    n = e.paginationElem;
                                (this.legendSet = i), (this.paginationArea = n);
                            }),
                            (t.prototype.render = function (t) {
                                this._render(t), this._listenEvents();
                            }),
                            (t.prototype.rerender = function (t) {
                                this.legendSet.remove(),
                                    this.paginationArea.forEach(function (t) {
                                        return t.remove();
                                    }),
                                    this._render(t);
                            }),
                            (t.prototype.resize = function (t) {
                                this.rerender(t);
                            }),
                            (t.prototype._getLegendRenderingData = function (t, e, i) {
                                var n = this,
                                    o = (x.default.isBarTypeChart(this.chartType) || x.default.isBoxplotChart(this.chartType)) && this.dataProcessor.options.series.colorByPoint,
                                    r = t.map(function (t, r) {
                                        var a = !1 === n.options.showCheckbox ? null : { checked: n.legendModel.isCheckedIndex(r) },
                                            s = t.label;
                                        return (
                                            n.options.maxWidth && (s = b.default.getEllipsisText(s, n.options.maxWidth, n.theme.label)),
                                                { checkbox: a, iconType: t.chartType || "rect", colorByPoint: o, index: r, theme: t.theme, label: s, labelHeight: e, labelWidth: i[r], isUnselected: n.legendModel.isUnselectedIndex(r) }
                                        );
                                    });
                                return (
                                    (x.default.isAreaChart(this.chartType) || x.default.isColumnChart(this.chartType)) &&
                                    (0, h.default)(this.dataProcessor.options.series.stack) &&
                                    "top" !== this.options.align &&
                                    "bottom" !== this.options.align &&
                                    r.reverse(),
                                        r
                                );
                            }),
                            (t.prototype._renderLegendArea = function (t) {
                                var e = this.legendModel.getData(),
                                    i = this.graphRenderer,
                                    n = x.default.isHorizontalLegend(this.options.align),
                                    o = this.layout.position,
                                    r = i.makeLabelWidths(e, this.theme.label, this.options.maxWidth),
                                    a = e[0] ? e[0].theme : {},
                                    s = i.getRenderedLabelHeight("DEFAULT_TEXT", a) - 1,
                                    u = r.length,
                                    l = o.top,
                                    h = o.left;
                                return (
                                    x.default.isLegendAlignLeft(this.options.align) || (h += D),
                                        i.render({
                                            paper: t,
                                            legendData: this._getLegendRenderingData(e, s, r),
                                            isHorizontal: n,
                                            position: { left: h, top: l },
                                            dimension: { height: (A + Math.max(_, s)) * (n ? 1 : u), width: this.layout.dimension.width },
                                            labelTheme: this.theme.label,
                                            labelWidths: r,
                                            eventBus: this.eventBus,
                                        })
                                );
                            }),
                            (t.prototype._fireChangeCheckedLegendsEvent = function () {
                                this.eventBus.fire("changeCheckedLegends", this.legendModel.getCheckedIndexes());
                            }),
                            (t.prototype._fireChangeCheckedLegendsPublicEvent = function () {
                                this.eventBus.fire(C + "changeCheckedLegends", this.legendModel.getCheckedIndexes());
                            }),
                            (t.prototype._fireSelectLegendEvent = function (t) {
                                var e = this.legendModel.getSelectedIndex(),
                                    i = (0, a.default)(e) ? e : t.seriesIndex;
                                this.eventBus.fire("selectLegend", t.chartType, i);
                            }),
                            (t.prototype._fireSelectLegendPublicEvent = function (t) {
                                var e = t.label,
                                    i = t.index,
                                    n = t.chartType;
                                this.eventBus.fire(C + "selectLegend", { legend: e, chartType: n, index: i });
                            }),
                            (t.prototype._selectLegend = function (t) {
                                var e = this.legendModel.getDatum(t);
                                this.legendModel.toggleSelectedIndex(t),
                                (0, a.default)(this.legendModel.getSelectedIndex()) || this.legendModel.isCheckedSelectedIndex() || (this.legendModel.checkSelectedIndex(), this._fireChangeCheckedLegendsEvent()),
                                    (this.dataProcessor.selectLegendIndex = this.legendModel.getSelectedIndex()),
                                    this.graphRenderer.selectLegend(this.dataProcessor.selectLegendIndex, this.legendSet),
                                    this._fireSelectLegendEvent(e),
                                    this._fireSelectLegendPublicEvent(e);
                            }),
                            (t.prototype._getCheckedIndexes = function () {
                                var t = [];
                                return (
                                    this.legendModel.checkedWholeIndexes.forEach(function (e, i) {
                                        e && t.push(i);
                                    }),
                                        t
                                );
                            }),
                            (t.prototype.getCheckedIndexes = function () {
                                return this.legendModel.getCheckedIndexes();
                            }),
                            (t.prototype._checkLegend = function () {
                                var t = this.legendModel.getSelectedDatum();
                                this.legendModel.isCheckedSelectedIndex() || this.legendModel.updateSelectedIndex(null), this._fireChangeCheckedLegendsEvent(), this._fireChangeCheckedLegendsPublicEvent(), t && this._fireSelectLegendEvent(t);
                            }),
                            (t.prototype._checkboxClick = function (t) {
                                this.legendModel.toggleCheckedIndex(t);
                                var e = this._getCheckedIndexes();
                                e.length > 0 ? (this.legendModel.updateCheckedLegendsWith(e), this._checkLegend()) : this.legendModel.toggleCheckedIndex(t);
                            }),
                            (t.prototype._labelClick = function (t) {
                                this._selectLegend(t);
                            }),
                            (t.prototype._listenEvents = function () {
                                this.eventBus.on("checkboxClicked", this._checkboxClick, this), this.eventBus.on("labelClicked", this._labelClick, this);
                            }),
                            t
                    );
                })();
            p.default.mixin(E), (o.componentType = "legend"), (o.Legend = E);
        },
        function (t, e, i) {
            "use strict";
            var n = i(214);
            t.exports = function t(e) {
                return !n(e);
            };
        },
        function (t, e, i) {
            "use strict";
            var n = i(156),
                o = i(146),
                r = i(140),
                a = i(215),
                s = i(138),
                u = i(187);
            t.exports = function t(e) {
                var i;
                return (
                    !(o(e) && (!n((i = e)) || "" !== i)) ||
                    (r(e) || a(e)
                        ? 0 === e.length
                        : !(s(e) && !u(e)) ||
                        !(function t(e) {
                            var i;
                            for (i in e) if (e.hasOwnProperty(i)) return !0;
                            return !1;
                        })(e))
                );
            };
        },
        function (t, e, i) {
            "use strict";
            var n = i(146);
            t.exports = function t(e) {
                return n(e) && ("[object Arguments]" === Object.prototype.toString.call(e) || !!e.callee);
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(137),
                r = n(o),
                a = i(145),
                s = n(a),
                u = (function () {
                    function t(e) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.theme = e.theme),
                            (this.labels = e.labels),
                            (this.legendData = e.legendData),
                            (this.seriesTypes = e.seriesTypes || []),
                            (this.chartType = e.chartType),
                            (this.data = null),
                            (this.selectedIndex = null),
                            (this.checkedIndexesMap = {}),
                            (this.checkedWholeIndexes = []),
                            this._setData(),
                            this._initCheckedIndexes();
                    }
                    return (
                        (t.prototype._initCheckedIndexes = function () {
                            var t = this,
                                e = [];
                            this.legendData.forEach(function (i, n) {
                                var o = i.visible;
                                o && e.push(n), (t.checkedWholeIndexes[n] = o);
                            }),
                                this.updateCheckedLegendsWith(e);
                        }),
                            (t.prototype._setThemeToLegendData = function (t, e, i) {
                                var n = e.colors,
                                    o = e.borderColor,
                                    r = 0;
                                t.forEach(function (t, e) {
                                    var a = { color: n[e] };
                                    o && (a.borderColor = o), (t.theme = a), (t.index = e), i && (0, s.default)(i[e]) ? (t.seriesIndex = -1) : ((t.seriesIndex = r), (r += 1));
                                });
                            }),
                            (t.prototype._setData = function () {
                                var t = this,
                                    e = this.theme,
                                    i = this.chartType,
                                    n = this.seriesTypes,
                                    o = this.legendData,
                                    r = this.checkedIndexesMap,
                                    a = void 0;
                                if (!n || n.length < 2) this._setThemeToLegendData(o, e[i], r[i]), (a = o);
                                else {
                                    var s,
                                        u = 0,
                                        l = n.map(function (i) {
                                            var n = u + t.labels[i].length,
                                                a = o.slice(u, n),
                                                s = r[i];
                                            return (u = n), t._setThemeToLegendData(a, e[i], s), a;
                                        });
                                    a = (s = []).concat.apply(
                                        s,
                                        (function t(e) {
                                            if (Array.isArray(e)) {
                                                for (var i = 0, n = Array(e.length); i < e.length; i++) n[i] = e[i];
                                                return n;
                                            }
                                            return Array.from(e);
                                        })(l)
                                    );
                                }
                                this.data = a;
                            }),
                            (t.prototype.getData = function () {
                                return this.data;
                            }),
                            (t.prototype.getDatum = function (t) {
                                return this.data[t];
                            }),
                            (t.prototype.getDatumByLabel = function (t) {
                                var e = null;
                                return (
                                    this.data.forEach(function (i) {
                                        return i.label === t && (e = i), !e;
                                    }),
                                        e
                                );
                            }),
                            (t.prototype.getSelectedDatum = function () {
                                return this.getDatum(this.selectedIndex);
                            }),
                            (t.prototype.updateSelectedIndex = function (t) {
                                this.selectedIndex = t;
                            }),
                            (t.prototype.toggleSelectedIndex = function (t) {
                                var e = void 0;
                                (e = this.selectedIndex === t ? null : t), this.updateSelectedIndex(e);
                            }),
                            (t.prototype.getSelectedIndex = function () {
                                return this.selectedIndex;
                            }),
                            (t.prototype.isUnselectedIndex = function (t) {
                                return !(0, r.default)(this.selectedIndex) && this.selectedIndex !== t;
                            }),
                            (t.prototype.isCheckedSelectedIndex = function () {
                                return this.isCheckedIndex(this.selectedIndex);
                            }),
                            (t.prototype.toggleCheckedIndex = function (t) {
                                this.checkedWholeIndexes[t] = !this.checkedWholeIndexes[t];
                            }),
                            (t.prototype._updateCheckedIndex = function (t) {
                                this.checkedWholeIndexes[t] = !0;
                            }),
                            (t.prototype.isCheckedIndex = function (t) {
                                return !!this.checkedWholeIndexes[t];
                            }),
                            (t.prototype._addSendingDatum = function (t) {
                                var e = this.getDatum(t),
                                    i = e.chartType,
                                    n = e.index;
                                this.checkedIndexesMap[i] || (this.checkedIndexesMap[i] = Array(this.labels[i].length).fill(!1)), (this.checkedIndexesMap[i][n] = !0);
                            }),
                            (t.prototype.checkSelectedIndex = function () {
                                this._updateCheckedIndex(this.selectedIndex), this._addSendingDatum(this.selectedIndex), this._setData();
                            }),
                            (t.prototype.getCheckedIndexes = function () {
                                var t = this;
                                return Object.keys(this.checkedIndexesMap).reduce(function (e, i) {
                                    return (
                                        (e[i] = Array.from(t.checkedIndexesMap[i], function (t) {
                                            return !!t;
                                        })),
                                            e
                                    );
                                }, {});
                            }),
                            (t.prototype._resetCheckedData = function () {
                                (this.checkedWholeIndexes = []), (this.checkedIndexesMap = {});
                            }),
                            (t.prototype.updateCheckedLegendsWith = function (t) {
                                var e = this;
                                this._resetCheckedData(),
                                    t.forEach(function (t) {
                                        e._updateCheckedIndex(t), e._addSendingDatum(t);
                                    }),
                                    this._setData();
                            }),
                            t
                    );
                })();
            e.default = u;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                var e = !!(0, a.default)(t.options.visible) || t.options.visible,
                    i = t.chartOptions.chartType,
                    n = null;
                return e && ((t.chartType = i), (n = new v(t))), n;
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(145),
                a = n(r),
                s = i(154),
                u = n(s),
                l = i(164),
                h = n(l),
                c = i(182),
                p = n(c),
                d = i(184),
                f = u.default.COMPONENT_TYPE_RAPHAEL,
                g = u.default.MAP_LEGEND_LABEL_PADDING,
                m = u.default.MAP_LEGEND_GRAPH_SIZE,
                y = u.default.CHART_PADDING,
                v = (function () {
                    function t(e) {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t);
                        var i = e.libType,
                            n = e.chartType,
                            o = e.theme,
                            r = e.options,
                            a = e.dataProcessor,
                            s = e.colorSpectrum,
                            u = e.eventBus;
                        (this.chartType = n),
                            (this.theme = o),
                        this._isDefaultLegendLabelColor(this.theme.label.color) && (this.theme.label.color = "#fff"),
                            (this.options = void 0 === r ? {} : r),
                            (this.dataProcessor = a),
                            (this.colorSpectrum = s),
                            (this.eventBus = u),
                            (this.graphRenderer = p.default.get(i, "mapLegend")),
                            (this.isHorizontal = h.default.isHorizontalLegend(this.options.align)),
                            (this.scaleData = null),
                            (this.drawingType = f),
                            this._attachToEventBus();
                    }
                    return (
                        (t.prototype._isDefaultLegendLabelColor = function (t) {
                            return t === d.DEFAULT_LEGEND_LABEL_FONTCOLOR;
                        }),
                            (t.prototype._attachToEventBus = function () {
                                this.eventBus.on({ showWedge: this.onShowWedge, hideTooltip: this.onHideWedge }, this),
                                    this.eventBus.on("beforeImageDownload", this._removeLocationURLFromFillAttribute.bind(this)),
                                    this.eventBus.on("afterImageDownload", this._restoreLocationURLToFillAttribute.bind(this));
                            }),
                            (t.prototype._removeLocationURLFromFillAttribute = function () {
                                this.graphRenderer.removeLocationURLFromFillAttribute();
                            }),
                            (t.prototype._restoreLocationURLToFillAttribute = function () {
                                this.graphRenderer.restoreLocationURLToFillAttribute();
                            }),
                            (t.prototype._makeBaseDataToMakeTickArea = function () {
                                var t = this.layout.dimension,
                                    e = this.scaleData,
                                    i = this.options.align,
                                    n = e.stepCount || e.tickCount - 1,
                                    o = {};
                                return (
                                    (o.position = this.layout.position),
                                        this.isHorizontal
                                            ? ((o.step = t.width / n), h.default.isLegendAlignTop(i) ? (o.position.top -= g) : (o.position.top += m + g))
                                            : ((o.step = t.height / n), h.default.isLegendAlignLeft(i) ? (o.position.left = y) : (o.position.left += m + g)),
                                        o
                                );
                            }),
                            (t.prototype._renderTickArea = function (t) {
                                this.options.reversed &&
                                this.scaleData.labels.sort(function (t, e) {
                                    return e - t;
                                }),
                                    this.graphRenderer.renderTickLabels(this.paper, this._makeBaseDataToMakeTickArea(), this.scaleData.labels, this.options.align, t);
                            }),
                            (t.prototype._makeVerticalGraphDimension = function () {
                                return { width: m, height: this.layout.dimension.height };
                            }),
                            (t.prototype._makeHorizontalGraphDimension = function () {
                                return { width: this.layout.dimension.width, height: m };
                            }),
                            (t.prototype._renderGraph = function (t) {
                                var e = this.layout.position,
                                    i = void 0;
                                if (((i = this.isHorizontal ? this._makeHorizontalGraphDimension() : this._makeVerticalGraphDimension()), this.options.reversed)) {
                                    var n = this.colorSpectrum.start;
                                    (this.colorSpectrum.start = this.colorSpectrum.end), (this.colorSpectrum.end = n);
                                }
                                this.graphRenderer.render({
                                    paper: this.paper,
                                    layout: { dimension: i, position: e },
                                    colorSpectrum: this.colorSpectrum,
                                    align: this.options.align,
                                    legendSet: t,
                                    theme: this.theme.label,
                                    labels: this.scaleData.labels,
                                });
                            }),
                            (t.prototype._renderLegendArea = function () {
                                var t = this.paper.set();
                                return this._renderGraph(t), this._renderTickArea(t), t;
                            }),
                            (t.prototype._setDataForRendering = function (t) {
                                var e = t.layout,
                                    i = t.paper,
                                    n = t.legendScaleData;
                                (this.layout = e), (this.paper = i), (this.scaleData = n);
                            }),
                            (t.prototype.render = function (t) {
                                this._setDataForRendering(t), (this.legendSet = this._renderLegendArea());
                            }),
                            (t.prototype.rerender = function (t) {
                                this.legendSet.remove(), this.render(t);
                            }),
                            (t.prototype.resize = function (t) {
                                this.rerender(t);
                            }),
                            (t.prototype.onShowWedge = function (t, e) {
                                (t = this.options.reversed ? 1 - t : t), this.graphRenderer.showWedge(t, e);
                            }),
                            (t.prototype.onHideWedge = function () {
                                this.graphRenderer.hideWedge();
                            }),
                            t
                    );
                })();
            (o.componentType = "legend"), (o.SpectrumLegend = v);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                var e = t.chartTheme,
                    i = t.chartOptions.chartType,
                    n = (0, u.default)(t.chartOptions, "circleLegend", "visible"),
                    o = null,
                    r = void 0;
                return (r = !!(0, a.default)(n) || n) && ((t.chartType = i), (t.baseFontFamily = e.chart.fontFamily), (o = new y(t))), o;
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(145),
                a = n(r),
                s = i(152),
                u = n(s),
                l = i(154),
                h = n(l),
                c = i(173),
                p = n(c),
                d = i(148),
                f = n(d),
                g = i(182),
                m = n(g),
                y = (function () {
                    function t(e) {
                        var i = e.libType,
                            n = e.chartType,
                            o = e.dataProcessor,
                            r = e.baseFontFamily;
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.circleRatios = [1, 0.5, 0.25]),
                            (this.chartType = n),
                            (this.dataProcessor = o),
                            (this.labelTheme = { fontSize: h.default.CIRCLE_LEGEND_LABEL_FONT_SIZE, fontFamily: r }),
                            (this.graphRenderer = m.default.get(i, "circleLegend")),
                            (this.layout = null),
                            (this.maxRadius = null),
                            (this.drawingType = h.default.COMPONENT_TYPE_RAPHAEL);
                    }
                    return (
                        (t.prototype._formatLabel = function (t, e) {
                            var i = this.dataProcessor.getFormatFunctions(),
                                n = void 0;
                            return (
                                (n = 0 === e ? String(parseInt(t, 10)) : f.default.formatToDecimal(String(t), e)), f.default.formatValue({ value: n, formatFunctions: i, chartType: this.chartType, areaType: "circleLegend", valueType: "r" })
                            );
                        }),
                            (t.prototype._makeLabels = function () {
                                var t = this,
                                    e = this.dataProcessor.getMaxValue(this.chartType, "r"),
                                    i = p.default.getDecimalLength(e);
                                return this.circleRatios.map(function (n) {
                                    return t._formatLabel(e * n, i);
                                });
                            }),
                            (t.prototype._render = function (t) {
                                return this.graphRenderer.render(t, this.layout, this.maxRadius, this.circleRatios, this._makeLabels());
                            }),
                            (t.prototype._setDataForRendering = function (t) {
                                (this.layout = t.layout), (this.maxRadius = t.maxRadius);
                            }),
                            (t.prototype.render = function (t) {
                                this._setDataForRendering(t), (this.circleLegendSet = this._render(t.paper));
                            }),
                            (t.prototype.rerender = function (t) {
                                this.circleLegendSet.remove(), this._setDataForRendering(t), (this.circleLegendSet = this._render(t.paper));
                            }),
                            (t.prototype.resize = function (t) {
                                this.rerender(t);
                            }),
                            t
                    );
                })();
            (o.componentType = "legend"), (o.CircleLegend = y);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t, e, i) {
                var n = (100 * t.ratio).toFixed(4),
                    o = parseFloat(n),
                    r = (n = o < 9e-4 || n.length > 5 ? n.substr(0, 4) : String(o)) + "&nbsp;%&nbsp;";
                return (e.ratioLabel = i + r), (e.label = t.tooltipLabel || (t.label ? t.label : "")), e;
            }
            function r(t) {
                var e = t.chartOptions.chartType,
                    i = t.seriesTypes,
                    n = t.chartOptions.xAxis,
                    r = [],
                    a = void 0;
                return (
                    Object.values(t.chartTheme.legend)
                        .filter(function (t) {
                            return (0, g.default)(t.colors);
                        })
                        .forEach(function (t) {
                            r = r.concat(t.colors);
                        }),
                        (a = "map" === e ? c.default : t.options.grouped ? l.default : s.default),
                    ("pie" === e || d.default.isPieDonutComboChart(e, i)) && (t.labelFormatter = o),
                        (t.chartType = e),
                        (t.chartTypes = i),
                        (t.xAxisType = n.type),
                        (t.dateFormat = n.dateFormat),
                        (t.colors = r),
                        a(t)
                );
            }
            (e.__esModule = !0), (e.default = r);
            var a = i(220),
                s = n(a),
                u = i(225),
                l = n(u),
                h = i(227),
                c = n(h),
                p = i(164),
                d = n(p),
                f = i(140),
                g = n(f);
            r.componentType = "tooltip";
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                return new T(t);
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(140),
                a = n(r),
                s = i(150),
                u = n(s),
                l = i(152),
                h = n(l),
                c = i(221),
                p = n(c),
                d = i(222),
                f = n(d),
                g = i(154),
                m = n(g),
                y = i(164),
                v = n(y),
                $ = i(223),
                x = n($),
                T = (function (t) {
                    function e(i) {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        var n = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, i));
                        return (n.colorSpectrum = i.colorSpectrum), n;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._makeTooltipHtml = function (t, e) {
                                return this._getTooltipTemplate(e)(Object.assign({ categoryVisible: t ? "show" : "hide", category: t }, e));
                            }),
                            (e.prototype._getTooltipTemplate = function (t) {
                                var e = x.default.tplDefault;
                                return (
                                    v.default.isBoxplotChart(this.chartType)
                                        ? (e = this._getBoxplotTooltipTemplate(t))
                                        : v.default.isPieChart(this.chartType) || v.default.isPieDonutComboChart(this.chartType, this.chartTypes)
                                            ? (e = x.default.tplPieChart)
                                            : this.dataProcessor.coordinateType
                                                ? (e = x.default.tplCoordinatetypeChart)
                                                : v.default.isBulletChart(this.chartType)
                                                    ? (e = x.default.tplBulletChartDefault)
                                                    : v.default.isHeatmapChart(this.chartType) && (e = x.default.tplHeatmapChart),
                                        e
                                );
                            }),
                            (e.prototype._getBoxplotTooltipTemplate = function (t) {
                                var e = x.default.tplBoxplotChartDefault;
                                return (0, u.default)(t.outlierIndex) && ((e = x.default.tplBoxplotChartOutlier), (t.label = t.outliers[t.outlierIndex].label)), e;
                            }),
                            (e.prototype._makeHtmlForValueTypes = function (t, e) {
                                return e
                                    .map(function (e) {
                                        return t[e] ? "<tr><td>" + e + '</td><td class="' + m.default.CLASS_NAME_TOOLTIP_VALUE + '">' + t[e] + "</td></tr>" : "";
                                    })
                                    .join("");
                            }),
                            (e.prototype._makeSingleTooltipHtml = function (t, e) {
                                var i = e.groupIndex,
                                    n = this._findTooltipData(t, e),
                                    o = this._findTooltipColor(t, e, n);
                                return (
                                    v.default.isBoxplotChart(this.chartType) && (0, u.default)(e.outlierIndex) && (n.outlierIndex = e.outlierIndex),
                                    this.colorSpectrum && (o = this.colorSpectrum.getColor(n.colorRatio || n.ratio)),
                                        (n.chartType = this.chartType),
                                        (n.cssText = "background-color: " + o),
                                        ((n = Object.assign({ suffix: this.suffix }, n)).valueTypes = this._makeHtmlForValueTypes(n, ["x", "y", "r"])),
                                        this.templateFunc(n.category, n, this.getRawCategory(i))
                                );
                            }),
                            (e.prototype._findTooltipData = function (t, e) {
                                var i = this.data[t],
                                    n = e.groupIndex;
                                return v.default.isRadialChart(t) && i.length === n && (n = 0), Object.assign({}, (0, h.default)(i, n, e.index));
                            }),
                            (e.prototype._findTooltipColor = function (t, e, i) {
                                var n = v.default.isBarTypeChart(this.chartType),
                                    o = v.default.isBoxplotChart(this.chartType),
                                    r = (n || o) && this.dataProcessor.options.series.colorByPoint,
                                    a = e.groupIndex,
                                    s = e.index;
                                return v.default.isBulletChart(this.chartType) ? (s = a) : v.default.isTreemapChart(this.chartType) && (s = i.tooltipColorIndex), r ? "#aaa" : this.tooltipColors[t][s];
                            }),
                            (e.prototype._setDefaultTooltipPositionOption = function () {
                                this.options.align || (this.isVertical ? (this.options.align = m.default.TOOLTIP_DEFAULT_ALIGN_OPTION) : (this.options.align = m.default.TOOLTIP_DEFAULT_HORIZONTAL_ALIGN_OPTION));
                            }),
                            (e.prototype._makeShowTooltipParams = function (t, e) {
                                var i = t.index,
                                    n = this.dataProcessor.getLegendItem(i);
                                if (!n) return null;
                                var o = n.chartType,
                                    r = Object.assign({ chartType: o, legend: n.label, legendIndex: i, index: t.groupIndex }, e);
                                return v.default.isBoxplotChart(o) && (0, u.default)(t.outlierIndex) && (r.outlierIndex = t.outlierIndex), r;
                            }),
                            (e.prototype._makeTooltipDatum = function () {
                                var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "",
                                    e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : "",
                                    i = arguments[2],
                                    n = i.tooltipLabel,
                                    o = this.labelFormatter,
                                    r = { legend: t, label: n || (i.label ? i.label : ""), category: e };
                                return o && (r = o(i, r, "")), (r.category = e), Object.assign(r, i.pickValueMapForTooltip());
                            }),
                            (e.prototype.makeTooltipData = function () {
                                var t = this,
                                    e = this.dataProcessor.getLegendLabels(),
                                    i = v.default.isTreemapChart(this.chartType),
                                    n = {},
                                    o = {};
                                return (
                                    (0, a.default)(e) ? (n[this.chartType] = e) : (n = e),
                                        this.dataProcessor.eachBySeriesGroup(function (e, i, r) {
                                            r = r || t.chartType;
                                            var a = v.default.isBulletChart(r),
                                                s = e.map(function (e, o) {
                                                    var s = t.dataProcessor.makeTooltipCategory(i, o, t.isVertical);
                                                    return e ? t._makeTooltipDatum(n[r][a ? i : o], s, e) : null;
                                                });
                                            o[r] || (o[r] = []), o[r].push(s);
                                        }, i),
                                        o
                                );
                            }),
                            e
                    );
                })(p.default);
            f.default.mixin(T), (o.componentType = "tooltip"), (o.NormalTooltip = T);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(146),
                r = n(o),
                a = i(135),
                s = n(a),
                u = i(186),
                l = n(u),
                h = i(154),
                c = n(h),
                p = i(155),
                d = n(p),
                f = i(164),
                g = n(f),
                m = i(148),
                y = n(m),
                v = (function () {
                    function t(e) {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t);
                        var i = g.default.isPieChart(e.chartType);
                        (this.chartType = e.chartType),
                            (this.chartTypes = e.chartTypes),
                            (this.dataProcessor = e.dataProcessor),
                            (this.options = e.options),
                            (this.colors = e.colors),
                            (this.theme = e.theme),
                            (this.originalTheme = l.default.deepCopy(e.theme)),
                            (this.isVertical = e.isVertical),
                            (this.eventBus = e.eventBus),
                            (this.labelTheme = e.labelTheme),
                            (this.xAxisType = e.xAxisType),
                            (this.dateFormat = e.dateFormat),
                            (this.labelFormatter = e.labelFormatter),
                            (this.className = "tui-chart-tooltip-area"),
                            (this.tooltipContainer = null),
                            (this.suffix = this.options.suffix ? "&nbsp;" + this.options.suffix : ""),
                            (this.templateFunc = this.options.template || this._makeTooltipHtml.bind(this)),
                            (this.animationTime = i ? c.default.TOOLTIP_PIE_ANIMATION_TIME : c.default.TOOLTIP_ANIMATION_TIME),
                            (this.data = []),
                            (this.layout = null),
                            (this.dimensionMap = null),
                            (this.positionMap = null),
                            (this.drawingType = c.default.COMPONENT_TYPE_DOM),
                            this._setDefaultTooltipPositionOption(),
                            this._saveOriginalPositionOptions(),
                            this._attachToEventBus();
                    }
                    return (
                        (t.prototype.presetForChangeData = function () {
                            var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : this.theme;
                            (this.theme = t), (this.originalTheme = l.default.deepCopy(t));
                        }),
                            (t.prototype._attachToEventBus = function () {
                                this.eventBus.on({ showTooltip: this.onShowTooltip, hideTooltip: this.onHideTooltip }, this),
                                this.onShowTooltipContainer && this.eventBus.on({ showTooltipContainer: this.onShowTooltipContainer, hideTooltipContainer: this.onHideTooltipContainer }, this);
                            }),
                            (t.prototype._makeTooltipHtml = function () {}),
                            (t.prototype._setDefaultTooltipPositionOption = function () {}),
                            (t.prototype._saveOriginalPositionOptions = function () {
                                this.orgPositionOptions = { align: this.options.align, offset: this.options.offset };
                            }),
                            (t.prototype.makeLineLegendIcon = function (t) {
                                for (var e = t.length, i = 0; i < e; i += 1) {
                                    var n = t[i],
                                        o = n.style["background-color"],
                                        r = (0, s.default)(n, 10, 10).path(c.default.LEGEND_LINE_ICON_PATH);
                                    (n.style["background-color"] = ""), r.attr({ stroke: o, "stroke-width": 2, "stroke-opacity": 1 });
                                }
                            }),
                            (t.prototype.makeTooltipData = function () {}),
                            (t.prototype._setDataForRendering = function (t) {
                                (this.layout = t.layout), (this.dimensionMap = t.dimensionMap), (this.positionMap = t.positionMap);
                            }),
                            (t.prototype.render = function (t) {
                                var e = t.paper;
                                return (
                                    d.default.addClass(e, this.className),
                                        this._setDataForRendering(t),
                                        (this.data = this.makeTooltipData()),
                                        (this.tooltipColors = this.makeTooltipLegendColor(t.checkedLegends)),
                                        y.default.renderPosition(e, this.layout.position),
                                        (this.tooltipContainer = e),
                                        e
                                );
                            }),
                            (t.prototype.rerender = function (t) {
                                this.resize(t), (this.data = this.makeTooltipData()), (this.tooltipColors = this.makeTooltipLegendColor(t.checkedLegends));
                            }),
                            (t.prototype.makeTooltipLegendColor = function (t) {
                                var e = this,
                                    i = {};
                                return (
                                    t &&
                                    Object.keys(this.theme).forEach(function (n) {
                                        i[n] || (i[n] = []),
                                            (t[n] || []).forEach(function (t, o) {
                                                t && i[n].push(e.theme[n].colors[o]);
                                            });
                                    }),
                                        i
                                );
                            }),
                            (t.prototype.resize = function (t) {
                                this._setDataForRendering(t), y.default.renderPosition(this.tooltipContainer, this.layout.position), this.positionModel && this.positionModel.updateBound(this.layout);
                            }),
                            (t.prototype.zoom = function () {
                                this.data = this.makeTooltipData();
                            }),
                            (t.prototype._getTooltipElement = function () {
                                if (!this.tooltipElement) {
                                    var t = (this.tooltipElement = d.default.create("DIV", "tui-chart-tooltip"));
                                    d.default.append(this.tooltipContainer, t);
                                }
                                return this.tooltipElement;
                            }),
                            (t.prototype.onShowTooltip = function (t) {
                                var e = this._getTooltipElement(),
                                    i = g.default.isComboChart(this.chartType) && g.default.isScatterChart(t.chartType),
                                    n = void 0;
                                (!g.default.isChartToDetectMouseEventOnSeries(t.chartType) || i) && e.offsetWidth && (n = { left: e.offsetLeft, top: e.offsetTop }), this._showTooltip(e, t, n);
                            }),
                            (t.prototype.getTooltipDimension = function (t) {
                                return { width: t.offsetWidth, height: t.offsetHeight };
                            }),
                            (t.prototype._moveToPosition = function (t, e, i) {
                                i ? this._slideTooltip(t, i, e) : y.default.renderPosition(t, e);
                            }),
                            (t.prototype._slideTooltip = function (t, e, i) {
                                var n = i.top - e.top,
                                    o = i.left - e.left;
                                y.default.cancelAnimation(this.slidingAnimation),
                                    (this.slidingAnimation = y.default.startAnimation(this.animationTime, function (i) {
                                        (t.style.left = e.left + o * i + "px"), (t.style.top = e.top + n * i + "px");
                                    }));
                            }),
                            (t.prototype.onHideTooltip = function (t, e) {
                                var i = this._getTooltipElement();
                                this._hideTooltip(i, t, e);
                            }),
                            (t.prototype.setAlign = function (t) {
                                (this.options.align = t), this.positionModel && this.positionModel.updateOptions(this.options);
                            }),
                            (t.prototype._updateOffsetOption = function (t) {
                                (this.options.offset = t), this.positionModel && this.positionModel.updateOptions(this.options);
                            }),
                            (t.prototype.setOffset = function (t) {
                                var e = Object.assign({}, this.options.offset);
                                (0, r.default)(t.x) && (e.x = t.x), (0, r.default)(t.y) && (e.y = t.y), this._updateOffsetOption(Object.assign({}, this.options.offset, e));
                            }),
                            (t.prototype.setPosition = function (t) {
                                var e = Object.assign({}, this.options.offset);
                                (0, r.default)(t.left) && (e.x = t.left), (0, r.default)(t.top) && (e.y = t.y), this._updateOffsetOption(e);
                            }),
                            (t.prototype.resetAlign = function () {
                                var t = this.orgPositionOptions.align;
                                (this.options.align = t), this.positionModel && this.positionModel.updateOptions(this.options);
                            }),
                            (t.prototype.resetOffset = function () {
                                (this.options.offset = this.orgPositionOptions.offset), this._updateOffsetOption(this.options.offset);
                            }),
                            (t.prototype.getRawCategory = function (t, e) {
                                var i = this.isVertical ? "x" : "y",
                                    n = this.dataProcessor.categoriesMap ? this.dataProcessor.categoriesMap[i] : null,
                                    o = "";
                                return n && (o = n[t]), e && (o = y.default.formatDate(o, e)), o;
                            }),
                            t
                    );
                })();
            e.default = v;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(137),
                r = n(o),
                a = i(154),
                s = n(a),
                u = i(164),
                l = n(u),
                h = i(155),
                c = n(h),
                p = i(148),
                d = n(p);
            e.default = {
                _setIndexesCustomAttribute: function (t, e) {
                    t.setAttribute("data-groupIndex", e.groupIndex), t.setAttribute("data-index", e.index);
                },
                _getIndexesCustomAttribute: function (t) {
                    var e = t.getAttribute("data-groupIndex"),
                        i = t.getAttribute("data-index"),
                        n = null;
                    return (0, r.default)(e) || (0, r.default)(i) || (n = { groupIndex: parseInt(e, 10), index: parseInt(i, 10) }), n;
                },
                _setShowedCustomAttribute: function (t, e) {
                    t.setAttribute("data-showed", e);
                },
                _isShowedTooltip: function (t) {
                    var e = t.getAttribute("data-showed");
                    return "true" === e || !0 === e;
                },
                _makeTooltipPositionForBulletChart: function (t) {
                    var e = t.mousePosition,
                        i = this.layout.position;
                    return { left: e.left - i.left, top: e.top - i.top };
                },
                _makeLeftPositionOfNotBarChart: function (t, e, i, n) {
                    var o = t,
                        r = i || 0,
                        a = n || s.default.TOOLTIP_GAP;
                    return e.indexOf("left") > -1 ? (o -= r + a) : e.indexOf("center") > -1 && r ? (o -= r / 2) : (o += a), o;
                },
                _makeTopPositionOfNotBarChart: function (t, e, i, n) {
                    var o = t,
                        r = i || 0;
                    return e.indexOf("bottom") > -1 ? (o += r + n) : e.indexOf("middle") > -1 && r ? (o += r / 2) : (o -= r + s.default.TOOLTIP_GAP), o;
                },
                _makeTooltipPositionForNotBarChart: function (t) {
                    var e = t.bound,
                        i = t.positionOption,
                        n = t.dimension,
                        o = t.alignOption,
                        r = void 0 === o ? "" : o,
                        a = n.width - (e.width || 0),
                        u = e.width ? 0 : s.default.TOOLTIP_GAP,
                        l = n.height,
                        h = e.left - this.layout.position.left + i.left,
                        c = e.top - this.layout.position.top + i.top - s.default.TOOLTIP_GAP;
                    return { left: this._makeLeftPositionOfNotBarChart(h, r, a, u), top: this._makeTopPositionOfNotBarChart(c, r, l, u) };
                },
                _makeTooltipPositionToMousePosition: function (t) {
                    return t.bound || ((t.bound = t.bound || {}), Object.assign(t.bound, t.mousePosition)), this._makeTooltipPositionForNotBarChart(t);
                },
                _makeLeftPositionForBarChart: function (t, e, i) {
                    var n = t;
                    return e.indexOf("left") > -1 ? (n -= i) : e.indexOf("center") > -1 ? (n -= i / 2) : (n += s.default.TOOLTIP_GAP), n;
                },
                _makeTopPositionForBarChart: function (t, e, i) {
                    var n = t;
                    return e.indexOf("top") > -1 ? (n -= i) : e.indexOf("middle") > -1 && (n -= i / 2), n;
                },
                _makeTooltipPositionForBarChart: function (t) {
                    var e = this.layout.position,
                        i = t.bound,
                        n = t.positionOption,
                        o = t.dimension,
                        r = t.alignOption,
                        a = void 0 === r ? "" : r,
                        s = o.height - (i.height || 0),
                        u = o.width,
                        l = i.left + i.width + n.left - e.left,
                        h = i.top + n.top - e.top;
                    return { left: this._makeLeftPositionForBarChart(l, a, u), top: this._makeTopPositionForBarChart(h, a, s) };
                },
                _makeTooltipPositionForTreemapChart: function (t) {
                    var e = this.layout.position,
                        i = t.bound,
                        n = t.positionOption,
                        o = t.dimension,
                        r = d.default.getRenderedLabelHeight(s.default.MAX_HEIGHT_WORD, this.labelTheme);
                    return { left: i.left + (i.width - o.width) / 2 + n.left - e.left, top: i.top + i.height / 2 - r + n.top - e.top };
                },
                _adjustPosition: function (t, e) {
                    var i = this.dimensionMap.chart,
                        n = this.layout.position;
                    return (e.left = Math.max(e.left, -n.left)), (e.left = Math.min(e.left, i.width - n.left - t.width)), (e.top = Math.max(e.top, -n.top)), (e.top = Math.min(e.top, i.height - n.top - t.height)), e;
                },
                _makeTooltipPosition: function (t) {
                    var e = {};
                    if (t.mousePosition) e = this._makeTooltipPositionToMousePosition(t);
                    else {
                        var i = void 0,
                            n = void 0,
                            o = void 0;
                        l.default.isBarChart(t.chartType)
                            ? ((e = this._makeTooltipPositionForBarChart(t)), (i = "width"), (n = "left"), (o = 1))
                            : l.default.isTreemapChart(t.chartType)
                                ? (e = this._makeTooltipPositionForTreemapChart(t))
                                : ((e = this._makeTooltipPositionForNotBarChart(t)), (i = "height"), (n = "top"), (o = -1)),
                        t.allowNegativeTooltip && (e = this._moveToSymmetry(e, { bound: t.bound, indexes: t.indexes, dimension: t.dimension, chartType: t.chartType, sizeType: i, positionType: n, addPadding: o })),
                            (e = this._adjustPosition(t.dimension, e));
                    }
                    return e;
                },
                _moveToSymmetry: function (t, e) {
                    var i = e.bound,
                        n = e.sizeType,
                        o = e.positionType,
                        r = e.indexes,
                        a = e.seriesType || e.chartType,
                        s = this.dataProcessor.getValue(r.groupIndex, r.index, a),
                        u = l.default.isBarChart(this.chartType) ? -1 : 1;
                    if (s < 0) {
                        var h = e.dimension[n],
                            c = i[n],
                            p = t[o] + (c + h) * u;
                        t[o] = p;
                    }
                    return t;
                },
                _isChangedIndexes: function (t, e) {
                    return !!t && (t.groupIndex !== e.groupIndex || t.index !== e.index);
                },
                _showTooltip: function (t, e, i) {
                    var n = this.tooltipContainer.parentNode.getBoundingClientRect(),
                        o = e.indexes,
                        r = this._getIndexesCustomAttribute(t),
                        a = this.options.offset || {},
                        u = {},
                        l = t && t.getAttribute("data-chart-type");
                    !e.bound && e.mousePosition && (e.bound = { left: e.mousePosition.left - n.left + s.default.CHART_PADDING, top: e.mousePosition.top - n.top + s.default.CHART_PADDING }),
                    (this._isChangedIndexes(r, o) || l !== e.chartType) && this.eventBus.fire("hoverOffSeries", r, l),
                        (t.innerHTML = this._makeSingleTooltipHtml(e.seriesType || e.chartType, o)),
                    "line" === e.chartType && this.makeLineLegendIcon(t.querySelectorAll(".tui-chart-legend-rect.line")),
                        t.setAttribute("data-chart-type", e.chartType),
                        this._setIndexesCustomAttribute(t, o),
                        this._setShowedCustomAttribute(t, !0),
                        this._fireBeforeShowTooltipPublicEvent(o, e.silent),
                        c.default.addClass(t, "show"),
                        (u.left = a.x || 0),
                        (u.top = a.y || 0);
                    var h = this._makeTooltipPosition(Object.assign({ dimension: this.getTooltipDimension(t), positionOption: u, alignOption: this.options.align || "" }, e));
                    this._moveToPosition(t, h, i), this.eventBus.fire("hoverSeries", o, e.chartType), this._fireAfterShowTooltipPublicEvent(o, { element: t, position: h }, e.silent), delete e.silent;
                },
                _fireBeforeShowTooltipPublicEvent: function (t, e) {
                    if (!e) {
                        var i = this._makeShowTooltipParams(t);
                        this.eventBus.fire(s.default.PUBLIC_EVENT_PREFIX + "beforeShowTooltip", i);
                    }
                },
                _fireAfterShowTooltipPublicEvent: function (t, e, i) {
                    if (!i) {
                        var n = this._makeShowTooltipParams(t, e);
                        this.eventBus.fire(s.default.PUBLIC_EVENT_PREFIX + "afterShowTooltip", n);
                    }
                },
                _executeHidingTooltip: function (t) {
                    c.default.removeClass(t, "show"), t.removeAttribute("data-groupIndex"), t.removeAttribute("data-index"), (t.style.cssText = "");
                },
                _hideTooltip: function (t, e, i) {
                    var n = this,
                        o = this._getIndexesCustomAttribute(t),
                        r = t.getAttribute("data-chart-type"),
                        a = !(!i || !i.silent);
                    l.default.isChartToDetectMouseEventOnSeries(r)
                        ? (this.eventBus.fire("hoverOffSeries", o, r), this._fireBeforeHideTooltipPublicEvent(o, a), this._executeHidingTooltip(t))
                        : r &&
                        (this._setShowedCustomAttribute(t, !1),
                            this.eventBus.fire("hoverOffSeries", o, r),
                        this._isChangedIndexes(this.prevIndexes, o) && delete this.prevIndexes,
                            setTimeout(function () {
                                n._isShowedTooltip(t) || (n._fireBeforeHideTooltipPublicEvent(o, a), n._executeHidingTooltip(t));
                            }, s.default.HIDE_DELAY));
                },
                _fireBeforeHideTooltipPublicEvent: function (t, e) {
                    e || this.eventBus.fire(s.default.PUBLIC_EVENT_PREFIX + "beforeHideTooltip", void 0);
                },
                onShowTooltipContainer: function () {
                    this.tooltipContainer.style.zIndex = s.default.TOOLTIP_ZINDEX;
                },
                onHideTooltipContainer: function () {
                    this.tooltipContainer.style.zIndex = 0;
                },
                mixin: function (t) {
                    Object.assign(t.prototype, this);
                },
            };
        },
        function (t, e, i) {
            "use strict";
            e.__esModule = !0;
            var n,
                o = (n = i(224)) && n.__esModule ? n : { default: n },
                r = {
                    HTML_DEFAULT_TEMPLATE:
                        '<div class="tui-chart-default-tooltip">\n      <div class="tui-chart-tooltip-head {{ categoryVisible }}">{{ category }}</div>\n      <div class="tui-chart-tooltip-body">\n        <span class="tui-chart-legend-rect {{ chartType }}" ></span>\n        <span>{{ legend }}</span>\n        <span class="tui-chart-tooltip-value">{{ label }}{{ suffix }}</span>\n      </div>\n    </div>',
                    HTML_PIE_TEMPLATE:
                        '<div class="tui-chart-default-tooltip">\n      <div class="tui-chart-tooltip-head {{ categoryVisible }}">{{ category }}</div>\n      <div class="tui-chart-tooltip-body">\n        <span class="tui-chart-legend-rect {{ chartType }}" ></span>\n        <span>{{ legend }}</span>\n        <span class="tui-chart-tooltip-value">{{ ratioLabel }} ( {{ label }} {{ suffix }})</span>\n      </div>\n    </div>',
                    HTML_COORDINATE_TYPE_CHART_TEMPLATE:
                        '<div class="tui-chart-default-tooltip">\n    <div class="tui-chart-tooltip-head {{ categoryVisible }}">\n      <span class="tui-chart-legend-rect {{ chartType }}" ></span>\n      {{ category }}\n    </div>\n    <div class="tui-chart-tooltip-body">\n      <span class="tui-chart-legend-rect {{ chartType }}" ></span>\n      <span>{{ legend }}</span>\n      <span class="tui-chart-tooltip-value">{{ label }}</span>\n    </div>\n    <table class="tui-chart-tooltip-body">{{ valueTypes }}</table>\n  </div>',
                    HTML_GROUP:
                        '<div class="tui-chart-default-tooltip tui-chart-group-tooltip">\n    <div class="tui-chart-tooltip-head">{{ category }}</div>\n    <table class="tui-chart-tooltip-body">\n      {{ items }}\n    </table>\n  </div>',
                    HTML_GROUP_TYPE: '\n  <tr>\n    <td colspan="3" class="tui-chart-tooltip-type">{{ type }}</div>\n  </tr>',
                    HTML_GROUP_ITEM:
                        '<tr>\n    <td><div class="tui-chart-legend-rect {{ chartType }}" ></div></td>\n    <td>{{ legend }}</td>\n    <td class="tui-chart-tooltip-value">{{ value }} {{ suffix }}</td>\n  </tr>',
                    GROUP_CSS_TEXT: "background-color:{{ color }}",
                    HTML_MAP_CHART_DEFAULT_TEMPLATE:
                        '<div class="tui-chart-default-tooltip">\n    <div class="tui-chart-tooltip-body">\n      <span class="tui-chart-legend-rect {{ chartType }}" ></span>\n      <span>{{ name }}</span>\n      <span class="tui-chart-tooltip-value">{{ value }}{{ suffix }}</span>\n    </div>\n  </div>',
                    HTML_HEATMAP_TEMPLATE:
                        '<div class="tui-chart-default-tooltip">\n    <div class="tui-chart-tooltip-head {{ categoryVisible }}">{{ category }}</div>\n    <div class="tui-chart-tooltip-body">\n      <span class="tui-chart-legend-rect {{ chartType }}" ></span>\n      <span>{{ label }}{{ suffix }}</span>\n    </div>\n  </div>',
                    HTML_BOXPLOT_TEMPLATE:
                        '<div class="tui-chart-default-tooltip">\n    <div class="tui-chart-tooltip-head {{ categoryVisible }}">{{ category }}</div>\n    <table class="tui-chart-tooltip-body">\n      <tr>\n        <td colspan="2">\n          <span class="tui-chart-legend-rect {{ chartType }}" ></span>\n          {{ legend }}\n        </td>\n      </tr>\n      <tr>\n        <td>Maximum: </td>\n        <td class="tui-chart-tooltip-value">{{ maxLabel }} {{ suffix }}</td>\n      </tr>\n      <tr>\n        <td>Upper Quartile: </td>\n        <td class="tui-chart-tooltip-value">{{ uqLabel }} {{ suffix }}</td>\n      </tr>\n      <tr>\n        <td>Median: </td>\n        <td class="tui-chart-tooltip-value">{{ medianLabel }} {{ suffix }}</td>\n      </tr>\n      <tr>\n        <td>Lower Quartile: </td>\n        <td class="tui-chart-tooltip-value">{{ lqLabel }} {{ suffix }}</td>\n      </tr>\n      <tr>\n        <td>Minimum: </td>\n        <td class="tui-chart-tooltip-value">{{ minLabel }} {{ suffix }}</td>\n      </tr>\n    </table>\n  </div>',
                    HTML_BOXPLOT_OUTLIER:
                        '<div class="tui-chart-default-tooltip">\n    <div class="tui-chart-tooltip-head {{ categoryVisible }}">{{ category }}</div>\n      <div class="tui-chart-tooltip-body">\n        <span>{{ legend }}</span>\n      </div>\n      <div class="tui-chart-tooltip-body">\n        <span>Outlier: </span>\n        <span class="tui-chart-tooltip-value">{{ label }} {{ suffix }}</span>\n      </div>\n  </div>',
                    HTML_BULLET_TEMPLATE:
                        '<div class="tui-chart-default-tooltip">\n    <div class="tui-chart-tooltip-body {{ categoryVisible }}">\n      <span class="tui-chart-legend-rect {{ chartType }}" ></span>\n      <span>{{ category }}</span>\n      <span class="tui-chart-tooltip-value">{{ label }} {{ suffix }}</span>\n    </div>\n  </div>',
                };
            e.default = {
                tplDefault: o.default.template(r.HTML_DEFAULT_TEMPLATE),
                tplPieChart: o.default.template(r.HTML_PIE_TEMPLATE),
                tplCoordinatetypeChart: o.default.template(r.HTML_COORDINATE_TYPE_CHART_TEMPLATE),
                tplGroup: o.default.template(r.HTML_GROUP),
                tplGroupType: o.default.template(r.HTML_GROUP_TYPE),
                tplGroupItem: o.default.template(r.HTML_GROUP_ITEM),
                tplGroupCssText: o.default.template(r.GROUP_CSS_TEXT),
                tplMapChartDefault: o.default.template(r.HTML_MAP_CHART_DEFAULT_TEMPLATE),
                tplHeatmapChart: o.default.template(r.HTML_HEATMAP_TEMPLATE),
                tplBoxplotChartDefault: o.default.template(r.HTML_BOXPLOT_TEMPLATE),
                tplBoxplotChartOutlier: o.default.template(r.HTML_BOXPLOT_OUTLIER),
                tplBulletChartDefault: o.default.template(r.HTML_BULLET_TEMPLATE),
            };
        },
        function (t, e) {
            "use strict";
            e.__esModule = !0;
            var i = function (t, e) {
                if (Array.isArray(t)) return t;
                if (Symbol.iterator in Object(t))
                    return (function t(e, i) {
                        var n = [],
                            o = !0,
                            r = !1,
                            a = void 0;
                        try {
                            for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                        } catch (l) {
                            (r = !0), (a = l);
                        } finally {
                            try {
                                !o && u.return && u.return();
                            } finally {
                                if (r) throw a;
                            }
                        }
                        return n;
                    })(t, e);
                throw TypeError("Invalid attempt to destructure non-iterable instance");
            };
            e.default = {
                template: function (t) {
                    return function (e) {
                        var n = t;
                        return (
                            Object.entries(e).forEach(function (t) {
                                var e = i(t, 2),
                                    o = e[0],
                                    r = e[1],
                                    a = RegExp("{{\\s*" + o + "\\s*}}", "g");
                                n = n.replace(a, String(r).replace("$", "＄"));
                            }),
                                n
                        );
                    };
                },
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                return new S(t);
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(137),
                a = n(r),
                s = i(189),
                u = n(s),
                l = i(221),
                h = n(l),
                c = i(226),
                p = n(c),
                d = i(154),
                f = n(d),
                g = i(155),
                m = n(g),
                y = i(148),
                v = n(y),
                $ = i(184),
                x = n($),
                T = i(223),
                b = n(T),
                _ = i(164),
                A = n(_),
                D = f.default.TOOLTIP_DEFAULT_GROUP_ALIGN_OPTION,
                C = f.default.TOOLTIP_DEFAULT_GROUP_HORIZONTAL_ALIGN_OPTION,
                E = f.default.SERIES_EXPAND_SIZE,
                L = f.default.PUBLIC_EVENT_PREFIX,
                S = (function (t) {
                    function e(i) {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        var n = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, i));
                        return (n.prevIndex = null), (n.tickInterval = null), (n.isBullet = A.default.isBulletChart(i.chartType)), n;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._makeTooltipHtml = function (t, e, i, n) {
                                var o = b.default.tplGroupItem,
                                    r = b.default.tplGroupCssText,
                                    a = A.default.isBarChart(this.chartType),
                                    s = A.default.isBarTypeChart(this.chartType),
                                    u = A.default.isBoxplotChart(this.chartType),
                                    l = this.dataProcessor.options.series,
                                    h = (s || u) && l && l.colorByPoint,
                                    c = !a && l && l.stack,
                                    p = this._makeColors(this.theme, n),
                                    d = void 0,
                                    f = e.map(function (t, e) {
                                        var i = t.type,
                                            n = "data" !== i && d !== i,
                                            a = "";
                                        return (d = i), t.value ? (n && (a = b.default.tplGroupType({ type: i })), (a += o(Object.assign({ cssText: r({ color: h ? "#aaa" : p[e] }) }, t)))) : null;
                                    });
                                return c && f.reverse(), b.default.tplGroup({ category: t, items: f.join("") });
                            }),
                            (e.prototype._setDefaultTooltipPositionOption = function () {
                                this.options.align || (this.isVertical ? (this.options.align = D) : (this.options.align = C));
                            }),
                            (e.prototype.render = function (t) {
                                var e = h.default.prototype.render.call(this, t),
                                    i = this.dimensionMap.chart,
                                    n = this.layout;
                                return t.checkedLegends && (this.theme = this._updateLegendTheme(t.checkedLegends)), (this.positionModel = new p.default(i, n, this.isVertical, this.options)), e;
                            }),
                            (e.prototype.rerender = function (t) {
                                h.default.prototype.rerender.call(this, t), (this.prevIndex = null), t.checkedLegends && (this.theme = this._updateLegendTheme(t.checkedLegends));
                            }),
                            (e.prototype.zoom = function () {
                                (this.prevIndex = null), (this.tickInterval = null), h.default.prototype.zoom.call(this);
                            }),
                            (e.prototype._updateLegendTheme = function (t) {
                                var e = this,
                                    i = [];
                                return (
                                    Object.keys(this.originalTheme).forEach(function (n) {
                                        e.originalTheme[n].colors.forEach(function (e, o) {
                                            (t[n] || t)[o] && i.push(e);
                                        });
                                    }),
                                        { colors: i }
                                );
                            }),
                            (e.prototype.makeTooltipData = function () {
                                var t = this,
                                    e = this.dataProcessor.getCategoryCount(this.isVertical);
                                return this.dataProcessor.getSeriesGroups().map(function (i, n) {
                                    var o = i.map(function (t) {
                                        return { type: t.type || "data", label: t.tooltipLabel || t.label };
                                    });
                                    return { category: t.dataProcessor.makeTooltipCategory(n, e - n, t.isVertical), values: o };
                                });
                            }),
                            (e.prototype._makeColors = function (t, e) {
                                var i = 0,
                                    n = this.dataProcessor.getLegendData(),
                                    o = void 0,
                                    r = void 0;
                                if (this.isBullet) return this.dataProcessor.getGraphColors()[e];
                                if (t.colors) return t.colors;
                                var a = x.default.series.colors.slice(0, n.length);
                                return (0, u.default)(n, "chartType").map(function (e) {
                                    r !== e && ((o = t[e] ? t[e].colors : a), (i = 0)), (r = e);
                                    var n = o[i];
                                    return (i += 1), n;
                                });
                            }),
                            (e.prototype._makeItemRenderingData = function (t, e) {
                                var i = this,
                                    n = this.dataProcessor,
                                    o = this.suffix;
                                return t.map(function (t, r) {
                                    var a = { value: t.label, type: t.type, suffix: o, legend: "" },
                                        s = void 0;
                                    return i.isBullet ? (s = n.getLegendItem(e)) : ((s = n.getLegendItem(r)), (a.legend = s.label)), (a.chartType = s.chartType), a;
                                });
                            }),
                            (e.prototype._makeGroupTooltipHtml = function (t) {
                                var e = this.data[t],
                                    i = "";
                                if (e) {
                                    var n = this._makeItemRenderingData(e.values, t);
                                    i = this.templateFunc(e.category, n, this.getRawCategory(t), t);
                                }
                                return i;
                            }),
                            (e.prototype._getTooltipSectorElement = function () {
                                if (!this.groupTooltipSector) {
                                    var t = (this.groupTooltipSector = m.default.create("DIV", "tui-chart-group-tooltip-sector"));
                                    m.default.append(this.tooltipContainer, t);
                                }
                                return this.groupTooltipSector;
                            }),
                            (e.prototype._makeVerticalTooltipSectorBound = function (t, e, i) {
                                var n = void 0;
                                return { dimension: { width: (n = i ? 1 : e.end - e.start), height: t }, position: { left: e.start + this.tickInterval / 2, top: E } };
                            }),
                            (e.prototype._makeHorizontalTooltipSectorBound = function (t, e) {
                                return { dimension: { width: t, height: e.end - e.start }, position: { left: E, top: e.start } };
                            }),
                            (e.prototype._makeTooltipSectorBound = function (t, e, i, n) {
                                return i ? this._makeVerticalTooltipSectorBound(t, e, n) : this._makeHorizontalTooltipSectorBound(t, e);
                            }),
                            (e.prototype._showTooltipSector = function (t, e, i, n, o) {
                                var r = this._getTooltipSectorElement(),
                                    a = e.start === e.end,
                                    s = this._makeTooltipSectorBound(t, e, i, a);
                                a ? this.eventBus.fire("showGroupTooltipLine", s) : (v.default.renderDimension(r, s.dimension), v.default.renderPosition(r, s.position), m.default.addClass(r, "show")),
                                o && (n -= 1),
                                    this.eventBus.fire("showGroupAnimation", n);
                            }),
                            (e.prototype._hideTooltipSector = function (t) {
                                var e = this._getTooltipSectorElement();
                                m.default.hasClass(e, "show") ? m.default.removeClass(e, "show") : this.eventBus.fire("hideGroupTooltipLine"), this.eventBus.fire("hideGroupAnimation", t), this.eventBus.fire("hideGroupTooltipLine");
                            }),
                            (e.prototype._showTooltip = function (t, e, i) {
                                (0, a.default)(this.prevIndex) || this.eventBus.fire("hideGroupAnimation", this.prevIndex),
                                (0, a.default)(this.tickInterval) && (this.tickInterval = e.tickInterval),
                                    (t.innerHTML = this._makeGroupTooltipHtml(e.index)),
                                    this._fireBeforeShowTooltipPublicEvent(e.index, e.range, e.silent),
                                document.getElementsByClassName && this.makeLineLegendIcon(t.querySelectorAll(".tui-chart-legend-rect.line")),
                                    m.default.addClass(t, "show"),
                                    this._showTooltipSector(e.size, e.range, e.isVertical, e.index, e.isMoving);
                                var n = this.getTooltipDimension(t),
                                    o = this.positionModel.calculatePosition(n, e.range);
                                this._moveToPosition(t, o, i), this._fireAfterShowTooltipPublicEvent(e.index, e.range, { element: t, position: o }, e.silent), (this.prevIndex = e.index);
                            }),
                            (e.prototype._fireBeforeShowTooltipPublicEvent = function (t, e, i) {
                                i || this.eventBus.fire(L + "beforeShowTooltip", { chartType: this.chartType, index: t, range: e });
                            }),
                            (e.prototype._fireAfterShowTooltipPublicEvent = function (t, e, i, n) {
                                n || this.eventBus.fire(L + "afterShowTooltip", Object.assign({ chartType: this.chartType, index: t, range: e }, i));
                            }),
                            (e.prototype._hideTooltip = function (t, e, i) {
                                var n = !(!i || !i.silent);
                                (this.prevIndex = null), this._fireBeforeHideTooltipPublicEvent(e, n), this._hideTooltipSector(e), m.default.removeClass(t, "show"), (t.style.cssText = "");
                            }),
                            (e.prototype._fireBeforeHideTooltipPublicEvent = function (t, e) {
                                e || this.eventBus.fire(L + "beforeHideTooltip", { chartType: this.chartType, index: t });
                            }),
                            e
                    );
                })(h.default);
            (o.componentType = "tooltip"), (o.GroupTooltip = S);
        },
        function (t, e, i) {
            "use strict";
            e.__esModule = !0;
            var n,
                o = (n = i(154)) && n.__esModule ? n : { default: n },
                r = (function () {
                    function t(e, i, n, o) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.chartDimension = e),
                            (this.areaBound = i),
                            (this.isVertical = n),
                            (this.options = o),
                            (this.positions = {}),
                            this._setData(e, i, n, o);
                    }
                    return (
                        (t.prototype._getHorizontalDirection = function () {
                            var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "";
                            return t.indexOf("left") > -1 ? o.default.TOOLTIP_DIRECTION_BACKWARD : t.indexOf("center") > -1 ? o.default.TOOLTIP_DIRECTION_CENTER : o.default.TOOLTIP_DIRECTION_FORWARD;
                        }),
                            (t.prototype._makeVerticalData = function (t, e, i) {
                                return {
                                    positionType: "left",
                                    sizeType: "width",
                                    direction: this._getHorizontalDirection(i),
                                    areaPosition: e.position.left,
                                    areaSize: e.dimension.width,
                                    chartSize: t.width,
                                    basePosition: o.default.SERIES_EXPAND_SIZE,
                                };
                            }),
                            (t.prototype._getVerticalDirection = function (t) {
                                return (t = t || "").indexOf("top") > -1 ? o.default.TOOLTIP_DIRECTION_BACKWARD : t.indexOf("bottom") > -1 ? o.default.TOOLTIP_DIRECTION_FORWARD : o.default.TOOLTIP_DIRECTION_CENTER;
                            }),
                            (t.prototype._makeHorizontalData = function (t, e, i) {
                                return {
                                    positionType: "top",
                                    sizeType: "height",
                                    direction: this._getVerticalDirection(i),
                                    areaPosition: e.position.top,
                                    areaSize: e.dimension.height,
                                    chartSize: t.height,
                                    basePosition: o.default.SERIES_EXPAND_SIZE,
                                };
                            }),
                            (t.prototype._setData = function (t, e, i, n) {
                                var o = this._makeVerticalData(t, e, n.align),
                                    r = this._makeHorizontalData(t, e, n.align),
                                    a = n.offset || {};
                                i ? ((this.mainData = o), (this.subData = r)) : ((this.mainData = r), (this.subData = o)),
                                    (this.positionOption = {}),
                                    (this.positionOption.left = a.x || 0),
                                    (this.positionOption.top = a.y || 0),
                                    (this.positions = {});
                            }),
                            (t.prototype._calculateMainPositionValue = function (t, e, i) {
                                var n = e.start === e.end,
                                    r = n ? 9 : 5;
                                return (
                                    i.basePosition +
                                    (i.direction === o.default.TOOLTIP_DIRECTION_FORWARD ? e.end + r : i.direction === o.default.TOOLTIP_DIRECTION_BACKWARD ? e.start - t - r : n ? e.start - t / 2 : e.start + (e.end - e.start - t) / 2)
                                );
                            }),
                            (t.prototype._calculateSubPositionValue = function (t, e) {
                                var i = e.areaSize / 2;
                                return e.direction === o.default.TOOLTIP_DIRECTION_FORWARD ? i + e.basePosition : e.direction === o.default.TOOLTIP_DIRECTION_BACKWARD ? i - t + e.basePosition : i - t / 2 + e.basePosition;
                            }),
                            (t.prototype._makePositionValueDiff = function (t, e, i) {
                                return t + i.areaPosition + e - i.chartSize;
                            }),
                            (t.prototype._adjustBackwardPositionValue = function (t, e, i, n) {
                                var r = void 0;
                                return (
                                    t < -n.areaPosition &&
                                    ((r = this._calculateMainPositionValue(i, e, { direction: o.default.TOOLTIP_DIRECTION_FORWARD, basePosition: n.basePosition })), (t = this._makePositionValueDiff(r, i, n) > 0 ? -n.areaPosition : r)),
                                        t
                                );
                            }),
                            (t.prototype._adjustForwardPositionValue = function (t, e, i, n) {
                                var r = this._makePositionValueDiff(t, i, n);
                                if (r > 0) {
                                    var a = this._calculateMainPositionValue(i, e, { direction: o.default.TOOLTIP_DIRECTION_BACKWARD, basePosition: n.basePosition });
                                    a < -n.areaPosition ? (t -= r) : (t = a);
                                }
                                return t;
                            }),
                            (t.prototype._adjustMainPositionValue = function (t, e, i, n) {
                                return (t =
                                    n.direction === o.default.TOOLTIP_DIRECTION_BACKWARD
                                        ? this._adjustBackwardPositionValue(t, e, i, n)
                                        : n.direction === o.default.TOOLTIP_DIRECTION_FORWARD
                                            ? this._adjustForwardPositionValue(t, e, i, n)
                                            : Math.min((t = Math.max(t, -n.areaPosition)), n.chartSize - n.areaPosition - i));
                            }),
                            (t.prototype._adjustSubPositionValue = function (t, e, i) {
                                return (t = i.direction === o.default.TOOLTIP_DIRECTION_FORWARD ? Math.min(t, i.chartSize - i.areaPosition - e) : Math.max(t, -i.areaPosition));
                            }),
                            (t.prototype._makeCachingKey = function (t) {
                                return t.start + "-" + t.end;
                            }),
                            (t.prototype._addPositionOptionValue = function (t, e) {
                                return t + this.positionOption[e];
                            }),
                            (t.prototype._makeMainPositionValue = function (t, e, i) {
                                var n = this._calculateMainPositionValue(t[i.sizeType], e, i);
                                return (n = this._addPositionOptionValue(n, i.positionType)), (n = this._adjustMainPositionValue(n, e, t[i.sizeType], i));
                            }),
                            (t.prototype._makeSubPositionValue = function (t, e) {
                                var i = this._calculateSubPositionValue(t[e.sizeType], e);
                                return (i = this._addPositionOptionValue(i, e.positionType)), (i = this._adjustSubPositionValue(i, t[e.sizeType], e));
                            }),
                            (t.prototype.calculatePosition = function (t, e) {
                                var i = this._makeCachingKey(e),
                                    n = this.mainData,
                                    o = this.subData,
                                    r = this.positions[i];
                                return r || (((r = {})[n.positionType] = this._makeMainPositionValue(t, e, n)), (r[o.positionType] = this._makeSubPositionValue(t, o)), (this.positions[i] = r)), r;
                            }),
                            (t.prototype.updateOptions = function (t) {
                                (this.options = t), this._setData(this.chartDimension, this.areaBound, this.isVertical, t);
                            }),
                            (t.prototype.updateBound = function (t) {
                                (this.areaBound = t), this._setData(this.chartDimension, t, this.isVertical, this.options);
                            }),
                            t
                    );
                })();
            e.default = r;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                return new d(t);
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(154),
                a = n(r),
                s = i(221),
                u = n(s),
                l = i(222),
                h = n(l),
                c = i(223),
                p = n(c),
                d = (function (t) {
                    function e(i) {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        var n = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, i));
                        return (n.mapModel = i.mapModel), (n.colorSpectrum = i.colorSpectrum), n;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._makeTooltipHtml = function (t) {
                                return p.default.tplMapChartDefault(t);
                            }),
                            (e.prototype._makeSingleTooltipHtml = function (t, e) {
                                var i = this.mapModel.getDatum(e.index),
                                    n = this.options.suffix ? " " + this.options.suffix : "";
                                return this.templateFunc({ name: i.name || i.code, value: i.label, suffix: n, cssText: "background-color: " + this.colorSpectrum.getColor(i.ratio) });
                            }),
                            (e.prototype._makeShowTooltipParams = function (t, e) {
                                var i = this.mapModel.getDatum(t.index);
                                return Object.assign({ chartType: this.chartType, code: i.code, name: i.name, value: i.label, index: t.index }, e);
                            }),
                            (e.prototype._setDefaultTooltipPositionOption = function () {
                                this.options.align || (this.options.align = a.default.TOOLTIP_DEFAULT_ALIGN_OPTION);
                            }),
                            e
                    );
                })(u.default);
            h.default.mixin(d), (o.componentType = "tooltip");
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                return new y(t);
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(229),
                a = n(r),
                s = i(154),
                u = n(s),
                l = i(211),
                h = n(l),
                c = i(155),
                p = n(c),
                d = i(148),
                f = n(d),
                g = i(153),
                m = n(g),
                y = (function (t) {
                    function e(i) {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        var n = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this));
                        return (n.chartType = i.chartType), (n.eventBus = i.eventBus), (n.isDown = !1), (n.drawingType = u.default.COMPONENT_TYPE_DOM), n;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._renderMouseEventDetectorArea = function (t) {
                                f.default.renderDimension(t, this.layout.dimension), f.default.renderPosition(t, this.layout.position);
                            }),
                            (e.prototype._onClick = function () {}),
                            (e.prototype._onMousedown = function (t) {
                                (this.isDown = !0), this.eventBus.fire("dragStartMapSeries", { left: t.clientX, top: t.clientY });
                            }),
                            (e.prototype._dragEnd = function () {
                                (this.isDrag = !1), p.default.removeClass(this.mouseEventDetectorContainer, "drag"), this.eventBus.fire("dragEndMapSeries");
                            }),
                            (e.prototype._onMouseup = function (t) {
                                (this.isDown = !1), this.isDrag ? this._dragEnd() : this._onMouseEvent("click", t), (this.isMove = !1);
                            }),
                            (e.prototype._onMousemove = function (t) {
                                this.isDown
                                    ? (this.isDrag || p.default.addClass(this.mouseEventDetectorContainer, "drag"), (this.isDrag = !0), this.eventBus.fire("dragMapSeries", { left: t.clientX, top: t.clientY }))
                                    : ((this.isMove = !0), this._onMouseEvent("move", t));
                            }),
                            (e.prototype._onMouseout = function (t) {
                                this.isDrag ? this._dragEnd() : this._onMouseEvent("move", t), (this.isDown = !1);
                            }),
                            (e.prototype._onMousewheel = function (t) {
                                var e = t.wheelDelta || t.detail * u.default.FF_WHEELDELTA_ADJUSTING_VALUE;
                                return this.eventBus.fire("wheel", e, { left: t.clientX, top: t.clientY }), t.preventDefault && t.preventDefault(), !1;
                            }),
                            (e.prototype.attachEvent = function (t) {
                                a.default.prototype.attachEvent.call(this, t), m.default.firefox ? h.default.on(t, "DOMMouseScroll", this._onMousewheel, this) : h.default.on(t, "mousewheel", this._onMousewheel, this);
                            }),
                            e
                    );
                })(a.default);
            o.componentType = "mouseEventDetector";
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(145),
                r = n(o),
                a = i(198),
                s = n(a),
                u = i(230),
                l = n(u),
                h = i(231),
                c = n(h),
                p = i(154),
                d = n(p),
                f = i(211),
                g = n(f),
                m = i(164),
                y = n(m),
                v = i(155),
                $ = n(v),
                x = i(148),
                T = n(x),
                b = (function () {
                    function t(e) {
                        if (
                            ((function t(e, i) {
                                if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                            })(this, t),
                                e)
                        ) {
                            (this.chartType = e.chartType),
                                (this.chartTypes = e.chartTypes),
                                (this.isVertical = e.isVertical),
                                (this.dataProcessor = e.dataProcessor),
                                (this.allowSelect = e.allowSelect),
                                (this.eventBus = e.eventBus),
                                (this.layout = null),
                                (this.selectedData = null);
                            var i = y.default.isLineTypeChart(this.chartType, this.chartTypes);
                            (this.expandSize = i ? d.default.SERIES_EXPAND_SIZE : 0),
                                (this.seriesItemBoundsData = []),
                                (this.seriesCount = y.default.isComboChart(this.chartType) ? 2 : 1),
                                this._attachToEventBus(),
                                (this.drawingType = d.default.COMPONENT_TYPE_DOM);
                        }
                    }
                    return (
                        (t.prototype._attachToEventBus = function () {
                            this.eventBus.on("receiveSeriesData", this.onReceiveSeriesData, this);
                        }),
                            (t.prototype._getRenderingBound = function () {
                                return T.default.expandBound(this.layout);
                            }),
                            (t.prototype._renderMouseEventDetectorArea = function (t, e) {
                                this.dimension = this.layout.dimension;
                                var i = new l.default(this.layout, e, this.chartType, this.isVertical, this.chartTypes);
                                this.tickBaseCoordinateModel = i;
                                var n = this._getRenderingBound(),
                                    o = n.dimension,
                                    r = n.position;
                                T.default.renderDimension(t, o), T.default.renderPosition(t, r);
                            }),
                            (t.prototype._setDataForRendering = function (t) {
                                this.layout = t.layout;
                            }),
                            (t.prototype._pickTickCount = function (t) {
                                return this.isVertical ? t.xAxis.eventTickCount || t.xAxis.tickCount : t.yAxis.tickCount;
                            }),
                            (t.prototype.render = function (t) {
                                this.positionMap = t.positionMap;
                                var e = t.paper,
                                    i = void 0;
                                return (
                                    $.default.addClass(e, "tui-chart-series-custom-event-area"),
                                        (e.style.backgroundColor = "aliceblue"),
                                    t.axisDataMap.xAxis && (i = this._pickTickCount(t.axisDataMap)),
                                        this._setDataForRendering(t),
                                        this._renderMouseEventDetectorArea(e, i),
                                        this.attachEvent(e),
                                        (this.mouseEventDetectorContainer = e),
                                        (this.transparentChild = this._createTransparentChild()),
                                        $.default.append(e, this.transparentChild),
                                        e
                                );
                            }),
                            (t.prototype._createTransparentChild = function () {
                                var t = document.createElement("DIV"),
                                    e = t.style;
                                return (e.backgroundColor = "#fff"), (e.height = T.default.getStyle(this.mouseEventDetectorContainer).height), T.default.setOpacity(t, 0), t;
                            }),
                            (t.prototype._calculateLayerPosition = function (t, e, i) {
                                var n = this.mouseEventDetectorContainer.getBoundingClientRect(),
                                    o = n.left,
                                    a = n.right,
                                    s = n.top,
                                    u = this.positionMap.series,
                                    l = this.expandSize,
                                    h = {};
                                return (i = !!(0, r.default)(i) || i) && (t = Math.min(Math.max(t, o + l), a - l)), (h.x = t - o + u.left - d.default.CHART_PADDING), (0, r.default)(e) || (h.y = e - s + u.top - d.default.CHART_PADDING), h;
                            }),
                            (t.prototype.onReceiveSeriesData = function (t) {
                                var e = this.seriesCount,
                                    i = this.seriesItemBoundsData;
                                i.length === e && (i = []), i.push(t), i.length === e && (this.boundsBaseCoordinateModel = new c.default(i));
                            }),
                            (t.prototype.rerender = function (t) {
                                var e = void 0;
                                (this.positionMap = t.positionMap),
                                t.axisDataMap.xAxis && (e = this._pickTickCount(t.axisDataMap)),
                                    (this.selectedData = null),
                                    this._setDataForRendering(t),
                                    this._renderMouseEventDetectorArea(this.mouseEventDetectorContainer, e),
                                    (this.transparentChild.style.height = T.default.getStyle(this.mouseEventDetectorContainer).height);
                            }),
                            (t.prototype.resize = function (t) {
                                (this.containerBound = null), this.rerender(t);
                            }),
                            (t.prototype._isChangedSelectData = function (t, e) {
                                return !t || !e || t.chartType !== e.chartType || t.indexes.groupIndex !== e.indexes.groupIndex || t.indexes.index !== e.indexes.index;
                            }),
                            (t.prototype._findDataFromBoundsCoordinateModel = function (t) {
                                var e = t.x,
                                    i = t.y,
                                    n = void 0;
                                return (n = y.default.isTreemapChart(this.chartType) ? 0 : this.tickBaseCoordinateModel.findIndex(this.isVertical ? e : i)), this.boundsBaseCoordinateModel.findData(n, e, i);
                            }),
                            (t.prototype._findData = function (t, e) {
                                var i = this._calculateLayerPosition(t, e);
                                return this._findDataFromBoundsCoordinateModel(i);
                            }),
                            (t.prototype._showTooltip = function () {}),
                            (t.prototype._hideTooltip = function () {}),
                            (t.prototype._onMouseEvent = function (t, e) {
                                $.default.addClass(this.mouseEventDetectorContainer, "hide"), this.eventBus.fire(t + "Series", { left: e.clientX, top: e.clientY }), $.default.removeClass(this.mouseEventDetectorContainer, "hide");
                            }),
                            (t.prototype.unselectSeries = function () {
                                this.selectedData && (this.eventBus.fire("unselectSeries", this.selectedData), (this.selectedData = null));
                            }),
                            (t.prototype._onClick = function (t) {
                                var e = this._findData(t.clientX, t.clientY);
                                this.selectSeries(e);
                            }),
                            (t.prototype.selectSeries = function (t) {
                                var e = !(arguments.length > 1 && void 0 !== arguments[1]) || arguments[1];
                                this._isChangedSelectData(this.selectedData, t) ? t && (this.selectedData && this.unselectSeries(), this.eventBus.fire("selectSeries", t, e), this.allowSelect && (this.selectedData = t)) : this.unselectSeries();
                            }),
                            (t.prototype._onMousedown = function () {}),
                            (t.prototype._onMouseup = function () {}),
                            (t.prototype._onMousemove = function () {}),
                            (t.prototype._onMouseout = function () {}),
                            (t.prototype.attachEvent = function (t) {
                                g.default.on(t, { click: this._onClick, mousedown: this._onMousedown, mouseup: this._onMouseup, mousemove: this._onMousemove, mouseout: this._onMouseout }, this);
                            }),
                            (t.prototype.findDataByIndexes = function () {}),
                            (t.prototype._setPrevClientPosition = function (t) {
                                t ? (this.prevClientPosition = { x: t.clientX, y: t.clientY }) : (this.prevClientPosition = null);
                            }),
                            t
                    );
                })();
            s.default.mixin(b), (e.default = b);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(144),
                r = n(o),
                a = i(164),
                s = n(a),
                u = i(158),
                l = n(u),
                h = (function () {
                    function t(e, i, n, o, r) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.isLineType = s.default.isLineTypeChart(n, r)),
                            (this.data = this._makeData(e, i, o));
                    }
                    return (
                        (t.prototype._getRanges = function (t, e, i) {
                            var n = e,
                                o = i / 2;
                            return (0, r.default)(0, t).map(function () {
                                var t = { min: n - o, max: n + o };
                                return (n += i), t;
                            });
                        }),
                            (t.prototype._makeLineTypeData = function (t, e, i) {
                                var n = this._getRanges(e, i || 0, (t + 1) / (e - 1));
                                return (n[e - 1].max -= 1), n;
                            }),
                            (t.prototype._makeNormalData = function (t, e, i) {
                                var n = e - 1,
                                    o = t / n,
                                    a = i || 0;
                                return (0, r.default)(0, n).map(function () {
                                    var e = l.default.min([t + a, o + a]),
                                        i = { min: a, max: e };
                                    return (a = e), i;
                                });
                            }),
                            (t.prototype._makeData = function (t, e, i) {
                                var n = i ? "width" : "height",
                                    o = i ? "left" : "top";
                                return this.isLineType ? this._makeLineTypeData(t.dimension[n], e, t.position[o]) : this._makeNormalData(t.dimension[n], e, t.position[o]);
                            }),
                            (t.prototype.findIndex = function (t) {
                                var e = -1;
                                return (
                                    this.data.forEach(function (i, n) {
                                        return !(i.min < t && i.max >= t) || ((e = n), !1);
                                    }),
                                        e
                                );
                            }),
                            (t.prototype.getLastIndex = function () {
                                return this.data.length - 1;
                            }),
                            (t.prototype.makeRange = function (t, e) {
                                var i = this.data[t],
                                    n = void 0;
                                return this.isLineType ? { start: (n = parseInt(i.max - (i.max - i.min) / 2, 10)), end: n } : { start: i.min - (e || 0), end: i.max - (e || 0) };
                            }),
                            t
                    );
                })();
            e.default = h;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(150),
                r = n(o),
                a = i(154),
                s = n(a),
                u = i(164),
                l = n(u),
                h = i(158),
                c = n(h),
                p = (function () {
                    function t(e) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.data = this._makeData(e));
                    }
                    return (
                        (t.prototype._makeTooltipData = function (t, e, i, n) {
                            return { sendData: { chartType: t, indexes: e, allowNegativeTooltip: i, bound: n }, bound: { left: n.left, top: n.top, right: n.left + n.width, bottom: n.top + n.height } };
                        }),
                            (t.prototype._makeRectTypePositionData = function (t, e) {
                                var i = this,
                                    n = !l.default.isBoxTypeChart(e);
                                return t.map(function (t, o) {
                                    return t.map(function (t, r) {
                                        return t ? i._makeTooltipData(e, { groupIndex: o, index: r }, n, t.end || t) : null;
                                    });
                                });
                            }),
                            (t.prototype._makeOutliersPositionDataForBoxplot = function (t, e, i) {
                                var n = this,
                                    o = !l.default.isBoxTypeChart(e);
                                []
                                    .concat(
                                        (function t(e) {
                                            if (Array.isArray(e)) {
                                                for (var i = 0, n = Array(e.length); i < e.length; i++) n[i] = e[i];
                                                return n;
                                            }
                                            return Array.from(e);
                                        })(t)
                                    )
                                    .forEach(function (t, r) {
                                        t.forEach(function (t, a) {
                                            var s = void 0;
                                            t.outliers &&
                                            t.outliers.length &&
                                            ((s = t.outliers.map(function (t, i) {
                                                var s = { top: t.top - 3, left: t.left - 3, width: 6, height: 6 };
                                                return n._makeTooltipData(e, { groupIndex: r, index: a, outlierIndex: i }, o, s);
                                            })),
                                                (i[r] = i[r].concat(s)));
                                        });
                                    });
                            }),
                            (t.prototype._makeDotTypePositionData = function (t, e) {
                                return t
                                    ? c.default.pivot(t).map(function (t, i) {
                                        return t.map(function (t, n) {
                                            return t
                                                ? {
                                                    sendData: { chartType: e, indexes: { groupIndex: i, index: n }, bound: t },
                                                    bound: { left: t.left - s.default.DOT_RADIUS, top: t.top - s.default.DOT_RADIUS, right: t.left + s.default.DOT_RADIUS, bottom: t.top + s.default.DOT_RADIUS },
                                                }
                                                : null;
                                        });
                                    })
                                    : [];
                            }),
                            (t.prototype._joinData = function (t) {
                                var e = [];
                                return (
                                    t.forEach(function (t) {
                                        t.forEach(function (t, i) {
                                            var n = void 0;
                                            e[i]
                                                ? ((n = e[i].length),
                                                    t.forEach(function (t) {
                                                        t && (t.sendData.indexes.legendIndex = t.sendData.indexes.index + n);
                                                    }),
                                                    (e[i] = e[i].concat(t)))
                                                : (e[i] = t);
                                        });
                                    }),
                                        e
                                );
                            }),
                            (t.prototype._makeData = function (t) {
                                var e = this,
                                    i = t.map(function (t) {
                                        var i = void 0;
                                        return (
                                            (i = l.default.isLineTypeChart(t.chartType) ? e._makeDotTypePositionData(t.data.groupPositions, t.chartType) : e._makeRectTypePositionData(t.data.groupBounds, t.chartType)),
                                            l.default.isBoxplotChart(t.chartType) && e._makeOutliersPositionDataForBoxplot(t.data.groupBounds, t.chartType, i),
                                                i
                                        );
                                    });
                                return this._joinData(i);
                            }),
                            (t.prototype._findCandidates = function (t, e, i) {
                                var n = this;
                                return t.filter(function (t) {
                                    var o = t && t.bound;
                                    return (
                                        !!o &&
                                        (o.top === o.bottom && ((o.top -= s.default.SERIES_EXTRA_EVENT_AREA_FOR_ZERO), (o.bottom += s.default.SERIES_EXTRA_EVENT_AREA_FOR_ZERO)),
                                        o.left === o.right && ((o.left -= s.default.SERIES_EXTRA_EVENT_AREA_FOR_ZERO), (o.right += s.default.SERIES_EXTRA_EVENT_AREA_FOR_ZERO)),
                                            n._isCandidateTarget(o, { layerX: e, layerY: i }))
                                    );
                                });
                            }),
                            (t.prototype._isCandidateTarget = function (t, e) {
                                var i = e.layerX,
                                    n = e.layerY,
                                    o = t.left <= i && t.right >= i,
                                    r = t.top <= n && t.bottom >= n;
                                return o && r;
                            }),
                            (t.prototype.findData = function (t, e, i) {
                                var n = 1e4,
                                    o = null;
                                return (
                                    t > -1 &&
                                    this.data[t] &&
                                    this._findCandidates(this.data[t], e, i).forEach(function (t) {
                                        var e = Math.abs(i - t.bound.top);
                                        n > e && ((n = e), (o = t.sendData));
                                    }),
                                        o
                                );
                            }),
                            (t.prototype.findDataByIndexes = function (t) {
                                var e = this.data[t.index][t.seriesIndex].sendData;
                                return (0, r.default)(t.outlierIndex) ? this._findOutlierDataByIndexes(t) : e;
                            }),
                            (t.prototype._findOutlierDataByIndexes = function (t) {
                                var e = null;
                                return (
                                    this.data[t.index].forEach(function (i) {
                                        var n = i.sendData.indexes,
                                            o = n.index === t.seriesIndex && n.outlierIndex === t.outlierIndex;
                                        return o && (e = i.sendData), !o;
                                    }),
                                        e
                                );
                            }),
                            t
                    );
                })();
            e.default = p;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                var e = t.chartOptions,
                    i = t.seriesTypes,
                    n = e.chartType,
                    o = e.series,
                    r = o.zoomable,
                    s = o.allowSelect,
                    l = e.xAxis.pointOnColumn,
                    c = void 0;
                return (
                    (c = t.chartOptions.tooltip.grouped
                        ? p.default
                        : a.default.isMapChart(n)
                            ? m.default
                            : a.default.isBarTypeChart(n) || a.default.isBoxplotChart(n) || a.default.isHeatmapChart(n) || a.default.isTreemapChart(n) || a.default.isBulletChart(n)
                                ? f.default
                                : a.default.isCoordinateTypeChart(n) || a.default.isPieChart(n) || a.default.isPieDonutComboChart(n, i)
                                    ? h.default
                                    : u.default),
                        (t.chartType = n),
                        (t.chartTypes = i),
                        (t.zoomable = r),
                        (t.allowSelect = s),
                        (t.pointOnColumn = !!l),
                        c(t)
                );
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(164),
                a = n(r),
                s = i(233),
                u = n(s),
                l = i(236),
                h = n(l),
                c = i(237),
                p = n(c),
                d = i(238),
                f = n(d),
                g = i(228),
                m = n(g);
            o.componentType = "mouseEventDetector";
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                return new c(t);
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(229),
                a = n(r),
                s = i(234),
                u = n(s),
                l = i(235),
                h = n(l),
                c = (function (t) {
                    function e(i) {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        var n = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, i));
                        return (n.prevFoundData = null), (n.prevClientPosition = null), (n.zoomable = i.zoomable), n.zoomable && (Object.assign(n, u.default), n._initForZoom(i.zoomable)), n;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype.animateForAddingData = function () {
                                if (this.prevClientPosition) {
                                    var t = this._findData(this.prevClientPosition.x, this.prevClientPosition.y);
                                    if (t) {
                                        var e = this.prevFoundData.indexes.groupIndex === t.indexes.groupIndex,
                                            i = this.prevFoundData && e;
                                        this._showTooltip(t, i);
                                    }
                                    this.prevFoundData = t;
                                }
                            }),
                            (e.prototype.onReceiveSeriesData = function (t) {
                                var e = this.seriesCount;
                                this.seriesItemBoundsData.length === e && (this.seriesItemBoundsData = []),
                                    this.seriesItemBoundsData.push(t),
                                this.seriesItemBoundsData.length === e && (this.dataModel = new h.default(this.seriesItemBoundsData)),
                                this.zoomable && this._showTooltipAfterZoom();
                            }),
                            (e.prototype._findData = function (t, e) {
                                var i = this._calculateLayerPosition(t, e),
                                    n = this.dataProcessor.selectLegendIndex,
                                    o = this.dataProcessor.isCoordinateType();
                                return this.dataModel.findData(i, n, { distanceLimit: this.dataModel.leftStepLength, isCoordinateTypeChart: o });
                            }),
                            (e.prototype._findDataForZoomable = function (t, e) {
                                var i = this._calculateLayerPosition(t, e);
                                return this.dataModel.findData(i);
                            }),
                            (e.prototype._getFirstData = function (t) {
                                return this.dataModel.getFirstData(t);
                            }),
                            (e.prototype._getLastData = function (t) {
                                return this.dataModel.getLastData(t);
                            }),
                            (e.prototype._showTooltip = function (t) {
                                this.eventBus.fire("showTooltip", t), (this.prevFoundData = t);
                            }),
                            (e.prototype._hideTooltip = function (t) {
                                this.eventBus.fire("hideTooltip", this.prevFoundData, t), (this.prevFoundData = null);
                            }),
                            (e.prototype._onMousemove = function (t) {
                                var e = void 0;
                                this._setPrevClientPosition(t);
                                var i = this._findData(t.clientX, t.clientY);
                                this.zoomable && (e = this._isAfterDragMouseup()), !e && this._isChangedSelectData(this.prevFoundData, i) && (i ? this._showTooltip(i) : this.prevFoundData && this._hideTooltip(), (this.prevFoundData = i));
                            }),
                            (e.prototype._onMouseout = function () {
                                this.prevFoundData && this._hideTooltip(), (this.prevClientPosition = null), (this.prevFoundData = null);
                            }),
                            (e.prototype.findDataByIndexes = function (t) {
                                return this.dataModel.findDataByIndexes(t);
                            }),
                            (e.prototype._setPrevClientPosition = function (t) {
                                t ? (this.prevClientPosition = { x: t.clientX, y: t.clientY }) : (this.prevClientPosition = null);
                            }),
                            e
                    );
                })(a.default);
            o.componentType = "mouseEventDetector";
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                r = i(137),
                a = n(r),
                s = i(229),
                u = n(s),
                l = i(154),
                h = n(l),
                c = i(155),
                p = n(c),
                d = i(164),
                f = n(d),
                g = i(158),
                m = n(g),
                y = i(148),
                v = n(y),
                $ = i(211),
                x = n($);
            e.default = {
                _initForZoom: function (t) {
                    (this.zoomable = t),
                        (this.dragStartIndexes = null),
                        (this.startClientPosition = null),
                        (this.startLayerX = null),
                        (this.dragSelectionElement = null),
                        (this.containerBound = null),
                        (this.isShowTooltipAfterZoom = !1),
                        (this.afterMouseup = !1),
                        (this.prevDistanceOfRange = null),
                        (this.reverseMove = null),
                        (this.resetZoomBtn = null);
                },
                presetBeforeRerender: function () {
                    this.resetZoomBtn && (this.mouseEventDetectorContainer.removeChild(this.resetZoomBtn), (this.resetZoomBtn = null)), this._hideTooltip(), (this.prevDistanceOfRange = null);
                },
                _showTooltipAfterZoom: function () {
                    var t = this.isShowTooltipAfterZoom,
                        e = void 0;
                    (this.isShowTooltipAfterZoom = !1), t && this.dragStartIndexes && (e = this.reverseMove ? this._getFirstData(this.dragStartIndexes.index) : this._getLastData(this.dragEndIndexes.index)) && this._showTooltip(e);
                },
                _updateDimensionForDragSelection: function (t) {
                    v.default.renderDimension(t, { height: this.layout.dimension.height });
                },
                _renderDragSelection: function () {
                    var t = p.default.create("DIV", "tui-chart-drag-selection");
                    return this._updateDimensionForDragSelection(t), t;
                },
                render: function (t) {
                    var e = u.default.prototype.render.call(this, t),
                        i = this._renderDragSelection();
                    return p.default.append(e, i), (this.dragSelectionElement = i), e;
                },
                resize: function (t) {
                    (this.containerBound = null), u.default.prototype.resize.call(this, t), this._updateDimensionForDragSelection(this.dragSelectionElement);
                },
                _onClick: function () {},
                _isAfterDragMouseup: function () {
                    var t = this.afterMouseup;
                    return t && (this.afterMouseup = !1), t;
                },
                _bindDragEvent: function (t) {
                    t.setCapture && t.setCapture(),
                        x.default.on(document, "mousemove", this._onDrag, this),
                        x.default.off(this.mouseEventDetectorContainer, "mouseup", this._onMouseup, this),
                        x.default.on(document, "mouseup", this._onMouseupAfterDrag, this);
                },
                _unbindDragEvent: function () {
                    this.downTarget && this.downTarget.releaseCapture && this.downTarget.releaseCapture(),
                        x.default.off(document, "mousemove", this._onDrag, this),
                        x.default.off(document, "mouseup", this._onMouseupAfterDrag, this),
                        x.default.on(this.mouseEventDetectorContainer, "mouseup", this._onMouseup, this);
                },
                _onMousedown: function (t) {
                    if (this.zoomable) {
                        var e = t.target || t.srcElement;
                        (this.startClientPosition = { x: t.clientX, y: t.clientY }), (this.startLayerX = this._calculateLayerPosition(t.clientX).x), (this.downTarget = e), this._bindDragEvent(e);
                    }
                },
                _showDragSelection: function (t) {
                    var e = this.mouseEventDetectorContainer.getBoundingClientRect().left,
                        i = this._calculateLayerPosition(t).x,
                        n = e - (this.startClientPosition.x - this.startLayerX),
                        o = Math.min(i, this.startLayerX) - n,
                        r = Math.abs(i - this.startLayerX),
                        a = this.dragSelectionElement;
                    (a.style.left = o + "px"), (a.style.width = r + "px"), p.default.addClass(a, "show");
                },
                _hideDragSelection: function () {
                    p.default.removeClass(this.dragSelectionElement, "show");
                },
                _onDrag: function (t) {
                    var e = this.startClientPosition,
                        i = t.target || t.srcElement;
                    if (e) {
                        var n = this._findDataForZoomable(e.x, e.y);
                        p.default.hasClass(i, h.default.CLASS_NAME_RESET_ZOOM_BTN) || ((0, a.default)(this.dragStartIndexes) ? (this.dragStartIndexes = n ? n.indexes : {}) : this._showDragSelection(t.clientX));
                    }
                },
                _changeIndexToHaveMinimumIndexes: function (t) {
                    var e = o(t, 2),
                        i = e[0],
                        n = e[1],
                        r = n - i;
                    return 0 === r ? (0 === i ? (n += 2) : ((i -= 1), (n += 1))) : 1 === r && (0 === i ? (n += 1) : (i -= 1)), { startIndex: i, endIndex: n };
                },
                _adjustIndexRange: function (t, e) {
                    var i = [t, e].sort(function (t, e) {
                        return t - e;
                    });
                    return this._changeIndexToHaveMinimumIndexes(i);
                },
                _fireZoom: function () {
                    this.dataProcessor.isLineCoordinateType() ? this._fireZoomUsingValue(this.dragStartIndexes, this.dragEndIndexes) : this._fireZoomUsingIndex(this.dragStartIndexes.groupIndex, this.dragEndIndexes.groupIndex);
                },
                _fireZoomUsingIndex: function (t, e) {
                    var i = this._adjustIndexRange(t, e),
                        n = i.startIndex,
                        o = i.endIndex,
                        r = o - n;
                    this.prevDistanceOfRange !== r && ((this.prevDistanceOfRange = r), (this.reverseMove = t > e), this.eventBus.fire("zoom", [n, o]));
                },
                _adjustValueRange: function (t, e) {
                    var i = void 0,
                        n = void 0,
                        o = this.dataProcessor,
                        r = o.integratedXAxisData,
                        a = o.options.xAxis;
                    f.default.isDatetimeType(a.type) ? ((i = m.default.findIndexFromDateTypeArray(r, new Date(t))), (n = m.default.findIndexFromDateTypeArray(r, new Date(e)))) : ((i = r.indexOf(t)), (n = r.indexOf(e)));
                    var s = [i, n].sort(function (t, e) {
                            return t - e;
                        }),
                        u = this._changeIndexToHaveMinimumIndexes(s),
                        l = u.startIndex,
                        h = u.endIndex;
                    return [r[l], r[h]];
                },
                _fireZoomUsingValue: function (t, e) {
                    var i = t.index,
                        n = t.groupIndex,
                        r = e.index,
                        a = e.groupIndex,
                        s = this.dataProcessor.rawData.series.line,
                        u = o(s[i].data[n], 1)[0],
                        l = o(s[r].data[a], 1)[0],
                        h = this._adjustValueRange(u, l);
                    this.eventBus.fire("zoom", h);
                },
                _setIsShowTooltipAfterZoomFlag: function (t, e) {
                    var i = this._calculateLayerPosition(t, e, !1).x,
                        n = this._calculateLayerPosition(t, e).x;
                    this.isShowTooltipAfterZoom = i === n;
                },
                _onMouseupAfterDrag: function (t) {
                    var e = this._findDataForZoomable(t.clientX, t.clientY);
                    if ((this._unbindDragEvent(), (0, a.default)(this.dragStartIndexes))) {
                        var i = t.target || t.srcElement;
                        p.default.hasClass(i, h.default.CLASS_NAME_RESET_ZOOM_BTN) ? (this._hideTooltip(), (this.prevDistanceOfRange = null), this.eventBus.fire("resetZoom")) : u.default.prototype._onClick.call(this, t);
                    } else
                        this.dragStartIndexes && e
                            ? ((this.dragEndIndexes = e.indexes), this._setIsShowTooltipAfterZoomFlag(t.clientX, t.clientY), this._hideDragSelection(), this._fireZoom())
                            : (this._setIsShowTooltipAfterZoomFlag(t.clientX, t.clientY), this._hideDragSelection());
                    (this.startClientPosition = null), (this.dragStartIndexes = null), (this.startLayerX = null), (this.afterMouseup = !0);
                },
                _renderResetZoomBtn: function () {
                    return p.default.create("DIV", h.default.CLASS_NAME_RESET_ZOOM_BTN);
                },
                zoom: function (t) {
                    (this.prevFoundData = null),
                        this.rerender(t),
                        this._updateDimensionForDragSelection(this.dragSelectionElement),
                        this.resetZoomBtn
                            ? t.isResetZoom && (this.mouseEventDetectorContainer.removeChild(this.resetZoomBtn), (this.resetZoomBtn = null))
                            : ((this.resetZoomBtn = this._renderResetZoomBtn()), p.default.append(this.mouseEventDetectorContainer, this.resetZoomBtn));
                },
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                if (Array.isArray(t)) {
                    for (var e = 0, i = Array(t.length); e < t.length; e++) i[e] = t[e];
                    return i;
                }
                return Array.from(t);
            }
            e.__esModule = !0;
            var r = i(164),
                a = n(r),
                s = i(158),
                u = n(s),
                l = i(137),
                h = n(l),
                c = (function () {
                    function t(e) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.data = this._makeData(e)),
                            (this.lastGroupIndex = 0);
                    }
                    return (
                        (t.prototype._makeData = function (t) {
                            var e,
                                i,
                                n = this,
                                r = t.length,
                                s = 0,
                                l = t.map(function (t, e) {
                                    var i = t.chartType,
                                        o = t.data,
                                        l = o.groupPositions || o.groupBounds;
                                    (a.default.isLineTypeChart(i) || a.default.isRadialChart(i)) && (l = u.default.pivot(l)), (s = Math.max(l.length - 1, s));
                                    var h = l.length > 1 && l[1][0] && l[0][0];
                                    return (
                                        (n.leftStepLength = h ? l[1][0].left - l[0][0].left : 0),
                                            l.map(function (t, n) {
                                                return t.map(function (t, o) {
                                                    var a = null;
                                                    return t && (a = { chartType: i, indexes: { groupIndex: n, index: o }, bound: t }), r > 1 && (a.indexes.legendIndex = e), a;
                                                });
                                            })
                                    );
                                });
                            return (
                                (l = (e = []).concat.apply(e, o(l))),
                                    (this.lastGroupIndex = s),
                                    (i = []).concat.apply(i, o(l)).filter(function (t) {
                                        return !!t;
                                    })
                            );
                        }),
                            (t.prototype.findData = function (t, e) {
                                var i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {},
                                    n = i.distanceLimit,
                                    o = i.isCoordinateTypeChart,
                                    r = n && n < this.leftStepLength;
                                return o || r ? this._findDataForCoordinateDistance(t, n, e) : this._findDataForFirstXPosition(t, e);
                            }),
                            (t.prototype._findDataForFirstXPosition = function (t, e) {
                                var i = this.data.reduce(
                                    function (e, i) {
                                        var n = Math.abs(t.x - i.bound.left);
                                        return n <= e.xMin && ((e.xMin = n), (e.xMinValue = i.bound.left)), e;
                                    },
                                    { xMin: Number.MAX_VALUE, xMinValue: 0 }
                                ).xMinValue;
                                return this.data.reduce(
                                    function (n, o) {
                                        var r = Math.abs(t.y - o.bound.top),
                                            a = {};
                                        return o.bound.left !== i ? (a = n) : (0, h.default)(e) || e !== o.indexes.index ? (r <= n.yMin ? ((a.yMin = r), (a.findFound = o)) : (a = n)) : ((a.yMin = Number.MIN_VALUE), (a.findFound = o)), a;
                                    },
                                    { yMin: Number.MAX_VALUE, findFound: null }
                                ).findFound;
                            }),
                            (t.prototype._findDataForCoordinateDistance = function (t, e, i) {
                                var n = 1e5,
                                    o = void 0;
                                return (
                                    (e = e || Number.MAX_VALUE),
                                        this.data.forEach(function (r) {
                                            var a = Math.sqrt(Math.pow(t.x - r.bound.left, 2) + Math.pow(t.y - r.bound.top, 2));
                                            a > e || (((0, h.default)(i) || i === r.indexes.index) && a <= n && ((n = a), (o = r)));
                                        }),
                                        o
                                );
                            }),
                            (t.prototype.findDataByIndexes = function (t) {
                                var e = t.index,
                                    i = t.seriesIndex,
                                    n = null;
                                return (
                                    this.data.forEach(function (t) {
                                        return t.indexes.groupIndex === e && t.indexes.index === i && (n = t), !n;
                                    }),
                                        n
                                );
                            }),
                            (t.prototype.getFirstData = function (t) {
                                return this.findDataByIndexes({ index: 0, seriesIndex: t });
                            }),
                            (t.prototype.getLastData = function (t) {
                                var e = { index: this.lastGroupIndex, seriesIndex: t };
                                return this.findDataByIndexes(e);
                            }),
                            t
                    );
                })();
            e.default = c;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                return new c(t);
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(154),
                a = n(r),
                s = i(229),
                u = n(s),
                l = i(148),
                h = n(l),
                c = (function (t) {
                    function e(i) {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        var n = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this));
                        return (n.chartType = i.chartType), (n.drawingType = a.default.COMPONENT_TYPE_DOM), (n.eventBus = i.eventBus), n;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._renderMouseEventDetectorArea = function (t) {
                                h.default.renderDimension(t, this.layout.dimension), h.default.renderPosition(t, this.layout.position);
                            }),
                            (e.prototype.onReceiveSeriesData = function () {}),
                            (e.prototype._onClick = function (t) {
                                this._onMouseEvent("click", t);
                            }),
                            (e.prototype._onMousemove = function (t) {
                                this._onMouseEvent("move", t);
                            }),
                            (e.prototype._onMouseout = function (t) {
                                this._onMouseEvent("move", t);
                            }),
                            e
                    );
                })(u.default);
            o.componentType = "mouseEventDetector";
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                return new f(t);
            }
            e.__esModule = !0;
            var r = function (t, e) {
                if (Array.isArray(t)) return t;
                if (Symbol.iterator in Object(t))
                    return (function t(e, i) {
                        var n = [],
                            o = !0,
                            r = !1,
                            a = void 0;
                        try {
                            for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                        } catch (l) {
                            (r = !0), (a = l);
                        } finally {
                            try {
                                !o && u.return && u.return();
                            } finally {
                                if (r) throw a;
                            }
                        }
                        return n;
                    })(t, e);
                throw TypeError("Invalid attempt to destructure non-iterable instance");
            };
            e.default = o;
            var a = i(137),
                s = n(a),
                u = i(154),
                l = n(u),
                h = i(229),
                c = n(h),
                p = i(234),
                d = n(p),
                f = (function (t) {
                    function e(i) {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        var n = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, i));
                        return (
                            (n.prevIndex = null), (n.zoomable = i.zoomable), (n.sizeType = n.isVertical ? "height" : "width"), (n.pointOnColumn = i.pointOnColumn), n.zoomable && (Object.assign(n, d.default), n._initForZoom(i.zoomable)), n
                        );
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype.initMouseEventDetectorData = function (t) {
                                c.default.prototype.initMouseEventDetectorData.call(this, t), this.zoomable && this._showTooltipAfterZoom();
                            }),
                            (e.prototype._findGroupData = function (t, e) {
                                var i = this._calculateLayerPosition(t, e, !0),
                                    n = void 0;
                                return (n = this.isVertical ? i.x : i.y), { indexes: { groupIndex: this.tickBaseCoordinateModel.findIndex(n) } };
                            }),
                            (e.prototype._findDataForZoomable = function (t, e) {
                                return this._findGroupData(t, e);
                            }),
                            (e.prototype._getFirstData = function () {
                                return { indexes: { groupIndex: 0 } };
                            }),
                            (e.prototype._getLastData = function () {
                                return { indexes: { groupIndex: this.tickBaseCoordinateModel.getLastIndex() } };
                            }),
                            (e.prototype._isOuterPosition = function (t, e) {
                                var i = this.dimension,
                                    n = i.width,
                                    o = i.height,
                                    r = this.layout.position,
                                    a = r.top,
                                    s = r.left;
                                return t < s || t > s + n || e < a || e > a + o;
                            }),
                            (e.prototype._showTooltip = function (t, e) {
                                var i = t.indexes.groupIndex,
                                    n = (this.isVertical ? this.layout.position.left : this.layout.position.top) - l.default.CHART_PADDING,
                                    o = this.tickBaseCoordinateModel.data,
                                    a = o.length;
                                if (this.pointOnColumn ? a - 1 > i : a > i) {
                                    var s = 0;
                                    if (this.pointOnColumn) {
                                        var u = r(o, 1)[0],
                                            h = u.min;
                                        s = u.max - h;
                                    }
                                    this.eventBus.fire("showTooltip", {
                                        index: i,
                                        range: this.tickBaseCoordinateModel.makeRange(i, n),
                                        size: this.dimension[this.sizeType],
                                        isVertical: this.isVertical,
                                        isMoving: e,
                                        silent: t.silent,
                                        tickInterval: s,
                                    }),
                                        (this.prevIndex = i);
                                }
                            }),
                            (e.prototype._hideTooltip = function (t) {
                                this.eventBus.fire("hideTooltip", this.prevIndex, t), (this.prevIndex = null);
                            }),
                            (e.prototype._onMousemove = function (t) {
                                if (!this.zoomable || !this._isAfterDragMouseup()) {
                                    var e = this._findGroupData(t.clientX, t.clientY),
                                        i = e.indexes.groupIndex;
                                    -1 === i ? this._onMouseout(t) : this.prevIndex !== i && this._showTooltip(e);
                                }
                            }),
                            (e.prototype._onMouseout = function (t) {
                                var e = this._calculateLayerPosition(t.clientX, t.clientY, !1),
                                    i = e.x,
                                    n = e.y;
                                this._isOuterPosition(i, n) && !(0, s.default)(this.prevIndex) && this._hideTooltip();
                            }),
                            e
                    );
                })(c.default);
            o.componentType = "mouseEventDetector";
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                return new d(t);
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(229),
                a = n(r),
                s = i(154),
                u = n(s),
                l = i(164),
                h = n(l),
                c = i(155),
                p = n(c),
                d = (function (t) {
                    function e() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        for (var i = arguments.length, n = Array(i), o = 0; o < i; o++) n[o] = arguments[o];
                        var r = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call.apply(t, [this].concat(n)));
                        return (r.prevFoundData = null), (r.zoomHistory = [-1]), (r.historyBackBtn = null), r;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._attachToEventBus = function () {
                                a.default.prototype._attachToEventBus.call(this), this.eventBus.on("afterZoom", this.onAfterZoom, this);
                            }),
                            (e.prototype._showTooltip = function (t) {
                                this.eventBus.fire("showTooltip", t), (this.prevFoundData = t);
                            }),
                            (e.prototype._hideTooltip = function (t) {
                                this.eventBus.fire("hideTooltip", this.prevFoundData, t), (this.prevFoundData = null), this.styleCursor(!1);
                            }),
                            (e.prototype.styleCursor = function (t) {
                                var e = this.mouseEventDetectorContainer;
                                t ? (e.style.cursor = "pointer") : (e.style.cursor = "default");
                            }),
                            (e.prototype._onMousemove = function (t) {
                                var e = t.clientX,
                                    i = t.clientY,
                                    n = this._calculateLayerPosition(e, i),
                                    o = this._findDataFromBoundsCoordinateModel(n);
                                if (this._isChangedSelectData(this.prevFoundData, o) && (this.prevFoundData && this._hideTooltip(), (this.prevFoundData = o), o)) {
                                    if (h.default.isTreemapChart(this.chartType)) {
                                        var r = this._getSeriesItemByIndexes(o.indexes);
                                        this.styleCursor(r.hasChild);
                                    } else h.default.isBulletChart(this.chartType) && (o.mousePosition = { left: e, top: i });
                                    this._showTooltip(o);
                                }
                            }),
                            (e.prototype._zoomHistoryBack = function () {
                                var t = this.zoomHistory[this.zoomHistory.length - 2];
                                this.zoomHistory.pop(), this.eventBus.fire("zoom", t), 1 === this.zoomHistory.length && (this.mouseEventDetectorContainer.removeChild(this.historyBackBtn), (this.historyBackBtn = null));
                            }),
                            (e.prototype._getSeriesItemByIndexes = function (t) {
                                return this.dataProcessor.getSeriesDataModel(u.default.CHART_TYPE_TREEMAP).getSeriesItem(t.groupIndex, t.index, !0);
                            }),
                            (e.prototype._onClick = function (t) {
                                var e = t.target || t.srcElement;
                                if ((a.default.prototype._onClick.call(this, t), h.default.isTreemapChart(this.chartType))) {
                                    if (p.default.hasClass(e, u.default.CLASS_NAME_RESET_ZOOM_BTN)) return this._hideTooltip(), void this._zoomHistoryBack();
                                    var i = this._calculateLayerPosition(t.clientX, t.clientY),
                                        n = this._findDataFromBoundsCoordinateModel(i);
                                    if (n) {
                                        if (!this._getSeriesItemByIndexes(n.indexes).hasChild) return;
                                        this._hideTooltip(), this.eventBus.fire("zoom", n.indexes.index);
                                    }
                                }
                            }),
                            (e.prototype._onMouseout = function (t) {
                                var e = t.clientX,
                                    i = t.clientY,
                                    n = this.mouseEventDetectorContainer.getBoundingClientRect(),
                                    o = n.left,
                                    r = n.right,
                                    a = n.top,
                                    s = n.bottom;
                                (o <= e && a <= i && r >= e && s >= i) || (this.prevFoundData && this._hideTooltip(), (this.prevFoundData = null));
                            }),
                            (e.prototype.onAfterZoom = function (t) {
                                this.historyBackBtn || ((this.historyBackBtn = p.default.create("DIV", u.default.CLASS_NAME_RESET_ZOOM_BTN)), p.default.append(this.mouseEventDetectorContainer, this.historyBackBtn)),
                                this.zoomHistory[this.zoomHistory.length - 1] !== t && this.zoomHistory.push(t);
                            }),
                            (e.prototype.findDataByIndexes = function (t) {
                                return this.boundsBaseCoordinateModel.findDataByIndexes(t);
                            }),
                            e
                    );
                })(a.default);
            o.componentType = "mouseEventDetector";
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                var e = t.chartTheme,
                    i = t.chartOptions.libType;
                return (t.libType = i), (t.chartType = "bar"), (t.chartBackground = e.chart.background), new g(t);
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(240),
                a = n(r),
                s = i(241),
                u = n(s),
                l = i(154),
                h = n(l),
                c = i(164),
                p = n(c),
                d = h.default.OVERLAPPING_WIDTH,
                f = h.default.TEXT_PADDING,
                g = (function (t) {
                    function e() {
                        return (
                            (function t(e, i) {
                                if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                            })(this, e),
                                (function t(e, i) {
                                    if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                                    return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                                })(this, t.apply(this, arguments))
                        );
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._makeBound = function (t, e, i, n, o) {
                                return { start: { top: i, left: n, width: 0, height: e }, end: { top: i, left: o, width: t, height: e } };
                            }),
                            (e.prototype._calculateAdditionalLeft = function (t) {
                                var e = 0;
                                return this.options.divided && t > 0 && (e = this.dimensionMap.yAxis.width + d), e;
                            }),
                            (e.prototype._makeBarChartBound = function (t, e, i, n, o) {
                                var r = t.baseBarSize,
                                    a = t.basePosition,
                                    s = t.barSize,
                                    u = t.itemCount,
                                    l = t.groupSize,
                                    h = t.pointInterval,
                                    c = n.ratioDistance,
                                    p = n.value,
                                    d = n.startRatio,
                                    f = n.stack,
                                    g = r * c,
                                    m = a + r * d + this._calculateAdditionalLeft(p),
                                    y = f !== e.prevStack,
                                    v = s * u > l,
                                    $ = void 0;
                                if (!i || (!this.options.diverging && y)) {
                                    var x = i ? this.dataProcessor.findStackIndex(f) : o;
                                    (e.top = e.baseTop + (v ? h : s) * x), (e.plusLeft = 0), (e.minusLeft = 0);
                                }
                                p >= 0 ? (($ = m + e.plusLeft), (e.plusLeft += g)) : ((e.minusLeft -= g), ($ = m + e.minusLeft)), (e.prevStack = f);
                                var T = e.top + h - s / 2;
                                return v || (T += ((h - s) / 2) * (u - 1)), this._makeBound(g, s, T, m, $);
                            }),
                            (e.prototype._makeBounds = function () {
                                var t = this,
                                    e = this._getSeriesDataModel(),
                                    i = p.default.isValidStackOption(this.options.stack),
                                    n = this.layout,
                                    o = n.dimension,
                                    r = o.width,
                                    a = o.height,
                                    s = n.position.top,
                                    u = this._makeBaseDataForMakingBound(a, r);
                                return e.map(function (e, n) {
                                    var o = n * u.groupSize + s,
                                        r = t._makeBarChartBound.bind(t, u, { baseTop: o, top: o, plusLeft: 0, minusLeft: 0, prevStack: null }, i);
                                    return e.map(r);
                                });
                            }),
                            (e.prototype._calculateTopPositionOfSumLabel = function (t, e) {
                                return t.top + (t.height - e + f) / 2;
                            }),
                            e
                    );
                })(a.default);
            u.default.mixin(g), (o.componentType = "series"), (o.BarChartSeries = g);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(140),
                r = n(o),
                a = i(214),
                s = n(a),
                u = i(146),
                l = n(u),
                h = i(137),
                c = n(h),
                p = i(154),
                d = n(p),
                f = i(155),
                g = n(f),
                m = i(164),
                y = n(m),
                v = i(148),
                $ = n(v),
                x = i(182),
                T = n(x),
                b = i(143),
                _ = n(b),
                A = d.default.COMPONENT_TYPE_RAPHAEL,
                D = d.default.PUBLIC_EVENT_PREFIX,
                C = d.default.CLASS_NAME_SERIES_LABEL,
                E = (function () {
                    function t(e) {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t);
                        var i = e.libType;
                        (this.className = "tui-chart-series-area"),
                            (this.chartType = e.chartType),
                            (this.seriesType = e.seriesType || e.chartType),
                            (this.componentType = e.componentType),
                            (this.dataProcessor = e.dataProcessor),
                            (this.eventBus = e.eventBus),
                            (this.chartBackground = e.chartBackground),
                            (this.options = e.options || {}),
                            (this.orgTheme = this.theme = e.theme),
                            (this.graphRenderer = T.default.get(i, e.chartType)),
                            (this.seriesContainer = null),
                            (this.seriesLabelContainer = null),
                            (this.seriesData = {}),
                            (this.selectedLegendIndex = null),
                            (this.labelShowEffector = null),
                            (this.paper = null),
                            (this.limit = null),
                            (this.aligned = null),
                            (this.layout = null),
                            (this.dimensionMap = null),
                            (this.positionMap = null),
                            (this.axisDataMap = null),
                            (this.beforeAxisDataMap = null),
                            (this.drawingType = A),
                            (this.supportSeriesLable = !0),
                            this._attachToEventBus();
                    }
                    return (
                        (t.prototype.decorateLabel = function (t) {
                            var e = this.options,
                                i = e.labelPrefix,
                                n = e.labelSuffix,
                                o = $.default.addPrefixSuffix,
                                a = $.default.addPrefixSuffixItem;
                            return ((0, r.default)(t) ? o : a).bind($.default)(t, void 0 === i ? "" : i, void 0 === n ? "" : n);
                        }),
                            (t.prototype.presetForChangeData = function () {
                                var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : this.orgTheme;
                                (this.orgTheme = t), (this.theme = t), "treemap" === this.chartType && (this.boundMap = null);
                            }),
                            (t.prototype._attachToEventBus = function () {
                                var t = function () {
                                    (this.isInitRenderCompleted = !0), this.eventBus.off("load", t);
                                }.bind(this);
                                this.eventBus.on(D + "load", t),
                                    this.eventBus.on(
                                        {
                                            selectLegend: this.onSelectLegend,
                                            selectSeries: this.onSelectSeries,
                                            unselectSeries: this.onUnselectSeries,
                                            hoverSeries: this.onHoverSeries,
                                            hoverOffSeries: this.onHoverOffSeries,
                                            showGroupAnimation: this.onShowGroupAnimation,
                                            hideGroupAnimation: this.onHideGroupAnimation,
                                        },
                                        this
                                    ),
                                this.onShowTooltip && this.eventBus.on("showTooltip", this.onShowTooltip, this),
                                this.onShowGroupTooltipLine && this.eventBus.on({ showGroupTooltipLine: this.onShowGroupTooltipLine, hideGroupTooltipLine: this.onHideGroupTooltipLine }, this),
                                this.onClickSeries && this.eventBus.on({ clickSeries: this.onClickSeries, moveSeries: this.onMoveSeries }, this);
                            }),
                            (t.prototype._getSeriesDataModel = function () {
                                return this.dataProcessor.getSeriesDataModel(this.seriesType);
                            }),
                            (t.prototype._makeSeriesData = function () {}),
                            (t.prototype.getSeriesData = function () {
                                return this.seriesData;
                            }),
                            (t.prototype._renderSeriesLabel = function () {}),
                            (t.prototype._renderConnector = function () {}),
                            (t.prototype._renderSeriesLabelArea = function (t) {
                                return this._renderSeriesLabel(t);
                            }),
                            (t.prototype._sendBoundsToMouseEventDetector = function (t) {
                                this.eventBus.fire("receiveSeriesData", { chartType: this.chartType, data: t });
                            }),
                            (t.prototype._renderSeriesArea = function (t, e) {
                                var i = this.dimensionMap.extendedSeries,
                                    n = (this.seriesData = this._makeSeriesData());
                                this._sendBoundsToMouseEventDetector(n),
                                (this.hasDataForRendering(n) || "map" === this.chartType) &&
                                (e && (this.seriesSet = e(i, n, t)),
                                y.default.isShowLabel(this.options) && this.supportSeriesLable && (this.labelSet = this._renderSeriesLabelArea(t)),
                                y.default.isBarTypeChart(this.chartType) &&
                                y.default.isValidStackOption(this.options.stack) &&
                                y.default.isRenderConnector(this.options.stack.connector) &&
                                (this.connectorSet = this._renderConnector(t, n, this.options.stack)));
                            }),
                            (t.prototype._makeParamsForGraphRendering = function (t, e) {
                                return Object.assign({ dimension: t, position: this.layout.position, chartType: this.seriesType, theme: this.theme, options: this.options }, e);
                            }),
                            (t.prototype._renderGraph = function (t, e, i) {
                                var n = this._makeParamsForGraphRendering(t, e);
                                return this.graphRenderer.render(i, n);
                            }),
                            (t.prototype._setDataForRendering = function (t) {
                                (this.paper = t.paper),
                                    (this.limit = t.limitMap[this.chartType]),
                                t.axisDataMap && t.axisDataMap.xAxis && (this.aligned = t.axisDataMap.xAxis.aligned),
                                    (this.layout = t.layout),
                                    (this.dimensionMap = t.dimensionMap),
                                    (this.positionMap = t.positionMap),
                                    (this.axisDataMap = t.axisDataMap);
                            }),
                            (t.prototype.render = function (t) {
                                if (((this.paper = t.paper), this._setDataForRendering(t), this._clearSeriesContainer(), (this.beforeAxisDataMap = this.axisDataMap), t.checkedLegends)) {
                                    var e = t.checkedLegends[this.seriesType];
                                    this.options.colorByPoint || (this.theme = this._getCheckedSeriesTheme(this.orgTheme, e));
                                }
                                this._renderSeriesArea(t.paper, this._renderGraph.bind(this)), this.paper.pushDownBackgroundToBottom && this.paper.pushDownBackgroundToBottom();
                            }),
                            (t.prototype._getCheckedSeriesTheme = function (t, e) {
                                if (!e.length) return t;
                                var i = JSON.parse(JSON.stringify(t));
                                return (
                                    (i.colors = i.colors.filter(function (t, i) {
                                        return e[i];
                                    })),
                                        i
                                );
                            }),
                            (t.prototype._clearSeriesContainer = function () {
                                this.seriesSet &&
                                this.seriesSet.remove &&
                                (this.seriesSet.forEach(function (t) {
                                    t.remove();
                                }, this),
                                    this.seriesSet.remove()),
                                this.labelSet &&
                                this.labelSet.remove &&
                                (this.labelSet.forEach(function (t) {
                                    t.remove();
                                }, this),
                                    this.labelSet.remove()),
                                this.connectorSet &&
                                this.connectorSet.remove &&
                                (this.connectorSet.forEach(function (t) {
                                    t.remove();
                                }, this),
                                    this.connectorSet.remove()),
                                    (this.seriesData = {});
                            }),
                            (t.prototype.rerender = function (t) {
                                var e = void 0;
                                "map" === this.seriesType || this.dataProcessor.getGroupCount(this.seriesType)
                                    ? (t.checkedLegends && ((e = t.checkedLegends[this.seriesType]), (this.theme = this._getCheckedSeriesTheme(this.orgTheme, e))),
                                        this._setDataForRendering(t),
                                        this._clearSeriesContainer(),
                                        this._renderSeriesArea(t.paper, this._renderGraph.bind(this)),
                                    this.labelShowEffector && clearInterval(this.labelShowEffector.timerId),
                                    (!e && this.isInitRenderCompleted) || this.animateComponent(!0),
                                    (0, c.default)(this.selectedLegendIndex) || this.graphRenderer.selectLegend(this.selectedLegendIndex))
                                    : this._clearSeriesContainer();
                            }),
                            (t.prototype._isLabelVisible = function () {
                                return !(!this.options.showLabel && !this.options.showLegend);
                            }),
                            (t.prototype._resizeGraph = function (t, e) {
                                return this.graphRenderer.resize(Object.assign({ dimension: this.dimensionMap.chart }, e)), this.seriesSet;
                            }),
                            (t.prototype.resize = function (t) {
                                this._clearSeriesContainer(), this._setDataForRendering(t), this._renderSeriesArea(t.paper, this._resizeGraph.bind(this)), this.rerender(t);
                            }),
                            (t.prototype._renderPosition = function (t, e) {
                                var i = $.default.isOldBrowser() ? 1 : 0;
                                $.default.renderPosition(t, { top: e.top - i, left: e.left - 2 * i });
                            }),
                            (t.prototype._getLimitDistanceFromZeroPoint = function (t, e) {
                                var i = e.min,
                                    n = e.max,
                                    o = n - i,
                                    r = 0,
                                    a = 0;
                                return i <= 0 && n >= 0 ? ((r = ((o + i) / o) * t), (a = ((o - n) / o) * t)) : i > 0 && (r = t), { toMax: r, toMin: a };
                            }),
                            (t.prototype._findLabelElement = function (t) {
                                return g.default.hasClass(t, C) ? t : g.default.findParentByClass(t, C);
                            }),
                            (t.prototype.onHoverSeries = function (t, e) {
                                e === this.chartType && this.graphRenderer.showAnimation && this.graphRenderer.showAnimation(t);
                            }),
                            (t.prototype.onHoverOffSeries = function (t, e) {
                                e === this.chartType && this.graphRenderer.hideAnimation && t && this.graphRenderer.hideAnimation(t);
                            }),
                            (t.prototype.onShowGroupAnimation = function (t) {
                                this.graphRenderer.showGroupAnimation && this.graphRenderer.showGroupAnimation(t);
                            }),
                            (t.prototype.onHideGroupAnimation = function (t) {
                                this.graphRenderer.hideGroupAnimation && this.graphRenderer.hideGroupAnimation(t);
                            }),
                            (t.prototype.animateComponent = function (t) {
                                var e = this;
                                this.graphRenderer.animate
                                    ? (this.seriesSet && this.graphRenderer.animate(this.animateSeriesLabelArea.bind(this, t), this.seriesSet),
                                    this.connectorSet && this.graphRenderer.animate(this.animateSeriesConnector.bind(this, t), this.connectorSet))
                                    : (this.animateSeriesLabelArea(t), this.animateSeriesConnector()),
                                    setTimeout(function () {
                                        e.options.animationDuration = _.default.getDefaultAnimationDuration(e.chartType);
                                    });
                            }),
                            (t.prototype._fireLoadEvent = function (t) {
                                t || this.eventBus.fire(D + "load");
                            }),
                            (t.prototype.animateSeriesLabelArea = function (t) {
                                return this._isLabelVisible() ? void (this.labelSet && this.labelSet.length && _.default.animateOpacity(this.labelSet, 0, 1, this.options.animationDuration)) : void this._fireLoadEvent(t);
                            }),
                            (t.prototype.animateSeriesConnector = function () {
                                this.connectorSet && this.connectorSet.length && _.default.animateOpacity(this.connectorSet, 0, 1, this.options.animationDuration);
                            }),
                            (t.prototype._makeExportationSeriesData = function (t) {
                                var e = t.indexes,
                                    i = (0, l.default)(e.legendIndex) ? e.legendIndex : e.index,
                                    n = this.dataProcessor.getLegendItem(i),
                                    o = (0, l.default)(e.groupIndex) ? e.groupIndex : 0,
                                    r = this._getSeriesDataModel().getSeriesItem(o, e.index),
                                    a = void 0;
                                return (0, l.default)(r) && ((a = { chartType: n.chartType, legend: n.label, legendIndex: i }).index = r.index), a;
                            }),
                            (t.prototype._executeGraphRenderer = function (t, e) {
                                var i = !1;
                                this.eventBus.fire("hideTooltipContainer"), this.seriesLabelContainer && g.default.hasClass(this.seriesLabelContainer, "show") && (g.default.removeClass(this.seriesLabelContainer, "show"), (i = !0));
                                var n = this.graphRenderer[e](t);
                                return i && g.default.addClass(this.seriesLabelContainer, "show"), this.eventBus.fire("showTooltipContainer"), n;
                            }),
                            (t.prototype.onSelectSeries = function (t, e) {
                                t.chartType === this.chartType &&
                                (this.eventBus.fire(D + "selectSeries", this._makeExportationSeriesData(t)),
                                    (e = !!(0, s.default)(e) || e),
                                this.options.allowSelect && this.graphRenderer.selectSeries && e && this.graphRenderer.selectSeries(t.indexes));
                            }),
                            (t.prototype.onUnselectSeries = function (t) {
                                t.chartType === this.chartType &&
                                (this.eventBus.fire(D + "unselectSeries", this._makeExportationSeriesData(t)), this.options.allowSelect && this.graphRenderer.unselectSeries && this.graphRenderer.unselectSeries(t.indexes));
                            }),
                            (t.prototype.onSelectLegend = function (t, e) {
                                this.seriesType === t || (0, c.default)(e) || (e = -1), (this.selectedLegendIndex = e), this._getSeriesDataModel().getGroupCount() && this.graphRenderer.selectLegend(e);
                            }),
                            (t.prototype.showLabel = function () {
                                (this.options.showLabel = !0), !this.seriesLabelContainer && this.supportSeriesLable && this._renderSeriesLabelArea(this.paper);
                            }),
                            (t.prototype.hideLabel = function () {
                                (this.options.showLabel = !1), this.seriesLabelContainer && (g.default.removeClass(this.seriesLabelContainer, "show"), g.default.removeClass(this.seriesLabelContainer, "opacity"));
                            }),
                            (t.prototype.isAvailableSeriesData = function () {
                                return !!(this.seriesData && this.seriesData.isAvailable && this.seriesData.isAvailable());
                            }),
                            (t.prototype.hasDataForRendering = function (t) {
                                return !(!t || !t.isAvailable());
                            }),
                            t
                    );
                })();
            e.default = E;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(154),
                r = n(o),
                a = i(242),
                s = n(a),
                u = i(164),
                l = n(u),
                h = i(173),
                c = n(h),
                p = i(148),
                d = n(p),
                f = i(143),
                g = n(f),
                m = i(146),
                y = n(m),
                v = r.default.CHART_PADDING,
                $ = r.default.LEGEND_LABEL_LEFT_PADDING,
                x = (function () {
                    function t() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t);
                    }
                    return (
                        (t.prototype._makeSeriesData = function () {
                            var t = this._makeBounds(this.layout.dimension);
                            return (
                                (this.groupBounds = t),
                                    {
                                        groupBounds: t,
                                        seriesDataModel: this._getSeriesDataModel(),
                                        isAvailable: function () {
                                            return t && t.length > 0;
                                        },
                                    }
                            );
                        }),
                            (t.prototype._getBarWidthOptionSize = function (t, e) {
                                var i = 0;
                                return e && (e / 2 >= t ? (e = 2 * t) : e < 0 && (e = 0), (i = e)), i;
                            }),
                            (t.prototype._calculateAdditionalPosition = function (t, e, i) {
                                var n = 0;
                                return e && e < t && (n = t / 2 + ((t - e) * i) / 2), n;
                            }),
                            (t.prototype._makeBaseDataForMakingBound = function (t, e) {
                                var i = l.default.isValidStackOption(this.options.stack),
                                    n = this._getSeriesDataModel(),
                                    o = t / n.getGroupCount(),
                                    r = -this.layout.position.top + v,
                                    a = this._getLimitDistanceFromZeroPoint(e, this.limit).toMin,
                                    s = void 0,
                                    u = void 0;
                                if (((s = l.default.isColumnChart(this.chartType) ? r : l.default.isBoxplotChart(this.chartType) ? this.layout.position.top - v : this.layout.position.left), n.rawSeriesData.length > 0)) {
                                    var h = void 0,
                                        c = o / ((h = i ? (this.options.diverging ? 1 : this.dataProcessor.getStackCount(this.seriesType)) : n.getFirstSeriesGroup().getSeriesItemCount()) + 1),
                                        p = this.options.barWidth || this.options.pointWidth,
                                        d = 0.85 * c,
                                        f = a + s;
                                    (d = this._getBarWidthOptionSize(c, p) || d),
                                    l.default.isColumnChart(this.chartType) && (f = e - f),
                                    l.default.isBoxplotChart(this.chartType) && a && (f -= 2 * a),
                                        (u = { baseBarSize: e, groupSize: o, barSize: d, pointInterval: c, basePosition: f, itemCount: h, firstAdditionalPosition: c });
                                }
                                return u;
                            }),
                            (t.prototype._renderConnector = function (t, e, i) {
                                return this.graphRenderer.renderConnector(t, e, i);
                            }),
                            (t.prototype._renderNormalSeriesLabel = function (t) {
                                var e = this,
                                    i = this.graphRenderer,
                                    n = this._getSeriesDataModel(),
                                    o = this.seriesData.groupBounds,
                                    r = this.theme.label,
                                    a = this.selectedLegendIndex,
                                    u = n.map(function (t) {
                                        return t.map(function (t) {
                                            var i = t.start,
                                                n = t.startLabel,
                                                o = t.endLabel,
                                                r = { end: e.decorateLabel(o) };
                                            return (0, y.default)(i) && (r.start = e.decorateLabel(n)), r;
                                        });
                                    }),
                                    h = void 0;
                                return (h = l.default.isBarChart(this.chartType) ? s.default.boundsToLabelPositionsForBarChart(n, o, r) : s.default.boundsToLabelPositionsForColumnChart(n, o, r)), i.renderSeriesLabel(t, h, u, r, a);
                            }),
                            (t.prototype._makeSumValues = function (t) {
                                return d.default.formatValue({ value: c.default.sum(t), formatFunctions: this.dataProcessor.getFormatFunctions(), chartType: this.chartType, areaType: "series" });
                            }),
                            (t.prototype._makeStackedLabelPosition = function (t) {
                                var e = t.top;
                                return { left: t.left + t.width / 2, top: e + t.height / 2 };
                            }),
                            (t.prototype._makeStackedLabelPositions = function (t) {
                                var e = this;
                                return t.seriesGroup.map(function (i, n) {
                                    var o = t.bounds[n],
                                        r = void 0;
                                    return o && i && (r = e._makeStackedLabelPosition(o.end)), { end: r };
                                });
                            }),
                            (t.prototype.getGroupLabels = function (t, e, i) {
                                var n = this,
                                    o = l.default.isNormalStack(this.options.stack);
                                return t.map(function (t) {
                                    var r = t.map(function (t) {
                                        return { end: n.decorateLabel(t.endLabel) };
                                    });
                                    if (o) {
                                        e.push(c.default.sumPlusValues(t.pluck("value")));
                                        var a = c.default.sumMinusValues(t.pluck("value"));
                                        a < 0 && i.push(a);
                                    }
                                    return r;
                                });
                            }),
                            (t.prototype.getGroupPositions = function (t, e) {
                                var i = this;
                                return t.map(function (t, n) {
                                    return i._makeStackedLabelPositions({ seriesGroup: t, bounds: e[n] });
                                });
                            }),
                            (t.prototype._renderStackedSeriesLabel = function (t) {
                                var e = this,
                                    i = [],
                                    n = [],
                                    o = this.theme.label,
                                    r = this.seriesData.groupBounds,
                                    a = this._getSeriesDataModel(),
                                    s = this.getGroupPositions(a, r),
                                    u = this.getGroupLabels(a, i, n),
                                    h = l.default.isNormalStack(this.options.stack),
                                    c = l.default.isBarChart(this.chartType),
                                    p = c ? "width" : "height",
                                    f = c ? "left" : "top",
                                    m = c ? 1 : -1;
                                return (
                                    h &&
                                    (u.forEach(function (t, o) {
                                        var r = i[o],
                                            a = n[o];
                                        a < 0 && e.options.diverging && (a *= -1), t.push({ end: e.decorateLabel(d.default.formatToComma(r)) }), n.length && t.push({ end: e.decorateLabel(d.default.formatToComma(a)) });
                                    }),
                                        s.forEach(function (t, a) {
                                            var s = r[a],
                                                u = s[s.length - 1].end,
                                                l = s[Math.max(parseInt(s.length / 2, 10), 1) - 1].end,
                                                h = e._makeStackedLabelPosition(u),
                                                c = e._makeStackedLabelPosition(l),
                                                d = i[a],
                                                y = n[a],
                                                v = g.default.getRenderedTextSize(d, o.fontSize, o.fontFamily),
                                                x = g.default.getRenderedTextSize(y, o.fontSize, o.fontFamily),
                                                T = (u[p] + v[p]) / 2,
                                                b = (l[p] + x[p]) / 2;
                                            (h[f] += (T + $) * m), (c[f] -= (b + $) * m), t.push({ end: h }), n.length && t.push({ end: c });
                                        })),
                                        this.graphRenderer.renderSeriesLabel(t, s, u, o, !0)
                                );
                            }),
                            (t.prototype._renderSeriesLabel = function (t) {
                                return this.options.stack ? this._renderStackedSeriesLabel(t) : this._renderNormalSeriesLabel(t);
                            }),
                            t
                    );
                })();
            (x.mixin = function (t) {
                Object.assign(t.prototype, x.prototype);
            }),
                (e.default = x);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(154),
                r = n(o),
                a = i(148),
                s = n(a),
                u = r.default.MAX_HEIGHT_WORD,
                l = r.default.SERIES_LABEL_PADDING;
            e.default = {
                _calculateLeftPositionForCenterAlign: function (t) {
                    return t.left + t.width / 2;
                },
                _calculateTopPositionForMiddleAlign: function (t) {
                    return t.top + t.height / 2;
                },
                _makePositionForBoundType: function (t) {
                    return { left: this._calculateLeftPositionForCenterAlign(t), top: this._calculateTopPositionForMiddleAlign(t) };
                },
                _makePositionMap: function (t, e, i, n, o) {
                    var r = t.value,
                        a = r >= 0,
                        s = { end: o(e, i, t.endLabel || t.label, n, a) };
                    return t.isRange && ((a = r < 0), (s.start = o(e, i, t.startLabel, n, a))), s;
                },
                boundsToLabelPositions: function (t, e, i, n, o) {
                    var r = this,
                        a = s.default.getRenderedLabelHeight(u, i);
                    return (
                        (n = n || this._makePositionForBoundType.bind(this)),
                            (o = !!o),
                            t.map(function (t, o) {
                                var s = e[o];
                                return t.map(function (t, e) {
                                    var o = s[e].end;
                                    return r._makePositionMap(t, o, a, i, n);
                                });
                            }, o)
                    );
                },
                _makePositionForBarChart: function (t, e, i, n, o) {
                    var r = s.default.getRenderedLabelWidth(i, n),
                        a = t.left;
                    return o ? (a += t.width + l) : (a -= r + l), { left: a, top: this._calculateTopPositionForMiddleAlign(t) };
                },
                boundsToLabelPositionsForBarChart: function (t, e, i) {
                    var n = this._makePositionForBarChart.bind(this);
                    return this.boundsToLabelPositions(t, e, i, n);
                },
                _makePositionForColumnChart: function (t, e, i, n, o) {
                    var r = t.top;
                    return o ? (r -= e + l) : (r += t.height + l), { left: this._calculateLeftPositionForCenterAlign(t), top: r };
                },
                boundsToLabelPositionsForColumnChart: function (t, e, i) {
                    var n = this._makePositionForColumnChart.bind(this);
                    return this.boundsToLabelPositions(t, e, i, n);
                },
                boundsToLabelPostionsForTreemap: function (t, e) {
                    var i = this;
                    return t.map(function (t) {
                        var n = e[t.id],
                            o = void 0;
                        return n && (o = i._makePositionForBoundType(n)), o;
                    });
                },
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                return (t.libType = t.chartOptions.libType), (t.chartType = "column"), (t.chartBackground = t.chartTheme.chart.background), new g(t);
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(240),
                a = n(r),
                s = i(241),
                u = n(s),
                l = i(154),
                h = n(l),
                c = i(164),
                p = n(c),
                d = i(148),
                f = n(d),
                g = (function (t) {
                    function e() {
                        return (
                            (function t(e, i) {
                                if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                            })(this, e),
                                (function t(e, i) {
                                    if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                                    return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                                })(this, t.apply(this, arguments))
                        );
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._makeBound = function (t, e, i, n, o) {
                                return { start: { top: n, left: i, width: t, height: 0 }, end: { top: o, left: i, width: t, height: e } };
                            }),
                            (e.prototype._makeColumnChartBound = function (t, e, i, n, o) {
                                var r = t.baseBarSize,
                                    a = t.basePosition,
                                    s = t.barSize,
                                    u = t.itemCount,
                                    l = t.groupSize,
                                    c = t.pointInterval,
                                    p = Math.abs(r * n.ratioDistance),
                                    d = a + r * n.startRatio + h.default.SERIES_EXPAND_SIZE,
                                    f = n.stack !== e.prevStack,
                                    g = s * u > l,
                                    m = void 0,
                                    y = void 0;
                                if (!i || (!this.options.diverging && f)) {
                                    var v = i ? this.dataProcessor.findStackIndex(n.stack) : o;
                                    (e.left = e.baseLeft + (g ? c : s) * v), (e.plusTop = 0), (e.minusTop = 0);
                                }
                                return (
                                    n.value >= 0 ? ((e.plusTop -= p), (m = d + e.plusTop)) : ((m = d + e.minusTop), (e.minusTop += p)),
                                        (e.prevStack = n.stack),
                                        (y = g ? e.left + c - s / 2 : e.left + c - s / 2 + ((c - s) / 2) * (u - 1)),
                                        this._makeBound(s, p, y, d, m)
                                );
                            }),
                            (e.prototype._makeBounds = function () {
                                var t = this,
                                    e = this._getSeriesDataModel(),
                                    i = p.default.isValidStackOption(this.options.stack),
                                    n = this.layout.dimension,
                                    o = n.width,
                                    r = n.height,
                                    a = this._makeBaseDataForMakingBound(o, r);
                                return e.map(function (e, n) {
                                    var o = n * a.groupSize + t.layout.position.left,
                                        r = t._makeColumnChartBound.bind(t, a, { baseLeft: o, left: o, plusTop: 0, minusTop: 0, prevStack: null }, i);
                                    return e.map(r);
                                });
                            }),
                            (e.prototype._calculateLeftPositionOfSumLabel = function (t, e) {
                                return t.left + (t.width - f.default.getRenderedLabelWidth(e, this.theme.label) + h.default.TEXT_PADDING) / 2;
                            }),
                            e
                    );
                })(a.default);
            u.default.mixin(g), (o.componentType = "series"), (o.ColumnChartSeries = g);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                return (t.libType = t.chartOptions.libType), (t.chartType = "line"), (t.chartBackground = t.chartTheme.chart.background), new l(t);
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(240),
                a = n(r),
                s = i(245),
                u = n(s),
                l = (function (t) {
                    function e() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        for (var i = arguments.length, n = Array(i), o = 0; o < i; o++) n[o] = arguments[o];
                        var r = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call.apply(t, [this].concat(n)));
                        return (r.movingAnimation = null), r;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._makePositions = function (t) {
                                return this._makeBasicPositions(t);
                            }),
                            (e.prototype._makeSeriesData = function () {
                                var t = this._makePositions();
                                return {
                                    chartBackground: this.chartBackground,
                                    groupPositions: t,
                                    isAvailable: function () {
                                        return t && t.length > 0;
                                    },
                                };
                            }),
                            (e.prototype.rerender = function (t) {
                                return this._cancelMovingAnimation(), a.default.prototype.rerender.call(this, t);
                            }),
                            e
                    );
                })(a.default);
            u.default.mixin(l), (o.componentType = "series");
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(146),
                r = n(o),
                a = i(137),
                s = n(a),
                u = i(158),
                l = n(u),
                h = i(154),
                c = n(h),
                p = i(164),
                d = n(p),
                f = i(173),
                g = n(f),
                m = i(148),
                y = n(m),
                v = c.default.SERIES_EXPAND_SIZE,
                $ = c.default.SERIES_LABEL_PADDING,
                x = c.default.MAX_HEIGHT_WORD,
                T = c.default.ADDING_DATA_ANIMATION_DURATION,
                b = (function () {
                    function t() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t);
                    }
                    return (
                        (t.prototype._makePositionsForDefaultType = function (t) {
                            var e = this.layout.dimension,
                                i = e.height,
                                n = e.width,
                                o = this._getSeriesDataModel(),
                                a = t || n || 0,
                                u = o.getGroupCount(),
                                l = this.layout.position.top,
                                h = this.layout.position.left,
                                c = void 0;
                            return (
                                this.aligned ? (c = a / (u > 1 ? u - 1 : u)) : (h += (c = a / u) / 2),
                                    o.map(function (t) {
                                        return t.map(function (t, e) {
                                            var n = void 0;
                                            return (0, s.default)(t.end) || ((n = { left: h + c * e, top: l + i - t.ratio * i }), (0, r.default)(t.startRatio) && (n.startTop = l + i - t.startRatio * i)), n;
                                        });
                                    }, !0)
                            );
                        }),
                            (t.prototype._makePositionForCoordinateType = function (t) {
                                var e = this.layout.dimension,
                                    i = this._getSeriesDataModel(),
                                    n = e.height,
                                    o = this.axisDataMap.xAxis,
                                    a = this.layout.position.top,
                                    u = this.layout.position.left,
                                    l = t || e.width || 0,
                                    h = 0;
                                return (
                                    o.sizeRatio && ((h = g.default.multiply(l, o.positionRatio)), (l = g.default.multiply(l, o.sizeRatio))),
                                        i.map(function (t) {
                                            return t.map(function (t) {
                                                var e = void 0;
                                                return (
                                                    (0, s.default)(t.end) ||
                                                    ((e = { left: u + t.ratioMap.x * l + h, top: a + n - t.ratioMap.y * n }), (0, r.default)(t.ratioMap.start) && (e.startTop = n - t.ratioMap.start * n + c.default.SERIES_EXPAND_SIZE)),
                                                        e
                                                );
                                            });
                                        }, !0)
                                );
                            }),
                            (t.prototype._makeBasicPositions = function (t) {
                                return this.dataProcessor.isCoordinateType() ? this._makePositionForCoordinateType(t) : this._makePositionsForDefaultType(t);
                            }),
                            (t.prototype._calculateLabelPositionTop = function (t, e, i, n) {
                                var o = t.top;
                                return d.default.isValidStackOption(this.options.stack) ? (t.startTop + o - i) / 2 + 1 : (e >= 0 && !n) || (e < 0 && n) ? o - i - $ : o + $;
                            }),
                            (t.prototype._makeLabelPosition = function (t, e, i, n, o) {
                                return { left: t.left, top: this._calculateLabelPositionTop(t, n, e / 2, o) };
                            }),
                            (t.prototype._getLabelPositions = function (t, e) {
                                var i = this,
                                    n = l.default.pivot(this.seriesData.groupPositions),
                                    o = y.default.getRenderedLabelHeight(x, e);
                                return t.map(function (t, e) {
                                    return t.map(function (t, r) {
                                        var a = n[e][r],
                                            s = { end: i._makeLabelPosition(a, o, t.endLabel, t.end) };
                                        return t.isRange && ((a.top = a.startTop), (s.start = i._makeLabelPosition(a, o, t.startLabel, t.start))), s;
                                    });
                                });
                            }),
                            (t.prototype._getLabelTexts = function (t) {
                                var e = this;
                                return t.map(function (t) {
                                    return t.map(function (t) {
                                        var i = t.endLabel,
                                            n = t.isRange,
                                            o = t.startLabel,
                                            r = { end: e.decorateLabel(i) };
                                        return n && (r.start = e.decorateLabel(o)), r;
                                    });
                                });
                            }),
                            (t.prototype._renderSeriesLabel = function (t) {
                                var e = this.theme.label,
                                    i = this._getSeriesDataModel(),
                                    n = this._getLabelTexts(i),
                                    o = this._getLabelPositions(i, e);
                                return this.graphRenderer.renderSeriesLabel(t, o, n, e);
                            }),
                            (t.prototype.onShowGroupTooltipLine = function (t) {
                                this.graphRenderer.showGroupTooltipLine && this.graphRenderer.showGroupTooltipLine(t, this.layout);
                            }),
                            (t.prototype.onHideGroupTooltipLine = function () {
                                this.isAvailableSeriesData() && this.graphRenderer.hideGroupTooltipLine && this.graphRenderer.hideGroupTooltipLine();
                            }),
                            (t.prototype.zoom = function (t) {
                                this._cancelMovingAnimation(),
                                    this._clearSeriesContainer(t.paper),
                                    this._setDataForRendering(t),
                                    this._renderSeriesArea(t.paper, this._renderGraph.bind(this)),
                                (0, s.default)(this.selectedLegendIndex) || this.graphRenderer.selectLegend(this.selectedLegendIndex);
                            }),
                            (t.prototype._isChangedLimit = function (t, e) {
                                return t.min !== e.min || t.max !== e.max;
                            }),
                            (t.prototype._isChangedAxisLimit = function () {
                                var t = this.beforeAxisDataMap,
                                    e = this.axisDataMap,
                                    i = !0;
                                return t && ((i = this._isChangedLimit(t.yAxis.limit, e.yAxis.limit)), e.xAxis.limit && (i = i || this._isChangedLimit(t.xAxis.limit, e.xAxis.limit))), (this.beforeAxisDataMap = e), i;
                            }),
                            (t.prototype._animate = function (t) {
                                var e = this;
                                this._isChangedAxisLimit() && this.seriesLabelContainer && (this.seriesLabelContainer.innerHTML = ""),
                                t &&
                                (this.movingAnimation = y.default.startAnimation(T, t, function () {
                                    e.movingAnimation = null;
                                }));
                            }),
                            (t.prototype._makeZeroTopForAddingData = function () {
                                var t = this.layout.dimension.height,
                                    e = this.axisDataMap.yAxis.limit;
                                return this._getLimitDistanceFromZeroPoint(t, e).toMax + v;
                            }),
                            (t.prototype.animateForAddingData = function (t) {
                                var e = t.tickSize,
                                    i = t.limitMap,
                                    n = t.axisDataMap,
                                    o = this.dimensionMap.extendedSeries,
                                    r = this.options.shifting,
                                    a = this.layout.dimension.width;
                                (this.limit = i[this.chartType]), (this.axisDataMap = n);
                                var s = this._makeSeriesData(),
                                    u = this._makeParamsForGraphRendering(o, s);
                                r && (a += e);
                                var l = this._makePositions(a),
                                    h = this._makeZeroTopForAddingData();
                                this.graphRenderer.animateForAddingData(u, e, l, r, h);
                            }),
                            (t.prototype._cancelMovingAnimation = function () {
                                this.movingAnimation && (cancelAnimationFrame(this.movingAnimation.id), (this.movingAnimation = null));
                            }),
                            t
                    );
                })();
            (b.mixin = function (t) {
                Object.assign(t.prototype, b.prototype);
            }),
                (e.default = b);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                return (t.libType = t.chartOptions.libType), (t.chartType = t.chartOptions.chartType), (t.chartBackground = t.chartTheme.background), new v(t);
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(137),
                a = n(r),
                s = i(240),
                u = n(s),
                l = i(154),
                h = n(l),
                c = i(173),
                p = n(c),
                d = i(205),
                f = n(d),
                g = h.default.COMPONENT_TYPE_RAPHAEL,
                m = h.default.RADIAL_PLOT_PADDING,
                y = h.default.RADIAL_MARGIN_FOR_CATEGORY,
                v = (function (t) {
                    function e() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        for (var i = arguments.length, n = Array(i), o = 0; o < i; o++) n[o] = arguments[o];
                        var r = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call.apply(t, [this].concat(n)));
                        return (r.options = Object.assign({ showDot: !1, showArea: !1 }, r.options)), (r.movingAnimation = null), (r.drawingType = g), r;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._makePositionsForRadial = function (t, e) {
                                var i = this.layout,
                                    n = i.dimension,
                                    o = i.position,
                                    r = o.top,
                                    s = o.left,
                                    u = n.width - m - y,
                                    l = n.height - m - y,
                                    h = p.default.sum([u / 2, m / 2, y / 2, s]),
                                    c = p.default.sum([l / 2, -(m / 2), -(y / 2), -r]),
                                    d = 360 / e,
                                    g = Math.min(u, l) / 2;
                                return t.map(function (t) {
                                    var e = t.map(function (t, e) {
                                        var i = void 0;
                                        if (!(0, a.default)(t.end)) {
                                            var n = t.ratio * g,
                                                o = f.default.rotatePointAroundOrigin(h, c, h, c + n, 360 - d * e);
                                            i = { left: o.x, top: l - o.y };
                                        }
                                        return i;
                                    });
                                    return e.push(e[0]), e;
                                });
                            }),
                            (e.prototype._getSeriesGroups = function () {
                                return this._getSeriesDataModel().map(function (t) {
                                    return t.map(function (t) {
                                        return t;
                                    });
                                }, !0);
                            }),
                            (e.prototype._makeSeriesData = function () {
                                var t = this._getSeriesGroups(),
                                    e = this._makePositionsForRadial(t, this._getSeriesDataModel().getGroupCount());
                                return {
                                    groupPositions: e,
                                    isAvailable: function () {
                                        return e && e.length > 0;
                                    },
                                };
                            }),
                            (e.prototype.rerender = function (t) {
                                return u.default.prototype.rerender.call(this, t);
                            }),
                            e
                    );
                })(u.default);
            (o.componentType = "series"), (o.RadialChartSeries = v);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                var e = t.chartTheme,
                    i = t.chartOptions.libType;
                return (t.libType = i), (t.chartType = "area"), (t.chartBackground = e.chart.background), new c(t);
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(240),
                a = n(r),
                s = i(245),
                u = n(s),
                l = i(164),
                h = n(l),
                c = (function (t) {
                    function e() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        for (var i = arguments.length, n = Array(i), o = 0; o < i; o++) n[o] = arguments[o];
                        var r = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call.apply(t, [this].concat(n)));
                        return (r.movingAnimation = null), r;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._makePositionTopOfZeroPoint = function () {
                                var t = this.layout,
                                    e = t.dimension.height,
                                    i = t.position.top,
                                    n = this.axisDataMap.yAxis.limit,
                                    o = this._getLimitDistanceFromZeroPoint(e, n).toMax + i;
                                return n.min >= 0 && !o && (o = e), o;
                            }),
                            (e.prototype._makeStackedPositions = function (t) {
                                var e = this.layout,
                                    i = e.dimension.height,
                                    n = e.position.top,
                                    o = this._makePositionTopOfZeroPoint(),
                                    r = [];
                                return t.map(function (t) {
                                    return t.map(function (t, e) {
                                        var a = r[e] || o,
                                            s = t ? t.top : 0,
                                            u = t ? a - (i - s + n) : a;
                                        return t && ((t.startTop = a), (t.top = u)), (r[e] = u), t;
                                    });
                                });
                            }),
                            (e.prototype._makePositions = function (t) {
                                var e = this._makeBasicPositions(t);
                                return h.default.isValidStackOption(this.options.stack) && (e = this._makeStackedPositions(e)), e;
                            }),
                            (e.prototype._makeSeriesData = function () {
                                var t = this.layout,
                                    e = t.dimension.height,
                                    i = t.position.top,
                                    n = this._getLimitDistanceFromZeroPoint(e, this.limit).toMax + i,
                                    o = this._makePositions();
                                return {
                                    chartBackground: this.chartBackground,
                                    groupPositions: o,
                                    hasRangeData: this._getSeriesDataModel().hasRangeData(),
                                    zeroTop: n,
                                    isAvailable: function () {
                                        return o && o.length > 0;
                                    },
                                };
                            }),
                            (e.prototype.rerender = function (t) {
                                return this._cancelMovingAnimation(), a.default.prototype.rerender.call(this, t);
                            }),
                            e
                    );
                })(a.default);
            u.default.mixin(c), (o.componentType = "series"), (o.AreaChartSeries = c);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                var e = t.chartOptions.libType,
                    i = t.chartTheme;
                return (t.libType = e), (t.chartType = "bubble"), (t.chartBackground = i.chart.background), new d(t);
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(154),
                a = n(r),
                s = i(240),
                u = n(s),
                l = i(249),
                h = n(l),
                c = i(146),
                p = n(c),
                d = (function (t) {
                    function e() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        for (var i = arguments.length, n = Array(i), o = 0; o < i; o++) n[o] = arguments[o];
                        var r = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call.apply(t, [this].concat(n)));
                        return (r.prevClickedIndex = null), (r.maxRadius = null), (r.drawingType = a.default.COMPONENT_TYPE_RAPHAEL), r;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._calculateStep = function () {
                                var t = this.dataProcessor.isXCountGreaterThanYCount(this.chartType),
                                    e = 0;
                                if (this.dataProcessor.hasCategories(t)) {
                                    var i = this.layout.dimension,
                                        n = this.dataProcessor.getCategoryCount(t),
                                        o = void 0;
                                    e = (o = t ? i.height : i.width) / n;
                                }
                                return e;
                            }),
                            (e.prototype._makeBound = function (t, e, i) {
                                var n = this.layout,
                                    o = n.dimension,
                                    r = o.width,
                                    a = o.height,
                                    s = n.position,
                                    u = (0, p.default)(t.x) ? t.x * r : e,
                                    l = (0, p.default)(t.y) ? t.y * a : e;
                                return { left: s.left + u, top: s.top + a - l, radius: Math.max(i * t.r, 2) };
                            }),
                            (e.prototype._makeBounds = function () {
                                var t = this,
                                    e = this._getSeriesDataModel(),
                                    i = this.maxRadius,
                                    n = this._calculateStep(),
                                    o = n ? n / 2 : 0;
                                return e.map(function (e, r) {
                                    var a = o + n * r;
                                    return e.map(function (e) {
                                        return e && e.ratioMap ? t._makeBound(e.ratioMap, a, i) : null;
                                    });
                                });
                            }),
                            (e.prototype._setDataForRendering = function (t) {
                                (this.maxRadius = t.maxRadius), u.default.prototype._setDataForRendering.call(this, t);
                            }),
                            e
                    );
                })(u.default);
            h.default.mixin(d), (o.componentType = "series"), (o.BubbleChartSeries = d);
        },
        function (t, e) {
            "use strict";
            e.__esModule = !0;
            var i = (function () {
                function t() {
                    !(function t(e, i) {
                        if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                    })(this, t);
                }
                return (
                    (t.prototype._makeSeriesData = function () {
                        var t = this._makeBounds();
                        return {
                            groupBounds: t,
                            seriesDataModel: this._getSeriesDataModel(),
                            isAvailable: function () {
                                return t && t.length > 0;
                            },
                        };
                    }),
                        (t.prototype.showTooltip = function (t, e, i, n, o) {
                            this.eventBus.fire("showTooltip", Object.assign({ indexes: { groupIndex: i, index: n }, mousePosition: o }, t));
                        }),
                        (t.prototype.hideTooltip = function () {
                            this.eventBus.fire("hideTooltip");
                        }),
                        (t.prototype._renderGraph = function (t, e, i) {
                            var n = { showTooltip: this.showTooltip.bind(this, { chartType: this.chartType }), hideTooltip: this.hideTooltip.bind(this) },
                                o = this._makeParamsForGraphRendering(t, e);
                            return this.graphRenderer.render(i, o, n);
                        }),
                        (t.prototype.onClickSeries = function (t) {
                            var e = this._executeGraphRenderer(t, "findIndexes"),
                                i = this.prevClickedIndexes,
                                n = this.options.allowSelect,
                                o = this.chartType;
                            if ((e && i && (this.onUnselectSeries({ chartType: o, indexes: i }), (this.prevClickedIndexes = null)), e)) {
                                var r = !i || e.index !== i.index || e.groupIndex !== i.groupIndex;
                                n && r && (this.onSelectSeries({ chartType: o, indexes: e }, r), (this.prevClickedIndexes = e));
                            }
                        }),
                        (t.prototype.onMoveSeries = function (t) {
                            this._executeGraphRenderer(t, "moveMouseOnSeries");
                        }),
                        t
                );
            })();
            (i.mixin = function (t) {
                Object.assign(t.prototype, i.prototype);
            }),
                (e.default = i);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                return (t.libType = t.chartOptions.libType), (t.chartType = "scatter"), (t.chartBackground = t.chartTheme.chart.background), new c(t);
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(240),
                a = n(r),
                s = i(249),
                u = n(s),
                l = i(154),
                h = n(l),
                c = (function (t) {
                    function e() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        for (var i = arguments.length, n = Array(i), o = 0; o < i; o++) n[o] = arguments[o];
                        var r = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call.apply(t, [this].concat(n)));
                        return (r.prevClickedIndex = null), r;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._makeBound = function (t) {
                                var e = this.layout,
                                    i = e.dimension,
                                    n = e.position;
                                return { left: n.left + t.x * i.width, top: i.height - t.y * i.height + n.top, radius: h.default.SCATTER_RADIUS };
                            }),
                            (e.prototype._makeBounds = function () {
                                var t = this;
                                return this._getSeriesDataModel().map(function (e) {
                                    return e.map(function (e) {
                                        return e && e.ratioMap ? t._makeBound(e.ratioMap) : null;
                                    });
                                });
                            }),
                            e
                    );
                })(a.default);
            u.default.mixin(c), (o.componentType = "series"), (o.ScatterChartSeries = c);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                return (t.libType = t.chartOptions.libType), (t.chartType = "map"), new v(t);
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(137),
                a = n(r),
                s = i(145),
                u = n(s),
                l = i(153),
                h = n(l),
                c = i(240),
                p = n(c),
                d = i(154),
                f = n(d),
                g = h.default.msie && h.default.version <= 8,
                m = f.default.TOOLTIP_GAP,
                y = f.default.PUBLIC_EVENT_PREFIX,
                v = (function (t) {
                    function e(i) {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        var n = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, i));
                        return (
                            (n.basePosition = { left: 0, top: 0 }),
                                (n.zoomMagn = 1),
                                (n.mapRatio = 1),
                                (n.graphDimension = {}),
                                (n.limitPosition = {}),
                                (n.mapModel = i.mapModel),
                                (n.colorSpectrum = i.colorSpectrum),
                                (n.prevPosition = null),
                                (n.prevMovedIndex = null),
                                (n.isDrag = !1),
                                (n.startPosition = null),
                                n
                        );
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._attachToEventBus = function () {
                                p.default.prototype._attachToEventBus.call(this),
                                g || this.eventBus.on({ dragStartMapSeries: this.onDragStartMapSeries, dragMapSeries: this.onDragMapSeries, dragEndMapSeries: this.onDragEndMapSeries, zoomMap: this.onZoomMap }, this);
                            }),
                            (e.prototype._setMapRatio = function (t) {
                                var e = this.layout.dimension,
                                    i = t || this.mapModel.getMapDimension(),
                                    n = e.width / i.width,
                                    o = e.height / i.height;
                                this.mapRatio = Math.min(n, o);
                            }),
                            (e.prototype._setGraphDimension = function () {
                                var t = this.layout.dimension,
                                    e = t.width,
                                    i = t.height;
                                this.graphDimension = { width: e * this.zoomMagn, height: i * this.zoomMagn };
                            }),
                            (e.prototype.render = function (t) {
                                p.default.prototype.render.call(this, t), (this.seriesSet = this.graphRenderer.sectorSet), this._setMapRatio();
                            }),
                            (e.prototype.rerender = function (t) {
                                p.default.prototype.rerender.call(this, t), (this.seriesSet = this.graphRenderer.sectorSet), this._setMapRatio();
                            }),
                            (e.prototype.resize = function (t) {
                                this.rerender(t);
                            }),
                            (e.prototype._setLimitPositionToMoveMap = function () {
                                var t = this.layout.dimension,
                                    e = this.graphDimension;
                                this.limitPosition = { left: t.width - e.width, top: t.height - e.height };
                            }),
                            (e.prototype._renderGraph = function () {
                                this._setGraphDimension(), this._setLimitPositionToMoveMap(), this.graphRenderer.render(this.paper, { colorSpectrum: this.colorSpectrum, mapModel: this.mapModel, layout: this.layout, theme: this.theme });
                            }),
                            (e.prototype._renderSeriesLabel = function () {
                                var t = this.mapModel.getLabelData(this.zoomMagn * this.mapRatio);
                                return this.graphRenderer.renderSeriesLabels(this.paper, t, this.theme.label);
                            }),
                            (e.prototype._renderSeriesArea = function (t, e, i) {
                                p.default.prototype._renderSeriesArea.call(this, t, e, i);
                            }),
                            (e.prototype._adjustMapPosition = function (t) {
                                return { left: Math.max(Math.min(t.left, 0), this.limitPosition.left), top: Math.max(Math.min(t.top, 0), this.limitPosition.top) };
                            }),
                            (e.prototype._updateBasePositionForZoom = function (t, e, i) {
                                var n = this.basePosition,
                                    o = n.left - e.left / 2,
                                    r = n.top - e.top / 2,
                                    a = { left: o * i + this.limitPosition.left / 2, top: r * i + this.limitPosition.top / 2 };
                                this.basePosition = this._adjustMapPosition(a);
                            }),
                            (e.prototype._zoom = function (t, e) {
                                var i = this.graphDimension,
                                    n = this.limitPosition;
                                this._setGraphDimension(), this._setLimitPositionToMoveMap(), this._updateBasePositionForZoom(i, n, t), this._setMapRatio(this.graphDimension), this.graphRenderer.scaleMapPaths(t, e, this.mapRatio, i, i);
                            }),
                            (e.prototype._updatePositionsToResize = function (t) {
                                var e = this.mapRatio / t;
                                (this.basePosition.left *= e), (this.basePosition.top *= e), (this.limitPosition.left *= e), (this.limitPosition.top *= e);
                            }),
                            (e.prototype.onClickSeries = function (t) {
                                var e = this._executeGraphRenderer(t, "findSectorIndex");
                                (0, a.default)(e) || this.eventBus.fire("selectSeries", { chartType: this.chartType, index: e, code: this.mapModel.getDatum(e).code });
                            }),
                            (e.prototype._isChangedPosition = function (t, e) {
                                return !t || t.left !== e.left || t.top !== e.top;
                            }),
                            (e.prototype._showWedge = function (t) {
                                var e = this.mapModel.getDatum(t),
                                    i = e.ratio,
                                    n = e.label;
                                (0, u.default)(i) || this.eventBus.fire("showWedge", i, n);
                            }),
                            (e.prototype._showTooltip = function (t, e) {
                                var i = e.left,
                                    n = e.top;
                                this.eventBus.fire("showTooltip", { chartType: this.chartType, indexes: { index: t }, mousePosition: { left: i, top: n - m } });
                            }),
                            (e.prototype.onMoveSeries = function (t) {
                                var e = this._executeGraphRenderer(t, "findSectorIndex");
                                (0, a.default)(e)
                                    ? (0, a.default)(this.prevMovedIndex) || (this.graphRenderer.restoreColor(this.prevMovedIndex), this.eventBus.fire("hideTooltip"), (this.prevMovedIndex = null))
                                    : (this.prevMovedIndex !== e && ((0, a.default)(this.prevMovedIndex) || (this.graphRenderer.restoreColor(this.prevMovedIndex), this.eventBus.fire("hideTooltip")), this.graphRenderer.changeColor(e)),
                                    this._isChangedPosition(this.prevPosition, t) && (this._showTooltip(e, { left: t.left, top: t.top }), (this.prevMovedIndex = e)),
                                        this._showWedge(e)),
                                    (this.prevPosition = t);
                            }),
                            (e.prototype.onDragStartMapSeries = function (t) {
                                this.startPosition = { left: t.left, top: t.top };
                            }),
                            (e.prototype._movePosition = function (t, e) {
                                var i = { x: (e.left - t.left) * this.mapRatio, y: (e.top - t.top) * this.mapRatio };
                                this.graphRenderer.moveMapPaths(i, this.graphDimension);
                            }),
                            (e.prototype.onDragMapSeries = function (t) {
                                this._movePosition(this.startPosition, t), (this.startPosition = t), this.isDrag || ((this.isDrag = !0), this.eventBus.fire("hideTooltip"));
                            }),
                            (e.prototype.onDragEndMapSeries = function () {
                                this.isDrag = !1;
                            }),
                            (e.prototype.onZoomMap = function (t, e) {
                                var i = t / this.zoomMagn,
                                    n = this.layout,
                                    o = n.position,
                                    r = o.top,
                                    a = o.left,
                                    s = n.dimension,
                                    u = s.width,
                                    l = s.height,
                                    h = e || { left: u / 2, top: l / 2 };
                                (this.zoomMagn = t), this._zoom(i, { left: h.left - a, top: h.top - r }), this.eventBus.fire(y + "zoom", t);
                            }),
                            (e.prototype._makeExportationSeriesData = function (t) {
                                return t;
                            }),
                            e
                    );
                })(p.default);
            (o.componentType = "series"), (o.MapChartSeries = v);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                var e = t.chartOptions,
                    i = e.libType,
                    n = e.chartType,
                    o = e.legend,
                    a = t.chartTheme;
                if (((t.libType = i), (t.chartType = "pie"), "combo" === n)) {
                    var s = r(t.name.split("Series"), 1);
                    (t.seriesType = s[0]), (t.isCombo = !0);
                }
                return o && (t.legendMaxWidth = o.maxWidth), (t.chartBackground = a.chart.background), new P(t);
            }
            e.__esModule = !0;
            var r = function (t, e) {
                if (Array.isArray(t)) return t;
                if (Symbol.iterator in Object(t))
                    return (function t(e, i) {
                        var n = [],
                            o = !0,
                            r = !1,
                            a = void 0;
                        try {
                            for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                        } catch (l) {
                            (r = !0), (a = l);
                        } finally {
                            try {
                                !o && u.return && u.return();
                            } finally {
                                if (r) throw a;
                            }
                        }
                        return n;
                    })(t, e);
                throw TypeError("Invalid attempt to destructure non-iterable instance");
            };
            e.default = o;
            var a = i(146),
                s = n(a),
                u = i(156),
                l = n(u),
                h = i(145),
                c = n(h),
                p = i(240),
                d = n(p),
                f = i(154),
                g = n(f),
                m = i(164),
                y = n(m),
                v = i(143),
                $ = n(v),
                x = g.default.COMPONENT_TYPE_RAPHAEL,
                T = g.default.ANGLE_360,
                b = g.default.PIE_GRAPH_LEGEND_LABEL_INTERVAL,
                _ = g.default.ANGLE_90,
                A = g.default.PIE_GRAPH_SMALL_RATIO,
                D = g.default.PIE_GRAPH_DEFAULT_RATIO,
                C = g.default.RAD,
                E = g.default.PIE_GRAPH_LEGEND_LABEL_SIZE,
                L = g.default.SERIES_OUTER_LABEL_PADDING,
                S = g.default.SERIES_LABEL_PADDING,
                P = (function (t) {
                    function e(i) {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        var n = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, i));
                        return (
                            (n.isCombo = !!i.isCombo),
                                (n.isShowOuterLabel = y.default.isShowOuterLabel(n.options)),
                                (n.isLabelAlignOuter = y.default.isLabelAlignOuter(n.options.labelAlign)),
                                (n.legendMaxWidth = i.legendMaxWidth),
                                (n.drawingType = x),
                                (n.quadrantRange = null),
                                (n.prevClickedIndex = null),
                                (n.legendLabels = []),
                                (n.valueLabels = []),
                                (n.ratioValues = []),
                                (n.legendLongestWidth = 0),
                                (n.labelTheme = n.theme.label),
                                n._setDefaultOptions(),
                                n
                        );
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._getLegendLabels = function () {
                                var t = this;
                                return this.dataProcessor.getLegendLabels(this.seriesType).map(function (e) {
                                    return $.default.getEllipsisText(e, t.legendMaxWidth, t.labelTheme);
                                });
                            }),
                            (e.prototype._makeValidAngle = function (t, e) {
                                return (0, c.default)(t) ? (t = e) : t < 0 ? (t = T - (Math.abs(t) % T)) : t > 0 && (t %= T), t;
                            }),
                            (e.prototype._transformRadiusRange = function () {
                                var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : ["0%", "100%"];
                                return t.map(function (t) {
                                    return Math.max(Math.min(0.01 * parseInt(t, 10), 1), 0);
                                });
                            }),
                            (e.prototype._setDefaultOptions = function () {
                                var t = this.options;
                                (t.startAngle = this._makeValidAngle(t.startAngle, 0)),
                                    (t.endAngle = this._makeValidAngle(t.endAngle, t.startAngle)),
                                    (t.radiusRange = this._transformRadiusRange(t.radiusRange)),
                                1 === t.radiusRange.length && t.radiusRange.unshift(0);
                            }),
                            (e.prototype._calculateAngleForRendering = function () {
                                var t = this.options,
                                    e = t.startAngle,
                                    i = t.endAngle;
                                return e < i ? i - e : e > i ? T - (e - i) : T;
                            }),
                            (e.prototype._makeSectorData = function (t) {
                                var e = this,
                                    i = t.cx,
                                    n = t.r,
                                    o = t.cy,
                                    a = r(this.options.radiusRange, 1)[0],
                                    s = this._calculateAngleForRendering(),
                                    u = this._getSeriesDataModel().getFirstSeriesGroup(),
                                    l = this.options.startAngle,
                                    h = 0.5 * n;
                                return (a && (h += h * a), u)
                                    ? u.map(function (t) {
                                        var r = t ? t.ratio : 0,
                                            a = s * r,
                                            u = l + a,
                                            c = l + a / 2,
                                            p = { start: { startAngle: l, endAngle: l }, end: { startAngle: l, endAngle: u } },
                                            d = { cx: i, cy: o, angle: c };
                                        return (l = u), { ratio: r, angles: p, centerPosition: e._getArcPosition(Object.assign({ r: h }, d)), outerPosition: e._getArcPosition(Object.assign({ r: n + e.legendLongestWidth / 2 + b }, d)) };
                                    })
                                    : null;
                            }),
                            (e.prototype._makeValueLabel = function () {
                                return this._getSeriesDataModel()
                                    .getFirstSeriesGroup()
                                    .map(function (t) {
                                        return t.label;
                                    });
                            }),
                            (e.prototype._makeRatioValues = function () {
                                return this._getSeriesDataModel()
                                    .getFirstSeriesGroup()
                                    .map(function (t) {
                                        return t.ratio;
                                    });
                            }),
                            (e.prototype._makeSeriesData = function () {
                                (this.valueLabels = this._makeValueLabel()), (this.legendLabels = this._getLegendLabels()), (this.legendLongestWidth = this._getMaxLengthLegendWidth()), (this.ratioValues = this._makeRatioValues());
                                var t = this._makeCircleBound(),
                                    e = this._makeSectorData(t);
                                return {
                                    chartBackground: this.chartBackground,
                                    circleBound: t,
                                    sectorData: e,
                                    isAvailable: function () {
                                        return e && e.length > 0;
                                    },
                                };
                            }),
                            (e.prototype._getQuadrantFromAngle = function (t, e) {
                                var i = parseInt(t / _, 10) + 1;
                                return e && t % _ == 0 && (i += 1 === i ? 3 : -1), i;
                            }),
                            (e.prototype._getRangeForQuadrant = function () {
                                return this.quadrantRange || (this.quadrantRange = { start: this._getQuadrantFromAngle(this.options.startAngle), end: this._getQuadrantFromAngle(this.options.endAngle, !0) }), this.quadrantRange;
                            }),
                            (e.prototype._isInQuadrantRange = function (t, e) {
                                var i = this._getRangeForQuadrant();
                                return i.start === t && i.end === e;
                            }),
                            (e.prototype._calculateBaseSize = function () {
                                var t = this.layout.dimension,
                                    e = t.width,
                                    i = t.height;
                                if (!this.isCombo) {
                                    var n = this._getRangeForQuadrant();
                                    this._isInQuadrantRange(2, 3) || this._isInQuadrantRange(4, 1) ? (i *= 2) : this._isInQuadrantRange(1, 2) || this._isInQuadrantRange(3, 4) ? (e *= 2) : n.start === n.end && ((e *= 2), (i *= 2));
                                }
                                return Math.min(e, i);
                            }),
                            (e.prototype._calculateRadius = function () {
                                var t = this.isCombo && "pie1" === this.seriesType,
                                    e = this._calculateBaseSize(),
                                    i = 0,
                                    n = this.isShowOuterLabel;
                                return t && (n = this.dataProcessor.isComboDonutShowOuterLabel()), (i = n ? A : D), (e * i * this.options.radiusRange[1]) / 2;
                            }),
                            (e.prototype._calculateCenterXY = function (t) {
                                var e = this.layout,
                                    i = e.dimension,
                                    n = i.width,
                                    o = i.height,
                                    r = e.position,
                                    a = r.top,
                                    s = r.left,
                                    u = t / 2,
                                    l = n / 2 + s,
                                    h = o / 2 + a;
                                return (
                                    this.isCombo ||
                                    (this._isInQuadrantRange(1, 1)
                                        ? ((l -= u), (h += u))
                                        : this._isInQuadrantRange(1, 2)
                                            ? (l -= u)
                                            : this._isInQuadrantRange(2, 2)
                                                ? ((l -= u), (h -= u))
                                                : this._isInQuadrantRange(2, 3)
                                                    ? (h -= u)
                                                    : this._isInQuadrantRange(3, 3)
                                                        ? ((l += u), (h -= u))
                                                        : this._isInQuadrantRange(3, 4)
                                                            ? (l += u)
                                                            : this._isInQuadrantRange(4, 1)
                                                                ? (h += u)
                                                                : this._isInQuadrantRange(4, 4) && ((l += u), (h += u))),
                                        { cx: l, cy: h }
                                );
                            }),
                            (e.prototype._makeCircleBound = function () {
                                var t = this._calculateRadius(),
                                    e = this._calculateCenterXY(t);
                                return Object.assign({ r: t }, e);
                            }),
                            (e.prototype._getArcPosition = function (t) {
                                return { left: t.cx + t.r * Math.sin(t.angle * C), top: t.cy - t.r * Math.cos(t.angle * C) };
                            }),
                            (e.prototype._renderGraph = function (t, e, i) {
                                var n = { showTooltip: this.showTooltip.bind(this, { allowNegativeTooltip: !!this.allowNegativeTooltip, seriesType: this.seriesType, chartType: this.chartType }), hideTooltip: this.hideTooltip.bind(this) },
                                    o = this._makeParamsForGraphRendering(t, e),
                                    r = this.seriesType,
                                    a = this.dataProcessor.seriesDataModelMap,
                                    s = [],
                                    u = 0;
                                return (
                                    (this.dataProcessor.seriesTypes || []).forEach(function (t) {
                                        var e = !0;
                                        return t !== r ? s.push(t) : (e = !1), e;
                                    }),
                                        s.forEach(function (t) {
                                            u += a[t].baseGroups.length;
                                        }),
                                        (o.additionalIndex = u),
                                        this.graphRenderer.render(i, o, n)
                                );
                            }),
                            (e.prototype.showTooltip = function (t, e, i, n, o) {
                                this.eventBus.fire("showTooltip", Object.assign({ indexes: { groupIndex: i, index: n }, mousePosition: o }, t));
                            }),
                            (e.prototype.hideTooltip = function () {
                                this.eventBus.fire("hideTooltip");
                            }),
                            (e.prototype._getMaxLengthLegendWidth = function () {
                                var t = this,
                                    e = this.legendLabels.map(function (e) {
                                        return $.default.getRenderedTextSize(e, t.labelTheme.fontSize, t.labelTheme.fontFamily).width;
                                    });
                                return (
                                    e.sort(function (t, e) {
                                        return t - e;
                                    }),
                                        e[e.length - 1]
                                );
                            }),
                            (e.prototype._makeSeriesDataBySelection = function (t) {
                                return { indexes: { index: t, groupIndex: t } };
                            }),
                            (e.prototype._pickPositionsFromSectorData = function (t, e) {
                                var i = this,
                                    n = this.options,
                                    o = n.showLegend,
                                    r = n.showLabel,
                                    a = $.default.getRenderedTextSize(this.legendLabels[0], this.labelTheme.fontSize, this.labelTheme.fontFamily).height,
                                    s = $.default.getRenderedTextSize(this.valueLabels[0], E, this.labelTheme.fontFamily).height;
                                return (this.seriesData.sectorData || []).map(function (n) {
                                    var u = n.ratio ? Object.assign({}, n[t]) : null;
                                    return u && o && r && !i.isLabelAlignOuter && ("value" === e ? (u.top -= s / 2) : "legend" === e && (u.top += a / 2)), u;
                                });
                            }),
                            (e.prototype._addEndPosition = function (t, e) {
                                e.forEach(function (e) {
                                    if (e) {
                                        var i = Object.assign({}, e.middle);
                                        i.left < t ? (i.left -= L) : (i.left += L), (e.end = i);
                                    }
                                });
                            }),
                            (e.prototype._moveToOuterPosition = function (t, e, i) {
                                var n = e.end,
                                    o = n.left,
                                    r = n.top,
                                    a = this.graphRenderer.getRenderedLabelWidth(i, this.labelTheme) / 2 + S;
                                return { left: o < t ? o - a : o + a, top: r };
                            }),
                            (e.prototype._setSeriesPosition = function (t, e) {
                                return t.funcMoveToPosition
                                    ? t.positions.map(function (i, n) {
                                        return i ? t.funcMoveToPosition(i, e[n]) : null;
                                    })
                                    : t.positions;
                            }),
                            (e.prototype._renderSeriesLabel = function (t) {
                                var e = [],
                                    i = {},
                                    n = t.set(),
                                    o = function (o, r) {
                                        var a = void 0,
                                            s = Object.assign({}, this.theme.label),
                                            u = this.ratioValues;
                                        this.isLabelAlignOuter && "legend" === o && ((a = this.theme.colors), (s.fontWeight = "bold")),
                                            (s.fontSize = "value" === o ? 16 : s.fontSize),
                                            (e = this._setSeriesPosition(i, r)),
                                            this.graphRenderer.renderLabels({
                                                dataType: o,
                                                paper: t,
                                                labelSet: n,
                                                positions: e,
                                                labels: r,
                                                ratioValues: u,
                                                theme: s,
                                                colors: a,
                                                seriesNames: this.legendLabels,
                                                labelFilter: this.options.labelFilter,
                                            });
                                    }.bind(this);
                                if ((this.options.showLabel && ((i.positions = this._pickPositionsFromSectorData("centerPosition", "value")), o("value", this.decorateLabel(this.valueLabels))), this.options.showLegend)) {
                                    var r = this.isLabelAlignOuter ? "outerPosition" : "centerPosition";
                                    (i.positions = this._pickPositionsFromSectorData(r, "legend")), o("legend", this.legendLabels);
                                }
                                return n;
                            }),
                            (e.prototype._isDetectedLabel = function (t) {
                                var e = document.elementFromPoint(t.left, t.top);
                                return (0, l.default)(e.className);
                            }),
                            (e.prototype.onClickSeries = function (t) {
                                var e = this._executeGraphRenderer(t, "findSectorInfo"),
                                    i = this.prevClickedIndex,
                                    n = this.options.allowSelect;
                                if (((e || this._isDetectedLabel(t)) && (0, s.default)(i) && n && (this.onUnselectSeries({ indexes: { index: i } }), (this.prevClickedIndex = null)), e && e.chartType === this.seriesType)) {
                                    var o = e.index,
                                        r = o > -1 && o !== i;
                                    (n && !r) || (this.onSelectSeries({ chartType: this.chartType, indexes: { index: o, legendIndex: e.legendIndex } }, r), n && o > -1 && (this.prevClickedIndex = o));
                                }
                            }),
                            (e.prototype.onMoveSeries = function (t) {
                                this._executeGraphRenderer(t, "moveMouseOnSeries");
                            }),
                            e
                    );
                })(d.default);
            (o.componentType = "series"), (o.PieChartSeries = P);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                return (t.libType = t.chartOptions.libType), (t.chartType = "heatmap"), new l(t);
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(240),
                a = n(r),
                s = i(242),
                u = n(s),
                l = (function (t) {
                    function e(i) {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        var n = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, i));
                        return (n.colorSpectrum = i.colorSpectrum), n;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._makeSeriesData = function () {
                                var t = this._makeBounds(),
                                    e = this._getSeriesDataModel();
                                return {
                                    colorSpectrum: this.colorSpectrum,
                                    groupBounds: t,
                                    seriesDataModel: e,
                                    isAvailable: function () {
                                        return t && t.length > 0;
                                    },
                                };
                            }),
                            (e.prototype._makeBound = function (t, e, i, n) {
                                var o = this.layout,
                                    r = o.dimension.height,
                                    a = o.position,
                                    s = a.top;
                                return { end: { left: a.left + t * i, top: s + r - e * (n + 1), width: t, height: e } };
                            }),
                            (e.prototype._makeBounds = function () {
                                var t = this,
                                    e = this._getSeriesDataModel(),
                                    i = this.layout.dimension,
                                    n = i.width,
                                    o = i.height,
                                    r = n / this.dataProcessor.getCategoryCount(!1),
                                    a = o / this.dataProcessor.getCategoryCount(!0);
                                return e.map(function (e, i) {
                                    return e.map(function (e, n) {
                                        return t._makeBound(r, a, i, n);
                                    });
                                });
                            }),
                            (e.prototype.onShowTooltip = function (t) {
                                var e = t.indexes,
                                    i = this._getSeriesDataModel().getSeriesItem(e.groupIndex, e.index),
                                    n = i.ratio,
                                    o = i.label;
                                this.eventBus.fire("showWedge", n, o);
                            }),
                            (e.prototype._renderSeriesLabel = function (t) {
                                var e = this,
                                    i = this._getSeriesDataModel(),
                                    n = this.seriesData.groupBounds,
                                    o = this.theme.label,
                                    r = this.selectedLegendIndex,
                                    a = u.default.boundsToLabelPositions(i, n, o),
                                    s = i.map(function (t) {
                                        return e.decorateLabel(t.valuesMap.value);
                                    });
                                return this.graphRenderer.renderSeriesLabel(t, a, s, o, r);
                            }),
                            (e.prototype.resize = function () {
                                this.boundMap = null;
                                for (var t = arguments.length, e = Array(t), i = 0; i < t; i++) e[i] = arguments[i];
                                a.default.prototype.resize.apply(this, e);
                            }),
                            (e.prototype._makeExportationSeriesData = function (t) {
                                var e = t.indexes;
                                return { x: e.groupIndex, y: e.index };
                            }),
                            e
                    );
                })(a.default);
            (o.componentType = "series"), (o.HeatmapChartSeries = l);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                return (t.libType = t.chartOptions.libType), (t.chartType = "treemap"), (t.chartBackground = t.chartTheme.chart.background), new y(t);
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(145),
                a = n(r),
                s = i(240),
                u = n(s),
                l = i(255),
                h = n(l),
                c = i(242),
                p = n(c),
                d = i(154),
                f = n(d),
                g = i(164),
                m = n(g),
                y = (function (t) {
                    function e(i) {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        var n = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, i));
                        return (
                            (n.theme.borderColor = n.theme.borderColor || f.default.TREEMAP_DEFAULT_BORDER),
                                (n.theme.label.color = n.options.useColorValue ? "#000" : "#fff"),
                                (n.rootId = f.default.TREEMAP_ROOT_ID),
                                (n.startDepth = 1),
                                (n.selectedGroup = null),
                                (n.boundMap = null),
                                (n.colorSpectrum = i.colorSpectrum),
                                n._initOptions(),
                                n
                        );
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._initOptions = function () {
                                (this.options.useColorValue = !!this.options.useColorValue),
                                (0, a.default)(this.options.zoomable) && (this.options.zoomable = !this.options.useColorValue),
                                (0, a.default)(this.options.useLeafLabel) && (this.options.useLeafLabel = !this.options.zoomable);
                            }),
                            (e.prototype._makeSeriesData = function () {
                                var t = this._getBoundMap(),
                                    e = this._makeBounds(t);
                                return {
                                    boundMap: t,
                                    groupBounds: e,
                                    seriesDataModel: this._getSeriesDataModel(),
                                    startDepth: this.startDepth,
                                    isPivot: !0,
                                    colorSpectrum: this.options.useColorValue ? this.colorSpectrum : null,
                                    chartBackground: this.chartBackground,
                                    zoomable: this.options.zoomable,
                                    isAvailable: function () {
                                        return e && e.length > 0;
                                    },
                                };
                            }),
                            (e.prototype._makeBoundMap = function (t, e, i) {
                                var n = this,
                                    o = this._getSeriesDataModel(),
                                    r = Object.assign({}, this.layout.dimension, this.layout.position),
                                    a = o.findSeriesItemsByParent(t);
                                return (
                                    (i = i || r),
                                        (e = Object.assign(e || {}, h.default.squarify(i, a))),
                                        a.forEach(function (t) {
                                            e = n._makeBoundMap(t.id, e, e[t.id]);
                                        }),
                                        e
                                );
                            }),
                            (e.prototype._makeBounds = function (t) {
                                var e = this.startDepth,
                                    i = this._getSeriesDataModel(),
                                    n = void 0;
                                return (
                                    (n = this.options.zoomable
                                        ? function (t) {
                                            return t.depth === e;
                                        }
                                        : function (t) {
                                            return !t.hasChild;
                                        }),
                                        i.map(function (e) {
                                            return e.map(function (e) {
                                                var i = t[e.id],
                                                    o = null;
                                                return i && n(e) && (o = { end: i }), o;
                                            }, !0);
                                        }, !0)
                                );
                            }),
                            (e.prototype._getBoundMap = function () {
                                return this.boundMap || (this.boundMap = this._makeBoundMap(this.rootId)), this.boundMap;
                            }),
                            (e.prototype._shouldDimmed = function (t, e, i) {
                                var n = !1;
                                if (e && i.id !== e.id && i.group === e.group) {
                                    var o = t.findParentByDepth(i.id, e.depth + 1);
                                    o && o.parent === e.id && (n = !0);
                                }
                                return n;
                            }),
                            (e.prototype._renderSeriesLabel = function (t) {
                                var e = this,
                                    i = this._getSeriesDataModel(),
                                    n = this._getBoundMap(),
                                    o = this.theme.label,
                                    r = this.options.labelTemplate,
                                    a = void 0,
                                    s = (a = this.options.useLeafLabel ? i.findLeafSeriesItems(this.selectedGroup) : i.findSeriesItemsByDepth(this.startDepth, this.selectedGroup)).map(function (t) {
                                        var i = r ? r(t.pickLabelTemplateData()) : t.label;
                                        return e.decorateLabel(i);
                                    }),
                                    u = p.default.boundsToLabelPostionsForTreemap(a, n, o);
                                return this.graphRenderer.renderSeriesLabelForTreemap(t, u, s, o);
                            }),
                            (e.prototype.resize = function () {
                                this.boundMap = null;
                                for (var t = arguments.length, e = Array(t), i = 0; i < t; i++) e[i] = arguments[i];
                                u.default.prototype.resize.apply(this, e);
                            }),
                            (e.prototype._zoom = function (t, e, i) {
                                this._clearSeriesContainer(),
                                    (this.boundMap = null),
                                    (this.rootId = t),
                                    (this.startDepth = e),
                                    (this.selectedGroup = i),
                                    this._renderSeriesArea(this.paper, this._renderGraph.bind(this)),
                                    this.animateComponent(!0);
                            }),
                            (e.prototype.zoom = function (t) {
                                var e = t.index;
                                if ((this.labelSet.remove(), -1 === e)) return void this._zoom(f.default.TREEMAP_ROOT_ID, 1, null);
                                var i = this._getSeriesDataModel().getSeriesItem(0, e, !0);
                                i && i.hasChild && (this._zoom(i.id, i.depth + 1, i.group), this.eventBus.fire("afterZoom", e));
                            }),
                            (e.prototype._makeExportationSeriesData = function (t) {
                                var e = t.indexes,
                                    i = this._getSeriesDataModel().getSeriesItem(e.groupIndex, e.index, !0);
                                return Object.assign({ chartType: this.chartType, indexes: i.indexes });
                            }),
                            (e.prototype.onHoverSeries = function (t) {
                                if (m.default.isShowLabel(this.options)) {
                                    var e = this._getSeriesDataModel().getSeriesItem(t.groupIndex, t.index, !0),
                                        i = e.colorRatio;
                                    this.graphRenderer.showAnimation(t, this.options.useColorValue, 0.6), i > -1 && this.eventBus.fire("showWedge", i, e.colorValue);
                                }
                            }),
                            (e.prototype.onHoverOffSeries = function (t) {
                                m.default.isShowLabel(this.options) && t && this.graphRenderer.hideAnimation(t, this.options.useColorValue);
                            }),
                            e
                    );
                })(u.default);
            (o.componentType = "series"), (o.TreemapChartSeries = y);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(189),
                r = n(o),
                a = i(173),
                s = n(a),
                u = i(158),
                l = n(u);
            e.default = {
                boundMap: {},
                _makeBaseBound: function (t) {
                    return Object.assign({}, t);
                },
                _calculateScale: function (t, e, i) {
                    return (e * i) / s.default.sum(t);
                },
                _makeBaseData: function (t, e, i) {
                    var n = this._calculateScale((0, r.default)(t, "value"), e, i);
                    return t
                        .map(function (t) {
                            return { id: t.id, weight: t.value * n };
                        })
                        .sort(function (t, e) {
                            return e.weight - t.weight;
                        });
                },
                _worst: function (t, e, i, n) {
                    var o = t * t,
                        r = n * n;
                    return Math.max((r * i) / o, o / (r * e));
                },
                _changedStackDirection: function (t, e, i, n) {
                    var o = l.default.min(e),
                        r = l.default.max(e),
                        a = this._worst(t, o, r, i);
                    return this._worst(t + n, Math.min(o, n), Math.max(r, n), i) >= a;
                },
                _isVerticalStack: function (t) {
                    return t.height < t.width;
                },
                _selectBaseSize: function (t) {
                    return this._isVerticalStack(t) ? t.height : t.width;
                },
                _calculateFixedSize: function (t, e, i) {
                    if (!e) {
                        var n = (0, r.default)(i, "weight");
                        e = s.default.sum(n);
                    }
                    return e / t;
                },
                _addBounds: function (t, e, i, n) {
                    [t].concat(e).reduce(function (t, e) {
                        var o = e.weight / i;
                        return n(o, t, e.id), t + o;
                    });
                },
                _addBound: function (t, e, i, n, o) {
                    this.boundMap[o] = { left: t, top: e, width: i, height: n };
                },
                _addBoundsForVerticalStack: function (t, e, i, n) {
                    var o = this,
                        r = this._calculateFixedSize(i, n, t);
                    this._addBounds(e.top, t, r, function (t, i, n) {
                        o._addBound(e.left, i, r, t, n);
                    }),
                        (e.left += r),
                        (e.width -= r);
                },
                _addBoundsForHorizontalStack: function (t, e, i, n) {
                    var o = this,
                        r = this._calculateFixedSize(i, n, t);
                    this._addBounds(e.left, t, r, function (t, i, n) {
                        o._addBound(i, e.top, t, r, n);
                    }),
                        (e.top += r),
                        (e.height -= r);
                },
                _getAddingBoundsFunction: function (t) {
                    return this._isVerticalStack(t) ? this._addBoundsForVerticalStack.bind(this) : this._addBoundsForHorizontalStack.bind(this);
                },
                squarify: function (t, e) {
                    var i = this,
                        n = this._makeBaseBound(t),
                        o = this._makeBaseData(e, n.width, n.height),
                        a = [],
                        u = void 0,
                        l = void 0;
                    return (
                        (this.boundMap = {}),
                            o.forEach(function (t) {
                                var e = (0, r.default)(a, "weight"),
                                    o = s.default.sum(e);
                                a.length && i._changedStackDirection(o, e, u, t.weight) && (l(a, n, u, o), (a = [])), a.length || ((u = i._selectBaseSize(n)), (l = i._getAddingBoundsFunction(n))), a.push(t);
                            }),
                        a.length && l(a, n, u),
                            this.boundMap
                    );
                },
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                var e = t.chartOptions.libType,
                    i = t.chartTheme;
                return (t.libType = e), (t.chartType = "boxplot"), (t.chartBackground = i.chart.background), new y(t);
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(240),
                a = n(r),
                s = i(241),
                u = n(s),
                l = i(154),
                h = n(l),
                c = i(164),
                p = n(c),
                d = i(148),
                f = n(d),
                g = h.default.SERIES_EXPAND_SIZE,
                m = h.default.TEXT_PADDING,
                y = (function (t) {
                    function e() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        for (var i = arguments.length, n = Array(i), o = 0; o < i; o++) n[o] = arguments[o];
                        var r = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call.apply(t, [this].concat(n)));
                        return (r.supportSeriesLable = !1), r;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._makeBoxplotChartBound = function (t, e, i, n, o) {
                                var r = t.pointInterval,
                                    a = t.barSize,
                                    s = t.baseBarSize,
                                    u = t.basePosition,
                                    l = Math.abs(s * n.ratioDistance),
                                    h = u + s * (1 - n.lqRatio) + g,
                                    c = u + g,
                                    p = void 0;
                                (e.left = e.baseLeft + r * o), (e.plusTop = 0), (e.minusTop = 0), n.value >= 0 ? ((e.plusTop -= l), (p = h + e.plusTop)) : ((p = h + e.minusTop), (e.minusTop += l));
                                var d = e.left + r - a / 2,
                                    f = (n.outliers || []).map(function (t) {
                                        return { top: s * (1 - t.ratio) + c, left: d + a / 2 };
                                    });
                                return {
                                    start: { top: h, left: d, width: a, height: 0 },
                                    end: { top: p, left: d, width: a, height: l },
                                    min: { top: s * (1 - n.minRatio) + c, left: d, width: a, height: 0 },
                                    max: { top: s * (1 - n.maxRatio) + c, left: d, width: a, height: 0 },
                                    median: { top: s * (1 - n.medianRatio) + c, left: d, width: a, height: 0 },
                                    outliers: f,
                                };
                            }),
                            (e.prototype._makeBounds = function () {
                                var t = this,
                                    e = this._getSeriesDataModel(),
                                    i = p.default.isValidStackOption(this.options.stack),
                                    n = this.layout.dimension,
                                    o = n.width,
                                    r = n.height,
                                    a = this._makeBaseDataForMakingBound(o, r);
                                return e.map(function (e, n) {
                                    var o = n * a.groupSize + t.layout.position.left,
                                        r = t._makeBoxplotChartBound.bind(t, a, { baseLeft: o, left: o, plusTop: 0, minusTop: 0, prevStack: null }, i);
                                    return e.map(r);
                                });
                            }),
                            (e.prototype._calculateLeftPositionOfSumLabel = function (t, e) {
                                return t.left + (t.width - f.default.getRenderedLabelWidth(e, this.theme.label) + m) / 2;
                            }),
                            e
                    );
                })(a.default);
            u.default.mixin(y), (o.componentType = "series"), (o.BoxplotChartSeries = y);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                return (t.chartType = "bullet"), (t.libType = t.chartOptions.libType), (t.chartBackground = t.chartTheme.chart.background), new $(t);
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(240),
                a = n(r),
                s = i(148),
                u = n(s),
                l = i(154),
                h = n(l),
                c = h.default.BULLET_TYPE_ACTUAL,
                p = h.default.BULLET_ACTUAL_HEIGHT_RATIO,
                d = h.default.BULLET_TYPE_RANGE,
                f = h.default.BULLET_RANGES_HEIGHT_RATIO,
                g = h.default.BULLET_TYPE_MARKER,
                m = h.default.BULLET_MARKERS_HEIGHT_RATIO,
                y = h.default.BULLET_MARKER_DETECT_PADDING,
                v = h.default.MAX_HEIGHT_WORD,
                $ = (function (t) {
                    function e(i) {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        var n = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, i));
                        return (n.isVertical = i.isVertical), n;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._makeSeriesData = function () {
                                var t = this._makeBounds();
                                return {
                                    groupBounds: t,
                                    seriesDataModel: this._getSeriesDataModel(),
                                    isVertical: this.isVertical,
                                    isAvailable: function () {
                                        return t && t.length > 0;
                                    },
                                };
                            }),
                            (e.prototype._makeBounds = function () {
                                var t = this,
                                    e = this._getSeriesDataModel(),
                                    i = this._makeBaseDataForMakingBound(),
                                    n = { renderedItemCount: 0, top: i.categoryAxisTop, left: i.categoryAxisLeft };
                                return e.map(function (e) {
                                    var o = t._makeBulletChartBound.bind(t, i, n),
                                        r = e.map(o);
                                    return t._updateIterationData(n, i.itemWidth), r;
                                });
                            }),
                            (e.prototype._makeBaseDataForMakingBound = function () {
                                var t = this._getSeriesDataModel().getGroupCount(),
                                    e = this.layout,
                                    i = e.dimension,
                                    n = i.width,
                                    o = i.height,
                                    r = e.position,
                                    a = r.left,
                                    s = r.top,
                                    u = void 0,
                                    l = void 0;
                                this.isVertical ? ((s += o), (u = n), (l = o)) : ((u = o), (l = n));
                                var h = u / t;
                                return { categoryAxisTop: s, categoryAxisLeft: a, categoryAxisWidth: u, valueAxisWidth: l, itemWidth: h };
                            }),
                            (e.prototype._makeBulletChartBound = function (t, e, i) {
                                var n = i.type,
                                    o = void 0;
                                return n === c ? (o = this._makeBarBound(i, p, t, e)) : n === d ? (o = this._makeBarBound(i, f, t, e)) : n === g && (o = this._makeLineBound(i, m, t, e)), (o.type = n), o;
                            }),
                            (e.prototype._makeBarBound = function (t, e, i, n) {
                                var o = t.ratioDistance,
                                    r = t.endRatio,
                                    a = i.itemWidth * e,
                                    s = i.valueAxisWidth * o,
                                    u = i.valueAxisWidth * r;
                                return this.isVertical ? this._makeVerticalBarBound(n, i, a, s, u) : this._makeHorizontalBarBound(n, i, a, s, u);
                            }),
                            (e.prototype._makeVerticalBarBound = function (t, e, i, n, o) {
                                return { top: t.top - o, left: t.left + (e.itemWidth - i) / 2, width: i, height: n };
                            }),
                            (e.prototype._makeHorizontalBarBound = function (t, e, i, n, o) {
                                return { top: t.top + (e.itemWidth - i) / 2, left: t.left + o - n, width: n, height: i };
                            }),
                            (e.prototype._makeLineBound = function (t, e, i, n) {
                                var o = i.itemWidth,
                                    r = i.valueAxisWidth,
                                    a = o * e,
                                    s = r * t.endRatio,
                                    u = y,
                                    l = y,
                                    h = void 0,
                                    c = void 0;
                                return this.isVertical ? ((h = n.top - s), (c = n.left + (o - a) / 2), (l = a)) : ((h = n.top + (o - a) / 2), (c = n.left + s), (u = a)), { top: h, left: c, width: l, height: u, length: a };
                            }),
                            (e.prototype._updateIterationData = function (t, e) {
                                (t.renderedItemCount += 1), this.isVertical ? (t.left += e) : (t.top += e);
                            }),
                            (e.prototype._renderSeriesArea = function (t, e) {
                                a.default.prototype._renderSeriesArea.call(this, t, e), this.dataProcessor.setGraphColors(this.graphRenderer.getGraphColors());
                            }),
                            (e.prototype._renderSeriesLabel = function (t) {
                                var e = this.theme.label,
                                    i = this._getSeriesDataModel(),
                                    n = this._getLabelTexts(i),
                                    o = this._calculateLabelPositions(i, e);
                                return this.graphRenderer.renderSeriesLabel(t, o, n, e);
                            }),
                            (e.prototype._getLabelTexts = function (t) {
                                var e = this;
                                return t.map(function (t) {
                                    var i = [];
                                    return (
                                        t.each(function (t) {
                                            t.type !== d && i.push(e.decorateLabel(t.endLabel));
                                        }),
                                            i
                                    );
                                });
                            }),
                            (e.prototype._calculateLabelPositions = function (t, e) {
                                var i = this,
                                    n = this.seriesData.groupBounds,
                                    o = u.default.getRenderedLabelHeight(v, e);
                                return n.map(function (t) {
                                    var e = [];
                                    return (
                                        t.forEach(function (t) {
                                            t.type !== d && e.push(i._makePositionByBound(t, o));
                                        }),
                                            e
                                    );
                                });
                            }),
                            (e.prototype._makePositionByBound = function (t, e) {
                                var i = t.top,
                                    n = t.left,
                                    o = {};
                                if (this.isVertical) {
                                    var r = t.width || t.length;
                                    (o.top = i - e), (o.left = n + r / 2);
                                } else {
                                    var a = t.width || 0,
                                        s = t.height || t.length;
                                    (o.top = i + s / 2), (o.left = n + 5 + (a || 0));
                                }
                                return o;
                            }),
                            e
                    );
                })(a.default);
            (o.componentType = "series"), (o.BulletChartSeries = $);
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                return new C(t);
            }
            (e.__esModule = !0), (e.default = o);
            var r = i(153),
                a = n(r),
                s = i(259),
                u = n(s),
                l = i(154),
                h = n(l),
                c = i(155),
                p = n(c),
                d = i(173),
                f = n(d),
                g = i(148),
                m = n(g),
                y = i(211),
                v = n(y),
                $ = i(164),
                x = n($),
                T = h.default.COMPONENT_TYPE_DOM,
                b = h.default.MAP_CHART_ZOOM_AREA_HEIGHT,
                _ = h.default.MAP_CHART_ZOOM_AREA_WIDTH,
                A = h.default.CHART_PADDING,
                D = a.default.msie && a.default.version <= 8,
                C = (function () {
                    function t(e) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.className = "tui-chart-zoom-area");
                        var i = e.seriesTypes,
                            n = !(!i || !i.length) && x.default.isMapChart(i[0]),
                            o = e.dataProcessor.options.legend,
                            r = x.default.isLegendAlignTop(o.align),
                            a = !1 !== o.visible;
                        (this.isMapLegendTop = n && r && a), (this.eventBus = e.eventBus), (this.magn = 1), (this.stackedWheelDelta = 0), (this.drawingType = T), this._attachToEventBus();
                    }
                    return (
                        (t.prototype._attachToEventBus = function () {
                            this.eventBus.on("wheel", this.onWheel, this);
                        }),
                            (t.prototype.render = function (t) {
                                var e = void 0;
                                if (!D) {
                                    var i = f.default.sum([t.positionMap.series.top, -b, _]);
                                    this.isMapLegendTop && (i = t.positionMap.legend.top - _);
                                    var n = { top: i, right: A };
                                    (e = p.default.create("DIV", this.className)), (e.innerHTML += u.default.ZOOM_BUTTONS), m.default.renderPosition(e, n), this._attachEvent(e);
                                }
                                return e;
                            }),
                            (t.prototype._findBtnElement = function (t) {
                                var e = "tui-chart-zoom-btn",
                                    i = t;
                                return p.default.hasClass(t, e) || (i = p.default.findParentByClass(t, e)), i;
                            }),
                            (t.prototype._zoom = function (t, e) {
                                this.eventBus.fire("zoomMap", t, e);
                            }),
                            (t.prototype._onClick = function (t) {
                                var e = t.target || t.srcElement,
                                    i = this._findBtnElement(e).getAttribute("data-magn"),
                                    n = this._calculateMagn(i);
                                return n > 5 ? (this.magn = 5) : n < 1 ? (this.magn = 1) : n >= 1 && this._zoom(n), t.preventDefault && t.preventDefault(), !1;
                            }),
                            (t.prototype._attachEvent = function (t) {
                                v.default.on(t, "click", this._onClick, this);
                            }),
                            (t.prototype._calculateMagn = function (t) {
                                return t > 0 ? (this.magn += 0.1) : t < 0 && (this.magn -= 0.1), this.magn;
                            }),
                            (t.prototype.onWheel = function (t, e) {
                                var i = this._calculateMagn(t);
                                i > 5 ? (this.magn = 5) : i < 1 ? (this.magn = 1) : i >= 1 && this._zoom(i, e);
                            }),
                            t
                    );
                })();
            o.componentType = "zoom";
        },
        function (t, e, i) {
            "use strict";
            e.__esModule = !0;
            var n,
                o = (n = i(224)) && n.__esModule ? n : { default: n },
                r = {
                    HTML_SERIES_LABEL: '<div class="tui-chart-series-label" {{ rangeLabelAttribute }}>{{ label }}</div>',
                    TEXT_CSS_TEXT: "left:{{ left }}px;top:{{ top }}px;font-family:{{ fontFamily }};font-size:{{ fontSize }}px;font-weight:{{ fontWeight }}{{opacity}}",
                    TEXT_CSS_TEXT_FOR_LINE_TYPE: "left:{{ left }}%;top:{{ top }}%;font-family:{{ fontFamily }};font-size:{{ fontSize }}px;font-weight:{{ fontWeight }}{{opacity}}",
                    HTML_ZOOM_BUTTONS: '<a class="tui-chart-zoom-btn zoom-in" href="#" data-magn="1"></a><a class="tui-chart-zoom-btn zoom-out" href="#" data-magn="-1"></a>',
                    HTML_SERIES_BLOCK: '<div class="tui-chart-series-block" >{{ label }}</div>',
                };
            e.default = {
                tplSeriesLabel: o.default.template(r.HTML_SERIES_LABEL),
                tplCssText: o.default.template(r.TEXT_CSS_TEXT),
                tplCssTextForLineType: o.default.template(r.TEXT_CSS_TEXT_FOR_LINE_TYPE),
                ZOOM_BUTTONS: r.HTML_ZOOM_BUTTONS,
                tplSeriesBlock: o.default.template(r.HTML_SERIES_BLOCK),
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            function o(t) {
                if (Array.isArray(t)) {
                    for (var e = 0, i = Array(t.length); e < t.length; e++) i[e] = t[e];
                    return i;
                }
                return Array.from(t);
            }
            e.__esModule = !0;
            var r = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                a = i(145),
                s = n(a),
                u = i(140),
                l = n(u),
                h = i(146),
                c = n(h),
                p = i(137),
                d = n(p),
                f = i(157),
                g = n(f),
                m = i(261),
                y = n(m),
                v = i(154),
                $ = n(v),
                x = i(262),
                T = n(x),
                b = i(263),
                _ = n(b),
                A = i(267),
                D = n(A),
                C = i(269),
                E = n(C),
                L = i(270),
                S = n(L),
                P = i(264),
                M = n(P),
                w = i(181),
                k = n(w),
                I = i(164),
                R = n(I),
                B = i(148),
                O = n(B),
                N = i(173),
                F = n(N),
                G = i(186),
                H = n(G),
                V = (function (t) {
                    function e(i, n, o, r) {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        var a = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this));
                        return (
                            (a.originalRawData = H.default.deepCopy(i)),
                                (a.chartType = n),
                                (a.options = o),
                                (a.seriesTypes = r),
                                (a.originalLegendData = null),
                                (a.selectLegendIndex = null),
                                (a.dynamicData = []),
                                (a.defaultValues = [0, 500]),
                                a.initData(i),
                                a.initZoomedRawData(),
                                a.baseInit(),
                            a.isLineCoordinateType() && (a.integratedXAxisData = a._integrateXAxisData()),
                                a
                        );
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._integrateXAxisData = function () {
                                var t = this.rawData.series.line,
                                    e = this.options.xAxis || {},
                                    i = [],
                                    n = !1;
                                return (
                                    (n = (0, l.default)(e)
                                        ? e.filter(function (t) {
                                            return t.type && R.default.isDatetimeType(t.type);
                                        })
                                        : e.type && R.default.isDatetimeType(e.type)),
                                        t.forEach(function (t) {
                                            t.data.forEach(function (t) {
                                                i.push(t[0]);
                                            });
                                        }),
                                        (i = [].concat(o(new Set(i)))),
                                    n &&
                                    (i = i.map(function (t) {
                                        return new Date(t);
                                    })),
                                        i.sort(function (t, e) {
                                            return t - e;
                                        })
                                );
                            }),
                            (e.prototype.getOriginalRawData = function () {
                                return H.default.deepCopy(this.originalRawData);
                            }),
                            (e.prototype.getCurrentData = function () {
                                var t = this.zoomedRawData;
                                return t ? H.default.deepCopy(t) : this.getOriginalRawData();
                            }),
                            (e.prototype._filterSeriesDataByIndexRange = function (t, e, i) {
                                var n = [].concat(o(t));
                                return (
                                    n.forEach(function (t) {
                                        t.data = t.data.slice(e, i + 1);
                                    }),
                                        n
                                );
                            }),
                            (e.prototype._filterRawDataByIndexRange = function (t, e) {
                                var i = this,
                                    n = r(e, 2),
                                    o = n[0],
                                    a = n[1],
                                    s = Object.assign({}, t);
                                return (
                                    Object.entries(s.series).forEach(function (t) {
                                        var e = r(t, 2),
                                            n = e[0],
                                            u = e[1];
                                        s.series[n] = i._filterSeriesDataByIndexRange(u, o, a);
                                    }),
                                    s.categories && (s.categories = s.categories.slice(o, a + 1)),
                                        s
                                );
                            }),
                            (e.prototype._filterSeriesDataByValue = function (t, e, i) {
                                var n = R.default.isDatetimeType(this.options.xAxis.type),
                                    r = [].concat(o(t));
                                return (
                                    r.forEach(function (t) {
                                        t.data = t.data.filter(function (t) {
                                            var o = n ? new Date(t[0]) : t[0];
                                            return o >= e && o <= i;
                                        });
                                    }),
                                        r
                                );
                            }),
                            (e.prototype._filterRawDataByValue = function (t, e) {
                                var i = this,
                                    n = r(e, 2),
                                    o = n[0],
                                    a = n[1],
                                    s = Object.assign({}, t);
                                return (
                                    Object.entries(s.series).forEach(function (t) {
                                        var e = r(t, 2),
                                            n = e[0],
                                            u = e[1];
                                        s.series[n] = i._filterSeriesDataByValue(u, o, a);
                                    }),
                                        s
                                );
                            }),
                            (e.prototype.updateRawDataForZoom = function (t) {
                                var e = this.getCurrentData(),
                                    i = this.getRawData(),
                                    n = this.isLineCoordinateType() ? this._filterRawDataByValue.bind(this) : this._filterRawDataByIndexRange.bind(this);
                                (this.zoomedRawData = n(e, t)), (i = n(i, t)), this.initData(i);
                            }),
                            (e.prototype.initZoomedRawData = function () {
                                this.zoomedRawData = null;
                            }),
                            (e.prototype.initData = function (t) {
                                var e = arguments.length > 1 && void 0 !== arguments[1] && arguments[1];
                                (this.rawData = t),
                                e && ((this.originalRawData = H.default.deepCopy(t)), (this.originalLegendData = null)),
                                    (this.categoriesMap = null),
                                    (this.categoriesIsDateTime = {}),
                                    (this.stacks = null),
                                    (this.seriesDataModelMap = {}),
                                    (this.legendVisibilities = null),
                                    (this.zoomedRawData = null),
                                    (this.seriesGroups = null),
                                    (this.valuesMap = {}),
                                    (this.legendLabels = null),
                                    (this.legendData = null),
                                    (this.multilineCategories = null),
                                    (this.coordinateType = null),
                                    (this.lineCoordinateType = null);
                            }),
                            (e.prototype.getRawData = function () {
                                return this.rawData;
                            }),
                            (e.prototype.findChartType = function (t) {
                                return k.default.findChartType(this.rawData.seriesAlias, t);
                            }),
                            (e.prototype._escapeCategories = function (t) {
                                return t.map(function (t) {
                                    return (0, y.default)(String(t));
                                });
                            }),
                            (e.prototype._mapCategories = function (t, e) {
                                var i = this,
                                    n = this.options[e + "Axis"] || {},
                                    o = !1;
                                return (
                                    (t = (o = (0, l.default)(n)
                                        ? n.filter(function (t) {
                                            return t.type && R.default.isDatetimeType(t.type);
                                        })
                                        : n.type && R.default.isDatetimeType(n.type))
                                        ? t.map(function (t) {
                                            return i.chageDatetypeToTimestamp(t);
                                        })
                                        : this._escapeCategories(t)),
                                        (this.categoriesIsDateTime[e] = o),
                                        t
                                );
                            }),
                            (e.prototype._processCategories = function (t) {
                                var e = this.rawData.categories,
                                    i = {};
                                return (0, l.default)(e) ? (i[t] = this._mapCategories(e, t)) : e && (e.x && (i.x = this._mapCategories(e.x, "x")), e.y && (i.y = this._mapCategories(e.y, "y").reverse())), i;
                            }),
                            (e.prototype.getCategories = function (t) {
                                var e = t ? "y" : "x",
                                    i = [];
                                return (
                                    this.categoriesMap || (this.categoriesMap = this._processCategories(e)),
                                        (0, c.default)(t)
                                            ? (i = this.categoriesMap[e] || [])
                                            : Object.values(this.categoriesMap).every(function (t) {
                                                return (i = t), !1;
                                            }),
                                        i
                                );
                            }),
                            (e.prototype.getCategorieDateType = function (t) {
                                return this.categoriesIsDateTime[t ? "y" : "x"];
                            }),
                            (e.prototype.chageDatetypeToTimestamp = function (t) {
                                var e = new Date(t);
                                return e.getTime() > 0 || (e = new Date(parseInt(t, 10))), e.getTime() || t;
                            }),
                            (e.prototype.getCategoryCount = function (t) {
                                var e = this.getCategories(t);
                                return e ? e.length : 0;
                            }),
                            (e.prototype.hasCategories = function (t) {
                                return !!this.getCategoryCount(t);
                            }),
                            (e.prototype.isXCountGreaterThanYCount = function (t) {
                                return this.getSeriesDataModel(t).isXCountGreaterThanYCount();
                            }),
                            (e.prototype.hasXValue = function (t) {
                                var e = this.isXCountGreaterThanYCount(t);
                                return !this.hasCategories(e) || e;
                            }),
                            (e.prototype.hasYValue = function (t) {
                                var e = this.isXCountGreaterThanYCount(t);
                                return !this.hasCategories(e) || !e;
                            }),
                            (e.prototype.getCategory = function (t, e) {
                                return this.getCategories(e)[t];
                            }),
                            (e.prototype.findCategoryIndex = function (t) {
                                var e = this,
                                    i = this.getCategories(),
                                    n = this.getCategorieDateType(),
                                    o = null;
                                return (
                                    i.forEach(function (i, r) {
                                        return n && (t = e.chageDatetypeToTimestamp(t)), i === t && (o = r), (0, d.default)(o);
                                    }),
                                        o
                                );
                            }),
                            (e.prototype.findAbsoluteCategoryIndex = function (t) {
                                var e = this.originalRawData ? this.originalRawData.categories : null,
                                    i = -1;
                                return (
                                    e &&
                                    e.forEach(function (e, n) {
                                        var o = e === t;
                                        return o && (i = n), !o;
                                    }),
                                        i
                                );
                            }),
                            (e.prototype._getTooltipCategory = function (t, e) {
                                var i = this.getCategory(t, e),
                                    n = this.options[e ? "yAxis" : "xAxis"] || {},
                                    o = this.options.tooltip || {};
                                return R.default.isDatetimeType(o.type) ? (i = O.default.formatDate(i, o.dateFormat)) : R.default.isDatetimeType(n.type) && (i = O.default.formatDate(i, n.dateFormat)), i;
                            }),
                            (e.prototype.makeTooltipCategory = function (t, e, i) {
                                var n = !i,
                                    o = this._getTooltipCategory(t, n),
                                    r = this.getCategoryCount(!n);
                                return r && (o += ", " + this._getTooltipCategory(r - e - 1, !n)), o;
                            }),
                            (e.prototype.getStacks = function (t) {
                                return this.stacks || (this.stacks = k.default.pickStacks(this.rawData.series[t])), this.stacks;
                            }),
                            (e.prototype.getStackCount = function (t) {
                                return this.getStacks(t).length;
                            }),
                            (e.prototype.findStackIndex = function (t) {
                                return (0, g.default)(t, this.getStacks());
                            }),
                            (e.prototype.isLineCoordinateType = function () {
                                var t = this.lineCoordinateType;
                                if (!(0, c.default)(t)) {
                                    var e = this.chartType;
                                    (t = R.default.isLineTypeChart(e) && !this.hasCategories()), (this.lineCoordinateType = t);
                                }
                                return t;
                            }),
                            (e.prototype.isCoordinateType = function () {
                                var t = this.coordinateType;
                                if (!(0, c.default)(t)) {
                                    var e = this.chartType;
                                    (t = (t = (t = R.default.isCoordinateTypeChart(e)) || R.default.isLineScatterComboChart(e, this.seriesTypes)) || (R.default.isLineTypeChart(e) && !this.hasCategories())), (this.coordinateType = t);
                                }
                                return t;
                            }),
                            (e.prototype.getSeriesDataModel = function (t) {
                                if (!this.seriesDataModelMap[t]) {
                                    var e = this.findChartType(t),
                                        i = this.rawData.series[t],
                                        n = void 0;
                                    (n = R.default.isBoxplotChart(this.chartType) ? D.default : R.default.isTreemapChart(this.chartType) ? S.default : R.default.isBulletChart(this.chartType) ? E.default : _.default),
                                        (this.seriesDataModelMap[t] = new n(i, e, this.options, this.getFormatFunctions(), this.isCoordinateType()));
                                }
                                return this.seriesDataModelMap[t];
                            }),
                            (e.prototype.getOption = function (t) {
                                return this.options[t];
                            }),
                            (e.prototype.getGroupCount = function (t) {
                                return this.getSeriesDataModel(t).getGroupCount();
                            }),
                            (e.prototype._pushCategory = function (t) {
                                this.rawData.categories && (this.rawData.categories.push(t), this.originalRawData.categories.push(t));
                            }),
                            (e.prototype._shiftCategory = function () {
                                this.rawData.categories && (this.rawData.categories.shift(), this.originalRawData.categories.shift());
                            }),
                            (e.prototype._findRawSeriesDatumByName = function (t, e) {
                                var i = this.rawData.series[e],
                                    n = null;
                                return (
                                    i.forEach(function (e) {
                                        var i = e.name === t;
                                        return i && (n = e), !i;
                                    }),
                                        n
                                );
                            }),
                            (e.prototype._pushValue = function (t, e, i) {
                                var n = this._findRawSeriesDatumByName(t.name, i);
                                t.data.push(e), n && n.data.push(e);
                            }),
                            (e.prototype._pushValues = function (t, e, i) {
                                var n = this;
                                t.forEach(function (t, o) {
                                    n._pushValue(t, e[o], i);
                                });
                            }),
                            (e.prototype._pushSeriesData = function (t) {
                                var e = this;
                                if ("combo" !== this.chartType && (0, l.default)(t)) {
                                    var i = t;
                                    (t = {})[this.chartType] = i;
                                }
                                Object.entries(this.originalRawData.series).forEach(function (i) {
                                    var n = r(i, 2),
                                        o = n[0],
                                        a = n[1];
                                    e._pushValues(a, t[o], o);
                                });
                            }),
                            (e.prototype._shiftValues = function (t, e) {
                                var i = this;
                                t.forEach(function (t) {
                                    var n = i._findRawSeriesDatumByName(t.name, e);
                                    t.data.shift(), n && n.data.shift();
                                });
                            }),
                            (e.prototype._shiftSeriesData = function () {
                                var t = this;
                                Object.entries(this.originalRawData.series).forEach(function (e) {
                                    var i = r(e, 2),
                                        n = i[0],
                                        o = i[1];
                                    t._shiftValues(o, n);
                                });
                            }),
                            (e.prototype.addDynamicData = function (t, e) {
                                this.dynamicData.push({ category: t, values: e });
                            }),
                            (e.prototype._pushDynamicData = function (t) {
                                this._pushCategory(t.category), this._pushSeriesData(t.values);
                            }),
                            (e.prototype._pushDynamicDataForCoordinateType = function (t) {
                                var e = this;
                                Object.values(this.originalRawData.series).forEach(function (i) {
                                    e._pushValue(i, t[i.name]);
                                });
                            }),
                            (e.prototype.addDataFromDynamicData = function () {
                                var t = this.dynamicData.shift();
                                return t && (this.isCoordinateType() ? this._pushDynamicDataForCoordinateType(t.values) : this._pushDynamicData(t), this.initData(this.rawData)), !!t;
                            }),
                            (e.prototype.shiftData = function () {
                                this._shiftCategory(), this._shiftSeriesData(), this.initData(this.rawData);
                            }),
                            (e.prototype.addDataFromRemainDynamicData = function (t) {
                                var e = this,
                                    i = this.dynamicData;
                                (this.dynamicData = []),
                                    i.forEach(function (i) {
                                        e._pushCategory(i.category), e._pushSeriesData(i.values), t && (e._shiftCategory(), e._shiftSeriesData());
                                    }),
                                    this.initData(this.rawData);
                            }),
                            (e.prototype._eachByAllSeriesDataModel = function (t) {
                                var e = this;
                                (this.seriesTypes || [this.chartType]).forEach(function (i) {
                                    return t(e.getSeriesDataModel(i), i);
                                });
                            }),
                            (e.prototype.isValidAllSeriesDataModel = function () {
                                var t = !0;
                                return (
                                    this._eachByAllSeriesDataModel(function (e) {
                                        t = !!e.getGroupCount();
                                    }),
                                        t
                                );
                            }),
                            (e.prototype._makeSeriesGroups = function () {
                                var t = [];
                                return (
                                    this._eachByAllSeriesDataModel(function (e) {
                                        e.each(function (e, i) {
                                            t[i] || (t[i] = []), (t[i] = t[i].concat(e.items));
                                        });
                                    }),
                                        t.map(function (t) {
                                            return new M.default(t);
                                        })
                                );
                            }),
                            (e.prototype.getSeriesGroups = function () {
                                return this.seriesGroups || (this.seriesGroups = this._makeSeriesGroups()), this.seriesGroups;
                            }),
                            (e.prototype.getValue = function (t, e, i) {
                                return this.getSeriesDataModel(i).getValue(t, e);
                            }),
                            (e.prototype.getDefaultDatetimeValues = function () {
                                var t = Date.now();
                                return [t - 36e5, t];
                            }),
                            (e.prototype.isSeriesDataEmpty = function (t) {
                                var e = this.rawData,
                                    i = e && !e.series;
                                return !e || i || !e.series[t] || (e.series[t] && !e.series[t].length);
                            }),
                            (e.prototype.isLimitOptionsEmpty = function (t) {
                                var e = this.options[t] || {};
                                return (0, s.default)(e.min) && (0, s.default)(e.max);
                            }),
                            (e.prototype.isLimitOptionsInsufficient = function (t) {
                                var e = this.options[t] || {};
                                return (0, s.default)(e.min) || (0, s.default)(e.max);
                            }),
                            (e.prototype._createValues = function (t, e, i) {
                                var n = void 0,
                                    o = this.options,
                                    r = o.plot,
                                    a = o[i] || {},
                                    s = a.type,
                                    u = this.isSeriesDataEmpty(t),
                                    l = this.isLimitOptionsEmpty(i),
                                    h = this.isLimitOptionsInsufficient(i),
                                    c = R.default.isLineChart(t) || R.default.isAreaChart(t) || R.default.isLineAreaComboChart(t, this.seriesTypes),
                                    p = this.defaultValues;
                                if (R.default.isComboChart(t))
                                    (n = []),
                                        this._eachByAllSeriesDataModel(function (t) {
                                            n = n.concat(t.getValues(e));
                                        });
                                else if (u && h) {
                                    if ((!l && h && (p = p.concat([a.min || a.max])), "x" === e && "datetime" === s)) {
                                        if (((n = this.getDefaultDatetimeValues()), c && r)) {
                                            var d = this.getValuesFromPlotOptions(r, s);
                                            n = n.concat(d);
                                        }
                                    } else n = p;
                                } else n = this.getSeriesDataModel(t).getValues(e);
                                return n;
                            }),
                            (e.prototype.getValuesFromPlotOptions = function (t, e) {
                                var i = [];
                                return (
                                    t.lines &&
                                    t.lines.forEach(function (t) {
                                        i.push("datetime" !== e ? t.value : new Date(t.value));
                                    }),
                                    t.bands &&
                                    t.bands.forEach(function (t) {
                                        var n = t.range.map(function (t) {
                                            return "datetime" !== e ? t : new Date(t);
                                        });
                                        i = i.concat(n);
                                    }),
                                        i
                                );
                            }),
                            (e.prototype.getValues = function (t, e, i) {
                                var n = t + e;
                                return this.valuesMap[n] || (this.valuesMap[n] = this._createValues(t, e, i)), this.valuesMap[n];
                            }),
                            (e.prototype.eachBySeriesGroup = function (t, e) {
                                this._eachByAllSeriesDataModel(function (i, n) {
                                    i.each(function (e, i) {
                                        t(e, i, n);
                                    }, e);
                                });
                            }),
                            (e.prototype._pickLegendLabel = function (t) {
                                return t.name ? (0, y.default)(t.name) : null;
                            }),
                            (e.prototype._isVisibleLegend = function (t) {
                                var e = !0;
                                return (0, c.default)(t.visible) && !1 === t.visible && (e = !1), e;
                            }),
                            (e.prototype._pickLegendData = function (t) {
                                var e = this.rawData.series,
                                    i = {},
                                    n = void 0;
                                return (
                                    "visibility" === t ? (n = this._isVisibleLegend) : "label" === t && (n = this._pickLegendLabel),
                                    n &&
                                    Object.entries(e).forEach(function (t) {
                                        var e = r(t, 2),
                                            o = e[0],
                                            a = e[1].map(n);
                                        (0, c.default)(a) && (i[o] = a);
                                    }),
                                        i
                                );
                            }),
                            (e.prototype.getLegendLabels = function (t) {
                                return this.legendLabels || (this.legendLabels = this._pickLegendData("label")), this.legendLabels[t] || this.legendLabels;
                            }),
                            (e.prototype.getLegendVisibility = function (t) {
                                return this.legendVisibilities || (this.legendVisibilities = this._pickLegendData("visibility")), this.legendVisibilities[t] || this.legendVisibilities;
                            }),
                            (e.prototype._makeLegendData = function () {
                                var t,
                                    e = this.getLegendLabels(this.chartType),
                                    i = this.getLegendVisibility(),
                                    n = this.seriesTypes || [this.chartType],
                                    r = void 0;
                                (0, l.default)(e) ? ((r = [this.chartType])[this.chartType] = e) : ((n = this.seriesTypes), (r = e));
                                var a = n.map(function (t) {
                                    return r[t].map(function (e, n) {
                                        var o = (0, l.default)(i[t]);
                                        return { chartType: t, label: e, visible: o ? i[t][n] : i[n] };
                                    });
                                });
                                return (t = []).concat.apply(t, o(a));
                            }),
                            (e.prototype.getLegendData = function () {
                                return this.legendData || (this.legendData = this._makeLegendData()), this.originalLegendData || (this.originalLegendData = this.legendData), this.legendData;
                            }),
                            (e.prototype.getOriginalLegendData = function () {
                                return this.originalLegendData;
                            }),
                            (e.prototype.getLegendItem = function (t) {
                                return this.getLegendData()[t];
                            }),
                            (e.prototype.getFirstItemLabel = function (t) {
                                return this.getSeriesDataModel(t).getFirstItemLabel();
                            }),
                            (e.prototype.addDataRatiosOfPieChart = function (t) {
                                this.getSeriesDataModel(t).addDataRatiosOfPieChart();
                            }),
                            (e.prototype.addDataRatiosForCoordinateType = function (t, e, i) {
                                R.default.isLineTypeChart(t) && this._addStartValueToAllSeriesItem(e.yAxis, t), this.getSeriesDataModel(t).addDataRatiosForCoordinateType(e, i);
                            }),
                            (e.prototype._addStartValueToAllSeriesItem = function (t, e) {
                                var i = 0;
                                t.min >= 0 ? (i = t.min) : t.max <= 0 && (i = t.max), this.getSeriesDataModel(e).addStartValueToAllSeriesItem(i);
                            }),
                            (e.prototype.addDataRatios = function (t, e, i) {
                                var n = this.getSeriesDataModel(i);
                                this._addStartValueToAllSeriesItem(t, i), n.addDataRatios(t, e);
                            }),
                            (e.prototype.addDataRatiosForTreemapChart = function (t, e) {
                                this.getSeriesDataModel(e).addDataRatios(t);
                            }),
                            (e.prototype._createBaseValuesForNormalStackedChart = function (t) {
                                var e = this.getSeriesDataModel(t),
                                    i = [];
                                return (
                                    e.each(function (t) {
                                        Object.values(t._makeValuesMapPerStack()).forEach(function (t) {
                                            var e = F.default.sumPlusValues(t),
                                                n = F.default.sumMinusValues(t);
                                            i = i.concat([e, n]);
                                        });
                                    }),
                                        i
                                );
                            }),
                            (e.prototype.createBaseValuesForLimit = function (t, e, i, n, o) {
                                var r = void 0;
                                return (
                                    R.default.isComboChart(this.chartType) && e
                                        ? ((r = this.getValues(this.chartType, n)), R.default.isNormalStackChart(t, i) && (r = r.concat(this._createBaseValuesForNormalStackedChart(t))))
                                        : (r = R.default.isTreemapChart(t) ? this.getValues(t, "colorValue") : R.default.isNormalStackChart(t, i) ? this._createBaseValuesForNormalStackedChart(t) : this.getValues(t, n, o)),
                                        r
                                );
                            }),
                            (e.prototype.findOverflowItem = function (t, e) {
                                var i = this.getSeriesDataModel(t),
                                    n = i.getMaxValue("r"),
                                    o = function (t) {
                                        return t.r / n > $.default.HALF_RATIO;
                                    };
                                return { minItem: i.findMinSeriesItem(e, o), maxItem: i.findMaxSeriesItem(e, o) };
                            }),
                            (e.prototype.setGraphColors = function (t) {
                                this.graphColors = t;
                            }),
                            (e.prototype.getGraphColors = function () {
                                return this.graphColors;
                            }),
                            (e.prototype.isComboDonutShowOuterLabel = function () {
                                var t = this.options.series;
                                return t && t.pie2 && "outer" === t.pie2.labelAlign;
                            }),
                            e
                    );
                })(T.default);
            e.default = V;
        },
        function (t, e) {
            "use strict";
            t.exports = function t(e) {
                var i = { '"': "quot", "&": "amp", "<": "lt", ">": "gt", "'": "#39" };
                return e.replace(/[<>&"']/g, function (t) {
                    return i[t] ? "&" + i[t] + ";" : t;
                });
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(187),
                r = n(o),
                a = i(156),
                s = n(a),
                u = i(152),
                l = n(u),
                h = i(158),
                c = n(h),
                p = i(148),
                d = n(p),
                f = i(173),
                g = n(f),
                m = (function () {
                    function t() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t);
                    }
                    return (
                        (t.prototype.baseInit = function () {
                            this.formatFunctions = null;
                        }),
                            (t.prototype.getValues = function () {}),
                            (t.prototype.getCurrentData = function () {}),
                            (t.prototype.getMaxValue = function (t, e) {
                                return c.default.max(this.getValues(t, e));
                            }),
                            (t.prototype.getMinValue = function (t, e) {
                                return c.default.min(this.getValues(t, e));
                            }),
                            (t.prototype.getFormattedMaxValue = function (t, e, i) {
                                var n = this.getMaxValue(t, i),
                                    o = this.getFormatFunctions();
                                return d.default.formatValue({ value: n, formatFunctions: o, chartType: t, areaType: e, valueType: i });
                            }),
                            (t.prototype.getFormattedMinValue = function (t, e, i) {
                                var n = this.getMinValue(t, i),
                                    o = this.getFormatFunctions();
                                return d.default.formatValue({ value: n, formatFunctions: o, chartType: t, areaType: e, valueType: i });
                            }),
                            (t.prototype._pickMaxLenUnderPoint = function (t) {
                                var e = 0;
                                return (
                                    t.forEach(function (t) {
                                        var i = g.default.getDecimalLength(t);
                                        i > e && (e = i);
                                    }),
                                        e
                                );
                            }),
                            (t.prototype._isZeroFill = function (t) {
                                return t.length > 2 && "0" === t.charAt(0);
                            }),
                            (t.prototype._isDecimal = function (t) {
                                var e = t.indexOf(".");
                                return e > -1 && e < t.length - 1;
                            }),
                            (t.prototype._isComma = function (t) {
                                return t.indexOf(",") > -1;
                            }),
                            (t.prototype._formatToZeroFill = function (t, e) {
                                return (e < 0 ? "-" : "") + (e = d.default.formatToZeroFill(Math.abs(e), t));
                            }),
                            (t.prototype._formatToDecimal = function (t, e) {
                                return d.default.formatToDecimal(e, t);
                            }),
                            (t.prototype._findSimpleTypeFormatFunctions = function (t) {
                                var e = [],
                                    i = void 0;
                                if (this._isDecimal(t)) (i = this._pickMaxLenUnderPoint([t])), (e = [this._formatToDecimal.bind(this, i)]);
                                else if (this._isZeroFill(t)) return (i = t.length), (e = [this._formatToZeroFill.bind(this, i)]);
                                return this._isComma(t) && e.push(d.default.formatToComma), e;
                            }),
                            (t.prototype._findFormatFunctions = function () {
                                var t = (0, l.default)(this.options, "chart", "format"),
                                    e = [];
                                return (0, r.default)(t) ? (e = [t]) : (0, s.default)(t) && (e = this._findSimpleTypeFormatFunctions(t)), e;
                            }),
                            (t.prototype.getFormatFunctions = function () {
                                return this.formatFunctions || (this.formatFunctions = this._findFormatFunctions()), this.formatFunctions;
                            }),
                            t
                    );
                })();
            e.default = m;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                r = i(140),
                a = n(r),
                s = i(146),
                u = n(s),
                l = i(152),
                h = n(l),
                c = i(264),
                p = n(c),
                d = i(265),
                f = n(d),
                g = i(266),
                m = n(g),
                y = i(164),
                v = n(y),
                $ = i(173),
                x = n($),
                T = i(158),
                b = n(T),
                _ = Array.prototype.concat,
                A = (function () {
                    function t(e, i, n, o, r) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.chartType = i),
                            (this.options = n || {}),
                            (this.formatFunctions = o),
                            (this.rawSeriesData = e || []),
                            (this.isCoordinateType = r),
                            (this.baseGroups = null),
                            (this.groups = null),
                            (this.options.series = this.options.series || {}),
                            (this.isDivergingChart = v.default.isDivergingChart(i, this.options.series.diverging)),
                            (this.valuesMap = {}),
                            this._removeRangeValue();
                    }
                    return (
                        (t.prototype._removeRangeValue = function () {
                            var t = (0, h.default)(this.options, "series") || {};
                            (v.default.isAllowRangeData(this.chartType) && !v.default.isValidStackOption(t.stack) && !t.spline) ||
                            this.isCoordinateType ||
                            Object.values(this.rawSeriesData).forEach(function (t) {
                                (0, a.default)(t.data) &&
                                t.data.forEach(function (e, i) {
                                    if ((0, u.default)(e)) {
                                        var n = _.apply(e),
                                            r = o(n, 1);
                                        t.data[i] = r[0];
                                    }
                                });
                            });
                        }),
                            (t.prototype._createBaseGroups = function () {
                                var t = this.chartType,
                                    e = this.formatFunctions,
                                    i = this.isDivergingChart,
                                    n = this.isCoordinateType,
                                    o = this.options.xAxis,
                                    r = v.default.isPieChart(this.chartType),
                                    s = v.default.isHeatmapChart(this.chartType) || v.default.isTreemapChart(this.chartType),
                                    l = void 0,
                                    h = void 0;
                                return (
                                    n
                                        ? ((h = m.default),
                                            (l = function (t) {
                                                t.sort(function (t, e) {
                                                    return t.x - e.x;
                                                });
                                            }))
                                        : ((h = f.default), (l = function () {})),
                                        this.rawSeriesData.map(function (c) {
                                            var p = void 0,
                                                d = void 0,
                                                f = void 0;
                                            (d = (0, a.default)(c) ? c : [].concat(c.data)), s || (p = c.stack), c.name && (f = c.name), (n || r) && (d = d.filter(u.default));
                                            var g = d.map(function (n, r) {
                                                return new h({ datum: n, chartType: t, formatFunctions: e, index: r, legendName: f, stack: p, isDivergingChart: i, xAxisType: o.type, dateFormat: o.dateFormat });
                                            });
                                            return l(g), g;
                                        })
                                );
                            }),
                            (t.prototype._getBaseGroups = function () {
                                return this.baseGroups || (this.baseGroups = this._createBaseGroups()), this.baseGroups;
                            }),
                            (t.prototype._createSeriesGroupsFromRawData = function (t) {
                                var e = this._getBaseGroups();
                                return (
                                    t && (e = b.default.pivot(e)),
                                        e.map(function (t) {
                                            return new p.default(t);
                                        })
                                );
                            }),
                            (t.prototype._getSeriesGroups = function () {
                                return this.groups || (this.groups = this._createSeriesGroupsFromRawData(!0)), this.groups;
                            }),
                            (t.prototype.getGroupCount = function () {
                                return this._getSeriesGroups().length;
                            }),
                            (t.prototype._getPivotGroups = function () {
                                return this.pivotGroups || (this.pivotGroups = this._createSeriesGroupsFromRawData()), this.pivotGroups;
                            }),
                            (t.prototype.getSeriesGroup = function (t, e) {
                                return e ? this._getPivotGroups()[t] : this._getSeriesGroups()[t];
                            }),
                            (t.prototype.getFirstSeriesGroup = function (t) {
                                return this.getSeriesGroup(0, t);
                            }),
                            (t.prototype.getFirstItemLabel = function () {
                                return this.getFirstSeriesGroup().getFirstSeriesItem().label;
                            }),
                            (t.prototype.getSeriesItem = function (t, e, i) {
                                return this.getSeriesGroup(t, i).getSeriesItem(e);
                            }),
                            (t.prototype.getFirstSeriesItem = function () {
                                return this.getSeriesItem(0, 0);
                            }),
                            (t.prototype.getValue = function (t, e) {
                                return this.getSeriesItem(t, e).value;
                            }),
                            (t.prototype.getMinValue = function (t) {
                                return b.default.min(this.getValues(t));
                            }),
                            (t.prototype.getMaxValue = function (t) {
                                return b.default.max(this.getValues(t));
                            }),
                            (t.prototype._findSeriesItem = function (t) {
                                var e = void 0;
                                return (
                                    this.each(function (i) {
                                        return !(e = i.find(t));
                                    }),
                                        e
                                );
                            }),
                            (t.prototype._findSeriesItemByValue = function (t, e, i) {
                                return (
                                    (i =
                                        i ||
                                        function () {
                                            return null;
                                        }),
                                        this._findSeriesItem(function (n) {
                                            return n && n[t] === e && i(n);
                                        })
                                );
                            }),
                            (t.prototype.findMinSeriesItem = function (t, e) {
                                var i = this.getMinValue(t);
                                return this._findSeriesItemByValue(t, i, e);
                            }),
                            (t.prototype.findMaxSeriesItem = function (t, e) {
                                var i = this.getMaxValue(t);
                                return this._findSeriesItemByValue(t, i, e);
                            }),
                            (t.prototype._createValues = function (t) {
                                var e,
                                    i = this.map(function (e) {
                                        return e.getValues(t);
                                    });
                                return (i = (e = []).concat.apply(
                                    e,
                                    (function t(e) {
                                        if (Array.isArray(e)) {
                                            for (var i = 0, n = Array(e.length); i < e.length; i++) n[i] = e[i];
                                            return n;
                                        }
                                        return Array.from(e);
                                    })(i)
                                )).filter(function (t) {
                                    return !isNaN(t);
                                });
                            }),
                            (t.prototype.getValues = function () {
                                var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : "value";
                                return this.valuesMap[t] || (this.valuesMap[t] = this._createValues(t)), this.valuesMap[t];
                            }),
                            (t.prototype.isXCountGreaterThanYCount = function () {
                                return this.getValues("x").length > this.getValues("y").length;
                            }),
                            (t.prototype._addRatiosWhenNormalStacked = function (t) {
                                var e = Math.abs(t.max - t.min);
                                this.each(function (t) {
                                    t.addRatios(e);
                                });
                            }),
                            (t.prototype._calculateBaseRatio = function () {
                                var t = this.getValues(),
                                    e = x.default.sumPlusValues(t),
                                    i = Math.abs(x.default.sumMinusValues(t));
                                return e > 0 && i > 0 ? 0.5 : 1;
                            }),
                            (t.prototype._addRatiosWhenPercentStacked = function () {
                                var t = this._calculateBaseRatio();
                                this.each(function (e) {
                                    e.addRatiosWhenPercentStacked(t);
                                });
                            }),
                            (t.prototype._addRatiosWhenDivergingStacked = function () {
                                this.each(function (t) {
                                    var e = t.pluck("value"),
                                        i = x.default.sumPlusValues(e),
                                        n = Math.abs(x.default.sumMinusValues(e));
                                    t.addRatiosWhenDivergingStacked(i, n);
                                });
                            }),
                            (t.prototype._makeSubtractionValue = function (t) {
                                var e = v.default.allowMinusPointRender(this.chartType),
                                    i = 0;
                                return !e && v.default.isMinusLimit(t) ? (i = t.max) : (e || t.min >= 0) && (i = t.min), i;
                            }),
                            (t.prototype._addRatios = function (t) {
                                var e = Math.abs(t.max - t.min),
                                    i = this._makeSubtractionValue(t);
                                this.each(function (t) {
                                    t.addRatios(e, i);
                                });
                            }),
                            (t.prototype.addDataRatios = function (t, e) {
                                var i = v.default.isAllowedStackOption(this.chartType);
                                i && v.default.isNormalStack(e)
                                    ? this._addRatiosWhenNormalStacked(t)
                                    : i && v.default.isPercentStack(e)
                                        ? this.isDivergingChart
                                            ? this._addRatiosWhenDivergingStacked()
                                            : this._addRatiosWhenPercentStacked()
                                        : this._addRatios(t);
                            }),
                            (t.prototype.addDataRatiosOfPieChart = function () {
                                this.each(function (t) {
                                    var e = x.default.sum(t.pluck("value"));
                                    t.addRatios(e);
                                });
                            }),
                            (t.prototype.addDataRatiosForCoordinateType = function (t, e) {
                                var i = t.xAxis,
                                    n = t.yAxis,
                                    o = e ? b.default.max(this.getValues("r")) : 0,
                                    r = void 0,
                                    a = void 0,
                                    s = void 0,
                                    l = void 0;
                                i && ((r = Math.abs(i.max - i.min)), (a = this._makeSubtractionValue(i))),
                                n && ((s = Math.abs(n.max - n.min)), (l = this._makeSubtractionValue(n))),
                                    this.each(function (t) {
                                        t.each(function (t) {
                                            t && (t.addRatio("x", r, a), t.addRatio("y", s, l), t.addRatio("r", o, 0), (0, u.default)(t.start) && t.addRatio("start", s, l));
                                        });
                                    });
                            }),
                            (t.prototype.addStartValueToAllSeriesItem = function (t) {
                                this.each(function (e) {
                                    e.addStartValueToAllSeriesItem(t);
                                });
                            }),
                            (t.prototype.hasRangeData = function t() {
                                var t = !1;
                                return (
                                    this.each(function (e) {
                                        return (t = e.hasRangeData()), !t;
                                    }),
                                        t
                                );
                            }),
                            (t.prototype.each = function (t, e) {
                                (e ? this._getPivotGroups() : this._getSeriesGroups()).forEach(function (e, i) {
                                    return t(e, i);
                                });
                            }),
                            (t.prototype.map = function (t, e) {
                                var i = [];
                                return (
                                    this.each(function (e, n) {
                                        i.push(t(e, n));
                                    }, e),
                                        i
                                );
                            }),
                            t
                    );
                })();
            e.default = A;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                r = i(146),
                a = n(r),
                s = i(189),
                u = n(s),
                l = i(173),
                h = n(l),
                c = (function () {
                    function t(e) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.items = e),
                            (this.valuesMap = {}),
                            (this.valuesMapPerStack = null);
                    }
                    return (
                        (t.prototype.getSeriesItemCount = function () {
                            return this.items.length;
                        }),
                            (t.prototype.getSeriesItem = function (t) {
                                return this.items[t];
                            }),
                            (t.prototype.getFirstSeriesItem = function () {
                                return this.getSeriesItem(0);
                            }),
                            (t.prototype._createValues = function (t) {
                                var e = [];
                                return (
                                    this.each(function (i) {
                                        i && ((0, a.default)(i[t]) && e.push(i[t]), (0, a.default)(i.start) && e.push(i.start));
                                    }),
                                        e
                                );
                            }),
                            (t.prototype.getValues = function (t) {
                                return (t = t || "value"), this.valuesMap[t] || (this.valuesMap[t] = this._createValues(t)), this.valuesMap[t];
                            }),
                            (t.prototype._makeValuesMapPerStack = function () {
                                var t = {};
                                return (
                                    this.each(function (e) {
                                        t[e.stack] || (t[e.stack] = []), t[e.stack].push(e.value);
                                    }),
                                        t
                                );
                            }),
                            (t.prototype.getValuesMapPerStack = function () {
                                return this.valuesMapPerStack || (this.valuesMapPerStack = this._makeValuesMapPerStack()), this.valuesMapPerStack;
                            }),
                            (t.prototype._makeSumMapPerStack = function () {
                                var t = this.getValuesMapPerStack(),
                                    e = {};
                                return (
                                    Object.entries(t).forEach(function (t) {
                                        var i = o(t, 2),
                                            n = i[0],
                                            r = i[1];
                                        e[n] = h.default.sum(
                                            r.map(function (t) {
                                                return Math.abs(t);
                                            })
                                        );
                                    }),
                                        e
                                );
                            }),
                            (t.prototype.addStartValueToAllSeriesItem = function (t) {
                                this.each(function (e) {
                                    e && e.addStart(t);
                                });
                            }),
                            (t.prototype.addRatiosWhenPercentStacked = function (t) {
                                var e = this._makeSumMapPerStack();
                                this.each(function (i) {
                                    var n = e[i.stack];
                                    i.addRatio(n, 0, t);
                                });
                            }),
                            (t.prototype.addRatiosWhenDivergingStacked = function (t, e) {
                                this.each(function (i) {
                                    var n = i.value >= 0 ? t : e;
                                    i.addRatio(n, 0, 0.5);
                                });
                            }),
                            (t.prototype.addRatios = function (t, e) {
                                this.each(function (i) {
                                    i && i.addRatio(t, e);
                                });
                            }),
                            (t.prototype.hasRangeData = function t() {
                                var t = !1;
                                return (
                                    this.each(function (e) {
                                        return (t = e && e.isRange), !t;
                                    }),
                                        t
                                );
                            }),
                            (t.prototype.each = function (t) {
                                this.items.forEach(t);
                            }),
                            (t.prototype.map = function (t) {
                                return this.items.map(t);
                            }),
                            (t.prototype.pluck = function (t) {
                                var e = this.items.filter(a.default);
                                return (0, u.default)(e, t);
                            }),
                            (t.prototype.find = function (t) {
                                var e = void 0;
                                return (
                                    this.each(function (i) {
                                        return t(i) && (e = i), !e;
                                    }),
                                    e || null
                                );
                            }),
                            (t.prototype.filter = function (t) {
                                return this.items.filter(t);
                            }),
                            t
                    );
                })();
            e.default = c;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                r = i(146),
                a = n(r),
                s = i(137),
                u = n(s),
                l = i(154),
                h = n(l),
                c = i(148),
                p = n(c),
                d = i(173),
                f = n(d),
                g = i(164),
                m = n(g),
                y = (function () {
                    function t(e) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.chartType = e.chartType),
                            (this.stack = e.stack || h.default.DEFAULT_STACK),
                            (this.isDivergingChart = e.isDivergingChart),
                            (this.formatFunctions = e.formatFunctions),
                            (this.isRange = !1),
                            (this.value = null),
                            (this.label = null),
                            (this.tooltipLabel = null),
                            (this.ratio = null),
                            (this.end = null),
                            (this.endLabel = null),
                            (this.endRatio = null),
                            (this.start = null),
                            (this.startLabel = null),
                            (this.startRatio = null),
                            (this.ratioDistance = null),
                        m.default.isBulletChart(this.chartType) && (this.type = e.type),
                            (this.legendName = e.legendName),
                            this._initValues(e.datum, e.index);
                    }
                    return (
                        (t.prototype._initValues = function (t, e) {
                            var i = this,
                                n = this._createValues(t),
                                r = n.length > 1,
                                a = o(n, 1)[0];
                            (this.value = this.end = a),
                                (this.index = e),
                            this.isDivergingChart && (a = Math.abs(a)),
                                (0, u.default)(a)
                                    ? this._setLabel("")
                                    : ["label", "tooltipLabel"].forEach(function (t) {
                                        i[t] = p.default.formatValue({
                                            value: a,
                                            formatFunctions: i.formatFunctions,
                                            chartType: i.chartType,
                                            areaType: "tooltipLabel" === t ? "makingTooltipLabel" : "makingSeriesLabel",
                                            legendName: i.legendName,
                                        });
                                    }),
                                (this.endLabel = this.label),
                            r && (this.addStart(n[1], !0), this._updateFormattedValueforRange(), (this.isRange = !0));
                        }),
                            (t.prototype._setLabel = function (t) {
                                (this.label = t), (this.tooltipLabel = t);
                            }),
                            (t.prototype._createValues = function (t) {
                                return []
                                    .concat(t)
                                    .map(function (t) {
                                        return (0, u.default)(t) ? null : parseFloat(t);
                                    })
                                    .sort(function (t, e) {
                                        return t < 0 && e < 0 ? t - e : e - t;
                                    });
                            }),
                            (t.prototype.addStart = function (t) {
                                (0, u.default)(this.start) &&
                                ((this.start = t), (this.startLabel = p.default.formatValue({ value: t, formatFunctions: this.formatFunctions, chartType: this.chartType, areaType: "series", legendName: this.legendName })));
                            }),
                            (t.prototype._updateFormattedValueforRange = function () {
                                this._setLabel(this.startLabel + " ~ " + this.endLabel);
                            }),
                            (t.prototype.addRatio = function () {
                                var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 1,
                                    e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0,
                                    i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 1;
                                (this.ratio = this.endRatio = f.default.calculateRatio(this.value, t, e, i)),
                                (0, a.default)(this.start) && ((this.startRatio = f.default.calculateRatio(this.start, t, e, i)), (this.ratioDistance = Math.abs(this.endRatio - this.startRatio)));
                            }),
                            (t.prototype._getFormattedValueForTooltip = function (t) {
                                return p.default.formatValue({ value: this[t], formatFunctions: this.formatFunctions, chartType: this.chartType, areaType: "tooltip", valueType: t, legendName: this.legendName });
                            }),
                            (t.prototype.pickValueMapForTooltip = function () {
                                var t = { value: this._getFormattedValueForTooltip("value"), ratio: this.ratio };
                                return (
                                    (0, a.default)(this.start) && ((t.start = this._getFormattedValueForTooltip("start")), (t.end = this._getFormattedValueForTooltip("end")), (t.startRatio = this.startRatio), (t.endRatio = this.endRatio)), t
                                );
                            }),
                            t
                    );
                })();
            e.default = y;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                r = i(140),
                a = n(r),
                s = i(149),
                u = n(s),
                l = i(146),
                h = n(l),
                c = i(150),
                p = n(c),
                d = i(164),
                f = n(d),
                g = i(148),
                m = n(g),
                y = (function () {
                    function t(e) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.chartType = e.chartType),
                            (this.formatFunctions = e.formatFunctions),
                            (this.xAxisType = e.xAxisType),
                            (this.dateFormat = e.dateFormat),
                            (this.ratioMap = {}),
                            this._initData(e.datum, e.index);
                    }
                    return (
                        (t.prototype._initData = function (t, e) {
                            var i = void 0;
                            if ((0, a.default)(t)) {
                                if (((this.x = t[0] || 0), (this.y = t[1] || 0), f.default.isBubbleChart(this.chartType))) {
                                    var n = o(t, 4);
                                    this.r = n[2];
                                    var r = n[3];
                                    this.label = void 0 === r ? "" : r;
                                } else {
                                    var s = o(t, 3)[2];
                                    this.label = void 0 === s ? "" : s;
                                }
                            } else (this.x = t.x), (this.y = t.y), (this.r = t.r), (this.label = t.label || "");
                            if ((f.default.isDatetimeType(this.xAxisType) && ((i = (0, u.default)(this.x) ? this.x : new Date(this.x)), (this.x = i.getTime() || 0)), (this.index = e), !this.label)) {
                                f.default.isLineTypeChart(this.chartType) && f.default.isDatetimeType(this.xAxisType)
                                    ? (this.label = m.default.formatDate(this.x, this.dateFormat))
                                    : (this.label = m.default.formatValue({ value: this.x, formatFunctions: this.formatFunctions, chartType: this.chartType, areaType: "series" }));
                                var l = m.default.formatValue({ value: this.y, formatFunctions: this.formatFunctions, chartType: this.chartType, areaType: "series" });
                                this.label += ",&nbsp;" + l;
                            }
                        }),
                            (t.prototype.addStart = function (t) {
                                this.start = t;
                            }),
                            (t.prototype.addRatio = function (t, e, i) {
                                !(0, h.default)(this.ratioMap[t]) && e && (this.ratioMap[t] = (this[t] - i) / e);
                            }),
                            (t.prototype._getFormattedValueForTooltip = function (t) {
                                var e = this.ratioMap[t],
                                    i = this[t],
                                    n = m.default.formatValue({ value: i, formatFunctions: this.formatFunctions, chartType: this.chartType, areaType: "tooltip", valueType: t });
                                return (0, p.default)(e) ? n : i;
                            }),
                            (t.prototype.pickValueMapForTooltip = function () {
                                var t = { x: this._getFormattedValueForTooltip("x"), y: this._getFormattedValueForTooltip("y"), xRatio: this.ratioMap.x, yRatio: this.ratioMap.y };
                                return (0, h.default)(this.r) && ((t.r = this._getFormattedValueForTooltip("r")), (t.rRatio = this.ratioMap.r)), t;
                            }),
                            t
                    );
                })();
            e.default = y;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(268),
                r = n(o),
                a = i(263),
                s = n(a),
                u = i(140),
                l = n(u),
                h = Array.prototype.concat,
                c = (function (t) {
                    function e(i, n, o, r) {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        var a = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this));
                        return (a.chartType = n), (a.options = o || {}), (a.formatFunctions = r), (a.rawSeriesData = i || []), (a.baseGroups = null), (a.groups = null), (a.options.series = a.options.series || {}), (a.valuesMap = {}), a;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._createBaseGroups = function () {
                                var t = this.chartType,
                                    e = this.formatFunctions;
                                return this.rawSeriesData.map(function (i) {
                                    return ((0, l.default)(i) ? i : [].concat(i.data)).map(function (n, o) {
                                        return new r.default({ datum: n, chartType: t, formatFunctions: e, index: o, legendName: i.name });
                                    });
                                });
                            }),
                            (e.prototype._createValues = function () {
                                var t = [];
                                return (
                                    this.map(function (e) {
                                        return e.items.forEach(function (e) {
                                            t.push(e.min), t.push(e.max), t.push(e.uq), t.push(e.lq), t.push(e.median);
                                        });
                                    }),
                                        (t = h.apply([], t)).filter(function (t) {
                                            return !isNaN(t);
                                        })
                                );
                            }),
                            e
                    );
                })(s.default);
            e.default = c;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                r = i(146),
                a = n(r),
                s = i(137),
                u = n(s),
                l = i(148),
                h = n(l),
                c = i(173),
                p = n(c),
                d = (function () {
                    function t(e) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.chartType = e.chartType),
                            (this.formatFunctions = e.formatFunctions),
                            (this.value = null),
                            (this.label = null),
                            (this.ratio = null),
                            (this.min = null),
                            (this.minLabel = null),
                            (this.minRatio = null),
                            (this.max = null),
                            (this.maxLabel = null),
                            (this.maxRatio = null),
                            (this.median = null),
                            (this.medianLabel = null),
                            (this.medianRatio = null),
                            (this.lq = null),
                            (this.lqLabel = null),
                            (this.lqRatio = null),
                            (this.uq = null),
                            (this.uqLabel = null),
                            (this.uqRatio = null),
                            (this.ratioDistance = null),
                            (this.legendName = e.legendName),
                            this._initValues(e.datum, e.index);
                    }
                    return (
                        (t.prototype._initValues = function (t, e) {
                            var i = this,
                                n = this._createValues(t),
                                r = o(n, 5),
                                a = r[0],
                                s = r[1],
                                u = r[2],
                                l = r[3],
                                c = r[4],
                                p = n.length > 5,
                                d = void 0,
                                f = function (t) {
                                    return h.default.formatValue({ value: t, formatFunctions: i.formatFunctions, chartType: i.chartType, areaType: "makingSeriesLabel", legendName: i.legendName });
                                };
                            (this.value = this.max = c),
                                (this.uq = l),
                                (this.median = u),
                                (this.lq = s),
                                (this.min = a),
                                (this.index = e),
                            p &&
                            ((this.outliers = []),
                                (d = this.outliers),
                                n.slice(5).forEach(function (t) {
                                    d.push({ value: t, label: f(t) });
                                })),
                                (this.label = f(c)),
                                (this.uqLabel = f(l)),
                                (this.medianLabel = f(u)),
                                (this.lqLabel = f(s)),
                                (this.minLabel = f(a)),
                                (this.maxLabel = this.label);
                        }),
                            (t.prototype._createValues = function (t) {
                                return [].concat(t).map(function (t) {
                                    return (0, u.default)(t) ? null : parseFloat(t);
                                });
                            }),
                            (t.prototype.addStart = function (t) {
                                (0, u.default)(this.min) &&
                                ((this.min = t), (this.minLabel = h.default.formatValue({ value: t, formatFunctions: this.formatFunctions, chartType: this.chartType, areaType: "series", legendName: this.legendName })));
                            }),
                            (t.prototype._updateFormattedValueforRange = function () {
                                this.label = this.minLabel + "~" + this.maxLabel;
                            }),
                            (t.prototype.addRatio = function () {
                                var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 1,
                                    e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0,
                                    i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : 1,
                                    n = p.default.calculateRatio;
                                (this.ratio = this.maxRatio = n(this.max, t, e, i)),
                                    (this.uqRatio = n(this.uq, t, e, i)),
                                    (this.medianRatio = n(this.median, t, e, i)),
                                    (this.lqRatio = n(this.lq, t, e, i)),
                                    (this.minRatio = n(this.min, t, e, i)),
                                    (this.outliers || []).forEach(function (o) {
                                        o.ratio = n(o.value, t, e, i);
                                    }),
                                    (this.ratioDistance = Math.abs(this.uqRatio - this.lqRatio));
                            }),
                            (t.prototype._getFormattedValueForTooltip = function (t) {
                                return h.default.formatValue({ value: this[t], formatFunctions: this.formatFunctions, chartType: this.chartType, areaType: "tooltip", valueType: t, legendName: this.legendName });
                            }),
                            (t.prototype.pickValueMapForTooltip = function () {
                                var t = { value: this._getFormattedValueForTooltip("value"), ratio: this.ratio };
                                return (
                                    (0, a.default)(this.min) &&
                                    ((t.min = this._getFormattedValueForTooltip("min")),
                                        (t.max = this._getFormattedValueForTooltip("max")),
                                        (t.minRatio = this.minRatio),
                                        (t.maxRatio = this.maxRatio),
                                        (t.maxLabel = this.maxLabel),
                                        (t.minLabel = this.minLabel),
                                        (t.uqLabel = this.uqLabel),
                                        (t.lqLabel = this.lqLabel),
                                        (t.medianLabel = this.medianLabel),
                                        (t.outliers = this.outliers)),
                                        t
                                );
                            }),
                            t
                    );
                })();
            e.default = d;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(265),
                r = n(o),
                a = i(263),
                s = n(a),
                u = i(154),
                l = n(u),
                h = (function (t) {
                    function e() {
                        return (
                            (function t(e, i) {
                                if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                            })(this, e),
                                (function t(e, i) {
                                    if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                                    return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                                })(this, t.apply(this, arguments))
                        );
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._createBaseGroups = function () {
                                var t = this.chartType,
                                    e = this.formatFunctions,
                                    i = 0,
                                    n = 0,
                                    o = this.rawSeriesData.map(function (o) {
                                        var a = [],
                                            s = o.data,
                                            u = o.markers,
                                            h = o.ranges,
                                            c = u.length,
                                            p = h.length;
                                        return (
                                            h &&
                                            p &&
                                            (h.forEach(function (i) {
                                                a.push(new r.default({ datum: i, chartType: t, formatFunctions: e, type: l.default.BULLET_TYPE_RANGE }));
                                            }),
                                                (i = Math.max(i, p))),
                                            s && a.push(new r.default({ datum: s, chartType: t, formatFunctions: e, type: l.default.BULLET_TYPE_ACTUAL })),
                                            u &&
                                            c &&
                                            (u.forEach(function (i) {
                                                a.push(new r.default({ datum: i, chartType: t, formabutFunctions: e, type: l.default.BULLET_TYPE_MARKER }));
                                            }),
                                                (n = Math.max(n, c))),
                                                a
                                        );
                                    });
                                return (this.maxMarkerCount = n), (this.maxRangeCount = i), o;
                            }),
                            (e.prototype._createSeriesGroupsFromRawData = function () {
                                return s.default.prototype._createSeriesGroupsFromRawData.call(this);
                            }),
                            e
                    );
                })(s.default);
            e.default = h;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                r = i(189),
                a = n(r),
                s = i(146),
                u = n(s),
                l = i(137),
                h = n(l),
                c = i(145),
                p = n(c),
                d = i(263),
                f = n(d),
                g = i(271),
                m = n(g),
                y = i(154),
                v = n(y),
                $ = i(173),
                x = n($),
                T = (function (t) {
                    function e() {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        for (var i = arguments.length, n = Array(i), o = 0; o < i; o++) n[o] = arguments[o];
                        var r = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call.apply(t, [this].concat(n)));
                        return (r.foundSeriesItemsMap = {}), (r.seriesItemMap = {}), r;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._flattenHierarchicalData = function (t, e, i) {
                                var n = this,
                                    o = [],
                                    r = void 0;
                                return (
                                    e ? (r = e + "_") : ((r = v.default.TREEMAP_ID_PREFIX), (e = v.default.TREEMAP_ROOT_ID)),
                                        (i = i || []),
                                        t.forEach(function (t, a) {
                                            var s = r + a,
                                                u = t.children,
                                                l = i.concat(a);
                                            (t.indexes = l), (0, h.default)(t.value) || o.push(t), t.id || (t.id = s), t.parent || (t.parent = e), u && ((o = o.concat(n._flattenHierarchicalData(u, s, l))), delete t.children);
                                        }),
                                        o
                                );
                            }),
                            (e.prototype._partitionRawSeriesDataByParent = function (t, e) {
                                var i = [],
                                    n = [];
                                return (
                                    t.forEach(function (t) {
                                        t.parent === e ? i.push(t) : n.push(t);
                                    }),
                                        [i, n]
                                );
                            }),
                            (e.prototype._setTreeProperties = function (t, e, i, n) {
                                var r = this,
                                    s = this._partitionRawSeriesDataByParent(t, i),
                                    u = o(s, 1)[0],
                                    l = o(s, 2)[1],
                                    h = e + 1;
                                return (
                                    u.forEach(function (t, i) {
                                        (t.depth = e), (t.group = (0, p.default)(n) ? i : n);
                                        var o = r._setTreeProperties(l, h, t.id, t.group, t.fillOpacity),
                                            s = o.filter(function (t) {
                                                return t.depth === h;
                                            });
                                        s.length ? ((t.value = x.default.sum((0, a.default)(s, "value"))), (t.hasChild = !0)) : (t.hasChild = !1),
                                        o.length &&
                                        o.sort(function (t, e) {
                                            return e.value - t.value;
                                        }),
                                            (u = u.concat(o));
                                    }),
                                        u
                                );
                            }),
                            (e.prototype._setRatio = function (t, e) {
                                var i = this,
                                    n = this._partitionRawSeriesDataByParent(t, e),
                                    r = o(n, 2),
                                    s = r[0],
                                    u = r[1],
                                    l = x.default.sum((0, a.default)(s, "value"));
                                s.forEach(function (t) {
                                    var e = (0, h.default)(t.value) ? 0 : t.value;
                                    (t.ratio = e / l), t.hasChild && i._setRatio(u, t.id);
                                });
                            }),
                            (e.prototype._createBaseGroups = function () {
                                var t = this.chartType,
                                    e = this.seriesItemMap,
                                    i = this.formatFunctions,
                                    n = this._flattenHierarchicalData(this.rawSeriesData);
                                return (
                                    (n = this._setTreeProperties(n, 1, v.default.TREEMAP_ROOT_ID)),
                                        this._setRatio(n, v.default.TREEMAP_ROOT_ID),
                                        [
                                            n.map(function (n) {
                                                var o = new m.default(n, i, t);
                                                return (e[o.id] = o), o;
                                            }),
                                        ]
                                );
                            }),
                            (e.prototype._findSeriesItems = function (t, e) {
                                return this.foundSeriesItemsMap[t] || (this.foundSeriesItemsMap[t] = this.getFirstSeriesGroup(!0).filter(e)), this.foundSeriesItemsMap[t];
                            }),
                            (e.prototype._makeCacheKey = function () {
                                for (var t = arguments.length, e = Array(t), i = 0; i < t; i++) e[i] = arguments[i];
                                var n = e[0];
                                return e.length > 1 && (n += e.slice(1).join("_")), n;
                            }),
                            (e.prototype._isValidGroup = function (t, e) {
                                return !(0, u.default)(e) || t === e;
                            }),
                            (e.prototype.findSeriesItemsByDepth = function (t, e) {
                                var i = this,
                                    n = this._makeCacheKey(v.default.TREEMAP_DEPTH_KEY_PREFIX, t, e);
                                return this._findSeriesItems(n, function (n) {
                                    return n.depth === t && i._isValidGroup(n.group, e);
                                });
                            }),
                            (e.prototype.findSeriesItemsByParent = function (t) {
                                var e = this._makeCacheKey(v.default.TREEMAP_PARENT_KEY_PREFIX, t);
                                return this._findSeriesItems(e, function (e) {
                                    return e.parent === t;
                                });
                            }),
                            (e.prototype.findLeafSeriesItems = function (t) {
                                var e = this,
                                    i = this._makeCacheKey(v.default.TREEMAP_LEAF_KEY_PREFIX, t);
                                return this._findSeriesItems(i, function (i) {
                                    return !i.hasChild && e._isValidGroup(i.group, t);
                                });
                            }),
                            (e.prototype.findParentByDepth = function (t, e) {
                                var i = this.seriesItemMap[t] || null;
                                return i && i.depth !== e && (i = this.findParentByDepth(i.parent, e)), i;
                            }),
                            (e.prototype.initSeriesItemsMap = function () {
                                this.foundSeriesItemsMap = null;
                            }),
                            e
                    );
                })(f.default);
            e.default = T;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(173),
                r = n(o),
                a = i(148),
                s = n(a),
                u = i(146),
                l = n(u),
                h = (function () {
                    function t(e, i, n) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.chartType = n),
                            (this.formatFunctions = i),
                            (this.id = e.id),
                            (this.parent = e.parent),
                            (this.value = e.value),
                            (this.ratio = e.ratio),
                            (this.colorValue = e.colorValue),
                            (this.depth = e.depth),
                            (this.label = e.label || ""),
                            (this.group = e.group),
                            (this.hasChild = !!e.hasChild),
                            (this.indexes = e.indexes),
                            (this.fillOpacity = e.fillOpacity);
                    }
                    return (
                        (t.prototype.addRatio = function () {
                            var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : 1,
                                e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0;
                            this.colorRatio = r.default.calculateRatio(this.colorValue, t, e, 1) || -1;
                        }),
                            (t.prototype.pickValueMapForTooltip = function () {
                                var t = this.formatFunctions,
                                    e = this.chartType,
                                    i = this.colorValue,
                                    n = s.default.formatValue({ value: this.value, formatFunctions: t, chartType: e, areaType: "tooltipValue" }),
                                    o = { legend: this.label || "", value: n, label: n, ratio: this.ratio, tooltipColorIndex: this.indexes[0] };
                                return (0, l.default)(i) && ((o.colorValue = s.default.formatValue({ value: i, formatFunctions: t, chartType: e, areaType: "tooltipColorValue" })), (o.colorRatio = this.colorRatio)), o;
                            }),
                            (t.prototype.pickLabelTemplateData = function () {
                                var t = { value: this.value, ratio: this.ratio, label: this.label };
                                return (0, l.default)(this.colorValue) && ((t.colorValue = this.colorValue), (t.colorValueRatio = this.ratio)), t;
                            }),
                            t
                    );
                })();
            e.default = h;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(273),
                r = n(o),
                a = i(279),
                s = n(a),
                u = i(154),
                l = n(u),
                h = i(164),
                c = n(h);
            e.default = {
                _createBoundsModel: function (t, e) {
                    return new r.default({ chartType: e.chartType, seriesTypes: e.seriesTypes, options: e.options, theme: e.theme, dataProcessor: t, hasAxes: e.hasAxes, isVertical: e.isVertical });
                },
                _createScaleDataModel: function (t, e, i) {
                    return new s.default({ chartType: i.chartType, seriesTypes: i.seriesTypes, options: i.options, theme: i.theme, dataProcessor: t, boundsModel: e, hasRightYAxis: i.hasRightYAxis, addedDataCount: i.addedDataCount });
                },
                addYAxisScale: function (t, e, i, n) {
                    t.addScale(e, (i && i.options) || n || {}, { valueType: i.valueType || "value", areaType: i.areaType, chartType: i.chartType }, i.additionalOptions);
                },
                _registerYAxisDimension: function (t, e, i, n, o) {
                    var r = t.get(n),
                        a = null,
                        s = [];
                    if (r) {
                        var u = i[n];
                        u && ((a = u.limit), (s = u.labels)), e.registerYAxisDimension({ limit: a, axisName: n, options: r.options, theme: r.theme, yAxisLabels: s, isVertical: o });
                    }
                },
                _setLayoutBoundsAndScale: function (t, e, i, n, o) {
                    var r = o.options,
                        a = o.scaleOption,
                        s = void 0 === a ? {} : a,
                        u = o.addingDataMode,
                        h = o.isVertical;
                    e.has("xAxis") && i.registerXAxisHeight(),
                    e.has("legend") && (e.get("legend").colorSpectrum ? i.registerSpectrumLegendDimension() : i.registerLegendDimension()),
                    s.yAxis && this.addYAxisScale(n, "yAxis", s.yAxis, o.options.yAxis),
                    s.rightYAxis && this.addYAxisScale(n, "rightYAxis", s.rightYAxis),
                    s.legend && n.addScale("legend", {}, { chartType: o.chartType }, { tickCounts: [l.default.SPECTRUM_LEGEND_TICK_COUNT] });
                    var p = n.scaleDataMap;
                    p.legend && e.get("legend") && e.get("legend").colorSpectrum && i.registerSpectrumLegendDimension(p.legend.limit),
                        this._registerYAxisDimension(e, i, p, "yAxis", h),
                        this._registerYAxisDimension(e, i, p, "rightYAxis", h),
                    s.xAxis && n.addScale("xAxis", r.xAxis, { valueType: s.xAxis.valueType || "value" }, s.xAxis.additionalOptions),
                    o.hasAxes && n.setAxisDataMap(),
                        i.registerSeriesDimension(),
                    e.has("circleLegend") && r.circleLegend.visible && i.registerCircleLegendDimension(n.axisDataMap),
                    e.has("xAxis") && (c.default.isAutoTickInterval(r.xAxis.tickInterval) && n.updateXAxisDataForAutoTickInterval(o.prevXAxisData, u), n.updateXAxisDataForLabel(u)),
                        i.registerBoundsData(n.axisDataMap.xAxis);
                },
                build: function (t, e, i) {
                    var n = this._createBoundsModel(t, i),
                        o = this._createScaleDataModel(t, n, i);
                    this._setLayoutBoundsAndScale(t, e, n, o, i);
                    var r = { dimensionMap: n.dimensionMap, positionMap: n.positionMap, limitMap: o.makeLimitMap(i.seriesTypes || [i.chartType], i.isVertical) };
                    return (
                        o.axisDataMap && (r.axisDataMap = o.axisDataMap), c.default.isBubbleChart(i.chartType) && (r.maxRadius = n.calculateMaxRadius(o.axisDataMap)), o.scaleDataMap.legend && (r.legendScaleData = o.scaleDataMap.legend), r
                    );
                },
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(153),
                r = n(o),
                a = i(140),
                s = n(a),
                u = i(146),
                l = n(u),
                h = i(189),
                c = n(h),
                p = i(154),
                d = n(p),
                f = i(164),
                g = n(f),
                m = i(148),
                y = n(m),
                v = i(143),
                $ = n(v),
                x = i(274),
                T = n(x),
                b = i(275),
                _ = n(b),
                A = i(276),
                D = n(A),
                C = i(277),
                E = n(C),
                L = i(278),
                S = n(L),
                P = d.default.LEGEND_AREA_H_PADDING,
                M = r.default.msie && r.default.version <= 8,
                w = (function () {
                    function t(e) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.options = e.options || {}),
                            (this.options.legend = this.options.legend || {}),
                            (this.options.yAxis = this.options.yAxis || {}),
                            (this.theme = e.theme || {}),
                            (this.hasAxes = e.hasAxes),
                            (this.chartType = e.chartType),
                            (this.seriesTypes = e.seriesTypes || []),
                            (this.dataProcessor = e.dataProcessor),
                            this.initBoundsData();
                    }
                    return (
                        (t.prototype.initBoundsData = function () {
                            (this.dimensionMap = { legend: { width: 0 }, yAxis: { width: 0 }, rightYAxis: { width: 0 }, xAxis: { height: 0 }, circleLegend: { width: 0 }, chartExportMenu: { width: 0 } }),
                                (this.positionMap = {}),
                                (this.chartLeftPadding = d.default.CHART_PADDING),
                                (this.maxRadiusForBubbleChart = null),
                                this._registerChartDimension(),
                                this._registerTitleDimension(),
                                this._registerChartExportMenuDimension();
                        }),
                            (t.prototype._registerDimension = function (t, e) {
                                this.dimensionMap[t] = Object.assign(this.dimensionMap[t] || {}, e);
                            }),
                            (t.prototype.getBound = function (t) {
                                return { dimension: this.dimensionMap[t] || {}, position: this.positionMap[t] || {} };
                            }),
                            (t.prototype._setBound = function (t, e) {
                                (this.dimensionMap[t] = e.dimension), (this.positionMap[t] = e.position);
                            }),
                            (t.prototype.getDimension = function (t) {
                                return this.dimensionMap[t];
                            }),
                            (t.prototype.getDimensionMap = function (t) {
                                var e = this,
                                    i = {};
                                return (
                                    t && t.length
                                        ? t.forEach(function (t) {
                                            i[t] = e.dimensionMap[t];
                                        })
                                        : (i = this.dimensionMap),
                                        JSON.parse(JSON.stringify(i))
                                );
                            }),
                            (t.prototype.getPosition = function (t) {
                                return this.positionMap[t];
                            }),
                            (t.prototype._registerChartDimension = function () {
                                var t = this.options.chart || {},
                                    e = { width: t.width || d.default.CHART_DEFAULT_WIDTH, height: t.height || d.default.CHART_DEFAULT_HEIGHT };
                                this._registerDimension("chart", e);
                            }),
                            (t.prototype._registerTitleDimension = function () {
                                var t = this.options.chart || {},
                                    e = (0, l.default)(t.title),
                                    i = this.theme.title,
                                    n = (e ? $.default.getRenderedTextSize(t.title.text, i.fontSize, i.fontFamily).height : 0) || 0;
                                n && (n += d.default.TITLE_PADDING), this._registerDimension("title", { height: n });
                            }),
                            (t.prototype._registerChartExportMenuDimension = function () {
                                var t = void 0;
                                (t = !1 === this.options.chartExportMenu.visible ? { width: 0, height: 0 } : { height: d.default.CHART_EXPORT_MENU_SIZE + d.default.SERIES_AREA_V_PADDING, width: d.default.CHART_EXPORT_MENU_SIZE }),
                                    this._registerDimension("chartExportMenu", t);
                            }),
                            (t.prototype.registerXAxisHeight = function () {
                                this._registerDimension("xAxis", { height: _.default.calculateXAxisHeight(this.options.xAxis, this.theme.xAxis) });
                            }),
                            (t.prototype.registerLegendDimension = function () {
                                var t = (0, c.default)(this.dataProcessor.getOriginalLegendData(), "label"),
                                    e = this.options.legend,
                                    i = this.theme.legend.label,
                                    n = this.getDimension("chart").width,
                                    o = D.default.calculate(e, i, t, n);
                                this._registerDimension("legend", o);
                            }),
                            (t.prototype.registerSpectrumLegendDimension = function (t) {
                                var e = t ? t.max : this.dataProcessor.getFormattedMaxValue(this.chartType, "legend"),
                                    i = t ? t.min : "",
                                    n = this.theme.label,
                                    o = this.options.legend.align,
                                    r = void 0;
                                if (g.default.isHorizontalLegend(o)) {
                                    var a = g.default.isBoxTypeChart(this.chartType),
                                        s = g.default.isLegendAlignTop(o);
                                    r = S.default._makeHorizontalDimension(e, n, a, s);
                                } else r = S.default._makeVerticalDimension(e, i, n);
                                this._registerDimension("legend", r), (this.useSpectrumLegend = !0);
                            }),
                            (t.prototype.registerYAxisDimension = function (t) {
                                var e = t.limit,
                                    i = t.options,
                                    n = t.theme,
                                    o = t.yAxisLabels,
                                    r = t.isVertical,
                                    a = t.axisName,
                                    u = this.options.series && this.options.series.diverging,
                                    l = void 0,
                                    h = void 0;
                                if (e) l = [e.min, e.max];
                                else {
                                    if (!g.default.isHeatmapChart(this.chartType) && r) return;
                                    l = this.dataProcessor.getCategories(!0);
                                }
                                (h = (0, s.default)(i) ? ("yAxis" === a ? i[0] : i[1]) : i), this._registerDimension(a, { width: _.default.calculateYAxisWidth(l, h, n, o, u) });
                            }),
                            (t.prototype.calculateSeriesWidth = function () {
                                var t = this.dataProcessor.getFormattedMaxValue(this.chartType, "series", "value"),
                                    e = this.getDimensionMap(["chart", "yAxis", "legend", "rightYAxis"]),
                                    i = 0;
                                g.default.isColumnTypeChart(this.chartType) || (i = y.default.getRenderedLabelHeight(t, this.theme.title));
                                var n = E.default.calculateWidth(e, this.options.legend, i);
                                return g.default.isMapChart(this.chartType) && !M && (n -= d.default.MAP_CHART_ZOOM_AREA_WIDTH + P), n;
                            }),
                            (t.prototype.calculateSeriesHeight = function () {
                                var t = this.getDimensionMap(["chart", "title", "legend", "xAxis", "chartExportMenu"]),
                                    e = 0;
                                return this.options.yAxis && this.options.yAxis.title && (e = y.default.getRenderedLabelHeight(this.options.yAxis.title, this.theme.title)), E.default.calculateHeight(t, this.options.legend, e);
                            }),
                            (t.prototype.getBaseSizeForLimit = function (t) {
                                return t ? this.calculateSeriesHeight() : this.calculateSeriesWidth();
                            }),
                            (t.prototype._makeSeriesDimension = function () {
                                return { width: this.calculateSeriesWidth(), height: this.calculateSeriesHeight() };
                            }),
                            (t.prototype.registerSeriesDimension = function () {
                                var t = this._makeSeriesDimension();
                                this._registerDimension("series", t);
                            }),
                            (t.prototype._updateLegendAndSeriesWidth = function (t, e) {
                                var i = this.options.legend;
                                g.default.isVerticalLegend(i.align) && i.visible && this._registerDimension("legend", { width: t }), this._registerDimension("series", { width: this.getDimension("series").width - e });
                            }),
                            (t.prototype.registerCircleLegendDimension = function (t) {
                                var e = this.getDimension("series"),
                                    i = this.options.legend,
                                    n = this.dataProcessor.getFormattedMaxValue(this.chartType, "circleLegend", "r"),
                                    o = this.theme.chart.fontFamily,
                                    r = T.default.calculateCircleLegendWidth(e, t, n, o),
                                    a = void 0,
                                    s = (r = Math.min(r, Math.max((a = g.default.isVerticalLegend(i.align) && i.visible ? this.getDimension("legend").width : 0), d.default.MIN_LEGEND_WIDTH))) - a;
                                this._registerDimension("circleLegend", { width: r, height: r }), s > 0 && this._updateLegendAndSeriesWidth(r, s);
                            }),
                            (t.prototype._makePlotDimension = function () {
                                var t = this.getDimension("series");
                                return { width: t.width, height: t.height + d.default.OVERLAPPING_WIDTH };
                            }),
                            (t.prototype._registerCenterComponentsDimension = function () {
                                var t = this.getDimension("series");
                                this._registerDimension("tooltip", t), this._registerDimension("mouseEventDetector", t);
                            }),
                            (t.prototype._registerAxisComponentsDimension = function () {
                                var t = this._makePlotDimension();
                                this._registerDimension("plot", t), this._registerDimension("xAxis", { width: t.width }), this._registerDimension("yAxis", { height: t.height }), this._registerDimension("rightYAxis", { height: t.height });
                            }),
                            (t.prototype._updateDimensionsWidth = function (t) {
                                var e = Math.max(t.overflowLeft, 0),
                                    i = e + (t.overflowRight ? Math.max(t.overflowRight, 0) : 0);
                                (this.chartLeftPadding += e), (this.dimensionMap.plot.width -= i), (this.dimensionMap.series.width -= i), (this.dimensionMap.mouseEventDetector.width -= i), (this.dimensionMap.xAxis.width -= i);
                            }),
                            (t.prototype._updateDimensionsHeight = function (t) {
                                (this.dimensionMap.plot.height -= t),
                                    (this.dimensionMap.series.height -= t),
                                    (this.dimensionMap.mouseEventDetector.height -= t),
                                    (this.dimensionMap.tooltip.height -= t),
                                    (this.dimensionMap.yAxis.height -= t),
                                    (this.dimensionMap.rightYAxis.height -= t),
                                    (this.dimensionMap.xAxis.height += t);
                            }),
                            (t.prototype._updateDimensionsForXAxisLabel = function (t) {
                                (t.overflowRight > 0 || t.overflowLeft > 0) && this._updateDimensionsWidth(t), t.overflowHeight && this._updateDimensionsHeight(t.overflowHeight);
                            }),
                            (t.prototype._registerAxisComponentsPosition = function (t) {
                                var e = this.getPosition("series"),
                                    i = this.getDimension("series"),
                                    n = t + this.getDimension("yAxis").width + i.width;
                                (this.positionMap.plot = { top: e.top, left: e.left }),
                                    (this.positionMap.yAxis = { top: e.top, left: this.chartLeftPadding + t }),
                                    (this.positionMap.xAxis = { top: e.top + i.height, left: e.left }),
                                    (this.positionMap.rightYAxis = { top: e.top, left: this.chartLeftPadding + n - d.default.OVERLAPPING_WIDTH });
                            }),
                            (t.prototype._makeLegendPosition = function () {
                                var t = this.dimensionMap,
                                    e = t.series,
                                    i = this.getPosition("series").top,
                                    n = this.options.legend,
                                    o = 0,
                                    r = void 0,
                                    a = void 0;
                                return (
                                    g.default.isHorizontalLegend(n.align)
                                        ? ((a = (this.getDimension("chart").width - this.getDimension("legend").width) / 2),
                                            (o = g.default.isLegendAlignBottom(n.align) ? i + e.height + this.getDimension("xAxis").height + d.default.SERIES_AREA_V_PADDING : i - t.legend.height + d.default.LEGEND_AREA_V_PADDING))
                                        : (g.default.isLegendAlignLeft(n.align) ? (a = this.chartLeftPadding) : ((r = this.getDimension("yAxis").width + this.getDimension("rightYAxis").width), (a = this.chartLeftPadding + r + e.width)),
                                            (o = i + d.default.SERIES_AREA_V_PADDING)),
                                        { top: o, left: a }
                                );
                            }),
                            (t.prototype._makeSpectrumLegendPosition = function () {
                                var t = this.options.legend,
                                    e = this.options.legend.align,
                                    i = this.getPosition("series"),
                                    n = this.getDimension("series"),
                                    o = this.getDimension("legend"),
                                    r = void 0,
                                    a = void 0,
                                    s = void 0;
                                g.default.isHorizontalLegend(e)
                                    ? ((a = (this.getDimension("chart").width - o.width) / 2), (r = g.default.isLegendAlignTop(e) ? i.top - o.height : i.top + n.height + this.getDimension("xAxis").height))
                                    : ((a = g.default.isLegendAlignLeft(t.align) ? this.chartLeftPadding : (s = this.getDimension("chart").width - this.chartLeftPadding) - this.getDimension("legend").width),
                                        (r = g.default.isBoxTypeChart(this.chartType) ? i.top : i.top + 0.75 * d.default.MAP_CHART_ZOOM_AREA_HEIGHT));
                                var u = { top: r, left: a };
                                return s && (u.right = s), u;
                            }),
                            (t.prototype._makeChartExportMenuPosition = function () {
                                return { top: this.getPosition("series").top - d.default.SERIES_AREA_V_PADDING - d.default.CHART_EXPORT_MENU_SIZE, right: d.default.CHART_PADDING };
                            }),
                            (t.prototype._makeCircleLegendPosition = function () {
                                var t = this.getPosition("series"),
                                    e = this.getDimension("series"),
                                    i = this.getDimension("circleLegend"),
                                    n = this.options.legend,
                                    o = void 0,
                                    r = void 0;
                                return (
                                    (o = g.default.isLegendAlignLeft(n.align) ? 0 : t.left + e.width),
                                    g.default.isVerticalLegend(n.align) && n.visible && (o += ((r = this.getDimension("legend").width + d.default.CHART_PADDING) - i.width) / 2),
                                        { top: t.top + e.height - i.height, left: o }
                                );
                            }),
                            (t.prototype._isNeedExpansionSeries = function () {
                                var t = this.chartType;
                                return !(g.default.isPieChart(t) || g.default.isMapChart(t) || g.default.isTreemapChart(t) || g.default.isRadialChart(t) || g.default.isPieDonutComboChart(t, this.seriesTypes));
                            }),
                            (t.prototype._registerEssentialComponentsPositions = function () {
                                var t = this.getPosition("series"),
                                    e = void 0;
                                (this.positionMap.mouseEventDetector = Object.assign({}, t)),
                                    (this.positionMap.legend = this.useSpectrumLegend ? this._makeSpectrumLegendPosition() : this._makeLegendPosition()),
                                    (this.positionMap.chartExportMenu = this._makeChartExportMenuPosition()),
                                this.getDimension("circleLegend").width && (this.positionMap.circleLegend = this._makeCircleLegendPosition()),
                                    (e = this._isNeedExpansionSeries() ? { top: t.top - d.default.SERIES_EXPAND_SIZE, left: t.left - d.default.SERIES_EXPAND_SIZE } : t),
                                    (this.positionMap.tooltip = e);
                            }),
                            (t.prototype._registerPositions = function () {
                                var t = this.options.legend.align,
                                    e = this.options.legend.visible,
                                    i = this.getDimension("legend"),
                                    n = g.default.isLegendAlignTop(t) && e,
                                    o = g.default.isLegendAlignLeft(t) && e,
                                    r = n ? i.height : 0,
                                    a = o ? i.width : 0,
                                    s = Math.max(this.getDimension("title").height, this.getDimension("chartExportMenu").height),
                                    u = this.options.yAxis.title && !this.useSpectrumLegend ? y.default.getRenderedLabelHeight(this.options.yAxis.title, this.theme.yAxis.title) + d.default.Y_AXIS_TITLE_PADDING : 0,
                                    l = s + Math.max(0, Math.max(r, u) - d.default.TITLE_PADDING);
                                s || (l = Math.max(r, u));
                                var h = { top: l + d.default.CHART_PADDING, left: this.chartLeftPadding + a + this.getDimension("yAxis").width };
                                (this.positionMap.series = h), this.hasAxes && this._registerAxisComponentsPosition(a), this._registerEssentialComponentsPositions();
                            }),
                            (t.prototype._registerExtendedSeriesBound = function () {
                                var t = this.getBound("series");
                                this._isNeedExpansionSeries() && (t = y.default.expandBound(t)), this._setBound("extendedSeries", t);
                            }),
                            (t.prototype._updateBoundsForYAxisCenterOption = function () {
                                var t = this.getDimension("yAxis").width,
                                    e = Math.floor(this.getDimension("series").width / 2) + d.default.OVERLAPPING_WIDTH,
                                    i = t - d.default.OVERLAPPING_WIDTH,
                                    n = y.default.isOldBrowser() ? 1 : 0;
                                (this.dimensionMap.extendedSeries.width += t),
                                    (this.dimensionMap.xAxis.width += d.default.OVERLAPPING_WIDTH),
                                    (this.dimensionMap.plot.width += t + d.default.OVERLAPPING_WIDTH),
                                    (this.dimensionMap.mouseEventDetector.width += t),
                                    (this.dimensionMap.tooltip.width += t),
                                    (this.positionMap.series.left -= t - n),
                                    (this.positionMap.extendedSeries.left -= i - n),
                                    (this.positionMap.plot.left -= i),
                                    (this.positionMap.yAxis.left += e),
                                    (this.positionMap.xAxis.left -= i),
                                    (this.positionMap.mouseEventDetector.left -= i),
                                    (this.positionMap.tooltip.left -= i);
                            }),
                            (t.prototype.registerBoundsData = function (t) {
                                this._registerCenterComponentsDimension(),
                                this.useSpectrumLegend && this._updateDimensionsForSpectrumLegend(),
                                this.hasAxes && (this._registerAxisComponentsDimension(), this._updateDimensionsForXAxisLabel(t)),
                                    this._registerPositions(),
                                    this._registerExtendedSeriesBound(),
                                this.options.yAxis.isCenter && this._updateBoundsForYAxisCenterOption();
                            }),
                            (t.prototype._updateDimensionsForSpectrumLegend = function () {
                                var t = this.options.legend.align,
                                    e = this.getDimension("legend"),
                                    i = this.getDimension("series");
                                g.default.isHorizontalLegend(t) && e.width > i.width
                                    ? (e.width = i.width)
                                    : g.default.isVerticalLegend(t) &&
                                    (g.default.isBoxTypeChart(this.chartType) ? (e.height = i.height) : e.height > i.height - d.default.MAP_CHART_ZOOM_AREA_HEIGHT && (e.height = i.height - d.default.MAP_CHART_ZOOM_AREA_HEIGHT));
                            }),
                            (t.prototype.calculateMaxRadius = function (t) {
                                var e = this.getDimensionMap(["series", "circleLegend"]),
                                    i = !!this.options.circleLegend && this.options.circleLegend.visible;
                                return T.default.calculateMaxRadius(e, t, i);
                            }),
                            t
                    );
                })();
            e.default = w;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(154),
                r = n(o),
                a = i(148),
                s = n(a);
            e.default = {
                _calculatePixelStep: function (t, e) {
                    var i = t.tickCount,
                        n = void 0;
                    return parseInt((n = t.isLabelAxis ? e / i / 2 : e / (i - 1)), 10);
                },
                _calculateRadiusByAxisData: function (t, e) {
                    return Math.min(this._calculatePixelStep(e.yAxis, t.height), this._calculatePixelStep(e.xAxis, t.width));
                },
                _getCircleLegendLabelMaxWidth: function (t, e) {
                    return s.default.getRenderedLabelWidth(t, { fontSize: r.default.CIRCLE_LEGEND_LABEL_FONT_SIZE, fontFamily: e });
                },
                calculateCircleLegendWidth: function (t, e, i, n) {
                    return Math.max(2 * this._calculateRadiusByAxisData(t, e), this._getCircleLegendLabelMaxWidth(i, n)) + r.default.CIRCLE_LEGEND_PADDING;
                },
                calculateMaxRadius: function (t, e, i) {
                    var n = this._calculateRadiusByAxisData(t.series, e),
                        o = t.circleLegend.width;
                    return i ? Math.min((o - r.default.CIRCLE_LEGEND_PADDING) / 2, n) : n;
                },
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(154),
                r = n(o),
                a = i(164),
                s = n(a),
                u = i(148),
                l = n(u);
            e.default = {
                calculateXAxisHeight: function (t, e) {
                    var i = t.title,
                        n = i ? l.default.getRenderedLabelHeight(i.text, e.title) : 0,
                        o = n ? n + r.default.X_AXIS_TITLE_PADDING : 0,
                        a = t.labelMargin || 0,
                        s = l.default.getRenderedLabelHeight(r.default.MAX_HEIGHT_WORD, e.label),
                        u = o + r.default.X_AXIS_LABEL_PADDING;
                    return a > 0 && (u += a), !1 !== t.showLabel && (u += s), u;
                },
                calculateYAxisWidth: function (t, e, i, n, o) {
                    var a = e.labelMargin,
                        u = e.prefix,
                        h = e.suffix,
                        c = e.isCenter,
                        p = e.type,
                        d = e.dateFormat,
                        f = e.showLabel,
                        g = e.title,
                        m = e.maxWidth,
                        y = 0,
                        v = 0,
                        $ = 0;
                    return (
                        (t = e.categories || t),
                            (t = l.default.addPrefixSuffix(t, u, h)),
                            (n = l.default.addPrefixSuffix(n, u, h)),
                        c && ($ += r.default.Y_AXIS_LABEL_PADDING),
                        s.default.isDatetimeType(p) && ((t = l.default.formatDates(t, d)), (n = l.default.formatDates(n, d))),
                        a && a > 0 && ($ += a),
                            (t = n.length ? n : t),
                        !1 !== f && (v = l.default.getRenderedLabelsMaxWidth(t, i.label, m)),
                        g && (y = l.default.getRenderedLabelWidth(g.text, i.title)),
                            ($ += (o ? Math.max(v, y) : v) + r.default.Y_AXIS_LABEL_PADDING)
                    );
                },
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(154),
                r = n(o),
                a = i(164),
                s = n(a),
                u = i(173),
                l = n(u),
                h = i(148),
                c = n(h),
                p = i(158),
                d = n(p),
                f = r.default.LEGEND_CHECKBOX_SIZE,
                g = r.default.LEGEND_ICON_WIDTH,
                m = r.default.LEGEND_LABEL_LEFT_PADDING,
                y = r.default.LEGEND_V_LABEL_RIGHT_PADDING,
                v = r.default.LEGEND_H_LABEL_RIGHT_PADDING,
                $ = r.default.LEGEND_AREA_H_PADDING;
            e.default = {
                _calculateLegendsWidth: function (t, e, i, n) {
                    var o = l.default.sum([$, i, g, m]);
                    return t.map(function (t) {
                        var i = c.default.getRenderedLabelWidth(t, e);
                        return n && i > n && (i = n), (i += o) + v;
                    });
                },
                _divideLegendLabels: function (t, e) {
                    var i = [],
                        n = [];
                    return (
                        t.forEach(function (t) {
                            n.length < e ? n.push(t) : (i.push(n), (n = [t]));
                        }),
                        n.length && i.push(n),
                            i
                    );
                },
                _getLegendWidthInfo: function (t, e, i, n) {
                    var o = this,
                        r = [],
                        a = t.map(function (t) {
                            var a = o._calculateLegendsWidth(t, e, i, n),
                                s = l.default.sum(a);
                            return (r = r.concat(a)), (s = s - v + $);
                        });
                    return { labelWidthArr: r, legendWidths: a };
                },
                _makeDividedLabelsAndMaxLineWidth: function (t, e, i, n, o) {
                    var r = Number.MAX_VALUE,
                        a = 1,
                        s = 0,
                        u = void 0,
                        l = void 0,
                        h = void 0;
                    do {
                        (r = Math.round(t.length / a)), (u = this._divideLegendLabels(t, r));
                        var c = this._getLegendWidthInfo(u, i, n, o);
                        if (((l = c.legendWidths), (h = c.labelWidthArr), (s = d.default.max(l)), 1 === r)) break;
                        a += 1;
                    } while (s >= e);
                    return (s = Math.min(s, e)), { labels: this._optimizedHorizontalLegendLabels(t, h, s), maxLineWidth: s };
                },
                _optimizedHorizontalLegendLabels: function (t, e, i) {
                    var n = [],
                        o = t.length - 1,
                        r = 0,
                        a = [];
                    return (
                        t.forEach(function (t, s) {
                            var u = e[s];
                            r + u + ($ - v) <= i ? a.push(t) : (n.push(a), (a = [t]), (r = 0)), (r += u), o === s && n.push(a);
                        }),
                            n
                    );
                },
                _calculateHorizontalLegendHeight: function (t, e) {
                    var i = Math.max.apply(
                        null,
                        t.map(function (t) {
                            return c.default.getRenderedLabelsMaxHeight(t, e);
                        })
                    );
                    return l.default.sum([Math.max(r.default.LEGEND_CHECKBOX_SIZE, i), r.default.LINE_MARGIN_TOP]) * t.length - r.default.LINE_MARGIN_TOP + r.default.SERIES_AREA_V_PADDING;
                },
                _makeHorizontalDimension: function (t, e, i, n, o) {
                    var a = this._makeDividedLabelsAndMaxLineWidth(e, i, t, n, o),
                        s = this._calculateHorizontalLegendHeight(a.labels, t) + r.default.SERIES_AREA_V_PADDING;
                    return { width: Math.max(a.maxLineWidth, r.default.MIN_LEGEND_WIDTH), height: s };
                },
                _makeVerticalDimension: function (t, e, i, n) {
                    var o = c.default.getRenderedLabelsMaxWidth(e, t),
                        r = 0;
                    return n && o > n && (o = n), { width: (r = l.default.sum([2 * $, i, g, m, o, y])), height: 0 };
                },
                calculate: function (t, e, i, n) {
                    var o = !1 === t.showCheckbox ? 0 : f + m,
                        r = t.maxWidth,
                        a = {};
                    return t.visible ? (a = s.default.isHorizontalLegend(t.align) ? this._makeHorizontalDimension(e, i, n, o, r) : this._makeVerticalDimension(e, i, o, r)) : (a.width = 0), a;
                },
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(154),
                r = n(o),
                a = i(164),
                s = n(a);
            e.default = {
                calculateWidth: function (t, e, i) {
                    var n = t.chart.width,
                        o = t.yAxis,
                        a = t.rightYAxis,
                        u = t.legend,
                        l = o.width + a.width,
                        h = u,
                        c = 0,
                        p = 0;
                    return s.default.isVerticalLegend(e.align) && e.visible && (c = h ? h.width : 0), c || a.width || !i || (p = i / 2), n - 2 * r.default.CHART_PADDING - l - c - p;
                },
                calculateHeight: function (t, e, i) {
                    var n = t.chart.height,
                        o = Math.max(t.title.height, t.chartExportMenu.height),
                        a = e.visible ? t.legend.height : 0,
                        u = s.default.isLegendAlignTop(e.align) ? a : 0,
                        l = Math.max(0, Math.max(i, u) - r.default.TITLE_PADDING),
                        h = s.default.isLegendAlignBottom(e.align) ? a : 0,
                        c = t.xAxis.height + h;
                    return n - 2 * r.default.CHART_PADDING - (o + l) - c;
                },
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(154),
                r = n(o),
                a = i(148),
                s = n(a),
                u = i(173),
                l = n(u),
                h = r.default.MAP_LEGEND_TOOLTIP_HORIZONTAL_PADDING,
                c = r.default.MAP_LEGEND_WEDGE_SIZE,
                p = r.default.MAP_LEGEND_AREA_PADDING_WIDE,
                d = r.default.MAP_LEGEND_PADDING_BTW_GRAPH_AND_WEDGE,
                f = r.default.MAP_LEGEND_GRAPH_SIZE,
                g = r.default.MAP_LEGEND_LABEL_PADDING,
                m = r.default.VERTICAL_MAP_LEGEND_HEIGHT,
                y = r.default.MAP_LEGEND_TOOLTIP_VERTICAL_PADDING,
                v = r.default.MAP_LEGEND_AREA_PADDING_NARROW,
                $ = r.default.HORIZONTAL_MAP_LEGEND_WIDTH;
            e.default = {
                _makeVerticalDimension: function (t, e, i) {
                    var n = s.default.getRenderedLabelWidth(t, i),
                        o = s.default.getRenderedLabelWidth(e, i),
                        r = s.default.getRenderedLabelWidth(t, i),
                        a = l.default.sum([2 * h, r, c]);
                    return { width: l.default.sum([p, a, d, f, g, Math.max(n, o)]), height: m };
                },
                _makeHorizontalDimension: function (t, e, i, n) {
                    var o = s.default.getRenderedLabelHeight(t, e),
                        r = l.default.sum([2 * y, o, c]);
                    return { width: $, height: l.default.sum([i ? v : p, r, d, f, g, o, g, n ? p : 0]) };
                },
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(140),
                r = n(o),
                a = i(137),
                s = n(a),
                u = i(280),
                l = n(u),
                h = i(282),
                c = n(h),
                p = i(283),
                d = n(p),
                f = i(164),
                g = n(f),
                m = i(148),
                y = n(m),
                v = (function () {
                    function t(e) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.chartType = e.chartType),
                            (this.seriesTypes = e.seriesTypes),
                            (this.dataProcessor = e.dataProcessor),
                            (this.boundsModel = e.boundsModel),
                            (this.options = e.options),
                            (this.theme = e.theme),
                            (this.hasRightYAxis = !!e.hasRightYAxis),
                            (this.prevValidLabelCount = null),
                            this.initScaleData(e.addedDataCount),
                            this.initForAutoTickInterval();
                    }
                    return (
                        (t.prototype.initScaleData = function (t) {
                            (this.scaleDataMap = {}), (this.axisDataMap = {}), (this.addedDataCount = t);
                        }),
                            (t.prototype.initForAutoTickInterval = function () {
                                this.firstTickCount = null;
                            }),
                            (t.prototype._pickLimitOption = function (t) {
                                return { min: (t = t || {}).min, max: t.max };
                            }),
                            (t.prototype._createBaseScaleData = function (t, e, i, n) {
                                var o = t.chartType,
                                    r = "xAxis" !== t.areaType,
                                    a = this.dataProcessor.createBaseValuesForLimit(o, n.isSingleYAxis, e.stack, t.valueType, t.areaType),
                                    s = this.boundsModel.getBaseSizeForLimit(r),
                                    u = Object.assign(e, { isVertical: r, limitOption: this._pickLimitOption(i), tickCounts: n.tickCounts, showLabel: this.options.series.showLabel });
                                return (
                                    g.default.isBubbleChart(o) && (u.overflowItem = this.dataProcessor.findOverflowItem(o, t.valueType)),
                                    (g.default.isMapChart(o) || g.default.isHeatmapChart(o) || g.default.isTreemapChart(o)) && (u.useSpectrumLegend = !0),
                                        l.default.makeScaleData(a, s, o, u)
                                );
                            }),
                            (t.prototype._createScaleLabels = function (t, e, i, n) {
                                var o = this.dataProcessor.getFormatFunctions(),
                                    r = Object.assign(i, { dateFormat: n });
                                return c.default.createFormattedLabels(t, e, r, o);
                            }),
                            (t.prototype._createScaleData = function (t, e, i) {
                                var n = this.options.series,
                                    o = e.chartType || this.chartType;
                                (e.chartType = o), (n = n[o] || n);
                                var r = { stack: i.stack || n.stack, diverging: n.diverging, type: t.type },
                                    a = this._createBaseScaleData(e, r, t, i);
                                return Object.assign(a, { labels: this._createScaleLabels(a, e, r, t.dateFormat), axisOptions: t });
                            }),
                            (t.prototype._createValueAxisData = function (t, e, i, n, o) {
                                var r = this.dataProcessor.hasCategories(),
                                    a = t.labels,
                                    s = t.limit,
                                    u = t.step,
                                    l = a.length,
                                    h = d.default.makeValueAxisData({ labels: a, tickCount: a.length, limit: s, step: u, labelTheme: e, aligned: i, options: t.axisOptions, isVertical: !!n, isPositionRight: !!o });
                                if (!n && !r && i) {
                                    var c = this.dataProcessor.getValues(this.chartType, "x"),
                                        p = d.default.makeAdditionalDataForCoordinateLineType(a, c, s, u, l);
                                    Object.assign(h, p);
                                }
                                return h;
                            }),
                            (t.prototype._createLabelAxisData = function (t, e, i, n, o) {
                                var r = t.categories || this.dataProcessor.getCategories(n);
                                return d.default.makeLabelAxisData({ labels: r, options: t, labelTheme: e, aligned: i, isVertical: !!n, isPositionRight: !!o, addedDataCount: this.options.series.shifting ? this.addedDataCount : 0 });
                            }),
                            (t.prototype._createAxisData = function (t, e, i, n, o) {
                                var r = g.default.isLineTypeChart(this.chartType, this.seriesTypes) && !e.pointOnColumn;
                                return t ? this._createValueAxisData(t, i, r, n, o) : this._createLabelAxisData(e, i, r, n, o);
                            }),
                            (t.prototype._createAxesData = function () {
                                var t = this.scaleDataMap,
                                    e = this.options,
                                    i = this.theme,
                                    n = (0, r.default)(e.yAxis) ? e.yAxis : [e.yAxis],
                                    o = {};
                                return (
                                    (o.xAxis = this._createAxisData(t.xAxis, e.xAxis, i.xAxis.label)),
                                        (o.yAxis = this._createAxisData(t.yAxis, n[0], i.yAxis.label, !0)),
                                    this.hasRightYAxis && ((o.rightYAxis = this._createAxisData(t.rightYAxis, n[1], i.yAxis.label, !0, !0)), o.rightYAxis.aligned || (o.rightYAxis.aligned = o.yAxis.aligned)),
                                        o
                                );
                            }),
                            (t.prototype.addScale = function (t, e) {
                                var i = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {},
                                    n = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : {};
                                (i.areaType = i.areaType || t), (i.chartType = n.chartType || i.chartType), (this.scaleDataMap[t] = this._createScaleData(e, i, n));
                            }),
                            (t.prototype.setAxisDataMap = function () {
                                this.axisDataMap = this._createAxesData();
                            }),
                            (t.prototype.updateXAxisDataForAutoTickInterval = function (t, e) {
                                var i = this.options.series.shifting,
                                    n = this.options.series.zoomable,
                                    o = this.axisDataMap.xAxis,
                                    r = this.boundsModel.getDimension("series").width,
                                    a = this.addedDataCount;
                                i || !t || n ? d.default.updateLabelAxisDataForAutoTickInterval(o, r, a, e) : d.default.updateLabelAxisDataForStackingDynamicData(o, t, this.firstTickCount),
                                this.firstTickCount || (this.firstTickCount = o.tickCount);
                            }),
                            (t.prototype.updateXAxisDataForLabel = function (t) {
                                var e = this.axisDataMap.xAxis,
                                    i = this.boundsModel.getDimensionMap(["series", "yAxis", "chart"]),
                                    n = e.isLabelAxis,
                                    o = this.theme.xAxis.label,
                                    r = void 0,
                                    a = void 0,
                                    u = e.labels;
                                t && (u = u.slice(0, u.length - 1));
                                var l = (u = y.default.addPrefixSuffix(u, this.options.xAxis.prefix, this.options.xAxis.suffix)).filter(function (t) {
                                    return t;
                                });
                                (r = (0, s.default)(this.prevValidLabelCount) ? l.length : this.prevValidLabelCount),
                                this.options.yAxis.isCenter && ((r += 1), (i.yAxis.width = 0)),
                                    (a = !1 === e.options.rotateLabel ? d.default.makeAdditionalDataForMultilineLabels(u, r, o, n, i) : d.default.makeAdditionalDataForRotatedLabels(l, r, o, n, i)),
                                    (this.prevValidLabelCount = r),
                                    Object.assign(e, a);
                            }),
                            (t.prototype._findLimit = function (t, e, i) {
                                return 0 === e ? (i ? t.yAxis : t.xAxis) : t.rightYAxis ? t.rightYAxis : t.yAxis;
                            }),
                            (t.prototype.makeLimitMap = function (t, e) {
                                var i = this,
                                    n = this.scaleDataMap,
                                    o = {};
                                return (
                                    n.xAxis && (o.xAxis = n.xAxis.limit),
                                    n.yAxis && (o.yAxis = n.yAxis.limit),
                                    n.rightYAxis && (o.rightYAxis = n.rightYAxis.limit),
                                    n.legend && (o.legend = n.legend.limit),
                                        t.forEach(function (t, n) {
                                            o[t] = i._findLimit(o, n, e);
                                        }),
                                        o
                                );
                            }),
                            t
                    );
                })();
            e.default = v;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                r = i(154),
                a = n(r),
                s = i(164),
                u = n(s),
                l = i(173),
                h = n(l),
                c = i(158),
                p = n(c),
                d = i(281),
                f = n(d),
                g = i(146),
                m = n(g);
            e.default = {
                _makeLimitForDivergingOption: function (t) {
                    var e = Math.max(Math.abs(t.min), Math.abs(t.max));
                    return { min: -e, max: e };
                },
                _adjustLimitForOverflow: function (t, e, i) {
                    var n = t.min,
                        o = t.max;
                    return i.min && (n = h.default.subtract(n, e)), i.max && (o = h.default.add(o, e)), { min: n, max: o };
                },
                millisecondMap: { year: 31536e6, month: 26784e5, week: 6048e5, date: 864e5, hour: 36e5, minute: 6e4, second: 1e3 },
                millisecondTypes: ["year", "month", "week", "date", "hour", "minute", "second"],
                _findDateType: function (t, e) {
                    var i = t.max - t.min,
                        n = this.millisecondTypes,
                        o = this.millisecondMap,
                        r = n.length - 1,
                        s = void 0;
                    return (
                        i
                            ? n.every(function (t, a) {
                                var u = Math.floor(i / o[t]),
                                    l = void 0;
                                return u && (s = n[(l = a < r && u < 2 && u < e ? a + 1 : a)]), !(0, m.default)(l);
                            })
                            : (s = a.default.DATE_TYPE_SECOND),
                            s
                    );
                },
                _makeDatetimeInfo: function (t, e) {
                    var i = this._findDateType(t, e),
                        n = this.millisecondMap[i],
                        o = h.default.divide(t.min, n),
                        r = h.default.divide(t.max, n);
                    return { divisionNumber: n, minDate: o, dataLimit: { min: 0, max: r - o } };
                },
                _restoreScaleToDatetimeType: function (t, e, i) {
                    var n = t.limit;
                    return (t.step = h.default.multiply(t.step, i)), (n.min = h.default.multiply(h.default.add(n.min, e), i)), (n.max = h.default.multiply(h.default.add(n.max, e), i)), t;
                },
                _getLimitSafely: function (t) {
                    var e = { min: p.default.min(t), max: p.default.max(t) };
                    if (1 === t.length) {
                        var i = o(t, 1)[0];
                        i > 0 ? (e.min = 0) : 0 === i ? (e.max = 10) : (e.max = 0);
                    } else 0 === e.min && 0 === e.max ? (e.max = 10) : e.min === e.max && ((e.min -= e.min / 10), (e.max += e.max / 10));
                    return e;
                },
                _calculateDatetimeScale: function (t, e, i) {
                    var n = this._makeDatetimeInfo(this._getLimitSafely(t), t.length),
                        o = n.dataLimit;
                    i && (o = this._makeLimitForDivergingOption(o));
                    var r = (0, f.default)({ min: o.min, max: o.max, offsetSize: e, minimumStepSize: 1 });
                    return this._restoreScaleToDatetimeType(r, n.minDate, n.divisionNumber);
                },
                _calculatePercentStackedScale: function (t, e) {
                    return 0 === h.default.sumMinusValues(t)
                        ? a.default.PERCENT_STACKED_AXIS_SCALE
                        : 0 === h.default.sumPlusValues(t)
                            ? a.default.MINUS_PERCENT_STACKED_AXIS_SCALE
                            : e
                                ? a.default.DIVERGING_PERCENT_STACKED_AXIS_SCALE
                                : a.default.DUAL_PERCENT_STACKED_AXIS_SCALE;
                },
                _calculateCoordinateScale: function (t) {
                    var e = t.options,
                        i = e.limitOption,
                        n = void 0 === i ? {} : i,
                        o = e.useSpectrumLegend,
                        r = t.baseSize,
                        a = t.overflowItem,
                        s = t.chartType,
                        l = this._getLimitSafely(t.baseValues),
                        h = (0, m.default)(n.min),
                        c = (0, m.default)(n.max),
                        p = l.min,
                        d = l.max,
                        g = void 0,
                        y = t.options.stepCount;
                    h && ((p = n.min), (y = null)), c && ((d = n.max), (y = null));
                    var v = (0, f.default)({ min: p, max: d, stepCount: y, offsetSize: r });
                    return (
                        o || (g = this._isOverflowed(a, v, l, h, c)),
                        g && !u.default.isMapTypeChart(s) && (v.limit = this._adjustLimitForOverflow(v.limit, v.step, g)),
                        t.isDiverging && (v.limit = this._makeLimitForDivergingOption(v.limit)),
                            v
                    );
                },
                _isOverflowed: function (t, e, i, n, o) {
                    var r = !(!t || !t.minItem),
                        a = !(!t || !t.maxItem),
                        s = e.limit,
                        u = r || (!n && s.min === i.min && 0 !== s.min),
                        l = a || (!o && s.max === i.max && 0 !== s.max);
                    return u || l ? { min: u, max: l } : null;
                },
                makeScaleData: function (t, e, i, n) {
                    var o = void 0,
                        r = u.default.isDivergingChart(i, n.diverging),
                        a = n.overflowItem;
                    return (
                        u.default.isPercentStackChart(i, n.stack)
                            ? (o = this._calculatePercentStackedScale(t, r))
                            : u.default.isDatetimeType(n.type)
                                ? (o = this._calculateDatetimeScale(t, e, r))
                                : (u.default.isRadialChart(i) && (n.stepCount = Math.floor(e / 100)), (o = this._calculateCoordinateScale({ baseValues: t, baseSize: e, overflowItem: a, isDiverging: r, chartType: i, options: n }))),
                            o
                    );
                },
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return Math.pow(10, Math.floor(0 === t ? 1 : Math.log(Math.abs(t)) / Math.LN10));
            }
            e.__esModule = !0;
            var o,
                r = (o = i(150)) && o.__esModule ? o : { default: o },
                a = [1, 2, 5, 10];
            e.default = function t(e) {
                var i,
                    o,
                    s,
                    u,
                    l,
                    h,
                    c,
                    p,
                    d,
                    f,
                    g,
                    m,
                    y,
                    v,
                    $,
                    x,
                    T,
                    b,
                    _,
                    A,
                    D,
                    C,
                    E,
                    L,
                    S,
                    P,
                    M,
                    w = e.min,
                    k = e.max,
                    I = e.offsetSize,
                    R = e.stepCount,
                    B = e.minimumStepSize,
                    O = e.showLabel;
                return (
                    (d =
                        ((i = w),
                            (o = k),
                            (s = I),
                            (u = R),
                            (l = B),
                            (h = Math.abs(o - i)),
                        u || (u = Math.ceil(s / 88)),
                            (c = s / u),
                            (p = (h / s) * c),
                        (0, r.default)(l) && p < l && (u = h / (p = l)),
                            { limit: { min: i, max: o }, step: p, stepCount: u })),
                        (f = O),
                        (S =
                            ((m = n((g = d.step))),
                            (function t(e) {
                                for (var i = void 0, n = 0, o = a.length; n < o && !(e <= ((i = a[n]) + (a[n + 1] || i)) / 2); n += 1);
                                return i;
                            })(g / m) * m)),
                        (M =
                            ((C = Math.abs(
                                (P =
                                    ((y = d.limit.min),
                                        (v = d.limit.max),
                                        ($ = S),
                                        (x = f),
                                        (b = (T = Math.min(n(v), n($))) > 1 ? 1 : 1 / T),
                                        (_ = $ * b),
                                        (A = v),
                                        (D = !1),
                                        (v = (Math.ceil((v * b) / _) * _) / b),
                                        (D = _ / 2 > v - A),
                                    x && D && (v += _),
                                        { min: (y = y > $ ? (Math.floor((y * b) / _) * _) / b : y < 0 ? -(Math.ceil((Math.abs(y) * b) / _) * _) / b : 0), max: v })).max - P.min
                            )),
                                (E = S),
                                (L = 1 / Math.min(n(C), n(E))),
                                Math.ceil((C * L) / (E * L)))),
                        { limit: { min: P.min, max: P.max }, step: S, stepCount: M }
                );
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(164),
                r = n(o),
                a = i(173),
                s = n(a),
                u = i(148),
                l = n(u);
            e.default = {
                _getFormatFunctions: function (t, e, i) {
                    return (
                        r.default.isPercentStackChart(t, e) &&
                        (i = [
                            function (t) {
                                return t + "%";
                            },
                        ]),
                            i
                    );
                },
                _createScaleValues: function (t, e, i) {
                    var n = s.default.makeLabelsFromLimit(t.limit, t.step);
                    return r.default.isDivergingChart(e, i) ? n.map(Math.abs) : n;
                },
                createFormattedLabels: function (t, e, i, n) {
                    var o = e.chartType,
                        a = e.areaType,
                        s = e.valueType,
                        u = i.diverging,
                        h = i.type,
                        c = i.dateFormat,
                        p = i.stack,
                        d = this._createScaleValues(t, o, u),
                        f = void 0;
                    return r.default.isDatetimeType(h) ? (f = l.default.formatDates(d, c)) : ((n = this._getFormatFunctions(o, p, n)), (f = l.default.formatValues(d, n, { chartType: o, areaType: a, valueType: s }))), f;
                },
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                r = i(144),
                a = n(r),
                s = i(154),
                u = n(s),
                l = i(164),
                h = n(l),
                c = i(205),
                p = n(c),
                d = i(173),
                f = n(d),
                g = i(148),
                m = n(g),
                y = i(158),
                v = n(y);
            e.default = {
                _makeLabelsByIntervalOption: function (t, e, i) {
                    return (
                        (i = i || 0),
                            (t = t.map(function (t, n) {
                                return (n + i) % e != 0 && (t = u.default.EMPTY_AXIS_LABEL), t;
                            }))
                    );
                },
                makeLabelAxisData: function (t) {
                    var e = t.options,
                        i = void 0 === e ? {} : e,
                        n = t.labels.length,
                        o = t.labels;
                    return (
                        h.default.isValidLabelInterval(i.labelInterval, i.tickInterval) && t.labels.length > i.labelInterval && (o = this._makeLabelsByIntervalOption(t.labels, i.labelInterval, t.addedDataCount)),
                        h.default.isDatetimeType(i.type) && (o = m.default.formatDates(o, i.dateFormat)),
                        t.aligned || (n += 1),
                            { labels: o, tickCount: n, validTickCount: n, isLabelAxis: !0, options: i, isVertical: !!t.isVertical, isPositionRight: !!t.isPositionRight, aligned: !!t.aligned }
                    );
                },
                makeValueAxisData: function (t) {
                    var e = t.labels,
                        i = t.tickCount,
                        n = t.limit,
                        o = t.step,
                        r = t.options,
                        a = t.isVertical,
                        s = t.isPositionRight,
                        u = t.aligned;
                    return { labels: e, tickCount: i, limit: n, step: o, options: r, validTickCount: i, dataMin: n.min, distance: n.max - n.min, isVertical: !!a, isPositionRight: !!s, aligned: !!u };
                },
                makeAdditionalDataForCoordinateLineType: function (t, e, i, n, o) {
                    var r = v.default.min(e),
                        a = v.default.max(e),
                        s = a - r,
                        u = 0,
                        l = 1;
                    return (
                        s && (i.min < r && ((i.min += n), (l -= u = (i.min - r) / s), (o -= 1), t.shift()), i.max > a && ((i.max -= n), (l -= (a - i.max) / s), (o -= 1), t.pop())),
                            { labels: t, tickCount: o, limit: i, distance: s, positionRatio: u, sizeRatio: l, validTickCount: o, dataMin: r }
                    );
                },
                _makeAdjustingIntervalInfo: function (t, e, i) {
                    var n = void 0,
                        o = parseInt(e / i, 10),
                        r = null,
                        a = parseInt(t / o, 10);
                    return a > 1 && ((n = t - a * o) >= a && ((o += parseInt(n / a, 0)), (n %= a)), (r = { blockCount: o, beforeRemainBlockCount: n, interval: a })), r;
                },
                _makeCandidatesForAdjustingInterval: function (t, e) {
                    var i = this,
                        n = [];
                    return (
                        f.default.divisors(t).forEach(function (i) {
                            var o = (i / t) * e;
                            o >= 90 && o <= 121 && n.push({ interval: i, blockCount: t / i, beforeRemainBlockCount: 0 });
                        }),
                        0 === n.length &&
                        (n = (0, a.default)(90, 121, 5).map(function (n) {
                            return i._makeAdjustingIntervalInfo(t, e, n);
                        })),
                            n.filter(function (t) {
                                return !!t;
                            })
                    );
                },
                _calculateAdjustingIntervalInfo: function (t, e) {
                    var i = this._makeCandidatesForAdjustingInterval(t, e),
                        n = null;
                    return (
                        i.length &&
                        (n = v.default.max(i, function (t) {
                            return t.blockCount;
                        })),
                            n
                    );
                },
                _makeFilteredLabelsByInterval: function (t, e, i) {
                    return t.slice(e).filter(function (t, e) {
                        return e % i == 0;
                    });
                },
                updateLabelAxisDataForAutoTickInterval: function (t, e, i, n) {
                    n && ((t.tickCount -= 1), t.labels.pop());
                    var o = t.tickCount - 1,
                        r = this._calculateAdjustingIntervalInfo(o, e);
                    if (r) {
                        t.eventTickCount = t.tickCount;
                        var a = r.blockCount,
                            s = r.beforeRemainBlockCount,
                            u = r.interval,
                            l = t.labels[t.labels.length - 1];
                        (t.labels = this._makeFilteredLabelsByInterval(t.labels, 0, u)),
                        s > 0 && t.labels.push(l),
                            Object.assign(t, { startIndex: 0, tickCount: a + 1, interval: u, positionRatio: 0 / o, sizeRatio: 1 - s / o, remainLastBlockInterval: s });
                    }
                },
                updateLabelAxisDataForStackingDynamicData: function (t, e, i) {
                    var n = e.interval,
                        o = e.startIndex,
                        r = t.tickCount - 1,
                        a = i ? i - 1 : 0,
                        s = r / n;
                    a && 2 * a <= s && (n *= 2), (t.labels = this._makeFilteredLabelsByInterval(t.labels, o, n));
                    var u = r - n * (s = t.labels.length - 1);
                    Object.assign(t, { startIndex: o, interval: n, eventTickCount: t.tickCount, tickCount: t.labels.length, positionRatio: o / r, sizeRatio: 1 - u / r });
                },
                _calculateXAxisLabelAreaWidth: function (t, e, i) {
                    return t || (i -= 1), e / i;
                },
                _createMultilineLabel: function (t, e, i) {
                    var n = String(t).split(" "),
                        r = [],
                        a = o(n, 1)[0];
                    return (
                        n.slice(1).forEach(function (t) {
                            m.default.getRenderedLabelWidth(a + " " + t, i) > e ? (r.push(a), (a = t)) : (a += " " + t);
                        }),
                        a && r.push(a),
                            r.join("\n")
                    );
                },
                _createMultilineLabels: function (t, e, i) {
                    var n = this._createMultilineLabel;
                    return t.map(function (t) {
                        return n(t, i, e);
                    });
                },
                _calculateMultilineHeight: function (t, e, i) {
                    return m.default.getRenderedLabelsMaxHeight(t, Object.assign({ cssText: "line-height:1.2;width:" + i + "px" }, e));
                },
                makeAdditionalDataForMultilineLabels: function (t, e, i, n, o) {
                    var r = o.series.width,
                        a = this._calculateXAxisLabelAreaWidth(n, r, e),
                        s = this._createMultilineLabels(t, i, a),
                        u = this._calculateMultilineHeight(s, i, a),
                        l = m.default.getRenderedLabelsMaxHeight(t, i);
                    return { multilineLabels: s, overflowHeight: u - l, overflowLeft: 0 };
                },
                _findRotationDegree: function (t, e, i) {
                    var n = null;
                    return (
                        u.default.DEGREE_CANDIDATES.every(function (o) {
                            var r = p.default.calculateRotatedWidth(o, e, i);
                            return (n = o), !(r <= t);
                        }),
                            n
                    );
                },
                _calculateRotatedWidth: function (t, e, i, n) {
                    var o = m.default.getRenderedLabelWidth(e, n);
                    return p.default.calculateRotatedWidth(t, o, i) - p.default.calculateAdjacent(u.default.ANGLE_90 - t, i / 2);
                },
                _calculateLimitWidth: function (t, e, i) {
                    var n = t;
                    return e && (n += i / 2), n;
                },
                makeAdditionalDataForRotatedLabels: function (t, e, i, n, o) {
                    var r = m.default.getRenderedLabelsMaxWidth(t, i),
                        a = o.series.width,
                        s = o.yAxis.width + (o.rightYAxis ? o.rightYAxis.width : 0),
                        l = this._calculateXAxisLabelAreaWidth(n, a, e),
                        h = null,
                        c = 2 * u.default.CHART_PADDING + s + a;
                    if (l < r) {
                        var d = m.default.getRenderedLabelsMaxHeight(t, i),
                            f = this._findRotationDegree(l, r, d),
                            g = p.default.calculateRotatedHeight(f, r, d),
                            y = this._calculateRotatedWidth(f, t[0], d, i),
                            v = this._calculateLimitWidth(o.yAxis.width, n, l);
                        (c += y), (h = { degree: f, overflowHeight: g - d, overflowLeft: y - v, overflowRight: c - o.chart.width });
                    } else (c += r), (h = { overflowLeft: (l = m.default.getRenderedLabelWidth(t[0], i) / 2) - o.yAxis.width, overflowRight: c - o.chart.width });
                    return h;
                },
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(195),
                r = n(o),
                a = i(154),
                s = n(a),
                u = i(181),
                l = n(u),
                h = (function (t) {
                    function e(i, n, o) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e),
                            l.default.updateRawSeriesDataByOptions(i, o.series);
                        var r = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, { rawData: i, theme: n, options: o, hasAxes: !0, isVertical: !0 }));
                        return r._updateOptionsRelatedDiverging(o), (r.className = "tui-column-chart"), r;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._updateOptionsRelatedDiverging = function (t) {
                                (t.series = t.series || {}), t.series.diverging && (t.series.stack = t.series.stack || { type: s.default.NORMAL_STACK_TYPE });
                            }),
                            (e.prototype.addComponents = function () {
                                this.componentManager.register("title", "title"),
                                    this.componentManager.register("plot", "plot"),
                                    this.componentManager.register("legend", "legend"),
                                    this.componentManager.register("columnSeries", "columnSeries"),
                                    this.componentManager.register("yAxis", "axis"),
                                    this.componentManager.register("xAxis", "axis"),
                                    this.componentManager.register("chartExportMenu", "chartExportMenu"),
                                    this.componentManager.register("tooltip", "tooltip"),
                                    this.componentManager.register("mouseEventDetector", "mouseEventDetector");
                            }),
                            (e.prototype.getScaleOption = function () {
                                return { yAxis: !0 };
                            }),
                            (e.prototype.addDataRatios = function (t) {
                                var e = this.options.series,
                                    i = void 0 === e ? {} : e,
                                    n = this.chartType,
                                    o = (i[n] || i).stack;
                                this.dataProcessor.addDataRatios(t[n], o, n);
                            }),
                            e
                    );
                })(r.default);
            e.default = h;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(141),
                r = n(o),
                a = i(146),
                s = n(a),
                u = i(152),
                l = n(u),
                h = i(195),
                c = n(h),
                p = i(164),
                d = n(p),
                f = i(286),
                g = n(f),
                m = i(244),
                y = n(m),
                v = i(181),
                $ = n(v),
                x = (function (t) {
                    function e(i, n, o) {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        var r = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, { rawData: i, theme: n, options: o, hasAxes: !0, isVertical: !0 }));
                        return (
                            (r.className = "tui-line-chart"),
                                (r.Series = y.default),
                            r.dataProcessor.isCoordinateType() && (delete r.options.xAxis.tickInterval, (r.options.tooltip.grouped = !1), (r.options.series.shifting = !1)),
                                (r._dynamicDataHelper = new g.default(r)),
                                r
                        );
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype.addData = function (t, e) {
                                this._dynamicDataHelper.addData(t, e);
                            }),
                            (e.prototype.onChangeCheckedLegends = function (t, e, i) {
                                this._dynamicDataHelper.reset(), this._dynamicDataHelper.changeCheckedLegends(t, e, i);
                            }),
                            (e.prototype.addDataRatios = function (t) {
                                var e = this,
                                    i = this.chartTypes || [this.chartType],
                                    n = this.options.series || {},
                                    o = void 0;
                                (o = this.dataProcessor.isCoordinateType()
                                    ? function (i) {
                                        var n = d.default.isBubbleChart(i);
                                        e.dataProcessor.addDataRatiosForCoordinateType(i, t, n);
                                    }
                                    : function (i) {
                                        var o = (n[i] || n).stack;
                                        e.dataProcessor.addDataRatios(t[i], o, i);
                                    }),
                                    (0, r.default)(i, o);
                            }),
                            (e.prototype.addComponents = function () {
                                this.componentManager.register("title", "title"),
                                    this.componentManager.register("plot", "plot"),
                                    this.componentManager.register("lineSeries", "lineSeries"),
                                    this.componentManager.register("xAxis", "axis"),
                                this.hasRightYAxis && this.componentManager.register("rightYAxis", "axis"),
                                    this.componentManager.register("yAxis", "axis"),
                                    this.componentManager.register("legend", "legend"),
                                    this.componentManager.register("chartExportMenu", "chartExportMenu"),
                                    this.componentManager.register("tooltip", "tooltip"),
                                    this.componentManager.register("mouseEventDetector", "mouseEventDetector");
                            }),
                            (e.prototype.getScaleOption = function () {
                                var t = {},
                                    e = this.options.xAxis,
                                    i = this.options.yAxis,
                                    n = void 0,
                                    o = void 0;
                                return (
                                    this.dataProcessor.isCoordinateType()
                                        ? ((n = (o = e && "datetime" === e.type) && (0, s.default)(e.dateFormat)),
                                            (t.xAxis = { valueType: "x" }),
                                        o && (t.xAxis.type = (e || {}).dateTime),
                                        n && (t.xAxis.format = (e || {}).dateFormat),
                                            (t.yAxis = { valueType: "y" }))
                                        : this.hasRightYAxis
                                            ? ((t.yAxis = this._makeYAxisScaleOption("yAxis", i[0])), (t.rightYAxis = this._makeYAxisScaleOption("yAxis", i[1])))
                                            : (t.yAxis = !0),
                                        t
                                );
                            }),
                            (e.prototype._makeYAxisScaleOption = function (t, e) {
                                return { options: e, areaType: "yAxis" };
                            }),
                            (e.prototype.addPlotLine = function (t) {
                                this.componentManager.get("plot").addPlotLine(t);
                            }),
                            (e.prototype.addPlotBand = function (t) {
                                this.componentManager.get("plot").addPlotBand(t);
                            }),
                            (e.prototype.removePlotLine = function (t) {
                                this.componentManager.get("plot").removePlotLine(t);
                            }),
                            (e.prototype.removePlotBand = function (t) {
                                this.componentManager.get("plot").removePlotBand(t);
                            }),
                            (e.prototype._renderForZoom = function (t) {
                                var e = this.readyForRender();
                                this.componentManager.render("zoom", e, { isResetZoom: t });
                            }),
                            (e.prototype.onZoom = function (t) {
                                this._dynamicDataHelper.pauseAnimation(), this.dataProcessor.updateRawDataForZoom(t), this._renderForZoom(!1);
                            }),
                            (e.prototype.onResetZoom = function () {
                                var t = this.dataProcessor.getOriginalRawData();
                                this._dynamicDataHelper.checkedLegends && (t = $.default.filterCheckedRawData(t, this._dynamicDataHelper.checkedLegends)),
                                    this.dataProcessor.initData(t),
                                    this.dataProcessor.initZoomedRawData(),
                                    this.dataProcessor.addDataFromRemainDynamicData((0, l.default)(this.options.series, "shifting")),
                                    this._renderForZoom(!0),
                                    this._dynamicDataHelper.restartAnimation();
                            }),
                            e
                    );
                })(c.default);
            e.default = x;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(154),
                r = n(o),
                a = i(164),
                s = n(a),
                u = (function () {
                    function t(e) {
                        var i = this;
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t);
                        var n = function t() {
                            (i.isInitRenderCompleted = !0), i.chart.off(t);
                        };
                        (this.chart = e), (this.isInitRenderCompleted = !1), this.chart.on("load", n), this.reset();
                    }
                    return (
                        (t.prototype.reset = function () {
                            (this.lookupping = !1), (this.paused = !1), (this.rerenderingDelayTimerId = null), (this.addedDataCount = 0), (this.checkedLegends = null), (this.prevXAxisData = null);
                        }),
                            (t.prototype._calculateAnimateTickSize = function (t) {
                                var e = this.chart.dataProcessor,
                                    i = this.chart.options.xAxis.tickInterval,
                                    n = !!this.chart.options.series.shifting,
                                    o = void 0;
                                return (o = e.isCoordinateType() ? e.getValues(this.chart.chartType, "x").length - 1 : e.getCategoryCount(!1) - 1), n && !s.default.isAutoTickInterval(i) && (o -= 1), t / o;
                            }),
                            (t.prototype._animateForAddingData = function () {
                                var t = this.chart,
                                    e = t.readyForRender(!0),
                                    i = !!this.chart.options.series.shifting;
                                this.addedDataCount += 1;
                                var n = this._calculateAnimateTickSize(e.dimensionMap.xAxis.width);
                                t.componentManager.render("animateForAddingData", e, { tickSize: n, shifting: i }), i && t.dataProcessor.shiftData();
                            }),
                            (t.prototype._rerenderForAddingData = function () {
                                var t = this.chart,
                                    e = t.readyForRender();
                                t.componentManager.render("rerender", e);
                            }),
                            (t.prototype._checkForAddedData = function () {
                                var t = this,
                                    e = this.chart;
                                return e.dataProcessor.addDataFromDynamicData()
                                    ? this.paused
                                        ? void (e.options.series.shifting && e.dataProcessor.shiftData())
                                        : (this._animateForAddingData(),
                                            void (this.rerenderingDelayTimerId = setTimeout(function () {
                                                (t.rerenderingDelayTimerId = null), t._rerenderForAddingData(), t._checkForAddedData();
                                            }, 400)))
                                    : void (this.lookupping = !1);
                            }),
                            (t.prototype.changeCheckedLegends = function (t, e, i) {
                                var n = this,
                                    o = this.chart,
                                    a = !!o.options.series.shifting,
                                    s = this.paused;
                                s || this.pauseAnimation(),
                                    (this.checkedLegends = t),
                                    o.protectedRerender(t, e, i),
                                s ||
                                setTimeout(function () {
                                    o.dataProcessor.addDataFromRemainDynamicData(a), n.restartAnimation();
                                }, r.default.RERENDER_TIME);
                            }),
                            (t.prototype.pauseAnimation = function () {
                                (this.paused = !0), this.rerenderingDelayTimerId && (clearTimeout(this.rerenderingDelayTimerId), (this.rerenderingDelayTimerId = null), this.chart.options.series.shifting && this.chart.dataProcessor.shiftData());
                            }),
                            (t.prototype.restartAnimation = function () {
                                (this.paused = !1), (this.lookupping = !1), this._startLookup();
                            }),
                            (t.prototype._startLookup = function () {
                                this.lookupping || ((this.lookupping = !0), this._checkForAddedData());
                            }),
                            (t.prototype.addData = function (t, e) {
                                e || ((e = t), (t = null)), this.chart.dataProcessor.addDynamicData(t, e), this.isInitRenderCompleted ? this._startLookup() : e && (this.addedDataCount += 1);
                            }),
                            t
                    );
                })();
            e.default = u;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(152),
                r = n(o),
                a = i(195),
                s = n(a),
                u = i(286),
                l = n(u),
                h = i(181),
                c = n(h),
                p = i(247),
                d = n(p),
                f = (function (t) {
                    function e(i, n, o) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e),
                            c.default.removeSeriesStack(i.series);
                        var r = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, { rawData: i, theme: n, options: o, hasAxes: !0, isVertical: !0 }));
                        return (r.className = "tui-area-chart"), (r.Series = d.default), (r._dynamicDataHelper = new l.default(r)), r;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype.addData = function (t, e) {
                                this._dynamicDataHelper.addData(t, e);
                            }),
                            (e.prototype.onChangeCheckedLegends = function (t, e, i) {
                                this._dynamicDataHelper.reset(), this._dynamicDataHelper.changeCheckedLegends(t, e, i);
                            }),
                            (e.prototype.addDataRatios = function (t) {
                                var e = this,
                                    i = this.chartTypes || [this.chartType],
                                    n = this.options.series || {},
                                    o = void 0;
                                (o = this.dataProcessor.isCoordinateType()
                                    ? function (i) {
                                        e.dataProcessor.addDataRatiosForCoordinateType(i, t, !1);
                                    }
                                    : function (i) {
                                        var o = (n[i] || n).stack;
                                        e.dataProcessor.addDataRatios(t[i], o, i);
                                    }),
                                    i.forEach(o);
                            }),
                            (e.prototype.addComponents = function () {
                                this.componentManager.register("title", "title"),
                                    this.componentManager.register("plot", "plot"),
                                    this.componentManager.register("legend", "legend"),
                                    this.componentManager.register("areaSeries", "areaSeries"),
                                    this.componentManager.register("xAxis", "axis"),
                                    this.componentManager.register("yAxis", "axis"),
                                    this.componentManager.register("chartExportMenu", "chartExportMenu"),
                                    this.componentManager.register("tooltip", "tooltip"),
                                    this.componentManager.register("mouseEventDetector", "mouseEventDetector");
                            }),
                            (e.prototype.getScaleOption = function () {
                                var t = {};
                                return this.dataProcessor.isCoordinateType() ? ((t.xAxis = { valueType: "x" }), (t.yAxis = { valueType: "y" })) : (t.yAxis = !0), t;
                            }),
                            (e.prototype.addPlotLine = function (t) {
                                this.componentManager.get("plot").addPlotLine(t);
                            }),
                            (e.prototype.addPlotBand = function (t) {
                                this.componentManager.get("plot").addPlotBand(t);
                            }),
                            (e.prototype.removePlotLine = function (t) {
                                this.componentManager.get("plot").removePlotLine(t);
                            }),
                            (e.prototype.removePlotBand = function (t) {
                                this.componentManager.get("plot").removePlotBand(t);
                            }),
                            (e.prototype._renderForZoom = function (t) {
                                var e = this.readyForRender();
                                this.componentManager.render("zoom", e, { isResetZoom: t });
                            }),
                            (e.prototype.onZoom = function (t) {
                                this._dynamicDataHelper.pauseAnimation(), this.dataProcessor.updateRawDataForZoom(t), this._renderForZoom(!1);
                            }),
                            (e.prototype.onResetZoom = function () {
                                var t = this.dataProcessor.getOriginalRawData();
                                this._dynamicDataHelper.checkedLegends && (t = c.default.filterCheckedRawData(t, this._dynamicDataHelper.checkedLegends)),
                                    this.dataProcessor.initData(t),
                                    this.dataProcessor.initZoomedRawData(),
                                    this.dataProcessor.addDataFromRemainDynamicData((0, r.default)(this.options.series, "shifting")),
                                    this._renderForZoom(!0),
                                    this._dynamicDataHelper.restartAnimation();
                            }),
                            e
                    );
                })(s.default);
            e.default = f;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                r = i(195),
                a = n(r),
                s = i(181),
                u = n(s),
                l = i(164),
                h = n(l),
                c = i(289),
                p = n(c),
                d = (function (t) {
                    function e(i, n, o) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e),
                            (o.tooltip = o.tooltip || {}),
                            (o.tooltip.grouped = !0);
                        var r = (0, p.default)({ rawSeriesData: i.series, yAxisOptions: o.yAxis }),
                            a = (function t(e, i) {
                                if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                                return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                            })(this, t.call(this, { rawData: i, theme: n, options: o, chartTypes: r.chartTypes, seriesTypes: r.seriesTypes, hasAxes: !0, isVertical: !0 }));
                        return (a.yAxisOptions = a._makeYAxisOptions(a.chartTypes, o.yAxis)), a;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._makeYAxisOptions = function (t, e) {
                                var i = {};
                                return (
                                    (e = e || {}),
                                        t.forEach(function (t, n) {
                                            i[t] = e[n] || e;
                                        }),
                                        i
                                );
                            }),
                            (e.prototype.onChangeCheckedLegends = function (t) {
                                var e = this.dataProcessor.getOriginalRawData(),
                                    i = u.default.filterCheckedRawData(e, t),
                                    n = (0, p.default)({ rawSeriesData: i.series, yAxisOptions: this.options.yAxis });
                                (this.chartTypes = n.chartTypes), (this.seriesTypes = n.seriesTypes), this.protectedRerender(t, i, n);
                            }),
                            (e.prototype.addComponents = function () {
                                this.componentManager.register("title", "title"),
                                    this.componentManager.register("plot", "plot"),
                                    this.componentManager.register("legend", "legend"),
                                    this.componentManager.register("columnSeries", "columnSeries"),
                                    this.componentManager.register("lineSeries", "lineSeries"),
                                    this.componentManager.register("yAxis", "axis"),
                                this.hasRightYAxis && this.componentManager.register("rightYAxis", "axis"),
                                    this.componentManager.register("xAxis", "axis"),
                                    this.componentManager.register("chartExportMenu", "chartExportMenu"),
                                    this.componentManager.register("tooltip", "tooltip"),
                                    this.componentManager.register("mouseEventDetector", "mouseEventDetector");
                            }),
                            (e.prototype.getScaleOption = function () {
                                var t = { yAxis: this._makeYAxisScaleOption("yAxis", this.chartTypes[0], !this.hasRightYAxis) };
                                return this.hasRightYAxis && (t.rightYAxis = this._makeYAxisScaleOption("rightYAxis", this.chartTypes[1])), t;
                            }),
                            (e.prototype._makeYAxisScaleOption = function (t, e, i) {
                                var n = this.yAxisOptions[e],
                                    o = { isSingleYAxis: !!i };
                                return i && this.options.series && this._setAdditionalOptions(o), { options: n, areaType: "yAxis", chartType: e, additionalOptions: o };
                            }),
                            (e.prototype._setAdditionalOptions = function (t) {
                                var e = this.dataProcessor;
                                Object.entries(this.options.series).forEach(function (i) {
                                    var n = o(i, 2),
                                        r = n[0],
                                        a = n[1];
                                    if (a.stackType) {
                                        var s = e.findChartType(r);
                                        h.default.isAllowedStackOption(s) && ((t.chartType = s), (t.stackType = a.stackType));
                                    }
                                });
                            }),
                            (e.prototype.addDataRatios = function (t) {
                                var e = this,
                                    i = this.chartTypes || [this.chartType],
                                    n = this.options.series || {},
                                    o = function (i) {
                                        var o = (n[i] || n).stack;
                                        e.dataProcessor.addDataRatios(t[i], o, i);
                                    };
                                i.forEach(o);
                            }),
                            e
                    );
                })(a.default);
            e.default = d;
        },
        function (t, e) {
            "use strict";
            (e.__esModule = !0),
                (e.default = function t(e) {
                    var i,
                        n,
                        o,
                        r,
                        a,
                        s,
                        u,
                        l,
                        h,
                        c,
                        p,
                        d,
                        f =
                            ((i = e.rawSeriesData),
                                (n = e.yAxisOptions),
                                (p = (c =
                                    ((o = h = Object.keys(i).sort()),
                                        (r = n),
                                        (a = o.slice()),
                                        (s = [].concat(r || [])),
                                        (u = !1),
                                        (l = void 0),
                                        s.length && (1 !== s.length || s[0].chartType)
                                            ? s.length &&
                                            ((l = s.map(function (t) {
                                                return t.chartType;
                                            })).forEach(function (t, e) {
                                                u = u || (t && a[e] !== t) || !1;
                                            }),
                                            u && a.reverse())
                                            : (a = []),
                                        a)).length
                                    ? c
                                    : h),
                                1 ===
                                (d = c.filter(function (t) {
                                    return i[t].length;
                                })).length
                                    ? { chartTypes: d, seriesTypes: d }
                                    : { chartTypes: p, seriesTypes: h });
                    return { chartTypes: f.chartTypes, seriesTypes: f.seriesTypes };
                });
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(141),
                r = n(o),
                a = i(195),
                s = (function (t) {
                    function e(i, n, o) {
                        return (
                            (function t(e, i) {
                                if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                            })(this, e),
                                (function t(e, i) {
                                    if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                                    return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                                })(this, t.call(this, { rawData: i, theme: n, options: o, chartTypes: ["line", "scatter"], seriesTypes: ["line", "scatter"], hasAxes: !0, isVertical: !0 }))
                        );
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype.getScaleOption = function () {
                                return { yAxis: { valueType: "y" }, xAxis: { valueType: "x" } };
                            }),
                            (e.prototype.addDataRatios = function (t) {
                                var e = this,
                                    i = this.chartTypes || [this.chartType],
                                    n = function (i) {
                                        e.dataProcessor.addDataRatiosForCoordinateType(i, t, !1);
                                    };
                                (0, r.default)(i, n);
                            }),
                            (e.prototype.addComponents = function () {
                                this.componentManager.register("title", "title"),
                                    this.componentManager.register("plot", "plot"),
                                    this.componentManager.register("legend", "legend"),
                                    this.componentManager.register("lineSeries", "lineSeries"),
                                    this.componentManager.register("scatterSeries", "scatterSeries"),
                                    this.componentManager.register("yAxis", "axis"),
                                    this.componentManager.register("xAxis", "axis"),
                                    this.componentManager.register("chartExportMenu", "chartExportMenu"),
                                    this.componentManager.register("tooltip", "tooltip"),
                                    this.componentManager.register("mouseEventDetector", "mouseEventDetector");
                            }),
                            e
                    );
                })(n(a).default);
            e.default = s;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = function (t, e) {
                    if (Array.isArray(t)) return t;
                    if (Symbol.iterator in Object(t))
                        return (function t(e, i) {
                            var n = [],
                                o = !0,
                                r = !1,
                                a = void 0;
                            try {
                                for (var s, u = e[Symbol.iterator](); !(o = (s = u.next()).done) && (n.push(s.value), !i || n.length !== i); o = !0);
                            } catch (l) {
                                (r = !0), (a = l);
                            } finally {
                                try {
                                    !o && u.return && u.return();
                                } finally {
                                    if (r) throw a;
                                }
                            }
                            return n;
                        })(t, e);
                    throw TypeError("Invalid attempt to destructure non-iterable instance");
                },
                r = i(152),
                a = n(r),
                s = i(141),
                u = n(s),
                l = i(195),
                h = n(l),
                c = i(181),
                p = n(c),
                d = i(164),
                f = n(d),
                g = i(289),
                m = n(g),
                y = i(286),
                v = n(y),
                $ = (function (t) {
                    function e(i, n, o) {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        var r = (0, m.default)({ rawSeriesData: i.series, yAxisOptions: o.yAxis });
                        (o.tooltip = o.tooltip || {}), (o.tooltip.grouped = !0);
                        var a = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, { rawData: i, theme: n, options: o, seriesTypes: r.seriesTypes, chartTypes: r.chartTypes, hasAxes: !0, isVertical: !0 }));
                        return (a.yAxisOptions = a._makeYAxisOptions(a.chartTypes, o.yAxis)), (a._dynamicDataHelper = new v.default(a)), (a.className = "tui-combo-chart"), a;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype.onChangeCheckedLegends = function (t) {
                                var e = this.dataProcessor.getCurrentData(),
                                    i = p.default.filterCheckedRawData(e, t),
                                    n = (0, m.default)({ rawSeriesData: i.series, yAxisOptions: this.options.yAxis });
                                this._dynamicDataHelper.reset(), this._dynamicDataHelper.changeCheckedLegends(t, i, n);
                            }),
                            (e.prototype.addComponents = function () {
                                this.componentManager.register("title", "title"),
                                    this.componentManager.register("plot", "plot"),
                                    this.componentManager.register("legend", "legend"),
                                    this.componentManager.register("areaSeries", "areaSeries"),
                                    this.componentManager.register("lineSeries", "lineSeries"),
                                    this.componentManager.register("xAxis", "axis"),
                                    this.componentManager.register("yAxis", "axis"),
                                this.hasRightYAxis && this.componentManager.register("rightYAxis", "axis"),
                                    this.componentManager.register("chartExportMenu", "chartExportMenu"),
                                    this.componentManager.register("tooltip", "tooltip"),
                                    this.componentManager.register("mouseEventDetector", "mouseEventDetector");
                            }),
                            (e.prototype.getScaleOption = function () {
                                var t = { yAxis: this._makeYAxisScaleOption("yAxis", this.chartTypes[0], !this.hasRightYAxis) };
                                return this.hasRightYAxis && (t.rightYAxis = this._makeYAxisScaleOption("rightYAxis", this.chartTypes[1])), t;
                            }),
                            (e.prototype._makeYAxisScaleOption = function (t, e, i) {
                                var n = this.yAxisOptions[e],
                                    o = { isSingleYAxis: !!i };
                                return i && this.options.series && this._setAdditionalOptions(o), { options: n, areaType: "yAxis", chartType: e, additionalOptions: o };
                            }),
                            (e.prototype._makeYAxisOptions = function (t) {
                                var e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
                                    i = {};
                                return (
                                    t.forEach(function (t, n) {
                                        i[t] = e[n] || e;
                                    }),
                                        i
                                );
                            }),
                            (e.prototype.addData = function (t, e) {
                                this._dynamicDataHelper.addData(t, e);
                            }),
                            (e.prototype._setAdditionalOptions = function (t) {
                                var e = this.dataProcessor;
                                Object.entries(this.options.series).forEach(function (i) {
                                    var n = o(i, 2),
                                        r = n[0],
                                        a = n[1];
                                    if (a.stackType) {
                                        var s = e.findChartType(r);
                                        f.default.isAllowedStackOption(s) && ((t.chartType = s), (t.stack = a.stack));
                                    }
                                });
                            }),
                            (e.prototype.addDataRatios = function (t) {
                                var e = this,
                                    i = this.chartTypes || [this.chartType],
                                    n = this.options.series || {},
                                    o = void 0;
                                (o = this.dataProcessor.isCoordinateType()
                                    ? function (i) {
                                        e.dataProcessor.addDataRatiosForCoordinateType(i, t, !1);
                                    }
                                    : function (i) {
                                        var o = (n[i] || n).stack;
                                        e.dataProcessor.addDataRatios(t[i], o, i);
                                    }),
                                    (0, u.default)(i, o);
                            }),
                            (e.prototype.addPlotLine = function (t) {
                                this.componentManager.get("plot").addPlotLine(t);
                            }),
                            (e.prototype.addPlotBand = function (t) {
                                this.componentManager.get("plot").addPlotBand(t);
                            }),
                            (e.prototype.removePlotLine = function (t) {
                                this.componentManager.get("plot").removePlotLine(t);
                            }),
                            (e.prototype.removePlotBand = function (t) {
                                this.componentManager.get("plot").removePlotBand(t);
                            }),
                            (e.prototype._renderForZoom = function (t) {
                                var e = this.readyForRender();
                                this.componentManager.render("zoom", e, { isResetZoom: t });
                            }),
                            (e.prototype.onZoom = function (t) {
                                this._dynamicDataHelper.pauseAnimation(), this.dataProcessor.updateRawDataForZoom(t), this._renderForZoom(!1);
                            }),
                            (e.prototype.onResetZoom = function () {
                                var t = this.dataProcessor.getOriginalRawData();
                                this._dynamicDataHelper.checkedLegends && (t = p.default.filterCheckedRawData(t, this._dynamicDataHelper.checkedLegends)),
                                    this.dataProcessor.initData(t),
                                    this.dataProcessor.initZoomedRawData(),
                                    this.dataProcessor.addDataFromRemainDynamicData((0, a.default)(this.options.series, "shifting")),
                                    this._renderForZoom(!0),
                                    this._dynamicDataHelper.restartAnimation();
                            }),
                            e
                    );
                })(h.default);
            e.default = $;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(195),
                r = n(o),
                a = i(181),
                s = n(a),
                u = (function (t) {
                    function e(i, n, o) {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        var r = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, { rawData: i, theme: n, options: o, seriesTypes: Object.keys(i.series).sort(), chartTypes: ["pie", "pie"], isVertical: !0 }));
                        return (r.className = "tui-combo-chart"), r;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype.addComponents = function () {
                                this.componentManager.register("title", "title"),
                                    this.componentManager.register("legend", "legend"),
                                    this.componentManager.register("pie1Series", "pieSeries"),
                                    this.componentManager.register("pie2Series", "pieSeries"),
                                    this.componentManager.register("chartExportMenu", "chartExportMenu"),
                                    this.componentManager.register("tooltip", "tooltip"),
                                    this.componentManager.register("mouseEventDetector", "mouseEventDetector");
                            }),
                            (e.prototype.addDataRatios = function () {
                                var t = this;
                                (this.seriesTypes || [this.chartType]).forEach(function (e) {
                                    t.dataProcessor.addDataRatiosOfPieChart(e);
                                });
                            }),
                            (e.prototype.onChangeCheckedLegends = function (t) {
                                var e = this.dataProcessor.getOriginalRawData(),
                                    i = s.default.filterCheckedRawData(e, t);
                                r.default.prototype.onChangeCheckedLegends.call(this, t, i, { seriesTypes: this.seriesTypes });
                            }),
                            e
                    );
                })(r.default);
            e.default = u;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(195),
                r = n(o),
                a = i(154),
                s = n(a),
                u = (function (t) {
                    function e(i, n, o) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e),
                            (o.tooltip = o.tooltip || {}),
                        o.tooltip.align || (o.tooltip.align = s.default.TOOLTIP_DEFAULT_ALIGN_OPTION);
                        var r = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, { rawData: i, theme: n, options: o }));
                        return (r.className = "tui-pie-chart"), r;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype.addComponents = function () {
                                this.componentManager.register("title", "title"),
                                    this.componentManager.register("legend", "legend"),
                                    this.componentManager.register("pieSeries", "pieSeries"),
                                    this.componentManager.register("chartExportMenu", "chartExportMenu"),
                                    this.componentManager.register("tooltip", "tooltip"),
                                    this.componentManager.register("mouseEventDetector", "mouseEventDetector");
                            }),
                            (e.prototype.addDataRatios = function () {
                                this.dataProcessor.addDataRatiosOfPieChart(this.chartType);
                            }),
                            e
                    );
                })(r.default);
            e.default = u;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(145),
                r = n(o),
                a = i(195),
                s = n(a),
                u = i(154),
                l = n(u),
                h = (function (t) {
                    function e(i, n, o) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e),
                            ((o = Object.assign({ tooltip: {}, circleLegend: {} }, o)).circleLegend = Object.assign({ visible: !0 }, o.circleLegend)),
                            (o.tooltip = Object.assign({ align: l.default.TOOLTIP_DEFAULT_ALIGN_OPTION, grouped: !1 }, o.tooltip));
                        var r = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, { rawData: i, theme: n, options: o, hasAxes: !0 }));
                        return (r.className = "tui-bubble-chart"), r;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype.getScaleOption = function () {
                                var t = {};
                                return this.dataProcessor.hasXValue(this.chartType) && (t.xAxis = { valueType: "x" }), this.dataProcessor.hasYValue(this.chartType) && (t.yAxis = { valueType: "y" }), t;
                            }),
                            (e.prototype._setDefaultOptions = function (t) {
                                s.default.prototype._setDefaultOptions.call(this, t), (this.options.circleLegend = this.options.circleLegend || {}), (0, r.default)(this.options.circleLegend.visible) && (this.options.circleLegend.visible = !0);
                            }),
                            (e.prototype.addComponents = function () {
                                this.componentManager.register("title", "title"),
                                    this.componentManager.register("plot", "plot"),
                                    this.componentManager.register("legend", "legend"),
                                    this.componentManager.register("circleLegend", "circleLegend"),
                                    this.componentManager.register("bubbleSeries", "bubbleSeries"),
                                    this.componentManager.register("yAxis", "axis"),
                                    this.componentManager.register("xAxis", "axis"),
                                    this.componentManager.register("chartExportMenu", "chartExportMenu"),
                                    this.componentManager.register("tooltip", "tooltip"),
                                    this.componentManager.register("mouseEventDetector", "mouseEventDetector");
                            }),
                            (e.prototype.addDataRatios = function (t) {
                                this.dataProcessor.addDataRatiosForCoordinateType(this.chartType, t, !0);
                            }),
                            e
                    );
                })(s.default);
            e.default = h;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(195),
                r = n(o),
                a = i(154),
                s = n(a),
                u = (function (t) {
                    function e(i, n, o) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e),
                            (o.tooltip = o.tooltip || {}),
                        o.tooltip.align || (o.tooltip.align = s.default.TOOLTIP_DEFAULT_ALIGN_OPTION),
                            (o.tooltip.grouped = !1);
                        var r = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, { rawData: i, theme: n, options: o, hasAxes: !0 }));
                        return (r.className = "tui-scatter-chart"), r;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype.getScaleOption = function () {
                                return { xAxis: { valueType: "x" }, yAxis: { valueType: "y" } };
                            }),
                            (e.prototype.addComponents = function () {
                                this.componentManager.register("title", "title"),
                                    this.componentManager.register("plot", "plot"),
                                    this.componentManager.register("legend", "legend"),
                                    this.componentManager.register("scatterSeries", "scatterSeries"),
                                    this.componentManager.register("yAxis", "axis"),
                                    this.componentManager.register("xAxis", "axis"),
                                    this.componentManager.register("chartExportMenu", "chartExportMenu"),
                                    this.componentManager.register("tooltip", "tooltip"),
                                    this.componentManager.register("mouseEventDetector", "mouseEventDetector");
                            }),
                            (e.prototype.addDataRatios = function (t) {
                                this.dataProcessor.addDataRatiosForCoordinateType(this.chartType, t, !1);
                            }),
                            e
                    );
                })(r.default);
            e.default = u;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(195),
                r = n(o),
                a = i(297),
                s = n(a),
                u = i(154),
                l = n(u),
                h = (function (t) {
                    function e(i, n, o) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e),
                            (o.tooltip = o.tooltip || {}),
                        o.tooltip.align || (o.tooltip.align = l.default.TOOLTIP_DEFAULT_ALIGN_OPTION),
                            (o.tooltip.grouped = !1);
                        var r = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, { rawData: i, theme: n, options: o, hasAxes: !0, isVertical: !0 }));
                        return (r.className = "tui-heatmap-chart"), r;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype._addComponents = function () {
                                var t = this.theme.series[this.chartType],
                                    e = new s.default(t.startColor, t.endColor);
                                this._addComponentsForAxisType({
                                    axis: [{ name: "yAxis", isVertical: !0 }, { name: "xAxis" }],
                                    legend: { classType: "spectrumLegend", additionalParams: { colorSpectrum: e } },
                                    series: [{ name: "heatmapSeries", data: { colorSpectrum: e } }],
                                    tooltip: !0,
                                    mouseEventDetector: !0,
                                });
                            }),
                            (e.prototype.getScaleOption = function () {
                                return { legend: !0 };
                            }),
                            (e.prototype.addDataRatios = function (t) {
                                this.dataProcessor.addDataRatios(t.legend, null, this.chartType);
                            }),
                            (e.prototype.addComponents = function () {
                                var t = this.theme.series[this.chartType],
                                    e = new s.default(t.startColor, t.endColor);
                                this.componentManager.register("title", "title"),
                                    this.componentManager.register("legend", "spectrumLegend", { colorSpectrum: e }),
                                    this.componentManager.register("heatmapSeries", "heatmapSeries", { colorSpectrum: e }),
                                    this.componentManager.register("xAxis", "axis"),
                                    this.componentManager.register("yAxis", "axis"),
                                    this.componentManager.register("chartExportMenu", "chartExportMenu"),
                                    this.componentManager.register("tooltip", "tooltip", { colorSpectrum: e }),
                                    this.componentManager.register("mouseEventDetector", "mouseEventDetector");
                            }),
                            e
                    );
                })(r.default);
            e.default = h;
        },
        function (t, e, i) {
            "use strict";
            e.__esModule = !0;
            var n,
                o = (n = i(298)) && n.__esModule ? n : { default: n },
                r = (function () {
                    function t(e, i) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.start = o.default.colorNameToHex(e)),
                            (this.startRGB = o.default.hexToRGB(this.start)),
                            (this.end = o.default.colorNameToHex(i));
                        var n = o.default.hexToRGB(this.end);
                        (this.distances = this._makeDistances(this.startRGB, n)), (this.colorMap = {});
                    }
                    return (
                        (t.prototype._makeDistances = function (t, e) {
                            return t.map(function (t, i) {
                                return e[i] - t;
                            });
                        }),
                            (t.prototype.getColor = function (t) {
                                var e = this.colorMap[t];
                                if (!e) {
                                    var i = this.distances,
                                        n = this.startRGB.map(function (e, n) {
                                            return e + parseInt(i[n] * t, 10);
                                        });
                                    e = o.default.rgbToHEX.apply(
                                        o.default,
                                        (function t(e) {
                                            if (Array.isArray(e)) {
                                                for (var i = 0, n = Array(e.length); i < e.length; i++) n[i] = e[i];
                                                return n;
                                            }
                                            return Array.from(e);
                                        })(n)
                                    );
                                }
                                return e || null;
                            }),
                            t
                    );
                })();
            e.default = r;
        },
        function (t, e) {
            "use strict";
            e.__esModule = !0;
            var i = /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i,
                n = {
                    aliceblue: "#f0f8ff",
                    antiquewhite: "#faebd7",
                    aqua: "#00ffff",
                    aquamarine: "#7fffd4",
                    azure: "#f0ffff",
                    beige: "#f5f5dc",
                    bisque: "#ffe4c4",
                    black: "#000000",
                    blanchedalmond: "#ffebcd",
                    blue: "#0000ff",
                    blueviolet: "#8a2be2",
                    brown: "#a52a2a",
                    burlywood: "#deb887",
                    cadetblue: "#5f9ea0",
                    chartreuse: "#7fff00",
                    chocolate: "#d2691e",
                    coral: "#ff7f50",
                    cornflowerblue: "#6495ed",
                    cornsilk: "#fff8dc",
                    crimson: "#dc143c",
                    cyan: "#00ffff",
                    darkblue: "#00008b",
                    darkcyan: "#008b8b",
                    darkgoldenrod: "#b8860b",
                    darkgray: "#a9a9a9",
                    darkgreen: "#006400",
                    darkkhaki: "#bdb76b",
                    darkmagenta: "#8b008b",
                    darkolivegreen: "#556b2f",
                    darkorange: "#ff8c00",
                    darkorchid: "#9932cc",
                    darkred: "#8b0000",
                    darksalmon: "#e9967a",
                    darkseagreen: "#8fbc8f",
                    darkslateblue: "#483d8b",
                    darkslategray: "#2f4f4f",
                    darkturquoise: "#00ced1",
                    darkviolet: "#9400d3",
                    deeppink: "#ff1493",
                    deepskyblue: "#00bfff",
                    dimgray: "#696969",
                    dodgerblue: "#1e90ff",
                    firebrick: "#b22222",
                    floralwhite: "#fffaf0",
                    forestgreen: "#228b22",
                    fuchsia: "#ff00ff",
                    gainsboro: "#dcdcdc",
                    ghostwhite: "#f8f8ff",
                    gold: "#ffd700",
                    goldenrod: "#daa520",
                    gray: "#808080",
                    green: "#008000",
                    greenyellow: "#adff2f",
                    honeydew: "#f0fff0",
                    hotpink: "#ff69b4",
                    "indianred ": "#cd5c5c",
                    indigo: "#4b0082",
                    ivory: "#fffff0",
                    khaki: "#f0e68c",
                    lavender: "#e6e6fa",
                    lavenderblush: "#fff0f5",
                    lawngreen: "#7cfc00",
                    lemonchiffon: "#fffacd",
                    lightblue: "#add8e6",
                    lightcoral: "#f08080",
                    lightcyan: "#e0ffff",
                    lightgoldenrodyellow: "#fafad2",
                    lightgrey: "#d3d3d3",
                    lightgreen: "#90ee90",
                    lightpink: "#ffb6c1",
                    lightsalmon: "#ffa07a",
                    lightseagreen: "#20b2aa",
                    lightskyblue: "#87cefa",
                    lightslategray: "#778899",
                    lightsteelblue: "#b0c4de",
                    lightyellow: "#ffffe0",
                    lime: "#00ff00",
                    limegreen: "#32cd32",
                    linen: "#faf0e6",
                    magenta: "#ff00ff",
                    maroon: "#800000",
                    mediumaquamarine: "#66cdaa",
                    mediumblue: "#0000cd",
                    mediumorchid: "#ba55d3",
                    mediumpurple: "#9370d8",
                    mediumseagreen: "#3cb371",
                    mediumslateblue: "#7b68ee",
                    mediumspringgreen: "#00fa9a",
                    mediumturquoise: "#48d1cc",
                    mediumvioletred: "#c71585",
                    midnightblue: "#191970",
                    mintcream: "#f5fffa",
                    mistyrose: "#ffe4e1",
                    moccasin: "#ffe4b5",
                    navajowhite: "#ffdead",
                    navy: "#000080",
                    oldlace: "#fdf5e6",
                    olive: "#808000",
                    olivedrab: "#6b8e23",
                    orange: "#ffa500",
                    orangered: "#ff4500",
                    orchid: "#da70d6",
                    palegoldenrod: "#eee8aa",
                    palegreen: "#98fb98",
                    paleturquoise: "#afeeee",
                    palevioletred: "#d87093",
                    papayawhip: "#ffefd5",
                    peachpuff: "#ffdab9",
                    peru: "#cd853f",
                    pink: "#ffc0cb",
                    plum: "#dda0dd",
                    powderblue: "#b0e0e6",
                    purple: "#800080",
                    red: "#ff0000",
                    rosybrown: "#bc8f8f",
                    royalblue: "#4169e1",
                    saddlebrown: "#8b4513",
                    salmon: "#fa8072",
                    sandybrown: "#f4a460",
                    seagreen: "#2e8b57",
                    seashell: "#fff5ee",
                    sienna: "#a0522d",
                    silver: "#c0c0c0",
                    skyblue: "#87ceeb",
                    slateblue: "#6a5acd",
                    slategray: "#708090",
                    snow: "#fffafa",
                    springgreen: "#00ff7f",
                    steelblue: "#4682b4",
                    tan: "#d2b48c",
                    teal: "#008080",
                    thistle: "#d8bfd8",
                    tomato: "#ff6347",
                    turquoise: "#40e0d0",
                    violet: "#ee82ee",
                    wheat: "#f5deb3",
                    white: "#ffffff",
                    whitesmoke: "#f5f5f5",
                    yellow: "#ffff00",
                    yellowgreen: "#9acd32",
                };
            e.default = {
                leadingZero: function (t, e) {
                    var i = "";
                    if (String(t).length > e) return String(t);
                    for (var n = 0; n < e - 1; n += 1) i += "0";
                    return (i + t).slice(-1 * e);
                },
                isValidRGB: function (t) {
                    return i.test(t);
                },
                hexToRGB: function (t) {
                    if (!this.isValidRGB(t)) return !1;
                    var e = parseInt((t = t.substring(1)).substr(0, 2), 16),
                        i = parseInt(t.substr(2, 2), 16),
                        n = parseInt(t.substr(4, 2), 16);
                    return [e, i, n];
                },
                rgbToHEX: function (t, e, i) {
                    var n = "#" + this.leadingZero(t.toString(16), 2) + this.leadingZero(e.toString(16), 2) + this.leadingZero(i.toString(16), 2);
                    return !!this.isValidRGB(n) && n;
                },
                colorNameToHex: function (t) {
                    return n[t.toLowerCase()] || t;
                },
            };
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(152),
                r = n(o),
                a = i(195),
                s = n(a),
                u = i(297),
                l = n(u),
                h = (function (t) {
                    function e(i, n, o) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e),
                            (o.tooltip = o.tooltip || {}),
                            (o.tooltip.grouped = !1);
                        var r = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, { rawData: i, theme: n, options: o, hasAxes: !1, isVertical: !0 }));
                        return (r.className = "tui-treemap-chart"), r;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype.addComponents = function () {
                                var t = this.theme.series[this.chartType],
                                    e = this.options.series.useColorValue,
                                    i = e ? new l.default(t.startColor, t.endColor) : null;
                                this.componentManager.register("title", "title"),
                                    this.componentManager.register("treemapSeries", "treemapSeries", { colorSpectrum: i }),
                                e && this.options.legend.visible && this.componentManager.register("legend", "spectrumLegend", { colorSpectrum: i }),
                                    this.componentManager.register("tooltip", "tooltip", Object.assign({ labelTheme: (0, r.default)(this.theme, "series", "label"), colorSpectrum: i })),
                                    this.componentManager.register("mouseEventDetector", "mouseEventDetector"),
                                    this.componentManager.register("chartExportMenu", "chartExportMenu");
                            }),
                            (e.prototype.getScaleOption = function () {
                                return { legend: !0 };
                            }),
                            (e.prototype.addDataRatios = function (t) {
                                this.dataProcessor.addDataRatiosForTreemapChart(t.legend, this.chartType);
                            }),
                            (e.prototype.onZoom = function (t) {
                                this.componentManager.render("zoom", null, { index: t });
                            }),
                            e
                    );
                })(s.default);
            e.default = h;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(195),
                r = n(o),
                a = i(185),
                s = n(a),
                u = i(301),
                l = n(u),
                h = i(302),
                c = n(h),
                p = i(297),
                d = n(p),
                f = (function (t) {
                    function e(i, n, o) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e),
                            (o.map = s.default.get(o.map)),
                            (o.tooltip = o.tooltip || {}),
                            (o.legend = o.legend || {});
                        var r = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, { rawData: i, theme: n, options: o, DataProcessor: c.default }));
                        return (r.className = "tui-map-chart"), r;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype.addComponents = function () {
                                var t = this.theme.series[this.chartType],
                                    e = new d.default(t.startColor, t.endColor);
                                (this.mapModel = new l.default(this.dataProcessor, this.options.map)),
                                    this.componentManager.register("mapSeries", "mapSeries", { mapModel: this.mapModel, colorSpectrum: e }),
                                    this.componentManager.register("title", "title"),
                                    this.componentManager.register("legend", "spectrumLegend", { colorSpectrum: e }),
                                    this.componentManager.register("tooltip", "tooltip", { mapModel: this.mapModel, colorSpectrum: e }),
                                    this.componentManager.register("zoom", "zoom"),
                                    this.componentManager.register("mouseEventDetector", "mapChartEventDetector");
                            }),
                            (e.prototype.setData = function () {
                                var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : null;
                                this.mapModel.clearMapData(), t.prototype.setData.call(this, e);
                            }),
                            (e.prototype.getScaleOption = function () {
                                return { legend: !0 };
                            }),
                            (e.prototype.addDataRatios = function (t) {
                                this.dataProcessor.addDataRatios(t.legend);
                            }),
                            e
                    );
                })(r.default);
            e.default = f;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(145),
                r = n(o),
                a = i(189),
                s = n(a),
                u = i(154),
                l = n(u),
                h = i(158),
                c = n(h),
                p = (function () {
                    function t(e, i) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, t),
                            (this.commandFuncMap = {
                                M: this._makeCoordinate.bind(this),
                                m: this._makeCoordinateFromRelativeCoordinate.bind(this),
                                L: this._makeCoordinate.bind(this),
                                l: this._makeCoordinateFromRelativeCoordinate.bind(this),
                                H: this._makeXCoordinate.bind(this),
                                h: this._makeXCoordinateFroRelativeCoordinate.bind(this),
                                V: this._makeYCoordinate.bind(this),
                                v: this._makeYCoordinateFromRelativeCoordinate.bind(this),
                            }),
                            (this.ignoreCommandMap = { Z: !0, z: !0 }),
                            (this.mapDimension = null),
                            (this.dataProcessor = e),
                            (this.rawMapData = i),
                            (this.mapData = null);
                    }
                    return (
                        (t.prototype._splitCoordinate = function (t) {
                            var e = t.split(","),
                                i = { x: parseFloat(e[0]) };
                            return e[1] && (i.y = parseFloat(e[1])), i;
                        }),
                            (t.prototype._makeCoordinate = function (t) {
                                return this._splitCoordinate(t);
                            }),
                            (t.prototype._makeCoordinateFromRelativeCoordinate = function (t, e) {
                                var i = this._splitCoordinate(t);
                                return { x: i.x + e.x, y: i.y + e.y };
                            }),
                            (t.prototype._makeXCoordinate = function (t) {
                                return { x: this._splitCoordinate(t).x };
                            }),
                            (t.prototype._makeXCoordinateFroRelativeCoordinate = function (t, e) {
                                return { x: this._splitCoordinate(t).x + e.x };
                            }),
                            (t.prototype._makeYCoordinate = function (t) {
                                return { y: this._splitCoordinate(t).x };
                            }),
                            (t.prototype._makeYCoordinateFromRelativeCoordinate = function (t, e) {
                                return { y: this._splitCoordinate(t).x + e.y };
                            }),
                            (t.prototype._splitPath = function (t) {
                                for (var e = t.length, i = [], n = "", o = void 0, r = 0; r < e; r += 1) {
                                    var a = t.charAt(r);
                                    this.commandFuncMap[a] ? (o && n && i.push({ type: o, coordinate: n }), (o = a), (n = "")) : this.ignoreCommandMap[a] || (n += a);
                                }
                                return this._addCommandPath(i, { commandType: o, coordinate: n }), i;
                            }),
                            (t.prototype._addCommandPath = function (t) {
                                var e = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
                                    i = e.commandType,
                                    n = e.coordinate;
                                i && n && t.push({ type: i, coordinate: n });
                            }),
                            (t.prototype._makeCoordinatesFromPath = function (t) {
                                var e = this,
                                    i = this._splitPath(t),
                                    n = { x: 0, y: 0 };
                                return i.map(function (t) {
                                    var i = (0, e.commandFuncMap[t.type])(t.coordinate, n);
                                    return Object.assign(n, i), i;
                                });
                            }),
                            (t.prototype._findBoundFromCoordinates = function (t) {
                                var e = (0, s.default)(t, "x").filter(function (t) {
                                        return !(0, r.default)(t);
                                    }),
                                    i = (0, s.default)(t, "y").filter(function (t) {
                                        return !(0, r.default)(t);
                                    }),
                                    n = c.default.max(e),
                                    o = c.default.min(e),
                                    a = c.default.max(i),
                                    u = c.default.min(i);
                                return { dimension: { width: n - o, height: a - u }, position: { left: o, top: u } };
                            }),
                            (t.prototype._makeLabelPosition = function (t, e) {
                                return (e = e || l.default.MAP_CHART_LABEL_DEFAULT_POSITION_RATIO), { left: t.position.left + t.dimension.width * e.x, top: t.position.top + t.dimension.height * e.y };
                            }),
                            (t.prototype._createMapData = function (t) {
                                var e = this;
                                return t.map(function (t) {
                                    var i = e._makeCoordinatesFromPath(t.path),
                                        n = e._findBoundFromCoordinates(i),
                                        o = e.dataProcessor.getValueMapDatum(t.code),
                                        r = void 0,
                                        a = void 0,
                                        s = void 0,
                                        u = void 0;
                                    o && ((s = o.label), (u = o.ratio), (r = o.name || t.name), (a = o.labelCoordinate || t.labelCoordinate));
                                    var l = { code: t.code, name: r, path: t.path, bound: n, labelPosition: e._makeLabelPosition(n, a) };
                                    return s && (l.label = s), u >= 0 && (l.ratio = u), l;
                                });
                            }),
                            (t.prototype.clearMapData = function () {
                                this.mapData = null;
                            }),
                            (t.prototype.getMapData = function () {
                                return this.mapData || (this.mapData = this._createMapData(this.rawMapData)), this.mapData;
                            }),
                            (t.prototype.getDatum = function (t) {
                                return this.getMapData()[t];
                            }),
                            (t.prototype.getLabelData = function (t) {
                                var e = this;
                                return this.getMapData()
                                    .filter(function (t) {
                                        return e.dataProcessor.getValueMapDatum(t.code);
                                    })
                                    .map(function (e) {
                                        return { name: e.name, labelPosition: { left: e.labelPosition.left * t, top: e.labelPosition.top * t } };
                                    });
                            }),
                            (t.prototype._makeMapDimension = function () {
                                var t = this.getMapData(),
                                    e = t.map(function (t) {
                                        return t.bound.position.left;
                                    }),
                                    i = t.map(function (t) {
                                        return t.bound.position.left + t.bound.dimension.width;
                                    }),
                                    n = t.map(function (t) {
                                        return t.bound.position.top;
                                    }),
                                    o = t.map(function (t) {
                                        return t.bound.position.top + t.bound.dimension.height;
                                    });
                                return { width: c.default.max(i) - c.default.min(e), height: c.default.max(o) - c.default.min(n) };
                            }),
                            (t.prototype.getMapDimension = function () {
                                return this.mapDimension || (this.mapDimension = this._makeMapDimension()), this.mapDimension;
                            }),
                            t
                    );
                })();
            e.default = p;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(189),
                r = n(o),
                a = i(262),
                s = n(a),
                u = i(148),
                l = n(u),
                h = (function (t) {
                    function e(i, n, o) {
                        !(function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e);
                        var r = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this));
                        return (r.rawData = i), (r.options = o), r;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype.initData = function (t) {
                                (this.rawData = t), (this.valueMap = null);
                            }),
                            (e.prototype._makeValueMap = function () {
                                var t = this.rawData.series.map,
                                    e = {},
                                    i = this._findFormatFunctions();
                                return (
                                    t.forEach(function (t) {
                                        var n = { value: t.data, label: l.default.formatValue({ value: t.data, formatFunctions: i, chartType: "map", areaType: "series" }) };
                                        t.name && (n.name = t.name), t.labelCoordinate && (n.labelCoordinate = t.labelCoordinate), (e[t.code] = n);
                                    }),
                                        e
                                );
                            }),
                            (e.prototype.getValueMap = function () {
                                return this.valueMap || (this.valueMap = this._makeValueMap()), this.valueMap;
                            }),
                            (e.prototype.getValues = function () {
                                return (0, r.default)(this.getValueMap(), "value");
                            }),
                            (e.prototype.getCurrentData = function () {
                                return this.rawData;
                            }),
                            (e.prototype.getValueMapDatum = function (t) {
                                return this.getValueMap()[t];
                            }),
                            (e.prototype.addDataRatios = function (t) {
                                var e = t.min,
                                    i = t.max - e;
                                Object.values(this.getValueMap()).forEach(function (t) {
                                    t.ratio = (t.value - e) / i;
                                });
                            }),
                            (e.prototype.createBaseValuesForLimit = function () {
                                return this.getValues();
                            }),
                            (e.prototype.getLegendVisibility = function () {
                                return null;
                            }),
                            e
                    );
                })(s.default);
            e.default = h;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(195),
                r = n(o),
                a = i(244),
                s = n(a),
                u = (function (t) {
                    function e(i, n, o) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e),
                        o.tooltip && (o.tooltip.grouped = !1);
                        var r = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, { rawData: i, theme: n, options: o, hasAxes: !0, isVertical: !0 }));
                        return (r.className = "tui-radial-chart"), (r.Series = s.default), r;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype.addComponents = function () {
                                this.componentManager.register("title", "title"),
                                    this.componentManager.register("legend", "legend"),
                                    this.componentManager.register("plot", "radialPlot"),
                                    this.componentManager.register("radialSeries", "radialSeries"),
                                    this.componentManager.register("chartExportMenu", "chartExportMenu"),
                                    this.componentManager.register("tooltip", "tooltip"),
                                    this.componentManager.register("mouseEventDetector", "mouseEventDetector");
                            }),
                            (e.prototype.addDataRatios = function (t) {
                                this.dataProcessor.addDataRatios(t[this.chartType], null, this.chartType);
                            }),
                            (e.prototype.getScaleOption = function () {
                                return { yAxis: {} };
                            }),
                            e
                    );
                })(r.default);
            e.default = u;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(195),
                r = n(o),
                a = i(181),
                s = n(a),
                u = (function (t) {
                    function e(i, n, o) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e),
                            s.default.appendOutliersToSeriesData(i);
                        var r = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, { rawData: i, theme: n, options: o, hasAxes: !0, isVertical: !0 }));
                        return (r.className = "tui-boxplot-chart"), r;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype.addComponents = function () {
                                this.componentManager.register("title", "title"),
                                    this.componentManager.register("plot", "plot"),
                                    this.componentManager.register("legend", "legend"),
                                    this.componentManager.register("boxplotSeries", "boxplotSeries"),
                                    this.componentManager.register("yAxis", "axis"),
                                    this.componentManager.register("xAxis", "axis"),
                                    this.componentManager.register("chartExportMenu", "chartExportMenu"),
                                    this.componentManager.register("tooltip", "tooltip"),
                                    this.componentManager.register("mouseEventDetector", "mouseEventDetector");
                            }),
                            (e.prototype.getScaleOption = function () {
                                return { yAxis: !0 };
                            }),
                            (e.prototype.onChangeCheckedLegends = function (t) {
                                var e = void 0;
                                this.hasRightYAxis && (e = { optionChartTypes: ["boxplot", "boxplot"] }), r.default.prototype.onChangeCheckedLegends.call(this, t, null, e);
                            }),
                            (e.prototype.addDataRatios = function (t) {
                                var e = this.options.series,
                                    i = void 0 === e ? {} : e,
                                    n = this.chartType,
                                    o = (i[n] || i).stack;
                                this.dataProcessor.addDataRatios(t[n], o, n);
                            }),
                            e
                    );
                })(r.default);
            e.default = u;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            e.__esModule = !0;
            var o = i(195),
                r = n(o),
                a = i(181),
                s = n(a),
                u = (function (t) {
                    function e(i, n, o) {
                        (function t(e, i) {
                            if (!(e instanceof i)) throw TypeError("Cannot call a class as a function");
                        })(this, e),
                            s.default._makeRawSeriesDataForBulletChart(i);
                        var r = (function t(e, i) {
                            if (!e) throw ReferenceError("this hasn't been initialised - super() hasn't been called");
                            return i && ("object" == typeof i || "function" == typeof i) ? i : e;
                        })(this, t.call(this, { rawData: i, theme: n, options: o, hasAxes: !0, isVertical: !!o.series.vertical }));
                        return (r.className = "tui-bullet-chart"), r;
                    }
                    return (
                        (function t(e, i) {
                            if ("function" != typeof i && null !== i) throw TypeError("Super expression must either be null or a function, not " + typeof i);
                            (e.prototype = Object.create(i && i.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })), i && (Object.setPrototypeOf ? Object.setPrototypeOf(e, i) : (e.__proto__ = i));
                        })(e, t),
                            (e.prototype.addComponents = function () {
                                this.componentManager.register("title", "title"),
                                    this.componentManager.register("plot", "plot"),
                                    this.componentManager.register("legend", "legend"),
                                    this.componentManager.register("bulletSeries", "bulletSeries"),
                                    this.componentManager.register("yAxis", "axis"),
                                    this.componentManager.register("xAxis", "axis"),
                                    this.componentManager.register("chartExportMenu", "chartExportMenu", { chartType: "bullet" }),
                                    this.componentManager.register("tooltip", "tooltip"),
                                    this.componentManager.register("mouseEventDetector", "mouseEventDetector");
                            }),
                            (e.prototype.getScaleOption = function () {
                                return this.isVertical ? { yAxis: !0 } : { xAxis: !0 };
                            }),
                            (e.prototype.addDataRatios = function (t) {
                                this.dataProcessor.addDataRatios(t[this.chartType], null, this.chartType);
                            }),
                            e
                    );
                })(r.default);
            e.default = u;
        },
        function (t, e, i) {
            "use strict";
            function n(t) {
                return t && t.__esModule ? t : { default: t };
            }
            var o = i(154),
                r = n(o),
                a = i(183),
                s = n(a),
                u = i(184),
                l = n(u);
            s.default.register(r.default.DEFAULT_THEME_NAME, l.default);
        },
        function (t, e) {},
    ]);
});
