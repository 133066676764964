/**
 * outcome page (TODO: unify all of the center submission JS to one file)
 */
/* define $ as jQuery just in case */
(function ($) {
    /* doc ready */
    $(function () {

    });

    /* save the data posted in the center data submission form */
    window.save_data = function (form, valid = true) {
        /* do not save the data if the input is not valid */
        // if (!valid) {
        //     return false;
        // } 

        /* clear the local (browser) storage */
        localStorage.clear();

        /* set vars */
        var action = form.attr('action');
        console.log('save_data (data.js): ' + action);
        /* store form data to DB w/ AJAX on click (filter and sanitize values, etc. in model) */
        $.ajax({
            url: action,
            type: 'POST',
            data: form.serialize(),
            success: function (res) {

                console.log(res);
                if (res.success) {
                    window.location.replace(res.redirect);
                    return true;
                } else {
                    return false;
                }
            },
            error: (res) => {
                console.log('Error: ', res);
            }
        });
    }

    window.save_data_2 = function (form, valid = true) {
        /* do not save the data if the input is not valid */
        // if (!valid) {
        //     return false;
        // } 

        /* clear the local (browser) storage */
        localStorage.clear();

        /* set vars */
        var action = form.attr('action');
        console.log('save_data_2 ' + action);
        /* store form data to DB w/ AJAX on click (filter and sanitize values, etc. in model) */
        $.ajax({
            url: action,
            type: 'POST',
            data: form.serialize(),
            success: function (res) {
                console.log(res);
                if (res.status == 'success') {

                    console.log(res);
                    //window.location.replace(res.redirect);
                    return true;
                } else {
                    return false;
                }
            },
            error: (err) => {
                console.log('Error: ', err);
                return false;
            }
        });
    }
})(jQuery);
