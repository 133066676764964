$(document).ready(function () {
	var lastScrollTop = 0;
	var firealready = 0;
    $(window).scroll(function () {
		var st = $(this).scrollTop();

		if (st > lastScrollTop){
	    /* add 10 more pixel to ensure load more scroll down */
	   // console.log('left: '+$(document).height());
	   // var total = $(window).scrollTop() + $(window).height();
	   // console.log('scrolltop: '+total);

	    if ($(document).height() <= Math.ceil($(window).scrollTop() + $(window).height())){

            if ($('.loadmore').length) {
	            // $('.loading-overlay').fadeIn();
                $.get($('.loadmore').attr('href'), function (res) {
                    var $data = res;
                    $('.loadmore').remove();
	                $('.holder').append($data); 
	                // $('.loading-overlay').fadeOut();
                });
            }
          }
        }
        lastScrollTop = st;
    });
    });
