$(document).ready(function () {
    $(document).on('keyup', '.nces-search', function () {
        var $el = $(this);
        var keyword = $(this).val();
        $.get('/nces/search/' + keyword, function (results) {
            if (!results.empty) {
                $el.next('.nces-results').html(results);
                //console.log(results);
            } else {
                $el.next('.nces-results').html("");
            }
        });
    });

    $(document).on('click', '.nces-result', function () {
        var $el = $(this);
        var name = $el.data('schoolname');
        var address = $el.data('schooladdress');
        var city = $el.data('schoolcity');
        var state = $el.data('schoolstate');
        var zip = $el.data('schoolzip');
        var phone = $el.data('schoolphone');

        $el.closest('.module').find('.center-name').val(name);
        $el.closest('.module').find('.center-address').val(address);
        $el.closest('.module').find('.center-city').val(city);
        // achan 05-27-2015  for BIE
        if ($el.closest('.module').find('.center-state').val() == '5') {
            $el.closest('.module').find('.center-bie-state').val(state);
        } else {
            $el.closest('.module').find('.center-state').val(state);
        }
        //$el.closest('.module').find('.center-state').val(state);
        $el.closest('.module').find('.center-zip').val(zip);
        $el.closest('.module').find('.center-zip-hidden').val(zip);
        $el.closest('.module').next().find('.center-phone').val(phone).formatter({
            'pattern': '{{999}}-{{999}}-{{9999}}'
        }).resetPattern();

        $(this).closest('.nces-results').html("");
        $('.alpha.module').find('.button').prop('disabled', false);
    });

});
