/* define $ as jQuery just in case */
(function ($) {

    /* toggle / accordion events - my custom plugin */
    $.fn.accordion = function () {

        /* set vars */
        var accordion = this;
        var groups = accordion.find('.toggle_group');
        var boxes = accordion.find('.toggle_target');
        var controls = accordion.find('.toggle_trigger');
        var active_controls = controls.find('.current');
        var active_control = active_controls.first().length > 0 ? active_controls.first() : accordion.find('.toggle_trigger:eq(0)');
        var speed = 400;

        /* hide the accordion and close all the boxes */
        accordion.removeClass('visible').addClass('hidden').removeAttr('style');
        boxes.removeClass('visible').addClass('hidden').removeAttr('style');

        /* on page load - show the accordion */
        $(window).load(function () {

            /* hide the collapse icon, unless the group is marked open, then hide the expander icon */
            $.each(groups, function (i, group) {
                if ($(groups[i]).hasClass('open')) {
                    $(groups[i]).find('.expand').removeClass('visible').addClass('hidden').removeAttr('style');
                    $(groups[i]).find('.toggle_target').removeClass('hidden').addClass('visible').removeAttr('style');
                } else {
                    $(groups[i]).find('.collapse').removeClass('visible').addClass('hidden').removeAttr('style');
                    $(groups[i]).find('.toggle_target').removeClass('visible').addClass('hidden').removeAttr('style');
                }
            });

            /* show the accordion when everything is loaded */
            accordion.removeClass('hidden').addClass('visible').removeAttr('style');

            /* hide elt question if ELP is No when load */
            if ($('.has-elt option:selected').text() == 'No') {
                $(".elt_questions").addClass('hidden');
            }

        });

        /* accordion control click action */
        accordion.on('click', '.toggle_trigger', function (e) {
            var parent_el = $(this).closest('.toggle_group');
            var target = parent_el.find('.toggle_target');
            var icon = parent_el.find('.icon');

            if (parent_el.hasClass('open')) {
                /* if open, collapse it */
                parent_el.removeClass('open');
                parent_el.find('.collapse').removeClass('visible').addClass('hidden').removeAttr('style'); /* hide the collapse icon */
                parent_el.find('.expand').removeClass('hidden').addClass('visible').removeAttr('style'); /* show the expand icon */

                /* slide up the target box */
                target.slideUp(speed);

                /* change the icon */
                $(icon).html('<i class="icon-chevron-down"></i>');

            } else /* otherwise, expand it */ {

                /* open this box */
                parent_el.addClass('open');
                parent_el.find('.expand').removeClass('visible').addClass('hidden').removeAttr('style'); /* hide the expand icon */
                parent_el.find('.collapse').removeClass('hidden').addClass('visible').removeAttr('style'); /* show the collapse icon */

                /* slide down the target box */
                target.slideDown(speed);

                /* change the icon */
                $(icon).html('<i class="icon-chevron-up"></i>');
            }
            e.preventDefault();
        });
    }

    /* doc ready */
    $(function () {

        function checkalert() {
            alert("This data is currently unavailable.");
        }
        
        function updateAccordion(accordion_button) {
            let accordion_expanded = accordion_button.attr('aria-expanded') == 'true' ? true : false;
            let accordion_panel = $('#' +accordion_button.attr('aria-controls'));
            let icon = accordion_button.find('.icon');
            if(accordion_expanded) {
                // change button status to false expanded
                accordion_button.attr('aria-expanded', 'false');
                
                // set accordion panel to hidden
                accordion_panel.attr('aria-hidden', 'true');
                
                
                $(icon).html('<i class="icon-chevron-down"></i>');
                accordion_panel.slideUp('slow');
            }
            else {
                // change button status to false expanded
                accordion_button.attr('aria-expanded', 'true');

                // set accordion panel to visible
                accordion_panel.attr('aria-hidden', 'false');
                

                $(icon).html('<i class="icon-chevron-up"></i>');
                accordion_panel.slideDown('fast');
            }
        }
        
        var dataavailable = function () {
            var overlay = '<div class="overlay"><div class="confirmation-container"><h1>Data Unavailable</h1> <p>2015 program data is not yet available.</p><button class="btn">Ok</button></div></div>';
            $(overlay).appendTo('body');
            $('.overlay button').click(function (e) {
                e.preventDefault();
                $('.overlay').fadeOut('slow');
                $('.overlay').remove();
            });
        };

        /**
         *  To change challenge question
         */

        $(document).on('click', '.changeChallegeQ', function (e) {
            e.preventDefault();
            qid = $(this).data('qid');

            if (qid == 0) {
                $('.challengequestion').children('p[data-qid=0]').addClass('hidden').removeClass('show');
                $('.challengequestion').children('p[data-qid=1]').addClass('show').removeClass('hidden');
                $(this).data('qid', 1);
                $('.challengequestion').children('input').data('qid', 1);
                $('.challengequestion').children('input').val(1);
            } else {
                $('.challengequestion').children('p[data-qid=1]').addClass('hidden').removeClass('show');
                $('.challengequestion').children('p[data-qid=0]').addClass('show').removeClass('hidden');
                $(this).data('qid', 0);
                $('.challengequestion').children('input').data('qid', 0);
                $('.challengequestion').children('input').val(0);
            }
        });


        /**
         *  Select challenge questions with update
         */
        $(document).on('change', '.challenge_question1', function (e) {
            e.preventDefault();
            /* reset disabled select item */
            var disabledSelect = $('.challenge_question2 option[disabled]').val();
            var selectedCquestion = $(this).find(":selected").val();
            $('.challenge_question2 option[value=' + disabledSelect + ']').attr('disabled', false);
            $('.challenge_question2 option[value=' + selectedCquestion + ']').attr('disabled', true);
        });

        $(document).on('change', '.challenge_question2', function (e) {
            e.preventDefault();
            /* reset disabled select item */
            var disabledSelect = $('.challenge_question1 option[disabled]').val();
            var selectedCquestion = $(this).find(":selected").val();
            $('.challenge_question1 option[value=' + disabledSelect + ']').attr('disabled', false);
            $('.challenge_question1 option[value=' + selectedCquestion + ']').attr('disabled', true);
        });

        function emailIsValid(email) {
            var re = /\S+@\S+\.\S+/;
            return re.test(email);
        }

        $(".email").keyup(function (e) {
            e.preventDefault();
            if (!emailIsValid($(this).val())) {
                $('.login-error').addClass('hidden').removeClass('show');
                return;
            }

            $.ajax({
                url: '/ajaxpub/checkusername',
                type: 'POST',
                data: $('.forgot').serialize(),
                success: function (res) {
                    $('.login-error').addClass('hidden').removeClass('show');
                    if (res == 'Invalid email') {
                        $('.login-error').addClass('show').removeClass('hidden');
                        $('.login-error').text('Email not found');
                        $(".challenge").addClass('hidden').removeClass('show');
                    } else {
                        $.ajax({
                            url: '/ajaxpub/challengequestion',
                            type: 'POST',
                            data: $('.forgot').serialize(),
                            complete: function (res) {
                                if ((res.responseText != "<p>Your challenge questions are not set in 21APR, please contact your SEA to reset your password.</p>") && (res.responseText.length > 0)) {
                                    $('.challengequestion').html(res.responseText);
                                    $(".challenge").removeClass("hidden");
                                } else {
                                    $('.login-error').addClass('show').removeClass('hidden');
                                    $(".challenge").addClass('hidden').removeClass('show');
                                    $('.login-error').html(res.responseText);
                                }
                            }
                        });
                    }
                }
            });

        });

        /**
         * public ajax
         */
        $(".helpState").change(function () {
            $.ajax({
                url: '/ajaxpub/find_state_sea_usermgr',
                type: 'POST',
                data: $('.help').serialize(),
                success: function (res) {
                    $('.help_state_info').html(res);
                }
            });

        });


        $('.forgot_email').click(function () {
            // todo query string fails when emails has format like z.y+z@thetactilegroup.com
            // https://stackoverflow.com/questions/6855624/plus-sign-in-query-string
            window.location.replace('/forgot?email=' + $('.issue_email').val());
        });


        $('.inactive_term_toggle').click(function () {
            $('.sea_questions').slideToggle();
            if ($('.inactive_term_toggle').hasClass('icon-chevron-up')) {
                $('.inactive_term_toggle').removeClass('icon-chevron-up').addClass('icon-chevron-down');
                $('label.ec_inactive_term_label').text('Inactive Terms');
                $('.sea_questions').addClass('collapsed');
            } else {
                $('.inactive_term_toggle').removeClass('icon-chevron-down').removeClass('collapse').addClass('icon-chevron-up');
                $('.sea_questions').removeClass('collapsed');
                $('label.ec_inactive_term_label').text('');
            }
            return false;
        });


        $('.center_details_toggle').click(function () {
            //fixme need to get id for center_details_toggle
            var el = $(this);
            var uid = el.data("uid");
            $('.center_details_more_' + uid).slideToggle();
            if (el.hasClass('icon-chevron-up')) {
                el.removeClass('icon-chevron-up').addClass('icon-chevron-down');
                el.addClass('collapsed');
            } else {
                el.removeClass('icon-chevron-down').removeClass('collapse').addClass('icon-chevron-up');
                el.removeClass('collapsed');
            }
            return false;
        });


        $('.award_question_toggle').click(function () {
            $('.sea_questions').slideToggle();
            if ($('.award_question_toggle').hasClass('icon-chevron-up')) {
                $('.award_question_toggle').removeClass('icon-chevron-up').addClass('icon-chevron-down');
                $('label.ec_award_label').text('New Awards');
                $('.sea_questions').addClass('collapsed');
            } else {
                $('.award_question_toggle').removeClass('icon-chevron-down').removeClass('collapse').addClass('icon-chevron-up');
                $('.sea_questions').removeClass('collapsed');
                $('label.ec_award_label').text('');
            }
            return false;
        });

        $('.cmethod_question_toggle').click(function () {
            $('.collect_method').slideToggle();
            if ($('.cmethod_question_toggle').hasClass('icon-chevron-up')) {
                $('.cmethod_question_toggle').removeClass('icon-chevron-up').addClass('icon-chevron-down').addClass('collapse');
                $('label.ec_method_label').text('Outcome Types');
                $('.collect_method').addClass('collapsed');
            } else {
                $('.cmethod_question_toggle').removeClass('icon-chevron-down').removeClass('collapse').addClass('icon-chevron-up');
                $('.collect_method').removeClass('collapsed');
                $('label.ec_method_label').text('');
            }
            return false;
        });

        $('.elt_question_toggle').click(function () {
            $('.elt').slideToggle();
            if ($('.elt_question_toggle').hasClass('icon-chevron-up')) {
                $('.elt_question_toggle').removeClass('icon-chevron-up').addClass('icon-chevron-down').addClass('collapse');
                $('label.elt_label').text('Expanded Learning Program');
                $('.elt').addClass('collapsed');
            } else {
                $('.elt_question_toggle').removeClass('icon-chevron-down').removeClass('collapse').addClass('icon-chevron-up');
                $('.elt').removeClass('collapsed');
                $('label.elt_label').text('');
            }
            return false;
        });

        $('.term_question_toggle').click(function () {
            $('.term_open_close').slideToggle();
            if ($('.term_question_toggle').hasClass('icon-chevron-up')) {
                $('.term_question_toggle').removeClass('icon-chevron-up').addClass('icon-chevron-down').addClass('collapse');
                $('label.term_open_close_label').text('Open/Close Dates by Term');
                $('.term_open_close').addClass('collapsed');
            } else {
                $('.term_question_toggle').removeClass('icon-chevron-down').removeClass('collapse').addClass('icon-chevron-up');
                $('.term_open_close').removeClass('collapsed');
                $('label.term_open_close_label').text('');
            }
            return false;
        });
        //zz
        $('.last_api_toggle').click(function () {
            $('.last_api').slideToggle();
            if ($('.last_api_toggle').hasClass('icon-chevron-up')) {
                $('.last_api_toggle').removeClass('icon-chevron-up').addClass('icon-chevron-down').addClass('collapse');
                $('label.last_api_label').text('API Access History');
                $('.view_last_api').addClass('collapsed');
            } else {
                $('.last_api_toggle').removeClass('icon-chevron-down').removeClass('collapse').addClass('icon-chevron-up');
                $('.last_api').removeClass('collapsed');
                $('label.last_api_label').text('');
            }
            return false;
        });

        $('.unlock_account').click(function () {
            window.location.replace('/unlockrequest?email=' + $('.issue_email').val());
        });

        $('.helpdeskform').click(function () {
            window.location.replace('/login?r=' + window.location.origin + '/helpdeskform/form');
        });

        // toggle in settings

        $('.toggle-container').click(function (e) {
            e.preventDefault();

            if (($(this).find('.switch-hidden')).hasClass('loginas')) {
                if (confirm('Are you sure you want to change the login as user flag?')) {
                    if ($(this).find('.toggle-switch').is(':checked')) {

                        $(this).find('.switch-hidden').val(0).trigger('keyup');
                        $(this).find('.toggle-switch').prop('checked', false);
                    } else if (!$(this).find('.toggle-switch').is(':checked')) {

                        $(this).find('.switch-hidden').val(1).trigger('keyup');
                        $(this).find('.toggle-switch').prop('checked', true);
                    }

                    $.ajax({
                        url: '/ajax/saveLoginas',
                        type: 'POST',
                        data: $('.loginas').serialize(),
                        success: function (res) {

                        }
                    });
                }
            } else {
                if ($(this).find('.toggle-switch').is(':checked')) {

                    $(this).find('.switch-hidden').val(0).trigger('keyup');
                    $(this).find('.toggle-switch').prop('checked', false);
                } else if (!$(this).find('.toggle-switch').is(':checked')) {

                    $(this).find('.switch-hidden').val(1).trigger('keyup');
                    $(this).find('.toggle-switch').prop('checked', true);
                }
            }

        });


        function valid_submit() {
            if (($('.email').val().length > 0) && ($('.c_answer').val().length > 0)) {
                $(".c_button").prop("disabled", false);
            } else {
                $(".c_button").prop("disabled", true);
            }
        }

        $(".email, .c_answer").keyup(function () {
            valid_submit();
        });

        $(".email, .c_answer").focusout(function () {
            valid_submit();
        });

        $(".password, .cpassword").focusout(function (e) {

            e.preventDefault();
            var re = /^.*(?=.{12,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*\+\-\=]).*$/;

            if (!re.test($('.password').val())) {

                $('.login-error p').html('The password you entered is invalid. The password must be at least 12 characters long, contain at least one digit (0-9), at least one lowercase letter (a-z), at least one uppercase letter (A-Z), and at least one special symbol (!@#$^&*+-=).  The password also cannot contain your first name, last name, username, or any variation of the word "password."');
                $('.login-error').addClass('show').removeClass('hidden');
                $('.button').addClass('disabled').attr('disabled', true);
            } else {

                if (($('.cpassword').val().length == 0)) {
                    // if confirm password is empty
                    $('.button').addClass('disabled').attr('disabled', true);
                } else if ($('.password').val() != $('.cpassword').val()) {
                    // if confirm password is not same as password
                    $('.button').addClass('disabled').attr('disabled', true);
                } else {
                    $('.login-error').removeClass('show').addClass('hidden');
                    $('.button').removeClass('disabled').attr('disabled', false);
                }
            }

        });


        $(".cpassword").keyup(function (e) {

            e.preventDefault();
            if (($('.cpassword').val().length == 0)) {
                $('.button').addClass('disabled').attr('disabled', true);
            }

            if ($('.password').val() != $('.cpassword').val()) {

                $('.login-error p').html('Confirm Password is not match with password');
                $('.login-error').addClass('show').removeClass('hidden');
                $('.button').addClass('disabled').attr('disabled', true);
            } else {
                $('.login-error').removeClass('show').addClass('hidden');
                $('.button').removeClass('disabled').attr('disabled', false);
            }

        });

        $('.changePassword').click(function (e) {
            e.preventDefault();
            $('.setting_password').slideToggle('slow');
            $(this).hide();
        });


        $('.changeChallenge').click(function (e) {
            e.preventDefault();
            $('.setting_challenge').slideToggle('slow');
            $(this).hide();
        });


        $('.generateNewAPI').click(function (e) {
            e.preventDefault();
            $('.setting_api').slideToggle('slow');
        });

        /* Activate account page*/
        function validate_submit() {
            var re = /^.*(?=.{12,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*\+\-\=]).*$/;
            /* only for activate page */
            if (($(document.body).prop("id") == 'main-activate_account-page') ||
                ($(document.body).prop("id") == 'main-reset_password_challenge-page')) {
                if ((re.test($('.password').val()))
                    && ($('.password').val().length > 0)
                    && ($('.cpassword').val().length > 0)
                    && ($('.challenge_question1 option:selected').val() > 0)
                    && ($('.challenge_question_answer1').val().length > 0)
                    && ($('.challenge_question2 option:selected').val() > 0)
                    && ($('.challenge_question_answer2').val().length > 0)) {
                    $(".cp_button").prop("disabled", false);
                } else {
                    $(".cp_button").prop("disabled", true);

                }
            }
        }

        $(".password, .cpassword,.challenge_question_answer1, .challenge_question_answer2").keyup(function (e) {
            e.preventDefault();
            validate_submit();
        });

        $(".challenge_question1,.challenge_question2").change(function (e) {
            e.preventDefault();
            validate_submit();
        });

        $(".userState, .access_lvl").change(function (e) {
            e.preventDefault();
            // clear all selected grantees
            $('.grantee').prop('selectedIndex', -1);
            $(".additional-grantees").empty();
            // populate grantee dropdown list which based on state for the stategrantees
            $.ajax({
                url: '/ajax/GetStateGrantee',
                type: 'POST',
                data: $('.sign-up').serialize(),
                success: function (res) {
                    $('.stategrantees').html(res);
                }
            });

            // populate grantee dropdown list which based on state for clone
            $.ajax({
                url: '/ajax/GetGrantee',
                type: 'POST',
                data: $('.sign-up').serialize(),
                success: function (res) {
                    $('.grantee-clone').html(res);
                }
            });
        });

        $(".release_num").change(function (e) {
            e.preventDefault();
            window.location.href = './' + $(this).val();
        });

        /* add release notes */
        $(".add_release_notes").click(function (e) {
            e.preventDefault();
            $.ajax({
                url: '/ajax/AddReleaseNotes',
                type: 'POST',
                data: $('.release').serialize(),
                success: function (res) {
                    window.location.href = './' + res;
                }
            });

        });

        /* remove release notes */
        $(document).on('click', '.remove-release-edit', function (e) {
            e.preventDefault();
            var releaseid = $(this).data('releaseid');
            var releaseNum = $(this).data('releasenum');
            $.ajax({
                url: '/ajax/DeleteReleaseNotes',
                type: 'POST',
                data: $('.release').serialize() + '&releaseid=' + releaseid,
                success: function (res) {

                    if (res == "delete notes") {
                        $(this).parent('li').addClass('hidden');
                        window.location.href = './' + releaseNum;
                    }
                }
            });
        });


        /* video toggle */
        $('.video_toggle, #faqs-section .toggle_trigger').click(function () {
            var parent_el = $(this).closest('.toggle_group');
            var target = parent_el.find('.toggle_target');
            var icon = parent_el.find('.icon');
            var video = parent_el.find('video');

            if (parent_el.hasClass('close')) {
                parent_el.removeClass('close');
                target.slideDown('fast');
                video.slideDown('slow');
                $(icon).html('<i class="icon-chevron-up"></i>');
            } else {
                video.trigger('pause');
                video.slideUp('slow');
                parent_el.addClass('close');
                target.slideUp('slow');
                /* change the icon */
                $(icon).html('<i class="icon-chevron-down"></i>');
            }

        });

        $('.accordion-group .question').click(function () {
            let accordion = $(this).find('button');
            updateAccordion(accordion);
        });
        
        $('#main-ttt-page .expanded span.icon').hide();
        $('#main-ttt-page .expanded .toggle_trigger').hide();


        /* certification real time save comment*/
        $(".activities_comment, .staffings_comment, .participations_comment, .outcomes_comment").keyup(function (e) {
            e.preventDefault();

            //calculate countdown character
            if ($(this).val().length > 10000) {
                $(this).siblings('p').html('Your input exceed 10000 characters!');
            } else {
                $(this).siblings('p').html('You have <span class="countdown"></span> characters left.');
                $(this).siblings('p').find('span').html(10000 - $(this).val().length);
            }

            // call update comment
            $.ajax({
                url: '/main/update_comment',
                type: 'POST',
                data: $('#certification').serialize(),
                success: function (res) {

                }
            });
        });

        /* subgrantee impact statement save comment*/
        $(".impact_statement").keyup(function (e) {
            e.preventDefault();
            var csrf = $('.token').val();
            $.ajax({
                url: '/main/update_impact_statement',
                type: 'POST',
                csrf_token_name: csrf,
                data: $('.grantee_overview').serialize(),
                success: function (res) {

                }
            });
        });
        // TODO: change this to $(document).on('keyup focuseout'm, '.impact_statement', function() {}); I would, but I dont want to test
        /* save at the focus out */
        $(".impact_statement").focusout(function (e) {
            e.preventDefault();
            // call update comment
            var csrf = $('.token').val();
            $.ajax({
                url: '/main/update_impact_statement',
                type: 'POST',
                csrf_token_name: csrf,
                data: $('.grantee_overview').serialize(),
                success: function (res) {

                }
            });
        });


        /* helpdesk_issue character count*/
        $(".issuetext").keyup(function (e) {
            e.preventDefault();
            //calculate countdown character
            if ($(this).val().length > 10000) {
                $(".textarea-text .countdown_text").html('Your input exceed 10000 characters!');
            } else {
                $(".textarea-text .countdown_text").html('You have <span class="countdown"></span> characters left.');
                $(".textarea-text .countdown_text").find('span').html(10000 - $(this).val().length);
            }
        });


        /* make textarea be elastic*/
        if ($(".alpha").hasClass('rules')) {
            $(".activities_comment, .staffings_comment, .participations_comment, .outcomes_comment").elastic();
        }

        /* load how many characters left */
        if ($(".alpha").hasClass('rules') && !$(".alpha").hasClass('resetpassword')) {
            try {
                $(".activities_comment").siblings('p').find('span').html(10000 - $(".activities_comment").val().length);
                $(".staffings_comment").siblings('p').find('span').html(10000 - $(".staffings_comment").val().length);
                $(".participations_comment").siblings('p').find('span').html(10000 - $(".participations_comment").val().length);
                $(".outcomes_comment").siblings('p').find('span').html(10000 - $(".outcomes_comment").val().length);
            } catch (error) {
                console.log(error);
            }
        }

        /* make helpdesk issue textarea to elastic */
        if ($(".main-content").hasClass('helpdesk')) {
            $(".issuetext").elastic();
        }

        /* active and inactive line on help desk page */
        $('.helpdesk select').focus(function () {
            showlog('helpdesk select');
            $('.helpdesk li').removeClass('active');
            $(this).closest('li').addClass('active');
        });

        /* archive data by click */
        $('.bravoo.archive , .charliee.archive').click(function (e) {
            var el = $(this);
            var uid = el.data("uid");
            if ($('.showData.' + uid).hasClass('hidden')) {
                $('.showData.' + uid).slideToggle("slow", function () {
                    $('.showData.' + uid).removeClass('hidden').addClass('visiable');
                });
            } else {
                $('.showData.' + uid).slideToggle("slow", function () {
                    $('.showData.' + uid).removeClass('visiable').addClass('hidden');
                });
            }

        });

        /* records data by click */
        $('.alpha.records, .bravoo.records , .charliee.records').click(function (e) {
            var el = $(this);
            var uid = el.data("uid");
            if ($('.showData.' + uid).hasClass('hidden')) {
                $('.showData.' + uid).slideToggle("slow", function () {
                    $('.showData.' + uid).removeClass('hidden').addClass('visiable');
                });
            } else {
                $('.showData.' + uid).slideToggle("slow", function () {
                    $('.showData.' + uid).removeClass('visiable').addClass('hidden');
                });
            }

        });

        /* inactive term justification real time save*/
        $(".inactive_justification").change(function (e) {
            e.preventDefault();
            var center_id = $(this).parents('.center-list-item').data('center');
            var justification = $(this).find(':selected').val();
            var season = $(this).parents('.inactive-terms').find('.season');
            var term = $(this).data('term');

            if (justification == "0") {
                /* warning message */
                $('.inactive-msg').removeClass('hidden');
                var update_url = '/main/delete_inactive_justification?center_id=' + center_id
            } else {
                $('.inactive-msg').addClass('hidden');
                var update_url = '/main/update_inactive_justification?center_id=' + center_id + '&justification=' + justification;

            }


            // call update comment
            $.ajax({
                url: update_url,
                type: 'POST',
                data: $('#inactive_term').serialize(),
                success: function (res) {
                }
            });
        });

        $('.inactiveTeam').click(function (e) {
            e.preventDefault();

            /* set vars */
            var form = $(this).parents('form');
            var item = $(this).parents('.season');
            var center_id = $(this).parents('.center-list-item').data('center');
            var secondhalf = $(this).parents('.inactive-terms').children('.second-half');
            var justification = $(this).find(':selected').val();
            var aprbtn = $(this).parents('.center-list-item').children('.apr-data-col').children('.apr-btn');
            var inactiveMsg = $(this).parents('.inactive-terms').children('.second-half').children('.inactive-msg');
            var noterm = $(this).data('noterm');

            /* toggle disabled class on the APR data */
            $(aprbtn).toggleClass('disabled');


            var obj = '';

            /* toggle checkbox */
            if (item.hasClass('active')) {
                $(this).removeAttr('checked');
            } else {
                $(this).attr('checked', 'checked');
            }


            /* if checkbox is active, delete the inactive_term table, and toggle active*/
            if ($(item).hasClass('active')) {

                var update_url = '/main/delete_inactive_justification?center_id=' + center_id;

                var term = $(this).data('term');
                /* clear justification */
                $('select[name^="inactive_justication_' + center_id + '"] option:selected').attr("selected", null);
                $(secondhalf).find('textarea').val('');
                $(item).toggleClass('active');
                $(secondhalf).toggleClass('hidden');
                $(inactiveMsg).toggleClass('hidden');


                // call update comment
                $.ajax({
                    url: update_url,
                    type: 'POST',
                    data: $('#inactive_term').serialize(),
                    success: function (res) {
                    }
                });
            } else {
                /* Skip the validate_apr because no active term as of today date */
                if (noterm == 0) {
                    /* check APR data, if any apr data existing, error message pop and no data will be saved */
                    $.ajax({
                        url: '/main/validate_apr?center_id=' + center_id,
                        type: 'POST',
                        data: $('#inactive_term').serialize(),
                        success: function (res) {
                            obj = jQuery.parseJSON(res);
                            console.log('main', obj);
                            if (obj.status == 'true') {
                                $(item).toggleClass('active');
                                $(secondhalf).toggleClass('hidden');
                                $(inactiveMsg).toggleClass('hidden');

                                if (typeof (justification) === "undefined") {
                                    /* warning message */
                                    $(inactiveMsg).html('Please select your justification to set your center under the “Inactive term” option. <u>If justification is not selected, the "Inactive term" will not be set.</u>');
                                } else {
                                    if ($('.notification').hasClass('warning')) {
                                        $('.notification').removeClass('active').removeClass('warning');
                                        /* scroll to top on the error message*/
                                        $("html, body").animate({scrollTop: 0}, "slow");
                                    }
                                    var update_url = '/main/update_inactive_justification?center_id=' + center_id + '&justification=' + justification;

                                    // call update comment
                                    $.ajax({
                                        url: update_url,
                                        type: 'POST',
                                        data: $('#inactive_term').serialize(),
                                        success: function (res) {
                                        }
                                    });
                                }
                            } else {
                                var display_validate = '';
                                var count = 0;
                                Object.keys(obj).forEach(function (key) {
                                    if (obj[key] == '') {
                                        if (count == 0) {
                                            display_validate = display_validate + " " + key;
                                        } else {
                                            display_validate = display_validate + ", " + key;
                                        }
                                        count++;
                                    }
                                    showlog(key, obj[key]);
                                });
                                /* warning message */
                                $('.notification p').html('You already reported APR data for this center, please delete ' + display_validate + ' data before you click inactive term checkbox.');
                                $('.notification').addClass('active').addClass('warning');
                                $('.notification  span').html('Alert!');
                                /* scroll to top on the error message*/
                                $("html, body").animate({scrollTop: 0}, "slow");
                            }
                        }

                    }); /* end of validate_apr ajax*/
                } else {
                    /* toggle */
                    $(item).toggleClass('active');
                    $(secondhalf).toggleClass('hidden');
                    $(inactiveMsg).toggleClass('hidden');

                    if (typeof (justification) === "undefined") {
                        /* warning message */
                        $(inactiveMsg).html('Please select your justification to set your center under the “Inactive term” option. <u>If justification is not selected, the "Inactive term" will not be set.</u>');
                    } else {
                        if ($('.notification').hasClass('warning')) {
                            $('.notification').removeClass('active').removeClass('warning');
                            /* scroll to top on the error message*/
                            $("html, body").animate({scrollTop: 0}, "slow");
                        }
                        var update_url = '/main/update_inactive_justification?center_id=' + center_id + '&justification=' + justification;

                        // call update comment
                        $.ajax({
                            url: update_url,
                            type: 'POST',
                            data: $('#inactive_term').serialize(),
                            success: function (res) {
                            }
                        });
                    }
                }/*  if noterm */
            }  /* end of hasClass active */

        }); /* click on the checker */


        /* disable any key down on datepicker */
        $('.hasDatepicker').keydown(function (e) {
            var key = e.keyCode;
            if ((key != 9) && (key != 37) && (key != 38) && (key != 39) && (key != 40)) {
                e.preventDefault();
                return false;
            }
        });

        /* grantee award checker*/
        $(document).on('click', '.checker_award', function (e) {

            if (!$(this).hasClass('disabled')) {

                if ($('.award_checkbox').hasClass("active")) {
                    $('.award_checkbox').removeClass('active');
                    $(this).parents().prev("input.dollar").val(0);
                    $(this).parents().prev("input.dollar").attr("disabled", false);
                    $('.hiddenaward').attr("disabled", true);
                } else {
                    $('.award_checkbox').addClass('active');
                    $(this).parents().prev("input.dollar").val($(this).parents().next("li").find("input.dollar").val());
                    $('.hiddenaward').val($(this).parents().next("li").find("input.dollar").val());
                    $(this).parents().prev("input.dollar").attr("disabled", true);
                    $('.hiddenaward').attr("disabled", false);
                }
            } else {
                return false;
            }
        });

        $(document).on('click', '.printable.button.secondary', function (e) {

            $('.button.secondary').removeClass('active');
            $(this).addClass('active');
            /* add active to other tab button */
            var buttonname = $(this).data('val');
            $('.button.secondary[data-val=' + buttonname + ']').addClass('active');

            if ($(this).data('val') == 'activities') {
                $('.activities-result').removeClass('hidden');
                if (!$('.staffing-result').hasClass('hidden')) {
                    $('.staffing-result').addClass('hidden');
                }
                if (!$('.participation-result').hasClass('hidden')) {
                    $('.participation-result').addClass('hidden');
                }
                if (!$('.outcomes-result').hasClass('hidden')) {
                    $('.outcomes-result').addClass('hidden');
                }
            } else if ($(this).data('val') == 'staffing') {
                $('.staffing-result').removeClass('hidden');
                if (!$('.activities-result').hasClass('hidden')) {
                    $('.activities-result').addClass('hidden');
                }
                if (!$('.participation-result').hasClass('hidden')) {
                    $('.participation-result').addClass('hidden');
                }
                if (!$('.outcomes-result').hasClass('hidden')) {
                    $('.outcomes-result').addClass('hidden');
                }
            } else if ($(this).data('val') == 'participation') {
                $('.participation-result').removeClass('hidden');
                if (!$('.activities-result').hasClass('hidden')) {
                    $('.activities-result').addClass('hidden');
                }
                if (!$('.staffing-result').hasClass('hidden')) {
                    $('.staffing-result').addClass('hidden');
                }
                if (!$('.outcomes-result').hasClass('hidden')) {
                    $('.outcomes-result').addClass('hidden');
                }
            } else if ($(this).data('val') == 'outcomes') {
                $('.outcomes-result').removeClass('hidden');
                if (!$('.activities-result').hasClass('hidden')) {
                    $('.activities-result').addClass('hidden');
                }
                if (!$('.staffing-result').hasClass('hidden')) {
                    $('.staffing-result').addClass('hidden');
                }
                if (!$('.participation-result').hasClass('hidden')) {
                    $('.participation-result').addClass('hidden');
                }
            }

            return false;
        });

        $(document).on('click', '.printrecords', function (e) {
            e.preventDefault();

            // get the screen height and width
            var maskHeight = $(document).height();
            var maskWidth = $(window).width();

            // calculate the values for center alignment
            var dialogTop = (maskHeight / 3) - ($('#dialog-box').height());
            var dialogLeft = (maskWidth / 2) - ($('#dialog-box').width() / 2);

            if (dialogTop > 100) {
                dialogTop = 100;
            }
            if (dialogLeft > 450) {
                dialogLeft = 450;
            }

            // assign values to the overlay and dialog box
            $('#dialog-overlay').css({height: maskHeight, width: maskWidth}).show();
            $('#dialog-box').css({top: dialogTop, left: dialogLeft}).show();
            $('#dialog-box').addClass('print-records-dialog');
            //store element that triggered the modal
            $( "body" ).data("modal_trigger", $(e.target));
            $($('#dialog-box .dialog.button')[0]).focus();
            // disable focus outside of modal
            $( ":focusable" ).not( "#dialog-box :focusable" ).attr('tabindex', '-1');
            $('.dialog.button.right').attr('id', 'okay-print-records');
            $('.dialog.button.right').attr('data-id', 'state');

            // display the message
            $('#dialog-message').html('<h3>Print State Records?</h3> <p>21APR state records will now be prepared. Are you sure you want to generate the state records?</p>');

            return false;
        });

        $(document).on('click', '.printgrantee', function (e) {
            e.preventDefault();

            //for grantee user
            var gid = $(this).data("gid");

            // get the screen height and width
            var maskHeight = $(document).height();
            var maskWidth = $(window).width();

            // calculate the values for center alignment
            var dialogTop = (maskHeight / 3) - ($('#dialog-box').height());
            var dialogLeft = (maskWidth / 2) - ($('#dialog-box').width() / 2);

            if (dialogTop > 100) {
                dialogTop = 100;
            }
            if (dialogLeft > 450) {
                dialogLeft = 450;
            }

            // assign values to the overlay and dialog box
            $('#dialog-overlay').css({height: maskHeight, width: maskWidth}).show();
            $('#dialog-box').css({top: dialogTop, left: dialogLeft}).show();
            $('.dialog.button.right').attr('id', 'okay');
            $('.dialog.button.right').attr('data-id', 'grantee');
            $('.dialog.button.right').attr('data-gid', gid);


            // display the message
            $('#dialog-message').html('<h3>Print Grantee Records?</h3> <p>21APR grantee records will now be prepared. Are you sure you want to generate the grantee records?</p>');

            return false;
        });

        $('#cancel').click(function (e) {

            e.preventDefault();
            var part = $(this).data("id");
            var userid = $(this).data("userid");
            /* remove doDelete Class on user page */
            if (part == 'user' || part == 'users') {
                $('.action_list_' + userid).removeClass('delete');
                $('.action_list_' + userid).children('.remove-btn').removeClass('doDelete');

            } else if (part == 'delete-grantee') {
                $('.remove-edit-grantee').removeClass('active');
                // clear selection
                $('.del_reason').attr('checked', false);
                $("#okay").attr('data-deletereason', 0);
            } else if (part == 'center') {
                // clear selection
                $('.del_reason').attr('checked', false);
                $("#okay").attr('data-deletereason', 0);
            }

            // clear pop up
            $('#dialog-overlay, #dialog-box').hide();
            $('.dialog.button.right.okayGranteePrint').removeClass('okayGranteePrint');
            $('.dialog.button.right.okayStatePrint').removeClass('okayStatePrint');

            // return focus to the element that opened the modal
            $( "body" ).data("modal_trigger").focus();
            // reenable focus outside of modal
            $( ":focusable" ).not( "#dialog-box :focusable" ).attr('tabindex', '0');
            
            /* remove doDelete in remove-btn */
            $('.remove-btn').removeClass('doDelete');
            $('.remove-btn').parent('.dropdown').removeClass('delete');
            return false;

        });


        $('#okay').click(function (e) {
            e.preventDefault();
            $('#okay').attr('disabled',true);

            let obj = $(this),
                report = obj.data('id'),
                gid = obj.data('gid'),
                centerid = obj.data('centerid'),
                userid = obj.data('userid'),
                uid = obj.data('uid'),
                seg3 = obj.data('seg3'),
                centername = obj.data('center'),
                deletereason = obj.attr('data-deletereason');

            if (report == 'grantee') {
                $('#dialog-box').hide();
                window.location.replace('/records/' + gid + '/grantee');
                $('.loading-overlay').fadeIn();
            } else if (report == 'state') {
                $('#dialog-box').hide();
                window.location.replace('/records/state');
                $('.loading-overlay').fadeIn();
            } else if (report == 'center_report') {
                $('#dialog-box').hide();
                window.location.replace('/records/' + centerid + '/center');
                $('.loading-overlay').fadeIn();
            }else if (report == 'center') {
                if (deletereason != 0) {
                    $('#dialog-box').hide();
                    $('#dialog-overlay').hide();
                    let con_message = confirm(
                        "You are about to delete this Center and all corresponding APR data entered for the current reporting year. Are you really sure you want to delete this Center?"
                    );
                    if (con_message == true) {
                        let csrf = $(".token").val();
                        centerid = typeof centerid != 'undefined' ? centerid : uid;
                        $.post(
                            '/center/' + centerid + '/remove/' + deletereason,
                            {
                                csrf_token_name: csrf
                            },
                            function (res) {
                                $(".notification p").html("Center: " + centername + " has been deleted!");
                                $(".notification")
                                    .addClass("active")
                                    .addClass("success").delay(5000)
                                    .queue(function (next) {
                                        $(this)
                                            .removeClass("active")
                                            .removeClass("success");
                                        next();
                                    });
                                $("html,body").animate({scrollTop: 0}, "slow");
                                /* page refer back to overview in 3 sec */
                                window.setTimeout(function () {
                                    window.location.href = '/grantee/' + gid + '/overview';
                                }, 1500);

                            })
                            .fail(function () {
                                location.reload();
                            });
                    } else {
                        $('.del_reason').attr('checked', false);
                        $("#okay").attr('data-deletereason', 0);


                        $(".remove-btn").removeClass("doDelete");
                    }
                } else { // No delete reason selected

                    $(".delreasonerror").removeClass('hidden');
                }

            } else if (report == 'user') {
                /* add for delete user confirmation */

                $('#dialog-box').hide();
                $('#dialog-overlay').hide();
                let btn = $('.action_list_' + userid).children('.remove-btn');

                if (btn.hasClass("doDelete") || seg3 == 'user') {
                    let id = btn.data("uid");
                    let useremail = $(this).data('user');
                    let csrf = $(".token").val();
                    let url = "/user/" + id + "/remove";
                    $.post(url, {csrf_token_name: csrf})
                        .done(function (res) {
                            res = JSON.parse(res);
                            if (res.ok) {
                                $(".record_" + id)
                                    .fadeOut()
                                    .remove();
                                $(".notification p").html(useremail + " has been deleted!");
                                $(".notification")
                                    .addClass("active")
                                    .addClass("success")
                                    .delay(3000)
                                    .queue(function (next) {
                                        $(this)
                                            .removeClass("active")
                                            .removeClass("success");
                                        next();
                                    });
                                $("html,body").animate({scrollTop: 0}, "slow");
                                window.location.href = '/users';
                            }
                        })
                        .fail(function (xhr, status, error) {

                        });

                    $("#add-user").removeAttr("disabled");

                } /* end for doDelete */
            } /* end for report == user */
            else if (report == 'delete-grantee') {

                if (deletereason != 0) {
                    let btn = $('.remove-edit-grantee');
                    let con_message = confirm(
                        "You are about to delete this Grantee, all of its Centers, and all corresponding APR data entered for the current reporting year. Are you really sure you want to delete this Grantee?"
                    );

                    /* achan add re-re-confirm message to warn the delete */
                    if (btn.hasClass("old")) {
                        let con_message = confirm(
                            "You are about to delete this Grantee from the current reporting year. (This action will not delete performance data from previous years.) If this Grantee has an existing award, do not delete it. If this Grantee has a new award, proceed with deleting this profile and create a new Grantee profile for the new award."
                        );
                    }

                    if (con_message) {
                        $('#dialog-box').hide();
                        $('#dialog-overlay').hide();
                        let id = btn.data("uid");
                        let csrf = $(".token").val();
                        let url = '/grantee/deleteGrantee/' + id + '/' + deletereason;
                        $.post(
                            url,
                            {
                                csrf_token_name: csrf
                            },
                            function (res) {
                                res = JSON.parse(res);
                                if (res.ok) {
                                    window.location.replace("../../grantees");
                                }
                            }
                        );
                    } else {
                        $('.del_reason').attr('checked', false);
                        $("#okay").attr('data-deletereason', 0);
                        btn.removeClass("active");
                        $('#dialog-box').hide();
                        $('#dialog-overlay').hide();
                    }

                } else { // error No Delete reason
                    $('.delreasonerror').removeClass('hidden');

                }

            } else {

            }

            return false;
        });

        // end of printable dialog box


        /* disable the dollar input box if check is active onload*/
        if ($('.award_checkbox').hasClass('active')) {
            $('.dollar').attr("disabled", true);
        }

        $(document).on('keyup', '.dollar', function (e) {
            $('.hiddenaward').attr("disabled", true);
        });


        $(document).on("click", ".genkey", function (e) {
            e.preventDefault();
            var csrf = $('.token').val();
            var email = $(this).data('email');

            $('.loading-overlay').show().fadeIn;

            $.post('/ajax/generateKey', {
                    'csrf_token_name': csrf,
                    'data': email,
                }
                , function (data, status) {

                    if (data === 'created') {
                        $('.notification p').html("Sent API Key Successfully.");
                        $('.notification').addClass('active').addClass('success');
                        //refresh browser instead of js operations
                        setTimeout(function () {
                            //window.location.href = "/users";
                            window.location.reload();
                        }, 1000);
                    } else {
                        $('.notification p').html('API Key Exists!');
                        $('.notification').addClass('active').addClass('warning');
                        $('.notification span').html('Alert!');
                    }
                });
            $('.loading-overlay').hide().fadeOut;
        });

        /**
         * copy from Angela-api-test
         */

        $(document).on("click", ".revokekey", function (e) {
            e.preventDefault();
            $('.loading-overlay').show().fadeIn;

            var csrf = $('.token').val();
            var email = $(this).data('email');


            $.post('/ajax/revokeKey', {
                'csrf_token_name': csrf,
                'data': email,
            }, function (data, status) {


                if (data === 'revoked') {
                    $('.notification p').html("Revoked API Key Successfully.");
                    $('.notification').addClass('active').addClass('success');
                    //refresh browser instead of js operations
                    //$('.genkey').removeClass('disabled');
                    //$('.revokekey').addClass("disabled").addClass("hidden");
                    //$(this).parent('div').parent('div').children('.icon-key').addClass('hidden');
                    setTimeout(function () {
                        //window.location.href = "/users";
                        window.location.reload();
                    }, 1000);
                } else {
                    $('.notification p').html('API Key NOT Exists!');
                    $('.notification').addClass('active').addClass('warning');
                    $('.notification span').html('Alert!');
                }
            });

            $('.loading-overlay').hide().fadeOut;

        });

        $(document).on("click", ".regenkey", function (e) {
            e.preventDefault();
            var csrf = $('.token').val();
            var email = $(this).data('email');

            $.ajax({
                type: 'POST',
                url: '/ajax/resentAPIKey',
                csrf_token_name: csrf,
                data: {
                    email: email
                },
                success: function (response) {
                    $('.notification p').html("Resent API Key Successfully.");
                    $('.notification').addClass("active").addClass("success").delay(5000).queue(function (next) {
                        $(this).removeClass("active").removeClass("success").delay(5000).queue(function () {
                            window.location.reload();
                        });
                        next();
                    });
                },
                error: function(e) {
                    $('.notification p').html('Request could not be completed');
                    $('.notification').addClass('active').addClass('warning');
                    $('.notification span').html('Alert!');
                }
            });
        });

        $(".isaState").change(function (e) {

            // get all ISA
            $.ajax({
                url: '/ajax/get_isa',
                type: 'POST',
                data: $('.sign-up').serialize(),
                dataType: "json",
                success: function (res) {
                    $('.has_isa_result').removeClass('hidden');
                    if (res.length > 0) {
                        $('.hasisaselect option[value=Yes]').attr('selected', 'selected');
                        $('.hasisaselect option[value=No]').removeAttr('selected');
                        var expire_date = res[0].date_expired.split(" ");
                        var inserted_date = res[0].date_inserted.split(" ");
                        $(".date_inserted_label").val(inserted_date[0]);
                        $('.datetimepicker_isaexpire').val(expire_date[0]);
                    } else {
                        $('.hasisaselect option[value=No]').attr('selected', 'selected');
                        $('.hasisaselect option[value=Yes]').removeAttr('selected');
                        $('.datetimepicker_isaexpire').val('');
                        $(".date_inserted_label").val('');
                    }
                }
            });

        });

        $(document).on('change', '.hasisaselect, #datetimepicker_isaexpire_input, .system_name ', function (e) {
            e.preventDefault();

            // update ISA
            $.ajax({
                url: '/ajax/update_isa',
                type: 'POST',
                data: $('.sign-up').serialize(),
                dataType: "json",
                success: function (res) {

                    if (res.res === 'OK') {
                        $('.notification p').html("Update ISA Successfully.");
                        $('.notification').addClass('active').addClass('success');
                        setTimeout(function () {
                            window.location.reload();
                        }, 1000);
                    }
                }
            });

        });

        $('.has-elt').change(function (e) {
            if ($(".has-elt").find(':selected').text() == 'Yes') {
                $('.elt_questions').slideDown("slow");
                $('.elt_questions').removeClass('hidden');
            } else {
                $('.elt_questions').slideUp("slow");
                $('.elt_questions').addClass('hidden');
            }
        });

        $('.elt_q').click(function (e) {
            if ($(this).val() == 0) {
                $(this).val('');
            }
        });

        $('.elt_q_checkbox').click(function (e) {
            if ($(this).hasClass('active')) {
                $(this).removeClass('active');
                $(this).prop('checked', false).removeAttr('checked');
                console.log('click off');
            } else {
                $(this).addClass('active');
                $(this).prop('checked', true).attr('checked', 'checked');
                console.log('click on');
            }
        });

        $('.turn_api').change(function (e) {
            $.ajax({
                url: '/ajax/setUsagePlan',
                type: 'POST',
                data: $('.sign-up').serialize(),
                success: function (res) {

                    var obj = JSON.parse(res);
                    if (obj.res === 'OK') {
                        $('.notification p').html("Update API Plan Successfully.");
                        $('.notification').addClass('active').addClass('success');
                    }
                }
            });
        });

        $('.apiState').change(function (e) {
            $.ajax({
                url: '/ajax/setApiStateView',
                type: 'POST',
                data: $('.sign-up').serialize(),
                success: function (res) {
                    $('.notification p').html("API Read-Only Mode has been updated");
                    $('.notification').addClass('active').removeClass('warning').addClass('success');
                    $('.notification span').html('Success!');
                },
                error: function (res) {
                    $('.notification p').html('Could not update API Read-Only Mode');
                    $('.notification').addClass('active').removeClass('success').addClass('warning');
                    $('.notification span').html('Alert!');
                }
            });
        });

        $('.center-form').on('change', '.elt-choices, .elt-radio, .elt-text, .elt-percentage', function (e) {
            e.preventDefault();
            if (!$(this).hasClass('skip')) {
                if ($('.elt-percentage').val() > 100) {
                    $('.elt-percentage').val(100);
                }
                $.ajax({
                    url: '/ajax/update_elt',
                    type: 'POST',
                    data: $('.center-form').serialize(),
                    success: function (res) {
                        //
                    }
                });
            }
        });

        // elt_stepper is not found in views
        $(".elt_stepper").click(function (e) {
            e.preventDefault();

            /* validate the value is under 100 */
            if ($(this).hasClass('percentage_fund')) {

                if ($('.elt_6').val() > 100) {
                    /* pop up error and put the value to 100 as maximum */
                    $('.notification p').html("Percentage of fund allocated by State must be less than or equal to 100%");
                    $('.notification').addClass('active').addClass('warning');
                    $('.notification span').html('Alert!');
                    $('.elt_6').val(100);
                    if (!$(this).hasClass('skip')) {
                        $.ajax({
                            url: '/ajax/update_elt',
                            type: 'POST',
                            data: $('.center-form').serialize(),
                            success: function (res) {
                                //
                            }
                        });
                    }
                } else {
                    $('.notification').removeClass('active');
                    if (!$(this).hasClass('skip')) {
                        $.ajax({
                            url: '/ajax/update_elt',
                            type: 'POST',
                            data: $('.center-form').serialize(),
                            success: function (res) {
                                //
                            }
                        });
                    }
                }
            } else {
                if (!$(this).hasClass('skip')) {
                    $.ajax({
                        url: '/ajax/update_elt',
                        type: 'POST',
                        data: $('.center-form').serialize(),
                        success: function (res) {
                            //
                        }
                    });
                }
            }
        });

        $('.elt_stepper, .eltcheckbox').keyup(function (e) {
            e.preventDefault();

            if ($(this).find("input").val() == 0) {
                $(this).find("input").val() == 0;
            }

            /* validate the value is under 100 */
            if ($(this).hasClass('percentage_fund')) {
                if ($('.elt_6').val() > 100) {
                    /* pop up error and put the value to 100 as maximum */
                    $('.notification p').html("Percentage of fund allocated by State must be less than or equal to 100%");
                    $('.notification').addClass('active').addClass('warning');
                    $('.notification span').html('Alert!');
                    $('.elt_6').val(100);
                    if (!$(this).hasClass('skip')) {
                        $.ajax({
                            url: '/ajax/update_elt',
                            type: 'POST',
                            data: $('.center-form').serialize(),
                            success: function (res) {
                                //
                            }
                        });
                    }
                } else {
                    $('.notification').removeClass('active');
                    if (!$(this).hasClass('skip')) {
                        $.ajax({
                            url: '/ajax/update_elt',
                            type: 'POST',
                            data: $('.center-form').serialize(),
                            success: function (res) {
                                //
                            }
                        });
                    }
                }
            } else {
                if (!$(this).hasClass('skip')) {
                    $.ajax({
                        url: '/ajax/update_elt',
                        type: 'POST',
                        data: $('.center-form').serialize(),
                        success: function (res) {
                            //
                        }
                    });
                }
            }

        });

        $('.showmore').click(function (e) {
            /* get the last count which is not hidden */
            count_start = $('.item').length - $('.item.hidden').length
            if ($('.item.hidden').length > 0) {
                var i;
                for (i = count_start + 1; i <= count_start + 10; i++) {
                    $("#release-count-" + i).removeClass('hidden');
                }
            } else {
                $('.showmore').addClass('hidden');
            }
        });

        class ClassWatcher {
            constructor(targetNode, classToWatch, classAddedCallback, classRemovedCallback) {
                this.targetNode = targetNode
                this.classToWatch = classToWatch
                this.classAddedCallback = classAddedCallback
                this.classRemovedCallback = classRemovedCallback
                this.observer = null
                if (targetNode)
                    this.lastClassState = targetNode.classList.contains(this.classToWatch)
                this.init()
            }

            init() {
                this.observer = new MutationObserver(this.mutationCallback)
                this.observe()
            }

            observe() {
                this.observer.observe(this.targetNode, {attributes: true})
            }

            disconnect() {
                this.observer.disconnect()
            }

            mutationCallback = mutationsList => {
                for (let mutation of mutationsList) {
                    if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                        let currentClassState = mutation.target.classList.contains(this.classToWatch)
                        if (this.lastClassState !== currentClassState) {
                            this.lastClassState = currentClassState
                            if (currentClassState) {
                                this.classAddedCallback()
                            } else {
                                this.classRemovedCallback()
                            }
                        }
                    }
                }
            }
        }


        // Floating notice bar
        $(window).on('scroll', function () {
            let top = $(this).scrollTop(),
                oldWidth = $('.notification').width();
            if (top > 106) {
                $('.notification').addClass('floating')
                    .width($('#content-container').width() - 30);
            } else {
                $('.notification').removeClass('floating').width($('.content-container').width());
            }
        });

        if (document.getElementsByClassName('notification').length) {
            let floatingWatcher = new ClassWatcher(
                document.getElementsByClassName('notification')[0],
                'floating',
                function () {
                    if ($(this).scrollTop > 106) {
                        $('.notification').width($('#content-container').width() - 30);
                    } else {
                        $('.notification').width($('#content-container').width());
                    }
                },
                () => {
                }
            );
        }

        $(document).on('click', '.printcenter', function (e) {
            e.preventDefault();

            //for center record
            var centerid = $(this).data("centerid");

            // get the screen height and width
            var maskHeight = $(document).height();
            var maskWidth = $(window).width();

            // calculate the values for center alignment
            var dialogTop = (maskHeight / 3) - ($('#dialog-box').height());
            var dialogLeft = (maskWidth / 2) - ($('#dialog-box').width() / 2);

            if (dialogTop > 100) {
                dialogTop = 100;
            }
            if (dialogLeft > 450) {
                dialogLeft = 450;
            }

            // assign values to the overlay and dialog box
            $('#dialog-overlay').css({height: maskHeight, width: maskWidth}).show();
            $('#dialog-box').css({top: dialogTop, left: dialogLeft}).show();
            $('.dialog.button.right').attr('id', 'okay');
            $('.dialog.button.right').attr('data-id', 'center_report');
            $('.dialog.button.right').attr('data-centerid', centerid);

            // display the message
            $('#dialog-message').html('<h3>Print Center Records?</h3> <p>21APR center records will now be prepared. Are you sure you want to generate the center records?</p>');

            return false;
        });

        $('.auto_submit_item').change(function() {
            // Do not auto submit on View Performance National
            let isNational = $("#national-states-select").length;
            if (!isNational) {
                let name = $(this).attr('name');
                let params = new URLSearchParams(window.location.search);
                params.set(name, $(this).val());

                window.location = "?" + params.toString();
            }
        });

    }); /* end doc ready */
})(jQuery);


/**
 *
 * @param string type     Either "success" or "error"
 * @param string message  HTML or Text
 * @param string fadeOut  If true, fade out after 10sec delay. Nice for errors.
 */
function showNotification(type, message, fadeOut = false)
{
    if (type.toLowerCase() !== 'success' && type.toLowerCase()  !== 'error') {
        return false;
    }

    $('.notification p').html(message);

    if (type.toLowerCase() === 'success') {
        $('.notification span').html('Success!');
        $('.notification').addClass('success').removeClass('warning').show();
    } else {
        $('.notification span').html('Alert!');
        $('.notification').addClass('warning').removeClass('success');
    }

    $('.notification').addClass('active');

    if (fadeOut) {
        setTimeout(() => { $('.notification').fadeOut('1000'); }, 10000);
    }
    return true;
}

function hideNotification()
{
    $('.notification').removeClass('active').hide();
}
