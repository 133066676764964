$(document).ready(function () {

    var app_url_participation = document.location.hostname;
    
    const GRADE_ROWS_TOP_STOP = 14;

    /**
     * Variable should track the value of window.ScrollY within the "scroll" event
     * Can be used to trigger events based on viewport changes (scroll up/down)
     */
    let pre_event_y_scroll = 0;

    validateAllGradeLevelsByHourBandRows();
    
    /**
     * Determine if a html element is currently in the view port
     * @param element_class
     * @returns {boolean}
     */
    function elementInViewPort(element_class)
    {
        var docViewTop = $(window).scrollTop();
        var docViewBottom = docViewTop + $(window).height();
        var elemTop = $(element_class).offset().top;
        return ((elemTop <= docViewBottom) && (elemTop >= docViewTop));
    }

    /**
     * Determines if the scroll direction is "down" 
     * given previous window.scrollY value
     * @param previous_scroll_direction : window.ScrollY
     * @returns {boolean}
     */    
    function scrollDirectionIsDown(previous_scroll_direction)
    {
        return window.scrollY > previous_scroll_direction;
    }    

    let reportedPreKTotal = getReportedValues_PreK(true);
    let reported6th12thTotal = getReportedValues_6th_12th_Grades(true);
    updateTotalsSexSection(reportedPreKTotal, reported6th12thTotal);

    updateTotalsRaceEthnicitySection(reportedPreKTotal, reported6th12thTotal);

    /* function to call console.log */
    function showlog(msg) {
        /* set debug mode, default is false, true is turn on and false is off */
        var DEBUG = false;
        if (DEBUG) {
            console.log(msg);
        }
    }

    var modCount = 1;
    var totalModules = $('.section').length;
    var completed = [];


    // if review_ind is 0, continue button will be shown
    if (!$('.participation-ul').hasClass('review')) {
        $('.section').hide();
        $('.section').first().fadeIn();
    }

    if ($('.participation-ul').hasClass('loadall')) {
        $('.section').show();
    }


    function validateAllGradeLevelsByHourBandRows() {

        valuesPreK = getReportedValues_PreK();
        values6th12thGrades = getReportedValues_6th_12th_Grades();

        for (let i = 0; i < GRADE_ROWS_TOP_STOP; i++) {
            validateGradeLevelsByHourBandRow(i, valuesPreK, values6th12thGrades);
        }
    }


    function validateGradeLevelsByHourBandRow(rowNumber, valuesPreK, values6th12thGrades) {

        if (undefined == valuesPreK) {
            valuesPreK = getReportedValues_PreK();
        }


        if (undefined == values6th12thGrades) {
            values6th12thGrades = getReportedValues_6th_12th_Grades();
        }

        let ROWS = [
            'PREK',
            'K',
            'G1',
            'G2',
            'G3',
            'G4',
            'G5',
            // 6th grade up - G6 missing per rows being one number up
            'G7',
            'G8',
            'G9',
            'G10',
            'G11',
            'G12',
            'G13',
        ];

        let section = 0;

        let reportedSectionNumber = 0;

        if (rowNumber >= 0 && rowNumber < 7) {
            section = ROWS[rowNumber];
            reportedSectionNumber = Number(valuesPreK.get(section));
        } else if (rowNumber > 6 && rowNumber < GRADE_ROWS_TOP_STOP) {
            section = ROWS[rowNumber];
            reportedSectionNumber = Number(values6th12thGrades.get(section));
        } else {
            return;
        }


        const all = $('tr[data-calc-row=' + rowNumber + '] > td > input.number-of-grade_hour-input');

        let sum = 0;

        all.each(function (i, el) {
            let num = Number($(el).val());
            sum += isNaN(num) ? 0 : num;
        });

        const SELECTOR_ROW_TOTAL = 'tr > td span.calc-row-' + rowNumber + '-total';

        updateTotalWordsAndClasses($(SELECTOR_ROW_TOTAL), sum, reportedSectionNumber);

        updateSectionTotalSum('.grades.grades_pre_k', getSumReportedValues(valuesPreK));
        updateSectionTotalSum('.grades.grades_6th_12th', getSumReportedValues(values6th12thGrades));
    }


    /**
     * updates  all totals
     */
    function updateTotals() {
        validateAllGradeLevelsByHourBandRows();

        // update the grades
        let reportedSumPreK = getSumReportedValues(getReportedValues_PreK());
        let reportedSum6th12thGrade = getSumReportedValues(getReportedValues_6th_12th_Grades());

        updateSectionTotalSum('.grades.grades_pre_k', reportedSumPreK);
        updateSectionTotalSum('.grades.grades_6th_12th', reportedSum6th12thGrade);


        // sex section
        updateTotalsSexSection(reportedSumPreK, reportedSum6th12thGrade);
        updateTotalsRaceEthnicitySection(reportedSumPreK, reportedSum6th12thGrade);
    }


    $('form[name=apr-grade-participation-form] :input').on('change update keyup keydown focus', () => {
        updateTotals();
    });


    function getSummaryGrade_PreK() {
        let preksum = 0;
        $('.num-of-grade-prek').each(function () {
            preksum += Number($(this).val());
        });
        return preksum;
    }

    function getSummaryGrade_6th_12th() {
        var g6sum = 0;
        $('.num-of-grade-g6_12').each(function () {
            g6sum += Number($(this).val());
        });

        return g6sum;
    }

    function getNumericValue(value) {
        return isNaN(value) ? 0 : Number(value);
    }

    function getReportedValues_PreK(asTotal = false) {
        let values = new Map();

        values.set('PREK', getNumericValue($('#student_0').val()));
        values.set('K', getNumericValue($('#student_1').val()));
        values.set('G1', getNumericValue($('#student_2').val()));
        values.set('G2', getNumericValue($('#student_3').val()));
        values.set('G3', getNumericValue($('#student_4').val()));
        values.set('G4', getNumericValue($('#student_5').val()));
        values.set('G5', getNumericValue($('#student_6').val()));

        sum = 0;
        if (asTotal) {
            values.forEach((value) => {
                sum += Number(value);
            });

            return sum;
        }

        return values;
    }


    function getReportedValues_6th_12th_Grades(asTotal = false) {
        let values = new Map();

        values.set('G7', getNumericValue($('#student_7').val()));
        values.set('G8', getNumericValue($('#student_8').val()));
        values.set('G9', getNumericValue($('#student_9').val()));
        values.set('G10', getNumericValue($('#student_10').val()));
        values.set('G11', getNumericValue($('#student_11').val()));
        values.set('G12', getNumericValue($('#student_12').val()));
        values.set('G13', getNumericValue($('#student_13').val()));

        sum = 0;
        if (asTotal) {
            values.forEach(value => {
                sum += Number(value);
            });

            return sum;
        }

        return values;
    }

    // update review participation button, when all number match, button should be enabled. it not, button should be disabled
    function validate_part_balance() {

        let returnObject = { summary: { preK: 0, g6_12: 0 } };

        var count = $(".good").length;

        var preksum = getSummaryGrade_PreK()

        var g6sum = getSummaryGrade_6th_12th();

        if ((preksum > 0) && (g6sum > 0)) {
            if (count === 6) {
                $(".save-participation-btn").prop("disabled", false);
                if ($(".save-participation-btn").hasClass("disabled")) {
                    $(".save-participation-btn").removeClass("disabled");
                }
                if ($(".notification").hasClass("active")) {
                    $(".notification").removeClass("active");
                }
                if ($(".notification").hasClass("warning")) {
                    $(".notification").removeClass("warning");
                }
                if ($(".outcomes").hasClass("ghost-button")) {
                    $(".outcomes").removeClass("ghost-button");
                }

                /* enable outcome nav-button and nav-top-bar*/
                if ($(".outcomes").hasClass("ghost-button")) {
                    $(".outcomes").removeClass("ghost-button");
                }
                if ($("#outcomes_button").hasClass("inactive")) {
                    $("#outcomes_button").removeClass("inactive");
                    var replace_href;
                    var current_href = window.location.pathname;

                    replace_href = "/outcome/" + current_href.split('/')[2] + '/edit';
                    $('#outcomes_button').prop('href', replace_href);
                }

                var link = $('.part_nav').attr('href');
                var newlink = link.replace('participation', 'outcome');
                $(".outcome_nav").attr("href", newlink);
            } else {
                $(".save-participation-btn").prop('disabled', true).addClass("disabled");
                $(".outcomes").addClass('ghost-button');
                $('.notification p').html('Please balance the values in this section.');
                $('.notification').addClass('active').addClass('warning');
                $('.notification span').html('Alert!');
                $(".outcome_nav").attr("href", "#");
            }
        } else {
            if (count === 3) {
                $(".save-participation-btn").prop('disabled', false);
                if ($(".save-participation-btn").hasClass("disabled")) {
                    $(".save-participation-btn").removeClass("disabled");
                }
                if ($(".notification").hasClass("active")) {
                    $(".notification").removeClass("active");
                }
                if ($(".notification").hasClass("warning")) {
                    $(".notification").removeClass("warning");
                }
                if ($(".outcomes").hasClass("ghost-button")) {
                    $(".outcomes").removeClass("ghost-button");
                }
                /* enable outcome nav-button and nav-top-bar*/
                if ($(".outcomes").hasClass("ghost-button")) {
                    $(".outcomes").removeClass("ghost-button");
                }
                if ($("#outcomes_button").hasClass("inactive")) {
                    $("#outcomes_button").removeClass("inactive");
                    var replace_href;
                    var current_href = window.location.pathname;

                    replace_href = "/outcome/" + current_href.split('/')[2] + '/edit';
                    $('#outcomes_button').prop('href', replace_href);
                }

                var link = $('.part_nav').attr('href');
                var newlink = link.replace('participation', 'outcome');
                $(".outcome_nav").attr("href", newlink);
            } else {
                $(".save-participation-btn").prop('disabled', true).addClass("disabled");
                $(".outcomes").addClass('ghost-button');
                $('.notification p').html('Please balance the values in this section.');
                $('.notification').addClass('active').addClass('warning');
                $('.notification span').html('Alert!');
                $(".outcome_nav").attr("href", "#");
            }
        }

        returnObject.summary.g6_12 = g6sum;
        returnObject.summary.preK = preksum;

        return returnObject;
    }// end of validate_part_balance

    // validate participation student balance
    if ($('#Participation-edit-page .participation-ul').hasClass('review')) {
        validate_part_balance();
    }


    $('.section').each(function () {
        var module = $(this);

        module.attr('id', "section" + modCount);
        module.find('.button').data("nextMod", "section" + (modCount + 1));
        modCount++;
    });

    $('.part-continue').click(function (e) {
        if (api_state_flag == 1 || view_only_mode_flag == 1) {
            return false;
        }


        e.preventDefault();
        var el = $(this);
        var nextEl = "#" + el.data("nextMod");

        el.fadeOut(400, function () {
            $(nextEl).fadeIn(400, function () {
                if (($('.6_12_participation-detail').hasClass('visible') && $('.prek_5_participation-detail').hasClass('hidden'))
                    || ($('.6_12_participation-detail').hasClass('hidden') && $('.prek_5_participation-detail').hasClass('visible'))
                ) {
                    var progress = (((completed.length + 1) / (totalModules - 1)) * 2) * 100;
                } else {
                    var progress = ((completed.length + 1) / (totalModules - 1)) * 100;
                }
                $('.progress').animate({
                    width: progress + "%"
                }, 500);

                $('html, body').animate({
                    scrollTop: $(nextEl).offset().top - 80
                }, 2000);
                el.closest('button').addClass('done');
            });
        });
        $('.head-section').show();

        var done_count = $('.done').length;
        if ((($('.6_12_participation-detail').hasClass('visible')) && ($('.prek_5_participation-detail').hasClass('hidden'))) || (($('.6_12_participation-detail').hasClass('hidden')) && ($('.prek_5_participation-detail').hasClass('visible')))) {
            if (done_count == 2) {
                $('.part-continue').addClass('hidden');
                setTimeout(function () {
                    $('.final-submit').removeClass('hidden');
                }, 1000);
            }
        } else {
            if (done_count == 6) {
                setTimeout(function () {
                    $('.final-submit').removeClass('hidden');
                }, 1000);
            }
        }

        validateParticipationData();
    });

    $('.part-detail-continue').click(function (e) {
        if (api_state_flag == 1 || view_only_mode_flag == 1) {
            return false;
        }

        e.preventDefault();
        $(this).hide();


        if (($('.6_12_participation-detail').hasClass('visible')) && ($('.prek_5_participation-detail').hasClass('hidden'))) {
            $('#section6').fadeIn(400, function () {
                $('html, body').animate({
                    scrollTop: $('#section6').offset().top - 100
                }, 2000);
            });
        } else {

            $('#section2').fadeIn(400, function () {
                $('html, body').animate({
                    scrollTop: $('#section2').offset().top - 100
                }, 2000);
            });

        }

        validateParticipationData();
    });

    /* generic function for participation checker open and reset */
    $('#Participation-edit-page').on('click', 'label.checker', function (e) {

        if (api_state_flag == 1 || view_only_mode_flag == 1) {
            return false;
        }

        showlog('participation label checker click');

        get_total();
        updateTotals();
        e.preventDefault();

        var initial_url = $('.grade-participationform').attr('action');
        var urls = initial_url.split('/');

        if ($(this).hasClass('grade')) {
            /* set vars */
            var form = $(this).parents('form');
            var item = $(this).parents('.item');
            var num_of_participant_wrapper = $(item).find('.number-of-participant-input.secondary-dropdown');
            var num_of_participant_input = $(item).find('.number-of-participant-input');
            var label_checker = $(item).find('label.checker');

            /* add the active class to the parent item */
            $(item).toggleClass('active');

            /* toggle the visibility of the sub-items */
            $(num_of_participant_wrapper).toggleClass('vis-hidden');
            $(num_of_participant_input).val("");

            get_total(); /* refresh all count */
            updateTotals();

            // prepare url for createGradePart($center_id, $participation_ccount, $attend_type);
            var update_url_action =// 'https://' + app_url_participation
                '/' + urls[urls.length - 3]
                + '/' + urls[urls.length - 2]
                + '/' + $.trim($(this).attr('class').substr(30, 2))
                + '/update_grade_number'
                + '/' + urls[urls.length - 1];

            /* save data via AJAX (to parent form action) - defined in data.js */


            $.ajax({
                url: update_url_action,
                type: 'POST',
                data: $('.grade-participationform').serialize(),
                error: (err) => {
                    console.log('participation error', err);
                }
            });
        } else if ($(this).hasClass('partdetail_checker')) {

            /* set vars */
            var item = $(this).parents('.item');
            var input = $(item).find('.number-of-detailpart-input');


            /* add the active class to the parent item */
            $(item).toggleClass('active');

            /* toggle the visibility of the sub-items */
            $(input).parent().parent().toggleClass('hidden');
            $(input).val("");

            updateTotals();
            get_total();

            // prepare url for createGradePart($center_id, $participation_ccount, $attend_type);
            var update_url_action =
                '/' + urls[urls.length - 3]
                + '/' + urls[urls.length - 2]
                + '/' + $.trim($(this).attr('class').substr(30, 2))
                + '/update_detail_part'
                + '/' + urls[urls.length - 1];

            /* save data via AJAX (to parent form action) - defined in data.js */

            console.log('ajax 1');
            $.ajax({
                url: update_url_action,
                type: 'POST',
                data: $('.grade-participationform').serialize(),
                error: (err) => {
                    console.log('participation error', err);
                }
            });
        } else if ($(this).hasClass('no-data')) {
            var uid = $(this).data("uid");
            var type = $(this).data("type"); //1 or 2

            /* toggle avtive class for checkbox */
            $(this).parent().toggleClass('active');

            /* reset the input to 0 and diable it */
            $(this).parent().prev().prev().children().val("");

            if ($(this).parent().hasClass('active')) {
                $(this).parent().prev().prev().children().attr("disabled", true);
                //$(button).attr("disabled", true);
            } else {
                $(this).parent().prev().prev().children().attr("disabled", false);
            }

            if ($(this).parent().hasClass('active')) {
                var update_url_action = '/' + urls[urls.length - 3]
                    + '/' + urls[urls.length - 2]
                    + '/' + uid + '-check_do_not_provide_ps'
                    + '/' + type
                    + '/' + urls[urls.length - 1];
            } else {
                var update_url_action = '/' + urls[urls.length - 3]
                    + '/' + urls[urls.length - 2]
                    + '/' + uid + '-uncheck_ps'
                    + '/' + type
                    + '/' + urls[urls.length - 1];
            }

            /* save 0 in database */

            $.ajax({
                url: update_url_action,
                type: 'POST',
                data: $('.grade-participationform').serialize(),
                error: (err) => {
                    console.log('participation error', err);
                }
            });
        }

        validateParticipationData();
    });

    // function to get total
    function get_total() {

        // hide session if total prek-5 is 0
        var preksum = 0;

        $('.num-of-grade-prek').each(function () {
            preksum += Number($(this).val());
        });

        $('.prek_5_total').html(preksum);

        // for grade hour prek_5 sum
        var hours_less_15_prek_sum = 0;
        $('.num-of-grade-hours_less_15-prek').each(function () {
            hours_less_15_prek_sum += Number($(this).val());
        })
        $('.prek_5_grade_hour_total_hours_less_15').html(hours_less_15_prek_sum);

        var hours_15_44_prek_sum = 0;
        $('.num-of-grade-hours_15_44-prek').each(function () {
            hours_15_44_prek_sum += Number($(this).val());
        });
        $('.prek_5_grade_hour_total_hours_15_44').html(hours_15_44_prek_sum);

        var hours_45_89_prek_sum = 0;
        $('.num-of-grade-hours_45_89-prek').each(function () {
            hours_45_89_prek_sum += Number($(this).val());
        });
        $('.prek_5_grade_hour_total_hours_45_89').html(hours_45_89_prek_sum);

        var hours_90_179_prek_sum = 0;
        $('.num-of-grade-hours_90_179-prek').each(function () {
            hours_90_179_prek_sum += Number($(this).val());
        });
        $('.prek_5_grade_hour_total_hours_90_179').html(hours_90_179_prek_sum);

        var hours_180_269_prek_sum = 0;
        $('.num-of-grade-hours_180_269-prek').each(function () {
            hours_180_269_prek_sum += Number($(this).val());
        });
        $('.prek_5_grade_hour_total_hours_180_269').html(hours_180_269_prek_sum);

        var hours_more_270_prek_sum = 0;
        $('.num-of-grade-hours_more_270-prek').each(function () {
            hours_more_270_prek_sum += Number($(this).val());
        });
        $('.prek_5_grade_hour_total_hours_more_270').html(hours_more_270_prek_sum);

        // hide session if total grade 6- 12 is 0
        var g6sum = 0;
        $('.num-of-grade-g6_12').each(function () {
            g6sum += Number($(this).val());
        });

        $('.g6_12_total').html(g6sum);

        // for grade hour g6_12_sum
        var hours_less_15_g6_12_sum = 0;
        $('.num-of-grade-hours_less_15-g6_12').each(function () {
            hours_less_15_g6_12_sum += Number($(this).val());
        })
        $('.g6_12_grade_hour_total_hours_less_15').html(hours_less_15_g6_12_sum);

        var hours_15_44_g6_12_sum = 0;
        $('.num-of-grade-hours_15_44-g6_12').each(function () {
            hours_15_44_g6_12_sum += Number($(this).val());
        });
        $('.g6_12_grade_hour_total_hours_15_44').html(hours_15_44_g6_12_sum);

        var hours_45_89_g6_12_sum = 0;
        $('.num-of-grade-hours_45_89-g6_12').each(function () {
            hours_45_89_g6_12_sum += Number($(this).val());
        });
        $('.g6_12_grade_hour_total_hours_45_89').html(hours_45_89_g6_12_sum);

        var hours_90_179_g6_12_sum = 0;
        $('.num-of-grade-hours_90_179-g6_12').each(function () {
            hours_90_179_g6_12_sum += Number($(this).val());
        });
        $('.g6_12_grade_hour_total_hours_90_179').html(hours_90_179_g6_12_sum);

        var hours_180_269_g6_12_sum = 0;
        $('.num-of-grade-hours_180_269-g6_12').each(function () {
            hours_180_269_g6_12_sum += Number($(this).val());
        });
        $('.g6_12_grade_hour_total_hours_180_269').html(hours_180_269_g6_12_sum);

        var hours_more_270_g6_12_sum = 0;
        $('.num-of-grade-hours_more_270-g6_12').each(function () {
            hours_more_270_g6_12_sum += Number($(this).val());
        });
        $('.g6_12_grade_hour_total_hours_more_270').html(hours_more_270_g6_12_sum);

        //Add total and display total of race
        var sum = 0;
        $('.pk-5-number-of-race-input').each(function () {
            sum += Number($(this).val());
        });
        $('#prek_5_race_count').html(sum);
        if (sum > preksum) {
            $('#prek_5_race_status').html('Over!');
            $('#prek_5_race_status').parent().addClass('over').removeClass('good').removeClass('almost');
            $(".save-participation-btn").prop('disabled', true).addClass("disabled");
            $('#gotonext2').prop('disabled', true);
        } else if ((sum == preksum) && (sum != 0)) {
            $('#prek_5_race_status').html('Great!');
            $('#prek_5_race_status').parent().addClass('good').removeClass('almost').removeClass('over');
            $('#gotonext2').prop('disabled', false);
        } else {
            $('#prek_5_race_status').html('Almost!');
            $('#prek_5_race_status').parent().addClass('almost').removeClass('good').removeClass('over');
            $(".save-participation-btn").prop('disabled', true).addClass("disabled");
            $('#gotonext2').prop('disabled', true);
        }

        var sum = 0;
        $('.6-12-number-of-race-input').each(function () {
            sum += Number($(this).val());
        });
        $('#g6_12_race_count').html(sum);
        if (sum > g6sum) {
            $('#g6_12_race_status').html('Over!');
            $('#g6_12_race_status').parent().addClass('over').removeClass('good').removeClass('almost');
            $(".save-participation-btn").prop('disabled', true).addClass("disabled");
            $('#gotonext6').prop('disabled', true);
        } else if ((sum == g6sum) && (sum != 0)) {
            $('#g6_12_race_status').html('Great!');
            $('#g6_12_race_status').parent().addClass('good').removeClass('almost').removeClass('over');
            $('#gotonext6').prop('disabled', false);
        } else {
            $('#g6_12_race_status').html('Almost!');
            $('#g6_12_race_status').parent().addClass('almost').removeClass('good').removeClass('over');
            $(".save-participation-btn").prop('disabled', true).addClass("disabled");
            $('#gotonext6').prop('disabled', true);
        }

        //Add total and display total of sex
        var sum = 0;
        $('.pk-5-number-of-sex-input').each(function () {
            sum += Number($(this).val());
        });
        $('#prek_5_sex_count').html(sum);
        if (sum > preksum) {
            $('#prek_5_sex_status').html('Over!');
            $('#prek_5_sex_status').parent().addClass('over').removeClass('good').removeClass('almost');
            $(".save-participation-btn").prop('disabled', true).addClass("disabled");
            $('#gotonext3').prop('disabled', true);
        } else if ((sum == preksum) && (sum != 0)) {
            $('#prek_5_sex_status').html('Great!');
            $('#prek_5_sex_status').parent().addClass('good').removeClass('almost').removeClass('over');
            $('#gotonext3').prop('disabled', false);
        } else {
            $('#prek_5_sex_status').html('Almost!');
            $('#prek_5_sex_status').parent().addClass('almost').removeClass('good').removeClass('over');
            $(".save-participation-btn").prop('disabled', true).addClass("disabled");
            $('#gotonext3').prop('disabled', true);
        }

        var sum = 0;
        $('.6-12-number-of-sex-input').each(function () {
            sum += Number($(this).val());
        });
        $('#g6_12_sex_count').html(sum);
        if (sum > g6sum) {
            $('#g6_12_sex_status').html('Over!');
            $('#g6_12_sex_status').parent().addClass('over').removeClass('good').removeClass('almost');
            $(".save-participation-btn").prop('disabled', true).addClass("disabled");
            $('#gotonext7').prop('disabled', true);

        } else if ((sum == g6sum) && (sum != 0)) {
            $('#g6_12_sex_status').html('Great!');
            $('#g6_12_sex_status').parent().addClass('good').removeClass('almost').removeClass('over');
            $('#gotonext7').prop('disabled', false);
        } else {
            $('#g6_12_sex_status').html('Almost!');
            $('#g6_12_sex_status').parent().addClass('almost').removeClass('good').removeClass('over');
            $(".save-participation-btn").prop('disabled', true).addClass("disabled");
            $('#gotonext7').prop('disabled', true);
        }


        // validate pk_5 lelp
        if ($('#pk5_lelp_0').val() > parseInt($('.prek_5_total').html())) {
            $('#prek_5_lelp_status').parent().removeClass('hidden').addClass('over');
            $('#prek_5_lelp_status').html('should not exceed total number of participants!');
            $('#pk5_lelp_0').val(parseInt($('.prek_5_total').html()));
        } else {
            $('#prek_5_lelp_status').parent().addClass('hidden').removeClass('over');
            $('#prek_5_lelp_status').html('');
        }

        // validate pk_5 frpl
        if ($('#pk5_frpl_1').val() > parseInt($('.prek_5_total').html())) {
            $('#prek_5_frpl_status').parent().removeClass('hidden').addClass('over');
            $('#prek_5_frpl_status').html('should not exceed total number of participants!');
            $('#pk5_frpl_1').val(parseInt($('.prek_5_total').html()));
        } else {
            $('#prek_5_frpl_status').parent().addClass('hidden').removeClass('over');
            $('#prek_5_frpl_status').html('');
        }

        // validate pk_5 sn
        if ($('#pk5_sn_2').val() > parseInt($('.prek_5_total').html())) {
            $('#prek_5_sn_status').parent().removeClass('hidden').addClass('over');
            $('#prek_5_sn_status').html('should not exceed total number of participants!');
            $('#pk5_sn_2').val(parseInt($('.prek_5_total').html()));
        } else {
            $('#prek_5_sn_status').parent().addClass('hidden').removeClass('over');
            $('#prek_5_sn_status').html('');
        }

        // validate 6_12 lelp
        if ($('#g6_12_lelp_0').val() > parseInt($('.g6_12_total').html())) {
            $('#g6_12_lelp_status').parent().removeClass('hidden').addClass('over');
            $('#g6_12_lelp_status').html('should not exceed total number of participants!');
            $('#g6_12_lelp_0').val(parseInt($('.g6_12_total').html()));
        } else {
            $('#g6_12_lelp_status').parent().addClass('hidden').removeClass('over');
            $('#g6_12_lelp_status').html('');
        }

        // validate 6_12 frpl
        if ($('#g6_12_frpl_1').val() > parseInt($('.g6_12_total').html())) {
            $('#g6_12_frpl_status').parent().removeClass('hidden').addClass('over');
            $('#g6_12_frpl_status').html('should not exceed total number of participants!');
            $('#g6_12_frpl_1').val(parseInt($('.g6_12_total').html()));
        } else {
            $('#g6_12_frpl_status').parent().addClass('hidden').removeClass('over');
            $('#g6_12_frpl_status').html('');
        }

        // validate 6_12 sn
        if ($('#g6_12_sn_2').val() > parseInt($('.g6_12_total').html())) {
            $('#g6_12_sn_status').parent().removeClass('hidden').addClass('over');
            $('#g6_12_sn_status').html('should not exceed total number of participants!!');
            $('#g6_12_sn_2').val(parseInt($('.g6_12_total').html()));
        } else {
            $('#g6_12_sn_status').parent().addClass('hidden').removeClass('over');
            $('#g6_12_sn_status').html('');
        }

        var count = $(".good").length;

        if (preksum > 0 && g6sum > 0) {
            if (count === 6) {
                $(".save-participation-btn").prop('disabled', false);
                if ($(".save-participation-btn").hasClass("disabled")) {
                    $(".save-participation-btn").removeClass("disabled");
                }
            }
        } else {
            if (count === 3) {
                $(".save-participation-btn").prop('disabled', false);
                if ($(".save-participation-btn").hasClass("disabled")) {
                    $(".save-participation-btn").removeClass("disabled");
                }
            }
        }

        /* clear grade hour entries if no grade total */
        if (preksum === 0) {
            // 1 reset grade hour for prek to 5
            $('.grade-hour-prek-5').val("");
            $('.pk-5-number-of-race-input').val("");
            $('.pk-5-number-of-sex-input').val("");

            $.ajax({
                url: 'https://' + app_url_participation + '/ajax/reset_grade_hours?type=1',
                type: 'POST',
                data: $('.grade-participationform').serialize(),
                success: function (res) {
                    if (res.status === 'success') {
                        // if (undefined != res.data.redirect) {
                        //     window.location.replace(res.data.redirect);
                        // }
                    } else {
                        console.log('Error, ', res);
                    }
                },
                error: (err) => {
                    console.log('participation error', err);
                }
            });
        }
        if (g6sum === 0) {
            // 1 reset grade hour for 6 to 12
            $('.grade-hour-6-12').val("");
            $('.6-12-number-of-race-input').val("");
            $('.6-12-number-of-sex-input').val("");

            $.ajax({
                url: 'https://' + app_url_participation + '/ajax/reset_grade_hours?type=2',
                type: 'POST',
                data: $('.grade-participationform').serialize(),
                success: function (res) {
                    if (res.status === 'success') {
                        //window.location. v(res.redirect);
                    } else {
                        console.log('Error: ', res);
                    }
                },
                error: (err) => {
                    console.log('participation error', err);
                }
            });

        }
    }


    $(document).on('click', '.grade-participationform .plus', function (e) {

        if (api_state_flag === 1 || view_only_mode_flag === 1) {
            return false;
        }

        e.preventDefault();
        // clear auto save cache
        localStorage.clear();

        get_total();
        updateTotals();

        // prepare url for createGradePart($center_id, $participation_ccount, $attend_type);
        var initial_url = $('.grade-participationform').attr('action');
        var urls = initial_url.split('/');
        var student;
        if ($(this).siblings('input').attr('id').substr(0, 7) == 'student') {
            student = '/' + $(this).siblings('input').attr('id').substr(8);
        } else {
            student = '/_/update_by_plus/';
        }
        var update_url_action =
            '/' + urls[urls.length - 3]
            + '/' + urls[urls.length - 2]
            + '/' + student
            + '/update_student'
            + '/' + urls[urls.length - 1];


        $.ajax({
            url: update_url_action,
            type: 'POST',
            data: $('.grade-participationform').serialize(),
            error: (err) => {
                console.log('participation error', err);
            }
        });

        validateParticipationData();
    });

    $(document).on('click', '.grade-participationform .minus', function (e) {

        if (api_state_flag === 1 || view_only_mode_flag === 1) {
            return false;
        }

        e.preventDefault();
        // clear auto save cache
        localStorage.clear();

        get_total();
        updateTotals();

        // prepare url for createGradePart($center_id, $participation_ccount, $attend_type);
        var initial_url = $('.grade-participationform').attr('action');
        var urls = initial_url.split('/');
        var student;
        var student;
        if ($(this).siblings('input').attr('id').substr(0, 7) == 'student') {
            student = '/' + $(this).siblings('input').attr('id').substr(8);
        } else {
            student = '/_/update_by_minus';
        }
        var update_url_action = '/' + urls[urls.length - 3]
            + '/' + urls[urls.length - 2]
            + '/' + student
            + '/update_student'
            + '/' + urls[urls.length - 1];

        $.ajax({
            url: update_url_action,
            type: 'POST',
            data: $('.grade-participationform').serialize(),
            error: (err) => {
                console.log('participation error', err);
            }
        });

        validateParticipationData();
    });

    $(document).on('focusout', '.number-of-participant-input', function (e) {

        e.preventDefault();
        // clear auto save cache
        localStorage.clear();

        get_total();
        updateTotals();

        // prepare url for createGradePart($center_id, $participation_ccount, $attend_type);
        var initial_url = $('.grade-participationform').attr('action');
        var urls = initial_url.split('/');
        var update_url_action = '/' + urls[urls.length - 3]
            + '/' + urls[urls.length - 2]
            + '/' + $(this).attr('id').substr(8)
            + '/keyup-input'
            + '/' + urls[urls.length - 1];

        // ignore number-of-participant, fix key up issue in php
        if ($(this).attr('id').substr(0, 21) != 'number-of-participant') {

            $.ajax({
                url: update_url_action,
                type: 'POST',
                data: $('.grade-participationform').serialize(),
                error: (err) => {
                    console.log('participation error', err);
                }

            });
        }

        validateParticipationData();
    });

    $(document).on('keyup', '.number-of-grade_hour-input', function (e) {
        e.preventDefault();
        // clear auto save cache
        localStorage.clear();

        get_total();
        updateTotals();

        const rowNumber = $(e.target).closest('tr').attr('data-calc-row');
        validateGradeLevelsByHourBandRow(rowNumber);
        validateParticipationData();
    });

    $(document).on('focusout', '.number-of-grade_hour-input', function (e) {
        e.preventDefault();

        var id = $(this).attr('id');
        var hours = [
            'hours_less_15_',
            'hours_15_44_',
            'hours_45_89_',
            'hours_90_179_',
            'hours_180_269_',
            'hours_more_270_'
        ];

        var newId = '';
        for (var i = 0; i < hours.length; i++) {
            // includes() is not supported in Internet Explorer 11 (or earlier).
            var tmp = hours[i];
            if (id.includes(hours[i])) {
                newId = id.replace(tmp, '');
            }
        }

        // prepare url for createGradePart($center_id, $participation_ccount, $attend_type);
        var initial_url = $('.grade-participationform').attr('action');
        var urls = initial_url.split('/');

        var update_url_action = '/' + urls[urls.length - 3]
            + '/' + urls[urls.length - 2]
            + '/' + newId
            + '/keyup-input'
            + '/' + urls[urls.length - 1];

        $.ajax({
            url: update_url_action,
            type: 'POST',
            data: $('.grade-participationform').serialize(),
            error: (err) => {
                console.log('participation error', err);
            }
        });

        validateParticipationData();
    });

    $(document).on('keyup', '.number-of-detailpart-input', function (e) {
        e.preventDefault();
        // clear auto save cache
        localStorage.clear();

        get_total();
        updateTotals();
        validateParticipationData();
    });

    $(document).on('focusout', '.number-of-detailpart-input', function (e) {
        e.preventDefault();
        // clear auto save cache
        localStorage.clear();

        get_total();
        updateTotals();

        // prepare url for createGradePart($center_id, $participation_ccount, $attend_type);
        var initial_url = $('.grade-participationform').attr('action');
        var urls = initial_url.split('/');
        var update_url_action =
            '/' + urls[urls.length - 3]
            + '/' + urls[urls.length - 2]
            + '/' + '_'
            + '/keyup-input'
            + '/' + urls[urls.length - 1];


        $.ajax({
            url: update_url_action,
            type: 'POST',
            data: $('.grade-participationform').serialize(),
            error: (err) => {
                console.log('participation error', err);
            }
        });

        validateParticipationData();
    });


    $('.review-participation-btn').click(function (e) {
        if (api_state_flag == 1 || view_only_mode_flag == 1) {
            return false;
        }

        e.preventDefault();
        /* add reveiw class for validation on outcomes*/
        $('.participation-ul').addClass('review');
        $('.grade-participationform').addClass('review');
        $('.prek_5_participation-detail').addClass('review');
        $('.6_12_participation-detail').addClass('review');

        var $form = $('form[name="apr-grade-participation-form"]').serialize();

        console.log('ajax 10');
        $.ajax({
            url: '/ajax/review_participation',
            type: 'POST',
            data: $form,
            success: function (res) {
                if (res) {
                    $('.review-section').html(res);
                    $('html, body').animate({
                        scrollTop: 0
                    }, 900, function () {
                        $('.participation-section').removeClass('visible').addClass('completed-section');
                        $('.review-section').addClass('visible');
                    });
                } else {
                    alert('Unable to process request');
                }
            },
            error: (err) => {
                console.log('participation error', err);
            }
        });

        validateParticipationData();
    });

    $('#gotonext3').click(function (e) {
        if (api_state_flag == 1 || view_only_mode_flag == 1) {
            return false;
        }

        e.preventDefault();
        /* enable outcome nav-button and nav-top-bar*/
        if ($(".outcomes").hasClass("ghost-button")) {
            $(".outcomes").removeClass("ghost-button");
        }
        if ($("#outcomes_button").hasClass("inactive")) {
            $("#outcomes_button").removeClass("inactive");
            var replace_href;
            var current_href = window.location.pathname;

            replace_href = "/outcome/" + current_href.split('/')[2] + '/edit';
            $('#outcomes_button').prop('href', replace_href);
        }

        validateParticipationData();
    });

    $(document).on('click', '.grade-participationform .review .plus, .prek_5_participation-detail .review .plus, .6_12_participation-detail .review .plus, .review .checker', function (e) {
        if (api_state_flag == 1 || view_only_mode_flag == 1) {
            return false;
        }

        e.preventDefault();
        /* validate for outcomes */
        validate_part_balance();
        validateParticipationData();
    });

    $(document).on('click', '.grade-participationform .review .minus, .prek_5_participation-detail .review .minus, .6_12_participation-detail .review .minus', function (e) {

        if (api_state_flag == 1 || view_only_mode_flag == 1) {
            return false;
        }

        e.preventDefault();
        /* validate for outcomes */
        validate_part_balance();
        validateParticipationData();
    });

    $(document).on('keyup', '.grade-participationform .review .number-of-participant-input', function (e) {
        e.preventDefault();
        /* validate for outcomes */
        validate_part_balance();
        validateParticipationData();
    });

    $(document).on('keyup', '.grade-participationform .review .number-of-detailpart-input', function (e) {
        e.preventDefault();
        /* validate for outcomes */
        validate_part_balance();
        validateParticipationData();
    });

    //validate Checker class, if not checker has check, then hide continue review button, and active continue button
    $('.participation-ul .checker').click(function (e) {
        if (api_state_flag == 1 || view_only_mode_flag == 1) {
            return false;
        }

        var preksum = 0;
        $('.num-of-grade-prek').each(function () {
            preksum += Number($(this).val());
        });
        var g6sum = 0;
        $('.num-of-grade-g6_12').each(function () {
            g6sum += Number($(this).val());
        });

        if ((preksum + g6sum == 0) && $('.participation-ul').hasClass('review')) {
            $('.review-participation-btn').hide();
            setTimeout(function () {
                location.reload();
            }, 2000);
        }

        validateParticipationData();
    });


    $(document).on('click', '.edit-particpation-section', function () {

        if (api_state_flag == 1 || view_only_mode_flag == 1) {
            return false;
        }

        $('html, body').animate(900, function () {
            $('.participation-section').addClass('visible').removeClass('completed-section');
            $('.review-section').removeClass('visible');

        });
    });

    $(document).on('click', '.save-participation-btn', function (e) {

        if (api_state_flag == 1 || view_only_mode_flag == 1) {
            if ($(this).hasClass('navigate-staffing')) {
                window.location.replace($('.grade-participationform').attr('prev'));
            } else {
                window.location.replace($('.grade-participationform').attr('next'));
            }
            return false;
        }
        let me = $(this);

        e.preventDefault();
        // clear auto save cache
        localStorage.clear();
        console.log('ajax 11');
        $.ajax({
            url: $('.grade-participationform').attr('save'),
            type: 'POST',
            data: $('.grade-participationform').serialize(),
            success: function (res) {
                if (me.hasClass('navigate-staffing')) {
                    window.location.replace($('.grade-participationform').attr('prev'));
                } else {
                    window.location.replace($('.grade-participationform').attr('next'));
                }
            },
            error: (err) => {
                $('.notification span').text('Error!')
                $('.notification p').text(err.message);
                $('.notification').addClass('active warning');
            }
        });
    });

    /**
     * Participant Input = allow numeric values 0-9 only AND also empty/null field
     */
    $(document).on('keyup', '.number-of-grade_hour-input.numbersonly', function (e) {
        e.preventDefault();
        let input_value = this.value.trim();
        if (input_value !== "" && input_value !== undefined && input_value >= 0) {
            this.value = this.value.replace(/[^0-9\.]/g, '').substring(0, 4);
        } else {
            this.value = "";
        }

        validateParticipationData();
    });

    // update the total for the section

    function updateSectionTotalSum(section, reportedValuesSum) {

        // get all the total-count for the section
        let sum = 0;

        const SELECTOR = section + ' .total-count';

        $(SELECTOR).each(function (i, el) {

            let nr = Number($(el).html());
            if (!isNaN(nr)) {
                sum += nr;
            }

        });

        // get sums of the sections

        const elTotalCountSum = $(section + ' .total-count-sum');

        updateTotalWordsAndClasses(elTotalCountSum, sum, reportedValuesSum, true);
    }

    /**
     * 
     * @param {*} values 
     * @returns int
     */
    function getSumReportedValues(values) {
        sum = 0;

        values.forEach((val) => {
            sum += val;
        });

        return sum;
    }


    function updateTotalWordsAndClasses(element, sum, reported, isSectionSum = false) {

        let gradeLevelTotalWord = ''
        const CLASS_ALMOST = 'grade-levels-total-almost';
        const CLASS_OVER = 'grade-levels-total-over';
        const CLASS_GREAT = 'grade-levels-total-great';
        // ALMOST
        if (sum < reported) {

            // remove classes
            $(element).closest('td').removeClass(CLASS_OVER + ' ' + CLASS_GREAT);

            // add the class
            $(element).closest('td').addClass(CLASS_ALMOST);
            // HTML word
            gradeLevelTotalWord = 'Almost!';
            // OVER
        } else if (sum > reported) {

            // remove classes
            $(element).closest('td').removeClass(CLASS_ALMOST + ' ' + CLASS_GREAT);

            // add the class
            $(element).closest('td').addClass(CLASS_OVER);
            // HTML word
            gradeLevelTotalWord = 'Over!';
        } else if (reported == sum && sum != 0) {
            // remove classes
            $(element).closest('td').removeClass(CLASS_OVER + ' ' + CLASS_ALMOST);
            // HTML word
            gradeLevelTotalWord = 'Great!';
            $(element).closest('td').addClass(CLASS_GREAT);
        } else {
            $(element).closest('td').removeClass(CLASS_OVER + ' ' + CLASS_ALMOST + ' ' + CLASS_GREAT);
        }
        let htmlString = gradeLevelTotalWord;
        if (gradeLevelTotalWord != '') {
            htmlString += '<br />';
        }

        // distinguish if used for the section's total or not
        if (isSectionSum === true) {
            htmlString += 'Total: ' + sum + '/' + reported;
        } else {
            htmlString += sum + '/' + reported;
        }

        $(element).html(htmlString);
    }


    function updateTotalsSexSection(reportedPreKTotal, reported6th12thTotal) {
        if (undefined == reportedPreKTotal) {
            reportedPreKTotal = getReportedValues_PreK(true);
        }

        if (undefined == reported6th12thTotal) {
            reported6th12thTotal = getReportedValues_6th_12th_Grades(true);
        }

        const enteredPreKTotal = getSummarySex_PreK();

        const entered6_12Total = getSummarySex_6th_12th();


        const element = 'span.sex-section-total-pre-k';
        updateElementTotalResultsStyleAndTheWord(element, reportedPreKTotal, enteredPreKTotal, 'total-sex-over', 'total-sex-almost', 'total-sex-great');

        const element2 = 'span.sex-section-total-6th-12th';
        updateElementTotalResultsStyleAndTheWord(element2, reported6th12thTotal, entered6_12Total, 'total-sex-over', 'total-sex-almost', 'total-sex-great');

    }

    function updateTotalsRaceEthnicitySection(reportedPreKTotal, reported6th12thTotal) {

        if (undefined == reportedPreKTotal) {
            reportedPreKTotal = getReportedValues_PreK(true);
            console.group('reportedPreKTotal : ' + reportedPreKTotal + ' has not been defined');
        }

        if (undefined == reported6th12thTotal) {
            reported6th12thTotal = getReportedValues_6th_12th_Grades(true);

            console.group('reported6th12thTotal : ' + reported6th12thTotal + ' has not been defined');
        }

        const enteredPreKTotal = getSummaryRaceEthicity_PreK();

        const entered6_12Total = getSummaryRaceEthicity_6th_12th();


        const element = 'span.race-ethnicity-section-total-pre-k';
        updateElementTotalResultsStyleAndTheWord(element, reportedPreKTotal, enteredPreKTotal, 'total-race-ethnicity-over', 'total-race-ethnicity-almost', 'total-race-ethnicity-great');

        const element2 = 'span.race-ethnicity-section-total-6th-12th';
        updateElementTotalResultsStyleAndTheWord(element2, reported6th12thTotal, entered6_12Total, 'total-race-ethnicity-over', 'total-race-ethnicity-almost', 'total-race-ethnicity-great');
    }


    function updateElementTotalResultsStyleAndTheWord(element, reportedValue, enteredValue, overClass, almostClass, greatClass) {

        gradeLevelTotalWord = '';

        // ALMOST
        if (enteredValue < reportedValue) {
            // remove classes
            $(element).closest('td').removeClass(overClass + ' ' + greatClass);

            // add the class
            $(element).closest('td').addClass(almostClass);
            // HTML word
            gradeLevelTotalWord = 'Almost!';
            // OVER
        } else if (enteredValue > reportedValue) {
            // remove classes
            $(element).closest('td').removeClass(almostClass + ' ' + greatClass);

            // add the class
            $(element).closest('td').addClass(overClass);
            // HTML word
            gradeLevelTotalWord = 'Over!';
        } else if ((enteredValue == reportedValue) && (reportedValue != 0)) {
            // remove classes
            $(element).closest('td').removeClass(overClass + ' ' + almostClass);

            $(element).closest('td').addClass(greatClass);
            // HTML word
            gradeLevelTotalWord = 'Great!';

        } else {
            // this is 0 == 0 and we show no text
        }

        let htmlString = gradeLevelTotalWord;

        if (gradeLevelTotalWord != '') {
            htmlString += '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;';
        }
        htmlString += enteredValue + '/' + reportedValue;

        $(element).html(htmlString);
    }

    function getSummarySex_6th_12th() {
        const inputs = $('input.6-12-number-of-sex-input');

        let sum = 0;
        inputs.each(function (i, el) {
            let nr = Number($(el).val());
            if (!isNaN(nr)) {
                sum += nr;
            }
        });

        return sum;
    }

    function getSummarySex_PreK() {
        const inputs = $('input.pk-5-number-of-sex-input');

        let sum = 0;
        inputs.each(function (i, el) {
            let nr = Number($(el).val());
            if (!isNaN(nr)) {
                sum += nr;
            }
        });

        return sum;
    }

    $('.pk-5-number-of-sex-input').change(() => {
        updateTotalsSexSection();
    });
    
    function displayAlert(message)
    {
        $('.notification span').text('Alert!');
        $('.notification p').html(message);
        $('.notification').removeClass('success');
        $('.notification').addClass('active warning');
    }
    
    function displayDefaultValidationError()
    {
        let message = 'There is an error with your Participation data. <br> ' +
            'Please carefully review and fix the error in the data you have entered. <br> ' +
            'Once completed, the Submit button will be enabled.';
        displayAlert(message);
    }
    
    function getSummaryRaceEthicity_6th_12th() {
        const inputs = $('input.6-12-number-of-race-input');

        let sum = 0;
        inputs.each(function (i, el) {
            let nr = Number($(el).val());
            if (!isNaN(nr)) {
                sum += nr;
            }
        });

        return sum;
    }

    function getSummaryRaceEthicity_PreK() {
        const inputs = $('input.pk-5-number-of-race-input');

        let sum = 0;
        inputs.each(function (i, el) {
            let nr = Number($(el).val());
            if (!isNaN(nr)) {
                sum += nr;
            }
        });

        return sum;
    }

    $('.pk-5-number-of-race-input, .6-12-number-of-race-input').change(() => {
        updateTotalsRaceEthnicitySection();
    });

    // On edit participation and view participation, wait until window is loaded - all images, styles-sheets, fonts, links, and other media assets
    if ($(".edit-participation.wrapper, .view-participation.wrapper").length >= 1) {
        // wait until window is loaded - all images, styles-sheets, fonts, links, and other media assets
        $(window).on("load", function () {
            // OPTIONAL - waits til next tick render to run code (prevents running in the middle of render tick)
            window.requestAnimationFrame(function () {
                activitiesComplete.play();
                activitiesComplete.restart();
                staffingComplete.play();
                staffingComplete.restart();
            });
        });
    }


    var api_state_flag = 0;
    var view_only_mode_flag = 0;
    $.fn.participation_view_only = function () {
        var form = this;
        api_state_flag = $(form).find('input[name="api_state_flag"]').val();
        view_only_mode_flag = $(form).find('input[name="view_only_mode_flag"]').val();
        showlog('participation.js => the api_state_flag IS SET to ' + api_state_flag);
        showlog('participation.js => the view_only_mode_flag IS SET to ' + view_only_mode_flag);
        if (api_state_flag == 1 || view_only_mode_flag == 1) {
            $(".plus, .minus, .number-of-participant-input, .number-of-grade_hour-input, .number-of-detailpart-input").attr('disabled', 'disabled');
            $("label.checker").removeAttr('tabindex');
        }
    }

    $('#apr-grade-participation-form').participation_view_only();

    function validateParticipationData() {
        let data_is_valid = true;
        let regex = /^.*Total\:\s/;
        let prek_5_total_hours_set = $('#prek_5_total_hours_set').html();
        prek_5_total_hours_set = prek_5_total_hours_set.replace(regex, '');

        if (prek_5_total_hours_set.includes('/')) {
            prek_5_total_hours_set = prek_5_total_hours_set.split('/');

            if (prek_5_total_hours_set[0] != prek_5_total_hours_set[1]) data_is_valid = false;
        } else {
            data_is_valid = false;
        }

        let g6_12_total_hours_set = $('#g6_12_total_hours_set').html();
        g6_12_total_hours_set = g6_12_total_hours_set.replace(regex, '');

        if (g6_12_total_hours_set.includes('/')) {
            g6_12_total_hours_set = g6_12_total_hours_set.split('/');

            if (g6_12_total_hours_set[0] != g6_12_total_hours_set[1]) data_is_valid = false;
        } else {
            data_is_valid = false;
        }

        regex = /^((Almost|Great)\!(\&nbsp\;)*)?/;
        let prek_5_total_race_set = $('#prek_5_total_race_set').html();
        prek_5_total_race_set = prek_5_total_race_set.replace(regex, '');

        if (prek_5_total_race_set.includes('/')) {
            prek_5_total_race_set = prek_5_total_race_set.split('/');

            if (prek_5_total_race_set[0] != prek_5_total_race_set[1]) data_is_valid = false;
        } else {
            data_is_valid = false;
        }

        let g6_12_total_race_set = $('#g6_12_total_race_set').html();
        g6_12_total_race_set = g6_12_total_race_set.replace(regex, '');

        if (g6_12_total_race_set.includes('/')) {
            g6_12_total_race_set = g6_12_total_race_set.split('/');

            if (g6_12_total_race_set[0] != g6_12_total_race_set[1]) data_is_valid = false;
        } else {
            data_is_valid = false;
        }

        let prek_5_total_sex_set = $('#prek_5_total_sex_set').html();
        prek_5_total_sex_set = prek_5_total_sex_set.replace(regex, '');

        if (prek_5_total_sex_set.includes('/')) {
            prek_5_total_sex_set = prek_5_total_sex_set.split('/');

            if (prek_5_total_sex_set[0] != prek_5_total_sex_set[1]) data_is_valid = false;
        } else {
            data_is_valid = false;
        }

        let g6_12_total_sex_set = $('#g6_12_total_sex_set').html();
        g6_12_total_sex_set = g6_12_total_sex_set.replace(regex, '');

        if (g6_12_total_sex_set.includes('/')) {
            g6_12_total_sex_set = g6_12_total_sex_set.split('/');

            if (g6_12_total_sex_set[0] != g6_12_total_sex_set[1]) data_is_valid = false;
        } else {
            data_is_valid = false;
        }

        if (data_is_valid) {
            $(".save-participation-btn")
                .prop("disabled", false)
                .removeClass("disabled");
        } else {
            $(".save-participation-btn")
                .prop('disabled', true)
                .addClass("disabled");
        }
        return data_is_valid;
    }
    
    if ($(".edit-participation.wrapper").length >= 1) {
            // Once the edit participation page has been loaded. Validate participation data
            validateParticipationData();

        // We will bind the event below, only to the participation page
        $( window ).on( "scroll", function() {
            if(elementInViewPort('.save-participation-btn'))
            {
                let validation_result = validateParticipationData();
                if(validation_result == false && scrollDirectionIsDown(pre_event_y_scroll))
                {
                    displayDefaultValidationError();
                }
                pre_event_y_scroll = window.scrollY;
            }
        } );
    }


}); // end (document).ready
