(function($) {
    $("input, textarea").attr("autocomplete", "off");
    $.fn.tooltip = function() {
        // Close if user presses 'Enter', 'ESC', or 'X'
        const ACCEPTED_KEYCODES = [13,27,88];

        function closeDialogModal(element) {
            $(element)
                .removeClass("open")
                .find(".tool-container")
                .html("");
        }

        $("body").on("click keydown", ".tooltip", function(e) {
            if(e.type == "click" || (e.type == "keydown" && e.keyCode == 13)) {
                e.preventDefault();


                if (!$(".tooltip-bucket").hasClass("open")) {
                    var title = $(this).attr("title");
                    var content = $(this).data("tipbody");
                    var link = $(this).data("tiplink");
                    //store element that triggered the modal
                    $("body").data("modal_trigger", $(e.target));
                    var string = $(location)
                        .attr("href")
                        .split("/");

                    $(".tooltip-bucket .tool-container").html(""); /* clear it first before appending */
                    if (string[2] == "21apr.ed.gov" || string[2] == "test21apr.ed.gov") {
                        $(".tool-container").append(
                            "<div class='dialog-header'><h3>" + title + "</h3><button type='button' id='close-modal' class='dialog dialog-icon-close close-tooltip'  tabindex='1'></button></div>"
                        );
                    } else {
                        $(".tool-container").append(
                            "<div class='dialog-header'><h3>" + title + "</h3><button type='button' id='close-modal' class='dialog dialog-icon-close close-tooltip' tabindex='1'></button></div>"
                        );
                    }
                    if (!link) {
                        $(".tooltip-bucket .tool-container").append("<p>" + content + "</p>");
                    } else {
                        $(".tooltip-bucket .tool-container").append(
                            "br<p>" +
                            content +
                            "<a href='" +
                            link +
                            "' target='_blank'>" +
                            link +
                            "</a>" +
                            "</p>"
                        );
                    }


                    $(".tooltip-bucket").addClass("open");
                    $($('.tooltip-bucket .dialog-content :focusable')[0]).focus();

                    // disable focus outside of modal
                    $(":focusable").not(".tooltip-bucket :focusable").attr('tabindex', '-1');

                }
            }
        });

        $(".tooltip").on("keydown", function(e){
            if(e.type == "keydown" && ACCEPTED_KEYCODES.includes(e.keyCode)){
                e.preventDefault();
                closeDialogModal(".tooltip-bucket");
                // return focus to the element that opened the modal
                $( "body" ).data("modal_trigger").focus();

                // reenable focus outside of modal
                $( ":focusable" ).not( ".tooltip-bucket :focusable" ).attr('tabindex', '0');
            }
        });

        $(".onboarding_popup .close-tooltip").click(function(e) {
            e.preventDefault();
            closeDialogModal(".onboarding_popup");
        });

        $(".tooltip-bucket .close-tooltip").on('click keydown',function(e) {
            if(e.type == "click" || (e.type == "keydown" && ACCEPTED_KEYCODES.includes(e.keyCode))) {
                e.preventDefault();
                closeDialogModal(".tooltip-bucket");
                // return focus to the element that opened the modal
                $("body").data("modal_trigger").focus();

                // reenable focus outside of modal
                $(":focusable").not(".tooltip-bucket :focusable").attr('tabindex', '0');
            }
        });

        $(document).on('click keydown', '.dialog-icon-close', function(e) {
            if(e.type == "click" || (e.type == "keydown" && ACCEPTED_KEYCODES.includes(e.keyCode))) {
                e.preventDefault();
                closeDialogModal(".tooltip-bucket");
                // return focus to the element that opened the modal
                $("body").data("modal_trigger").focus();

                // reenable focus outside of modal
                $(":focusable").not(".tooltip-bucket :focusable").attr('tabindex', '0');
            }
        });



        $(".res-close-popup").click(function(e) {
            e.preventDefault();
            $(".reporting-des-popup").removeClass("open");
            $("#reporting-des-dialog-overlay").attr('id', 'reporting-des-dialog-overlay hide');

        });

        $("#goto_stateconfig").click(function(e) {
            e.preventDefault();
            console.log('click button');
            window.location.replace("/sysusersetting/stateconfig");
        });
    };
})(jQuery);

/* function to call console.log */
function showlog(msg) {
    /* set debug mode, default is false, true is turn on and false is off */
    var DEBUG = false;
    if (DEBUG) {
        console.log(msg);
    }
}

/* close the tooltip if user clicks outside it */
$("body").click(function(e) {
    var tooltip_control = $(document).find(".tooltip");
    var tooltip_box = $(document).find(".tooltip-bucket");
    if ($(tooltip_box).hasClass("open")) {
        if (!tooltip_control.is(e.target) &&
            tooltip_control.has(e.target).length === 0 &&
            !tooltip_box.is(e.target) &&
            tooltip_box.has(e.target).length === 0
        ) {
            $(tooltip_box).removeClass("open");
        }
    }
});

function isRestrictedPathName(url){
    const STATE_CERTIFICATION_RESTRICTED_PATHS = [
        /dashboard\/*.*/,
        /grantees\/*.*/,
        /grantee\/*.*/,
        /center\/*.*/,
    ];
    return STATE_CERTIFICATION_RESTRICTED_PATHS.some(path => path.test(url));
}


function readURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function(e) {
            $(".avatar").attr("src", e.target.result);
            $(".avatar").fadeIn();
        };

        reader.readAsDataURL(input.files[0]);
    }
}

function makeFileList() {
    var input = document.getElementById("helpdesk-upload-file");
    showlog("makeFileList function");
    var ul = document.getElementById("fileList");
    while (ul.hasChildNodes()) {
        ul.removeChild(ul.firstChild);
    }
    for (var i = 0; i < input.files.length; i++) {
        var li = document.createElement("li");
        li.innerHTML = input.files[i].name;
        ul.appendChild(li);
    }
    if (!ul.hasChildNodes()) {
        var li = document.createElement("li");
        li.innerHTML = "No Files Selected";
        ul.appendChild(li);
    }
}

//Alert user of issue
$(document).ready(function() {
    if (window.location.pathname == '/dashboard') {
        $.ajax({
            url: "/ajax/get_issue_alert",
            type: "GET",
            success: function(data){
                if(data.issue_alert){
                    showNotification('error', data.issue_alert_text, false);
                }
            },
            error: function(xhr, textStatus, error){
                console.error(xhr.statusText);
                console.error(textStatus);
                console.error(error);
                alert("An error occurred")
            }
        });
    }
});

$(document).ready(function() {
    $(".avatar-file").change(function() {
        $(".sign-up").addClass("uploading");
        readURL(this);
    });

    if (window.location.pathname == '/login' && document.cookie.match(/^(.*;)?\s*warning_accepted\s*=\s*[^;]+(.*)?$/)) {
        acceptWarning();
    }

    if (isRestrictedPathName(window.location.pathname)){
        $.ajax({
            url: "/ajax/get_state_certification_status",
            type: "GET",
            success: function(data){
                if(data.certified && data.performance_period){
                    let view_only_mode_text = `Your State has completed reporting and certification for the ${data.performance_period} performance period. The 21APR system is currently closed for reporting and no new data can be entered. The system will remain in View-Only mode until July when it is set to reopen. Please contact the 21APR Help Desk if you have any questions or need further assistance. `;
                    showNotification('error', view_only_mode_text, false);

                    $(":button, a.button, textarea, input, a.button-delete, select").prop('disabled', true).addClass("disabled");
                   //Used to disable lazy loaded centers when viewing a grantee's information
                    $(document).on("load", ".center-list-item .season", function(e){
                        e.preventDefault();
                        $(":button, a.button, :input:checkbox").attr("disabled").addClass("disabled");
                    });
                    //When document lazy loads more grantees on the grantee list of the logged in user
                    $(document).on("change", ".holder .grantee-list-item ", function(e){
                        $(":button, a.button, input").prop('disabled', true).addClass("disabled");
                    });
                }
                if (!data.ignore) {
                    if (data.certified === null || data.performance_period === null) {
                        let no_data_warning_text = `Your State's performance period is not available or certification status could not be determined. Please contact the 21APR Help Desk for further assistance. `;
                        showNotification('error', no_data_warning_text, false);
                    }
                }
            },
            error: function(xhr, textStatus, error){
                console.error(xhr.statusText);
                console.error(textStatus);
                console.error(error);
                alert("An error occurred")
            }
        });
    }

    $(".upload-btn").click(function(e) {
        e.preventDefault();

        $(".avatar-file").click();
    });
    
    $(".main-content-button").focusin(function (){
        $(this).css('position', 'static');
    });
    
    $(".main-content-button").focusout(function (){
        $(this).css('position', 'absolute');
    });

    $(".helpdesk-upload-file").change(function() {
        $(".createissue").addClass("uploading");
    });

    $(".test-banner").ready(function() {
        if($(".test-banner").is(":visible")){
            $(($(".test-banner").find(":focusable"))[0]).focus();
        }
    });  

    $(".helpdesk-upload-btn").click(function(e) {
        e.preventDefault();
        $(".helpdesk-upload-file").click();
    });

    $("form, .center-form").submit(function() {
        $(".loading-overlay").fadeIn();
    });

    $(".open").click(function(e) {
        e.preventDefault();
        $(".main-nav").toggleClass("open");
    });

    $("input, textarea").placeholder();

    $(".close").click(function(e) {
        e.preventDefault();
        $(".main-nav").toggleClass("open");
    });

    $(".trigger").click(function(e) {
        e.preventDefault();
        $(this)
            .closest(".slide-button")
            .addClass("dbl-button");
    });

    /* close the main navigation box if user clicks outside it */
    $("body").click(function(e) {
        //console.log('close da main nav laddie!');
        var main_nav = $(document).find(".main-nav");
        if ($(main_nav).hasClass("open")) {
            if (!main_nav.is(e.target) && main_nav.has(e.target).length === 0) {
                $(main_nav).removeClass("open");
            }
        }
    });

    $(".grantee input").focus(function() {
        showlog("grantee input");
        if (!$(this).hasClass("dollar")) {
            $(".grantee li").removeClass("active");
            $(this)
                .closest("li")
                .addClass("active");
        }
    });

    $(".grantee select").focus(function() {
        showlog("grantee select");
        $(".grantee li").removeClass("active");
        $(this)
            .closest("li")
            .addClass("active");
    });

    $(".center input").focus(function() {
        showlog("center input");
        $(".center li").removeClass("active");
        $(this)
            .closest("li")
            .addClass("active");
    });

    $(".center select").focus(function() {
        showlog("center select");
        $(".center li").removeClass("active");
        $(this)
            .closest("li")
            .addClass("active");
    });

    $("body").on("click", ".action", function(e) {
        e.preventDefault();
        var parent_el = $(this).parents(".listed-user");
        var el = $(this);
        var uid = el.data("uid");

        /* flip arrow*/
        if (el.hasClass("icon-chevron-down")) {
            el.removeClass("icon-chevron-down").addClass("icon-chevron-up");
        } else if (el.hasClass("icon-chevron-up")) {
            el.removeClass("icon-chevron-up").addClass("icon-chevron-down");
        }

        $(".action_list_" + uid).slideToggle();
        $(".action_list_" + uid).removeClass("delete");
        $(".action_list_" + uid)
            .find(".doDelete")
            .first()
            .removeClass("doDelete");
        $(parent_el).addClass("open_toggle");
    });

    /* close the delete button if user clicks outside it */
    $("body").click(function(e) {
        showlog("body delete user");
        /* delete user button */
        var delete_user = $("section").find(".remove-edit-user");
        if (!delete_user.is(e.target) && delete_user.has(e.target).length === 0) {
            $(delete_user).removeClass("active");
        }

        /* lock user button */
        var lock_user = $("section").find(".unlock-user");
        if (!lock_user.is(e.target) && lock_user.has(e.target).length === 0) {
            $(lock_user).removeClass("active");
        }
    });

    /* delete Grantee button */
    $("body").click(function(e) {
        showlog("body delete grantee");
        /* lock user button */
        var remove_grantee = $("section").find(".remove-edit-grantee");
        if (!remove_grantee.is(e.target) &&
            remove_grantee.has(e.target).length === 0
        ) {
            $(remove_grantee).removeClass("active");
        }
    });

    $("body").on("click", ".disable-btn", function(e) {
        return false;
    });

    $("body").on("click", ".reset-btn", function(e) {
        var btn = $(this);
        console.log("in reset button");
        e.preventDefault();

        var id          = btn.data("uid");
        var active_ind  = btn.data("active");
        var token       = $(".token").val();
        var url         = $(this).attr("href");
        var resend_activate_url = "/user/" + id + "/resendActivateEmail";
        var segments            = url.split("/");
        var username            = btn.data("username");

        if (active_ind == 1) {
            $.post(
                url, {
                    csrf_token_name: token
                },
                function(res) {
                    /* Add for reset password*/
                    if (segments[1] == "user") {
                        $(".notification p").html(username + "'s password has been reset");
                    }
                    $(".notification")
                        .addClass("active")
                        .addClass("success")
                        .delay(5000)
                        .queue(function(next) {
                            $(this)
                                .removeClass("active")
                                .removeClass("success");
                            next();
                        });
                }
            );
        } else {
            if (segments[1] == "user") {
                $.post(
                    resend_activate_url, {
                        csrf_token_name: token
                    },
                    function(res) {

                        console.log(res);

                        if(res.status == 'ok') {
                            $(".notification p").html(
                                "Account has yet to be activated. Activation email has been re-sent."
                            );
                            $(".notification span.type").html(
                                "Success"
                            );

                            $(".notification")
                                .addClass("active")
                                .addClass("success")
                                .delay(5000)
                                .queue(function(next) {
                                    $(this)
                                        .removeClass("active")
                                        .removeClass("success");
                                    next();
                                });
                        } else {
                            $(".notification p").html(
                                "Sending confirmation email has been disabled by its directive."
                            );
                            $(".notification span.type").html(
                                "Error"
                            );

                            $(".notification")
                                .addClass("active")
                                .addClass("warning")
                                .delay(5000)
                                .queue(function(next) {
                                    $(this)
                                        .removeClass("active")
                                        .removeClass("warning");
                                    next();
                                });
                        }
                    }
                ).done(function() {
                    //console.log('send activate email : done ');
                }).fail(() => {
                    console.log('send activate email : fail ');
                    alert('An error has ocurred while performing the request');
                }).always(() => {
                    //console.log('send activate email : Always ');
                });
            }
        }
    });


    $("body").on("click", ".remove-btn", function(e) {
        var btn = $(this);
        e.preventDefault();

        var url = $(location).attr("href");
        var segments = url.split("/");
        var username = btn.data('username');
        var center = btn.data('center');
        var grantee = btn.data('grantee');
        //store element that triggered the modal
        $( "body" ).data("modal_trigger", btn);

        // get the screen height and width
        var maskHeight = $(document).height();
        var maskWidth = $(window).width();

        // calculate the values for center alignment
        var dialogTop = (maskHeight / 3) - ($('#dialog-box').height());
        var dialogLeft = (maskWidth / 2) - ($('#dialog-box').width() / 2);

        if (dialogTop > 100) {
            dialogTop = 100;
        }

        // assign values to the overlay and dialog box
        $('#dialog-overlay').css({ height: maskHeight, width: maskWidth }).show();
        $('#dialog-box').css({ top: dialogTop, left: dialogLeft }).show();
        $('.dialog.button.left').attr('data-userid', btn.data("uid"))
            .attr('data-uid', btn.data('uid'))
            .attr('data-gid', btn.data('gid'))
            .attr('data-center', btn.data('center'));
        $('.dialog.button.right').attr('id', 'okay');
        
        $($('.dialog-content :focusable')[0]).focus();
        // disable focus outside of modal
        $( ":focusable" ).not( "#dialog-box :focusable" ).attr('tabindex', '-1');
        
        if (segments[3] == "user" || segments[3] == "users") {
            $('.dialog.button.right').attr('data-id', 'user');
            $('.dialog.button.left').attr('data-id', 'user');
            $('.dialog.button.right').attr('data-userid', btn.data("uid"));
            $('.dialog.button.right').attr('data-user', username);
            $('.dialog.button.left').attr('data-user', username);


            // display the message
            $('#dialog-message').html('<h3>Delete User?</h3> <p>Are you sure you want to delete ' + username + '?</p>');
        } else if (segments[3] == "center") {
            $('.dialog.button.right').attr('data-id', 'center');
            $('.dialog.button.left').attr('data-id', 'center');
            $('.dialog.button.right').attr('data-deleteReason', '0');
            $('.dialog.button.right').attr('data-centerid', btn.data("uid"));
            $('.dialog.button.right').attr('data-gid', btn.data("gid"));
            $('.dialog.button.right').attr('data-center', btn.data("center"));

            // display the message
            $('.popCentername').html(center);
            return false;
        } else if (segments[3] == "grantee" && segments[5] == "overview") {
            $('.dialog.button.right').attr('data-id', 'center');
            $('.dialog.button.left').attr('data-id', 'center');
            $('.dialog.button.right').attr('data-deleteReason', '0');
            $('.dialog.button.right').attr('data-centerid', btn.data("uid"));
            $('.dialog.button.right').attr('data-gid', btn.data("gid"));
            $('.dialog.button.right').attr('data-center', btn.data("center"));
            $('.popCentername').html(center);
        }

        if (btn.hasClass("doDelete")) {
            var id = btn.data("uid");
            var csrf = $(".token").val();

            if (segments[3] == "user") {
                $.post(
                    url, {
                        csrf_token_name: csrf
                    },
                    function(res) {
                        if (res.ok) {
                            $(".record_" + id)
                                .fadeOut()
                                .remove();
                            $(".notification p").html("The user has been deleted!");
                            $(".notification")
                                .addClass("active")
                                .addClass("success")
                                .delay(5000)
                                .queue(function(next) {
                                    $(this)
                                        .removeClass("active")
                                        .removeClass("success");
                                    next();
                                });
                        }
                    }
                );
                $("#add-user").removeAttr("disabled");
            } else {
                /* achan add re-re-confirm message to warn the delete */
                if (segments[3] == "grantee") {
                    if (btn.hasClass("old")) {
                        var con_message = confirm(
                            "You are about to delete this Grantee from the current reporting year. (This action will not delete performance data from previous years.) If this Grantee has an existing award, do not delete it. If this Grantee has a new award, proceed with deleting this profile and create a new Grantee profile for the new award."
                        );
                    } else {
                        var con_message = confirm(
                            "You are about to delete this Grantee, all of its Centers, and all corresponding APR data entered for the current reporting year. Are you really sure you want to delete this Grantee?"
                        );
                    }
                } else {
                    var con_message = confirm(
                        "You are about to delete this Center and all corresponding APR data entered for the current reporting year. Are you really sure you want to delete this Center?"
                    );
                }

                // achan 08-06-2020 not sure we still use this after confirmation , we use main.js line 1543 on click #okay
                if (con_message == true) {
                    $.post(
                        url, {
                            csrf_token_name: csrf
                        },
                        function(res) {
                            if (res.ok) {
                                $(".record_" + id)
                                    .fadeOut()
                                    .remove();
                                if (segments[3] == "grantee") {
                                    $(".notification p").html("The grantee has been deleted!");
                                } else if (segments[1] == "center") {
                                    $(".notification p").html("The center has been deleted!");
                                }
                                $(".notification")
                                    .addClass("active")
                                    .addClass("success")
                                    .delay(5000)
                                    .queue(function(next) {
                                        $(this)
                                            .removeClass("active")
                                            .removeClass("success");
                                        next();
                                    });
                            }
                        }
                    );
                    $("#add-user").removeAttr("disabled");
                } else {
                    console.log('remove doDelete');
                    btn.removeClass("doDelete");
                }
            } //end of 	if ((segments[1] == 'grantee') || (segments[1] == 'center'))
        } else {
            if (segments[3] == "center") {
                btn.closest(".col-4-dropdown").addClass("delete");
            } else {
                btn.closest(".col-3-dropdown").addClass("delete");
            }
            if (segments[3] == "user") {
                btn.closest(".col-5-dropdown").addClass("delete");
            }
            btn.addClass("doDelete");
        }
    });


    // read more
    $('body').on("click", '.readmore', function(e) {
        e.preventDefault();
        var displaytext = $('.copyText').val();
        var rows_length = $('.rmore').data('length');
        console.log('rows_length: ' + rows_length);
        $(this).parents().prev('.rmore').text(displaytext);
        $(this).parents().prev('.rmore').attr('rows', rows_length);
        $(this).addClass('hidden');
    });


    // cancel delete
    $("body").on("click", ".delete", function(e) {
        $(this)
            .closest(".col-3-dropdown")
            .removeClass("delete");
        $(this)
            .closest(".col-5-dropdown")
            .removeClass("delete");
        $(this)
            .find(".doDelete")
            .first()
            .removeClass("doDelete");
    });

    $("#add-user").click(function(e) {
        window.location.replace("/user/createUser");
    });

    /* close the edit / delete box if user clicks outside it */
    $(
        "#user-users-page, #grantee-grantees-page, #grantee-viewGrantee-page"
    ).click(function(e) {
        var parent_el = $(this).find(".listed-user");
        var dropdown = $(this).find(".listed-user .col-4-dropdown");
        var arrow = $(this).find(".icon-chevron-down .action");
        var action_control = $(this).find(".listed-user .action");

        if (!parent_el.is(e.target) && parent_el.has(e.target).length === 0) {
            $(dropdown)
                .slideUp()
                .removeClass("delete");
        }
    });

    //achan 05-08-2015  Add delete button on overview page
    $(".remove-edit-grantee").click(function(e) {

        var granteename = $(this).data('granteename');
        var gid = $(this).data('gid');

        /* add delete message box */
        // get the screen height and width
        var maskHeight = $(document).height();
        var maskWidth = $(window).width();

        // calculate the values for center alignment
        var dialogTop = (maskHeight / 3) - ($('#dialog-box').height());
        var dialogLeft = (maskWidth / 2) - ($('#dialog-box').width() / 2);

        if (dialogTop > 100) {
            dialogTop = 100;
        }
        if (dialogLeft > 450) {
            dialogLeft = 450;
        }

        //store element that triggered the modal
        $( "body" ).data("modal_trigger", $(e.target));

        // assign values to the overlay and dialog box
        $('#dialog-overlay').css({ height: maskHeight, width: maskWidth }).show();
        $('#dialog-box').css({ top: dialogTop, left: dialogLeft }).show();
        $('.dialog.button.right').attr('id', 'okay');
        $('.dialog.button.right').attr('data-id', 'delete-grantee');
        $('.dialog.button.right').attr('data-deletereason', '0');
        $('.dialog.button.right').attr('data-gid', gid);
        $('.dialog.button.right').attr('data-granteename', granteename);
        $('.dialog.button.left').attr('data-id', 'delete-grantee');


        // display the message
        $(this).addClass("active");

        $('.popGrantname').html(granteename);
        $($('.dialog-content :focusable')[0]).focus();

    });

    $(".searching").keyup(function(e) {
        e.preventDefault();
        var el = $(this);
        var data = el.val();
        var token = $(".token").val();
        if (data.length) {
            $.get("/user/search?q=" + el.val(), function(res) {
                $(".holder").html(res);
            });
        } else {
            $.get("/user/search", function(res) {
                $(".holder").html(res);
            });
        }
    });

    $(".searching-grantees").keyup(function(e) {
        e.preventDefault();
        var el = $(this);
        var data = el.val();
        var token = $(".token").val();
        if (data.length) {
            $.get("/grantee/search?type=grantee&q=" + el.val(), function(res) {
                $(".holder").html(res);

            });
        } else {
            $.get("/grantee/search?type=grantee", function(res) {
                $(".holder").html(res);
            });
        }
    });

    function checkAPRDataButtonToggles(row, type) {
        let i = 0;
        if (row.find('.inactiveTeam[data-type="' + type + '"]').hasClass('active')) {
            row.find('.' + type).addClass('disabled');
        } else {
            row.find('.' + type).removeClass('disabled');
        }
    }

    $(".searching-centers").keyup(function(e) {
        e.preventDefault();
        let el = $(this),
            data = el.val(),
            subg_pkid = $(".center_search_subg_id").val(),
            url = "/grantee/search?type=center&subg_pkid=" + subg_pkid;

        if (data.length) {
            url = url + "&q=" + el.val();
        }

        $.get(url,
            function(res) {
                $(".holder").html(res);
                /* inactive term justification real time save*/
                $(".inactive_justification").change(function(e) {
                    e.preventDefault();
                    let item = $(this),
                        term_type = item.data('type'),
                        center_id = item.parents('.center-list-item').data('center');
                    term_fkid = item.attr('data-term_fkid'),
                        justification = item.find(':selected').val(),
                        update_url = '/main/update_inactive_justification?center_id=' + center_id + "&current_term_id=" + term_fkid;

                    checkAPRDataButtonToggles(item.parents('.center-list-item'), term_type);

                    if (justification == "0") {
                        /* warning message */
                        $('.inactive-msg').removeClass('hidden');
                    } else {
                        $('.inactive-msg').addClass('hidden');
                        update_url = update_url + '&justification=' + justification;
                    }

                    // call update comment
                    $.ajax({
                        url: update_url,
                        type: 'POST',
                        data: $('#inactive_term').serialize(),
                        success: function(res) {
                            //TODO: Add error checking?
                        }
                    });
                });

                $('.inactiveTeam').click(function(e) {
                    e.preventDefault();

                    /* set vars */
                    let item = $(this),
                        term_type = item.data('type'),
                        center_id = item.parents('.center-list-item').data('center'),
                        current_term_id = item.data('term'),
                        secondhalf = item.parents('.first-half').siblings('.second-half.' + term_type),
                        justification = item.find(':selected').val(),
                        inactiveMsg = item.parents('.inactive-terms').children('.second-half').children('.inactive-msg'),
                        noterm = item.data('noterm');

                    /* if checkbox is active, delete the inactive_term table, and toggle active*/
                    if (item.hasClass('active')) {
                        let update_url = '/main/delete_inactive_justification?center_id=' + center_id + "&current_term_id=" + current_term_id;

                        /* clear justification */
                        $('select[name^="inactive_justication_' + center_id + '"][data-type=' + term_type + ']').val(0);
                        secondhalf.find('textarea').val('').toggleClass('hidden');
                        item.removeClass('active').removeAttr('checked');
                        secondhalf.addClass('hidden');
                        inactiveMsg.addClass('hidden');
                        checkAPRDataButtonToggles(item.parents('.center-list-item'), term_type);

                        // call update comment
                        $.ajax({
                            url: update_url,
                            type: 'POST',
                            data: $('#inactive_term').serialize(),
                            success: function(res) {}
                        });
                    } else {
                        /* Skip the validate_apr because no active term as of today date */
                        if (noterm == 0) {
                            /* check APR data, if any apr data existing, error message pop and no data will be saved */
                            $.ajax({
                                url: '/main/validate_apr?center_id=' + center_id + '&current_term_id=' + current_term_id,
                                type: 'POST',
                                data: $('#inactive_term').serialize(),
                                success: function(res) {
                                    let obj = jQuery.parseJSON(res);
                                    console.log('script', obj);
                                    if (obj.status == 'true') {
                                        item.addClass('active').attr('checked', 'checked');
                                        secondhalf.removeClass('hidden');
                                        inactiveMsg.removeClass('hidden');
                                        checkAPRDataButtonToggles(item.parents('.center-list-item'), term_type);

                                        if (typeof(justification) === "undefined") {
                                            /* warning message */
                                            $(inactiveMsg).html('Please select your justification to set your center under the “Inactive term” option. <u>If justification is not selected, the "Inactive term" will not be set.</u>');
                                        } else {
                                            if ($('.notification').hasClass('warning')) {
                                                $('.notification').removeClass('active').removeClass('warning');
                                                /* scroll to top on the error message*/
                                                $("html, body").animate({ scrollTop: 0 }, "slow");
                                            }
                                            let update_url = '/main/update_inactive_justification?center_id=' + center_id + '&justification=' + justification;

                                            // call update comment
                                            $.ajax({
                                                url: update_url,
                                                type: 'POST',
                                                data: $('#inactive_term').serialize(),
                                                success: function(res) {}
                                            });
                                        }
                                    } else {
                                        let display_validate = '';
                                        let count = 0;
                                        Object.keys(obj).forEach(function(key) {
                                            if (obj[key] == '') {
                                                if (count == 0) {
                                                    display_validate = display_validate + " " + key;
                                                } else {
                                                    display_validate = display_validate + ", " + key;
                                                }
                                                count++;
                                            }
                                            showlog(key, obj[key]);
                                        });
                                        /* warning message */
                                        $('.notification p').html('You already reported APR data for this center, please delete ' + display_validate + ' data before you click inactive term checkbox.');
                                        $('.notification').addClass('active').addClass('warning');
                                        $('.notification  span').html('Alert!');
                                        /* scroll to top on the error message*/
                                        $("html, body").animate({ scrollTop: 0 }, "slow");
                                    }
                                }

                            }); /* end of validate_apr ajax*/
                        } else {
                            /* toggle */
                            item.addClass('active').attr('checked', 'checked');
                            secondhalf.removeClass('hidden');
                            inactiveMsg.removeClass('hidden');
                            checkAPRDataButtonToggles(item.parents('.center-list-item'), term_type);


                            if (typeof(justification) === "undefined") {
                                /* warning message */
                                inactiveMsg.html('Please select your justification to set your center under the “Inactive term” option. <u>If justification is not selected, the "Inactive term" will not be set.</u>');

                            } else {
                                if ($('.notification').hasClass('warning')) {
                                    $('.notification').removeClass('active').removeClass('warning');
                                    /* scroll to top on the error message*/
                                    $("html, body").animate({ scrollTop: 0 }, "slow");
                                }
                            }
                        } /*  if noterm */
                    } /* end of hasClass active */

                }); /* click on the checker */

                // Set them to defaults
                $('.center-list-item').each(function(item) {
                    checkAPRDataButtonToggles($(this), 'schoolyear');
                    checkAPRDataButtonToggles($(this), 'summer');
                });
            }
        );
    });

    if ($('.grantees-overview').length) {
        var polling = setInterval(function() {
            let hasEvents = $._data($(".searching-centers")[0], "events");
            if (hasEvents) {
                $(".searching-centers").trigger('keyup');
                clearInterval(polling);
            }
        }, 250)
    }


    $(".usercreate, .granteeupdate").submit(function() {
        $(".state-select").prop("disabled", false);
        $(".state-dropdown").prop("disabled", false);
        $(".access_lvl_dropdown").prop("disabled", false);
    });

    $(".disable").click(function(e) {
        e.preventDefault();
    });

    $(".main-nav").blur(function() {
        $(".main-nav").removeClass("open");
    });

    $(".tooltip").tooltip();

    $(".phone-input")
        .formatter({
            pattern: "{{999}}-{{999}}-{{9999}}"
        })
        .resetPattern();

    $(".zipcode-input").formatter({
        pattern: "{{99999}}"
    });

    $(".number-of-paid-input, .number-of-volunteer-input").formatter({
        pattern: "{{9999}}"
    });

    $(".number-of-participants-input, .number-of-hours-input").formatter({
        pattern: "{{9999}}"
    });

    $(".number-of-participant-input, .number-of-detailpart-input").formatter({
        pattern: "{{9999}}"
    });

    $(".grantee .num_of_new_grants").formatter({
        pattern: "{{9999}}"
    });

    $(".elt_2, .elt_4, .elt_6").formatter({
        pattern: "{{9999}}"
    });

    $(".money").formatter({
        pattern: "{{9999999}}"
    });

    // achan 05-05-2015  validate email address
    $(".email-input").focusout(function(e) {
        e.preventDefault();
        finalsubmit();

        var re = /[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,4}/gim;
        if (!re.test($(".email-input").val())) {
            $(".notification p").html(
                "The email address you entered is invalid. Please make sure the address is formatted as email@domain.com."
            );
            $(".notification")
                .addClass("active")
                .addClass("warning");
            $('.notification span').html('Alert!');
            return false;
        } else if ($(".email-input").val().length > 100) {
            $(".notification p").html(
                "The email address length is more than 100 characters. Please make sure the address length is less than 100 characters"
            );
            $(".notification")
                .addClass("active")
                .addClass("warning");
            $('.notification span').html('Alert!');
            return false;
        }
    });

    // achan 05-05-2015  validate email address
    $(".center-email-input").focusout(function(e) {
        e.preventDefault();
        //centerfinalsubmit();
        var re = /[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,4}/gim;
        if (!re.test($(".center-email-input").val())) {
            $(".notification p").html(
                "The email address you entered is invalid. Please make sure the address is formatted as email@domain.com."
            );
            $(".notification")
                .addClass("active")
                .addClass("warning");
            $('.notification span').html('Alert!');
            return false;
        } else if ($(".center-email-input").val().length > 100) {
            $(".notification p").html(
                "The email address length is more than 100 characters. Please make sure the address length is less than 100 characters"
            );
            $(".notification")
                .addClass("active")
                .addClass("warning");
            $('.notification span').html('Alert!');
            return false;
        }
    });

    // phone number validation, phone has to be 10 digits
    $(".phone-input").focusout(function(e) {
        e.preventDefault();
        finalsubmit();
        if ($(".phone-input").val().length < 12) {
            $(".notification p").html(
                "Please make sure you enter 10 digits for the phone number."
            );
            $(".notification")
                .addClass("active")
                .addClass("warning");
            $('.notification span').html('Alert!');
            return false;
        }
    });

    // copy center-zip to center-zip-hidden
    $(".center-zip").keyup(function(e) {
        $(".center-zip-hidden").val($(".center-zip").val());
    });

    $(".zipcode-input").focusout(function(e) {
        // finalsubmit();
        e.preventDefault();
        if ($(".zipcode-input").val().length < 5) {
            $(".notification p").html(
                "Please make sure you have a 5 digit zip code"
            );
            $(".notification")
                .addClass("active")
                .addClass("warning");
            $('.notification span').html('Alert!');
            return false;
        } else {
            /* get url */
            var url = window.location.href;
            var segments = url.split("/");
            var location = segments[3];
            var last_segment = segments[5];

            if (
                location.toLowerCase() == "grantee" &&
                last_segment.toLowerCase() == "addcenter"
            ) {
                /* for add center */
                var type = $(".center-form").serialize();
                var page = "center";
            } else if (location.toLowerCase() == "creategrantee") {
                /* add grantee */
                var type = $(".grantee-form").serialize();
                var page = "grantee";
            } else if (location.toLowerCase() == "grantee") {
                /* edit grantee */
                var type = $(".granteeupdate").serialize();
                var page = "grantee";
            } else if (location.toLowerCase() == "center") {
                /*edit center */
                var type = $(".center-form").serialize();
                var page = "center";
            }

            if ($(".state-select").val() == 5) {
                // For BIE state
                $.ajax({
                    url: "/ajax/getStateByZip",
                    type: "POST",
                    data: type,
                    success: function(StateRes) {
                        console.log(StateRes);
                        $(".bi-state").val(StateRes);
                    }
                });
                $.ajax({
                    url: "/ajax/getCityByZip",
                    type: "POST",
                    data: type,
                    success: function(res) {
                        if (page == "grantee") {
                            $(".subg_city").val(res);
                        } else {
                            $(".center-city").val(res);
                        }
                    }
                });
            } else {
                /* for center page */
                $.ajax({
                    url: "/ajax/getStateByZip",
                    type: "POST",
                    data: type,
                    success: function(res) {
                        console.log(res);

                        if (res == -1) {
                            console.log("No address return from USPS");
                            //whoops error
                            $(".notification p").html(
                                "Your Address and/or Zip Code supplied failed validation. Please re-enter these fields."
                            );
                            $(".notification")
                                .addClass("active")
                                .addClass("warning");
                            $('.notification span').html('Alert!');
                        } else if (res == $(".state-select").val()) {
                            //console.log(res);
                            $.ajax({
                                url: "/ajax/getCityByZip",
                                type: "POST",
                                data: type,
                                success: function(res) {
                                    if (page == "grantee") {
                                        $(".subg_city").val(res);
                                        var csrf = $(".token").val();
                                        /* save subg_city data */
                                        $.ajax({
                                            url: $('.granteeupdate').attr('action'),
                                            csrf_token_name: csrf,
                                            type: 'POST',
                                            data: $('.granteeupdate').serialize(),
                                            success: function(res) {
                                                if (res.success) {}
                                            }
                                        });
                                    }
                                }
                            });
                        } else {
                            console.log("adress and zip is not for your state");
                            //whoops error
                            $(".notification p").html(
                                "Your Address and/or Zip Code supplied is not for your state. Please re-enter these fields."
                            );
                            $(".notification")
                                .addClass("active")
                                .addClass("warning");
                            $('.notification span').html('Alert!');
                        }
                    }
                });
            }
        } // end on else for zip code get city
    });

    $(".state-select").focusout(function() {
        finalsubmit();
    });

    $(".notification").click(function(e) {
        e.preventDefault();
        $(".notification")
            .removeClass("active")
            .removeClass("warning");
    });

    //validate center data
    $(".center-name").focusout(function(e) {
        e.preventDefault();
        if ($(".center-name").val().length > 100) {
            $(".notification p").html(
                "The center name length is more than 100 characters. Please make sure center name length is less than 100 characters"
            );
            $(".notification")
                .addClass("active")
                .addClass("warning");
            $('.notification span').html('Alert!');

            return false;
        }
        if ($(".center-name").val().length == 0) {
            $(".notification p").html(
                "The Center name is mandatory field. Please input center name."
            );
            $('.notification span').html('Warning!');
            $(".notification")
                .addClass("active")
                .addClass("warning");
            $('.notification span').html('Alert!');

            return false;
        }
    });

    //validate custom center id, if exists(true), disable continue to review button
    $(".center-id").focusout(function(e) {
        e.preventDefault();

        if((e.target).hasAttribute("data-original")) {
            let original_value = String($(e.target).data("original"));
            let updated_value  = String($(e.target).val());
            if(original_value === updated_value) {
                $(".center-section .button").prop("disabled", false);
                // if there are no changes to the center id, re-enable submit button, exit early
                return;
            }
        }
        $.ajax({
            url: "/ajax/search_centerid",
            type: "POST",
            data: $(".center-form, .add-center-form").serialize(),

            success: function(res) {
                console.log(res);

                if (res === "id_exist") {
                    $(".notification p").html(
                        "Center ID already exists, please re-enter it"
                    );
                    $(".notification")
                        .addClass("active")
                        .addClass("warning");
                    $('.notification span').html('Alert!');

                    $(".center-section .button").prop("disabled", true);
                    $(".review-grantee-btn").prop("disabled", true);
                    $(".review-edit-center-btn").prop("disabled", true);
                    $(".loading-overlay").fadeOut();
                } else if (res === "id_locked") {
                    $(".loading-overlay").fadeOut();
                } else if (res === "id_not_exist") {
                    $(".loading-overlay").fadeOut();
                    $(".center-section .button").prop("disabled", false);
                } else if (res === "id_not_format") {
                    $(".notification p").html(
                        "Center ID requires a 4 to 10 digits (numeric or alphabetic) string, please re-enter it"
                    );
                    $(".notification")
                        .addClass("active")
                        .addClass("warning");
                    $('.notification span').html('Alert!');
                    $(".center-section .button").prop("disabled", true);
                    $(".loading-overlay").fadeOut();
                }
            }
        });
    });

    $(".center-city").focusout(function(e) {
        e.preventDefault();
        //centerfinalsubmit();
        if ($(".center-city").val().length > 100) {
            $(".notification p").html(
                "The center city length is more than 100 characters. Please make sure center city length is less than 100 characters"
            );
            $(".notification")
                .addClass("active")
                .addClass("warning");
            $('.notification span').html('Alert!');

            return false;
        }
        if ($(".center-city").val().length == 0) {
            $(".notification p").html(
                "The center city is mandatory field. Please input center city."
            );
            $(".notification")
                .addClass("active")
                .addClass("warning");
            $('.notification span').html('Alert!');

            return false;
        }
    });
    $(".center-contact").focusout(function(e) {
        e.preventDefault();
        if ($(".center-contact").val().length > 100) {
            $(".notification p").html(
                "The center contact length is more than 100 characters. Please make sure center contact length is less than 100 characters"
            );
            $(".notification")
                .addClass("active")
                .addClass("warning");
            $('.notification span').html('Alert!');

            return false;
        }

        if ($(".center-contact").val().length == 0) {
            $(".notification p").html(
                "The center contact is mandatory field. Please input center contact."
            );
            $('.notification span').html('Alert!');
            $(".notification")
                .addClass("active")
                .addClass("warning");
            $('.notification span').html('Alert!');

            return false;
        }
    });

    $(".center-address").focusout(function(e) {
        e.preventDefault();
        //centerfinalsubmit();
        if ($(".center-address").val().length > 255) {
            $(".notification p").html(
                "The center address length is more than 255 characters. Please make sure center address length is less than 255 characters"
            );
            $(".notification")
                .addClass("active")
                .addClass("warning");
            $('.notification span').html('Alert!');

            return false;
        }
        if ($(".center-address").val().length == 0) {
            $(".notification p").html(
                "The center address is mandatory field. Please input center address."
            );
            $(".notification")
                .addClass("active")
                .addClass("warning");
            $('.notification span').html('Alert!');

            return false;
        }
    });

    $(".subg_name").focusout(function(e) {
        e.preventDefault();
        finalsubmit();
        if ($(".subg_name").val().length > 100) {
            $(".notification p").html(
                "The grantee name length is more than 100 characters. Please make sure grantee name length is less than 100 characters"
            );
            $(".notification")
                .addClass("active")
                .addClass("warning");
            $('.notification span').html('Alert!');

            return false;
        }
        if ($(".subg_name").val().length == 0) {
            $(".notification p").html(
                "The grantee name is mandatory field. Please input grantee name."
            );
            $(".notification")
                .addClass("active")
                .addClass("warning");
            $('.notification span').html('Alert!');

            return false;
        }
    });
    $(".subg_contact").focusout(function(e) {
        finalsubmit();
        if ($(".subg_contact").val().length > 100) {
            $(".notification p").html(
                "The grantee contact length is more than 100 characters. Please make sure grantee contact length is less than 100 characters"
            );
            $(".notification")
                .addClass("active")
                .addClass("warning");
            $('.notification span').html('Alert!');

            return false;
        }
        if ($(".subg_contact").val().length == 0) {
            $(".notification p").html(
                "The grantee contact is mandatory field. Please input grantee contact."
            );
            $(".notification")
                .addClass("active")
                .addClass("warning");
            $('.notification span').html('Alert!');

            return false;
        }
    });


    $(".subg_city").focusout(function(e) {
        finalsubmit();
        if ($(".subg_city").val().length > 100) {
            $(".notification p").html(
                "The grantee city length is more than 100 characters. Please make sure grantee city length is less than 100 characters"
            );
            $(".notification")
                .addClass("active")
                .addClass("warning");
            $('.notification span').html('Alert!');

            return false;
        }
        if ($(".subg_city").val().length == 0) {
            $(".notification p").html(
                "The grantee city is mandatory field. Please input grantee city."
            );
            $(".notification")
                .addClass("active")
                .addClass("warning");
            $('.notification span').html('Alert!');

            return false;
        }
    });

    $(".subg_address").focusout(function(e) {
        finalsubmit();
        e.preventDefault();
        if ($(".subg_address").val().length > 255) {
            $(".notification p").html(
                "The grantee address length is more than 255 characters. Please make sure center address length is less than 255 characters"
            );
            $(".notification")
                .addClass("active")
                .addClass("warning");
            $('.notification span').html('Alert!');

            return false;
        } else if ($(".subg_address").val().length == 0) {
            $(".notification p").html(
                "The grantee address is mandatory field. Please input grantee address."
            );
            $(".notification")
                .addClass("active")
                .addClass("warning");
            $('.notification span').html('Alert!');

            return false;
        }
    });

    $("#gotocenter").click(function() {
        var aTag = $("#center");
        $("html,body").animate({
                scrollTop: aTag.offset().top - 100
            },
            "slow"
        );
    });

    $("#gotoGPRAExplan").click(function() {
        var aTag = $("#GPRAExplanation");
        $("html,body").animate({
                scrollTop: aTag.offset().top - 100
            },
            "slow"
        );
    });

    $("#go_to_rob").click(function() {
        var aTag = $("#rules_of_behavior");
        $("html,body").animate({
                scrollTop: aTag.offset().top - 100
            },
            "slow"
        );
    });
    /* user edit */
    $(".remove-edit-user").click(function(e) {
        e.preventDefault();

        var btn = $(this);

        var url = $(location).attr("href");
        var segments = url.split("/");
        var username = $(this).data('username');
        var center = $(this).data('center');

        // get the screen height and width
        var maskHeight = $(document).height();
        var maskWidth = $(window).width();

        // calculate the values for center alignment
        var dialogTop = (maskHeight / 3) - ($('#dialog-box').height());
        var dialogLeft = (maskWidth / 2) - ($('#dialog-box').width() / 2);

        if (dialogTop > 100) {
            dialogTop = 100;
        }
        if (dialogLeft > 450) {
            dialogLeft = 450;
        }

        // assign values to the overlay and dialog box
        $('#dialog-overlay').css({ height: maskHeight, width: maskWidth }).show();
        $('#dialog-box').css({ top: dialogTop, left: dialogLeft }).show();
        $('.dialog.button.left').attr('data-userid', btn.data("userid"));
        $('.dialog.button.right').attr('data-uid', btn.data("userid"));
        $('.dialog.button.right').attr('id', 'okay');
        $('.dialog.button.right').attr('data-seg3', segments[3]);
        $('.dialog.button.right').attr('data-seg5', segments[5]);

        $('.dialog.button.right').attr('data-id', 'user');
        $('.dialog.button.left').attr('data-id', 'user');
        $('.dialog.button.right').attr('data-userid', btn.data("userid"));
        $('.dialog.button.right').attr('data-user', username);

        // display the message
        $('#dialog-message').html('<h3>Delete User?</h3> <p>Are you sure you want to delete ' + username + '?</p>');
    });

    $(".unlock-user").click(function(e) {
        e.preventDefault();
        var $url = $(this).attr("href");
        var csrf = $('input[name="csrf_token_name"]').val();
        
        $.ajax({
                url: $url,
                type: "POST",
                data: {
                    csrf_token_name: csrf
                },
                success: function(res) {
                    if (res.ok) {
                        window.location.reload()
                    }
                }
        });
    });

    $("#state_dashboard").click(function(e) {
        e.preventDefault();
        location.replace("reports/statedashboard");
    });

    $("#improve_dashboard").click(function(e) {
        e.preventDefault();
        location.replace("reports/improvedashboard");
    });

    function allGpraExplanationsAreChecked()
    {
        return $("#gpraTexttop").val().length > 20
            && $("#gpraTextother").val().length > 20
            && $("#gpraText1").val().length > 20
            && $("#gpraText2").val().length > 20
            && $("#gpraText3").val().length > 20
            && $("#gpraText4").val().length > 20
            && $("#gpraText5").val().length > 20;
    }
    function shouldIEnableTheSubmitButton()
    {
        if (
            allGpraExplanationsAreChecked()
            && $("#certify_check1").prop("checked")
            && $("#certify_check2").prop("checked")
        ) {
            $("#certify_submit").prop("disabled", false);
            $('#certify_final').val(1);
        } else {
            $("#certify_submit").prop("disabled", true);
            $('#certify_final').val(0);
        }
    }

    $("#certify_check1, #certify_check2").click(
        function() {
            shouldIEnableTheSubmitButton();
        }
    );
    $("#gpraTexttop, #gpraTextother, #gpraText1, #gpraText2, #gpraText3, #gpraText4, #gpraText5").keyup(
        function() {
            shouldIEnableTheSubmitButton();
        }
    );
    $('.container-unavailable-student-data .explanation-data-gap').keyup(function() {
        let that = $(this)
        if (that.val().trim().length < 20) {
            that.parent().children('.validation').show();
        } else {
            that.parent().children('.validation').hide();
        }
    });

    $("#gotoCertCheckbox").click(function() {
        var aTag = $("#certifyform");
        $("html,body").animate({
                scrollTop: aTag.offset().top - 100
            },
            "slow"
        );
    });

    function finalsubmit() {
        var re = /[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,4}/gim;
        if (!re.test($(".email-input").val()) ||
            $(".subg_name").val().length == 0 ||
            $(".subg_contact").val().length == 0 ||
            $(".phone-input").val().length < 12 ||
            $(".subg_address").val().length == 0 ||
            $(".subg_city").val().length == 0 ||
            $(".zipcode-input").val().length < 5
        ) {
            $(".zipcode-input")
                .parents(".bravoo ")
                .find(".button")
                .prop("disabled", true);
            $(".review-grantee-btn").prop("disabled", true);
        } else {
            $(".zipcode-input")
                .parents(".bravoo ")
                .find(".button")
                .prop("disabled", false);
            $(".review-grantee-btn").prop("disabled", false);
        }
    }

    function centerfinalsubmit() {
        showlog("centerfinalsubmit function");
        var re = /[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,4}/gim;
        if (!re.test($(".center-email-input").val()) ||
            $(".center-name").val().length == 0 ||
            $(".center-contact").val().length == 0 ||
            $(".center-phone").val().length < 12 ||
            $(".center-address").val().length == 0 ||
            $(".center-city").val().length == 0 ||
            $(".center-zip").val().length < 5
        ) {
            $(".module")
                .find(".button")
                .prop("disabled", true);
            $(".review-center-btn").prop("disabled", true);
        } else {
            $(".module")
                .find(".button")
                .prop("disabled", false);

            $(".review-center-btn").prop("disabled", false);
        }
    }

    /* disable update setting button until password get enter */
    $("#oldpassword").keyup(function(e) {
        e.preventDefault();
        if ($("#oldpassword").val().length > 0) {
            $(".updatesetting").prop("disabled", false);
        } else {
            $(".updatesetting").prop("disabled", true);
        }
    });

    //fixme
    /* disable send a new api key button until password get enter */
    $("#old_password_api").keyup(function(e) {
        e.preventDefault();
        if ($("#old_password_api").val().length > 0) {
            $(".send_new_key").prop("disabled", false);
        } else {
            $(".send_new_key").prop("disabled", true);
        }
    });

    $("#helpdesk-type, #helpdesk-functional").change(function(e) {
        if (
            $("#helpdesk-type").val() > 0 &&
            $("#helpdesk-functional").val() > 0 &&
            $(".issuetext").val().length > 0
        ) {
            $(".helpdesk-submit").prop("disabled", false);
        } else {
            $(".helpdesk-submit").prop("disabled", true);
        }
    });

    $(".issuetext").keyup(function(e) {
        if (
            $("#helpdesk-type").val() > 0 &&
            $("#helpdesk-functional").val() > 0 &&
            $(".issuetext").val().length > 0
        ) {
            $(".helpdesk-submit").prop("disabled", false);
        } else {
            $(".helpdesk-submit").prop("disabled", true);
        }
    });

    function hideWarningModal() {
        $(".test-banner").slideUp("slow");
        $(".main-content").animate({
                "padding-top": 0,
                "padding-right": 0,
                "padding-bottom": 0,
                "padding-left": 0
            },
            "slow"
        );
    }

    function acceptWarning() {
        $(".accept_flag").val(1);
    }

    $(".close-banner-button").click(function(e) {
        e.preventDefault();
        hideWarningModal();
        acceptWarning();
        var date = new Date();
        date.setTime(date.getTime() + (10 * 60 * 1000));
        document.cookie = "warning_accepted=true; expires=" + date.toGMTString() + "; path=/";
    });


    //  grantee updates
    $(".regular-text").keyup(function(e) {
        console.log("regular-text keyup");
        var url = $(location).attr('href');
        var segments = url.split('/');


        console.log(segments[3]);
        if (!$(this).is('[readonly]')) {
            if (segments[5] == 'addcenter' || segments[5] == 'addcenter#' || segments[3] == 'createGrantee') {
                // Do nothing
            } else if (segments[3] == 'center') {
                console.log("in save center");
            } else if (segments[3] == 'grantee') { // Not run when it is addcenter to avoid error
                // call ajax to update
                if ($(this).hasClass('partner-name')) {
                    // not save
                } else {
                    // call ajax function to save
                    $.ajax({
                        url: $('.center-form').attr('action'),
                        type: 'POST',
                        data: $('.center-form').serialize(),
                        success: function(res) {
                            if (res.success) {
                                // console.log("save update center");
                            }
                        }
                    });
                }
            }
        }
    });

    //for on-time certification
    $("#on_time_button").click(function(event) {
        event.preventDefault();
        if( !$('#states_multi_input').val() ) {
            return false;
        }

        var csrf = $(".token").val();

        var serialized = $("#report-filter-ontime").serialize();

        var url = "/reports/refresh_ontimecertification";

        $.ajax({
            url: url,
            type: "POST",
            data: serialized,

            success: function(res) {
                $(".holder").html(res);

                if (res.success == true) {

                }
                if (res.error) {

                }
            },
            error: function(status) {}
        })

    });


    $("body").on("click", ".revoke-btn", function(e) {
        var btn = $(this);
        e.preventDefault();

        var url = $(location).attr("href");
        var segments = url.split("/");
        var username = $(this).data('username');
        var center = $(this).data('center');
        var grantee = $(this).data('grantee');

        // get the screen height and width
        var maskHeight = $(document).height();
        var maskWidth = $(window).width();

        // calculate the values for center alignment
        var dialogTop = (maskHeight / 3) - ($('#dialog-box').height());
        var dialogLeft = (maskWidth / 2) - ($('#dialog-box').width() / 2);

        if (dialogTop > 100) {
            dialogTop = 100;
        }
        if (dialogLeft > 450) {
            dialogLeft = 450;
        }

        // assign values to the overlay and dialog box
        $('#dialog-overlay').css({ height: maskHeight, width: maskWidth }).show();
        $('#dialog-box').css({ top: dialogTop, left: dialogLeft }).show();
        $('.dialog.button.left').attr('data-userid', btn.data("uid"));
        $('.dialog.button.right').attr('id', 'okay');

        if ((segments[3] == "user") || (segments[3] == "users")) {
            $('.dialog.button.right').attr('data-id', 'user');
            $('.dialog.button.left').attr('data-id', 'user');
            $('.dialog.button.right').attr('data-userid', btn.data("uid"));
            $('.dialog.button.right').attr('data-user', username);


            // display the message
            $('#dialog-message').html('<h3>Delete User?</h3> <p>Are you sure you want to delete ' + username + '?</p>');
        } else if (segments[3] == "center") {
            $('.dialog.button.right').attr('data-id', 'center');
            $('.dialog.button.left').attr('data-id', 'center');
            $('.dialog.button.right').attr('data-deleteReason', '0');
            $('.dialog.button.right').attr('data-centerid', btn.data("uid"));
            $('.dialog.button.right').attr('data-gid', btn.data("gid"));
            $('.dialog.button.right').attr('data-center', btn.data("center"));

            // display the message
            $('.popCentername').html(center);
            return false;
        } else if ((segments[3] == "grantee") && (segments[5] == "overview")) {
            $('.dialog.button.right').attr('data-id', 'center');
            $('.dialog.button.left').attr('data-id', 'center');
            $('.dialog.button.right').attr('data-deleteReason', '0');
            $('.dialog.button.right').attr('data-centerid', btn.data("uid"));
            $('.dialog.button.right').attr('data-gid', btn.data("gid"));
            $('.dialog.button.right').attr('data-center', btn.data("center"));
            $('.popCentername').html(center);
        }

        if (btn.hasClass("doDelete")) {
            var id = btn.data("uid");
            var csrf = $(".token").val();
            console.log('in doDelete');

            if (segments[3] == "user") {
                $.post(
                    url, {
                        csrf_token_name: csrf
                    },
                    function(res) {
                        if (res.ok) {
                            $(".record_" + id)
                                .fadeOut()
                                .remove();
                            $(".notification p").html("User has been deleted!");
                            $(".notification")
                                .addClass("active")
                                .addClass("success")
                                .delay(5000)
                                .queue(function(next) {
                                    $(this)
                                        .removeClass("active")
                                        .removeClass("success");
                                    next();
                                });
                        }
                    }
                );
                $("#add-user").removeAttr("disabled");
            } else {
                /* achan add re-re-confirm message to warn the delete */
                if (segments[3] == "grantee") {
                    if (btn.hasClass("old")) {
                        var con_message = confirm(
                            "You are about to delete this Grantee from the current reporting year. (This action will not delete performance data from previous years.) If this Grantee has an existing award, do not delete it. If this Grantee has a new award, proceed with deleting this profile and create a new Grantee profile for the new award."
                        );
                    } else {
                        var con_message = confirm(
                            "You are about to delete this Grantee, all of its Centers, and all corresponding APR data entered for the current reporting year. Are you really sure you want to delete this Grantee?"
                        );
                    }
                } else {
                    var con_message = confirm(
                        "You are about to delete this Center and all corresponding APR data entered for the current reporting year. Are you really sure you want to delete this Center?"
                    );
                }

                // achan 08-06-2020 not sure we still use this after confirmation , we use main.js line 1543 on click #okay
                if (con_message == true) {
                    $.post(
                        url, {
                            csrf_token_name: csrf
                        },
                        function(res) {
                            if (res.ok) {
                                $(".record_" + id)
                                    .fadeOut()
                                    .remove();
                                if (segments[3] == "grantee") {
                                    $(".notification p").html("The grantee has been deleted!");
                                } else if (segments[1] == "center") {
                                    $(".notification p").html("The center has been deleted!");
                                }
                                $(".notification")
                                    .addClass("active")
                                    .addClass("success")
                                    .delay(5000)
                                    .queue(function(next) {
                                        $(this)
                                            .removeClass("active")
                                            .removeClass("success");
                                        next();
                                    });
                            } else {
                                console.log('nope');
                            }
                        }
                    );
                    $("#add-user").removeAttr("disabled");
                } else {
                    btn.removeClass("doDelete");
                }
            } //end
        } else {
            if (segments[3] == "center") {
                btn.closest(".col-4-dropdown").addClass("delete");
            } else {
                btn.closest(".col-3-dropdown").addClass("delete");
            }
            if (segments[3] == "user") {
                btn.closest(".col-5-dropdown").addClass("delete");
            }
            console.log('Add doDelete');
            btn.addClass("doDelete");
        }
    });

    $("#login-gov-alert").off();
    $("#login-gov-alert .close-icon").on("click", function() {
        $("#login-gov-alert").removeClass("warning, active");
    });

    $('.center-list-item').each(function(item) {
        checkAPRDataButtonToggles($(this), 'schoolyear');
        checkAPRDataButtonToggles($(this), 'summer');
    });

    // Temp script for check status
    $("a[title='logout']").on("click", function(e) {
        sessionStorage.removeItem("statusAlert");
    })

}); // end of js