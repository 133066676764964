function makeCharts() {
    $('.activities').circleProgress({
        size: 90,
        thickness: 12,
        emptyFill: "rgba(0, 0, 0, 0)",
        reverse: true,
        startAngle: -Math.PI / 2,
        fill: {
            gradient: ["#370A20", "#A74876"]
        }
    }).on('circle-animation-progress', function (event, progress, stepValue) {
        $(this).find('strong').html(Math.floor(100 * stepValue) + '%');
    });

    $('.participation').circleProgress({
        size: 90,
        thickness: 12,
        emptyFill: "rgba(0, 0, 0, 0)",
        reverse: true,
        startAngle: -Math.PI / 2,
        fill: {
            gradient: ["#2A3A5E", "#44619B"]
        }
    }).on('circle-animation-progress', function (event, progress, stepValue) {
        $(this).find('strong').html(Math.floor(100 * stepValue) + '%');
    });

    $('.staffing').circleProgress({
        size: 90,
        thickness: 12,
        emptyFill: "rgba(0, 0, 0, 0)",
        reverse: true,
        startAngle: -Math.PI / 2,
        fill: {
            gradient: ["#B06518", "#F4BB6B"]
        }
    }).on('circle-animation-progress', function (event, progress, stepValue) {
        $(this).find('strong').html(Math.floor(100 * stepValue) + '%');
    });

    $('.outcomes').circleProgress({
        size: 90,
        thickness: 12,
        emptyFill: "rgba(0, 0, 0, 0)",
        reverse: true,
        startAngle: -Math.PI / 2,
        fill: {
            gradient: ["#858BC7", "#C2C5E2"]
        }
    }).on('circle-animation-progress', function (event, progress, stepValue) {
        $(this).find('strong').html(Math.floor(100 * stepValue) + '%');
    });

}

(function ($) {
    var stateReport = $(".state-table-wrapper").length;
    if (stateReport >= 1) {
        makeCharts();
    }
})(jQuery);
