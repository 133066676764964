class PoDataContainer{
    
    startup()
    {

    }
    constructor(data_container) {
        //display data onto the target data_container
        if (!(data_container instanceof jQuery) || data_container.length == 0){
            throw new Error("The data_container passed is not an instance of a Jquery object Or is not selecting anything"); // Throws a new Error object
        }
        this.data_container = data_container;
        this.startup();
    }
    get stateId(){
        let state_id = parseInt($('#report_state').find(":selected").val());
        if(state_id <= 0){
            return null;
        }
        return state_id;
    }
    get stateName(){
        let state_name = ($('#report_state').find(":selected")).text();
        if(state_name == ""){
            return null;
        }
        return state_name;
    }
    
    get activeYearRange(){
       let active_year = parseInt($('#active_year_helper').val());
       return active_year + '-' + (active_year + 1);
    }

    get getActiveYear(){
        let active_year = parseInt($('#active_year_helper').val());
        return active_year;
    }

    updateHeader(title){
        let object_instance = this;
        object_instance.data_container.find('.po-section-header').text(title);
    }
}
class PoTable{
    table_id = null;
    headers = [];
    rows = [];
    
    constructor(table_container) {
        //display data onto the target data_container
        if (!(table_container instanceof jQuery) || table_container.length == 0){
            throw new Error("The data_container passed is not an instance of a Jquery object Or is not selecting anything"); // Throws a new Error object
        }
        this.table_container = table_container;
        this.table_id = table_container.attr("id");
    }
}
class SeaSuInfo extends PoDataContainer {
    email = "N/A";
    first_name = "N/A";
    last_name = "N/A";
    designation_text = "N/A";
    url = "/reports/refresh_sea_su_info";

    getSeaSuInfo(){
        let object_instance = this;
        $.ajax({
            url: object_instance.url,
            type: "POST",
            data: { state_id : object_instance.stateId },
            success: function (res) {
                if (res.success == true) {
                    let sea_details_title = object_instance.activeYearRange + ' State Details';
                    object_instance.updateHeader(sea_details_title);
                    object_instance.email = res.data.email;
                    object_instance.first_name =res.data.first_name;
                    object_instance.last_name = res.data.last_name;
                    object_instance.designation_text = res.data.designation_text;
                    object_instance.updateWidget();
                }
            },
            error: function (status) {

            }
        });
    }
    get full_name(){
        if(this.first_name == "N/A"){
            return "N/A";
        }
        return this.first_name + " " + this.last_name; 
    }
    updateWidget() {
        this.data_container.find("#email").text(this.email);
        this.data_container.find("#full_name").text(this.full_name);
        this.data_container.find("#designation_text").text(this.designation_text);
    };
}
class CertificationInfo extends PoDataContainer {
   
    url = "/reports/refresh_ontimecertification";
    getCertifcationInfo(){
        let object_instance = this;
        let data = {
            // ensures all years all selected
            "report_year" : 0,
            // PO dashboard only selects a single year
            "report_states" : [object_instance.stateId],
            // PO dashboard shows all report statuses
            "report_status" : "allstatus"
        } ;
        
        $.ajax({
            url: object_instance.url,
            type: "POST",
            data: data,

            success: function(res) {
                object_instance.updateHeader((object_instance.stateName + ' - On-Time Certification'));
                object_instance.data_container.find('.po-data-container').html(res);
                if (res.success == true) {

                }
                if (res.error) {

                }
            },
            error: function(status) {}
        });
    }
}
class StateSnapShotInfo extends PoDataContainer{
    url = "/records/get_year_snapshot";
    getSnapshotData(){
        let object_instance = this;
        let data = {
            // PO dashboard only selects a single year
            state_id : object_instance.stateId,
        } ;

        $.ajax({
            url: object_instance.url,
            type: "POST",
            data: data,
            success: function(res) {
                let section_title = object_instance.stateName + ' ' +object_instance.activeYearRange + ' State Snapshot';
                object_instance.updateHeader(section_title);
                object_instance.data_container.find('.snapshot-section-content').html(res);
            },
            error: function(status) {}
        })
    }
}
class DemographicInfo extends PoDataContainer{
    getDemographicsLink(){
        let object_instance = this;
        let title = object_instance.stateName+ ' ' + object_instance.activeYearRange +' Demographics';
        let demographics_url = '/reports/demographics?referral_state='+ object_instance.stateId;
        object_instance.updateHeader(title);
        object_instance.data_container.find('.authoritative-link-btn-container a').attr("href", demographics_url);
    }
}
class CertificationNarratives extends PoDataContainer{
    url = "/reports/refresh_certification_narratives";
    getNarratives(){
        let object_instance = this;
        object_instance.updateHeader();
        let data = {
            // PO dashboard only selects a single year
            "state_id" : object_instance.stateId,
        } ;
        $.ajax({
            url: object_instance.url,
            type: "POST",
            data: data,

            success: function(res) {
                if (res.success == true) {
                    object_instance.populateNarratives(res.data);
                }
                if (res.error) {

                }
            },
            error: function(status) {}
        });
    }
    populateNarratives(data){
        let object_instance = this;
        if(data.length == 0 || data == null){
            return;
        }
        for (const property in data) {
            object_instance.data_container.find('#' + property).find('textarea').val(data[property]);
        }
        return;
    }
    updateHeader(){
        let object_instance = this;
        let section_header = object_instance.stateName+ ' ' + object_instance.activeYearRange +' Certification Narratives';
        let gpra_header = object_instance.stateName +' Explanation for GPRA Outcomes';
        let gaps_title = object_instance.stateName +' Explanation for Gaps In Data';
        let other_explanation_header = object_instance.stateName +'\'s Explanation for Other Data Elements';

        super.updateHeader(section_header);
        object_instance.data_container.find('#gpra_explanation_header').text(gpra_header);
        object_instance.data_container.find('#gaps_explanation_header').text(gaps_title);
        object_instance.data_container.find('#explanation_other_data_header').text(other_explanation_header);
    }
}
class OutcomeTrends extends PoDataContainer{
    getOutcomeTrendLink(){
        let object_instance = this;
        let title = object_instance.stateName + ' ' + object_instance.activeYearRange +' Outcome Trends';
        let outcome_url = '/reports/outcometrends/chart?report_state=' + object_instance.stateId;
        object_instance.updateHeader(title);
        object_instance.data_container.find('.authoritative-link-btn-container a').attr("href", outcome_url);
    }
}


if($("#po-dashboard-controller").length == 1){
        try {
            var SeaSuController = new SeaSuInfo($("#sea-su-info"));
            var CertificationStatusController = new CertificationInfo($("#certification-status"));
            var StateSnapshotController = new StateSnapShotInfo(
                $('#state-year-snapshot')
            );
            var DemographicsController = new DemographicInfo($('#state-year-demographics-helper'));
            var CertificationNarrativesController = new CertificationNarratives($('#state-year-certification-narratives'));
            var OutcomeTrendsController = new OutcomeTrends($('#state-year-outcomes-trend-helper'));
        } catch ({ name, message }){
            console.log(message);
        }
        $('#report_state').change(function() {
            let value = $(this).children(":selected").val();
            $('.controller-container').hide();
            if(value > 0){
                SeaSuController.getSeaSuInfo();
                CertificationStatusController.getCertifcationInfo();
                StateSnapshotController.getSnapshotData();
                DemographicsController.getDemographicsLink();
                CertificationNarrativesController.getNarratives();
                OutcomeTrendsController.getOutcomeTrendLink();
                $('.controller-container').slideDown(1000);
            }
        });
}