(function ($) {

    $('textarea.explanation-data-gap').keyup(function() {
        const LIMIT = 10000;
        const LEFT = (LIMIT - $(this).val().length).toString();

        // find siblings
        let amount = $(this).siblings('.unavailable-student-data-chars-left').children().html(LEFT);

        submitCertificationForm(false);
    });


    let rows =  $('.certification-container table.inactive_centers tr.center_names');
    $('.certification-container table.inactive_centers input').keyup(function(){
        var val = $.trim($(this).val()).replace(/ +/g, ' ').toLowerCase();

        rows.show().filter(function() {
            var text = $(this).text().replace(/\s+/g, ' ').toLowerCase();
            return !~text.indexOf(val);
        }).hide();
    });


    $('.certification-container button[name=certify_submit]').click((e) => {
        e.preventDefault();
        submitCertificationForm(true);
    });


    function submitCertificationForm(final = false) {

        let data = $('#certification');

        console.log(data);

        $.ajax({
            url: '/ajax/v4/certifications',
            type: 'POST',
            data: data.serialize(),
            success: function (res) {
                console.log(res);
                if (res.status == 'success') {
                    if (final === true) {
                        $('textarea').attr('disabled', 'disabled');
                        $('input[type=checkbox]').attr('disabled', 'disabled');
                        location.reload();
                    }
                } else {
                    console.log('error save certifications: ' + res.message );
                    if (final === true) {
                        showNotification('error', 'There are errors in your submission. Please be sure all fields are filled out.', true);
                    }
                }
            },
            error: (err) => {
                console.log('Error', err);
                showNotification('error', 'There was a system error. Your data may not have been saved.', true);
            }
        });
    }

    $(window).on("load",function(e) {
        let certified_status = $('#certified_status').val();
        if (certified_status == 1) {
            $('#certify_check1').prop('checked').prop('disabled', true);
            $('#certify_check2').prop('checked').prop('disabled', true);
            $('textarea').attr('disabled', 'disabled');
            $('input[type=checkbox]').attr('disabled', 'disabled');
            $("#certify_submit").prop("disabled", true);
        }
    });


})(jQuery);