function sysadminConfig() {

    var selection = ''
    var speed = 200

    $('select[name=state_selected]').on('change', function() {

        $('.section-reporting-designation').addClass('hidden');
        $.ajax({
            url: "/ajax/get_reporting_designation_for_sys_admin",
            type: "GET",
            data: {"state_selected": $('select[name=state_selected] option:selected').val()},
            success: function (d) {
                state_selected =  $('select[name=state_selected] option:selected').val();
                $('.not-reported').addClass('hidden');

                d = JSON.parse(d);

                if (d.state_no_elt) {
                    $('input[name=state_no_elt][value=0]').prop('checked', true);
                } else {
                    $('input[name=state_no_elt][value=1]').prop('checked', true);
                }

                if (d.open_close_dates && Object.keys(d.open_close_dates).length > 0) {
                    $.each(d.open_close_dates, function(i, date) {

                        let federal_open_date = d.federal_open_close_dates[i].open_date.split(" ");
                        let federal_close_date = d.federal_open_close_dates[i].close_date.split(" ");
                        let text = moment(federal_open_date[0]).format('MMMM Do, YYYY') + ' to ' + moment(federal_close_date[0]).format('MMMM Do, YYYY');
                        $('#window' + date.apr_type_fkid).text(text);

                        $('.apr_type_open_date_' + date.apr_type_fkid).val(federal_open_date);
                        $('.apr_type_close_date_' + date.apr_type_fkid).val(federal_close_date);

                        $('.apr_type_open_date_' + date.apr_type_fkid + ', .apr_type_close_date_' + date.apr_type_fkid)
                          .datepicker({
                              dateFormat: "yy-mm-dd",
                              minDate: d.federal_open_close_dates[i].open_date,
                              maxDate: d.federal_open_close_dates[i].close_date
                          })
                          .removeAttr('disabled');

                    })
                }

                if (typeof d.open_close_dates !== 'undefined' && Object.keys(d.open_close_dates).length > 0) {
                    $.each(d.open_close_dates, function(index, item) {
                        $('.apr_type_open_date_' + item.apr_type_fkid).val(item.open_date.substring(0, 10));
                        $('.apr_type_close_date_' + item.apr_type_fkid).val(item.close_date.substring(0, 10));
                    });
                }

                if (d.designation_set === true) {
                    if(state_selected == 100){
                        $('.section-reporting-designation').children().hide();
                        $('.section-reporting-designation').hide();
                        $('.section-elp').children().hide();
                        $('.section-elp').hide();
                    } else {
                        $('.section-reporting-designation').children().show();
                        $('.section-reporting-designation').show();
                        $('.section-elp').children().hide();
                        $('.section-elp').hide();
                    }
                    var answer = d.state_config_answer;
                    $('.section-after-reporting').slideDown(speed)

                    console.log(answer.designation_text)
                    if (answer.designation_text == "") {
                        $('#designation-header').hide();
                    } else {
                        $('#designation-header').show();
                        $('#designation-text').text(answer.designation_text);
                    }

                    $('#designation-text').text(answer.designation_text);
                    
                    $('input[name=schoolyear_only][value=' + answer.schoolyear_only + ']').prop('checked', true)
                    $('input[name=schoolyear_summer][value=' + answer.schoolyear_summer + ']').prop('checked', true)
                    $('input[name=same_curriculum_methodology][value=' + answer.same_curriculum_methodology + ']').prop('checked', true)
                    $('input[name=summer_grant_competition][value=' + answer.summer_grant_competition + ']').prop('checked', true)
                    $('input[name=summer_have_more_distinctive_char][value=' + answer.summer_have_more_distinctive_char + ']').prop('checked', true)
                    $('input[name=understand_save_reporting_designation]').prop('checked', answer.understand_save_reporting_designation == 1)
                    $('textarea[name=reporting_explanation]').text(answer.reporting_explanation)

                    $('.school-year-opts').not('.opt-1').addClass('hidden')
                    if (answer.schoolyear_only == 1) {
                        $('.opt-1').removeClass('hidden')
                    }
                    if (answer.schoolyear_summer == 1) {
                        $('.opt-2').removeClass('hidden')

                        if (answer.same_curriculum_methodology == 1) {
                            $('.opt-3').removeClass('hidden')
                            $('.opt-4, .opt-5').addClass('hidden')
                        } else {
                            $('.opt-3').removeClass('hidden')
                            $('.opt-4').removeClass('hidden')
                            if (answer.summer_grant_competition === 0) {
                                $('.opt-5').removeClass('hidden')
                            }
                        }
                    }

                    if (answer.schoolyear_only == 1) {
                        updateSchoolYear(answer.designation_text)
                        $('.section-reporting-designation .school-year-help-desk').addClass('hidden')
                        $('.section-reporting-designation .school-year-attest').removeClass('hidden')
                    } else if(answer.schoolyear_summer == 1 && answer.same_curriculum_methodology == 1) {
                        updateSchoolYear(answer.designation_text)
                        $('.section-reporting-designation .school-year-help-desk').addClass('hidden')
                        $('.section-reporting-designation .school-year-attest').removeClass('hidden')
                    } else if (answer.schoolyear_only == 1 && answer.same_curriculum_methodology == 0 && answer.summer_have_more_distinctive_char == 1) {
                        updateSchoolYear(answer.designation_text)
                        $('.section-reporting-designation .school-year-help-desk').addClass('hidden')
                        $('.section-reporting-designation .school-year-attest').removeClass('hidden')
                    } else {
                        $('.section-reporting-designation .school-year-help-desk').removeClass('hidden');
                        $('.section-reporting-designation .school-year-attest').addClass('hidden');
                    }

                    $('.section-reporting-designation input, .section-reporting-designation textarea').prop('disabled', true)
                    $('.section-reporting-designation .save-and-attest').hide(speed)

                    $('.section-reporting-designation').slideDown(speed);
                    $('.school-year-opts, .school-year-attest').removeClass('hidden');
                } else {
                    $('.section-reporting-designation').addClass('hidden');
                    $('.not-reported').removeClass('hidden');
                }
            },
            error: function(xhr, textStatus, error){
                console.error(xhr.statusText);
                console.error(textStatus);
                console.error(error);
                alert("An error occurred")
            }
        })
    });

    function updateSchoolYear(opt) {
        selection = opt
        $('.school-year-selection').text(opt)
    }

    /* Disable hit enter to submit the form, only load for this page. */
    $(window).keydown(function(event){
        if(event.keyCode == 13) {
            event.preventDefault()
            return false
        }
    })

}
function seasuConfig(designation_text, disable_answering) {

    var selection = ''
    var speed = 200

    $(document).ready(function() {

        $('.school-year-selection').text(designation_text);

        getStateAprOpenCloseDates();

        function getStateAprOpenCloseDates(state_fkid) {
            let params = {
                url: "/sysusersetting/get-apr-date-by-state",
                type: "GET",
                success: function(d) {
                    if (JSON.stringify(d) !== '{}') {
                        d = JSON.parse(d);

                        if (d.designation_set)
                            $('#designation-text').text(d.state_config_answer.designation_text);

                        if (d.open_close_dates) {
                            // Open close dates are 1 indexed in their respective arrays
                            for (let i = 1; i < 3; i++) {

                                let date = d.open_close_dates[i];
                                let federalDate = d.federal_open_close_dates[i];
                                
                                let federal_open_date = federalDate.open_date.split(" ");
                                let federal_close_date = federalDate.close_date.split(" ");

                                let text = moment(federal_open_date[0]).format('MMMM Do, YYYY') +
                                  ' to ' + moment(federal_close_date[0]).format('MMMM Do, YYYY');

                                $('#window' + date.apr_type_fkid).text(text);

                                if (Object.keys(d.open_close_dates).length == 0) {
                                    $('.apr_type_open_date_' + date.apr_type_fkid).val(federalDate.open_date.substring(0, 10));
                                    $('.apr_type_close_date_' + date.apr_type_fkid).val(federalDate.close_date.substring(0, 10));
                                }

                                let datepicker_opts = {
                                    dateFormat: "yy-mm-dd"
                                };

                                if (d.exception[date.apr_type_fkid].length == 0) {
                                    datepicker_opts = {
                                        ...datepicker_opts,
                                        minDate: federalDate.open_date,
                                        maxDate: federalDate.close_date
                                    }
                                }

                                $('.apr_type_open_date_' + date.apr_type_fkid + ', .apr_type_close_date_' + date.apr_type_fkid)
                                  .datepicker(datepicker_opts)
                                  .removeAttr('disabled');

                                $('.apr_type_open_date_' + date.apr_type_fkid).val(date.open_date.substring(0, 10));
                                $('.apr_type_close_date_' + date.apr_type_fkid).val(date.close_date.substring(0, 10));
                            }
                        }

                        if (typeof d.open_close_dates !== 'undefined' && Object.keys(d.open_close_dates).length > 0) {
                            $.each(d.open_close_dates, function (index, item) {
                                $('.apr_type_open_date_' + item.apr_type_fkid).val(item.open_date.substring(0, 10));
                                $('.apr_type_close_date_' + item.apr_type_fkid).val(item.close_date.substring(0, 10));
                            });
                        }

                    }
                }
            }
            if (state_fkid) {
                params = {
                    ...params,
                    data: { state: state_fkid}
                }
            }
            $.ajax(params);
        }


    if (!disable_answering) {

        $('input[name=schoolyear_only]').on('click', function () {
            if ($(this).val() == 1) {
                $('.school-year-opts')
                  .not('.school-year-opts.opt-1').slideUp(speed)
                $('.school-year-help-desk').slideUp(speed)
                updateSchoolYear('School Year Only')
                $('.school-year-attest').slideDown(speed).css('display', 'flex') // If yes, show the form

            } else if ($(this).val() == 0) {
                $('.school-year-attest').slideUp(speed)
                $('.school-year-help-desk').slideUp(speed)
                $('.school-year-opts.opt-2').slideDown(speed).css('display', 'flex') // If no, have to answer opt_2
                $('input[name=schoolyear_summer]').prop('checked', false)
            }
        })

        $('input[name=schoolyear_summer]').on('click', function () {
            if ($(this).val() == 1) {
                updateSchoolYear('?')
                $('.school-year-help-desk').slideUp(speed)
                $('.school-year-attest').slideUp(speed)
                $('.school-year-opts.opt-3').slideDown(speed).css('display', 'flex') // If yes, have to answer opt_3
                $('input[name=same_curriculum_methodology]').prop('checked', false)

            } else if ($(this).val() == 0) {
                $('.school-year-opts')
                  .not('.school-year-opts.opt-1, .school-year-opts.opt-2').slideUp(speed)
                $('.school-year-attest').slideUp(speed)
                $('.school-year-help-desk').slideDown(speed).css('display', 'flex') // If no, show the helpdesk
            }
        })

        $('input[name=same_curriculum_methodology]').on('click', function () {
            if ($(this).val() == 1) {
                $('.school-year-opts.opt-4, .school-year-opts.opt-5').slideUp(speed)
                $('.school-year-help-desk').slideUp(speed)
                updateSchoolYear('All Year')
                $('.school-year-attest').slideDown(speed).css('display', 'flex') // If yes, show the form

            } else if ($(this).val() == 0) {
                updateSchoolYear('?')
                $('.school-year-attest').slideUp(speed)
                $('.school-year-help-desk').slideUp(speed)
                $('.school-year-opts.opt-4').slideDown(speed).css('display', 'flex') // If no, have to answer opt_4
                $('input[name=summer_grant_competition').prop('checked', false)
            }
        })

        $('input[name=summer_grant_competition]').on('click', function () {
            if ($(this).val() == 1) {
                $('.school-year-opts.opt-5').slideUp(speed)
                $('.school-year-help-desk').slideUp(speed)
                updateSchoolYear('School Year and Summer')
                $('.school-year-attest').slideDown(speed).css('display', 'flex') // If yes, show the form

            } else if ($(this).val() == 0) {
                updateSchoolYear('?')
                $('.school-year-attest').slideUp(speed)
                $('.school-year-help-desk').slideUp(speed)
                $('.school-year-opts.opt-5').slideDown(speed).css('display', 'flex') // If no, have to answer opt_5
                $('input[name=summer_have_more_distinctive_char]').prop('checked', false)
            }
        })

        $('input[name=summer_have_more_distinctive_char]').on('click', function () {
            if ($(this).val() == 1) {
                $('.school-year-help-desk').slideUp(speed)
                updateSchoolYear('School Year and Summer')
                $('.school-year-attest').slideDown(speed).css('display', 'flex') // If yes, show the form
            } else if ($(this).val() == 0) {
                updateSchoolYear('?')
                $('.school-year-attest').slideUp(speed)
                $('.school-year-help-desk').slideDown(speed).css('display', 'flex') // If no, show help desk
            }
        })

        function updateSchoolYear (opt) {
            selection = opt
            $('.school-year-selection').text(opt)
        }

        $('.save-and-attest').on('click', function () {
            if ($('textarea[name=reporting_explanation]').val().length <= 20) {
                alert('Please type an explanation longer than 20 characters.');
            }
            if ($('input[name=understand_save_reporting_designation]').prop('checked') !== true
              && $('textarea[name=reporting_explanation]').val().length > 20
            ) {
                alert('Please agree before continuing.');
            }
            if ($('input[name=understand_save_reporting_designation]').prop('checked') === true
              && $('textarea[name=reporting_explanation]').val().length > 20
            ) {
                if (confirm('Are you sure? This cannot be changed.')) {
                    $.ajax({
                        url: "/ajax/save_reporting_designation_for_sea_su",
                        type: "GET",
                        data: $('.form_state_designation').serialize(),
                        success: function (r) {
                            $('.section-reporting-designation input, .section-reporting-designation textarea').prop('disabled', true)
                            $('.section-reporting-designation .save-and-attest').fadeOut(speed)
                        },
                        error: function (xhr, textStatus, error) {
                            console.error(xhr.statusText);
                            console.error(textStatus);
                            console.error(error);
                            alert("An error occurred")
                        }
                    })
                }
            }
        })


    } else {

        ///if ends here, and else starts here

        $('.school-year-opts').not('.opt-1').addClass('hidden')

        if ($("input[name=schoolyear_only]:checked").val() === "1") {
            $('.opt-1').removeClass('hidden')
        }
        if ($("input[name=schoolyear_summer]:checked").val() === "1") {
            $('.opt-2').removeClass('hidden')
            if ($("input[name=same_curriculum_methodology]:checked").val() === "1") {
                $('.opt-3').removeClass('hidden')
                $('.opt-4, .opt-5').addClass('hidden')
            } else {
                $('.opt-3').removeClass('hidden')
                $('.opt-4').removeClass('hidden')
                if ($("input[name=summer_grant_competition]:checked").val() === "0") {
                    $('.opt-5').removeClass('hidden')
                }
            }
        }

        $('.school-year-opts input').prop('disabled', 'disabled');

        if (designation_text != 'idk') {
            $('.school-year-help-desk').addClass('hidden')
            $('.school-year-attest').slideDown(speed).css('display', 'flex')
        } else {
            $('.school-year-help-desk').slideDown(speed)
            $('.school-year-attest').addClass('hidden')
        }
    }

        /* Disable hit enter to submit the form, only load for this page. */
        $(window).keydown(function(event){
            if(event.keyCode == 13 && !$(event.target).hasClass('main-content-button')) {
                event.preventDefault();
                return false;
            }
        });
    })

}

(function ($) {

    let stateConfigAdmin = $(".section-select-state").length;
    let stateConfigSeaSu = $(".stateconfigurationform").length;
    let disable_answered = $("#disable-answering").val();
    let designation_text = $("#designation-text").val();
    
    if (stateConfigAdmin >= 1) {
        sysadminConfig();
    } else if (stateConfigSeaSu >= 1) {
        seasuConfig(designation_text,disable_answered);
    }

    let validWindows = true;

    $('.datepick input').on('change', function() {

        if ($('#window1')) {
            let openDate1 = new Date($('.apr_type_open_date_1').val()).getTime();
            let closeDate1 = new Date($('.apr_type_close_date_1').val()).getTime();

            if (openDate1 >= closeDate1) {
                $(".notification").addClass("active warning");
                $(".notification .type").html("Warning!");
                $(".notification p").html("Please insert a valid open and close date for Window 1.")
                validWindows = false;
                return false;
            }
        }
        if ($('#window2')) {
            let openDate2 = new Date($('.apr_type_open_date_2').val()).getTime();
            let closeDate2 = new Date($('.apr_type_close_date_2').val()).getTime();

            if (openDate2 >= closeDate2) {
                $(".notification").addClass("active warning");
                $(".notification .type").html("Warning!");
                $(".notification p").html("Please insert a valid open and close date for Window 2.")
                validWindows = false;
                return false;
            }
        }

        $(".notification").removeClass("active warning");
        $(".notification .type").html("");
        $(".notification p").html("")
        validWindows = true;

    })

    $('#lower_form_submit').on("click", function (e) {
        if (!validWindows) {
            $(".notification").addClass("active warning");
            e.preventDefault();
            return false;
        }
        $('#lower_form').submit();
    });

})(jQuery);
