var expanded = false;

function showCheckboxes() {
  var checkboxes = document.getElementById("checkboxes");
  if (!expanded) {
    checkboxes.style.display = "block";
    expanded = true;
  } else {
    checkboxes.style.display = "none";
    expanded = false;
  }

  var selectallBox = document.getElementById('select-all');
  var chkBxs = document.querySelectorAll('.select-me');
  if (selectallBox) {
    selectallBox.addEventListener('click', function() {
      for (var i = 0; i < chkBxs.length; i++) {
        if (chkBxs[i] != selectallBox)
          chkBxs[i].checked = selectallBox.checked;
      }
    })
  }

  for (var i = 0; i < chkBxs.length; i++) {
    chkBxs[i].addEventListener('click', function() {
      var selectedCount = document.querySelectorAll('.select-me:checked').length;
      if (chkBxs.length == selectedCount) {
        selectallBox.checked = true;
      }
      else{
        selectallBox.checked = false;
      }
    });
  }

  // Commenting out, as it was dismissing the dropdown after trying to select more than a single item
  //added focusout to close the dropdown list
  // checkboxes.addEventListener("blur", function (event) {
  //   console.log('addEventListener blur');
  //   checkboxes.style.display = 'none';
  //   expanded = false;  $("#checkboxes .checkbox").on("click", function() {
  //     $(this).find("input").click();
  //   })
  // }, true);

}
$(".multiselect .selectBox, #show-checkboxes").on("click", function() {
  showCheckboxes();
})

