/**
 * staffing page (TODO: unify all of the center submission JS to one file)
 */
/* define $ as jQuery just in case */
(function ($) {

    /* doc ready */
    $(function () {

        initialFormInputsEnableDisable();

        /* function to call console.log */
        function showlog(msg) {
            /* set debug mode, default is false, true is turn on and false is off */
            var DEBUG = true;
            if (DEBUG) {
                console.log(msg);
            }
        }

        let validation_result = true;

        /* generic function for staffing checker open and reset */
        $('#Staffing-edit-page').on('click', '.staffing_checker', function (e) {
            if (api_state_flag == 1 || view_only_mode_flag == 1) {
                return false;
            }

            e.preventDefault();

            /* set vars */
            var form = $(this).parents('form');
            var item = $(this).parents('.item');
            var num_of_paid = $(item).next().next().find('.number-of-paid-input');
            var num_of_volunteer = $(item).next().next().next().find('.number-of-volunteer-input');

            /* add the active class to the parent item */
            $(item).toggleClass('active');
            checkForm();

            const parentTrRow = $(item).parents("tr.staffing-edit-row");
            const inputs = $(parentTrRow).children('td').children('input[type=text].number-of-paid-input, input[type=text].number-of-volunteer-input');

            if ($(item).hasClass('active')) {
                $(item).children("input[type=checkbox]").val('on');
                inputs.each((i, e)  => {
                    $(e).removeAttr('disabled');
                });
        
            } else {
                $(item).parent("input[type=checkbox]").val('off');
                inputs.each((i, e)  => {
                    $(e).attr('disabled', 'disabled').val("");
                });

            }

            /* toggle the disabled attribute to the next next item */
            $(num_of_paid).prop('readonly', !$(num_of_paid).prop('readonly'));
            $(num_of_paid).val("");
            $(num_of_volunteer).prop('readonly', !$(num_of_volunteer).prop('readonly'));
            $(num_of_volunteer).val("");



            get_total();
            /* save data via AJAX (to parent form action) - defined in data.js */

            console.log('save data staffing 1');
            let saved = save_data_2(form, validation_result);
        });

        /* on click of any button in the numeric steppers */
        $('#apr-staffing-form .numeric-stepper button').click(function (e) {
            if (api_state_flag == 1 || view_only_mode_flag == 1) {
                return false;
            }

            e.preventDefault();
            var form = $(this).parents('form');
            get_total();
            /* save data via AJAX (to parent form action) - defined in data.js */

            console.log('save data staffing 2');
            save_data(form, validation_result);
        });

        /* on keyup change, focusout for any input and button in the apr-staffing-form */
        $('#apr-staffing-form .numeric-staffing input').on("change, keyup, focusout", function (e) {
            if (api_state_flag == 1 || view_only_mode_flag == 1) {
                return false;
            }

            e.preventDefault();
            var form = $(this).parents('form');
            get_total();
            /* save data via AJAX (to parent form action) - defined in data.js */

            console.log('save data staffing 3');
            save_data(form, validation_result);
        });

        $(document).on('click', '.save-staffing-btn', function (e) {
            if (api_state_flag == 1 || view_only_mode_flag == 1) {
                
                if($(e.target).hasClass('navigate-activities')){
                    window.location.replace($('.staffform').attr('prev'));
                }
                
                if($(e.target).hasClass('navigate-participation')){
                    window.location.replace($('.staffform').attr('next'));
                }
                
                return false;
            }
            let me = $(this);

            e.preventDefault();
            // clear auto save cache
            localStorage.clear();

            console.log('Ajax staffing 1');

            $.ajax({
                url: $('.staffform').attr('action'),
                type: 'POST',
                data: $('.staffform').serialize(),
                success: function (res) {
                    //Redirect based on button
                    if (me.hasClass('navigate-activities')) {
                        console.log('prev', $('.staffform').attr('prev'));
                        window.location.replace($('.staffform').attr('prev'));
                    } else {
                        console.log('next', $('.staffform').attr('next'));
                        window.location.replace($('.staffform').attr('next'));
                    }
                },
                error: function (res) {
                    $('.notification span').text('Error!')
                    $('.notification p').text(res.message);
                    $('.notification').addClass('active warning');
                }
            });
        });

        // was in review page, now deprecated
        $(document).on('click', '.edit-staffing-section', function () {
            if (api_state_flag == 1 || view_only_mode_flag == 1) {
                return false;
            }

            $('html, body').animate({
                scrollTop: 0
            }, 900, function () {
                $('.staffing-section').addClass('visible').removeClass('completed-section');
                $('.review-section').removeClass('visible');
            });
        });

        // go to review page, deprecated
        $('.review-staffing-btn').click(function (e) {
            if (api_state_flag == 1 || view_only_mode_flag == 1) {
                return false;
            }

            e.preventDefault();

            var $form = $('form[name="apr-staffing-form"]').serialize();
            console.log('Ajax staffing 2');
            $.ajax({
                url: '/ajax/review_staffing',
                type: 'POST',
                data: $form,
                success: function (res) {
                    if (res) {
                        $('.review-section').html(res);
                        $('html, body').animate({
                            scrollTop: 0
                        }, 900, function () {
                            $('.staffing-section').removeClass('visible').addClass('completed-section');
                            $('.review-section').addClass('visible');
                        });
                    } else {
                        alert('Unable to process request');
                    }
                }
            });
        });
    

        function get_total() {
            var paid_sum = 0;
            $('.number-of-paid-input').each(function () {
                paid_sum += Number($(this).val());
            });
            $('#paid-sum').text(paid_sum);

            var volunteer_sum = 0;
            $('.number-of-volunteer-input').each(function () {
                volunteer_sum += Number($(this).val());
            });
            $('#volunteer-sum').text(volunteer_sum);
        }

        get_total();

        function checkForm(onload = false) {
          let paidInputs = [];
          let volunteerInputs = [];

          $(".number-of-paid-input").each((i, x) => {
            paidInputs.push(x.value);
          });
          $(".number-of-volunteer-input").each((i, x) => {
            volunteerInputs.push(x.value);
          });

          validation_result = true;
          $(".save-staffing-btn, #aspo-inner-container").removeClass("disabled");

          for (let i = 0; i < paidInputs.length; i++) {
            if (
              (paidInputs[i] == "" && volunteerInputs[i] != "") ||
              (paidInputs[i] != "" && volunteerInputs[i] == "")
            ) {
              if (onload == true) {
                staffingWarning(true);
              }
              validation_result = false;
              $(".save-staffing-btn, #aspo-inner-container").addClass("disabled");
            }
          }
        }

        checkForm(true);

        $(".number-of-paid-input, .number-of-volunteer-input").on("keyup change", function (e) {
            let validInput = true;
            if ($(this).val() == "") {
              validInput = false;
              $(this).focus();
            }
            if (validInput == false) {
              $(".save-staffing-btn, #aspo-inner-container").addClass("disabled");
              // do not trigger validation when user is tabbing to empty input
              if (e.keyCode != 9) {
                validation_result = false;
                staffingWarning(true);
              }
            } else {
              validation_result = true;
              staffingWarning(false);
              $(".save-staffing-btn, #aspo-inner-container").removeClass("disabled");
            }
            checkForm();
          }
        );

        $(".number-of-paid-input, .number-of-volunteer-input").on("keydown", function (e) {
            if (e.keyCode == 9 && $(this).val() == "") {
              validation_result = false;
              staffingWarning(true);
            }
          }
        );

        function staffingWarning(show) {
          if (show) {
            $(".notification span").text("Alert!");
            $(".notification p").text(
              "There is an error with your Staffing data. Please carefully review and fix the error in the data you have entered. Please check to make sure that for each staffing category selected, both data entry fields have a value entered in them (including any “0” values). Once fixed, the button to advance will be enabled."
            );
            $(".notification").addClass("active warning");
            $(".notification").removeClass("d-none success");
          } else {
            $(".notification span").text("Alert!");
            $(".notification p").text("");
            $(".notification").addClass("d-none");
          }
        }

        if ($("#Staffing-edit-page").length >= 1) {
          function elementInViewPort(element_class) {
            var docViewTop = $(window).scrollTop();
            var docViewBottom = docViewTop + $(window).height();
            var elemTop = $(element_class).offset().top;
            return elemTop <= docViewBottom && elemTop >= docViewTop;
          }

          function scrollDirectionIsDown(previous_scroll_direction) {
            return window.scrollY > previous_scroll_direction;
          }

          let pre_event_y_scroll = 0;
          $(window).on("scroll", function () {
            if (elementInViewPort(".save-staffing-btn")) {
              if (
                validation_result == false &&
                scrollDirectionIsDown(pre_event_y_scroll)
              ) {
                staffingWarning(true);
              }
              pre_event_y_scroll = window.scrollY;
            }
          });
        }      

        initialFormInputsEnableDisable();

        var api_state_flag = 0;
        var view_only_mode_flag = 0;
        $.fn.staffing_view_only = function () {
            var form = this;
            api_state_flag = $(form).find('input[name="api_state_flag"]').val();
            view_only_mode_flag = $(form).find('input[name="view_only_mode_flag"]').val();
            showlog('staffing.js => the api_state_flag IS SET to ' + api_state_flag);
            showlog('staffing.js => the view_only_mode_flag IS SET to ' + view_only_mode_flag);
            if (api_state_flag == 1 || view_only_mode_flag == 1) {
                $(".number-of-paid-input, .number-of-volunteer-input").attr('disabled', 'disabled');
            }
        }

        $('#apr-staffing-form').staffing_view_only();

        // On edit staffing and view staffing, wait until window is loaded - all images, styles-sheets, fonts, links, and other media assets
        if ($(".edit-staffing.wrapper, .view-staffing.wrapper").length >= 1) {
            // wait until window is loaded - all images, styles-sheets, fonts, links, and other media assets
            $(window).on("load", function () {
                // OPTIONAL - waits til next tick render to run code (prevents running in the middle of render tick)
                window.requestAnimationFrame(function () {
                    activitiesComplete.play();
                    activitiesComplete.restart();
                });
            });
        }


    }); /* end doc ready */
})(jQuery);


/**
         * 
         */
 function initialFormInputsEnableDisable() {

    // get all the 'items'
    const items = $('table.staffing td.activity.item');

    items.each((index, i) => {
        disableItemRowInputs(i);
    });
}

/**
 * 
 * @param {*} item 
 * 
 * goes through the form on load and enabled disables the inputs bases on the checkbox
 */
 function disableItemRowInputs(item) {

    const parentTrRow = $(item).parents("tr.staffing-edit-row");
    const inputs = $(parentTrRow).children('td').children('input[type=text].number-of-paid-input, input[type=text].number-of-volunteer-input');
    $("label.checker").removeAttr('tabindex');

    if ($(item).hasClass('active')) {
        $(item).children("input[type=checkbox]").val('on');

        inputs.each((i, e)  => {
           
            $(e).removeAttr('disabled');
        });

    } else {
        $(item).parent("input[type=checkbox]").val('off');

        inputs.each((i, e)  => {
    
            $(e).attr('disabled', 'disabled').val("");
        });

    }
}






