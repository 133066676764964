$(function () {
    $(document).on('keyup', '.center-section input.numbersonly, elt_questions input[type-text].numbersonly', function (e) {
        e.preventDefault();

        this.value = this.value.replace(/[^0-9\.]/g, '');
    });


    // Expanded Learning Program
    $('.has-elt').change(function (e) {
        const choice = $(".has-elt").find(':selected').text().trim();
        const YES = 'Yes';

        if (choice == YES) {
            $('.elt_questions').slideDown("slow");
            $('.elt_questions').removeClass('hidden');

        } else {
            $('.elt_questions').slideUp("slow");
            $('.elt_questions').addClass('hidden');

        }

    });
    
    $(document).on('click', '.edit-center-section', function () {
        $('html, body').animate({
            scrollTop: 0
        }, 900, function () {
            $('.center-section').addClass('visible').removeClass('completed-section');
            $('.review-section').removeClass('visible');
            $('.progress-title').html('Centers Info');
        });
    });
});
