$(document).ready(function () {
    //$('input, textarea').attr('pattern', "");
    var modCount = 1;
    var totalModules = $('form .module').length;
    var completed = [];

    //$('.main-content').matchHeight();
    //Hide all modules on a page

    // comment out continue button for enhancement
    // $('form .module').hide();
    // $('form .module .expand').hide();
    function isEmpty(value) {
        return value == null || isNaN(value) || (typeof value === "string" && value.trim().length === 0);
    }
    
    function normalizeIntegerArray(array){
        normalized_array = (array).map(
            (el) => isEmpty(el) ? 0 : el
        );
        return normalized_array;
    }
    
    $('form .module').show();
    $('form .module .expand').show();



    $('.choose-bi-state:not(.show)').hide();
    //Show the first module in a field

    $('form .module').each(function () {

        var module = $(this);

        module.attr('id', "module" + modCount);
        module.find('.button').data("nextMod", "module" + (modCount + 1));
        modCount++;

        var $fields = module.find('input:not(.skip), select:not(.skip)');

        $fields.keyup(function () {
        console.log('1st key up');
            if (!$(this).is('[readonly]')){
                console.log('only for non readonly');
                var $emptyFields = $fields.filter(function () {
                    return $.trim(this.value) === "";
                });

                if ($emptyFields > 0) {
                } else if ($emptyFields.length == 0) {
                    module.find('.button').prop('disabled', false);
                }
            }
        });

        $fields.click(function () {

            var $emptyFields = $fields.filter(function () {
                return $.trim(this.value) === "";
            });

            if ($emptyFields > 0) {
            } else if ($emptyFields.length == 0) {
            }

        });


        //Fix the autocomplete issue
        $fields.change(function () {
            var $emptyFields = $fields.filter(function () {
                return $.trim(this.value) === "";
            });

            if ($emptyFields > 0) {
            } else if ($emptyFields.length == 0) {
            }
        });

    });

    // check all the fields in centers and grantee are filled
    $('form .session').each(function () {
        var session = $(this);
        var $fields = session.find('input:not(.skip), select:not(.skip)');

        $fields.keyup(function () {
            var $emptyFields = $fields.filter(function () {
                return $.trim(this.value) === "";
            });

            if ($emptyFields.length > 0) {
            } else if ($emptyFields.length == 0) {
                session.find('.button').prop('disabled', false);
                $('.update-grantee').prop('disabled', false);
                $('.save-center').prop('disabled', false);
                $('.review-edit-center-btn').prop('disabled', false);
            }

            if ($('form').hasClass('center-form')) {
                var re = /[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,4}/igm;
                validate_center();
            }

        });

        $fields.change(function () {
            var $emptyFields = $fields.filter(function () {
                return $.trim(this.value) === "";
            });
            console.log($emptyFields.length);
            console.log($.trim(this.value));
            if (!$emptyFields.length) {
            } else {
                $('.update-grantee').prop('disabled', false);
                $('.review-grantee-btn').prop('disabled', false);
                $('.review-edit-center-btn').prop('disabled', false);
            }

            if ($('form').hasClass('center-form')) {
                validate_center();
            }
        });

        $fields.click(function () {
            var $emptyFields = $fields.filter(function () {
                return $.trim(this.value) === "";
            });
            var $emptyFields = $fields.filter(function () {
                return $.trim(this.value) === "";
            });
            validate_center();
        });

        $fields.focus(function () {
            validate_center();
        });

        // for load each session
        function validate_center() {
            var $emptyFields = $fields.filter(function () {

                return $.trim(this.value) === "";

            });

            var totalpartners = $('.partner-name').length - 1;
            var totalPartner_filled = 0;

            var re = /[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,4}/igm;
            if (
                ($emptyFields.length <= 0) &&
                (re.test($('.center-email-input').val())) &&
                ($('.center-phone').val().length == 12)
            ) {


                if ($(".has-partners").val() == 'Yes') {
                    $('.partner-name').each(function () {
                        if ($.trim($(this).val().length) > 0) {
                            ++totalPartner_filled;
                        }
                    });
                }

                if (($(".has-feeders").val() == 'Yes') && ($(".has-partners").val() == 'No')) {
                    if (totalFeeder_filled == totalfeeders) {
                        $('.save-center').prop('disabled', false);
                    } else {
                    }
                } else if (($(".has-feeders").val() == 'No') && ($(".has-partners").val() == 'Yes')) {
                    if (totalPartner_filled == totalpartners) {
                        $('.save-center').prop('disabled', false);
                    } else {
                    }
                } else if (($(".has-feeders").val() == 'Yes') && ($(".has-partners").val() == 'Yes')) {
                    if ((totalPartner_filled == totalpartners) && (totalFeeder_filled == totalfeeders)) {
                        $('.save-center').prop('disabled', false);
                    } else {
                    }
                } else {
                    $('.save-center').prop('disabled', false);
                }
            } else {
            }
         }

    });


    $('.center-zip').change(function () {
        $('.center_zip').value($('.center-zip').value());
    });

    // validate_elt
    function validate_elt() {
        var error = false;

        /* check ELP when ELP is selected as Yes */
        console.log($(".elt_1:checked").length);
        if ($(".elt_1:checked").length == 0) {
            error = 'Offer ELP during cannot be not selected.';
        } else if (($(".elt_2").val() == '0') || ($(".elt_2").val() == '')
            || ($(".elt_2").val() == '00') || ($(".elt_2").val() == '000')
            || ($(".elt_2").val() == '0000') || ($(".elt_2").val() == '00000')) {
            error = 'Total number students served during ELP, by Center, cannot be “0”.';
        } else if ($(".elt_3:checked").length == 0) {
            error = 'Activities are the grantees implementing during ELP cannot be no selected.';
        } else if (($(".elt_4").val() == '0') || ($(".elt_4").val() == '')
            || ($(".elt_4").val() == '00') || ($(".elt_4").val() == '000')
            || ($(".elt_4").val() == '0000') || ($(".elt_4").val() == '00000')) {
            error = 'Total number of hours per week ELP Activities offered cannot be “0”.';
        } else if ($(".elt_5:checked").length == 0) {
            error = 'Engaged in providing services during ELP cannot be no selected.';
        } else if (($(".elt_6").val() == '0') || ($(".elt_6").val() == '')
            || ($(".elt_6").val() == '00') || ($(".elt_6").val() == '000')
            || ($(".elt_6").val() == '0000') || ($(".elt_6").val() == '00000')) {
            error = 'Percentage of funds allocated by the State directed towards funding ELP Activities cannot be “0”.';
        }
        return error;
    }


    // for all other function call
    function validate_center() {
    // console.log("in validate center");
        var session = $(this);
        var $fields = session.find('input:not(.skip), select:not(.skip)');
        var totalfeeders = $('.feeder-name').length - 1;
        var totalFeeder_filled = 0;
        var totalpartners = $('.partner-name').length - 1;
        var totalPartner_filled = 0;

        var $emptyFields = $fields.filter(function () {
            return $.trim(this.value) === "";
        });

        var re = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

        /* remove #gtype on validate center */
         if ( $emptyFields.length <= 0 ) {
            if ($(".has-feeders").val() == 'Yes') {
                /* change CONTINUE TO REVIEW button to disable*/
                $('.feeder-name').each(function () {
                    if ($.trim($(this).val().length) > 0) {
                        ++totalFeeder_filled;
                    }
                });
            }

            if ($(".has-partners").val() == 'Yes') {
                $('.partner-name').each(function () {
                    if ($.trim($(this).val().length) > 0) {
                        ++totalPartner_filled;
                    }
                });
            }

            if (($(".has-feeders").val() == 'Yes') && ($(".has-partners").val() == 'No')) {
                if (totalFeeder_filled == totalfeeders) {
                    $('.save-center').prop('disabled', false);
                } else {
                }
            } else if (($(".has-feeders").val() == 'No') && ($(".has-partners").val() == 'Yes')) {
                if (totalPartner_filled == totalpartners) {
                    $('.save-center').prop('disabled', false);
                } else {
                }
            } else if (($(".has-feeders").val() == 'Yes') && ($(".has-partners").val() == 'Yes')) {
                if ((totalPartner_filled == totalpartners) && (totalFeeder_filled == totalfeeders)) {
                    $('.save-center').prop('disabled', false);
                } else {
                }
            } else {
                $('.save-center').prop('disabled', false);
            }

        } else {
                // console.log ('not pass validate');
        }
    }



    $('form .explode').show();

    $('.debug').each(function () {

        var module = $(this);

        var $fields = module.find('input');
        //alert($fields.length);
        $fields.keyup(function () {
            console.log('3st key up');
            var $emptyFields = $fields.filter(function () {
                return $.trim(this.value) === "";
            });
            // console.log($emptyFields.length);
        });
    });

    $('.counter').each(function () {
        var counter = $(this);
        var leftTab = counter.find('.icon-chevron-left');
        var rightTab = counter.find('.icon-chevron-right');
        var display = counter.find('span.viewer');
        var input = $("." + counter.data("ifield"));

        var year = input.val();

        if (year <= 0) {
            year = 2009;
        }

        input.val(year);
        display.html(year);

        leftTab.click(function (e) {
            e.preventDefault();

            year = input.val();

            if ((year - 1) <= 2009) {
                year = 2009;
            } else {
                year = year - 1;
            }

            display.html(year);
            input.val(year).trigger('keyup');

        });

        rightTab.click(function (e) {
            e.preventDefault();

            year = input.val();
            year++;
            var currentyear = (new Date).getFullYear();
            if (year > currentyear) {
                year = currentyear;
            }
            display.html(year);
            input.val(year).trigger('keyup');

        });
    });

    $('.method_button').click(function (e) {
        e.preventDefault();
        if ($('#' + $(this).data('input')).val() == 0) {
            $('#' + $(this).data('input')).val(1).trigger('keyup');
        } else {
            $('#' + $(this).data('input')).val(0).trigger('keyup');
        }
        $(this).toggleClass('active');
        update_state_profile();
    });

    $('.grantsperiod').change(function (e) {
        e.preventDefault();
        update_state_profile();
    });

    $('.grantee .plus').click(function (e) {
        e.preventDefault();
        update_state_profile();
    });


    $('.grantee .minus').click(function (e) {
        e.preventDefault();
        update_state_profile();
    });

    $('.grantee .num_of_new_grants').keyup(function (e) {
        e.preventDefault();
        update_state_profile();
    });



    $('.month').each(function () {
        var month = $(this);
        var input = month.find('#month');
        var dates = month.find('.m');

        dates.click(function (e) {
            e.preventDefault();
            dates.removeClass('active');
            input.val($(this).data("month")).trigger('keyup');
            $(this).addClass('active');
        });

    });

    $('.addyear').click(function (e) {
        e.preventDefault();
        $('.expand').slideToggle();
    });

    $('.cal-heading').each(function () {
        var el = $(this);
    });


    $('.continue').click(function (e) {
        e.preventDefault();

        // turn on continue button after center type
        $(this).parents().siblings('.module ~ .make-visible').find('.button').prop('disabled', false);

        var el = $(this);
        var nextEl = "#" + el.data("nextMod");
    });

    $('.email-continue').click(function (e) {
        e.preventDefault();

        //validate email
        var re = /[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,4}/igm;
        if (!re.test($('.email-input').val())) {
            $('.notification p').html('The email address you entered is invalid. Please make sure the address is formatted as email@domain.com.');
            $('.notification').addClass('active').addClass('warning');
            $('.notification span').html('Alert!');
            $("html,body").animate({scrollTop:0},"slow");
        } else {

            $('form .module').show();
        }
    });

    $(document).on('click', '.center-email-continue', function (e) {
        e.preventDefault();

        // check contact's name first
        if ($('.center-contact').val().length == 0) {
            $('.notification p').html('The center contact is mandatory field. Please input center contact.');
            $('.notification').addClass('active').addClass('warning');
            $('.notification  span').html('Alert!');
            $("html, body").animate({ scrollTop: 0 }, "slow");
        } else {

            //validate email
            var re = /[A-Z0-9._%+-]+@(?:[A-Z0-9-]+\.)+[A-Z]{2,4}/igm;
            if (!re.test($('.center-email-input').val())) {
                $('.notification p').html('The email address you entered is invalid. Please make sure the address is formatted as email@domain.com.');
                $('.notification').addClass('active').addClass('warning');
                $('.notification  span').html('Alert!');
                $("html, body").animate({ scrollTop: 0 }, "slow");
            } else {
                var el = $(this);
                var nextEl = "#" + el.data("nextMod");
                completed.push("mod");
                el.fadeOut(400, function () {
                    $(nextEl).fadeIn(400, function () {
                        var progress = ((completed.length + 1) / totalModules) * 100;
                        $('.progress').animate({
                            width: progress + "%"
                        }, 500);

                        $('html, body').animate({
                            scrollTop: $(nextEl).offset().top - 105
                        }, 2000);
                    });
                });
            }
        } // end of validate email
    });

    $('.granteeType .org').click(function (e) {
        e.preventDefault();
        console.log('.granteeType .org');
        $('.org').removeClass('active');
        $('#gtype').val($(this).data('typepkid')).trigger('keyup');
        $(this).addClass('active');
    });

    $('.editCenterType').change(function (e) {
        e.preventDefault();
        $form = $('.center-form').serialize();
        $.ajax({
            url: $('.center-form').attr('action'),
            type: 'POST',
            data: $('.center-form').serialize(),
            success: function (res) {
                if (res.success) {
                    console.log("save update center type");
                }
            }
        });
    });

    $('.state-select').on('change', function (e) {
        var val = $(this).val();
        if (val == 5) {
            $('.choose-bi-state').show();
        } else {
            $('.choose-bi-state').hide();
        }
    });

    $('.continue-section').click(function (e) {
        e.preventDefault();
    });

    $('.review-grantee-btn').click(function (e) {
        e.preventDefault();
        var $parent = $(this).closest('.main-content');
        var section = $(this).data("section");
        $form = $('.grantee-form').serialize();
        $.ajax({
            url: '/ajax/review_grantee',
            type: 'POST',
            data: $form,
            success: function (res) {
                if (res) {
                    $('.review-section').html(res);

                    $('html, body').animate({
                        scrollTop: 0
                    }, 900, function () {
                        $('.grantee').removeClass('visible');
                        $('.review-section').addClass('visible');
                        $parent.addClass('completed-section');
                        $('.progress-title').html("Grantee Review");
                    });
                }
            }
        });
    });

    $('.review-edit-grantee-btn').click(function (e) {
        e.preventDefault();
        var $parent = $(this).closest('.main-content');
        var section = $(this).data("section");
        $form = $('.grantee-form').serialize();
        $.ajax({
            url: '/ajax/review_edit_grantee',
            type: 'POST',
            data: $form,
            success: function (res) {
                if (res) {
                    $('.review-section').html(res);

                    $('html, body').animate({
                        scrollTop: 0
                    }, 900, function () {
                        $('.grantee').removeClass('visible');
                        $('.review-section').addClass('visible');
                        $parent.addClass('completed-section');
                        $('.progress-title').html("Review");
                    });
                }
            }
        });
    });

    $('.review-center-btn').click(function (e) {
        e.preventDefault();
        /* ELP input validation */
        if ($('.has-elt').val() == 'Yes') {
            return_err = validate_elt();
        } else {
            return_err = false;
        }


        if (return_err) {
            $('.notification p').html(return_err);
            $('.notification').addClass('active').addClass('warning');
            $('.notification span').html('Alert!');
        } else {
            var $form = $('.center-form').serialize();
            $.ajax({
                url: '/ajax/review_center',
                type: 'POST',
                data: $form,
                success: function (res) {
                    console.log($form);
                    if (res) {
                        $('.review-section').html(res);
                        $('html, body').animate({
                            scrollTop: 0
                        }, 900, function () {
                            $('.center-section').removeClass('visible').addClass('completed-section');
                            $('.review-section').addClass('visible');
                            $('.progress-title').html('Review');
                        });
                    } else {
                        alert('Unable to process request');
                    }
                }
            });
        }
    });

    $('.review-edit-center-btn').click(function (e) {
        e.preventDefault();


        if ($('.has-elt').val() == 'Yes') {
            return_err = validate_elt();
        } else {
            return_err = false;
        }

        if (return_err) {
            //alert(return_err);
            $('.notification p').html(return_err);
            $('.notification').addClass('active').addClass('warning');
            $('.notification span').html('Alert!');
        } else {
            var $form = $('.center-form').serialize();
            $.ajax({
                url: '/ajax/review_edit_center',
                type: 'POST',
                data: $form,
                success: function (res) {
                    if (res) {
                        $('.review-section').html(res);
                        $('html, body').animate({ scrollTop: 0 }, 900, function () {
                            $('.center-section').removeClass('visible').addClass('completed-section');
                            $('.review-section').addClass('visible');
                            $('.progress-title').html('Review');
                        });
                    }
                    else {
                        alert('Unable to process request');
                    }
                }
            });
        }

    });

    $(document).on('click', '.save-center', function (e) {
        e.preventDefault();
        $('.save-center').attr('disabled',true);
        $.ajax({
            url: $('.add-center-form').attr('action'),
            type: 'POST',
            data: $('.add-center-form').serialize(),
            success: function (res) {
                //Redirect
                if (res.success) {
                    window.location.replace(res.redirect);
                } else {
                    $('.loading-overlay').hide();
                    $('.notification span').text('Alert!')
                    $('.notification p').text(res.message);
                    $('.notification').addClass('active warning');
                }

            },
            error: function(err) {
                $('.loading-overlay').hide();
                $('.notification span').text('Alert!')
                $('.notification p').text(err.message);
                $('.notification').addClass('active warning');
            }
        });
    });


    $(document).on('click', '.edit-center', function (e) {
        e.preventDefault();
        $('.loading-overlay').fadeIn();
        let allow_redirect = $(this).attr('data-allow_redirect');
        $.ajax({
            url: $('.center-form').attr('action'),
            type: 'POST',
            data: $('.center-form').serialize(),
            success: function (res) {
                if (res.success) {
                    // allow method to redirect if defined in form element
                    if(allow_redirect == 'true' || allow_redirect == '1') {
                        window.location.replace(res.redirect);
                    }
                } else {
                    $('html, body').animate({
                        scrollTop: 0
                    }, 900, function () {
                        $('.center-section').addClass('visible').removeClass('completed-section');
                        $('.review-section').removeClass('visible');
                        $('.errors').html(res.message).show();
                        $('.progress-title').html('Centers Info');
                    });
                }
            },
            error: function (err) {
                $('.loading-overlay').hide();
                $('.notification span').text('Error!')
                $('.notification p').text(err.message);
                $('.notification').addClass('active warning');
            }
        });
    });

    $(document).on('click', '.edit-center-section', function () {
        $('html, body').animate({
            scrollTop: 0
        }, 900, function () {
            $('.center-section').addClass('visible').removeClass('completed-section');
            $('.review-section').removeClass('visible');
            $('.progress-title').html('Centers Info');
        });
    });

    $(document).on('click', '.edit-grantee-section', function () {
        $('html, body').animate({
            scrollTop: 0
        }, 900, function () {
            $('.grantee').addClass('visible').removeClass('completed-section');
            $('.review-section').removeClass('visible');
            $('.progress-title').html('Grantee Info');
        });
    });

    $(document).on('change', '.has-feeders', function () {
        var val = $(this).val();
        var feeder_list = $('.feeders');
        var feeders = $(feeder_list).find('.feeder');
        if (val == "Yes") {
            $('.feeders').show();
            if (feeders.length < 1) {
                $('.feeder-clone').clone().removeClass('feeder-clone').addClass('feeder').find('input').each(function () {
                    var count = $('.feeder').length;
                    this.name = this.name.replace(/feeders\[(\d+)\]/, function (str, p1) {
                        return "feeders[" + count + "]";
                    });
                }).end().appendTo('.additional-feeders');
            }
        } else {
            //achan 05-29-2015 add clear feeder name when select no
            $('.feeder-name').val('');
            $('.feeders').hide();
        }
        validate_center();
    });

    $(document).on('keyup', '.feeder-name', function () {
        console.log('keyup feeder-name');
        validate_center();
    });


    $(document).on('change', '.has-partners', function () {
        var val = $(this).val();
        var review_button = $(this).parents('form').find('.review-center-btn');
        var partner_list = $('.partners');
        var partners = $(partner_list).find('.partner');
        if (val == "Yes") {
            $('.partners').show();
            if (partners.length < 1) {
                $('.partner-clone').clone().removeClass('partner-clone').addClass('partner').find('input').each(function () {
                    var count = $('.partner').length;
                    this.name = this.name.replace(/partners\[(\d+)\]/, function (str, p1) {
                        return "partners[" + count + "]";
                    });
                    console.log('test!'+this.name);
                }).end().appendTo('.additional-partners');
            }
        } else {
            //achan 05-29-2015 add clear feeder name when select no
            $(review_button).prop('disabled', false); /* enable the review button */
            $('.partner-name').val('');
            $('.partners').hide();
        }
        validate_center();
    });

    $(document).on("click", "form:not(#createGranteeForm) #submitbtn", function (e) {
        $.ajax({
            url: $(".granteeupdate").attr("action"),
            type: "POST",
            data: $(".granteeupdate").serialize(),
            success: function (res) {
                let requiredInputs = $(
                    ".alpha.module input, .bravoo.module input"
                );
                let success = true;

                for (let i = 0; i < requiredInputs.length; i++) {
                    if (requiredInputs[i].value == "") {
                        success = false;
                    }
                }

                // Check required checkbox input
                if ($("input[name='org_type']:checked").length == 0) {
                    success = false;
                }

                if (success) {
                    $(".grantee-form, .granteeupdate").submit();
                    return success;
                }

                $(".notification p").html(
                    "Please fill out all mandatory fields and try again."
                );

                $(".notification").addClass("active").addClass("warning");

                $(".notification span").html("Alert!");
            },
        });
    });
    $(document).on("click", "#createGranteeForm button[type=\"submit\"]", function (e) {
        e.preventDefault();
        let requiredInputs = $(
            ".alpha.module input, .bravoo.module input"
        );
        let success = true;

        for (let i = 0; i < requiredInputs.length; i++) {
            if (requiredInputs[i].value == "") {
                success = false;
            }
        }

        // Check required checkbox input
        if ($("input[name='org_type']:checked").length == 0) {
            success = false;
        }
        
        if(success){
            $(".grantee-form, .granteeupdate").submit();
            return;
        }

        $(".notification p").html(
            "Please fill out all mandatory fields and try again."
        );

        $(".notification").addClass("active").addClass("warning");

        $(".notification span").html("Alert!");
    });

    $(document).on('click', '#stateconfig-submitbtn', function (e) {
        e.preventDefault();
        if ($(this).data('role')=="SYS_ADMIN"){
            if (confirm('Are you sure want to update '+$('.configState :selected').text()+' state configuration?')){
                $('.stateprofileform').submit();
            }else{
                return false;
            }
        } else{
            $('.stateprofileform').submit();
        }


    });

    $(document).on('click', '.remove-feeder-add', function (e) {
        e.preventDefault();
        $(this).closest('.feeder').fadeOut('slow').remove();
        // remove the last one and change the feeder to no

        if ($('.feeder').length == 0) {
            // console.log("FEEDERS");
            $('.feeders').slideUp();
            $('.has-feeders').val("No");
        }
    });

    $(document).on('click', '.remove-feeder-edit', function (e) {
        // console.log('.remove-feeder-edit');
        e.preventDefault();
        var id = $(this).data("feederid");
        var el = $(this);
        var csrf = $('input[name="csrf_token_name"]').val();

        el.closest('.feeder').remove();
        $.ajax({
            url: '/ajax/remove_feeder/' + id,
            type: 'POST',
            data: {
                csrf_token_name: csrf
            },
            success: function (res) {
                el.closest('.feeder').remove();
            }
        });
        // remove the last one and change the feeder to no
        if ($('.feeder').length == 0) {
            // console.log("FEEDERS");
            $('.feeders').slideUp();
            $('.has-feeders').val("No");
        }
    });

    $(document).on('click', '.add-center', function (e) {
        e.preventDefault();
        var $clone = $('.center-clone').clone(true).removeClass('center-clone').addClass('center');
        $clone.find('input, select').each(function () {
            var count = $('.centers-container .center').length;
            this.name = this.name.replace(/centers\[(\d+)\]/, function (str, p1) {
                return "centers[" + count + "]";
            });
        }).end().appendTo('.more-centers');

    });

    $(document).on('click', '.add-feeder', function (e) {
        e.preventDefault();
        var prime = $(this).next('.feeder.prime');

        $('.feeder-clone').clone().removeClass('feeder-clone').addClass('feeder').find('input').each(function () {
            this.name = this.name.replace(/feeders\[(\d+)\]/, function (str, p1) {
                return "feeders[]";
            });
        }).end().appendTo('.additional-feeders');
    });


    $('.add-partner').on('click', function (e) {
        e.preventDefault();
        var prime = $(this).next('.partner');
        $('.partner-clone').clone().removeClass('partner-clone').prop('disabled', false).addClass('partner').find('input').each(function () {
          var count = $('.partner').length;
          this.name = this.name.replace(/partners\[(\d+)\]/, function (str, p1) {
              return "partners[" + count + "]";
          });
        }).end().appendTo('.additional-partners');
    });

    $(document).on('click', '.remove-partner-add', function (e) {
        e.preventDefault();
        $(this).closest('.partner').remove();
        // remove the last one and change the partner to no
        if ($('.partner').length == 0) {
            $('.partners').slideUp();
            $('.has-partners').val("No");
        }
    });

    $(document).on('click', '.remove-partner-edit', function (e) {
        e.preventDefault();
        var id = $(this).data("partnerid");
        var el = $(this);
        var csrf = $('input[name="csrf_token_name"]').val();
        el.closest('.partner').remove();
        $.ajax({
            url: '/ajax/remove_partner/' + id,
            type: 'POST',
            data: {
                csrf_token_name: csrf
            },
            success: function (res) {
                el.closest('.partner').remove();
            }
        });

        // remove the last one and change the feeder to no
        if ($('.partner').length == 0) {
            $('.partners').slideUp();
            $('.has-partners').val("No");
        }
    });

    // function validate user setting before enable save button
    function newUser_valiation() {
        if ($(".firstname").val() == undefined
            || $(".lastname").val() == undefined
            || $(".useremail").val() == undefined
            || $(".access_lvl").val() == undefined
        ) {
            return false;
        }
        if (($(".firstname").val().length > 0) && ($(".lastname").val().length > 0) &&
            ($(".useremail").val().length > 0) && ($(".access_lvl").val() > 0)) {
            if ($(".access_lvl").val() == 4) {
                if ($(".userGrantee").val()) {
                    $('.userbutton').prop('disabled', false);
                } else {
                    $('.userbutton').prop('disabled', true);
                }
            } else if ($(".access_lvl").val() == 2) {
                if ($('select.userState').val()) {
                    $('.userbutton').prop('disabled', false);
                } else {
                    $('.userbutton').prop('disabled', true);
                }
            } else {
                $('.userbutton').prop('disabled', false);
            }
        } else {
            $('.userbutton').prop('disabled', true);
        }
    }

    $('.firstname, .lastname, .useremail').keyup(function () {
        newUser_valiation();

    });

    $('.stategrantees, .userState, .userGrantee').change(function () {
        newUser_valiation();
    });

    // access_lvl on add / edit user
    $('#access_lvl').change(function () {
        var selected;
        selected = $.trim($("#access_lvl option:selected").text());
        if (selected == 'ED SUPER USER') {
            if (!$(".searole").hasClass('hidden')) {
                $(".searole").addClass('hidden');
            }
            if (!$(".stategrantees").hasClass('hidden')) {
                $(".stategrantees").addClass('hidden');
            }
            if (!$(".additional-grantees").hasClass('hidden')) {
                $(".additional-grantees").addClass('hidden');
            }
            if (!$(".add-more-grantees-btn").hasClass('hidden')) {
                $(".add-more-grantees-btn").addClass('hidden');
            }
            $('#grantee option:selected').attr("selected", null);
            $('.add_grantee').addClass('hidden');
            if (!$(".userState").hasClass('hidden')) {
                $(".userState").addClass('hidden');
                $('select[name^="userState"] option[value="District of Columbia"]').attr("selected", "selected"); /* all ED user state is DC*/
            }
            if (!$(".apiState").hasClass('hidden')) {
                $(".apiState").addClass('hidden');
            }
        }
        else if (selected == 'ED USER') {
            if (!$(".searole").hasClass('hidden')) {
                $(".searole").addClass('hidden');
            }
            if (!$(".userState").hasClass('hidden')) {
                $(".userState").addClass('hidden');
                $('select[name^="userState"] option[value="District of Columbia"]').attr("selected", "selected"); /* all ED user state is DC*/
            }
            if (!$(".apiState").hasClass('hidden')) {
                $(".apiState").addClass('hidden');
            }
            if (!$(".stategrantees").hasClass('hidden')) {
                $(".stategrantees").addClass('hidden');
            }
            if (!$(".additional-grantees").hasClass('hidden')) {
                $(".additional-grantees").addClass('hidden');
            }
            if (!$(".add-more-grantees-btn").hasClass('hidden')) {
                $(".add-more-grantees-btn").addClass('hidden');
            }
            $('#grantee option:selected').attr("selected", null);
            $('.add_grantee').addClass('hidden');
        }
        else if (selected == 'SYSTEM ADMINISTRATOR') {
            if (!$(".userState").hasClass('hidden')) {
                $(".userState").addClass('hidden');
            }
            if (!$(".apiState").hasClass('hidden')) {
                $(".apiState").addClass('hidden');
            }
            if (!$(".searole").hasClass('hidden')) {
                $(".searole").addClass('hidden');
            }
            if (!$(".stategrantees").hasClass('hidden')) {
                $(".stategrantees").addClass('hidden');
            }
            if (!$(".additional-grantees").hasClass('hidden')) {
                $(".additional-grantees").addClass('hidden');
            }
            if (!$(".add-more-grantees-btn").hasClass('hidden')) {
                $(".add-more-grantees-btn").addClass('hidden');
            }
            $('#grantee option:selected').attr("selected", null);
            $('.add_grantee').addClass('hidden');
        }
        else if (selected == 'SEA SUPER USER') {
            $('.searole').removeClass('hidden');
            $(".apiState").removeClass('hidden');
            if ($(".userState").hasClass('hidden')) {
                $(".userState").removeClass('hidden');
            }
            if (!$(".stategrantees").hasClass('hidden')) {
                $(".stategrantees").addClass('hidden');
            }
            if (!$(".additional-grantees").hasClass('hidden')) {
                $(".additional-grantees").addClass('hidden');
            }
            if (!$(".add-more-grantees-btn").hasClass('hidden')) {
                $(".add-more-grantees-btn").addClass('hidden');
            }
            $('#grantee option:selected').attr("selected", null);
            $('.add_grantee').addClass('hidden');
        }
        else if (selected == 'SEA USER') {
            $('.searole').removeClass('hidden');
            if ($(".userState").hasClass('hidden')) {
                $(".userState").removeClass('hidden');
            }
            if (!$(".apiState").hasClass('hidden')) {
                $(".apiState").addClass('hidden');
            }
            if (!$(".stategrantees").hasClass('hidden')) {
                $(".stategrantees").addClass('hidden');
            }
            if (!$(".additional-grantees").hasClass('hidden')) {
                $(".additional-grantees").addClass('hidden');
            }
            if (!$(".add-more-grantees-btn").hasClass('hidden')) {
                $(".add-more-grantees-btn").addClass('hidden');
            }
            $('#grantee option:selected').attr("selected", null);
            $('.add_grantee').addClass('hidden');
            //$('.add_grantee').fadeOut('slow').remove();
        }
        else if (selected == 'GRANTEE USER') {
            $('#grantee').removeClass('hidden');
            $(".userState").removeClass('hidden');
            if (!$(".apiState").hasClass('hidden')) {
                $(".apiState").addClass('hidden');
            }
            $(".add-more-grantees-btn").removeClass('hidden');
            $(".stategrantees").removeClass('hidden');
            $(".additional-grantees").removeClass('hidden');
            $('#grantee option:selected').attr("selected", null);
            $('#center').addClass('hidden');
            if (!$('.searole').hasClass('hidden')) {
                $('.searole').addClass('hidden');
            }
        }
        else if (selected == 'CENTER USER') {
            $('#center').removeClass('hidden');
            $('#center option:selected').attr("selected", null);
            $('#grantee').addClass('hidden');
            $('.searole').addClass('hidden');
            $(".add-more-grantees-btn").addClass('hidden');
        } else {

        }
        newUser_valiation();
    });

    $(document).on('click', '.remove-grantee-add, .remove-grantee', function (e) {
        e.preventDefault();
        $(this).closest('.add_grantee').fadeOut('slow').remove();
    });

    $(document).on('click', '.add-more-grantees-btn', function (e) {
        e.preventDefault();
        $('.grantee-clone').clone().removeClass('grantee-clone').removeClass('hidden').addClass('add_grantee').find('select').each(function () {
            /* get the last array number from grantee[ ] */
            this.name = this.name.replace(/grantee\[(\d+)\]/, function (str, p1) {
                return "grantee[]";
            });
        }).end().appendTo('.additional-grantees');
    });

    $('#outcome_types').on('click', '.group_toggle_trigger_grantee', function (e) {
        e.preventDefault(e);
        if ($('.accesslvl').val() == 'admin'){
            if (confirm('Do you want to update '+$('.configState option:selected' ).text()+' outcome type?')){
                var target_post_flag = $(this).attr('data-post-flag');
                /* add active class to the element to toggle visibility (actually done with set_open_groups() function)*/
                if ($(this).hasClass('active')) {
                    $(this).removeClass('active');
                    $('input[name="' + target_post_flag + '"]').val('0');
                    $(this).find('.state-checkbox').prop('checked', false).removeAttr('checked');
                    console.log('outcome_types click off');
                } else {
                    $(this).addClass('active');
                    $('input[name="' + target_post_flag + '"]').val('1');
                    $(this).find('.state-checkbox').prop('checked', true).attr('checked', 'checked');
                    console.log('outcome_types click on');
                }
                /* update in DB */
                update_state_profile();
            }
        }else{
            if ($(this).parents('#outcome_types').hasClass('on')) {
                var target_post_flag = $(this).attr('data-post-flag');
                /* add active class to the element to toggle visibility (actually done with set_open_groups() function)*/
                if ($(this).hasClass('active')) {
                    $(this).removeClass('active');
                    $('input[name="' + target_post_flag + '"]').val('0');
                    $(this).find('.state-checkbox').prop('checked', false).removeAttr('checked');
                    console.log('outcome_types click off');
                } else {
                    $(this).addClass('active');
                    $('input[name="' + target_post_flag + '"]').val('1');
                    $(this).find('.state-checkbox').prop('checked', true).attr('checked', 'checked');
                    console.log('outcome_types click on');
                }

                /* update in DB */
                update_state_profile();

            } else {
                $('.notification p').html('Collect Setting Period is closed, Please contact Administrator.');
                $('.notification').addClass('active').addClass('warning');
                $('.notification span').html('Alert!');
                $("html, body").animate({ scrollTop: 0 }, "slow");
            }
        }
    });

    /* Only on outcome trends, automatically submit form on change */
    $(".change-report-category").change(function() {
        $("#change-report-category-form").submit();
    });

    /* Make chart on outcome trends */
    if ($("#outcome-trends-wrapper").length >= 1) 
    {
        let report_version = $("[name='trends_report_version']").val();
        
        if(report_version == "v1") buildOutcomesV1();
    }

    /**
     *  Contains the chart rendering logic for outcomes trends version 1 report
     */
    function buildOutcomesV1() {
        $.ajax({
            url: "/reports/makeoutcomechart_v1",
            type: 'GET',
            data: {
                report_state: $("[name='report_state']").val(),
                report_category: $("[name='report_category']:checked").val(),
                days_attended: $("[name='days_attended']:checked").val(),
                attendance_type: $("[name='attendance_type']:checked").val(),
                subject: $("[name='subject']:checked").val(),
            },
            success: function (res) {
                let data = JSON.parse(res);
                if (data.success) {
                    //console.log("Success");
                    //console.log(data);

                    let chartData = {
                        award_year_min: data.award_year_min,
                        award_year_max: data.award_year_max,
                        chart_label_1: data.chart_label1,
                        chart_label_2: data.chart_label2,
                        chart_label_3: data.chart_label3,
                        chart_label_4: data.chart_label4,
                        chart_color_1: data.chart_color1,
                        chart_color_2: data.chart_color2,
                        state_data_1: data.state_data1,
                        state_data_2: data.state_data2,
                        national_data_1: data.national_data1,
                        national_data_2: data.national_data2,
                    }

                    chartData.state_data_1 = normalizeIntegerArray(chartData.state_data_1);
                    chartData.state_data_2 = normalizeIntegerArray(chartData.state_data_2);
                    chartData.national_data_1 = normalizeIntegerArray(chartData.national_data_1);
                    chartData.national_data_2 = normalizeIntegerArray(chartData.national_data_2);

                    makeOutcomeChart(chartData, data.has_subject1, data.has_subject2);
                } else {
                    //console.log("error")
                    //console.log(data)
                }
            }
        })
    }

    /**
     *  Contains the chart rendering logic for outcomes trends version 2 report
     */    
    function buildOutcomesV2() {

        let gpra_filter = $("[name='report_gpra']").val();
        let report_state = $("[name='report_state']").val();
        let hour_band = $("[name='report_hour_band']").val();

        // Clear the chart containers of error messages, guiding test. Just in case any 
        // information has been previously added to it
        $("#chart-area, #chart-area-secondary, .chart-area-secondary-header").html("");
        
        if(gpra_filter == "1")
        {
            // we must render reading and math charts for GPRA 1
            makeOutcomeChartV2('1_reading', hour_band, report_state, 'chart-area');
            makeOutcomeChartV2('1_math', hour_band, report_state,'chart-area-secondary');
        }
        else {
            makeOutcomeChartV2(gpra_filter, hour_band, report_state, 'chart-area');  
        } 
    }
    
    function makeOutcomeChart(chartData, hasSubject1, hasSubject2) {

        let categories = [];

        for (let i = parseInt(chartData.award_year_min); i <= chartData.award_year_max; i++) {
            categories.push(i + "-" + (i + 1));
        }

        var container = document.getElementById('chart-area');
        var data = {
            categories: categories,
            series: {
                line: [
                    {
                        name: chartData.chart_label_1,
                        data: chartData.state_data_1,
                        visible: hasSubject1
                    },
                    {
                        name: chartData.chart_label_2,
                        data: chartData.state_data_2,
                        visible: hasSubject2
                    }
                ],
                column: [
                    {
                        name: chartData.chart_label_3,
                        data: chartData.national_data_1,
                        visible: hasSubject1
                    },
                    {
                        name: chartData.chart_label_4,
                        data: chartData.national_data_2,
                        visible: hasSubject2
                    }
                ]
            }
        };
        var options = {
            chart: {
                width: 900,
                height: 540
            },
            yAxis: [{
                title: {
                    text: 'Improvement Percentage',
                    offsetY: -10
                },
                chartType: 'line'
            }],
            xAxis: {
                title: ''
            },
            series: {
                showDot: true
            },
            tooltip: {
                grouped: true,
                suffix: '%'
            },
            legend: {
                align: 'bottom'
            }
        };
        var theme = {
            plot: {
                lineColor: '#171838'
            },
            series: {
                line: {
                    colors: [`rgba(${chartData.chart_color_1}, 1)`, `rgba(${chartData.chart_color_2}, 1)`]
                },
                column: {
                    colors: [`rgba(${chartData.chart_color_1}, 0.5)`, `rgba(${chartData.chart_color_2}, 0.5)`]
                }
            },
            xAxis: {
                tickColor: '#171838',
                title: {
                    fontSize: 18,
                    fontFamily: '"Barlow", sans-serif',
                    fontWeight: 'bold',
                    color: '#171838',
                    padding: 20,
                    marginBottom: 20
                },
                label: {
                    fontSize: 18,
                    fontFamily: '"Barlow", sans-serif',
                    color: '#171838'
                }
            },
            yAxis: {
                tickColor: '#171838',
                title: {
                    fontSize: 18,
                    fontFamily: '"Barlow", sans-serif',
                    fontWeight: 'bold',
                    color: '#171838'
                },
                label: {
                    fontSize: 18,
                    fontFamily: '"Barlow", sans-serif',
                    color: '#171838'
                }
            },
            legend: {
                label: {
                    fontSize: 14,
                    fontFamily: '"Barlow", sans-serif',
                    fontWeight: 'bold',
                    color: '#171838'
                }
            }
        };

        tui.chart.registerTheme('myTheme', theme);
        options.theme = 'myTheme';
        var chart = tui.chart.comboChart(container, data, options);
    }

    /**
     *  Retrieve the guiding header content based on the GPRA
     */
    function getGuidingHeaderContent(gpra) {
        switch (gpra) {
            case "1_reading":
            case "1_math":
                return 'GPRA #1 - Academic Achievements - State Assessments';
            case "2":
                return 'GPRA #2 - Academic Achievements - GPA';
            case "3":
                return 'GPRA #3 - School Day Attendance';
            case "4":
                return 'GPRA #4 - Behavior';
            case "5":
                return 'GPRA #5 - Engagement in Learning';
            default:
                return 'GPRA chart'
        }
    }

    /**
     *  Retrieve the guiding text content based on the GPRA
     */    
    function getGuidingTextContent(gpra) {
        switch (gpra) {
            case "1_reading":
            case "1_math":
                return `
                        <p><b>Reading and Language Arts:</b> Percentage of students in grade 4-8 participating in 21st CCLC programming during the school year 
                        and summer who demonstrated growth in reading and language arts on state assessments.
                        </p>
                        
                        <p><b>Mathematics:</b> Percentage of students in grades 4-8 participating in 21st CCLC programming during the school year 
                        and summer who demonstrated growth in mathematics on state assessments.
                        </p>                        
                    `;
            case "2":
                return `
                        <p>Percentage of students in grades 7-8 and 10-12 attending 21st CCLC programming during the school year 
                        and summer with a prior unweighted Grade Point Average (GPA) less than 3.0 who demonstrated an improved GPA.
                        </p>                  
                    `;
            case "3":
                return `
                        <p>Percentage of students in grades 1-12 participating in 21st CCLC programming during the school year 
                        who had a school day attendance rate at or below 90% in the prior school year and demonstrated an improved 
                        attendance rate in the current school year.
                        </p>          
                    `;
            case "4":
                return `
                        <p>Percentage of students in grades 1-12 participating in 21st CCLC programming during the school year and summer
                        who experienced a decrease in in-school suspensions compared to the previous school year.
                        </p>          
                    `;
            case "5":
                return `
                        <p>Percentage of students in grades 1-5 participating in 21st CCLC programming during the school year and summer
                        who demonstrated an improvement in teacher-reported engagement.
                        </p>          
                    `;
            default:
                return ''
        }
    }

    /**
     *  Retrieve the chart title container selector, by GPRA
     */
    function getChartTitleSelector(gpra)
    {
        switch (gpra) {
            case "1_math":
                return `.chart-area-secondary-header`;
            default:
                return `.chart-area-header`;
        }
    }
    
    /**
     *  Set the guiding text associated with the GPRA chart
     */
    function setChartGuidingText(gpra)
    {
        $('#chart-data-guide .gpra-description-header').text(getGuidingHeaderContent(gpra));
        $('#chart-data-guide .gpra-description').html(getGuidingTextContent(gpra));
        $('#chart-data-guide, .gpra-data-disclamer').removeClass('hidden');
    }
    
    /**
     *  Generates an array with categories labels
     */    
    function getGraphCategories(year_min, year_max)
    {
        let categories = [];

        for (let i = parseInt(year_min); i <= year_max; i++) {
            categories.push(i + "-" + (i + 1));
        }
        return categories;
    }

    /**
     *  Transforms the back-end "percentage" improvement data into TUI-chart ready arrays
     *  Because states can change their designation from year to year we build an object that can track
     *  percentage improvement on all three reporting designations
     *  A value of columnData.AllYear = [14,null,43] would indicate :
     *  14% percentage improvement first year
     *  different reporting designation the second year
     *  (second year data would likely be found in columnData.SchoolYear and columnData.Summer)
     *  43% improvement third year the state with "AllYear" designation once again
     */
    function prepareMultiColumnChartData(chartData)
    {
        let columnData = {
            SchoolYear : [],
            Summer : [],
            AllYear : []
        };
        let data = chartData.chart_data;

        Object.entries(data).forEach(([key, value]) => {
            let term = value;
            Object.entries(columnData).forEach(([columnDataKey, columnDataValue]) => {
                if(term.hasOwnProperty(columnDataKey)) (columnData[columnDataKey]).push(term[columnDataKey]);
                else (columnData[columnDataKey]).push(null);
            });
        });

        return columnData;
    }

    /**
     *  Separate a 'ProperCase' string into 'Proper Case'
     *  @param str : target string
     */
    function separateProperCase(str) {
        return str.replace(/\b([A-Z][a-z]+)\b/g, ' $1');
    }

    /**
     *  Generates the column parameter of a TUI chart for our multi Column chart
     */
    function getMultiColumnConfig(chartData)
    {
        columnColorConfig = [];
        columnDataConfig = [];
        let columData =  prepareMultiColumnChartData(chartData);

        // if the columData.AllYear array has only null values [null, null, null] it means the state
        //  has never selected the All year designation as a reporting designation
        // in that case, we don't include it as a column in our table
        let display_all_year = (columData.AllYear).some(improve_percent => improve_percent !== null);

        let display_school_year = (columData.SchoolYear).some(improve_percent => improve_percent !== null);

        let display_summer = (columData.Summer).some(improve_percent => improve_percent !== null);

        // Adding All Year configuration
        if(display_all_year) {
            columnDataConfig.push({
                name: "All Year Data",
                data: columData.AllYear
            });
            columnColorConfig.push('#9c4ea0');
        }

        // Adding School Year configuration
        if(display_school_year) {
            columnDataConfig.push({
                name: "School Year Data",
                data: columData.SchoolYear
            });
            columnColorConfig.push('#3d7b70');
        }


        // Adding Summer configuration
        if(display_summer) {
            columnDataConfig.push({
                name: "Summer Data",
                data: columData.Summer
            });
            columnColorConfig.push('#081d3a');
        }

        return {
            columnData : columnDataConfig,
            columnColors : columnColorConfig
        };
    }

    function makeOutcomeChartV2(gpra_filter, hour_band, report_state, chart_container_id)
    {
        $.ajax({
            url: "/reports/makeoutcomechart",
            type: 'GET',
            data: {
                report_state : report_state,
                report_gpra : gpra_filter,
                report_hour_band : hour_band,
            },
            success: function (res)
            {
                let chartData = res;
                let categories = getGraphCategories(chartData.award_year_min, chartData.award_year_max);
                let columnConfig = getMultiColumnConfig(chartData);
                let container = document.getElementById(chart_container_id);
                let data = {
                    categories: categories,
                    series: {
                        line: [
                            {
                                name: chartData.national_average_label,
                                data: chartData.national_average
                            }
                        ],
                        column: columnConfig.columnData
                    }
                };
                let options = {
                    chart: {
                        width: 900,
                        height: 540
                    },
                    yAxis: [{
                        title: {
                            text: 'Improvement percentage',
                            offsetY: -10
                        },
                        chartType: 'line',
                        min: 0,
                        max: 100
                    }],
                    xAxis: {
                        title: '',
                    },
                    series: {
                        showDot: true
                    },
                    tooltip: {
                        grouped: true,
                        suffix: '%'
                    },
                    legend: {
                        align: 'bottom'
                    }
                };
                let theme = {
                    plot: {
                        lineColor: '#171838'
                    },
                    series: {
                        line: {
                            colors: ['#398ef7']
                        },
                        column: {
                            colors: columnConfig.columnColors
                        }
                    },
                    xAxis: {
                        tickColor: '#171838',
                        title: {
                            fontSize: 18,
                            fontFamily: '"Barlow", sans-serif',
                            fontWeight: 'bold',
                            color: '#171838',
                            padding: 20,
                            marginBottom: 20
                        },
                        label: {
                            fontSize: 18,
                            fontFamily: '"Barlow", sans-serif',
                            color: '#171838'
                        }
                    },
                    yAxis: {
                        tickColor: '#171838',
                        title: {
                            fontSize: 18,
                            fontFamily: '"Barlow", sans-serif',
                            fontWeight: 'bold',
                            color: '#171838'
                        },
                        label: {
                            fontSize: 18,
                            fontFamily: '"Barlow", sans-serif',
                            color: '#171838'
                        }
                    },
                    legend: {
                        label: {
                            fontSize: 14,
                            fontFamily: '"Barlow", sans-serif',
                            fontWeight: 'bold',
                            color: '#171838'
                        }
                    }
                };

                tui.chart.registerTheme('myTheme', theme);
                options.theme = 'myTheme';
                
                // Clear the chart container of error messages. Just in case any 
                // information has been previously added to it
                $(container).html("");
                
                let chart = tui.chart.comboChart(container, data, options);
                setChartGuidingText(gpra_filter);
                $(getChartTitleSelector(gpra_filter)).text(chartData.chart_title);
            },
            error: function (res)
            {
                if(res.responseJSON?.error) 
                {
                    $('#chart-area').html('<p><b>Error</b>: ' + (res.responseJSON?.error) + '</p>');
                }
            }
        });        
    }    

    $('.del_reason').click(function (e) {
        // add del_reason value to the okay parameter
        $("#okay").attr('data-deleteReason', $(this).val());
        if (!$('.delreasonerror').hasClass('hidden')){
            $('.delreasonerror').addClass('hidden');
        }
    });

    $('#outcomes-trend-form-v2 .filter-search-btn').click(function (e) 
    {
        e.preventDefault();
        let report_version = $("[name='trends_report_version']").val();
        if(report_version == "v2") buildOutcomesV2();
    });    

    $(".state-checkbox").click(function (e) {
        $(this).find('post_flag').val(0).trigger('keyup');
    });


    $(".state_has-elt").on('change', function (e) {
        e.preventDefault();
        console.log($(".state_has-elt").val());
        update_state_profile();
    });

    /* Only on grantees-page,  when user hit enter, page won't submit*/
    $(document).on('keydown', '.searching-grantees', function (e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            return false;
        }
    });

    /* Only on centers-page, when user hit enter, page won't submit*/
    $(document).on('keydown', '.searching-centers', function (e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            return false;
        }
    });

    /* Only on edit user page, when user hit enter, page won't submit*/
    $(document).on('keydown', '#sys-admin-edit-user, #User-editUser-page', function (e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            return false;
        }
    });

    /* Autofill datepicker on sysadmin edit user page if they have ISA */
    $('#sys-admin-edit-user #datetimepicker_isaexpire_input').datepicker({
        dateFormat: "yy-mm-dd"
    }).datepicker("setDate", $("#isa-date").val())

    /* Only on reports pages, automatically submit when user changes dropdown */
    $("#report-filter .auto_submit_item").on("change", () => {
        // Do not auto submit on View Performance National
        let isNational = $("#national-states-select").length;
        if (!isNational) {
            const urlParams = new URLSearchParams(window.location.search);
            const reportState = urlParams.get('report_state');
            const selectValue = $('select[name="report_state"]').val();

            if (reportState != selectValue) {
                $('select[name="report_center"]').prop('selectedIndex', 0);
                $('select[name="report_grantee"]').prop('selectedIndex', 0);
            }

            $("#report-filter").submit();
        }
    });

    /* Only on grantee report page, automatically submit when user updates fields */
    $("#grantee-report-wrapper .auto_submit_search").focusout(function () {
        $("#report-filter").submit();
    });

    $("#grantee-report-wrapper #grantee_name").keyup(function () {
        $("#report-filter").submit();
    });



    /* function  update state profile */
    function update_state_profile() {
        $.ajax({
            url: $('.stateprofileform').attr('action'),
            type: 'POST',
            data: $('.stateprofileform').serialize(),
            success: function (res) {
                if (res.success) {
                }
            }
        });
    }

    // load API documentation;
    $('.api_document').attr('src', 'https://21apr.readme.io/');

    // For support page: Configure Google Analytics event tracking for production
    if (window.location.host.toLowerCase() == '21apr.ed.gov') {

        //Google Analytics event tracking for Data Dictionary PDF Download
        $("#download_data_dictionary_pdf").click(function () {
            ga('send', {
                hitType: 'event',
                eventCategory: 'DATA GUIDE AND DATA DICTIONARY',
                eventAction: 'Download',
                eventLabel: 'Download Data Dictionary (PDF)'
            });
        });

        //Google Analytics event tracking for SEA 21APR data guide PDF Download
        $("#download_SEA_21APR_data_guide_pdf").click(function () {
            ga('send', {
                hitType: 'event',
                eventCategory: 'DATA GUIDE AND DATA DICTIONARY',
                eventAction: 'Download',
                eventLabel: 'Download SEA 21APR Data Guide (PDF)'
            });
        });

        $(".GA_event_grantee_download_quick_guides").click(function () {
            var EL = $(this).text();
            ga('send', {
                hitType: 'event',
                eventCategory: 'GRANTEE QUICK GUIDES',
                eventAction: 'Download',
                eventLabel: EL
            });
        });

        $(".GA_event_sea_download_quick_guides").click(function () {
            var EL = $(this).text();
            ga('send', {
                hitType: 'event',
                eventCategory: 'SEA QUICK GUIDES',
                eventAction: 'Download',
                eventLabel: EL
            });
        });
    }

});
